import type { TimelineEvent } from "@brm/schema-types/types.js"
import { Divider } from "@chakra-ui/react"
import type { FC } from "react"
import FieldTimeline from "../../../../../components/Timeline/FieldTimeline.js"
import { TimelineCommentInput } from "../../../../../components/Timeline/TimelineCommentInput.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../../utils.js"

interface StepLevelCommentSectionProps {
  workflowRunId: string
  workflowRunStepId: string
  workflowRunStepName: string
  timelineEvents: Array<TimelineEvent>
}

export const StepLevelCommentSection: FC<
  StepLevelCommentSectionProps & GetLogoForOrganizationProps & GetOrganizationActorProps
> = ({
  workflowRunId,
  workflowRunStepId,
  workflowRunStepName,
  timelineEvents,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
}) => {
  return (
    <>
      {timelineEvents.length > 0 && (
        <>
          <Divider marginY={4} />
          <FieldTimeline
            timelineEvents={timelineEvents}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
          />
        </>
      )}
      <TimelineCommentInput
        workflowRunId={workflowRunId}
        workflowRunStepId={workflowRunStepId}
        showPrivacyControls={true}
        objectType="WorkflowRunStep"
        objectId={workflowRunStepId}
        getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        label={workflowRunStepName}
        autoFocus
      />
    </>
  )
}
