import type { ToolItStatus } from "@brm/schema-types/types.js"
import { ToolItStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayToolItStatus, toolItColors } from "./constants.js"

interface Props {
  status: ToolItStatus | undefined
  /** If editing is enabled, a toolId needs to be provided */
  editToolId: string
}

export default function ToolItStatusBadge({ status, editToolId }: Props) {
  if (!status) {
    return undefined
  }

  return (
    <UpdateStatusBadge<ToolItStatus, "it_status">
      status={status}
      fieldName="it_status"
      objectType="Tool"
      objectId={editToolId}
      options={ToolItStatusSchema.anyOf.map((s) => s.const)}
      statusColors={toolItColors}
      displayStatus={displayToolItStatus}
      updatePermissions={["tool:update", "it:update"]}
    />
  )
}
