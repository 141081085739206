import { chakra, type ChakraProps } from "@chakra-ui/react"
import type { RenderLeafProps } from "slate-react"
import { baseMentionSpanStyle } from "./util/mentions.js"

const highlightStyle: ChakraProps = {
  ...baseMentionSpanStyle,
  backgroundColor: "gray.100",
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if ("highlighted" in leaf && leaf.highlighted) {
    children = <chakra.span {...highlightStyle}>{children}</chakra.span>
  }
  if ("bold" in leaf && leaf.bold) {
    children = <chakra.strong>{children}</chakra.strong>
  }

  if ("italic" in leaf && leaf.italic) {
    children = <chakra.em>{children}</chakra.em>
  }

  if ("underline" in leaf && leaf.underline) {
    children = <chakra.u>{children}</chakra.u>
  }

  return <chakra.span {...attributes}>{children}</chakra.span>
}
