import type { NotificationChannel, NotificationFrequency, UpdateNotificationSetting } from "@brm/schema-types/types.js"
import { type NotificationGroup, type NotificationType } from "@brm/schema-types/types.js"
import { NotificationGroupSchema } from "@brm/schemas"
import { mapBy } from "@brm/util/collections.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Stack,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import type { ReactElement } from "react"
import React, { useEffect, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useGetUserV1NotificationSettingsQuery,
  usePostUserV1DisableNotificationChannelMutation,
  usePutUserV1NotificationSettingsMutation,
} from "../../app/services/generated-api.js"
import { CancelIcon } from "../../components/icons/icons.js"
import { SlackIcon } from "../../components/icons/provider-icons.js"
import { SHORT_TOAST_DURATION } from "../../util/constant.js"
import { SettingsHeader } from "./SettingsHeader.js"

interface NotificationSubGroup {
  header?: ReactElement
  notifications: NotificationType[]
}
const notificationTypeByGroup: Record<NotificationGroup, NotificationSubGroup[]> = {
  workflow_run: [
    {
      notifications: [
        "workflow_run.started",
        "workflow_run.input_needed",
        "workflow_run.completed",
        "workflow_run.timeline_comment",
        "workflow_run_step.approved",
        "workflow_run_step.approval_requested",
        "workflow_run_step.unapproved",
        "workflow_run_step.changes_requested",
        "workflow_run_step_field.reopened",
        "workflow_run_field.gatherer_assigned",
        "mention",
      ],
    },
  ],
  renewal_reminder: [
    {
      header: (
        <FormattedMessage
          id="notification.settings.expiration"
          defaultMessage="Expiration"
          description="Notification text for new requests"
        />
      ),
      notifications: ["renewal_reminder.30d", "renewal_reminder.60d", "renewal_reminder.90d"],
    },
    {
      header: (
        <FormattedMessage
          id="notification.settings.expiration"
          defaultMessage="Auto Renewal"
          description="Notification text for new requests"
        />
      ),
      notifications: ["opt_out_reminder.30d", "opt_out_reminder.60d", "opt_out_reminder.90d"],
    },
  ],
  owned_tool: [
    {
      header: (
        <FormattedMessage
          id="notification.settings.activity"
          description="Notification text for vendor timeline comment"
          defaultMessage="Activity"
        />
      ),
      notifications: ["tool_owned.timeline_comment"],
    },
    {
      header: (
        <FormattedMessage
          id="notification.settings.expiration"
          defaultMessage="Expiration"
          description="Notification text for new requests"
        />
      ),
      notifications: [
        "tool_owned_renewal_reminder.30d",
        "tool_owned_renewal_reminder.60d",
        "tool_owned_renewal_reminder.90d",
      ],
    },
    {
      header: (
        <FormattedMessage
          id="notification.settings.expiration"
          defaultMessage="Auto Renewal"
          description="Notification text for new requests"
        />
      ),
      notifications: [
        "tool_owned_opt_out_reminder.30d",
        "tool_owned_opt_out_reminder.60d",
        "tool_owned_opt_out_reminder.90d",
      ],
    },
  ],
  owned_vendor: [
    {
      header: (
        <FormattedMessage
          id="notification.settings.activity"
          description="Notification text for vendor timeline comment"
          defaultMessage="Activity"
        />
      ),
      notifications: ["vendor_owned.timeline_comment"],
    },
    {
      header: (
        <FormattedMessage
          id="notification.settings.expiration"
          defaultMessage="Expiration"
          description="Notification text for new requests"
        />
      ),
      notifications: [
        "vendor_owned_renewal_reminder.30d",
        "vendor_owned_renewal_reminder.60d",
        "vendor_owned_renewal_reminder.90d",
      ],
    },
    {
      header: (
        <FormattedMessage
          id="notification.settings.expiration"
          defaultMessage="Auto Renewal"
          description="Notification text for new requests"
        />
      ),
      notifications: [
        "vendor_owned_opt_out_reminder.30d",
        "vendor_owned_opt_out_reminder.60d",
        "vendor_owned_opt_out_reminder.90d",
      ],
    },
  ],
  tool: [{ notifications: ["tool_digest", "tool.timeline_comment"] }],
  vendor: [{ notifications: ["vendor_digest", "vendor.timeline_comment"] }],
}

const digestNotifications: NotificationType[] = ["tool_digest", "vendor_digest"]

const inboxDisabledGroups: NotificationGroup[] = ["tool", "vendor", "workflow_run", "owned_tool", "owned_vendor"]

const inboxOptionalNotifications: NotificationType[] = [
  "vendor_owned_opt_out_reminder.30d",
  "vendor_owned_opt_out_reminder.60d",
  "vendor_owned_opt_out_reminder.90d",

  "vendor_owned_renewal_reminder.30d",
  "vendor_owned_renewal_reminder.60d",
  "vendor_owned_renewal_reminder.90d",

  "tool_owned_opt_out_reminder.30d",
  "tool_owned_opt_out_reminder.60d",
  "tool_owned_opt_out_reminder.90d",

  "tool_owned_renewal_reminder.30d",
  "tool_owned_renewal_reminder.60d",
  "tool_owned_renewal_reminder.90d",

  "opt_out_reminder.90d",
  "opt_out_reminder.60d",
  "opt_out_reminder.30d",

  "renewal_reminder.90d",
  "renewal_reminder.60d",
  "renewal_reminder.30d",

  "vendor.timeline_comment",
  "tool.timeline_comment",
]

const notificationGroupText: Record<NotificationGroup, string> = {
  workflow_run: "Requests",
  renewal_reminder: "Legal Agreements",
  owned_tool: "Tools owned by me",
  owned_vendor: "Vendors owned by me",
  vendor: "Vendors",
  tool: "Tools",
}

const notificationText: Record<NotificationType, ReactElement> = {
  // This is intentionally not part of any notification group because we do not want users to disable it today
  mention: (
    <FormattedMessage
      id="notification.settings.mention"
      defaultMessage="You are Mentioned or Participating"
      description="Notification text for mentions"
    />
  ),
  "workflow_run.started": (
    <FormattedMessage
      id="notification.settings.newRequests"
      defaultMessage="New Requests"
      description="Notification text for new requests"
    />
  ),
  "workflow_run.completed": (
    <FormattedMessage
      id="notification.settings.requestCompleted"
      defaultMessage="Request Completed"
      description="Notification text for completed requests"
    />
  ),
  "workflow_run.input_needed": (
    <FormattedMessage
      id="notification.settings.inputNeeded"
      defaultMessage="Input Needed"
      key="workflow_run.input_needed"
      description="Notification text for requests needing input"
    />
  ),
  "workflow_run_step.approved": (
    <FormattedMessage
      id="notification.settings.stepApproved"
      defaultMessage="Step Approved"
      description="Notification text for request steps getting approved"
    />
  ),
  "workflow_run_step.approval_requested": (
    <FormattedMessage
      id="notification.settings.stepApprovalRequested"
      defaultMessage="Step Approval Requested"
      description="Notification text for request steps getting approval requested"
    />
  ),
  "workflow_run_step_field.reopened": (
    <FormattedMessage
      id="notification.settings.fieldReopened"
      defaultMessage="Field Re-opened"
      description="Notification text for approved request field getting reopened"
    />
  ),
  "workflow_run.timeline_comment": (
    <FormattedMessage
      id="notification.settings.timelineEvent"
      defaultMessage="Field Comments"
      description="Notification text for request timeline events"
    />
  ),
  "workflow_run_field.gatherer_assigned": (
    <FormattedMessage
      id="notification.settings.gathererAssigned"
      defaultMessage="Field Gatherer Assigned"
      description="Notification text for request gatherer assignment"
    />
  ),
  "renewal_reminder.30d": (
    <FormattedMessage
      id="notification.settings.renewalReminder30d"
      defaultMessage="30 Days Before Agreement End Date"
      description="Notification text for 30-day agreement renewal reminder"
    />
  ),
  "renewal_reminder.60d": (
    <FormattedMessage
      id="notification.settings.renewalReminder60d"
      defaultMessage="60 Days Before Agreement End Date"
      description="Notification text for 60-day agreement renewal reminder"
    />
  ),
  "renewal_reminder.90d": (
    <FormattedMessage
      id="notification.settings.renewalReminder90d"
      defaultMessage="90 Days Before Agreement End Date"
      description="Notification text for 90-day agreement renewal reminder"
    />
  ),
  "opt_out_reminder.30d": (
    <FormattedMessage
      id="notification.settings.optOutReminder30d"
      defaultMessage="30 Days Before Agreement Opt Out Date"
      description="Notification text for 30-day agreement opt-out reminder"
    />
  ),
  "opt_out_reminder.60d": (
    <FormattedMessage
      id="notification.settings.optOutReminder60d"
      defaultMessage="60 Days Before Agreement Opt Out Date"
      description="Notification text for 60-day agreement opt-out reminder"
    />
  ),
  "opt_out_reminder.90d": (
    <FormattedMessage
      id="notification.settings.optOutReminder90d"
      defaultMessage="90 Days Before Agreement Opt Out Date"
      description="Notification text for 90-day agreement opt-out reminder"
    />
  ),
  "tool_owned_renewal_reminder.30d": (
    <FormattedMessage
      id="notification.settings.toolRenewalReminder30d"
      defaultMessage="30 Days Before Agreement End Date"
      description="Notification text for 30-day tool agreement renewal reminder"
    />
  ),
  "tool_owned_renewal_reminder.60d": (
    <FormattedMessage
      id="notification.settings.toolRenewalReminder60d"
      defaultMessage="60 Days Before Agreement End Date"
      description="Notification text for 60-day tool agreement renewal reminder"
    />
  ),
  "tool_owned_renewal_reminder.90d": (
    <FormattedMessage
      id="notification.settings.toolRenewalReminder90d"
      defaultMessage="90 Days Before Agreement End Date"
      description="Notification text for 90-day tool agreement renewal reminder"
    />
  ),
  "tool_owned_opt_out_reminder.30d": (
    <FormattedMessage
      id="notification.settings.toolRenewalReminder30d"
      defaultMessage="30 Days Before Agreement Opt Out Date"
      description="Notification text for 30-day tool agreement renewal reminder"
    />
  ),
  "tool_owned_opt_out_reminder.60d": (
    <FormattedMessage
      id="notification.settings.toolRenewalReminder60d"
      defaultMessage="60 Days Before Agreement Opt Out Date"
      description="Notification text for 60-day tool agreement renewal reminder"
    />
  ),
  "tool_owned_opt_out_reminder.90d": (
    <FormattedMessage
      id="notification.settings.toolRenewalReminder90d"
      defaultMessage="90 Days Before Agreement Opt Out Date"
      description="Notification text for 90-day tool agreement renewal reminder"
    />
  ),
  "tool_owned.timeline_comment": (
    <FormattedMessage
      id="notification.settings.toolOwned.TimelineComment"
      description="Notification text for tool timeline comment"
      defaultMessage="Comments"
    />
  ),
  "vendor_owned_renewal_reminder.30d": (
    <FormattedMessage
      id="notification.settings.vendorRenewalReminder30d"
      defaultMessage="30 Days Before Agreement End Date"
      description="Notification text for 30-day vendor agreement renewal reminder"
    />
  ),
  "vendor_owned_renewal_reminder.60d": (
    <FormattedMessage
      id="notification.settings.vendorRenewalReminder60d"
      defaultMessage="60 Days Before Agreement End Date"
      description="Notification text for 60-day vendor agreement renewal reminder"
    />
  ),
  "vendor_owned_renewal_reminder.90d": (
    <FormattedMessage
      id="notification.settings.vendorRenewalReminder90d"
      defaultMessage="90 Days Before Agreement End Date"
      description="Notification text for 90-day vendor agreement renewal reminder"
    />
  ),
  "vendor_owned_opt_out_reminder.30d": (
    <FormattedMessage
      id="notification.settings.vendorOptOutReminder30d"
      defaultMessage="30 Days Before Agreement Opt Out Date"
      description="Notification text for 30-day vendor agreement opt-out reminder"
    />
  ),
  "vendor_owned_opt_out_reminder.60d": (
    <FormattedMessage
      id="notification.settings.vendorOptOutReminder60d"
      defaultMessage="60 Days Before Agreement Opt Out Date"
      description="Notification text for 60-day vendor agreement opt-out reminder"
    />
  ),
  "vendor_owned_opt_out_reminder.90d": (
    <FormattedMessage
      id="notification.settings.vendorOptOutReminder90d"
      defaultMessage="90 Days Before Agreement Opt Out Date"
      description="Notification text for 90-day vendor agreement opt-out reminder"
    />
  ),
  "vendor_owned.timeline_comment": (
    <FormattedMessage
      id="notification.settings.vendorOwned.TimelineComment"
      description="Notification text for vendor timeline comment"
      defaultMessage="Comments"
    />
  ),
  "tool.timeline_comment": (
    <FormattedMessage
      id="notification.settings.tool.TimelineComment"
      description="Notification text for tool timeline comment"
      defaultMessage="Comments"
    />
  ),
  tool_digest: (
    <FormattedMessage
      id="notification.settings.toolDigest"
      defaultMessage="New Tool Detected"
      description="Notification text for tool digest"
    />
  ),
  "vendor.timeline_comment": (
    <FormattedMessage
      id="notification.settings.vendor.TimelineComment"
      description="Notification text for vendor timeline comment"
      defaultMessage="Comments"
    />
  ),
  vendor_digest: (
    <FormattedMessage
      id="notification.settings.vendorDigest"
      defaultMessage="New Vendor Detected"
      description="Notification text for vendor digest"
    />
  ),
  "workflow_run_step.unapproved": (
    <FormattedMessage
      id="notification.settings.workflowRunStep.unapproved"
      defaultMessage="Step Unapproved"
      description="Notification text for workflow run step unapproved"
    />
  ),
  "workflow_run_step.changes_requested": (
    <FormattedMessage
      id="notification.settings.workflowRunStep.changesRequested"
      defaultMessage="Step Changes Needed"
      description="Notification text for workflow run step changes requested"
    />
  ),
}

type NotificationState = {
  [K in keyof typeof notificationTypeByGroup]: Map<NotificationType, boolean>
}

const defaultCheckedState = (): NotificationState => {
  const defaultState: NotificationState = {
    workflow_run: new Map(),
    renewal_reminder: new Map(),
    owned_tool: new Map(),
    owned_vendor: new Map(),
    tool: new Map(),
    vendor: new Map(),
  }

  for (const key in notificationTypeByGroup) {
    const group = key as NotificationGroup
    notificationTypeByGroup[group].forEach((subGroup) => {
      subGroup.notifications.forEach((notification) => {
        defaultState[group].set(notification, false)
      })
    })
  }

  return defaultState
}

const DEFAULT_FREQUENCY: NotificationFrequency = "weekly"

export default function NotificationSettings() {
  const columns = 10
  const textStart = 1
  const textEnd = 7
  const slackStart = 7
  const slackEnd = 8
  const emailStart = 8
  const emailEnd = 9
  const inboxStart = 9
  const inboxEnd = 10
  const frequencyStart = 10
  const frequencyEnd = 11

  const [setNotificationSettings] = usePutUserV1NotificationSettingsMutation()
  const [disableNotificationChannel] = usePostUserV1DisableNotificationChannelMutation()
  const { data } = useGetUserV1NotificationSettingsQuery()
  const toast = useToast()
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()

  const settingByType = useMemo(
    () => data?.settings && mapBy(data.settings, (setting) => setting.type),
    [data?.settings]
  )

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      toast({
        description: intl.formatMessage({
          id: "notificationSettings.slack.oauthCallback.errorToastMsg",
          description: "Toast message when connecting Slack through a callback returns an error",
          defaultMessage: error,
        }),
        status: "error",
        position: "top",
        onCloseComplete: () => setSearchParams(),
      })
    }
  }, [searchParams, intl, toast, setSearchParams])

  const { checkedInboxItems, checkedEmailItems, checkedSlackItems } = useMemo(() => {
    // make a copy of the default state
    const { activeChannels, settings } = data ?? { activeChannels: [], settings: [] }
    const checkedEmailItems: NotificationState = defaultCheckedState()
    const checkedInboxItems: NotificationState = defaultCheckedState()
    let checkedSlackItems: NotificationState | undefined = activeChannels.includes("slack")
      ? defaultCheckedState()
      : undefined
    if (!settings) {
      return { checkedInboxItems, checkedEmailItems, checkedSlackItems }
    }

    const notificationSettingsMap = new Map<NotificationChannel, Map<NotificationType, boolean>>()

    settings.forEach((setting) => {
      const { channel, type, enabled } = setting
      const channelMap = notificationSettingsMap.get(channel) ?? new Map<NotificationType, boolean>()
      channelMap.set(type, enabled)
      notificationSettingsMap.set(channel, channelMap)
    })

    if (notificationSettingsMap.has("slack")) {
      checkedSlackItems = defaultCheckedState()
    }

    for (const key in notificationTypeByGroup) {
      const group = key as NotificationGroup
      notificationTypeByGroup[group].forEach((subGroup) => {
        subGroup.notifications.forEach((notification) => {
          checkedEmailItems[group].set(notification, notificationSettingsMap.get("email")?.get(notification) ?? false)
          checkedInboxItems[group].set(notification, notificationSettingsMap.get("inbox")?.get(notification) ?? false)
          if (checkedSlackItems) {
            checkedSlackItems[group].set(notification, notificationSettingsMap.get("slack")?.get(notification) ?? false)
          }
        })
      })
    }

    return { checkedInboxItems, checkedEmailItems, checkedSlackItems }
  }, [data])

  const updateNotifications = async (notificationSettings: UpdateNotificationSetting[]) => {
    try {
      await setNotificationSettings({ body: notificationSettings }).unwrap()
      toast({
        description: intl.formatMessage({
          id: "notification.settings.updateSuccess",
          description: "Notification settings update success toast title",
          defaultMessage: "Notification settings updated",
        }),
        status: "success",
        duration: SHORT_TOAST_DURATION,
      })
    } catch (_) {
      toast({
        description: intl.formatMessage({
          id: "notification.settings.failure",
          description: "Toast failure message when saving notification settings",
          defaultMessage: "Failed to save notification settings",
        }),
        status: "error",
        duration: SHORT_TOAST_DURATION,
      })
    }
  }

  const checkedForGroup = (channel: NotificationChannel, group: NotificationGroup) => {
    switch (channel) {
      case "slack":
        return checkedSlackItems ? Array.from(checkedSlackItems[group].values()) : []
      case "email":
        return Array.from(checkedEmailItems[group].values())
      case "inbox":
        return Array.from(checkedInboxItems[group].values())
      default:
        unreachable(channel)
    }
  }

  const allChecked = (channel: NotificationChannel, group: NotificationGroup) => {
    return checkedForGroup(channel, group).every(Boolean)
  }

  const isIndeterminate = (channel: NotificationChannel, group: NotificationGroup) => {
    const checked = checkedForGroup(channel, group)
    return checked.some(Boolean) && !checked.every(Boolean)
  }

  interface FrequencyOption {
    value: NotificationFrequency
    label: string
  }
  const frequencyOptions = useMemo(
    (): FrequencyOption[] => [
      {
        value: "daily",
        label: intl.formatMessage({
          id: "notification.settings.daily",
          description: "Option for daily frequency",
          defaultMessage: "Daily",
        }),
      },
      {
        value: "weekly",
        label: intl.formatMessage({
          id: "notification.settings.weekly",
          description: "Option for weekly frequency",
          defaultMessage: "Weekly",
        }),
      },
    ],
    [intl]
  )

  return (
    <>
      <SettingsHeader>
        <FormattedMessage
          id="notification.settings.title"
          description="Title for notification settings page"
          defaultMessage="Notifications"
        />
      </SettingsHeader>
      <Stack minWidth="450px" maxWidth="600px">
        <Text>
          <FormattedMessage
            id="notification.settings.title.description"
            description="Description under the notification settings title  "
            defaultMessage="Select where and when you’ll be notified. Note that if you subscribe to a tool or vendor, you’ll receive notifications related to that tool or vendor regardless of your settings here."
          />
        </Text>
        <HStack borderTopWidth={1} borderBottomWidth={1} py={2} mt={4}>
          <Icon as={SlackIcon} />
          <Stack gap={0}>
            <Text>
              <FormattedMessage
                defaultMessage="Slack"
                description="Notification Slack settings description"
                id="notification.settings.slack"
              />
            </Text>
            <Text fontSize="xs">
              <FormattedMessage
                defaultMessage="Receive personal notifications in Slack"
                description="Notification Slack settings description"
                id="notification.settings.slack.description"
              />
            </Text>
          </Stack>
          <Flex ml="auto">
            <Switch
              isChecked={checkedSlackItems !== undefined}
              onChange={async (e) => {
                if (e.target.checked) {
                  window.open(`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/auth/slack_oauth`, "_self")
                } else {
                  try {
                    await disableNotificationChannel({ body: { channel: "slack" } }).unwrap()
                    toast({
                      description: intl.formatMessage({
                        id: "notification.settings.slack.disableSuccess",
                        description: "Notification settings Slack disable success toast title",
                        defaultMessage: "Slack notifications disabled",
                      }),
                      status: "success",
                      duration: SHORT_TOAST_DURATION,
                    })
                  } catch {
                    toast({
                      description: intl.formatMessage({
                        id: "notification.settings.slack.disableFailure",
                        description: "Notification settings Slack disable failure toast title",
                        defaultMessage: "Failed to disable Slack notifications",
                      }),
                      status: "error",
                      duration: SHORT_TOAST_DURATION,
                    })
                  }
                }
              }}
            />
          </Flex>
        </HStack>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} alignItems="center" columnGap={3} rowGap={4}>
          {NotificationGroupSchema.enum.map((group, i) => {
            const marginTop = i === 0 ? 0 : 8
            return (
              // Need React fragment because the GridItems need to all be children of the grid
              <React.Fragment key={group}>
                <GridItem colStart={textStart} colEnd={textEnd} marginTop={marginTop}>
                  <Text fontWeight="medium" fontSize="xl">
                    {notificationGroupText[group]}
                  </Text>
                </GridItem>
                {checkedSlackItems && (
                  <GridItem colStart={slackStart} colEnd={slackEnd} marginTop={marginTop}>
                    <Flex justifyContent="center" alignItems="center">
                      <Checkbox
                        isChecked={allChecked("slack", group)}
                        isIndeterminate={isIndeterminate("slack", group)}
                        onChange={async (e) => {
                          await updateNotifications(
                            notificationTypeByGroup[group].flatMap((subGroup) =>
                              subGroup.notifications.map((type) => ({
                                type,
                                channel: "slack",
                                enabled: e.target.checked,
                                frequency: digestNotifications.includes(type)
                                  ? (settingByType?.get(type)?.frequency ?? DEFAULT_FREQUENCY)
                                  : undefined,
                              }))
                            )
                          )
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="Slack"
                          description="Label for checkbox that toggles Slack notifications for a group"
                          id="notification.settings.slack.label"
                        />
                      </Checkbox>
                    </Flex>
                  </GridItem>
                )}
                <GridItem colStart={emailStart} colEnd={emailEnd} marginTop={marginTop}>
                  <Flex justifyContent="center" alignItems="center">
                    <Checkbox
                      isChecked={allChecked("email", group)}
                      isIndeterminate={isIndeterminate("email", group)}
                      onChange={async (e) => {
                        await updateNotifications(
                          notificationTypeByGroup[group].flatMap((subGroup) =>
                            subGroup.notifications.map((type) => ({
                              type,
                              channel: "email",
                              enabled: e.target.checked,
                              frequency: digestNotifications.includes(type)
                                ? (settingByType?.get(type)?.frequency ?? DEFAULT_FREQUENCY)
                                : undefined,
                            }))
                          )
                        )
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Email"
                        description="Label for checkbox that toggles email notifications for a group"
                        id="notification.settings.email.label"
                      />
                    </Checkbox>
                  </Flex>
                </GridItem>
                {!inboxDisabledGroups.includes(group) && (
                  <GridItem colStart={inboxStart} colEnd={inboxEnd} marginTop={marginTop}>
                    <Flex justifyContent="center" alignItems="center">
                      <Checkbox
                        isChecked={allChecked("inbox", group)}
                        isIndeterminate={isIndeterminate("inbox", group)}
                        onChange={async (e) => {
                          await updateNotifications(
                            notificationTypeByGroup[group].flatMap((subGroup) =>
                              subGroup.notifications.map((type) => ({
                                type,
                                channel: "inbox",
                                enabled: e.target.checked,
                                frequency: digestNotifications.includes(type)
                                  ? (settingByType?.get(type)?.frequency ?? DEFAULT_FREQUENCY)
                                  : undefined,
                              }))
                            )
                          )
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="Inbox"
                          description="Label for checkbox that toggles inbox notifications for a group"
                          id="notification.settings.inbox.label"
                        />
                      </Checkbox>
                    </Flex>
                  </GridItem>
                )}
                <GridItem colSpan={columns}>
                  <Divider borderWidth={1} />
                </GridItem>
                {notificationTypeByGroup[group].map((subGroup) => (
                  <>
                    {subGroup.header && (
                      <GridItem colStart={textStart} colEnd={textEnd}>
                        <Text fontWeight="medium" fontSize="l">
                          {subGroup.header}
                        </Text>
                      </GridItem>
                    )}
                    {subGroup.notifications.map((type: NotificationType) => {
                      const setting = settingByType?.get(type)
                      return (
                        <React.Fragment key={type}>
                          <GridItem colStart={textStart} colEnd={textEnd}>
                            <Text>{notificationText[type]}</Text>
                          </GridItem>
                          {checkedSlackItems && (
                            <GridItem colStart={slackStart} colEnd={slackEnd}>
                              <Flex justifyContent="center" alignItems="center">
                                <Checkbox
                                  isChecked={checkedSlackItems[group].get(type)}
                                  onChange={async (e) => {
                                    await updateNotifications([
                                      {
                                        type,
                                        channel: "slack",
                                        enabled: e.target.checked,
                                        frequency: digestNotifications.includes(type)
                                          ? (setting?.frequency ?? DEFAULT_FREQUENCY)
                                          : undefined,
                                      },
                                    ])
                                  }}
                                >
                                  <FormattedMessage
                                    defaultMessage="Slack"
                                    description="Label for checkbox that toggles Slack notifications for a type"
                                    id="notification.settings.slack.label"
                                  />
                                </Checkbox>
                              </Flex>
                            </GridItem>
                          )}
                          <GridItem colStart={emailStart} colEnd={emailEnd}>
                            <Flex justifyContent="center" alignItems="center">
                              <Checkbox
                                isChecked={checkedEmailItems[group].get(type)}
                                onChange={async (e) => {
                                  await updateNotifications([
                                    {
                                      type,
                                      channel: "email",
                                      enabled: e.target.checked,
                                      frequency: digestNotifications.includes(type)
                                        ? (setting?.frequency ?? DEFAULT_FREQUENCY)
                                        : undefined,
                                    },
                                  ])
                                }}
                              >
                                <FormattedMessage
                                  defaultMessage="Email"
                                  description="Label for checkbox that toggles email notifications for a type"
                                  id="notification.settings.email.label"
                                />
                              </Checkbox>
                            </Flex>
                          </GridItem>
                          <GridItem colStart={inboxStart} colEnd={inboxEnd}>
                            {!digestNotifications.includes(type) ? (
                              <Tooltip
                                isDisabled={inboxOptionalNotifications.includes(type)}
                                label={intl.formatMessage({
                                  id: "notification.settings.inbox.mandatory.tooltip",
                                  description: "Tooltip for inbox notifications that cannot be turned off",
                                  defaultMessage: "Required for Inbox",
                                })}
                              >
                                <Flex justifyContent="center" alignItems="center">
                                  <Checkbox
                                    isDisabled={!inboxOptionalNotifications.includes(type)}
                                    isChecked={checkedInboxItems[group].get(type)}
                                    onChange={async (e) => {
                                      await updateNotifications([
                                        {
                                          type,
                                          channel: "inbox",
                                          enabled: e.target.checked,
                                          frequency: digestNotifications.includes(type)
                                            ? (setting?.frequency ?? DEFAULT_FREQUENCY)
                                            : undefined,
                                        },
                                      ])
                                    }}
                                  >
                                    <FormattedMessage
                                      defaultMessage="Inbox"
                                      description="Label for checkbox that toggles Inbox notifications for a type"
                                      id="notification.settings.inbox.label"
                                    />
                                  </Checkbox>
                                </Flex>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                label={intl.formatMessage({
                                  id: "notification.settings.inbox.digest.tooltip",
                                  description: "Tooltip for Inbox checkbox when digest notifications are disabled",
                                  defaultMessage: "Digest notifications are not available in inbox",
                                })}
                              >
                                <HStack gap={2}>
                                  <Icon as={CancelIcon} color="gray.400" />
                                  <FormattedMessage
                                    defaultMessage="Inbox"
                                    description="Label for checkbox that toggles Inbox notifications for a type"
                                    id="notification.settings.inbox.label"
                                  />
                                </HStack>
                              </Tooltip>
                            )}
                          </GridItem>
                          {/**
                           * Show the frequency column for digest notifications. Due to DB schema keeping 1 row per
                           * setting and channel, but there today being only 1 frequency per notification type, we
                           * update the frequency of both the email and slack settings at the same time. This is
                           * somewhat of a hack, but it isn't worth migrating the DB schema since we might as well
                           * support a different frequency per channel in the future.
                           */}
                          {digestNotifications.includes(type) && (
                            <GridItem colStart={frequencyStart} colEnd={frequencyEnd} minW="7em">
                              <Select<FrequencyOption, false, never>
                                size="sm"
                                options={frequencyOptions}
                                value={frequencyOptions.find((option) => option.value === setting?.frequency)}
                                onChange={async (option) => {
                                  if (!option) {
                                    return
                                  }
                                  const emailEnabled =
                                    (type === "tool_digest" && checkedEmailItems.tool.get(type)) ||
                                    (type === "vendor_digest" && checkedEmailItems.vendor.get(type))
                                  const slackEnabled =
                                    checkedSlackItems &&
                                    ((type === "tool_digest" && checkedSlackItems.tool.get(type)) ||
                                      (type === "vendor_digest" && checkedSlackItems.vendor.get(type)))
                                  await updateNotifications([
                                    {
                                      type,
                                      channel: "email" as const,
                                      frequency: option.value,
                                      enabled: emailEnabled,
                                    },
                                    {
                                      type,
                                      channel: "slack" as const,
                                      frequency: option.value,
                                      enabled: slackEnabled,
                                    },
                                  ])
                                }}
                              ></Select>
                            </GridItem>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </>
                ))}
              </React.Fragment>
            )
          })}
        </Grid>
      </Stack>
    </>
  )
}
