import type { TimePeriod } from "@brm/schema-types/types.js"
import { safeBig } from "@brm/util/currency/parse.js"
import { Button, Heading } from "@chakra-ui/react"
import Big from "big.js"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetVendorV1ByIdSpendBreakdownQuery } from "../../../app/services/generated-api.js"
import { FormattedCurrency } from "../../../components/FormattedCurrency.js"
import { Link } from "../../../components/Link.js"
import { DEFAULT_CURRENCY } from "../../../util/currency.js"
import PieChartCard from "../../charts/PieChartCard.js"
import { type OptionWithLabel, type PieDatum } from "../../charts/types.js"
import { displayTimePeriod, getChartTimePeriodDateParams, spendChartTimePeriodOptions } from "../../charts/utils.js"

interface Props {
  vendorId: string
}

export default function VendorToolSpendPie({ vendorId }: Props) {
  const intl = useIntl()

  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriod>("last_twelve_months")
  const dateParams = getChartTimePeriodDateParams(selectedPeriod)

  const { data } = useGetVendorV1ByIdSpendBreakdownQuery({
    id: vendorId,
    currencyCode: DEFAULT_CURRENCY,
    startDate: dateParams.startDate,
    endDate: dateParams.endDate,
  })

  const { pieData, totalSpend } = useMemo(() => {
    if (!data) {
      return { pieData: [], totalSpend: Big(0) }
    }

    const { tool_spend, non_tool_spend } = data

    const totalSpend = tool_spend.reduce(
      (sum, { spend }) => sum.add(Big(spend.amount)),
      (non_tool_spend && Big(non_tool_spend.amount)) ?? Big(0)
    )
    const nonToolSpendDisplay = intl.formatMessage({
      defaultMessage: "Non-tool spend",
      id: "vendor.toolSpend.nonTool",
      description: "Non-tool spend",
    })
    const pieData: PieDatum[] = tool_spend.map((d) => ({
      id: d.tool.id,
      label: d.tool.display_name,
      value: safeBig(d.spend.amount)?.toNumber() ?? 0,
    }))

    if (non_tool_spend) {
      pieData.push({
        id: "non_tool",
        label: nonToolSpendDisplay,
        value: safeBig(non_tool_spend.amount)?.toNumber() ?? 0,
      })
    }

    return { pieData, totalSpend }
  }, [data, intl])

  // If there is not more than one data point for the last twelve months, don't show the chart
  if (totalSpend.eq(0) || (selectedPeriod === "last_twelve_months" && pieData.length < 2)) {
    return null
  }

  return (
    <PieChartCard<OptionWithLabel<TimePeriod>>
      pieProps={{
        data: pieData,
        totalValue: totalSpend,
      }}
      title={intl.formatMessage({
        id: "vendor.toolSpend.title",
        description: "Title for the vendor spend by tool breakdown pie chart",
        defaultMessage: "Spend by Tool",
      })}
      description={
        <Heading size="md" color="gray.900">
          <FormattedCurrency currencyAmount={{ currency_code: DEFAULT_CURRENCY, amount: totalSpend.toString() }} />
        </Heading>
      }
      selectProps={{
        options: spendChartTimePeriodOptions(),
        value: { value: selectedPeriod, label: displayTimePeriod(selectedPeriod) },
        onChange: (newSelection) => {
          if (newSelection) {
            setSelectedPeriod(newSelection.value)
          }
        },
      }}
      footer={
        <Button as={Link} variant="outline" to="../tools" fontSize="sm">
          <FormattedMessage
            id="vendor.toolSpend.viewAllTools"
            description="View all tools"
            defaultMessage="View all tools"
          />
        </Button>
      }
    />
  )
}
