import type { VendorItStatus } from "@brm/schema-types/types.js"
import { VendorItStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayVendorItStatus, vendorItStatusColors } from "./constants.js"

interface Props {
  status: VendorItStatus | undefined
  /** If editing is enabled, a vendorId needs to be provided */
  editVendorId?: string
}

export default function VendorItStatusBadge({ status, editVendorId }: Props) {
  if (!status) {
    return undefined
  }

  return (
    <UpdateStatusBadge<VendorItStatus, "it_status">
      status={status}
      fieldName="it_status"
      objectType="Vendor"
      objectId={editVendorId}
      options={VendorItStatusSchema.anyOf.map((s) => s.const)}
      statusColors={vendorItStatusColors}
      displayStatus={displayVendorItStatus}
      updatePermissions={["vendor:update", "it:update"]}
    />
  )
}
