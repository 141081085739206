import type { InputProps, StyleProps } from "@chakra-ui/react"
import { Center, FormControl, FormLabel, Grid, Input } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

// TODO support currency input and duration as well
interface InputRangeItemProps extends InputProps {
  label?: JSX.Element
}

interface InputRangeProps extends StyleProps {
  leftInputProps: InputRangeItemProps
  rightInputProps: InputRangeItemProps
}

const enDash = "–"

export function InputRange({
  leftInputProps: { label: labelLeft, ...leftRest },
  rightInputProps: { label: labelRight, ...rightRest },
  ...props
}: InputRangeProps) {
  return (
    <Grid gridTemplateRows="auto auto" gridTemplateColumns="1fr auto 1fr" gridColumnGap={2} {...props}>
      <FormControl display="contents">
        <FormLabel gridColumn={1}>
          {labelLeft ?? (
            <FormattedMessage
              id="menuItem.inputRange.minValue"
              description="The label for the input for the minimum value of the number range filter"
              defaultMessage="Minimum"
            />
          )}
        </FormLabel>
        <Input {...leftRest} gridRow={2} gridColumn={1} />
      </FormControl>
      <Center gridRow={2} gridColumn={2}>
        {enDash}
      </Center>
      <FormControl display="contents">
        <FormLabel gridRow={1} gridColumn={3}>
          {labelRight ?? (
            <FormattedMessage
              id="menuItem.inputRange.maxValue"
              description="The label for the input for the maximum value of the number range filter"
              defaultMessage="Maximum"
            />
          )}
        </FormLabel>
        <Input {...rightRest} gridRow={2} gridColumn={3} />
      </FormControl>
    </Grid>
  )
}
