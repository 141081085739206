import { Editor, Point, Range, Element as SlateElement, Transforms } from "slate"
import type { CustomEditor, CustomElementType } from "../types.js"
import { isListType } from "./rich-text.js"
import type { BulletedListElement, NumberedListElement } from "@brm/schema-types/types.js"

/** Markdown conversion formatting */
const SHORTCUTS: Record<string, CustomElementType> = {
  "*": "bulleted-list",
  "-": "bulleted-list",
  "+": "bulleted-list",
  "1.": "numbered-list",
  ">": "block-quote",
  "#": "heading-one",
  "##": "heading-two",
}
export const withShortcuts = (editor: CustomEditor) => {
  const { deleteBackward, insertText, insertBreak } = editor

  editor.insertText = (text) => {
    const { selection } = editor

    if (text.endsWith(" ") && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range) + text.slice(0, -1)
      const type = SHORTCUTS[beforeText]

      if (type) {
        Transforms.select(editor, range)

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor)
        }

        const newProperties: Partial<SlateElement> = {
          // If the type is a list, we will be inserting a list item and wrap it later with the list container
          type: isListType(type) ? "list-item" : type,
        }
        Transforms.setNodes<SlateElement>(editor, newProperties, {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        })

        if (type === "bulleted-list") {
          const list: BulletedListElement = {
            type: "bulleted-list",
            children: [],
          }
          Transforms.wrapNodes(editor, list, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "list-item",
          })
        } else if (type === "numbered-list") {
          const list: NumberedListElement = {
            type: "numbered-list",
            children: [],
          }
          Transforms.wrapNodes(editor, list, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "list-item",
          })
        }

        return
      }
    }

    insertText(text)
  }

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          block.type !== "paragraph" &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties: Partial<SlateElement> = {
            type: "paragraph",
          }
          Transforms.setNodes(editor, newProperties)

          if (block.type === "list-item") {
            Transforms.unwrapNodes(editor, {
              match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && isListType(n.type),
              split: true,
            })
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection) {
      // Check if the new line is being inserted below a list item
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) && n.type === "list-item",
      })
      if (match) {
        const [block] = match
        if (block.children.length === 1) {
          const [child] = block.children
          if (child && "text" in child && child.text === "") {
            // Check if the list item above is empty, then convert it to a paragraph and unwrap from the list container
            const newProperties: Partial<SlateElement> = {
              type: "paragraph",
            }
            Transforms.setNodes(editor, newProperties)
            Transforms.unwrapNodes(editor, {
              match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && isListType(n.type),
              split: true,
            })
            // Don't execute normal line break insertion
            return
          }
        }
      }
    }
    insertBreak()
  }

  return editor
}
