import type { VendorDetails, VendorUserReport } from "@brm/schema-types/types.js"
import { VendorUserReportColumnSchema } from "@brm/schemas"
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostVendorListingV1ByIdReportDataMutation } from "../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { log } from "../../util/logger.js"
import { validateArrayNotEmpty } from "../../util/validation.js"

interface Props extends UseModalProps {
  vendor: VendorDetails
}

export function FlagVendorModal({ vendor, ...modalProps }: Props) {
  const intl = useIntl()
  const toast = useToast()

  const form = useForm<VendorUserReport>({ defaultValues: { report_columns: [], report_body: "" } })

  const [createBadDataReport, createBadDataReportResult] = usePostVendorListingV1ByIdReportDataMutation()

  const submit = async (formValues: VendorUserReport) => {
    try {
      await createBadDataReport({
        id: vendor.vendor_listing_id,
        vendorUserReport: {
          report_body: formValues.report_body || undefined,
          report_columns: formValues.report_columns,
        },
      }).unwrap()

      toast({
        description: intl.formatMessage({
          id: "vendor.flagged.toast.title",
          description: "The title of the toast message when a vendor is flagged",
          defaultMessage: "Report sent",
        }),
        status: "success",
      })
      modalProps.onClose()
    } catch (err) {
      log.error("Error flagging vendor", err, { vendor })
      const title =
        getAPIErrorMessage(err) ||
        intl.formatMessage({
          id: "vendor.flagged.toast.error",
          description: "The title of the toast message when a vendor flagging fails",
          defaultMessage: "Failed to send report",
        })
      toast({ status: "error", title })
    }
  }

  const isOtherChecked = form.watch("report_columns").includes("other")
  return (
    <Modal {...modalProps} size="md">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={form.handleSubmit(submit)}>
        <ModalHeader>
          <FormattedMessage
            id="flag.vendor.title"
            description="The title of the flag vendor modal"
            defaultMessage="Report bad data"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Text>
            <FormattedMessage
              id="flag.vendor.description"
              description="The description of the flag vendor modal"
              defaultMessage="Sometimes there’s an error in the data BRM displays. Let us know which fields need to be fixed for {vendorName}"
              values={{ vendorName: vendor.display_name }}
            />
          </Text>

          <Controller
            name="report_columns"
            control={form.control}
            rules={{
              validate: (value) =>
                validateArrayNotEmpty(
                  value,
                  intl.formatMessage({
                    id: "flag.vendor.error",
                    defaultMessage: "At least one field must be selected",
                    description: "The error message when no fields are selected in the flag vendor modal",
                  })
                ),
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <Stack>
                  {VendorUserReportColumnSchema.anyOf.map((column) => (
                    <Checkbox
                      key={column.const}
                      onChange={(e) => {
                        if (e.target.checked) {
                          field.onChange([...field.value, column.const])
                        } else {
                          field.onChange(field.value.filter((value) => value !== column.const))
                        }
                      }}
                    >
                      {column.title}
                    </Checkbox>
                  ))}
                </Stack>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Controller
            name="report_body"
            control={form.control}
            rules={{ required: isOtherChecked }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid} isRequired={isOtherChecked}>
                <FormLabel>
                  {isOtherChecked ? (
                    <FormattedMessage
                      id="flag.vendor.suggestions.label"
                      description="The label of the other suggestions input in the flag vendor modal when it’s required"
                      defaultMessage="Other suggestions"
                    />
                  ) : (
                    <FormattedMessage
                      id="flag.vendor.suggestions.label.optional"
                      description="The label of the other suggestions input in the flag vendor modal when it’s optional"
                      defaultMessage="Other suggestions (optional)"
                    />
                  )}
                </FormLabel>
                <Textarea
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={intl.formatMessage({
                    id: "flag.vendor.suggestions.placeholder",
                    description: "The placeholder of the other suggestions input in the flag vendor modal",
                    defaultMessage: "Enter a description of the issue...",
                  })}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </ModalBody>
        <ModalFooter gap={2}>
          <Button type="button" onClick={modalProps.onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="flag.vendor.cancel"
              description="The label of the cancel button in the flag vendor modal"
            />
          </Button>
          <Button type="submit" colorScheme="brand" isLoading={createBadDataReportResult.isLoading}>
            <FormattedMessage
              id="flag.vendor.send"
              description="The label of the send button in the flag vendor modal"
              defaultMessage="Send to BRM"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
