import { formatDate, formatDateRange } from "@brm/util/format-date-time.js"
import type { ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"

type Props =
  | {
      startDate?: string | null
      endDate?: string | null
      /**
       * The label to display when the date range does not have any fields set that can be used to display it. Render this default label value instead.
       */
      defaultLabel: ReactNode
    }
  | {
      startDate?: string | null
      endDate: string | null
      defaultLabel?: never
    }
  | {
      startDate: string | null
      endDate?: string | null
      defaultLabel?: never
    }

export default function DateRange({ startDate, endDate, defaultLabel }: Props): ReactNode {
  const intl = useIntl()
  return startDate && endDate ? (
    formatDateRange(intl, startDate, endDate)
  ) : startDate ? (
    <FormattedMessage
      id="dateRange.startDate"
      description="The start date in a date range"
      defaultMessage="Since {startDate}"
      values={{ startDate: formatDate(intl, startDate) }}
    />
  ) : endDate ? (
    <FormattedMessage
      id="dateRange.endDate"
      description="The end date in a date range"
      defaultMessage="Until {endDate}"
      values={{ endDate: formatDate(intl, endDate) }}
    />
  ) : (
    defaultLabel
  )
}
