import {
  Flex,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  type FlexProps,
} from "@chakra-ui/react"
import { useRef, useState, type ReactElement } from "react"
import { useIntl } from "react-intl"
import { useSlate } from "slate-react"
import {
  BoldIcon,
  BulletedListIcon,
  HeadingIcon,
  ItalicIcon,
  LinkIcon,
  NumberedListIcon,
  QuoteIcon,
  UnderlineIcon,
} from "../icons/icons.js"
import type { CustomElementType, CustomTextMark } from "./types.js"
import { insertLink, isLinkActive } from "./util/inline.js"
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from "./util/rich-text.js"

interface Props extends FlexProps {
  additionalButtons?: React.ReactNode
}

const buttonSize = "xs" as const

export default function EditorToolbar(props: Props) {
  const { additionalButtons, ...flexProps } = props
  const intl = useIntl()
  return (
    // Note: The gap is required to keep a 24px area per button despite the xs size as per https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum
    <Flex alignItems="center" gap={4} height={6} onMouseDown={(event) => event.preventDefault()} {...flexProps}>
      <Flex alignItems="center" gap={4} height={6} overflowY="auto" sx={{ scrollbarWidth: "none" }}>
        <MarkButton
          format="bold"
          icon={<Icon as={BoldIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.bold",
            description: "Label for the bold button in the editor toolbar",
            defaultMessage: "Bold",
          })}
        />
        <MarkButton
          format="italic"
          icon={<Icon as={ItalicIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.italic",
            description: "Label for the italic button in the editor toolbar",
            defaultMessage: "Italic",
          })}
        />
        <MarkButton
          format="underline"
          icon={<Icon as={UnderlineIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.underline",
            description: "Label for the underline button in the editor toolbar",
            defaultMessage: "Underline",
          })}
        />
        <AddLinkButton />
        <BlockButton
          format="heading-one"
          icon={<Icon as={HeadingIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.heading",
            description: "Label for the heading button in the editor toolbar",
            defaultMessage: "Heading",
          })}
        />
        <BlockButton
          format="block-quote"
          icon={<Icon as={QuoteIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.quote",
            description: "Label for the quote button in the editor toolbar",
            defaultMessage: "Quote",
          })}
        />
        <BlockButton
          format="bulleted-list"
          icon={<Icon as={BulletedListIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.bulleted-list",
            description: "Label for the bulleted list button in the editor toolbar",
            defaultMessage: "Bulleted List",
          })}
        />
        <BlockButton
          format="numbered-list"
          icon={<Icon as={NumberedListIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.numbered-list",
            description: "Label for the numbered list button in the editor toolbar",
            defaultMessage: "Numbered List",
          })}
        />
      </Flex>
      {additionalButtons}
    </Flex>
  )
}

const MarkButton = ({ format, icon, label }: { format: CustomTextMark; icon: ReactElement; label: string }) => {
  const editor = useSlate()
  return (
    <IconButton
      isActive={isMarkActive(editor, format)}
      onClick={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      variant="link"
      size={buttonSize}
      icon={icon}
      aria-label={label}
    />
  )
}

const BlockButton = ({ format, icon, label }: { format: CustomElementType; icon: ReactElement; label: string }) => {
  const editor = useSlate()
  return (
    <IconButton
      isActive={isBlockActive(editor, format)}
      size={buttonSize}
      variant="link"
      onClick={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      icon={icon}
      aria-label={label}
    />
  )
}

const AddLinkButton = () => {
  const intl = useIntl()
  const editor = useSlate()
  const inputRef = useRef<HTMLInputElement>(null)
  const popoverDisclosure = useDisclosure()
  const [url, setUrl] = useState("")
  return (
    <Popover initialFocusRef={inputRef} {...popoverDisclosure}>
      <PopoverTrigger>
        <IconButton
          size={buttonSize}
          variant="link"
          isActive={isLinkActive(editor)}
          icon={<Icon as={LinkIcon} />}
          aria-label={intl.formatMessage({
            id: "editor.toolbar.link",
            description: "Label for the link button in the editor toolbar",
            defaultMessage: "Link",
          })}
        />
      </PopoverTrigger>
      <PopoverContent width="20ch">
        <Input
          size="sm"
          ref={inputRef}
          placeholder={intl.formatMessage({
            id: "editor.link.placeholder",
            description: "Placeholder in input url when user is adding a link to an editor input",
            defaultMessage: "Enter link URL",
          })}
          type="url"
          value={url}
          onChange={(event) => setUrl(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault()
              const url = event.currentTarget.value
              if (url) {
                insertLink(editor, url)
                setUrl("")
              }
              popoverDisclosure.onClose()
            }
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
