import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useStore } from "react-redux"
import { brmApi as api } from "../../app/services/brm-api.js"
import type { UserWithOrganization } from "../../app/services/generated-api.js"
import { useGetUserV1LogoutMutation } from "../../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { Link } from "../Link.js"
import { HelpIcon, LogoutIcon, SettingsIcon, UserIcon } from "../icons/icons.js"

interface Props {
  user?: UserWithOrganization
  hideSettings?: boolean
}

export default function ProfileMenu({ user, hideSettings }: Props) {
  const intl = useIntl()
  const toast = useToast()
  const store = useStore()

  const [logout] = useGetUserV1LogoutMutation()

  const { first_name: firstName, last_name: lastName, email, profile_image: profileImage } = user || {}
  return (
    <Menu placement="top-end" autoSelect={false} isLazy>
      <MenuButton
        as={Button}
        variant="unstyled"
        borderRadius="100%"
        aria-label={intl.formatMessage({
          id: "userMenu.button.label",
          description: "Label for the user menu button",
          defaultMessage: "User menu",
        })}
      >
        <Avatar size="md" src={getPublicImageGcsUrl(profileImage?.gcs_file_name)} icon={<Icon as={UserIcon} />} />
      </MenuButton>
      <MenuList>
        <Box p={3}>
          <HStack>
            <Avatar src={getPublicImageGcsUrl(profileImage?.gcs_file_name)} icon={<Icon as={UserIcon} />} />
            <Flex direction="column">
              <Text fontWeight="medium">
                <FormattedMessage
                  id="table.cell.user.name"
                  description="formatted first and last name"
                  defaultMessage="{firstName} {lastName}"
                  values={{ firstName, lastName }}
                />
              </Text>
              <Text color="muted">{email}</Text>
            </Flex>
          </HStack>
        </Box>
        <Divider />
        {!hideSettings && (
          <MenuGroup>
            <MenuItem p={3} icon={<Icon as={SettingsIcon} />} as={Link} to="/settings/users">
              <FormattedMessage
                id="nav.menu.settings"
                description="Navigation menu item link to settings page"
                defaultMessage="Settings"
              />
            </MenuItem>
          </MenuGroup>
        )}
        <Divider />
        <MenuGroup>
          <MenuItem onClick={() => window.Pylon?.("show")} p={3} icon={<Icon as={HelpIcon} />}>
            <FormattedMessage
              id="nav.menu.help"
              description="Navigation menu item link to contact support for help"
              defaultMessage="Feedback & Help"
            />
          </MenuItem>
        </MenuGroup>
        <Divider />
        <MenuGroup>
          <MenuItem
            as={Link}
            p={3}
            icon={<Icon as={LogoutIcon} />}
            onClick={async () => {
              const logoutResult = await logout().unwrap()

              if (logoutResult.status === "success") {
                localStorage.clear()
                store.dispatch(api.util.resetApiState())
                // Use a hard navigation to ensure no client state is left behind.
                window.location.href = "/login"
              } else {
                toast({
                  description: intl.formatMessage({
                    id: "logout.toast.error",
                    description: "Failed to log out",
                    defaultMessage: "Logout Failed",
                  }),
                  status: "error",
                })
              }
            }}
          >
            <FormattedMessage
              id="nav.menu.logout"
              description="Navigation menu item link to log out"
              defaultMessage="Log out"
            />
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}
