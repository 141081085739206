import { hasAllPermissions } from "@brm/schema-helpers/role.js"
import type { Permission, ToolDetails } from "@brm/schema-types/types.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  AvatarGroup,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { isString } from "typed-assert"
import {
  useGetToolV1ByIdQuery,
  useGetUserV1WhoamiQuery,
  usePutToolV1ByIdFollowingMutation,
} from "../../app/services/generated-api.js"
import { useContextMenu } from "../../components/ContextMenu/context-menu.js"
import OwnerCell from "../../components/DataTable/CellRenderer/OwnerCell.js"
import VendorCell from "../../components/DataTable/CellRenderer/VendorCell.js"
import { DividedRowSubHeader } from "../../components/DividedRowSubHeader.js"
import { Link } from "../../components/Link.js"
import { LogoHeader } from "../../components/LogoHeader.js"
import ShowHideDrawerButton from "../../components/ShowHideDrawerButton.js"
import TagGroup from "../../components/TagGroup.js"
import SessionStorageTimelineContainer from "../../components/Timeline/SessionStorageTimelineContainer.js"
import { logoHeaderBoxSize } from "../../components/constants.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { ToolLogo } from "../../components/icons/Logo.js"
import {
  FlagIcon,
  LinkExternalIcon,
  MoreMenuIcon,
  NotificationsDisabledIcon,
  NotificationsEnabledIcon,
  UsersIcon,
} from "../../components/icons/icons.js"
import NotificationIconButton from "../../components/icons/system/NotificationIconButton.js"
import { PAGE_PADDING_X } from "../../util/constant.js"
import { filterActiveSubscriptions } from "../../util/legal-agreement.js"
import { getDisplayUrl, getPublicImageGcsUrl } from "../../util/url.js"
import { DuplicateSubscriptionAlert } from "../legal/DuplicateSubscriptionAlert.js"
import TransactionList from "../transaction/TransactionList.js"
import { FlagToolModal } from "./FlagToolModal.js"
import ToolCategory from "./ToolCategory.js"
import ToolOverview from "./ToolOverview.js"
import ToolSecurity from "./ToolSecurity.js"
import ToolUsers from "./ToolUsers.js"
import type { ToolTab } from "./constants.js"
import { TOOL_TIMELINE_HIDE_STORAGE_KEY, isToolTab, toolTabs } from "./constants.js"
import ToolStatusBadge from "./status/ToolStatusBadge.js"

interface ToolDataTab {
  displayName: string
  permissions?: Permission[]
  panel: JSX.Element
}

const MIN_WIDTH = "400px"

export default function Tool() {
  const { toolId, tab } = useParams()
  isString(toolId, "Missing id route param")
  const shownTab: ToolTab = tab && isToolTab(tab) ? tab : "overview"

  const intl = useIntl()
  const toast = useToast()
  const flagDataModal = useDisclosure()
  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps } = useContextMenu<HTMLDivElement>({
    betsyEnabled: true,
  })

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: tool, isError } = useGetToolV1ByIdQuery({ id: toolId })
  const [updateToolFollowing, { isLoading: isFollowingSaving }] = usePutToolV1ByIdFollowingMutation()

  const hasDuplicateSubscriptions = useMemo(
    () => tool && filterActiveSubscriptions(tool.legal_agreements).length > 1,
    [tool]
  )

  const followTool = useCallback(
    async (tool: ToolDetails) => {
      const newFollowState = !tool.following
      try {
        await updateToolFollowing({
          id: tool.id,
          body: { following: newFollowState },
        })
        toast({
          description: newFollowState
            ? intl.formatMessage({
                id: "tool.notification.toggle.enabled",
                description: "Notification toggle enabled toast title",
                defaultMessage: "Tool Followed",
              })
            : intl.formatMessage({
                id: "tool.notification.toggle.disabled",
                description: "Notification toggle disabled toast title",
                defaultMessage: "Tool Unfollowed",
              }),
          status: "success",
        })
      } catch (err) {
        toast({
          description: newFollowState
            ? intl.formatMessage({
                id: "tool.notification.toggle.error.enabled",
                description: "Notification toggle enabled error toast title",
                defaultMessage: "Error following tool",
              })
            : intl.formatMessage({
                id: "tool.notification.toggle.error.disabled",
                description: "Notification toggle disabled error toast title",
                defaultMessage: "Error unfollowing tool",
              }),
          status: "error",
        })
      }
    },
    [intl, toast, updateToolFollowing]
  )

  if (isError) {
    return (
      <Alert colorScheme="error">
        <AlertIcon />
        <AlertDescription>
          <FormattedMessage
            defaultMessage="An error occurred while loading the tool."
            description="Error message when loading a tool."
            id="tool.error"
          />
        </AlertDescription>
      </Alert>
    )
  }

  if (!tool) {
    return null
  }

  const dataByTab: Record<ToolTab, ToolDataTab> = {
    overview: {
      displayName: intl.formatMessage({
        id: "tool.tabs.overview",
        description: "Tool view tab overview info",
        defaultMessage: "Overview",
      }),
      panel: <ToolOverview tool={tool} />,
    },
    users: {
      displayName: intl.formatMessage({
        id: "tool.tabs.users",
        description: "Tool view tab users info",
        defaultMessage: "Users",
      }),
      panel: <ToolUsers tool={tool} />,
    },
    transactions: {
      displayName: intl.formatMessage({
        id: "tool.tabs.transactions",
        description: "Tool view tab transactions",
        defaultMessage: "Transactions",
      }),
      permissions: ["transaction:read"],
      panel: <TransactionList toolId={tool.id} />,
    },
    security: {
      displayName: intl.formatMessage({
        id: "tool.tabs.security",
        description: "Tool view tab security",
        defaultMessage: "Security",
      }),
      permissions: ["login:read"],
      panel: <ToolSecurity tool={tool} />,
    },
    general: {
      displayName: intl.formatMessage({
        id: "tool.tabs.general",
        description: "Tool view tab general",
        defaultMessage: "General",
      }),
      permissions: [],
      panel: <ToolCategory category={undefined} tool={tool} flex={1} minW={MIN_WIDTH} />,
    },
    compliance: {
      displayName: intl.formatMessage({
        id: "tool.tabs.compliance",
        description: "Tool view tab compliance",
        defaultMessage: "Compliance",
      }),
      permissions: ["compliance:update"],
      panel: <ToolCategory category="compliance" tool={tool} flex={1} minW={MIN_WIDTH} />,
    },
    it: {
      displayName: intl.formatMessage({
        id: "tool.tabs.it",
        description: "Tool view tab it",
        defaultMessage: "IT",
      }),
      permissions: ["it:update"],
      panel: <ToolCategory category="it" tool={tool} flex={1} minW={MIN_WIDTH} />,
    },
    finance: {
      displayName: intl.formatMessage({
        id: "tool.tabs.finance",
        description: "Tool view tab finance",
        defaultMessage: "Finance",
      }),
      permissions: ["finance:update"],
      panel: <ToolCategory category="finance" tool={tool} flex={1} minW={MIN_WIDTH} />,
    },
  }

  const availableTabs =
    tool.owner && tool.owner.user_id === whoami?.id
      ? toolTabs
      : toolTabs.filter((tab) => {
          const permissions = dataByTab[tab].permissions
          return permissions ? hasAllPermissions(whoami?.roles, permissions) : true
        })
  return (
    <Flex flexDirection="column" gap={2} flexGrow={1} minHeight={0} {...subjectProps.baseProps}>
      <LogoHeader
        logoElement={
          <ToolLogo logo={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)} boxSize={logoHeaderBoxSize} />
        }
        heading={
          <HStack>
            <Heading size="md" display="flex" flexShrink={1} minW={0}>
              <Text as="span" isTruncated>
                {tool.display_name}
              </Text>
            </Heading>
            <Flex flexShrink={0}>
              <ToolStatusBadge status={tool.status} editToolId={toolId} />
            </Flex>
          </HStack>
        }
        rightActions={
          <>
            <Menu>
              <MenuButton as={IconButton} variant="outline" icon={<Icon as={MoreMenuIcon} />} />
              <MenuList>
                <MenuItem onClick={flagDataModal.onOpen}>
                  <FormattedMessage
                    id="tool.report.bad.data"
                    description="Report bad data menu item"
                    defaultMessage="Report bad data"
                  />
                </MenuItem>
              </MenuList>
            </Menu>
            <NotificationIconButton
              variant="outline"
              notificationEnabled={tool.following}
              isDisabled={isFollowingSaving}
              onClick={() => followTool(tool)}
            />
            <ShowHideDrawerButton
              hideStorageKey={TOOL_TIMELINE_HIDE_STORAGE_KEY}
              size="md"
              label={intl.formatMessage({
                id: "timeline.showHide.tooltip",
                description: "Label shown on button that can toggle showing/hiding a timeline sidebar",
                defaultMessage: "Show/Hide Activity",
              })}
            />
            {flagDataModal.isOpen && <FlagToolModal {...flagDataModal} tool={tool} />}
          </>
        }
      >
        <HStack justifyContent="space-between" flexGrow={1}>
          <Stack gap={4} flexGrow={1}>
            {tool.description && (
              <Box width="70%">
                <Text>{tool.description}</Text>
              </Box>
            )}
            <DividedRowSubHeader>
              <OwnerCell owner={tool.owner} objectType="Tool" objectId={tool.id} showTooltip tooltipOpenDelay={0} />
              <VendorCell vendor={tool.vendor} flexGrow={0} showTooltip={true} />
              {tool.categories && tool.categories.length > 0 ? (
                <TagGroup
                  displayNames={tool.categories.filter((category) => !category.parent_id).map((c) => c.display_name)}
                  limit={1}
                />
              ) : null}
              <HStack as={Link} to="../users">
                <Icon as={UsersIcon} />
                <Text as="span" isTruncated>
                  <FormattedMessage
                    id="tool.users"
                    description="Tool header users label"
                    defaultMessage="{toolUsersCount} {toolUsersCount, plural, one {user} other {users}}"
                    values={{ toolUsersCount: tool.total_people }}
                  />
                </Text>
              </HStack>
              {tool.integrations.length > 0 ? (
                <HStack as={Link} to="/settings/integrations">
                  <FormattedMessage
                    id="integration.list"
                    description="Description field for the integrations an entity has sources from"
                    defaultMessage="Integrations:"
                  />
                  <AvatarGroup spacing={-1}>
                    {tool.integrations.map((integration) => (
                      <Avatar
                        key={integration.id}
                        icon={<IntegrationProviderIcon boxSize="1.5rem" integration={integration} />}
                        backgroundColor="white"
                      />
                    ))}
                  </AvatarGroup>
                </HStack>
              ) : null}
              {tool.website ? (
                <Link variant="highlighted" to={tool.website}>
                  <HStack gap={1}>
                    <Box flexGrow={1} maxWidth={250} overflow="hidden">
                      <Text noOfLines={1}>{getDisplayUrl(new URL(tool.website))}</Text>
                    </Box>
                    <Icon as={LinkExternalIcon} flexShrink={0} />
                  </HStack>
                </Link>
              ) : null}
            </DividedRowSubHeader>
          </Stack>
        </HStack>
      </LogoHeader>

      <Flex flex={1} alignItems="start" minHeight={0}>
        <Tabs
          isLazy
          isFitted
          variant="line"
          display="flex"
          flexDir="column"
          index={availableTabs.indexOf(shownTab)}
          pt={1}
          flex={1}
          minHeight={0}
          height="100%"
          overflowY="auto"
        >
          <Grid
            height="100%"
            width="100%"
            templateColumns="[tabs] minmax(0, 1fr) [activity] auto"
            templateRows="[title] auto [content] minmax(0, 1fr)"
            columnGap={0}
            rowGap={0}
          >
            <GridItem zIndex={1}>
              <TabList>
                {availableTabs.map((tab) => (
                  <Tab key={tab} as={Link} to={`../${tab}`} relative="path">
                    {dataByTab[tab].displayName}
                  </Tab>
                ))}
              </TabList>
            </GridItem>
            <GridItem zIndex={0} borderBottomWidth="1px" />
            <GridItem>
              <TabPanels minW={600} height="100%" overflow="auto">
                {availableTabs.map((tab) => (
                  <TabPanel key={tab} pl={PAGE_PADDING_X}>
                    {dataByTab[tab].panel}
                  </TabPanel>
                ))}
              </TabPanels>
            </GridItem>
            <GridItem>
              <SessionStorageTimelineContainer
                hideStorageKey={TOOL_TIMELINE_HIDE_STORAGE_KEY}
                timelineProps={{
                  filterParams: { toolId: tool.id },
                  alerts: hasDuplicateSubscriptions && (
                    <DuplicateSubscriptionAlert toolOrVendorName={tool.display_name} />
                  ),
                }}
                borderLeftWidth="1px"
                backgroundColor="white"
              />
            </GridItem>
          </Grid>
        </Tabs>
      </Flex>
      <Portal>
        <Menu {...menuProps}>
          <MenuList {...menuListProps}>
            {menuListProps.children}
            <MenuItem
              {...menuItemProps}
              onClick={() => followTool(tool)}
              icon={<Icon as={tool.following ? NotificationsEnabledIcon : NotificationsDisabledIcon} />}
            >
              {tool.following ? (
                <FormattedMessage
                  defaultMessage="Unfollow tool"
                  description="Label for the menu item tool unfollow button"
                  id="context-menu.item.unfollow-tool"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Follow tool"
                  description="Label for the menu item tool unfollow button"
                  id="context-menu.item.follow-tool"
                />
              )}
            </MenuItem>
            <MenuItem {...menuItemProps} onClick={flagDataModal.onOpen} icon={<Icon as={FlagIcon} color="red.700" />}>
              <FormattedMessage
                id="context-menu.item.report-bad-data"
                description="Report bad data context menu item"
                defaultMessage="Report bad data"
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </Portal>
      {betsyProps?.BetsyModal}
    </Flex>
  )
}
