import type { ToolComplianceStatus } from "@brm/schema-types/types.js"
import { ToolComplianceStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayToolComplianceStatus, toolComplianceColors } from "./constants.js"

interface Props {
  status: ToolComplianceStatus | undefined
  /** If editing is enabled, a toolId needs to be provided */
  editToolId: string
}

export default function ToolComplianceStatusBadge({ status, editToolId }: Props) {
  if (!status) {
    return undefined
  }
  return (
    <UpdateStatusBadge<ToolComplianceStatus, "compliance_status">
      status={status}
      fieldName="compliance_status"
      objectType="Tool"
      objectId={editToolId}
      options={ToolComplianceStatusSchema.anyOf.map((s) => s.const)}
      statusColors={toolComplianceColors}
      displayStatus={displayToolComplianceStatus}
      updatePermissions={["tool:update", "compliance:update"]}
    />
  )
}
