import { Flex } from "@chakra-ui/react"
import type { ReactNode } from "react"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../util/constant.js"

interface Props {
  children: ReactNode
}

export default function PageWrapper({ children }: Props) {
  return (
    <Flex py={PAGE_PADDING_Y} px={PAGE_PADDING_X} minHeight={0} direction="column" grow={1}>
      {children}
    </Flex>
  )
}
