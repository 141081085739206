/**
 * Formats a URL for display purposes without the protocol.
 */
export function getDisplayUrl(url: Readonly<URL>): string {
  return url.host + (url.pathname !== "/" ? url.pathname : "") + url.search + url.hash
}

export function getPublicImageGcsUrl(gcsFileName?: string | null) {
  if (!gcsFileName) {
    return undefined
  }
  const publicAssetsBucket = import.meta.env.VITE_PUBLIC_ASSETS_BUCKET

  return `https://${publicAssetsBucket}.storage.googleapis.com/${gcsFileName}`
}

export function prependHttps(url: string): string {
  return url.startsWith("http") ? url : `https://${url}`
}
