import { Box, Container, Flex, HStack } from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import BrmLogo from "../BrmLogo.js"
import ProfileMenu from "../Nav/ProfileMenu.js"
import { HEADER_HEIGHT, NAVBAR_Z_INDEX } from "../Nav/constants.js"
import FixedHeightContainer from "./FixedHeightContainer.js"

const SellerContainer: FunctionComponent = () => {
  const { data: whoami } = useGetUserV1WhoamiQuery()

  return (
    <>
      <Box as="nav" bgColor="white" position="sticky" top={0} zIndex={NAVBAR_Z_INDEX} height={`${HEADER_HEIGHT}px`}>
        <Container py={3} px={6} maxWidth="100%" borderBottom="1px solid #EAECF0">
          <HStack justifyContent="space-between">
            <BrmLogo />
            <ProfileMenu user={whoami} hideSettings />
          </HStack>
        </Container>
      </Box>
      <Flex flexGrow={1} minHeight={0} direction="column">
        <FixedHeightContainer />
      </Flex>
    </>
  )
}

export default SellerContainer
