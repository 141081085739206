import type { WorkflowKind } from "@brm/schema-types/types.js"
import type { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import { isString } from "typed-assert"
import { useObjectSchema } from "../../../util/use-schema.js"
import WorkflowDefinitionForm from "./WorkflowDefinitionForm.js"

const WorkflowDefinitionCreatePage: FunctionComponent = () => {
  const { kind } = useParams<{ kind: WorkflowKind }>()
  isString(kind, "Missing workflow definition kind")

  const toolSchema = useObjectSchema("Tool")
  if (!toolSchema) {
    return null
  }

  return <WorkflowDefinitionForm workflowKind={kind} toolSchema={toolSchema} />
}

export default WorkflowDefinitionCreatePage
