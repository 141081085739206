import { isDocumentOrURLStringType, isFrequencyOrOneTimeType } from "@brm/schema-helpers/schema.js"
import { DateDurationString } from "@brm/schemas"
import {
  isBooleanConditionalSchema,
  isCompliantWithDocumentConditionalSchema,
  isCurrencyAmountConditionalSchema,
  isDateStringConditionalSchema,
  isDurationStringConditionalSchema,
  isEnumArrayConditionalSchema,
  isNumberConditionalSchema,
} from "@brm/type-helpers/conditional-schema.js"
import { isCompliantWithDocumentType, isCurrencyAmountType } from "@brm/type-helpers/schema.js"
import {
  getEnumOptions,
  isBooleanType,
  isEnumArrayType,
  isIntegerType,
  isNotNullSchema,
  isNullSchema,
  isNumberType,
  isStringType,
} from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Button,
  GridItem,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Select } from "chakra-react-select"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { log } from "../../util/logger.js"
import { BooleanSelect } from "../Form/BooleanSelect.js"
import { CurrencyAmountInputGroup } from "../Form/CurrencyAmountInput.js"
import { DATE_DURATION_UNITS, DATE_TIME_DURATION_UNITS } from "../Form/duration-unit.js"
import { DurationInputGroup } from "../Form/DurationInput.js"
import { AlertIcon } from "../icons/icons.js"
import { DatePickerInput } from "./DatePicker.js"

export interface SchemaComparatorProps {
  conditionalSchema: ReadonlyDeep<JSONSchemaObject>
  referenceSchema: ReadonlyDeep<JSONSchema>
  onChange: (value: ReadonlyDeep<JSONSchemaObject>) => void
}

const onChangeMock = () => (_: unknown) => {}
export function SchemaComparator(props: SchemaComparatorProps) {
  const { conditionalSchema, referenceSchema, onChange } = props
  const intl = useIntl()

  if (!isObject(referenceSchema)) {
    return null
  }

  if (isNullSchema(conditionalSchema) || isNotNullSchema(conditionalSchema)) {
    return (
      <>
        <GridItem colSpan={1}>
          <IsOrIsNotComparator
            value={{ value: isNullSchema(conditionalSchema) }}
            onChange={(newValue) => {
              if (!newValue.value) {
                onChange({ not: { const: null } })
              } else {
                onChange({ const: null })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Input
            value={intl.formatMessage({
              defaultMessage: "Empty",
              id: "condition.value.empty",
              description: "Condition value describing an empty value",
            })}
            readOnly
          />
        </GridItem>
      </>
    )
  }

  if (isBooleanType(referenceSchema)) {
    if (!isBooleanConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }
    return (
      <>
        <GridItem colSpan={1}>
          <IsOrIsNotComparator value={{ value: true }} onChange={onChangeMock} isReadOnly />
        </GridItem>
        <GridItem colSpan={1}>
          <BooleanSelect
            onChange={(value) => (typeof value === "boolean" ? onChange({ type: "boolean", const: value }) : null)}
            value={conditionalSchema.const}
          />
        </GridItem>
      </>
    )
  }

  if ((isStringType(referenceSchema) || isDocumentOrURLStringType(referenceSchema)) && conditionalSchema.pattern) {
    return (
      <>
        <GridItem colSpan={1}>
          <Input
            value={intl.formatMessage({
              defaultMessage: "contains",
              id: "condition.value.string.contains",
              description: "Condition value describing a string contains pattern",
            })}
            readOnly
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Input
            onChange={(e) => onChange({ type: "string", pattern: e.target.value })}
            value={conditionalSchema.pattern}
          />
        </GridItem>
      </>
    )
  }

  if (
    (isStringType(referenceSchema) && referenceSchema.format === "duration") ||
    isFrequencyOrOneTimeType(referenceSchema)
  ) {
    if (!isDurationStringConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }
    const currValue =
      "formatMinimum" in conditionalSchema ? conditionalSchema.formatMinimum : conditionalSchema.formatMaximum
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "formatMinimum" in conditionalSchema ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "string",
                  format: "duration",
                  formatMaximum: currValue,
                })
              } else {
                onChange({
                  type: "string",
                  format: "duration",
                  formatMinimum: currValue,
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DurationInputGroup
            value={currValue}
            onChange={(newDuration) => {
              if (newDuration) {
                if ("formatMinimum" in conditionalSchema) {
                  onChange({
                    type: "string",
                    format: "duration",
                    formatMinimum: newDuration.toString(),
                  })
                } else {
                  onChange({
                    type: "string",
                    format: "duration",
                    formatMaximum: newDuration.toString(),
                  })
                }
              }
            }}
            units={
              referenceSchema.pattern === DateDurationString.pattern ? DATE_DURATION_UNITS : DATE_TIME_DURATION_UNITS
            }
          />
        </GridItem>
      </>
    )
  }

  if (isStringType(referenceSchema) && referenceSchema.format === "date") {
    if (!isDateStringConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }
    const currValue =
      "formatMinimum" in conditionalSchema ? conditionalSchema.formatMinimum : conditionalSchema.formatMaximum
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "formatMinimum" in conditionalSchema ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "string",
                  format: "date",
                  formatMaximum: currValue,
                })
              } else {
                onChange({
                  type: "string",
                  format: "date",
                  formatMinimum: currValue,
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DatePickerInput
            onChange={(newDate) => {
              if (newDate) {
                if ("formatMinimum" in conditionalSchema) {
                  onChange({
                    type: "string",
                    format: "date",
                    formatMinimum: newDate.toString(),
                  })
                } else {
                  onChange({
                    type: "string",
                    format: "date",
                    formatMaximum: newDate.toString(),
                  })
                }
              }
            }}
            value={currValue}
            ariaLabel={intl.formatMessage({
              id: "form.date.ariaLabel",
              defaultMessage: "Enter date",
              description: "Date input field aria label",
            })}
          />
        </GridItem>
      </>
    )
  }

  if (isIntegerType(referenceSchema) || isNumberType(referenceSchema)) {
    if (!isNumberConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }
    const currValue = "minimum" in conditionalSchema ? conditionalSchema.minimum : conditionalSchema.maximum
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "minimum" in conditionalSchema ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "number",
                  maximum: currValue,
                })
              } else {
                onChange({
                  type: "number",
                  minimum: currValue,
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Input
            type="number"
            value={currValue}
            onChange={(e) => {
              const numberValue = Number(e.target.value)
              if (isNaN(numberValue)) {
                return
              }
              onChange(
                "minimum" in conditionalSchema
                  ? { type: "number", minimum: numberValue }
                  : { type: "number", maximum: numberValue }
              )
            }}
            step={isIntegerType(referenceSchema) ? 1 : undefined}
          />
        </GridItem>
      </>
    )
  }

  if (isEnumArrayType({ schema: referenceSchema })) {
    if (!isEnumArrayConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }

    const options = Array.from(
      getEnumOptions(referenceSchema).map((option) => ({
        label: option.title,
        value: option.const,
      }))
    )
    const enumSchema = "not" in conditionalSchema ? conditionalSchema.not : conditionalSchema
    const value = enumSchema.contains.const
    const selectedOption = options.find((option) => option.value === value)
    return (
      <>
        <GridItem colSpan={1}>
          <ContainsComparator
            value={{ value: "not" in conditionalSchema ? "does_not_contain" : "contains" }}
            onChange={(contains) => {
              if (contains.value === "contains") {
                onChange({ ...enumSchema, type: "array" })
              } else {
                onChange({ type: "array", not: enumSchema })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Select<{ label: string; value: unknown }, false>
            value={selectedOption}
            options={options}
            onChange={(selectedOption) => {
              if (!selectedOption) {
                return
              }
              if ("not" in conditionalSchema) {
                onChange({
                  type: "array",
                  not: {
                    ...conditionalSchema.not,
                    contains: { ...conditionalSchema.not.contains, const: selectedOption.value },
                  },
                })
              } else {
                onChange({
                  ...conditionalSchema,
                  contains: { ...conditionalSchema.contains, const: selectedOption.value },
                  type: "array",
                })
              }
            }}
          />
        </GridItem>
      </>
    )
  }

  if (isCurrencyAmountType(referenceSchema)) {
    if (!isCurrencyAmountConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }
    const currValue =
      "formatMinimum" in conditionalSchema.properties.amount
        ? conditionalSchema.properties.amount.formatMinimum
        : conditionalSchema.properties.amount.formatMaximum
    const currencyValue = {
      amount: currValue,
      currency_code: conditionalSchema.properties.currency_code.const,
    }
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "formatMinimum" in conditionalSchema.properties.amount ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "object",
                  properties: {
                    amount: { formatMaximum: currValue, format: "number", type: "string" },
                    currency_code: { type: "string", const: conditionalSchema.properties.currency_code.const },
                  },
                })
              } else {
                onChange({
                  type: "object",
                  properties: {
                    amount: { formatMinimum: currValue, format: "number", type: "string" },
                    currency_code: { type: "string", const: conditionalSchema.properties.currency_code.const },
                  },
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <CurrencyAmountInputGroup
            value={currencyValue}
            onChange={(newCurrencyAmount) => {
              if (newCurrencyAmount) {
                onChange({
                  type: "object",
                  properties: {
                    amount:
                      "formatMinimum" in conditionalSchema.properties.amount
                        ? { formatMinimum: newCurrencyAmount.amount, format: "number", type: "string" }
                        : { formatMaximum: newCurrencyAmount.amount, format: "number", type: "string" },
                    currency_code: { type: "string", const: newCurrencyAmount.currency_code },
                  },
                })
              }
            }}
          />
        </GridItem>
      </>
    )
  }

  if (isCompliantWithDocumentType(referenceSchema)) {
    if (!isCompliantWithDocumentConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
    }
    return (
      <>
        <GridItem colSpan={1}>
          {/* readOnly field because the value is either true or false, only allow user to toggle that instead of is or is not */}
          <IsOrIsNotComparator value={{ value: true }} onChange={onChangeMock} isReadOnly />
        </GridItem>
        <GridItem colSpan={1}>
          <BooleanSelect
            onChange={(value) =>
              onChange({
                type: "object",
                properties: { compliant: { type: "boolean", const: value } },
              })
            }
            value={conditionalSchema.properties.compliant.const}
          />
        </GridItem>
      </>
    )
  }

  log.error(`Unsupported schema comparator: ${JSON.stringify(referenceSchema)}`, null)
  return <InvalidSchemaEditor schema={conditionalSchema} onChange={onChange} />
}

interface IsOrIsNot {
  value: boolean
}

interface Contains {
  value: "contains" | "does_not_contain"
}

function IsOrIsNotComparator({
  value,
  onChange,
  isReadOnly,
}: {
  value: IsOrIsNot
  onChange: (value: IsOrIsNot) => void
  isReadOnly?: boolean
}) {
  const intl = useIntl()
  return (
    <Select<IsOrIsNot, false>
      value={value}
      options={[{ value: true }, { value: false }]}
      getOptionLabel={(opt) =>
        opt.value
          ? intl.formatMessage({
              defaultMessage: "is",
              id: "condition.comparator.is",
              description: "Condition comparator describing a field being equal to a value",
            })
          : intl.formatMessage({
              defaultMessage: "is not",
              id: "condition.comparator.isNot",
              description: "Condition comparator describing a field being not equal to a value",
            })
      }
      getOptionValue={(opt) => String(opt.value)}
      onChange={(opt) => onChange(opt ?? { value: true })}
      isReadOnly={isReadOnly}
    />
  )
}

function ContainsComparator({ value, onChange }: { value: Contains; onChange: (value: Contains) => void }) {
  const intl = useIntl()
  return (
    <Select<Contains, false>
      value={value}
      options={[{ value: "contains" }, { value: "does_not_contain" }]}
      getOptionLabel={(opt) =>
        opt.value === "contains"
          ? intl.formatMessage({
              defaultMessage: "contains",
              id: "condition.comparator.contains",
              description: "Condition comparator describing a field being equal to a value",
            })
          : intl.formatMessage({
              defaultMessage: "does not contain",
              id: "condition.comparator.doesNotContain",
              description: "Condition comparator describing a field not containing a value",
            })
      }
      onChange={(opt) => onChange(opt ?? { value: "contains" as const })}
    />
  )
}

interface GreaterThanLessThan {
  value: "greaterThan" | "lessThan"
}

function GreaterThanLessThanComparator({
  value,
  onChange,
}: {
  value: GreaterThanLessThan
  onChange: (value: GreaterThanLessThan) => void
}) {
  const intl = useIntl()
  return (
    <Select<GreaterThanLessThan, false>
      value={value}
      options={[{ value: "greaterThan" }, { value: "lessThan" }]}
      getOptionLabel={(opt) =>
        opt.value === "greaterThan"
          ? intl.formatMessage({
              defaultMessage: "is greater than",
              id: "condition.comparator.greaterThan",
              description: "Condition comparator describing a field being greater than a value",
            })
          : intl.formatMessage({
              defaultMessage: "is less than",
              id: "condition.comparator.lessThan",
              description: "Condition comparator describing a field being less than a value",
            })
      }
      getOptionValue={(opt) => opt.value}
      onChange={(opt) => onChange(opt ?? { value: "greaterThan" })}
    />
  )
}

function InvalidSchemaEditor({
  schema,
  onChange,
}: {
  schema: ReadonlyDeep<JSONSchemaObject>
  onChange: (newValue: JSONSchemaObject) => void
}) {
  const popoverDisclosure = useDisclosure()
  const toast = useToast()
  const [editedValue, setEditedValue] = useState<string>(JSON.stringify(schema, null, 2))
  return (
    <GridItem as={HStack} colSpan={2}>
      <Icon as={AlertIcon} color="error.500" />
      <FormattedMessage
        id="schemaForm.invalidSchemaEditor"
        description="Error message shown when an invalid schema is encountered"
        defaultMessage="Invalid schema"
      />
      <Popover {...popoverDisclosure}>
        <PopoverTrigger>
          <Button variant="unstyled">
            <FormattedMessage
              id="schemaForm.invalidSchemaEditor.button"
              description="Button to open popover to edit the json schema manually"
              defaultMessage="Edit JSON"
            />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverHeader>
              <FormattedMessage
                id="schemaForm.invalidSchemaEditor.popover.header"
                description="Header for the popover that allows the user to edit the json schema manually"
                defaultMessage="Manually fix JSON schema"
              />
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Textarea value={editedValue} onChange={(event) => setEditedValue(event.target.value)} rows={15} />
            </PopoverBody>
            <PopoverFooter display="flex" gap={2} justifyContent="end">
              <Button onClick={popoverDisclosure.onClose}>
                <FormattedMessage
                  id="schemaForm.invalidSchemaEditor.button.close"
                  description="Button to close the popover"
                  defaultMessage="Close"
                />
              </Button>
              <Button
                colorScheme="brand"
                onClick={() => {
                  try {
                    onChange(JSON.parse(editedValue))
                  } catch (_) {
                    toast({
                      status: "error",
                      description:
                        "Could not parse edited schema. Please fix JSON and try again. If you need support, contact your BRM administrator.",
                    })
                  }
                }}
              >
                <FormattedMessage
                  id="schemaForm.invalidSchemaEditor.button.save"
                  description="Button to save the schema"
                  defaultMessage="Save"
                />
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </GridItem>
  )
}
