import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import {
  type FieldCategory,
  type ToolDetails,
  type ToolPatchInput,
  type ToolPatchOutput,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import { HStack, Heading, Spacer, Stack, useToast, type StackProps } from "@chakra-ui/react"
import { useCallback, useMemo } from "react"
import type { DefaultValues } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { dynamoTelemetryEnabled } from "../../app/feature-flags.js"
import { usePatchToolV1ByIdMutation } from "../../app/services/generated-api.js"
import SchemaFormPage from "../../components/SchemaForm/SchemaFormPage.js"
import { useObjectPatchSchema, useObjectSchema } from "../../util/use-schema.js"
import DynamoTelemetry from "../dynamo/TelemetryCard.js"
import DynamoTrigger from "../dynamo/TriggerButton.js"
import ToolComplianceStatusBadge from "./status/ToolComplianceStatusBadge.js"
import ToolFinanceStatusBadge from "./status/ToolFinanceStatusBadge.js"
import ToolItStatusBadge from "./status/ToolItStatusBadge.js"

interface Props extends StackProps {
  tool: ToolDetails
  category: FieldCategory | undefined
}

export default function ToolCategory({ tool, category, ...stackProps }: Props) {
  const toast = useToast()
  const intl = useIntl()

  const toolSchema = useObjectSchema("Tool")
  const toolPatchSchema = useObjectPatchSchema("Tool")
  const categoryFields = useMemo(() => {
    if (toolPatchSchema) {
      const { custom, standard } = schemaToFormFields(toolPatchSchema, "Tool", category)
      const customWithPath = custom.map((field) => ({ ...field, path: ["custom", field.field_name] }))
      const standardWithPath = standard.map((field) => ({ ...field, path: [field.field_name] }))
      return [...standardWithPath, ...customWithPath]
    }
    return undefined
  }, [toolPatchSchema, category])

  const [updateTool] = usePatchToolV1ByIdMutation()

  const title = useMemo(() => {
    const fieldCategorySchema = FieldCategorySchema.anyOf.find((schema) => schema.const === category)
    return getTitle(
      category ??
        intl.formatMessage({
          defaultMessage: "General",
          description: "General category title",
          id: "general",
        }),
      fieldCategorySchema
    )
  }, [category, intl])

  const submit = useCallback(
    async (inputs: ToolPatchInput) => {
      try {
        await updateTool({ id: tool.id, toolPatch: inputs }).unwrap()
        toast({
          description: intl.formatMessage(
            {
              id: "tool.category.saved",
              defaultMessage: "{category} saved",
              description: "Tool category saved toast message",
            },
            { category: title }
          ),
          status: "success",
        })
      } catch (err) {
        toast({
          description: intl.formatMessage(
            {
              id: "tool.category.error",
              defaultMessage: "Error saving {category}",
              description: "Tool category error toast message",
            },
            { category: title }
          ),
          status: "error",
        })
        throw err
      }
    },
    [intl, title, toast, tool.id, updateTool]
  )

  const documentDownloadURL = useCallback(
    (path: (string | number)[]) =>
      new URL(`/tool/v1/${tool.id}/${path.join("/")}/content`, import.meta.env.VITE_API_BASE_URL).href,
    [tool.id]
  )
  if (!categoryFields || !toolSchema) {
    return null
  }
  return (
    <Stack {...stackProps}>
      <HStack justifyContent="space-between">
        <Heading size="xs">{title}</Heading>
        <Spacer />

        {category === "compliance" && dynamoTelemetryEnabled(tool.organization_id) ? (
          <DynamoTrigger toolListingId={tool.tool_listing_id} />
        ) : null}

        {category === "it" ? (
          <ToolItStatusBadge status={tool.it_status} editToolId={tool.id} />
        ) : category === "compliance" ? (
          <ToolComplianceStatusBadge status={tool.compliance_status} editToolId={tool.id} />
        ) : category === "finance" ? (
          <ToolFinanceStatusBadge status={tool.finance_status} editToolId={tool.id} />
        ) : null}
      </HStack>
      {categoryFields.length === 0 ? (
        <FormattedMessage
          defaultMessage="No editable fields in this category"
          description="Helper message when there are no editable fields in this category"
          id="tool.category.no-editable-fields"
        />
      ) : (
        <>
          {category === "compliance" && dynamoTelemetryEnabled(tool.organization_id) ? (
            <DynamoTelemetry toolListingId={tool.tool_listing_id} />
          ) : null}

          <SchemaFormPage<ToolPatchOutput>
            initialFormValues={tool as DefaultValues<ToolPatchOutput>}
            rootSchema={toolSchema}
            formFields={categoryFields}
            onSubmit={submit}
            documentDownloadURL={documentDownloadURL}
            isEditing={true}
          />
        </>
      )}
    </Stack>
  )
}
