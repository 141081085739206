import { mapBy } from "@brm/util/collections.js"
import { isNotUndefined } from "typed-assert"

interface Node {
  id: string
  next: string[]
}

/**
 * Performs a breadth-first traversal of a directed graph, returning a list of nodes in the order they were visited. Returns
 * a deterministic ordering that breaks ties between nodes in the same level by comparing them with the given sort function.
 * @param nodes unordered list of nodes in a graph
 * @param sortFunction function comparing two nodes to determine the ordering they should appear in if they are in the same level
 * @returns sorted list of nodes in the graph in BFS traversal order with the given sort function applied to each level
 */
export function sortedBfs<T extends Node>(nodes: T[], sortFunction: (a: T, b: T) => number): T[] {
  const nodesById = mapBy(nodes, (node) => node.id)
  // Find root steps (steps with no incoming edges) to determine first level of traversal
  const nonRootNodeIds = new Set<string>(nodes.flatMap((node) => node.next))
  const rootSteps = nodes.filter((step) => !nonRootNodeIds.has(step.id))

  const sortedNodes: T[] = []
  const visited = new Set<string>()
  // The queue is a list of levels, where each level is a list of nodes
  const queue = [rootSteps]

  while (queue.length > 0) {
    const currentLevel = queue.shift()
    // queue should not be empty
    if (!currentLevel) {
      break
    }
    const nextLevel: T[] = []
    // Sort the current level to determine the order in which nodes should be visited
    currentLevel.sort(sortFunction)
    while (currentLevel.length > 0) {
      const currentNode = currentLevel.shift()
      // currentLevel should not be empty
      if (!currentNode) {
        break
      }
      // If we have already visited this node, skip it
      if (visited.has(currentNode.id)) {
        continue
      }
      // Mark the current node as visited
      visited.add(currentNode.id)
      // Add the current node to the sorted order output array
      sortedNodes.push(currentNode)
      // Add the current node's children to the next level of traversal if they have not been visited
      nextLevel.push(
        ...currentNode.next.map((id) => {
          const n = nodesById.get(id)
          // node should always exist in nodesById
          isNotUndefined(n)
          return n
        })
      )
    }
    // Add the next level to the queue if it is not empty
    if (nextLevel.length > 0) {
      queue.push(nextLevel)
    }
  }

  return sortedNodes
}
