import { hasPermission } from "@brm/schema-helpers/role.js"
import type { SavedView, TableIdentifier } from "@brm/schema-types/types.js"
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { useState, type ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetUserV1WhoamiQuery, usePutSavedViewV1ByIdMutation } from "../../app/services/generated-api.js"
import { apiCallToastWrapper } from "../../util/api.js"
import type { SavedViewState } from "../../util/schema-table.js"
import { ChevronDownIcon, PlusIcon, SavedViewsIcon, TrashIcon } from "../icons/icons.js"
import { TableDeleteViewModal } from "./TableDeleteViewModal.js"
import TableSaveViewModal from "./TableSaveViewModal.js"

export default function TableSaveViewMenu({
  savedViewState,
  onUpdate,
  onDelete,
  tableId,
}: {
  tableId: TableIdentifier
  onUpdate: (savedView: SavedView) => void
  onDelete: (savedView: SavedView) => void
  savedViewState: SavedViewState
}): ReactNode {
  const intl = useIntl()
  const toast = useToast()

  const [isUpdate, setIsUpdate] = useState(false)

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [updateSavedView] = usePutSavedViewV1ByIdMutation()

  const savedViewModal = useDisclosure({
    onClose: () => setIsUpdate(false),
  })

  const [viewToDelete, setViewToDelete] = useState<SavedView | undefined>(undefined)
  const confirmDeleteModal = useDisclosure({
    onClose: () => setViewToDelete(undefined),
  })

  const hasUnsavedChanges =
    savedViewState?.view &&
    (savedViewState.newColumnParams !== undefined ||
      savedViewState.newFilterParams !== undefined ||
      savedViewState.newSortParams !== undefined)

  return (
    <>
      <Menu isLazy>
        <Tooltip
          label={
            <FormattedMessage
              id="table.saveViewMenu.tooltip"
              defaultMessage="Saved view"
              description="Tooltip for the save view menu"
            />
          }
        >
          <MenuButton
            as={Button}
            variant="outline"
            leftIcon={<Icon as={SavedViewsIcon} />}
            rightIcon={<Icon as={ChevronDownIcon} />}
            iconSpacing={1}
          />
        </Tooltip>
        <MenuList>
          {savedViewState?.view &&
            (!savedViewState.view.is_shared || hasPermission(whoami?.roles, "saved_view.shared:update")) && (
              <MenuItem
                icon={<Icon as={SavedViewsIcon} />}
                isDisabled={!hasUnsavedChanges}
                onClick={async () => {
                  const currentView = savedViewState.view
                  if (currentView?.is_default) {
                    await apiCallToastWrapper<SavedView>({
                      toast,
                      apiCall: () =>
                        updateSavedView({
                          id: currentView.id,
                          editSavedView: {
                            column_params:
                              savedViewState.newColumnParams === undefined
                                ? currentView.column_params
                                : savedViewState.newColumnParams && Array.from(savedViewState.newColumnParams),
                            filter_params:
                              savedViewState.newFilterParams === undefined
                                ? currentView.filter_params
                                : savedViewState.newFilterParams,
                            sort_params:
                              savedViewState.newSortParams === undefined
                                ? currentView.sort_params
                                : savedViewState.newSortParams,
                          },
                        }).unwrap(),
                      onSuccess: onUpdate,
                      successMessage: intl.formatMessage({
                        id: "table.saveViewMenu.defaultViewUpdatedToast",
                        defaultMessage: "Default view updated",
                        description: "Toast message when the default view updated successfully",
                      }),
                      fallbackErrorMessage: intl.formatMessage({
                        id: "savedView.modal.toast.error",
                        defaultMessage: "An error occurred saving the view",
                        description: "The toast description for an error when saving a view",
                      }),
                    })
                  } else {
                    setIsUpdate(true)
                    savedViewModal.onOpen()
                  }
                }}
              >
                {savedViewState.view.is_default ? (
                  <FormattedMessage
                    id="table.saveViewMenu.updateDefaultView"
                    defaultMessage="Update default view"
                    description="Update default view"
                  />
                ) : (
                  <FormattedMessage
                    id="table.saveViewMenu.updateView"
                    defaultMessage="Update “{viewName}”"
                    description="Update current view"
                    values={{ viewName: savedViewState.view.name }}
                  />
                )}
              </MenuItem>
            )}
          {savedViewState.view &&
            !savedViewState.view.is_default &&
            (!savedViewState.view.is_shared || hasPermission(whoami?.roles, "saved_view.shared:delete")) && (
              <MenuItem
                icon={<Icon as={TrashIcon} />}
                onClick={() => {
                  setViewToDelete(savedViewState.view)
                  confirmDeleteModal.onOpen()
                }}
              >
                <FormattedMessage
                  id="table.saveViewMenu.deleteView"
                  defaultMessage="Delete “{viewName}”"
                  description="Delete view button label"
                  values={{ viewName: savedViewState.view.name }}
                />
              </MenuItem>
            )}
          <MenuItem icon={<Icon as={PlusIcon} />} onClick={savedViewModal.onOpen}>
            <FormattedMessage
              id="table.saveViewMenu.createNewView"
              defaultMessage="Create new view"
              description="Create new view"
            />
          </MenuItem>
        </MenuList>
      </Menu>
      {savedViewModal.isOpen && (
        <TableSaveViewModal
          tableId={tableId}
          onUpdate={onUpdate}
          savedViewState={savedViewState}
          isUpdate={isUpdate}
          {...savedViewModal}
        />
      )}
      {confirmDeleteModal.isOpen && viewToDelete && (
        <TableDeleteViewModal
          viewToDelete={viewToDelete}
          onDelete={() => onDelete(viewToDelete)}
          {...confirmDeleteModal}
        />
      )}
    </>
  )
}
