import type { IconButtonProps, IconProps } from "@chakra-ui/react"
import { Icon, IconButton } from "@chakra-ui/react"
import { useState } from "react"
import { LockIcon, UnlockedIcon } from "../icons.js"

/**
 * Wrapper around IconButton that adds a default tooltip and aria-label intl string.
 * Also allows for a global setting for what Chakra icon should be used for each button type.
 */
export default function LockUnlockIconButton({ iconProps, ...props }: IconButtonProps & { iconProps?: IconProps }) {
  const [showUnlock, setShowUnlock] = useState(false)

  const setToLock = () => {
    setShowUnlock(false)
  }

  const setToUnlock = () => {
    setShowUnlock(true)
  }

  return (
    <IconButton
      icon={
        <>
          <Icon as={LockIcon} {...iconProps} display={showUnlock ? "none" : undefined} />
          <Icon as={UnlockedIcon} {...iconProps} display={showUnlock ? undefined : "none"} />
        </>
      }
      {...props}
      onMouseOver={setToUnlock}
      onMouseLeave={setToLock}
      onFocus={setToUnlock}
      onBlur={setToLock}
    />
  )
}
