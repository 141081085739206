import { Temporal, toTemporalInstant } from "@js-temporal/polyfill"
import { isObject } from "./type-guard.js"

interface WeekInfo {
  firstDay: number
  minimalDays: number
  weekend: number[]
}

/**
 * Returns the first day of the week for the given locale.
 * 1 = Monday, 7 = Sunday.
 */
export function localeFirstDayOfWeek(localeTag: string): number | undefined {
  if (typeof Intl?.Locale !== "function") {
    return undefined
  }
  const locale = new Intl.Locale(localeTag)
  let weekInfo: WeekInfo | undefined
  if ("getWeekInfo" in locale && typeof locale.getWeekInfo === "function") {
    weekInfo = locale.getWeekInfo()
  }
  if ("weekInfo" in locale && isObject(locale.weekInfo)) {
    weekInfo = locale.weekInfo as WeekInfo
  }
  if (typeof weekInfo?.firstDay === "number") {
    return weekInfo.firstDay
  }
  return undefined
}

/**
 * @param firstDay The first day of the week. 7 = Sunday, 1 = Monday
 */
export function startOfWeek(date: Temporal.PlainDate, firstDay: number = 7): Temporal.PlainDate {
  // Calculate the number of days to subtract to get to the first day of the week
  const daysToSubtract = (date.dayOfWeek - firstDay + 7) % 7
  return date.subtract({ days: daysToSubtract })
}

export function startOfMonth(date: Temporal.PlainDate): Temporal.PlainDate {
  return date.with({ day: 1 })
}

export function startOfQuarter(date: Temporal.PlainDate): Temporal.PlainDate {
  const quarter = Math.floor((date.month - 1) / 3) + 1
  return date.with({ month: quarter * 3 - 2, day: 1 })
}

export function startOfYear(date: Temporal.PlainDate): Temporal.PlainDate {
  return date.with({ month: 1, day: 1 })
}

export function isPlainDate(value: unknown): value is Temporal.PlainDate {
  return Object.prototype.toString.call(value) === "[object Temporal.PlainDate]"
}

// eslint-disable-next-line no-restricted-globals
export function plainDateToLegacyDate(date: Temporal.PlainDateLike | string): Date {
  // eslint-disable-next-line no-restricted-globals
  return new Date(
    Temporal.PlainDate.from(date)
      .toPlainDateTime({ hour: 0, minute: 0, second: 0 })
      .toZonedDateTime(Temporal.Now.timeZoneId()).epochMilliseconds
  )
}

// eslint-disable-next-line no-restricted-globals
export function legacyDateToYearMonth(month: Date): Temporal.PlainYearMonth {
  return toTemporalInstant.call(month).toZonedDateTimeISO(Temporal.Now.timeZoneId()).toPlainYearMonth()
}

// eslint-disable-next-line no-restricted-globals
export function legacyDateToPlainDate(date: Date): Temporal.PlainDate {
  return toTemporalInstant.call(date).toZonedDateTimeISO(Temporal.Now.timeZoneId()).toPlainDate()
}

/**
 * Verifies whether a date like string parses to a valid Temporal.PlainDate.
 */
export function isValidPlainDate(string: string): boolean {
  try {
    Temporal.PlainDate.from(string)
    return true
  } catch (_) {
    return false
  }
}

/**
 * Get the maximum date between multiple temporal instants
 */
export function max(...dates: Temporal.Instant[]): Temporal.Instant {
  if (dates.length === 0) {
    throw new Error("At least one date is required")
  }
  return dates.reduce((max, date) => (Temporal.Instant.compare(date, max) > 0 ? date : max))
}
