import { Flex } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

interface Props {
  error: unknown
}

export default function UnexpectedError({ error }: Props) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <FormattedMessage
        id="error.message"
        description="Let users know an error has occurred"
        defaultMessage="<h1>Oops!</h1>{br}<p>Sorry, an unexpected error has occurred</p>"
        values={{
          br: <br />,
          h1: (chunks) => <h1>{chunks}</h1>,
          p: (chunks) => <p>{chunks}</p>,
        }}
      />
      <p>
        <i>
          {(error instanceof Error ? error.message : undefined) || (
            <FormattedMessage
              id="error.message.default"
              description="Default route error explanation when no message is available"
              defaultMessage="Unknown Error"
            />
          )}
        </i>
      </p>
    </Flex>
  )
}
