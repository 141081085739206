import { hasAllPermissions } from "@brm/schema-helpers/role.js"
import type { Permission } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Badge,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Tooltip,
  useToast,
  type ThemeTypings,
} from "@chakra-ui/react"
import { useRef } from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  usePatchToolV1ByIdMutation,
  usePatchVendorV1ByIdMutation,
} from "../app/services/generated-api.js"
import { SHORT_TOOLTIP_OPEN_DELAY } from "../util/constant.js"
import { CheckIcon } from "./icons/icons.js"

export type StatusField = "status" | "finance_status" | "it_status" | "compliance_status"

interface Props<StatusEnum extends string, TFieldName extends StatusField> {
  status: StatusEnum
  objectId: string | undefined
  objectType: "Tool" | "Vendor"
  fieldName: TFieldName
  options: readonly StatusEnum[]
  statusColors: Record<StatusEnum, ThemeTypings["colorSchemes"]>
  displayStatus: (status: StatusEnum, intl: IntlShape) => string
  updatePermissions: Permission[]
}

export default function UpdateStatusBadge<StatusEnum extends string, TFieldName extends StatusField>({
  status,
  fieldName,
  objectId,
  objectType,
  options,
  statusColors,
  displayStatus,
  updatePermissions,
}: Props<StatusEnum, TFieldName>) {
  const intl = useIntl()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const [updateTool] = usePatchToolV1ByIdMutation()
  const [updateVendor] = usePatchVendorV1ByIdMutation()

  const selectedItemRef = useRef<HTMLButtonElement>(null)

  const canEdit = objectId && hasAllPermissions(whoami?.roles, updatePermissions)
  if (!canEdit) {
    return <Badge colorScheme={statusColors[status]}>{displayStatus(status, intl)}</Badge>
  }

  return (
    <Menu isLazy initialFocusRef={selectedItemRef}>
      <Tooltip
        label={
          <FormattedMessage
            id="tool.header.owner"
            description="Tooltip explaining the person shown there is the owner of the tool"
            defaultMessage="Change Status"
          />
        }
        openDelay={SHORT_TOOLTIP_OPEN_DELAY}
      >
        <MenuButton sx={{ "> span": { display: "flex" } }}>
          <Badge variant="clickable" colorScheme={statusColors[status]}>
            {displayStatus(status, intl)}
          </Badge>
        </MenuButton>
      </Tooltip>
      <Portal>
        <MenuList minW="12.125rem">
          <MenuOptionGroup defaultValue={status} fontWeight="medium" fontSize="sm" type="radio">
            {options.map((enumVal) => (
              <MenuItemOption
                {...(enumVal === status ? { ref: selectedItemRef } : {})}
                key={enumVal}
                value={enumVal}
                icon={null}
                onClick={async () => {
                  try {
                    switch (objectType) {
                      case "Tool":
                        await updateTool({
                          id: objectId,
                          toolPatch: { id: objectId, object_type: "Tool", [fieldName]: enumVal },
                        }).unwrap()
                        break
                      case "Vendor":
                        await updateVendor({
                          id: objectId,
                          vendorPatch: { id: objectId, object_type: "Vendor", [fieldName]: enumVal },
                        }).unwrap()
                        break
                      default:
                        unreachable(objectType)
                    }
                    toast({
                      status: "success",
                      description: intl.formatMessage({
                        id: "status.change.success.title",
                        defaultMessage: "Status updated",
                        description: "Title of the toast that appears when a status is changed",
                      }),
                    })
                  } catch (_) {
                    toast({
                      status: "error",
                      description: intl.formatMessage({
                        id: "status.change.error.title",
                        defaultMessage: "Error changing status",
                        description: "Title of the toast that appears when a status change fails",
                      }),
                    })
                  }
                }}
              >
                <HStack justifyContent="space-between">
                  <Badge variant="clickable" colorScheme={statusColors[enumVal]}>
                    {displayStatus(enumVal, intl)}
                  </Badge>
                  {enumVal === status && <Icon as={CheckIcon} />}
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}
