import type { Filter } from "@brm/type-helpers/filters.js"
import type { ReactElement } from "react"
import type { Location } from "react-router-dom"

export interface TabData {
  locationHash: string
  label: ReactElement | string
  /** For tabs that display a count next to the badge */
  total?: number
  isDisabled?: boolean
}

export interface FilterTabData<TColumn extends string = string> extends TabData {
  filters?: Filter<TColumn>[][]
}

export function getTabIndexFromLocation(location: Location, tabs: readonly TabData[]) {
  // Use the first tab if no hash is present
  if (!location.hash) {
    return 0
  }

  const hashParams = new URLSearchParams(location.hash.slice(1))
  const tabIndex = tabs.findIndex((tab) => hashParams.get("tab") === tab.locationHash)
  if (tabIndex < 0) {
    // If hash is unrecognized (-1), default to viewing tab 0
    return 0
  }
  return tabIndex
}
