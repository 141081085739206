import type { IconType } from "@brm/util/custom-keywords.js"
import { AgreementIcon, MailIcon, RequestIcon, ToolIcon, UserIcon, VendorIcon } from "./icons.js"

export const iconMap: Record<IconType, React.ComponentType> = {
  mail: MailIcon,
  request: RequestIcon,
  tool: ToolIcon,
  vendor: VendorIcon,
  agreement: AgreementIcon,
  user: UserIcon,
}
