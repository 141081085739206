import type { Role } from "@brm/schema-types/types.js"
import type { IntlShape } from "react-intl"

export const getLabelByRole = (intl: IntlShape) => {
  const labelByRole: Record<Role, string> = {
    admin: intl.formatMessage({
      id: "role.admin",
      description: "Admin role label",
      defaultMessage: "Admin",
    }),
    super_admin: intl.formatMessage({
      id: "role.superAdmin",
      description: "Super admin role label",
      defaultMessage: "Super Admin",
    }),
    finance: intl.formatMessage({
      id: "role.finance",
      description: "Finance role label",
      defaultMessage: "Finance",
    }),
    legal: intl.formatMessage({
      id: "role.legal",
      description: "Legal role label",
      defaultMessage: "Legal",
    }),
    legal_approver: intl.formatMessage({
      id: "role.legalApprover",
      description: "Legal role label",
      defaultMessage: "Legal",
    }),
    compliance: intl.formatMessage({
      id: "role.compliance",
      description: "Compliance role label",
      defaultMessage: "Compliance",
    }),
    compliance_approver: intl.formatMessage({
      id: "role.complianceApprover",
      description: "Compliance role label",
      defaultMessage: "Compliance",
    }),
    it: intl.formatMessage({
      id: "role.it",
      description: "IT role label",
      defaultMessage: "IT",
    }),
    it_approver: intl.formatMessage({
      id: "role.it_approver",
      description: "IT role label",
      defaultMessage: "IT",
    }),
    finance_approver: intl.formatMessage({
      id: "role.finance_approver",
      description: "Finance approver role label",
      defaultMessage: "Finance",
    }),
  }
  return labelByRole
}

export const getLabelByRoleOrUser = (intl: IntlShape) => {
  const userLabel = intl.formatMessage({
    id: "role.user",
    description: "User role label",
    defaultMessage: "User",
  })
  const labelByRole: Record<Role | "user", string> = { ...getLabelByRole(intl), user: userLabel }
  return labelByRole
}
