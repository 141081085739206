import type { WorkflowRunMinimal } from "@brm/schema-types/types.js"
import type { UseModalProps } from "@chakra-ui/react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostWorkflowV1RunsByIdCancelMutation } from "../../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"

interface Props extends UseModalProps {
  workflowRun: WorkflowRunMinimal
  onCancel?: () => void
}

export function CancelWorkflowRunModal({ workflowRun, isOpen, onClose, onCancel }: Props) {
  const intl = useIntl()
  const toast = useToast()

  const [cancelWorkflowRun, { isLoading }] = usePostWorkflowV1RunsByIdCancelMutation()
  const focusRef = useRef<HTMLButtonElement>(null)

  const onCancelWorkflowClick = async () => {
    try {
      await cancelWorkflowRun({ id: workflowRun.id }).unwrap()
      toast({
        description: intl.formatMessage(
          {
            id: "requests.run.cancel.success.toast.title",
            description: "Toast message shown when request cancellation succeeds",
            defaultMessage: "<strong>{workflowName}</strong> canceled",
          },
          { workflowName: workflowRun.display_name }
        ),
        status: "success",
      })
      onCancel?.()
      onClose()
    } catch (err) {
      log.error("Error deleting request", err, { workflowRun })
      const title =
        getAPIErrorMessage(err) ||
        intl.formatMessage(
          {
            defaultMessage: "Something went wrong while deleting <strong>{workflowName}</strong>",
            description: "The toast title for the error toast when a request could not be cancelled",
            id: "requests.modal.cancel.error.toast.title",
          },
          { workflowName: workflowRun.display_name }
        )
      toast({ status: "error", title })
    }
  }

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={focusRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <FormattedMessage
              id="requests.cancel.modal.header"
              description="Modal header for cancel request confirmation"
              defaultMessage="Cancel Request"
            />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage
              id="requests.cancel.modal.body"
              description="Text shown in modal confirming that user wants to permanently cancel a request"
              defaultMessage="Are you sure you want to cancel <strong>{name}</strong>? This will stop all gather progress and close the request."
              values={{
                name: workflowRun.display_name,
                strong: (content) => <strong>{content}</strong>,
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter gap={2}>
            <Button ref={focusRef} onClick={onClose}>
              <FormattedMessage
                id="requests.cancel.modal.close"
                description="Button text for closing the modal in the cancel confirmation modal"
                defaultMessage="Close"
              />
            </Button>
            <Button colorScheme="error" isLoading={isLoading} onClick={onCancelWorkflowClick}>
              <FormattedMessage
                id="request.cancel.modal.cancel"
                description="Button text for cancel request button in the cancel confirmation modal"
                defaultMessage="Cancel"
              />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
