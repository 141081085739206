import { Flex, Heading } from "@chakra-ui/react"
import type { FunctionComponent, ReactNode } from "react"
import FullTextSearch from "../search/FullTextSearch.js"

export const SettingsHeader: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
  <Flex gap={2} mb={4}>
    <FullTextSearch order={2} ml="auto" />
    <Heading as="h1" size="sm">
      {children}
    </Heading>
  </Flex>
)
