import { Button, Image, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import primaryTableEmptyState from "../../../assets/primary_table_empty_state.jpg"
import { Link } from "../../components/Link.js"

export default function ZeroIntegrationsView() {
  return (
    <Stack justifyContent="center" align="center" spacing={4}>
      <Image src={primaryTableEmptyState} width="container.sm" />
      <Text fontWeight="bold" fontSize="2xl">
        <FormattedMessage
          id="no.integration.added.title"
          description="Title for users to see when there are no integrations added"
          defaultMessage="No integrations found"
        />
      </Text>
      <Text fontSize="2xl" align="center">
        <FormattedMessage
          id="no.integration.added.description"
          description="Description for users to see when there are no integrations added"
          defaultMessage="Connect spend and login integrations to automagically populate your BRM"
        />
      </Text>
      <Button colorScheme="brand" as={Link} style={{ textDecoration: "none" }} to="/settings/integrations">
        <FormattedMessage
          id="no.integration.added.button"
          description="Button text for users to see when there are no integrations added"
          defaultMessage="Add Integrations"
        />
      </Button>
    </Stack>
  )
}
