import type { EmployeeGroup, PersonPicker, ToolOption, UserPicker, WorkflowKind } from "@brm/schema-types/types.js"
import { Temporal } from "@js-temporal/polyfill"
import type { IntlShape } from "react-intl"
import type { LegalAgreementOption } from "../../../legal/LegalAgreementPicker.js"
import { displayWorkflowKind } from "../../util.js"

interface LinkInviteRecipient {
  first_name: string
  last_name: string
  email: string
}

export interface StartWorkflowFormState {
  workflow_kind: WorkflowKind | null
  tool: ToolOption | null
  tool_owner: PersonPicker | null
  display_name: string
  workflow_owner: UserPicker | null
  departments: EmployeeGroup[]
  target_date: string | null
  legal_agreement: LegalAgreementOption | null
  new_legal_agreement_name: string | null
  is_new_tool: boolean | null
  new_tool_url: string | null
  new_tool_display_name: string | null
  recipients: LinkInviteRecipient[]
  message: string | null
  // The due date the buyer wants to communicate to the seller for when to submit link by
  seller_due_date: string | null
  skipped_invites: boolean
}

export enum StartWorkflowFormStep {
  WORKFLOW_KIND,
  TOOL,
  LEGAL_AGREEMENT,
  WORKFLOW,
  CONFIRM,
  SUCCESS,
}

/**
 * Returns the percentage of steps completed at the current step (0-100). Used to display progress bar
 * for an arbitrary number of steps, assuming the step provided is within the steps array.
 */
export const getProgressValue = (step: StartWorkflowFormStep, steps: StartWorkflowFormStep[]) => {
  return (steps.indexOf(step) / (steps.length - 1)) * 100
}

export const generateWorkflowDisplayName = (toolName: string, workflowKind: WorkflowKind, intl: IntlShape): string => {
  const formattedDateParts = intl.formatDateToParts(Temporal.Now.instant().toString(), {
    year: "numeric",
    month: "short",
  })
  const month = formattedDateParts.find((d) => d.type === "month")?.value
  const year = formattedDateParts.find((d) => d.type === "year")?.value
  const workflowKindDisplay = displayWorkflowKind(workflowKind)

  if (month && year) {
    return `${toolName} ${workflowKindDisplay} ${month} ${year}`
  }
  return `${toolName} ${workflowKindDisplay}`
}

export const StartPurchaseWorkflowFields: Array<keyof StartWorkflowFormState> = [
  "workflow_kind",
  "display_name",
  "departments",
  "tool",
  "workflow_owner",
  "target_date",
]
export const StartRenewalWorkflowFields: Array<keyof StartWorkflowFormState> = [
  "workflow_kind",
  "display_name",
  "departments",
  "legal_agreement",
  "workflow_owner",
  "target_date",
  "new_legal_agreement_name",
]

export const WorkflowKindSectionFields: Array<keyof StartWorkflowFormState> = ["workflow_kind"]

export type WorkflowLegalAgreementSectionFormState = Pick<
  StartWorkflowFormState,
  "legal_agreement" | "display_name" | "tool" | "tool_owner" | "new_legal_agreement_name"
>

export const WorkflowLegalAgreementSectionFields: Array<keyof StartWorkflowFormState> = [
  "legal_agreement",
  "new_legal_agreement_name",
]

export type WorkflowSectionFormState = Pick<
  StartWorkflowFormState,
  "display_name" | "departments" | "workflow_owner" | "target_date"
>

export const WorkflowSectionFields: Array<keyof StartWorkflowFormState> = [
  "display_name",
  "departments",
  "workflow_owner",
  "target_date",
]

export type WorkflowToolSectionFormState = Pick<
  StartWorkflowFormState,
  "tool" | "tool_owner" | "display_name" | "new_tool_url"
>

export const WorkflowToolSectionFields: Array<keyof StartWorkflowFormState> = ["tool", "tool_owner"]

export type WorkflowInviteSectionFormState = Pick<
  StartWorkflowFormState,
  "recipients" | "message" | "seller_due_date" | "skipped_invites"
>

export const WorkflowInviteSectionFields: Array<keyof StartWorkflowFormState> = [
  "recipients",
  "message",
  "seller_due_date",
]
