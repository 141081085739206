import type { WorkflowRunListItem } from "@brm/schema-types/types.js"
import { formatDateTime } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import { Box, Card, CardBody, Divider, HStack, Heading, Icon, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { FormattedMessage, useIntl } from "react-intl"
import type { Descendant } from "slate"
import { hasPresentKey } from "ts-is-present"
import { Link } from "../../../components/Link.js"
import RichTextDisplay from "../../../components/RichTextEditor/RichTextDisplay.js"
import { EMPTY_RICH_TEXT_BODY } from "../../../components/RichTextEditor/util/common.js"
import { CalendarIcon } from "../../../components/icons/icons.js"
import { EMPTY_VALUE_SYMBOL } from "../../../util/constant.js"
import { pathForWorkflowRun } from "../../../util/json-schema.js"
import PickableEntityDisplay from "../../organization/PickableEntityDisplay.js"
import { AUTO_SELECT_STEP_HASH } from "../constants.js"
import { displayWorkflowStatus } from "../util.js"
import { NestedBadge } from "./NestedBadge.js"
import { WorkflowRunCard } from "./WorkflowRunCard.js"
import { workflowRunStatusColors } from "./status-colors.js"

interface Props {
  /** Name of the tool or vendor detail page that the workflow card is in */
  sorDisplayName: string
  workflowRuns: WorkflowRunListItem[]
}

export const WorkflowRunSorOverview = ({ sorDisplayName, workflowRuns }: Props) => {
  const intl = useIntl()

  if (workflowRuns.length === 0) {
    return null
  }

  const activeRequests = workflowRuns.filter((workflowRun) => workflowRun.status === "in_progress")

  // WorkflowRuns are sorted by created_at but we want to show the latest approved one
  const approvedWorkflows = workflowRuns
    .filter((workflowRun) => workflowRun.status === "approved")
    .filter(hasPresentKey("completed_at"))
    .sort((a, b) => Temporal.Instant.compare(a.completed_at, b.completed_at))
  const latestApprovedWorkflowRun = approvedWorkflows[0]

  if (activeRequests.length === 0 && !latestApprovedWorkflowRun) {
    return null
  }

  return (
    <Stack gap={6}>
      {activeRequests.length > 0 && (
        <>
          <Heading size="xs">
            <FormattedMessage
              id="workflowRun.sor.overview.title"
              description="Heading for a list of request cards on the tool or vendor"
              defaultMessage="Active Requests"
            />
          </Heading>
          {activeRequests.map((workflowRun) => (
            <WorkflowRunCard key={workflowRun.id} workflowRun={workflowRun} sorDisplayName={sorDisplayName} />
          ))}
        </>
      )}
      {latestApprovedWorkflowRun && (
        <>
          <Heading size="xs">
            {latestApprovedWorkflowRun.kind === "purchase" ? (
              <FormattedMessage
                id="workflowRun.sor.latest.purchase.title"
                description="Heading for the latest request that has been approved on the tool or vendor"
                defaultMessage="Latest Purchase"
              />
            ) : (
              <FormattedMessage
                id="workflowRun.sor.latest.renewal.title"
                description="Heading for the latest request that has been approved on the tool or vendor"
                defaultMessage="Latest Renewal"
              />
            )}
          </Heading>
          <Card variant="outline">
            <CardBody>
              <Text as="span" fontWeight="medium" fontSize="sm">
                <FormattedMessage
                  id="workflowRun.sor.latest.problemsAddressed"
                  description="Title of the latest request problems address detail"
                  defaultMessage="Problems Addressed"
                />
              </Text>
              <Box py={2}>
                <RichTextDisplay
                  content={(latestApprovedWorkflowRun.overview as Descendant[]) ?? EMPTY_RICH_TEXT_BODY}
                />
              </Box>
              <Divider opacity={1} />
              <SimpleGrid columns={2} spacingY={4} paddingTop={2}>
                {/* Champ */}
                <Stack gap={2}>
                  <Text as="span" fontWeight="medium" fontSize="sm">
                    <FormattedMessage
                      id="workflowRun.sor.latest.champ"
                      description="Title of the latest request champion"
                      defaultMessage="Champ"
                    />
                  </Text>
                  <PickableEntityDisplay
                    displayText={displayPersonName(latestApprovedWorkflowRun.owner, intl)}
                    image={latestApprovedWorkflowRun.owner.profile_image?.gcs_file_name}
                  />
                </Stack>
                {/* Request link */}
                <Stack gap={2} borderLeftWidth={1} paddingLeft={4}>
                  <Text as="span" fontWeight="medium" fontSize="sm">
                    <FormattedMessage
                      id="workflowRun.sor.latest.request"
                      description="Title of the link to the latest request"
                      defaultMessage="Request"
                    />
                  </Text>
                  <NestedBadge
                    as={Link}
                    variant="clickable"
                    to={{ pathname: pathForWorkflowRun(latestApprovedWorkflowRun), hash: AUTO_SELECT_STEP_HASH }}
                    colorScheme={workflowRunStatusColors[latestApprovedWorkflowRun.status]}
                    leftBadgeContent={displayWorkflowStatus(latestApprovedWorkflowRun.status)}
                    rightContent={<Text>{latestApprovedWorkflowRun.display_name}</Text>}
                  />
                </Stack>
                {/* Two dividers are needed because the simple grid has two columns */}
                <Divider opacity={1} />
                <Divider opacity={1} />
                {/* Department */}
                <Stack gap={2}>
                  <Text as="span" fontWeight="medium" fontSize="sm">
                    <FormattedMessage
                      id="workflowRun.sor.latest.department"
                      description="Title of the latest request department"
                      defaultMessage="Department"
                    />
                  </Text>
                  <Text fontSize="md">
                    {latestApprovedWorkflowRun.employee_groups
                      ? intl.formatList(
                          latestApprovedWorkflowRun.employee_groups.map((group) => group.display_name),
                          { type: "conjunction" }
                        )
                      : EMPTY_VALUE_SYMBOL}
                  </Text>
                </Stack>
                {/* Completed on */}
                <Stack gap={2} borderLeftWidth={1} paddingLeft={4}>
                  <Text as="span" fontWeight="medium" fontSize="sm">
                    <FormattedMessage
                      id="workflowRun.sor.latest.completedOn"
                      description="Title of the latest request completed on"
                      defaultMessage="Completed on"
                    />
                  </Text>
                  <HStack alignItems="center">
                    <Icon as={CalendarIcon} />
                    <Text fontSize="md">
                      {latestApprovedWorkflowRun.completed_at
                        ? formatDateTime(intl, latestApprovedWorkflowRun.completed_at)
                        : EMPTY_VALUE_SYMBOL}
                    </Text>
                  </HStack>
                </Stack>
              </SimpleGrid>
            </CardBody>
          </Card>
        </>
      )}
    </Stack>
  )
}
