import type { ObjectType } from "@brm/schema-types/types.js"
import type { ModalProps, UseModalProps } from "@chakra-ui/react"
import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text, useToast } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation } from "../../app/services/generated-api.js"
import { log } from "../../util/logger.js"

interface Props extends UseModalProps, Pick<ModalProps, "returnFocusOnClose"> {
  workflowRunStepId: string
  objectId: string | undefined
  objectType: ObjectType | undefined
  fieldName: string | undefined

  fieldLabel: string
}

export default function FieldApprovalConfirmationModal({
  workflowRunStepId,
  objectType,
  objectId,
  fieldName,
  fieldLabel,
  ...modalProps
}: Props) {
  const intl = useIntl()
  const toast = useToast()
  const [updateFieldApproval] =
    usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation()
  return (
    <Modal {...modalProps} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody as={Stack} gap={4}>
          <Text fontWeight="medium">
            <FormattedMessage
              defaultMessage="Are you sure you want to edit the approved field “{fieldLabel}”?"
              description="Warning modal text prompting the user to confirm editing an approved field."
              id="field.approval.edit.confirmation"
              values={{ fieldLabel }}
            />
          </Text>
          <Text fontWeight="normal" color="gray.600">
            <FormattedMessage
              defaultMessage="The field will lose its approval status and the approver will be notified to review the changed field."
              id="field.approval.edit.confirmation.description"
              description="Modal text informing user that approver will be notified if they edit this field."
            />
          </Text>
          <Flex gap={4}>
            <Button flex={1} onClick={modalProps.onClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Modal cancel button"
                id="field.approval.edit.modal.cancel"
              />
            </Button>
            <Button
              flex={1}
              colorScheme="brand"
              onClick={async () => {
                modalProps.onClose()
                if (objectId && objectType && fieldName) {
                  try {
                    await updateFieldApproval({
                      id: workflowRunStepId,
                      objectType,
                      objectId,
                      fieldName,
                      workflowRunStepFieldApprovalStatus: "needs_review",
                    }).unwrap()
                  } catch (err) {
                    toast({
                      description: intl.formatMessage({
                        id: "request.field.reopen.error",
                        description: "Toast error message when gatherer reopening a field fails",
                        defaultMessage: "There was an error reopening this field",
                      }),
                      status: "error",
                    })
                    log.error("Failed to re-open an approved field", err)
                  }
                }
              }}
            >
              <FormattedMessage
                defaultMessage="Continue"
                description="Modal continue button"
                id="field.approval.edit.modal.continue"
              />
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
