import type { InputProps } from "@chakra-ui/react"
import { Input, List, ListItem, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import { useCombobox } from "downshift"
import type { ReactElement } from "react"

export interface Props<T> {
  onInputValueChange: (value: string) => void
  onSelectedItemChange: (item: T) => void
  itemToString: (item: T | null) => string
  renderAutocompleteItem: (item: T) => ReactElement
  getAutocompleteItemKey: (item: T) => string
  inputProps?: InputProps
  autocompleteItems?: T[]
}

export default function ComboBox<T>({
  onInputValueChange,
  inputProps,
  itemToString,
  renderAutocompleteItem,
  onSelectedItemChange,
  getAutocompleteItemKey,
  autocompleteItems = [],
}: Props<T>) {
  const { isOpen, getInputProps, getMenuProps, getItemProps, highlightedIndex } = useCombobox({
    onInputValueChange: ({ inputValue = "" }) => {
      onInputValueChange(inputValue)
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
      newSelectedItem ? onSelectedItemChange(newSelectedItem) : null,
    items: autocompleteItems,
    itemToString,
  })

  return (
    <Popover isOpen={isOpen} autoFocus={false} matchWidth={true} returnFocusOnClose={false} gutter={4} isLazy>
      <PopoverTrigger>
        {/* Override autocomplete behavior (which is getting set by the props provided
              from useCombobox to "off" which does not actually turn off autocompletion) */}
        <Input {...getInputProps()} {...inputProps} autoComplete="new-password" />
      </PopoverTrigger>
      <PopoverContent hidden={autocompleteItems.length === 0} width="inherit">
        <List maxH="200px" overflowY="auto" padding="0" styleType="disc" {...getMenuProps()}>
          {autocompleteItems.map((item, index) => (
            <ListItem
              backgroundColor={highlightedIndex === index ? "gray.100" : undefined}
              key={getAutocompleteItemKey(item)}
              {...getItemProps({ item, index })}
            >
              {renderAutocompleteItem(item)}
            </ListItem>
          ))}
        </List>
      </PopoverContent>
    </Popover>
  )
}
