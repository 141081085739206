/**
 *
 * @param currentStep current step, starting from 1
 * @param numSteps total number of steps
 * @returns returns a value from 1-100 based
 */
export function getProgress(currentStep: number, numSteps: number) {
  if (currentStep > numSteps) currentStep = numSteps
  return (100 / numSteps) * currentStep
}
