import { hasPermission } from "@brm/schema-helpers/role.js"
import type { SavedView, TableIdentifier } from "@brm/schema-types/types.js"
import type { UseModalProps } from "@chakra-ui/react"
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { useState, type FormEventHandler } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  usePostSavedViewV1CreateMutation,
  usePutSavedViewV1ByIdMutation,
} from "../../app/services/generated-api.js"
import { apiCallToastWrapper } from "../../util/api.js"
import type { SavedViewState } from "../../util/schema-table.js"
import { displayNameForTableId } from "./const.js"

interface Props extends UseModalProps {
  tableId: TableIdentifier
  onUpdate: (savedView: SavedView) => void
  isUpdate: boolean
  savedViewState: SavedViewState
}

export default function TableSaveViewModal({ tableId, savedViewState, isUpdate, onUpdate, ...modalProps }: Props) {
  const intl = useIntl()
  const toast = useToast()

  const [viewName, setViewName] = useState(isUpdate ? (savedViewState.view?.name ?? "") : "")
  const [isShared, setIsShared] = useState(isUpdate ? (savedViewState.view?.is_shared ?? false) : false)

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [createSavedView, createSavedViewStatus] = usePostSavedViewV1CreateMutation()
  const [updateSavedView, updateSavedViewStatus] = usePutSavedViewV1ByIdMutation()

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault()

    const trimmedName = viewName.trim()
    await apiCallToastWrapper<SavedView>({
      toast,
      onSuccess: (savedView) => {
        onUpdate(savedView)
        modalProps.onClose()
      },
      apiCall: isUpdate
        ? () => {
            isNotUndefined(savedViewState.view)
            return updateSavedView({
              id: savedViewState.view.id,
              editSavedView: {
                name: trimmedName,
                is_shared: isShared,
                column_params:
                  savedViewState.newColumnParams === undefined
                    ? savedViewState.view.column_params
                    : savedViewState.newColumnParams && Array.from(savedViewState.newColumnParams),
                filter_params:
                  savedViewState.newFilterParams === undefined
                    ? savedViewState.view.filter_params
                    : savedViewState.newFilterParams,
                sort_params:
                  savedViewState.newSortParams === undefined
                    ? savedViewState.view.sort_params
                    : savedViewState.newSortParams,
              },
            }).unwrap()
          }
        : () =>
            createSavedView({
              createSavedView: {
                name: trimmedName,
                is_shared: isShared,
                table_identifier: tableId,
                column_params:
                  savedViewState.newColumnParams === undefined
                    ? (savedViewState.view?.column_params ?? null)
                    : savedViewState.newColumnParams && Array.from(savedViewState.newColumnParams),
                filter_params:
                  savedViewState.newFilterParams === undefined
                    ? (savedViewState.view?.filter_params ?? null)
                    : savedViewState.newFilterParams,
                sort_params:
                  savedViewState.newSortParams === undefined
                    ? (savedViewState.view?.sort_params ?? null)
                    : savedViewState.newSortParams,
              },
            }).unwrap(),
      successMessage: isUpdate
        ? intl.formatMessage(
            {
              id: "table.saveViewMenu.viewUpdatedToast",
              defaultMessage: "View {viewName} updated",
              description: "Toast message when a view is updated successfully",
            },
            { viewName: trimmedName }
          )
        : intl.formatMessage(
            {
              id: "table.saveViewMenu.viewSavedToast",
              defaultMessage: "View {viewName} created",
              description: "Toast message when a view is saved successfully",
            },
            { viewName: trimmedName }
          ),
      fallbackErrorMessage: intl.formatMessage({
        id: "savedView.modal.toast.error",
        defaultMessage: "An error occured saving the view",
        description: "The toast description for an error when saving a view",
      }),
    })
  }

  return (
    <Modal {...modalProps} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>
          <HStack justifyContent="space-between" alignItems="center">
            {!isUpdate ? (
              <FormattedMessage
                defaultMessage="New {tableName} Table View"
                description="New table view modal title"
                id="table.saveViewMenu.modalTitle"
                values={{ tableName: displayNameForTableId(tableId, intl) }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Update {tableName} Table View"
                description="Update table view modal title"
                id="table.saveViewMenu.modalTitle"
                values={{ tableName: displayNameForTableId(tableId, intl) }}
              />
            )}
            {/* By default the position is absolute with the top right, but we want it to align with the title */}
            <ModalCloseButton position="unset" />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Name"
                description="New table view modal name label"
                id="table.saveViewMenu.modalNameLabel"
              />
            </FormLabel>
            <Input
              value={viewName}
              onChange={(e) => setViewName(e.target.value)}
              placeholder={intl.formatMessage({
                defaultMessage: "Enter a name for this view",
                description: "New table view modal name placeholder",
                id: "table.saveViewMenu.modalNamePlaceholder",
              })}
            />
          </FormControl>
          {savedViewState.view?.owner_id &&
            hasPermission(whoami?.roles, isUpdate ? "saved_view.shared:update" : "saved_view.shared:create") && (
              <FormControl mt={4}>
                <Checkbox isChecked={isShared} onChange={(e) => setIsShared(e.target.checked)}>
                  <FormattedMessage
                    defaultMessage="Share view with others in your organization"
                    description="New table view modal share checkbox"
                    id="table.saveViewMenu.modalShareCheckbox"
                  />
                </Checkbox>
              </FormControl>
            )}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="outline"
              flexGrow={1}
              onClick={modalProps.onClose}
              isDisabled={createSavedViewStatus.isLoading || updateSavedViewStatus.isLoading}
            >
              <FormattedMessage
                id="requests.start.modal.close"
                defaultMessage="Close"
                description="Request start modal close button text"
              />
            </Button>
            <Button
              type="submit"
              colorScheme="brand"
              flexGrow={1}
              isDisabled={viewName.trim().length === 0 ? true : false}
              isLoading={createSavedViewStatus.isLoading || updateSavedViewStatus.isLoading}
            >
              {!isUpdate ? (
                <FormattedMessage
                  id="table.saveViewMenu.createView"
                  description="Text on a button to create a new view"
                  defaultMessage="Create View"
                />
              ) : (
                <FormattedMessage
                  id="table.saveViewMenu.updateView"
                  description="Text on a button to update a view"
                  defaultMessage="Update View"
                />
              )}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
