import { Text } from "@chakra-ui/react"
import { EMPTY_VALUE_SYMBOL } from "../util/constant.js"

export function EmptySymbol() {
  return (
    <Text color="gray.400" fontSize={12}>
      {EMPTY_VALUE_SYMBOL}
    </Text>
  )
}
