import type {
  IntegrationProvider,
  IntegrationPublicFields,
  MergeIntegrationAccountingType,
  MergeIntegrationHRISType,
} from "@brm/schema-types/types.js"
import { Image, Tooltip, type IconProps } from "@chakra-ui/react"
import { useMemo, type FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { LinkOrSpan } from "../Link.js"
import { providerIcons } from "./providers.js"

export type IntegrationProviderType = Pick<IntegrationPublicFields, "provider"> &
  Partial<Pick<IntegrationPublicFields, "merge_hris_type" | "merge_accounting_type" | "display_name" | "logo">>

export const IntegrationProviderIcon: FunctionComponent<
  {
    integration: IntegrationProviderType
    enableLink?: boolean
  } & IconProps
> = ({ enableLink, integration, ...restProps }) => {
  const provider: IntegrationProvider | MergeIntegrationHRISType | MergeIntegrationAccountingType = useMemo(() => {
    // We don't do integration.accounting_sub_type || integration.hris_type || integration.provider directly
    // because models like the reconciled_transaction_view condense multiple providers into one. This can lead to
    // records that have an integration.provider of "brex" but an accounting sub_type present. In those cases
    // we want to default to the provider type instead of the accounting sub_type.
    switch (integration.provider) {
      case "merge_accounting_link_token":
        return integration.merge_accounting_type || integration.provider
      case "merge_hris_link_token":
        return integration.merge_hris_type || integration.provider
      default:
        return integration.provider
    }
  }, [integration])

  const ProviderIcon = providerIcons[provider]

  return (
    <Tooltip
      isDisabled={!integration.display_name}
      label={
        <FormattedMessage
          id="integration.tooltip"
          description="The tooltip on the integration icon."
          defaultMessage="{integrationName} integration"
          values={{ integrationName: integration.display_name }}
        />
      }
    >
      <LinkOrSpan
        to={enableLink ? "/settings/integrations" : undefined}
        display="inline-flex"
        alignItems="center"
        gap={1}
      >
        {integration.logo && integration.provider === "plaid_access_token" ? (
          // Integration.logo is a base64 string
          <Image src={`data:image/png;base64,${integration.logo}`} height="1.5lh" width="1.5lh" />
        ) : (
          <ProviderIcon {...restProps} />
        )}
      </LinkOrSpan>
    </Tooltip>
  )
}
