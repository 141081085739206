import type { CommentTimelineEvent, TimelineEventReplyTimelineEvent } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  Tooltip,
  chakra,
  useToast,
} from "@chakra-ui/react"
import { useRef, useState, type FunctionComponent } from "react"
import { createPortal } from "react-dom"
import { Controller, useForm, type Control, type UseFormSetValue } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { Descendant } from "slate"
import { isNotNull } from "typed-assert"
import {
  useDeleteTimelineV1EventsByIdMutation,
  usePutTimelineV1EventsByIdMutation,
} from "../../app/services/generated-api.js"
import { timelineGridGapPx, timelineIconsWidthPx } from "../../features/timeline/constants.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { validateRichTextRequired } from "../../util/validation.js"
import RichTextDisplay from "../RichTextEditor/RichTextDisplay.js"
import RichTextEditor from "../RichTextEditor/RichTextEditor.js"
import { EMPTY_RICH_TEXT_BODY, trimRichTextWhiteSpace } from "../RichTextEditor/util/common.js"
import { Timestamp } from "../Timestamp.js"
import { CommentActions } from "./CommentActions.js"
import { ReactionRow } from "./ReactionRow.js"

interface FormState {
  richTextBody: Descendant[] | null
}

/**
 * Displays a single body of a timeline event which can either be a comment or a timeline_event_reply.
 */
export const CommentTimelineEventBody: FunctionComponent<
  {
    event: CommentTimelineEvent | (TimelineEventReplyTimelineEvent & { isLast?: boolean })
    onClickReply?: () => void
    highlight?: boolean
  } & GetLogoForOrganizationProps
> = ({ event, onClickReply, highlight, getLogoToShowByOrganizationId }) => {
  const intl = useIntl()
  const toast = useToast()

  const [isEditMode, setIsEditMode] = useState(false)
  const [promptDelete, setPromptDelete] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)

  const [deleteTimelineEvent, { isLoading: isDeleting }] = useDeleteTimelineV1EventsByIdMutation()
  const [editTimelineEvent, { isLoading: isEditSaving }] = usePutTimelineV1EventsByIdMutation()

  const form = useForm<FormState>({
    defaultValues: {
      richTextBody: event.rich_text_body as Descendant[] | null,
    } satisfies FormState,
    disabled: !isEditMode,
  })
  const formId = `edit-comment-form:${event.id}`

  const handleDeleteComment = async () => {
    try {
      await deleteTimelineEvent({
        id: event.id,
      }).unwrap()

      toast({
        status: "success",
        description: intl.formatMessage({
          id: "comment.delete.success.title",
          defaultMessage: "Comment deleted successfully",
          description: "Title of the toast that appears when a comment is deleted",
        }),
      })
    } catch (err) {
      toast({
        status: "error",
        description: intl.formatMessage({
          id: "comment.delete.error.title",
          defaultMessage: "Something went wrong while deleting your comment",
          description: "Title of the toast that appears when an error occurs while deleting a comment",
        }),
      })
    }
  }

  const handleSetEditMode = () => {
    setIsEditMode(true)
    setPromptDelete(false)
  }

  const handlePromptDelete = () => {
    setPromptDelete(true)
    setIsEditMode(false)
  }

  return (
    <>
      {event.type === "timeline_event_reply" && (
        <Box position="relative">
          {/* Draw a line left of the avatar to connect to the divider */}
          <Box
            position="absolute"
            backgroundColor="white"
            right="100%"
            display="block"
            // 1px for half of the border width
            width={`${timelineIconsWidthPx / 2 + timelineGridGapPx + 1}px`}
            // Center of Avatar + border width, which is always at the top left
            height="calc(12px + 2px)"
            borderBottomWidth="2px"
            borderLeftWidth="2px"
            borderBottomLeftRadius="16px"
          />
        </Box>
      )}
      <Box mb={5} className="comment-body">
        <HStack alignItems="start">
          {event.type === "timeline_event_reply" && (
            <Tooltip
              label={
                <chakra.span color="gray.600">
                  <Text as="span" fontWeight="medium" mr={1}>
                    {displayPersonName(event.actor, intl)}
                  </Text>{" "}
                  {/* Timestamp */}
                  <Text as="span" fontSize="xs">
                    <Timestamp dateTime={event.created_at} />
                  </Text>{" "}
                  {/* Edited indicator */}
                  {event.updated_at && (
                    <Text as="span" fontSize="xs">
                      <FormattedMessage
                        id="timeline.comment.edited_at"
                        description="Indicator that a comment has been edited"
                        defaultMessage="(edited)"
                      />
                    </Text>
                  )}
                </chakra.span>
              }
            >
              <Avatar
                name={displayPersonName(event.actor, intl)}
                src={getPublicImageGcsUrl(event.actor?.profile_image?.gcs_file_name)}
                outline="4px solid white"
              />
            </Tooltip>
          )}
          {isEditMode ? (
            <CommentBodyEditMode
              control={form.control}
              setValue={form.setValue}
              formId={formId}
              formRef={formRef}
              isEditSaving={isEditSaving}
              isPrivate={event.type === "comment" ? (event.is_private ?? undefined) : undefined}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            />
          ) : (
            <Box position="relative" minW={0} flex={1}>
              <Box
                borderWidth={highlight ? "2px" : "1px"}
                px={3}
                py={2}
                borderColor={highlight ? "brand.600" : "none"}
                borderRadius="lg"
                borderTopLeftRadius="none"
                overflowWrap="anywhere"
                // Comment should only show as private if is_private is explicitly false
                background={event.type === "comment" && event.is_private === false ? "blue.50" : "white"}
              >
                <RichTextDisplay
                  content={event.rich_text_body as Descendant[]}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                />
              </Box>
              <Flex position="absolute" bottom={-4} width="100%" justifyContent="space-between" zIndex={1} px={1.5}>
                <Flex flexWrap="wrap">
                  <ReactionRow event={event} />
                </Flex>
                <Flex flexShrink={0}>
                  {!isEditMode && !promptDelete && (
                    <CommentActions
                      event={event}
                      onClickReply={onClickReply}
                      onClickEdit={handleSetEditMode}
                      onClickDelete={handlePromptDelete}
                    />
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
        </HStack>
        {promptDelete && (
          <Flex justifyContent="end" pt={1} gap={2}>
            <Button
              variant="unstyled"
              color="gray.600"
              size="sm"
              height="auto"
              onClick={() => setPromptDelete(false)}
              isDisabled={isDeleting}
            >
              <FormattedMessage
                id="timeline.comment.delete.cancel"
                description="Confirmation message shown to user to cancel deletion of a comment on a timeline event"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              variant="unstyled"
              color="error.500"
              size="sm"
              height="auto"
              onClick={handleDeleteComment}
              isDisabled={isDeleting}
            >
              <FormattedMessage
                id="timeline.comment.delete.confirmation"
                description="Confirmation message shown to user to confirm deletion of a comment on a timeline event"
                defaultMessage="Delete"
              />
            </Button>
          </Flex>
        )}
        {isEditMode && (
          <Flex justifyContent="end" pt={1}>
            <Button variant="ghost" size="sm" onClick={() => setIsEditMode(false)} isDisabled={isEditSaving}>
              <FormattedMessage
                id="timeline.comment.edit.cancel"
                description="Confirmation message shown to user to cancel editing of a comment on a timeline event"
                defaultMessage="Cancel"
              />
            </Button>
            <Button variant="ghost" colorScheme="brand" size="sm" type="submit" form={formId} isDisabled={isEditSaving}>
              <FormattedMessage
                id="timeline.comment.save.confirmation"
                description="Confirmation message shown to user to confirm deletion of a comment on a timeline event"
                defaultMessage="Save"
              />
            </Button>
          </Flex>
        )}
        {/* Render form in a portal because forms cannot be nested in HTML (but you can use the `form` attribute to achieve the same effect) */}
        {createPortal(
          <chakra.form
            ref={formRef}
            method="dialog"
            id={formId}
            noValidate
            onSubmit={async (e) => {
              e.preventDefault()
              e.stopPropagation()
              await form.handleSubmit(async (formValues) => {
                isNotNull(formValues.richTextBody, "Comment update must have a body")
                try {
                  await editTimelineEvent({
                    id: event.id,
                    timelineUpdate: {
                      rich_text_body: trimRichTextWhiteSpace(formValues.richTextBody),
                    },
                  })
                  toast({
                    status: "success",
                    description: intl.formatMessage({
                      id: "comment.save.success.title",
                      defaultMessage: "Comment saved!",
                      description: "Title of the toast that appears when a comment is saved",
                    }),
                  })
                } catch (_) {
                  toast({
                    status: "error",
                    description: intl.formatMessage({
                      id: "comment.save.error.title",
                      defaultMessage: "Something went wrong while saving your comment",
                      description: "Title of the toast that appears when an error occurs while saving a comment",
                    }),
                  })
                }
                // Timeout before remounting the comment view to make sure that the form renders
                // with the new value of the rich text body for the uncontrolled component RichTextEditor
                setTimeout(() => setIsEditMode(false), 500)
              })(e)
            }}
          />,
          document.body,
          formId
        )}
      </Box>
    </>
  )
}

function CommentBodyEditMode({
  control,
  setValue,
  formId,
  formRef,
  isEditSaving,
  getLogoToShowByOrganizationId,
  isPrivate,
}: {
  control: Control<FormState>
  setValue: UseFormSetValue<FormState>
  formId: string
  formRef: React.RefObject<HTMLFormElement>
  isEditSaving: boolean
  isPrivate?: boolean
} & GetLogoForOrganizationProps) {
  const intl = useIntl()

  return (
    <Controller
      name="richTextBody"
      control={control}
      rules={{
        validate: (value) =>
          validateRichTextRequired(
            value,
            intl.formatMessage({
              id: "comment.input.body.validate.empty",
              description: "Validation message shown when user attempts to create a comment that is empty",
              defaultMessage: "Comment cannot be empty",
            })
          ),
      }}
      render={({ field, fieldState }) => (
        <FormControl isRequired isInvalid={fieldState.invalid}>
          <RichTextEditor
            form={formId}
            initialValue={field.value ?? EMPTY_RICH_TEXT_BODY}
            onChange={(value) => {
              setValue("richTextBody", value)
              field.onChange(value)
            }}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            isPrivate={isPrivate}
            containerProps={{
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  if ((event.metaKey || event.ctrlKey) && !isEditSaving) {
                    formRef.current?.requestSubmit()
                  }
                }
              },
            }}
            hasError={fieldState.invalid}
          />
          {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
        </FormControl>
      )}
    />
  )
}
