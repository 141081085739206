import type { SellerLinkData } from "@brm/schema-types/types.js"
import { Box, Stack, Text } from "@chakra-ui/react"
import type { FC } from "react"
import { FormattedMessage } from "react-intl"
import { useGetWorkflowV1SellerLinksQuery } from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"

const SellerHomePage = () => {
  const { data } = useGetWorkflowV1SellerLinksQuery()

  const approvedOpportunities = data?.links.filter((link) => {
    return link.workflowRun.status === "approved"
  })

  const rejectedOpportunities = data?.links.filter((link) => {
    return link.workflowRun.status === "rejected"
  })

  const inProgressOpportunities = data?.links.filter((link) => {
    return link.workflowRun.status === "in_progress"
  })

  const cancelledOpportunities = data?.links.filter((link) => {
    return link.workflowRun.status === "cancelled"
  })

  return (
    <Stack>
      <Text size="md" fontWeight="semibold" textAlign="center" paddingTop={8}>
        <FormattedMessage
          id="sellerHomePage.heading"
          description="Heading on seller home page"
          defaultMessage="Your Requests"
        />
      </Text>
      {inProgressOpportunities && inProgressOpportunities.length >= 1 && (
        <>
          <Text size="sm" fontWeight="semibold" pt={8} pl={8}>
            <FormattedMessage
              id="sellerHomePage.heading"
              description="Heading on seller home page"
              defaultMessage="In Progress Requests"
            />
          </Text>
          {inProgressOpportunities.map((link) => {
            return <SellerLink key={link.link.id} link={link} />
          })}
        </>
      )}
      {approvedOpportunities && approvedOpportunities.length >= 1 && (
        <>
          <Text size="sm" fontWeight="semibold" pt={8} pl={8}>
            <FormattedMessage
              id="sellerHomePage.approvedHeading"
              description="Heading for approved requests"
              defaultMessage="Approved Requests"
            />
          </Text>
          {approvedOpportunities.map((link) => {
            return <SellerLink key={link.link.id} link={link} />
          })}
        </>
      )}
      {rejectedOpportunities && rejectedOpportunities.length >= 1 && (
        <>
          <Text size="sm" fontWeight="semibold" pt={8} pl={8}>
            <FormattedMessage
              id="sellerHomePage.rejectedHeading"
              description="Heading for rejected requests"
              defaultMessage="Rejected Requests"
            />
          </Text>
          {rejectedOpportunities.map((link) => {
            return <SellerLink key={link.link.id} link={link} />
          })}
        </>
      )}
      {cancelledOpportunities && cancelledOpportunities.length >= 1 && (
        <>
          <Text size="sm" fontWeight="semibold" pt={8} pl={8}>
            <FormattedMessage
              id="sellerHomePage.cancelledHeading"
              description="Heading for cancelled requests"
              defaultMessage="Cancelled Requests"
            />
          </Text>
          {cancelledOpportunities.map((link) => {
            return <SellerLink key={link.link.id} link={link} />
          })}
        </>
      )}
    </Stack>
  )
}

interface SellerLinkProps {
  link: SellerLinkData
}

const SellerLink: FC<SellerLinkProps> = ({ link: { link, workflowRun, buyerOrganization } }) => {
  return (
    <Box
      border={1}
      borderColor="gray.300"
      borderStyle="solid"
      borderRadius="lg"
      padding={10}
      margin={5}
      as={Link}
      to={`/links/${link.auth_code}`}
    >
      <FormattedMessage
        defaultMessage="{buyerOrganization}: {workflowRun}"
        description="Action footer in email"
        id="email.actionFooter"
        values={{
          buyerOrganization: buyerOrganization.display_name,
          workflowRun: workflowRun.display_name,
        }}
      />
    </Box>
  )
}

export default SellerHomePage
