import { Flex } from "@chakra-ui/react"
import type { User } from "../../../app/services/generated-api.js"
import PersonDisplay from "../../../features/person/PersonDisplay.js"
import { Link } from "../../Link.js"

export default function UserCell({ user }: { user: User }) {
  return (
    <Flex flexShrink={1} height="100%" alignItems="center">
      <Link to={`/people/${user.person.id}`} maxW="100%">
        <PersonDisplay {...user} />
      </Link>
    </Flex>
  )
}
