import type { FieldSourceOutputProperties } from "@brm/schema-types/types.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { Button, Flex, Icon, Tooltip, chakra } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { FormattedMessage, useIntl } from "react-intl"
import { isPresent } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import { InfoIcon, SearchIcon } from "../icons/icons.js"
import { Timestamp } from "../Timestamp.js"

export const FieldSourceFooter = ({
  fieldSource,
  rootSchema,
  onClick,
}: {
  fieldSource: FieldSourceOutputProperties
  rootSchema?: ReadonlyDeep<JSONSchema>
  onClick?: () => void
}) => {
  const intl = useIntl()
  switch (fieldSource.type) {
    case "user": {
      if (!fieldSource.source_display_name) {
        return null
      }
      if (fieldSource.assigned_by_metadata && onClick) {
        const sourceDisplayName = fieldSource.assigned_by_metadata.object_field_source.source_display_name
        const source = (
          <Button
            color="brand.600"
            onClick={onClick}
            variant="link"
            fontWeight="normal"
            display="inline"
            textAlign="left"
            whiteSpace="normal"
          >
            {sourceDisplayName}
            <chakra.span flexShrink={0} display="inline-flex" verticalAlign="bottom">
              <Icon as={SearchIcon} marginX={1} />
            </chakra.span>
          </Button>
        )
        return fieldSource.updated_at ? (
          <FormattedMessage
            defaultMessage="Assigned by {user} from {source}, {timestamp}"
            description="The source field for a field manually edited by a user"
            id="schemaForm.sourceField.helperText.user"
            values={{
              user: fieldSource.source_display_name,
              source,
              timestamp: <Timestamp dateTime={fieldSource.updated_at} />,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Assigned by {user} from {source}"
            description="The source field for a field manually edited by a user"
            id="schemaForm.sourceField.helperText.user"
            values={{
              user: fieldSource.source_display_name,
              source,
            }}
          />
        )
      }
      return fieldSource.updated_at ? (
        <FormattedMessage
          defaultMessage="Updated by {source}, {timestamp}"
          description="The source field for a field manually edited by a user"
          id="schemaForm.sourceField.helperText.user"
          values={{
            source: fieldSource.source_display_name,
            timestamp: <Timestamp dateTime={fieldSource.updated_at} />,
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="Updated by {source}"
          description="The source field for a field manually edited by a user"
          id="schemaForm.sourceField.helperText.user"
          values={{
            source: fieldSource.source_display_name,
          }}
        />
      )
    }
    case "derived": {
      const derivedValues =
        fieldSource.derived_from
          ?.map((field) => {
            const fieldSchema = getSchemaAtPath(rootSchema, field)
            return fieldSchema ? getTitle(field, fieldSchema) : undefined
          })
          .filter(isPresent) ?? []

      return (
        <Flex gap={1} alignItems="center">
          <FormattedMessage
            defaultMessage="Suggested by BRM"
            description="The source field suggested by BRM"
            id="schemaForm.sourceField.helperText.suggested"
          />
          {derivedValues.length > 0 && (
            <Tooltip
              shouldWrapChildren
              label={
                <FormattedMessage
                  defaultMessage="Derived from {derivedValues}"
                  description="The source field for a field calculated from other fields"
                  id="schemaForm.sourceField.helperText.calculated"
                  values={{ derivedValues: intl.formatList(derivedValues) }}
                />
              }
            >
              <Icon as={InfoIcon} />
            </Tooltip>
          )}
        </Flex>
      )
    }
    case "document":
    case "transaction": {
      const displayName = fieldSource.source_display_name
      const source = onClick ? (
        <Button
          color="purple.500"
          onClick={onClick}
          variant="link"
          fontWeight="normal"
          display="inline"
          textAlign="left"
          whiteSpace="normal"
        >
          {displayName}
          <chakra.span flexShrink={0} display="inline-flex" verticalAlign="bottom">
            <Icon as={SearchIcon} marginX={1} />
          </chakra.span>
        </Button>
      ) : (
        displayName
      )
      return displayName ? (
        <FormattedMessage
          defaultMessage="Extracted from {source}"
          description="The source field for a field extracted from a document"
          id="schemaForm.sourceField.helperText.extraction"
          values={{
            source,
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="Extracted from deleted source"
          description="The source field for a field extracted from an unknown source"
          id="schemaForm.sourceField.helperText.extraction.unknown"
        />
      )
    }
    default:
      return null
  }
}
