import type { IntegrationProvider, IntegrationSummary, InviteUser } from "@brm/schema-types/types.js"
import { mapBy } from "@brm/util/collections.js"
import { Card, Stack, chakra, useToast } from "@chakra-ui/react"
import { useCallback, useMemo, type FunctionComponent } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetIntegrationV1PrivateQuery,
  useGetOrganizationV1CurrentQuery,
  useGetUserV1WhoamiQuery,
  usePutUserV1ByIdMutation,
  type Organization,
  type UserWithOrganization,
} from "../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { initializeReactHookFromState } from "../../util/form.js"
import { log } from "../../util/logger.js"
import { ConnectGmailCard } from "../integrations/ConnectGmailCard.js"
import { profileIntegrationConnectOptions } from "../integrations/constants.js"
import InviteOrUpdateUserFormContent from "../organization/invites/InviteOrUpdateUserFormContent.js"
import AuthenticationSettings from "./AuthenticationSettings.js"
import { SettingsHeader } from "./SettingsHeader.js"

export default function UserProfileSettings() {
  const { data: user } = useGetUserV1WhoamiQuery()
  const { data: organization } = useGetOrganizationV1CurrentQuery()
  const { data: integrations } = useGetIntegrationV1PrivateQuery()

  const integrationByProvider = useMemo((): Map<IntegrationProvider, IntegrationSummary> | null => {
    return integrations ? mapBy(integrations, (integration) => integration.provider) : null
  }, [integrations])

  if (!user || !organization || !integrationByProvider) {
    return null
  }

  return (
    <UserProfileSettingsPage user={user} integrationByProvider={integrationByProvider} organization={organization} />
  )
}

// This is a separate component so that it can receive the fetched `legalAgreement` as opposed to just the ID and doesn't have to deal with data fetching.
const UserProfileSettingsPage: FunctionComponent<{
  user: UserWithOrganization
  integrationByProvider: Map<IntegrationProvider, IntegrationSummary>
  organization: Organization
}> = ({ user, integrationByProvider, organization }) => {
  const toast = useToast()
  const intl = useIntl()
  const [updateUser] = usePutUserV1ByIdMutation()

  const form = useForm<InviteUser>({ defaultValues: user })

  initializeReactHookFromState(form)

  const submit = useCallback(
    async (values: InviteUser) => {
      try {
        await updateUser({
          id: user.id,
          updateUser: { first_name: values.first_name, last_name: values.last_name },
        }).unwrap()
        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Profile updated successfully"
              description="The toast description for a successful profile update"
              id="settings.profile.toast.success"
            />
          ),
        })
      } catch (err) {
        log.error("Error saving user", err, { values })
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred saving the profile"
              description="The toast description for an error saving the profile"
              id="settings.profile.toast.error"
            />
          ),
        })
      }
    },
    [toast, updateUser, user]
  )

  return (
    <>
      <SettingsHeader>
        <FormattedMessage
          defaultMessage="Profile"
          description="The heading for the user profile settings page"
          id="settings.profile.heading"
        />
      </SettingsHeader>
      <Stack spacing={8} maxWidth="2xl">
        <Card variant="outline" padding={5}>
          <chakra.form
            // Submit on blur if changed
            onBlur={(event) => {
              if (form.formState.isDirty) {
                event.currentTarget.requestSubmit()
              }
            }}
            onSubmit={form.handleSubmit(submit)}
            noValidate={true} // ensure react-hook-form validation is used
          >
            <InviteOrUpdateUserFormContent form={form} userToUpdateId={user.id} hideRoleEditing={true} />
          </chakra.form>
        </Card>
        {profileIntegrationConnectOptions.map((connectOption) => {
          const integration = integrationByProvider.get(connectOption.provider)
          const description = integration?.email
            ? intl.formatMessage(
                {
                  id: "settings.profile.integrations.gmail.description",
                  description: "The description for the Gmail integration when there is an email address",
                  defaultMessage: "BRM is automagically finding vendor documents from the email: {email}",
                },
                { email: integration.email }
              )
            : connectOption.description
          return (
            <ConnectGmailCard
              width="100%"
              key={connectOption.provider}
              connectedIntegration={integrationByProvider.get(connectOption.provider)}
              integrationConnectOption={{ ...connectOption, description }}
            />
          )
        })}
        {organization && !organization.sso_only && <AuthenticationSettings />}
      </Stack>
    </>
  )
}
