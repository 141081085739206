import type { VendorMinimal } from "@brm/schema-types/types.js"
import type { FlexProps } from "@chakra-ui/react"
import { Flex, HStack, Text, Tooltip } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { Link } from "../../Link.js"
import { VendorLogo } from "../../icons/Logo.js"
import { CELL_SIZE_PROPS_MAP, type CellSize } from "./util.js"

interface Props extends FlexProps {
  showTooltip?: boolean
  size?: CellSize
  vendor: Pick<VendorMinimal, "id" | "display_name" | "image_asset">
}

export default function VendorCell({ vendor, showTooltip, size = "md", ...flexProps }: Props) {
  const cellSizeProps = CELL_SIZE_PROPS_MAP[size]
  return (
    <Flex flexShrink={1} flexGrow={1} alignItems="center" {...flexProps}>
      <Link to={`/vendors/${vendor.id}`} maxW="100%">
        <Tooltip
          isDisabled={!showTooltip}
          label={
            <FormattedMessage
              id="vendor.cell.tooltip"
              description="Tooltip explaining the entity displayed here is a vendor"
              defaultMessage="Vendor: {name}"
              values={{
                name: vendor.display_name,
              }}
            />
          }
        >
          <HStack maxW="100%">
            <VendorLogo
              logo={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)}
              boxSize={cellSizeProps.boxSize}
            />
            <Text
              fontWeight={cellSizeProps.fontWeight}
              maxW="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              minW={0}
            >
              {vendor.display_name}
            </Text>
          </HStack>
        </Tooltip>
      </Link>
    </Flex>
  )
}
