import { displayPersonName } from "@brm/util/names.js"
import { Icon, Text, Tooltip } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import type { User } from "../../app/services/generated-api.js"
import { RelativeDateTimeDisplay } from "../Timestamp.js"
import { CircleCheckIcon } from "../icons/icons.js"

interface Props {
  approver?: User
  approvedAt: string
}

export default function FieldApprovalIcon({ approver, approvedAt }: Props) {
  const intl = useIntl()
  return (
    <Tooltip
      label={
        <>
          <Text>
            {approver ? (
              <FormattedMessage
                id="field.approved.tooltip.with_approver"
                description="Tooltip text on hover of a button indicating that a field has been approved with the name of the approver"
                defaultMessage="Approved by {approverName}"
                values={{ approverName: displayPersonName(approver, intl) }}
              />
            ) : (
              // This icon and tooltip is shown if the field is implicitly approved by approving the whole step.
              // The step may be approved by a different user than the approver (super admins) so in that case show
              // a more generic message
              <FormattedMessage
                id="field.approved.tooltip"
                description="Tooltip text on hover of a button indicating that a field has been approved"
                defaultMessage="Approved"
              />
            )}
          </Text>
          <RelativeDateTimeDisplay dateTime={approvedAt} />
        </>
      }
      placement="top-end"
      textAlign="center"
      shouldWrapChildren
    >
      <Icon as={CircleCheckIcon} color="brand.700" />
    </Tooltip>
  )
}
