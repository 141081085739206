import { type PersonListItem, type SavedView } from "@brm/schema-types/types.js"
import { Box, Center, Flex } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { ColumnPinningState } from "@tanstack/react-table"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import type { Paths } from "type-fest"
import { usePostPersonV1Query } from "../../../app/services/generated-api.js"
import DataTable from "../../../components/DataTable/DataTable.js"
import FullDataTableHeader from "../../../components/DataTable/FullDataTableHeader.js"
import TablePageHeader from "../../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../../components/DataTable/SchemaTableColumnCustomization.js"
import { useSchemaColumns } from "../../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../../components/DataTable/use-schema-table-params.js"
import Spinner from "../../../components/spinner.js"
import { packageSortFilterOptionsForAPI, shownColumnsForTableParamState } from "../../../util/schema-table.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import ZeroIntegrationsView from "../../error/ZeroIntegrationsView.js"
import { PERSON_TABLE_DEFAULT_PARAMS, PERSON_TABLE_ID } from "./constants.js"

const defaultColumns: Paths<PersonListItem>[] = [
  "employment_status",
  "hris_groups.department",
  "tool_count",
  "last_login.authorized_at",
]

// The primary column is the root person object (so that the cell can access the name, logo and ID).
const primaryColumn = ""
const primarySearchColumn: Paths<PersonListItem> = "display_name"

const columnPinning: ColumnPinningState = {
  left: [primaryColumn],
}

const savedViews: SavedView[] = []

export default function PersonList() {
  const intl = useIntl()

  const personSchema = useObjectSchema("Person")

  useLocalStorageTableParamsSync(PERSON_TABLE_ID)
  const { tableParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: PERSON_TABLE_DEFAULT_PARAMS,
    objectSchema: personSchema,
    primarySearchColumn,
    savedViews,
  })

  const apiParams = useMemo(
    () => tableParams && personSchema && packageSortFilterOptionsForAPI(tableParams, personSchema, intl),
    [intl, tableParams, personSchema]
  )

  const { data, isFetching, isLoading } = usePostPersonV1Query(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )

  // SET UP COLUMNS
  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const columns = useSchemaColumns<PersonListItem>({ objectSchema: personSchema, shownColumns, primaryColumn })

  if (!tableParams || !personSchema || !shownColumns || !columns) {
    return null
  }

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  return (
    <Flex justifyContent="center" flexDir="column" flexGrow={1} minHeight={0}>
      <FullDataTableHeader
        displayTitle={intl.formatMessage({
          id: "persons.title",
          description: "title of the people page",
          defaultMessage: "People",
        })}
      >
        <TablePageHeader
          tableId={PERSON_TABLE_ID}
          primarySearch={{
            column: primarySearchColumn,
            currentPrimaryFilter: tableParams.primaryFilter?.fields,
            placeholder: intl.formatMessage({
              id: "personList.searchPlaceholder",
              description: "placeholder text for the person search input",
              defaultMessage: "Search People",
            }),
            onPrimaryFilterChange: (fields) =>
              updateTableParams({ primaryFilter: fields && { column: primarySearchColumn, fields } }),
          }}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
          objectSchema={personSchema}
          selectedColumns={shownColumns}
          afterSavedView={
            <SchemaTableColumnCustomization
              activeColumns={shownColumns}
              primaryColumn={primaryColumn}
              onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
              objectSchema={personSchema}
            />
          }
        />
      </FullDataTableHeader>
      {data ? (
        <DataTable<PersonListItem>
          data={data.persons}
          columns={columns}
          columnPinning={columnPinning}
          columnOrder={[primaryColumn, ...shownColumns]}
          sorting={tableParams.sorting}
          onSortingChange={(newSorting) =>
            newSorting instanceof Function
              ? updateTableParams({ sorting: newSorting(tableParams.sorting) })
              : updateTableParams({ sorting: newSorting })
          }
          paginationProps={{
            page: tableParams.page,
            pageSize: tableParams.pageSize,
            onPageChange: (page) => updateTableParams({ page }),
            onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
            totalListElements: data.total,
          }}
          isSticky
        />
      ) : (
        <Box flexGrow={1}>
          {!isFetching && tableParams.filterMap.size === 0 && tableParams.sorting.length === 0 && (
            <ZeroIntegrationsView />
          )}
        </Box>
      )}
    </Flex>
  )
}
