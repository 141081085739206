import type { InvitedSellerUser } from "@brm/schema-types/types.js"
import { formatPersonName } from "@brm/util/names.js"
import {
  Avatar,
  AvatarBadge,
  chakra,
  defineStyle,
  forwardRef,
  HStack,
  Icon,
  omitThemingProps,
  Stack,
  Text,
  Tooltip,
  useMultiStyleConfig,
  type AvatarProps,
  type HTMLChakraProps,
  type SystemProps,
  type SystemStyleObject,
  type ThemingProps,
} from "@chakra-ui/react"
import { Children, cloneElement, isValidElement, type FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { UserIcon } from "../../components/icons/icons.js"
import { RelativeDateTimeDisplay } from "../../components/Timestamp.js"
import { getPublicImageGcsUrl } from "../../util/url.js"

interface SellerUsersAvatarGroupProps {
  sellerUsers: Array<InvitedSellerUser>
  sellerLogoGcsFileName?: string
}

const SellerUsersAvatarGroup: FC<SellerUsersAvatarGroupProps> = ({ sellerUsers, sellerLogoGcsFileName }) => {
  const maxNumberOfIconsToShow = 3

  const intl = useIntl()
  if (sellerUsers.length === 0) {
    return undefined
  }

  const overflow = sellerUsers.length - maxNumberOfIconsToShow
  const sellerLogoURL = sellerLogoGcsFileName && getPublicImageGcsUrl(sellerLogoGcsFileName)

  const overflowAvatarTooltip = overflow > 0 && (
    <Stack overflow="hidden" textOverflow="ellipsis">
      {sellerUsers.slice(maxNumberOfIconsToShow).map((user) => {
        const displayName =
          user.first_name && user.last_name
            ? formatPersonName({ first_name: user.first_name, last_name: user.last_name }, intl)
            : undefined
        return (
          <UserTooltipSection key={user.email} user={user} displayName={displayName} sellerLogoUrl={sellerLogoURL} />
        )
      })}
    </Stack>
  )

  return (
    <AvatarGroup excessTooltipLabel={overflowAvatarTooltip} max={maxNumberOfIconsToShow} spacing={-1}>
      {sellerUsers.map((user) => {
        const displayName =
          user.first_name && user.last_name
            ? formatPersonName({ first_name: user.first_name, last_name: user.last_name }, intl)
            : undefined
        return (
          <AvatarWithTooltip
            toolTipLabel={<UserTooltipSection user={user} displayName={displayName} />}
            key={user.email}
            outline="2px solid white"
            icon={<Icon as={UserIcon} fontSize="20px" />}
            name={displayName || user.email}
          >
            {sellerLogoURL && <AvatarBadge as={Avatar} boxSize={3} src={sellerLogoURL} />}
          </AvatarWithTooltip>
        )
      })}
    </AvatarGroup>
  )
}

export default SellerUsersAvatarGroup

const AvatarWithTooltip = forwardRef<AvatarProps & { toolTipLabel: React.ReactNode }, "div">(
  ({ toolTipLabel, ...avatarProps }, ref) => (
    <Tooltip label={toolTipLabel} aria-label="Tooltip">
      <Avatar {...avatarProps} ref={ref} />
    </Tooltip>
  )
)

const UserTooltipSection: FC<{ user: InvitedSellerUser; displayName?: string; sellerLogoUrl?: string }> = ({
  user,
  displayName,
  sellerLogoUrl,
}) => {
  return (
    <Stack key={user.email} mt={1} mb={1}>
      <HStack>
        <Avatar
          outline="2px solid white"
          size="sm"
          icon={<Icon as={UserIcon} fontSize="20px" />}
          name={displayName || user.email}
        >
          {sellerLogoUrl && <AvatarBadge as={Avatar} boxSize={3} src={sellerLogoUrl} />}
        </Avatar>
        <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
          {displayName || user.email}
        </Text>
      </HStack>
      <Text ml="1" color="gray.500">
        {user.opened_at ? (
          <FormattedMessage
            defaultMessage="Opened {datetime}"
            description="Label for open date"
            id="sellerUsersAvatarGroup.invitedDate.label"
            values={{
              datetime: <RelativeDateTimeDisplay dateTime={user.opened_at} />,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Invited {datetime}"
            description="Label for invited date"
            id="sellerUsersAvatarGroup.invitedDate.label"
            values={{
              datetime: <RelativeDateTimeDisplay dateTime={user.invited_at} />,
            }}
          />
        )}
      </Text>
    </Stack>
  )
}

// This is taken directly from Chakra's source code to enable tooltips on avatar group's excess component.
// https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/avatar/avatar-group.tsx

function getValidChildren(children: React.ReactNode) {
  return Children.toArray(children).filter((child) => isValidElement(child)) as React.ReactElement[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function compact<T extends Record<any, any>>(object: T) {
  const clone = Object.assign({}, object)
  for (const key in clone) {
    if (clone[key] === undefined) delete clone[key]
  }
  return clone
}

const baseStyle = defineStyle({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  textTransform: "uppercase",
  fontWeight: "medium",
  position: "relative",
  flexShrink: 0,
})

interface AvatarGroupOptions {
  /**
   * The children of the avatar group.
   *
   * Ideally should be `Avatar` and `MoreIndicator` components
   */
  children: React.ReactNode
  /**
   * The space between the avatars in the group.
   * @default "-0.75rem"
   * @type SystemProps["margin"]
   */
  spacing?: SystemProps["margin"]

  // This is our custom addition to the group avatars
  excessTooltipLabel?: React.ReactNode

  /**
   * The maximum number of visible avatars
   */
  max?: number
}

export interface AvatarGroupProps
  extends AvatarGroupOptions,
    Omit<HTMLChakraProps<"div">, "children">,
    ThemingProps<"Avatar"> {}

/**
 * AvatarGroup displays a number of avatars grouped together in a stack.
 */

export const AvatarGroup = forwardRef<AvatarGroupProps, "div">(function AvatarGroup(props, ref) {
  const styles = useMultiStyleConfig("Avatar", props)

  const {
    children,
    borderColor,
    max,
    spacing = "-0.75rem",
    borderRadius = "full",
    excessTooltipLabel,
    ...rest
  } = omitThemingProps(props)

  const validChildren = getValidChildren(children)

  /**
   * get the avatars within the max
   */
  // eslint-disable-next-line eqeqeq
  const childrenWithinMax = max != null ? validChildren.slice(0, max) : validChildren

  /**
   * get the remaining avatar count
   */
  // eslint-disable-next-line eqeqeq
  const excess = max != null ? validChildren.length - max : 0

  /**
   * Reversing the children is a great way to avoid using zIndex
   * to overlap the avatars
   */
  const reversedChildren = childrenWithinMax.reverse()

  const clones = reversedChildren.map((child, index) => {
    const isFirstAvatar = index === 0

    const childProps = {
      marginEnd: isFirstAvatar ? 0 : spacing,
      size: props.size,
      borderColor: child.props.borderColor ?? borderColor,
      showBorder: true,
    }

    return cloneElement(child, compact(childProps))
  })

  const groupStyles: SystemStyleObject = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    ...styles.group,
  }

  const excessStyles: SystemStyleObject = {
    borderRadius,
    marginStart: spacing,
    ...baseStyle,
    ...styles.excessLabel,
  }

  return (
    <chakra.div
      ref={ref}
      role="group"
      __css={groupStyles}
      {...rest}
      className={`chakra-avatar__group ${props.className}`}
    >
      {excess > 0 && (
        <Tooltip label={excessTooltipLabel}>
          <chakra.span className="chakra-avatar__excess" __css={excessStyles}>
            {`+${excess}`}
          </chakra.span>
        </Tooltip>
      )}
      {clones}
    </chakra.div>
  )
})
