import type { TableIdentifier } from "@brm/schema-types/types.js"
import type { TableParamsState } from "../../util/schema-table.js"

export const LEGAL_AGREEMENTS_TABLE_ID: TableIdentifier = "legal-agreements"

export const LEGAL_AGREEMENTS_TABLE_DEFAULT_PARAMS = {
  sorting: [{ id: "total_contract_value", desc: true }],
} satisfies Partial<TableParamsState<string>>

export const AGREEMENT_LIST_MINIMIZE_STORAGE_KEY = "agreement-list-minimize"
