import type { TextProps } from "@visx/text"
import type { AllPaymentMethods } from "./types.js"

export const defaultAccentColor = "var(--chakra-colors-blue-600)"
export const defaultGridStroke = "var(--chakra-colors-gray-300)"
export const defaultTickLabelProps: Partial<TextProps> = {
  fill: "var(--chakra-colors-gray-600)",
  fontFamily: "inherit",
  fontWeight: "inherit",
}

export const paymentMethodColors: Record<AllPaymentMethods, string> = {
  bill_payment: "var(--chakra-colors-blue-500)",
  credit_card: "var(--chakra-colors-purple-500)",
  other: "var(--chakra-colors-green-500)",
}

/** For tool/vendor spend chart */
export const smallChartHeight = 200

export const pieChartHeight = 200

export const pieChartWidth = 250

export const PIE_CHART_DEFAULT_MARGIN = { top: 25, bottom: 25, left: 50, right: 50 }
