import type { CustomizableObjectType } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { IntlFormatters } from "react-intl"
import type { CriterionFormState } from "./types.js"

export const customizableObjectTypes = Object.keys({
  /* eslint-disable @typescript-eslint/naming-convention */
  Tool: true,
  Vendor: true,
  LegalAgreement: true,
  /* eslint-enable @typescript-eslint/naming-convention */
} satisfies Record<CustomizableObjectType, true>) as CustomizableObjectType[]

export function formatCustomizableObjectType(type: CustomizableObjectType, intl: IntlFormatters) {
  switch (type) {
    case "Tool":
      return intl.formatMessage({
        defaultMessage: "Tool",
        id: "customizableObjectType.tool",
        description: "Label for the customizable object type tool",
      })
    case "Vendor":
      return intl.formatMessage({
        defaultMessage: "Vendor",
        id: "customizableObjectType.vendor",
        description: "Label for the customizable object type vendor",
      })
    case "LegalAgreement":
      return intl.formatMessage({
        defaultMessage: "Agreement",
        id: "customizableObjectType.legal",
        description: "Label for the customizable object type legal",
      })
    default:
      unreachable(type)
  }
}

export const DEFAULT_CRITERION_FORM_STATE: Readonly<CriterionFormState> = {
  title: "",
  description: "",
  object_type: "Tool",
  category: null,
}
