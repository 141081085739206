import type { ToastId, UseToastOptions } from "@chakra-ui/react"
import { getAPIErrorMessage } from "./error.js"

export async function apiCallToastWrapper<ApiReturnValue>({
  apiCall,
  toast,
  onSuccess,
  successMessage,
  onError,
  fallbackErrorMessage,
}: {
  apiCall: () => Promise<ApiReturnValue>
  onSuccess?: (apiReturnValue: ApiReturnValue) => void
  onError?: (err: unknown) => void
  toast: (options?: UseToastOptions | undefined) => ToastId
  fallbackErrorMessage: string
  successMessage: string
}) {
  try {
    const apiReturnValue = await apiCall()
    toast({
      status: "success",
      description: successMessage,
    })
    onSuccess?.(apiReturnValue)
  } catch (err) {
    toast({
      status: "error",
      duration: 60_000,
      description: getAPIErrorMessage(err) ?? fallbackErrorMessage,
    })
    onError?.(err)
  }
}
