import type { WorkflowRunStep } from "@brm/schema-types/types.js"
import { Badge, Icon, Text, type BadgeProps } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { DotIcon } from "../../../../../components/icons/icons.js"
import { getStepStatusColor } from "../../status-colors.js"
import { displayWorkflowRunStepStatus } from "../../utils.js"

export function WorkflowRunStepStatusBadge({ step, ...props }: { step: Pick<WorkflowRunStep, "status"> } & BadgeProps) {
  const intl = useIntl()
  return (
    <Badge
      colorScheme={getStepStatusColor(step)}
      variant="subtleOutlined"
      size="sm"
      fontSize="xs"
      display="inline-flex"
      alignItems="center"
      gap={1}
      minW={0}
      {...props}
    >
      <Icon as={DotIcon} color={`${getStepStatusColor(step)}.500`} />
      <Text as="span" isTruncated>
        {displayWorkflowRunStepStatus(step, intl)}
      </Text>
    </Badge>
  )
}
