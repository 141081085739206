import type { Receipt } from "@brm/schema-types/types.js"
import { IconButton, Tooltip, type IconButtonProps } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { FileTypeIcon } from "../../components/Document/FileTypeIcon.js"
import { Link } from "../../components/Link.js"

interface Props extends Omit<IconButtonProps, "aria-label" | "icon"> {
  receipt: Receipt
  transaction_id: string
}

export default function ReceiptButton({ receipt, transaction_id, ...props }: Props) {
  const intl = useIntl()

  return (
    <Tooltip label={receipt.document.file_name} shouldWrapChildren>
      <IconButton
        px={1}
        py={5}
        {...props}
        as={Link}
        variant="ghost"
        to={`#transaction=${transaction_id}&receipt=${receipt.id}`}
        aria-label={intl.formatMessage({
          id: "transactions.table.receipt.view.label",
          description: "The label for the view receipt button in the payments table",
          defaultMessage: "View Receipt",
        })}
        icon={<FileTypeIcon mimeType={receipt.document.mime_type} boxSize={8} />}
      />
    </Tooltip>
  )
}
