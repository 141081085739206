import type { EmployeeGroup, ToolOption } from "@brm/schema-types/types.js"
import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Input, Text } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { Select as MultiSelect } from "chakra-react-select"
import { Controller, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetEmployeeGroupV1Query, useGetToolV1ByIdQuery } from "../../../../app/services/generated-api.js"
import BasicOrganizationEntityPicker from "../../../../components/Form/BasicOrganizationEntityPicker.js"
import { CurrencyAmountFormControl } from "../../../../components/Form/CurrencyAmountInput.js"
import OrganizationEntityPicker from "../../../../components/Form/OrganizationEntityPicker.js"
import { ToolOptionPicker } from "../../../../components/Form/ToolOptionPicker.js"
import { VendorOptionPicker } from "../../../../components/Form/VendorOptionPicker.js"
import { ChampIcon } from "../../../../components/icons/icons.js"
import { LegalAgreementPicker, type LegalAgreementOption } from "../../../legal/LegalAgreementPicker.js"
import type { StartWorkflowFormState } from "./util.js"

export default function WorkflowConfirmSection({
  control,
  selectedTool,
  selectedAgreement,
  newLegalAgreementName,
  departments,
}: {
  control: Control<StartWorkflowFormState>
  selectedTool: ToolOption | null
  selectedAgreement: LegalAgreementOption | null
  newLegalAgreementName: string | null
  departments: EmployeeGroup[]
}) {
  const intl = useIntl()

  const tool = selectedTool || selectedAgreement?.tools[0]
  const { data: departmentOptions, isFetching: isFetchingDepartments } = useGetEmployeeGroupV1Query({
    groupType: "department",
  })
  const { data: selectedToolWithDetails } = useGetToolV1ByIdQuery(tool?.id ? { id: tool.id } : skipToken)

  const departmentSelectOptions =
    departmentOptions?.map((group) => ({
      label:
        group.display_name ||
        intl.formatMessage({
          id: "department.unknown.label",
          defaultMessage: "Unknown department",
          description: "Label for unknown department",
        }),
      value: group,
    })) ?? []

  const selectedDepartmentsSet = new Set(departments.map((d) => d.id))

  return (
    <Box>
      <Text>
        <FormattedMessage
          id="requests.start.modal.confirm.header"
          description="Header for the start workflow modal workflow parameters section"
          defaultMessage="3. Confirm information and create request"
        />
      </Text>
      <Controller
        name="display_name"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly marginTop={3}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Request name"
                description="The label for the request name field in the start request modal form"
                id="requests.start.modal.name.label"
              />
            </FormLabel>
            <Input
              isReadOnly
              value={field.value}
              onChange={field.onChange}
              placeholder={intl.formatMessage({
                id: "requests.start.modal.name.placeholder",
                defaultMessage: "Ex. Purchase Nods",
                description: "Placeholder for request name field in the start request modal form",
              })}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller
        name="workflow_owner"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly marginTop={3}>
            <FormLabel display="flex">
              <HStack>
                <FormattedMessage
                  defaultMessage="Champ"
                  description="The label for the request name field in the start request modal form"
                  id="requests.start.modal.name.label"
                />
                <Icon as={ChampIcon} />
              </HStack>
            </FormLabel>
            <OrganizationEntityPicker
              includedEntities={["user", "person"]}
              onChangeUser={(user) => field.onChange(user)}
              initialSelected={field.value}
              placeholder={intl.formatMessage({
                id: "requests.start.modal.champ.placeholder",
                description: "Placeholder text for request owner aka champ",
                defaultMessage: "The Champ will own the request process",
              })}
              isRequired
              isReadOnly
              menuPortalTarget={undefined}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      {selectedTool ? (
        <Controller
          name="tool"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3} isReadOnly>
              <FormLabel>
                <FormattedMessage
                  defaultMessage="Tool name"
                  description="The label for the tool field in the start workflow modal form"
                  id="requests.start.modal.tool.label"
                />
              </FormLabel>
              <ToolOptionPicker value={field.value} onChange={field.onChange} menuPortalTarget={undefined} isReadOnly />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      ) : (
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Tool name"
              description="The label for the tool field in the start workflow modal form"
              id="requests.start.modal.tool.label"
            />
          </FormLabel>
          <ToolOptionPicker value={selectedToolWithDetails} isReadOnly />
        </FormControl>
      )}
      {newLegalAgreementName && (
        <Controller
          name="new_legal_agreement_name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly marginTop={4}>
              <FormLabel>
                <FormattedMessage
                  defaultMessage="Agreement name"
                  description="The label for the agreement name field in the start workflow modal form"
                  id="requests.start.modal.agreement_name.label"
                />
              </FormLabel>
              <Input onChange={field.onChange} value={field.value || ""} isRequired isReadOnly />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      )}
      {selectedAgreement && (
        <Controller
          name="legal_agreement"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly marginTop={3}>
              <FormLabel>
                <FormattedMessage
                  defaultMessage="Agreement Name"
                  description="The label for the tool field in the start workflow modal form"
                  id="requests.start.modal.tool.label"
                />
              </FormLabel>
              <LegalAgreementPicker
                isReadOnly
                value={field.value}
                onChange={field.onChange}
                menuPortalTarget={undefined}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      )}
      {selectedToolWithDetails && (
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Vendor"
              description="The label for the vendor field in the start workflow modal form"
              id="requests.start.modal.vendor.label"
            />
          </FormLabel>
          <VendorOptionPicker value={selectedToolWithDetails.vendor} isReadOnly />
        </FormControl>
      )}
      <Controller
        name="tool_owner"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} marginTop={3} isRequired isReadOnly>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Tool owner"
                description="The label for the tool owner field in the start workflow modal form"
                id="requests.start.modal.tool.owner.label"
              />
            </FormLabel>
            <BasicOrganizationEntityPicker
              // This is an uncontrolled component so if the owner changes we force re-render to make sure it picks up the new "initialValue"
              key={field.value?.id}
              includedEntities={["person"]}
              initialSelected={field.value}
              onChangePerson={(person) => field.onChange(person)}
              isRequired
              isReadOnly
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
              }}
            />
          </FormControl>
        )}
      />
      {/* Only render the department picker if the departments query has completed and some department options were returned */}
      {isFetchingDepartments ||
        (departmentSelectOptions.length > 0 && (
          <Controller
            name="departments"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid} marginTop={3} isReadOnly>
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="Departments"
                    description="The label for the departments field in the start request modal form"
                    id="requests.start.modal.departments.label"
                  />
                </FormLabel>
                <MultiSelect<{ label: string; value: unknown }, true>
                  isMulti={true}
                  value={departmentSelectOptions.filter((t) => selectedDepartmentsSet.has(t.value.id))}
                  options={departmentSelectOptions}
                  onChange={(e) => field.onChange(e.map((v) => v.value))}
                  isReadOnly
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        ))}
      {selectedAgreement && (
        <CurrencyAmountFormControl
          value={selectedAgreement.total_contract_value || null}
          isReadOnly
          legend={
            <FormattedMessage
              defaultMessage="Total contract value"
              description="The label for the total contract value field in the start workflow modal form"
              id="requests.start.modal.agreement.tcv.label"
            />
          }
          marginTop={3}
        />
      )}
      <Controller
        name="target_date"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly marginTop={3}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Complete by"
                description="The label for the complete by field in the start request modal form"
                id="requests.start.modal.complete_by.label"
              />
            </FormLabel>
            <Input type="date" value={field.value || ""} onChange={field.onChange} isReadOnly />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Box>
  )
}
