import type { LegalAgreementMinimal } from "@brm/schema-types/types.js"
import {
  acceptedDocumentExtensionsByMimeType,
  largePdfPageThreshold,
  maxDocumentSize,
} from "@brm/type-helpers/document.js"
import { sha1Hash, uint8ArrayToHex } from "@brm/util/crypto.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { ModalProps, UseModalProps } from "@chakra-ui/react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Spinner,
  Stack,
  Text,
  Tooltip,
  chakra,
  useToast,
} from "@chakra-ui/react"
import pMap from "p-map"
import { useRef, useState, type FunctionComponent } from "react"
import { useDropzone } from "react-dropzone"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import type { DocumentMinimal } from "../../app/services/generated-api.js"
import { usePostDocumentV1Mutation, usePutDocumentV1ByIdMutation } from "../../app/services/generated-api.js"
import { FileTypeIcon } from "../../components/Document/FileTypeIcon.js"
import { uploadFile } from "../../components/Document/upload-document.js"
import { CancelButton } from "../../components/buttons.js"
import { CheckIcon, XIcon } from "../../components/icons/icons.js"
import { onDropRejected } from "../../components/on-drop-rejected.js"
import { log } from "../../util/logger.js"

const maxFiles = 1000

export type LegalDocumentsBulkUploadModalProps = UseModalProps &
  Pick<ModalProps, "returnFocusOnClose"> & {
    onSubmit: (uploadedDocuments: DocumentMinimal[]) => void | Promise<void>
  }

export const LegalDocumentsBulkUploadModal: FunctionComponent<LegalDocumentsBulkUploadModalProps> = ({
  onSubmit,
  returnFocusOnClose,
  ...modalProps
}) => {
  const toast = useToast({ variant: "subtle" })
  const intl = useIntl()

  const [createDocument] = usePostDocumentV1Mutation()
  const [markUploaded] = usePutDocumentV1ByIdMutation()

  // Progress tracking for uploading (dropzone.acceptedFiles is not incremental)
  const [allAcceptedFiles, setAllAcceptedFiles] = useState<File[]>([])
  const [finishedFiles, setFinishedFiles] = useState<Set<File>>(new Set())
  const [erroredFiles, setErroredFiles] = useState<Map<File, { error?: string }>>(new Map())
  const [existingAgreementsByFileName, setExistingAgreementsByFileName] = useState<
    Map<string, LegalAgreementMinimal[]>
  >(new Map())
  // This is what is passed to onSubmit when all uploads finished and "Extract" is clicked
  const [uploadedDocuments, setUploadedDocuments] = useState<DocumentMinimal[]>([])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const quarantinedErrorMessage = intl.formatMessage({
    defaultMessage: "This file did not pass our virus scan. Please contact support if you believe this is an error.",
    description: "The description for the error toast when uploading a quarantined file",
    id: "documents.modal.upload.quarantined.description",
  })

  const dropzone = useDropzone({
    accept: acceptedDocumentExtensionsByMimeType,
    multiple: true,
    maxFiles,
    maxSize: maxDocumentSize,
    // We handle clicks and keyboard events on the button ourselves
    noClick: true,
    noKeyboard: true,
    onDropRejected: onDropRejected(toast, { maxFiles }),
    onDropAccepted: async (newAcceptedFiles) => {
      if (!newAcceptedFiles.length) {
        return
      }
      const allNames = new Set(allAcceptedFiles.map((file) => file.name))
      newAcceptedFiles = newAcceptedFiles
        .filter((file) => !allNames.has(file.name))
        .slice(0, maxFiles - allAcceptedFiles.length)
      setAllAcceptedFiles((files) => [...newAcceptedFiles, ...files])
      await pMap(
        newAcceptedFiles,
        async (file) => {
          try {
            const content = await file.arrayBuffer()
            const contentHash = uint8ArrayToHex(await sha1Hash(content))
            const createdDocumentResponse = await createDocument({
              documentInput: {
                file_name: file.name,
                file_size: file.size,
                mime_type: file.type,
                content_hash: contentHash,
              },
            }).unwrap()

            switch (createdDocumentResponse.status) {
              case "pending": {
                await uploadFile(createdDocumentResponse, file)
                const uploadedDocument = await markUploaded({
                  id: createdDocumentResponse.id,
                  body: { status: "uploaded" },
                }).unwrap()
                if (uploadedDocument.status === "quarantined") {
                  const newErroredFiles = new Map(erroredFiles)
                  newErroredFiles.set(file, { error: quarantinedErrorMessage })
                  setErroredFiles(newErroredFiles)
                } else {
                  setUploadedDocuments((documents) => [...documents, uploadedDocument])
                }
                break
              }
              case "uploaded":
              case "scanned": {
                const allExistingAgreements: LegalAgreementMinimal[] = []
                const agreementIdSet = new Set<string>()

                for (const agreement of createdDocumentResponse.existing_legal_agreements ?? []) {
                  if (!agreementIdSet.has(agreement.id)) {
                    agreementIdSet.add(agreement.id)
                    allExistingAgreements.push(agreement)
                  }
                }

                if (allExistingAgreements.length > 0) {
                  setExistingAgreementsByFileName(
                    new Map(existingAgreementsByFileName.set(file.name, allExistingAgreements))
                  )
                }
                setUploadedDocuments((documents) => [...documents, createdDocumentResponse])
                break
              }
              case "quarantined": {
                const newErroredFiles = new Map(erroredFiles)
                newErroredFiles.set(file, { error: quarantinedErrorMessage })
                setErroredFiles(newErroredFiles)
                break
              }
              default:
                unreachable(createdDocumentResponse)
            }
          } catch (err) {
            const newErroredFiles = new Map(erroredFiles)
            newErroredFiles.set(file, {})
            setErroredFiles(newErroredFiles)
            log.error("Error uploading file", err, { file })
            toast({
              status: "error",
              duration: null,
              description: (
                <FormattedMessage
                  defaultMessage="Error uploading document {fileName}"
                  description="The description for the error toast when uploading a file fails"
                  id="documentUpload.dropzone.uploadError.description"
                  values={{ fileName: file.name }}
                />
              ),
            })
          } finally {
            setFinishedFiles((files) => new Set([...files, file]))
          }
        },
        { concurrency: 1 }
      )
    },
  })

  const uploadButtonRef = useRef<HTMLButtonElement>(null)
  const unprocessedFileCount = allAcceptedFiles.length - finishedFiles.size - erroredFiles.size

  return (
    <Modal
      {...modalProps}
      size="3xl"
      isCentered
      initialFocusRef={uploadButtonRef}
      returnFocusOnClose={returnFocusOnClose}
    >
      <ModalOverlay />
      <ModalContent minH="650px" height="70vh">
        <chakra.form
          display="contents"
          onSubmit={async (event) => {
            event.preventDefault()
            event.stopPropagation()
            setIsSubmitting(true)
            try {
              await onSubmit(uploadedDocuments)
              modalProps.onClose()
            } finally {
              setIsSubmitting(false)
            }
          }}
        >
          <ModalHeader>
            <FormattedMessage
              defaultMessage="Upload Documents"
              description="Header for the upload documents modal"
              id="documents.modal.upload.header"
            />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody minHeight="400px" display="flex" flexDirection="column" gap={6} py={0}>
            <Text>
              <FormattedMessage
                defaultMessage="Upload your contracts, order forms, MSAs, and legal documents, and BRM will take it from here."
                description="Description for uploading documents modal body text"
                id="documents.modal.upload.body"
              />
            </Text>

            <input {...dropzone.getInputProps()} />

            <Stack {...dropzone.getRootProps()} flexGrow={1} justifyContent="top" height="400px">
              <Button
                variant="outline"
                p={8}
                colorScheme="brand"
                onClick={dropzone.open}
                ref={uploadButtonRef}
                flexGrow={allAcceptedFiles.length === 0 ? 1 : undefined}
              >
                <FormattedMessage
                  defaultMessage="Click to upload or drag and drop files here"
                  description="Dropzone text for bulk upload documents modal"
                  id="documents.modal.upload.dropzone.text"
                />
              </Button>
              <List as="ul" overflowY="auto" display="flex" flexDirection="column" gap={2}>
                {allAcceptedFiles.map((file) => {
                  const existingAgreements = existingAgreementsByFileName.get(file.name)
                  const erroredFile = erroredFiles.get(file)

                  return (
                    <ListItem
                      as={Card}
                      size="md"
                      variant="outline"
                      key={file.name + file.size}
                      display="flex"
                      flexDirection="column"
                    >
                      <CardBody display="flex" flexDirection="row" gap={4} p={4}>
                        <FileTypeIcon mimeType={file.type} boxSize={8} />
                        <Flex flexDirection="column" flexGrow={1}>
                          <HStack gap={2} fontWeight="medium" fontSize="sm">
                            <Text as="span">{file.name}</Text>
                            {existingAgreements && (
                              <Tooltip
                                label={
                                  <>
                                    <Text fontWeight="normal">
                                      <FormattedMessage
                                        defaultMessage="This document will not create a new agreement because it is part of {agreementsCount, plural, one {an existing agreement} other {existing agreements}}:"
                                        description="Tooltip for already uploaded files"
                                        id="documents.modal.upload.alreadyUploaded.tooltip"
                                        values={{ agreementsCount: existingAgreements.length }}
                                      />
                                    </Text>
                                    {existingAgreements.map((agreement) => (
                                      <Text key={agreement.id}>{agreement.display_name}</Text>
                                    ))}
                                  </>
                                }
                              >
                                <Badge colorScheme="warning">
                                  <FormattedMessage
                                    defaultMessage="Already uploaded"
                                    description="The text for files that have already been uploaded"
                                    id="documents.modal.upload.alreadyUploaded"
                                  />
                                </Badge>
                              </Tooltip>
                            )}
                            <Spacer />
                            {erroredFile ? (
                              <Tooltip label={erroredFile.error} shouldWrapChildren>
                                <Icon as={XIcon} color="error" />
                              </Tooltip>
                            ) : (
                              finishedFiles.has(file) && <Icon as={CheckIcon} float="right" />
                            )}
                          </HStack>
                          <Text color="gray.600">
                            <FormattedNumber
                              value={file.size}
                              notation="compact"
                              style="unit"
                              unit="byte"
                              unitDisplay="narrow"
                            />
                          </Text>
                          <Progress
                            size="sm"
                            mt={2}
                            isIndeterminate={finishedFiles.has(file) ? false : true}
                            value={finishedFiles.has(file) ? 100 : undefined}
                            colorScheme={erroredFile ? "error" : "brand"}
                          />
                        </Flex>
                      </CardBody>
                    </ListItem>
                  )
                })}
              </List>
            </Stack>

            <Text>
              <FormattedMessage
                defaultMessage="Supports files up to {maxFileSize}. Up to {maxFiles, number} files per upload. PDF, JPEG, PNG, TIFF, DOCX, plain text, and more. PDFs over {largePdfPageThreshold, number} pages may take a few minutes to extract."
                description="Description for file size and number limits for uploading documents"
                id="documents.modal.upload.limits"
                values={{
                  maxFiles,
                  maxFileSize: (
                    <FormattedNumber
                      value={maxDocumentSize}
                      notation="compact"
                      style="unit"
                      unit="byte"
                      unitDisplay="narrow"
                    />
                  ),
                  largePdfPageThreshold,
                }}
              />
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <CancelButton onClick={modalProps.onClose} />
            <Button type="submit" colorScheme="brand" isDisabled={unprocessedFileCount > 0} isLoading={isSubmitting}>
              {unprocessedFileCount > 0 ? (
                <>
                  <FormattedMessage
                    defaultMessage="Uploading {count} {count, plural, =1 {file} other {files}}"
                    description="The button text to indicate that the documents are being uploaded in the document upload modal"
                    id="documents.modal.upload.button.uploading.label"
                    values={{ count: unprocessedFileCount }}
                  />
                  <Spinner size="sm" marginLeft={2} />
                </>
              ) : (
                <FormattedMessage
                  defaultMessage="Extract"
                  description="The button text to start extraction in the document upload modal"
                  id="documents.modal.upload.button.extract.label"
                />
              )}
            </Button>
          </ModalFooter>
        </chakra.form>
      </ModalContent>
    </Modal>
  )
}
