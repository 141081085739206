import type { SavedView, TableIdentifier } from "@brm/schema-types/types.js"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import type { ReadonlyDeep } from "type-fest"
import type { TableParamsState } from "../../util/schema-table.js"
import {
  TABLE_DEFAULT_PARAMS,
  TABLE_INITIAL_PAGE,
  deserializeTableQueryParams,
  readSavedViewState,
  savedViewFilterParams,
  savedViewSortingParams,
  serializeTableQueryParams,
} from "../../util/schema-table.js"

/**
 * This hook takes care of:
 *
 * 1. Loading table params from localStorage to the URL if the URL params are empty
 * 2. Persisting table params from the URL to localStorage if the URL has params
 *
 * @param localStorageVersion Used as a prefix for the localStorage key to ignore old keys in case of a major migration.
 * @param tableId This tableId is used to form the key for local storage.
 * @returns {void} The hook does not return anything, it will sync the local storage params and the URL while the component is mounted.
 */
export function useLocalStorageTableParamsSync(tableId: TableIdentifier, localStorageVersion: number = 6): void {
  const navigate = useNavigate()
  const location = useLocation()

  const localStorageKey = `v${localStorageVersion}_${tableId}`
  const paramsStr = location.search.slice(1)

  // Update URL with local storage value if URL is empty
  useEffect(() => {
    if (paramsStr) {
      return
    }
    const localStorageParams = localStorage.getItem(localStorageKey)
    if (localStorageParams) {
      navigate({ search: localStorageParams, hash: location.hash }, { replace: true })
    }
  }, [localStorageKey, location.hash, navigate, paramsStr])

  // Persist changes to local storage when URL has values
  useEffect(() => {
    if (!paramsStr) {
      return
    }
    const urlParamsToSave = new URLSearchParams(paramsStr)
    urlParamsToSave.delete("page" satisfies keyof TableParamsState<string>)
    localStorage.setItem(localStorageKey, urlParamsToSave.toString())
  }, [localStorageKey, paramsStr])
}

/**
 * This hook reads and parses the table params from the URL, including saved view state.
 *
 * To consider params from local storage, call {@link useLocalStorageTableParamsSync} separately.
 */
export function useUrlTableParams<TColumn extends string = string>({
  objectSchema,
  defaultParams,
  savedViews,
  primarySearchColumn,
}: {
  defaultParams: Partial<TableParamsState<TColumn>>
  objectSchema: ReadonlyDeep<JSONSchemaObject> | undefined
  savedViews: SavedView[] | undefined

  /**
   * The path of the column used for the primary search input (if one exists). Filters referencing this path will
   * not be included in the view and filter row UI.
   */
  primarySearchColumn?: NoInfer<TColumn>
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const tableParams = useMemo(() => {
    if (!objectSchema || !savedViews) {
      return
    }
    if (!location.search.slice(1)) {
      return { ...TABLE_DEFAULT_PARAMS, ...defaultParams }
    }
    const searchParams = new URLSearchParams(location.search)
    return deserializeTableQueryParams<TColumn>(
      searchParams,
      readSavedViewState(searchParams, savedViews),
      objectSchema,
      primarySearchColumn
    )
  }, [defaultParams, location.search, objectSchema, primarySearchColumn, savedViews])

  if (!objectSchema || !tableParams) {
    return {}
  }

  // Table param rules: setting a table param may reset other ones
  const getUpdatedSerializedParams = ({
    page: newPage,
    pageSize: newPageSize,
    sorting: newSorting,
    filterMap: newFilterMap,
    primaryFilter: newPrimaryFilter,
    selectedColumns: newSelectedColumns,
    savedViewState: newSavedViewState,
  }: Partial<TableParamsState<TColumn>>): URLSearchParams => {
    const newTableParams = { ...tableParams }
    if (newPage !== undefined) {
      newTableParams.page = newPage
    } else if (newPageSize !== undefined) {
      newTableParams.pageSize = newPageSize
      newTableParams.page = TABLE_INITIAL_PAGE
    } else if (newSorting !== undefined) {
      newTableParams.sorting = newSorting
      newTableParams.savedViewState.newSortParams = savedViewSortingParams(newSorting)
      newTableParams.page = TABLE_INITIAL_PAGE
    } else if (newPrimaryFilter !== undefined) {
      newTableParams.primaryFilter = newPrimaryFilter
      newTableParams.page = TABLE_INITIAL_PAGE
    } else if (newFilterMap !== undefined) {
      newTableParams.filterMap = newFilterMap
      newTableParams.savedViewState.newFilterParams = savedViewFilterParams(newFilterMap, objectSchema)
      newTableParams.page = TABLE_INITIAL_PAGE
    } else if (newSelectedColumns !== undefined) {
      invariant(!newSelectedColumns.includes("" as TColumn), "Customizable columns cannot contain the root path")
      newTableParams.selectedColumns = newSelectedColumns
      newTableParams.savedViewState.newColumnParams = newSelectedColumns
      // Reset page, sorting upon new column selection
      newTableParams.page = TABLE_INITIAL_PAGE
      newTableParams.sorting = []
    } else if (newSavedViewState !== undefined) {
      newTableParams.savedViewState = newSavedViewState
      newTableParams.page = TABLE_INITIAL_PAGE
    }
    return serializeTableQueryParams(newTableParams, objectSchema)
  }

  const updateTableParams = (params: Partial<TableParamsState<TColumn>>) => {
    const searchParams = getUpdatedSerializedParams(params)
    navigate({ ...location, search: searchParams.toString() }, { replace: true })
  }

  return {
    tableParams,
    getUpdatedSerializedParams,
    updateTableParams,
  }
}
