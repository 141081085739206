import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import type {
  FieldSourceOutputProperties,
  FieldSourceType,
  LegalAgreementFieldsMetadata,
  LegalWorkflowFieldsMetadata,
  User,
  WorkflowFieldsMetadata,
} from "@brm/schema-types/types.js"
import { isCompliantWithDocumentType } from "@brm/type-helpers/schema.js"
import { displayPersonName } from "@brm/util/names.js"
import { getValueAtPath } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type * as cfWorkerJsonSchema from "@cfworker/json-schema"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { ColorScheme } from "chakra-react-select"
import type { IntlShape } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { getSchemaCustomErrorMessage, isToolListingArrayType } from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import { isEmptyRichText } from "../RichTextEditor/util/common.js"

export type SchemaFormInitialValueSourceType = "organization" | "link" | "listing" | "user"
export type SchemaFormFieldsMetadata =
  | LegalAgreementFieldsMetadata
  | WorkflowFieldsMetadata
  | LegalWorkflowFieldsMetadata

export const FIELD_SOURCE_COLORS: Partial<Record<FieldSourceType, ColorScheme>> = {
  link: "blue",
  listing: "brand",
  document: "purple",
  transaction: "purple",
  derived: "purple",
  user: "brand",
}

export const getDefaultUserFieldSource = (intl: IntlShape, whoami: User): FieldSourceOutputProperties => ({
  type: "user",
  id: whoami.id,
  updated_at: Temporal.Now.instant().toString(),
  source_display_name: displayPersonName(whoami, intl),
  assigned_by_metadata: null,
})

export const fieldHasFormValue = (schema: ReadonlyDeep<JSONSchema> | undefined, value: unknown): boolean => {
  if (value === null) {
    return false
  }
  if (value === undefined) {
    return false
  }
  if (isCompliantWithDocumentType(schema, value)) {
    return value.compliant !== undefined
  }
  if (isToolListingArrayType(schema, value)) {
    return value.length > 0
  }
  if (isRichTextType(schema, value)) {
    return !isEmptyRichText(value)
  }
  return true
}

export const formatValidationErrors = ({
  errors,
  schema,
  fieldName,
  handleCustomErrorMessage,
}: {
  errors: cfWorkerJsonSchema.OutputUnit[]
  schema: ReadonlyDeep<JSONSchema>
  fieldName: string | undefined
  /** Allows caller to handle errors with custom messages for specific schemas or keywords. If not specified or returns undefined, will default to using the error message from the schema. */
  handleCustomErrorMessage?: (
    schema: ReadonlyDeep<JSONSchema>,
    /** The keyword on the schema that caused the error */
    errorKeyword: string
  ) => string | undefined
}): string => {
  log.warn("Validation for field", fieldName, { errors, schema })
  // The errors are sentences that end with a full stop, so we join them with a space rather than a comma.
  return errors
    .map(
      ({ keyword, error }) =>
        handleCustomErrorMessage?.(schema, keyword) ?? getSchemaCustomErrorMessage(schema, keyword) ?? error
    )
    .join(" ")
}

/**
 * Given a path find the closest fields_metadata with data as the root object.
 * The response will also return a fieldMetadataPath which can be used to access the fieldMetadata of the given path.
 */
export const findClosestFieldsMetadata = (
  pathArray: (string | number)[],
  i: number,
  data: unknown
): { fieldsMetadata: SchemaFormFieldsMetadata; fieldMetadataPath: (string | number)[] } | undefined => {
  if (i === 0) {
    return undefined
  }
  const parentValue = getValueAtPath(data, pathArray.slice(0, i - 1))
  const fieldsMetadata =
    isObject(parentValue) &&
    "fields_metadata" in parentValue &&
    (parentValue.fields_metadata as SchemaFormFieldsMetadata)
  if (fieldsMetadata) {
    return { fieldsMetadata, fieldMetadataPath: pathArray.slice(i - 1, pathArray.length) }
  }
  return findClosestFieldsMetadata(pathArray, i - 1, data)
}
