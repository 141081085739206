import type { SavedView, Transaction, TransactionMinimal } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Heading, Stack } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { usePostTransactionV1ListQuery, type PostTransactionV1ListApiArg } from "../../app/services/generated-api.js"
import DataTable from "../../components/DataTable/DataTable.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import TablePageHeader from "../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../components/DataTable/SchemaTableColumnCustomization.js"
import { useSchemaColumns } from "../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../components/DataTable/use-schema-table-params.js"
import {
  TABLE_DEFAULT_PARAMS,
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
} from "../../util/schema-table.js"
import { useObjectSchema } from "../../util/use-schema.js"
import { TransactionDetailModal } from "./TransactionDetailModal.js"
import { TRANSACTIONS_TABLE_ID } from "./constants.js"

const defaultColumns: BRMPaths<Transaction>[] = [
  "transacted_at",
  "merchant_identification",
  "memo",
  "person",
  "payment_method",
  "integrations",
  "erp_tracking_categories.departments",
  "currency_amount",
  "receipts",
]

const savedViews: SavedView[] = []

export default function TransactionList(props: Pick<PostTransactionV1ListApiArg, "toolId" | "vendorId">) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const hashParams = new URLSearchParams(location.hash.slice(1))
  const transactionId = hashParams.get("transaction")
  const receiptId = hashParams.get("receipt")

  const transactionSchema = useObjectSchema("ReconciledTransaction")

  useLocalStorageTableParamsSync(TRANSACTIONS_TABLE_ID)
  const { tableParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TABLE_DEFAULT_PARAMS,
    objectSchema: transactionSchema,
    savedViews,
  })
  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  // Not passing a document URL getter because when clicking the receipts, we want the click to open the whole
  // transaction viewer which includes the receipt instead of opening only the receipt in a new tab.
  const columns = useSchemaColumns<TransactionMinimal>({ objectSchema: transactionSchema, shownColumns })

  const apiParams = useMemo(
    () =>
      transactionSchema && tableParams && packageSortFilterOptionsForAPI<string>(tableParams, transactionSchema, intl),
    [intl, tableParams, transactionSchema]
  )

  const { data, isFetching } = usePostTransactionV1ListQuery(
    apiParams
      ? {
          toolId: props.toolId,
          vendorId: props.vendorId,
          listQueryStringParams: apiParams,
        }
      : skipToken
  )

  if (!tableParams || !transactionSchema || !shownColumns || !columns) {
    return null
  }

  return (
    <>
      <Stack width="full" gap={4}>
        <Heading size="xs">
          {data ? (
            <FormattedMessage
              id="transaction.tab.heading.withCount"
              description="Heading for transactions overview section"
              defaultMessage="Transactions ({total})"
              values={{ total: data.total }}
            />
          ) : (
            <FormattedMessage
              id="transaction.tab.heading"
              description="Heading for transactions overview section"
              defaultMessage="Transactions"
            />
          )}
        </Heading>
        {data ? (
          <>
            <TablePageHeader
              tableId={TRANSACTIONS_TABLE_ID}
              filterMap={tableParams.filterMap}
              onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
              objectSchema={transactionSchema}
              selectedColumns={shownColumns}
              afterSavedView={
                <SchemaTableColumnCustomization
                  activeColumns={shownColumns}
                  primaryColumn={undefined}
                  onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
                  objectSchema={transactionSchema}
                />
              }
            />
            <DataTable
              borderWidth="1px"
              overflow="auto"
              data={data.transactions}
              columns={columns}
              sorting={tableParams.sorting}
              onSortingChange={(newSorting) =>
                newSorting instanceof Function
                  ? updateTableParams({ sorting: newSorting(tableParams.sorting) })
                  : updateTableParams({ sorting: newSorting })
              }
              onRowClick={(row) => navigate(`#transaction=${row.id}`)}
              paginationProps={{
                page: tableParams.page,
                pageSize: tableParams.pageSize,
                onPageChange: (page) => updateTableParams({ page }),
                onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
                totalListElements: data.total,
              }}
            />
          </>
        ) : (
          !isFetching &&
          tableParams.filterMap.size === 0 && (
            <EmptyTableState
              emptyState={intl.formatMessage({
                id: "tool.payments.emptyState",
                description: "Text to display instead of the payments table when there are no payments for the tool",
                defaultMessage:
                  "No transaction data found. This tool is free or your payment method is not integrated with BRM",
              })}
            />
          )
        )}
      </Stack>
      {/* Only mount the component when the transaction is open - to prevent the modal from being immediately rendered with empty form state always */}
      {transactionId !== null && (
        <TransactionDetailModal
          transactionId={transactionId}
          receiptId={receiptId}
          onClose={() => {
            hashParams.delete("transaction")
            hashParams.delete("receipt")
            navigate({ search: location.search, hash: `#${hashParams}` })
          }}
        />
      )}
    </>
  )
}
