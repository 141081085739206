import { Icon } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { IconButtonWithTooltip } from "../../IconButtonWithTooltip.js"
import { RefreshIcon } from "../icons.js"
import type { BrmIconButtonProps } from "./types.js"

/**
 * Wrapper around IconButton that adds a default tooltip and aria-label intl string.
 * Also allows for a global setting for what Chakra icon should be used for each button type.
 */
export default function RefreshIconButton({ label, iconProps, ...props }: BrmIconButtonProps) {
  const intl = useIntl()

  const labelMessage =
    label ??
    intl.formatMessage({
      id: "iconButtons.refresh.label",
      description: "The label for a refresh button",
      defaultMessage: "Refresh",
    })

  return <IconButtonWithTooltip icon={<Icon as={RefreshIcon} {...iconProps} />} label={labelMessage} {...props} />
}
