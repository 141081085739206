import type { VendorStatus } from "@brm/schema-types/types.js"
import { VendorStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayVendorStatus, vendorStatusColors } from "./constants.js"

interface Props {
  status: VendorStatus
  /** If editing is enabled, a vendorId needs to be provided */
  editVendorId?: string
}

export default function VendorStatusBadge({ status, editVendorId }: Props) {
  return (
    <UpdateStatusBadge<VendorStatus, "status">
      status={status}
      fieldName="status"
      objectType="Vendor"
      objectId={editVendorId}
      options={VendorStatusSchema.anyOf.map((s) => s.const)}
      statusColors={vendorStatusColors}
      displayStatus={displayVendorStatus}
      updatePermissions={["vendor:update"]}
    />
  )
}
