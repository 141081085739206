import type { WorkflowRun } from "@brm/schema-types/types.js"
import { HStack, Icon } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { Timestamp } from "../../../components/Timestamp.js"
import { ClockIcon } from "../../../components/icons/icons.js"

// Displays the seller due date of a workflow run if it exists
export default function SellerDueDateIndicator({ workflowRun }: { workflowRun: WorkflowRun }) {
  const intl = useIntl()
  if (!workflowRun.seller_due_date) {
    return null
  }
  return (
    <HStack>
      <Icon as={ClockIcon} />
      <Timestamp
        dateTime={workflowRun.seller_due_date}
        linkProps={{
          fontWeight: "semibold",
        }}
        prefix={intl.formatMessage({
          id: "due.seller.prefix",
          defaultMessage: "Due ",
          description:
            "Prefix for a due date timestamp that will be inserted before the timestamp content. Ex/ `Due 2 days ago` or `Due in a month`",
        })}
      />
    </HStack>
  )
}
