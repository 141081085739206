import { hasPermission } from "@brm/schema-helpers/role.js"
import type { VendorDetails } from "@brm/schema-types/types.js"
import { Box, HStack, Heading, Stack } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { AgreementTcvChartCard } from "../charts/AgreementTcvChartCard.js"
import { SpendChartCard } from "../charts/SpendChartCard.js"
import { smallChartHeight } from "../charts/constants.js"
import { getDefaultSpendChartXAxisProps, getDefaultSpendChartYAxisProps } from "../charts/utils.js"
import { LegalAgreementOverview } from "../legal/LegalAgreementOverview.js"
import { WorkflowRunSorOverview } from "../workflows/run/WorkflowRunSorOverview.js"
import { ToolCardsList } from "./ToolCards.js"
import VendorToolSpendPie from "./charts/VendorToolSpendPie.js"

interface Props {
  vendor: VendorDetails
}

export default function VendorOverview({ vendor }: Props) {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const location = useLocation()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  const canViewSpend =
    (vendor.owner && vendor.owner.user_id === whoami?.id) || hasPermission(whoami?.roles, "spend:read")
  const agreementHash = location.hash.includes("agreements")

  useEffect(() => {
    if (agreementHash && ref.current) {
      ref.current.scrollIntoView()
      // Reset hash to avoid scrolling to the same position on re-renders
      navigate({ ...location, hash: "" })
    }
  }, [agreementHash, location, navigate])

  return (
    <Stack gap={8}>
      <WorkflowRunSorOverview workflowRuns={vendor.workflow_runs} sorDisplayName={vendor.display_name} />
      {canViewSpend && (
        <Stack className="spendSection" sx={{ "&:has(> .chartsContainer:empty)": { display: "none" } }}>
          <Heading size="xs">
            <FormattedMessage id="vendor.header.spend" description="Vendor view spend" defaultMessage="Spend" />
          </Heading>
          <HStack flexWrap="wrap" gap={6} className="chartsContainer">
            <SpendChartCard
              entityParams={{ type: "vendor", id: vendor.id }}
              chartHeight={smallChartHeight}
              axisProps={[getDefaultSpendChartYAxisProps(intl), getDefaultSpendChartXAxisProps(intl)]}
              xyChartProps={{ margin: { left: 50, top: 20, right: 30, bottom: 40 } }}
            />
            <VendorToolSpendPie vendorId={vendor.id} />
            <AgreementTcvChartCard
              agreements={vendor.legal_agreements}
              entityParams={{ type: "vendor", id: vendor.id }}
            />
          </HStack>
        </Stack>
      )}
      {vendor.tools.length > 0 && (
        <Stack>
          <Heading size="xs">
            <FormattedMessage
              id="vendor.tools.heading"
              description="Heading for vendor view tools table"
              defaultMessage="Tools"
            />
          </Heading>
          <ToolCardsList tools={vendor.tools} />
        </Stack>
      )}
      <Box ref={ref}>
        <LegalAgreementOverview vendor={vendor} />
      </Box>
    </Stack>
  )
}
