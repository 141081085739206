import { AbsoluteCenter, Box, Button, HStack, Heading, Icon, Text, VStack } from "@chakra-ui/react"
import type { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import notFound404ImageURL from "../../../assets/404.svg"
import { BackIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import { isBrmError } from "../../util/error.js"

interface NotFoundErrorViewProps {
  error: unknown
  serializedError?: string
}

const NotFoundErrorView: FC<NotFoundErrorViewProps> = (props) => {
  const intl = useIntl()

  let message = intl.formatMessage({
    defaultMessage: "Page not found",
    description: "NotFoundErrorView header",
    id: "errors.notFoundErrorView.header",
  })

  let details: string | undefined = intl.formatMessage({
    defaultMessage: "The page you are looking for doesn’t exist or has been deleted",
    description: "NotFoundErrorView subheader",
    id: "errors.notFoundErrorView.subheader",
  })

  if (isBrmError(props.error) && props.error.data.details) {
    message = props.error.data.message
    details = props.error.data.details
  }

  const emailForSupport = encodeURIComponent(
    `I encountered an error in the BRM app. Please help me fix it.

Technical Details:
Message: ${message}
Details: ${details}
URL: ${window.location.href}
Serialized Error: ${props.serializedError}
`
  )
  return (
    <AbsoluteCenter
      backgroundImage={notFound404ImageURL}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      width="100%"
      height="358px"
    >
      <Box pos="absolute" bottom="0" width="100%">
        <VStack>
          <Heading size="lg" paddingBottom={4}>
            {message}
          </Heading>
          <Text fontSize="medium" textColor="gray.600" paddingBottom={8}>
            {details}
          </Text>
          <HStack>
            <Button as={Link} to="/" colorScheme="brand" size="lg" leftIcon={<Icon as={BackIcon} />}>
              <FormattedMessage
                defaultMessage="Back to home"
                description="Button to send users back to brm homepage"
                id="errors.notFoundErrorView.homeButton"
              />
            </Button>
            <Button
              as={Link}
              to={`mailto:support@brm.ai?subject=BRM Error&body=${emailForSupport}`}
              variant="outline"
              size="lg"
              backgroundColor="white"
            >
              <FormattedMessage
                defaultMessage="Email Support"
                description="Button to send email to support"
                id="errors.notFoundErrorView.emailSupportButton"
              />
            </Button>
          </HStack>
        </VStack>
      </Box>
    </AbsoluteCenter>
  )
}

export default NotFoundErrorView
