import { isCustomizableObjectType } from "@brm/schema-helpers/object-type.js"
import type { CustomizableObjectType, DocumentMinimal, ExtractedFieldConfig } from "@brm/schema-types/types.js"
import { Box, Flex, Icon, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react"
import { pascalCase } from "change-case"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { isNotUndefined } from "typed-assert"
import { useGetSchemaV1ByObjectTypeFieldsQuery } from "../../../app/services/generated-api.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { UploadIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { SettingsHeader } from "../SettingsHeader.js"
import { FormCriteriaReviewModal } from "./FormCriteriaReviewModal.js"
import { FormUploadModal } from "./FormUploadModal.js"
import { NewCriterionPopover } from "./NewCriterionPopover.js"
import { ObjectCriteria } from "./ObjectCriteria.js"

interface CriteriaTab {
  title: string
  customizableType: CustomizableObjectType
  pathSegment: string
}

export default function CriteriaSettings() {
  const intl = useIntl()

  const { objectType: kebabCaseObjectType } = useParams()
  isNotUndefined(kebabCaseObjectType, "Missing object_type route param")

  const tabs: CriteriaTab[] = [
    {
      title: intl.formatMessage({
        id: "criteria.settings.tab.tool",
        defaultMessage: "Tool",
        description: "Tab title for tool criteria settings",
      }),
      customizableType: "Tool",
      pathSegment: "tool",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.tab.vendor",
        defaultMessage: "Vendor",
        description: "Tab title for vendor criteria settings",
      }),
      customizableType: "Vendor",
      pathSegment: "vendor",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.tab.agreement",
        defaultMessage: "Agreement",
        description: "Tab title for legal agreement criteria settings",
      }),
      customizableType: "LegalAgreement",
      pathSegment: "legal-agreement",
    },
  ]

  const formUploadModal = useDisclosure()
  const formCriteriaReviewModal = useDisclosure()

  const [extractedFields, setExtractedFields] = useState<{
    fields: ExtractedFieldConfig[]
    document: DocumentMinimal
  }>()

  const objectType = pascalCase(kebabCaseObjectType)
  invariant(isCustomizableObjectType(objectType), `Invalid object_type: ${objectType}`)

  const { data } = useGetSchemaV1ByObjectTypeFieldsQuery({ objectType })

  // For validating field name conflicts
  const fieldNames = useMemo(() => new Set(data?.fields.map((field) => field.field_name)), [data?.fields])

  return (
    <>
      <SettingsHeader>
        <FormattedMessage
          id="settings.criteria.header"
          description="Settings page header for criteria settings"
          defaultMessage="Criteria"
        />
      </SettingsHeader>
      <Stack spacing={4} maxW="960px">
        <Flex gap={2}>
          <Box>
            <Text color="gray.600">
              <FormattedMessage
                id="settings.criteria.description"
                description="Description of the criteria settings page"
                defaultMessage="Criteria are the data and information your organization tracks about tools and vendors. Criteria are integrated into requests, and also appear on tools, and vendors. Use Global Criteria to leverage the BRM network’s collective capabilities, and our intelligence. Don’t see Global Criteria you like, or want to use, opt for Custom Criteria that are unique and private to your organization."
              />
            </Text>
          </Box>
          <Flex float="right" gap={1}>
            <IconButtonWithTooltip
              {...formUploadModal.getButtonProps()}
              variant="outline"
              icon={<Icon as={UploadIcon} />}
              onClick={formUploadModal.onOpen}
              label={
                <FormattedMessage
                  defaultMessage="Upload document to generate criteria"
                  description="Tooltip label for upload document button"
                  id="settings.criteria.upload_form_tooltip"
                />
              }
            />
            <NewCriterionPopover objectType={objectType} fieldNames={fieldNames} />
            {formUploadModal.isOpen && (
              <FormUploadModal
                isOpen={formUploadModal.isOpen}
                onClose={formUploadModal.onClose}
                onExtracted={(fields, document) => {
                  setExtractedFields({ fields, document })
                  formUploadModal.onClose()
                  formCriteriaReviewModal.onOpen()
                }}
              />
            )}
            {formCriteriaReviewModal.isOpen && extractedFields && (
              <FormCriteriaReviewModal
                document={extractedFields.document}
                extractedFields={extractedFields.fields}
                isOpen={formCriteriaReviewModal.isOpen}
                onClose={formCriteriaReviewModal.onClose}
              />
            )}
          </Flex>
        </Flex>

        <Tabs index={tabs.findIndex((tab) => tab.customizableType === objectType)} isLazy>
          <TabList>
            {tabs.map(({ customizableType, pathSegment, title }) => (
              <Tab key={customizableType} as={Link} to={`../${pathSegment}`}>
                {title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {data &&
              tabs.map(({ customizableType }) => (
                <TabPanel key={customizableType}>
                  <ObjectCriteria objectType={customizableType} fields={data.fields} defs={data.$defs} />
                </TabPanel>
              ))}
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  )
}
