import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import { type FieldCategory, type VendorDetails, type VendorPatch } from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { mutableClone } from "@brm/util/mutable.js"
import { getTitle } from "@brm/util/schema.js"
import { HStack, Heading, Spacer, Stack, useToast, type StackProps } from "@chakra-ui/react"
import { useCallback, useMemo } from "react"
import type { DefaultValues } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePatchVendorV1ByIdMutation } from "../../app/services/generated-api.js"
import SchemaFormPage from "../../components/SchemaForm/SchemaFormPage.js"
import { useObjectPatchSchema, useObjectSchema } from "../../util/use-schema.js"
import VendorComplianceStatusBadge from "./status/VendorComplianceStatusBadge.js"
import VendorFinanceStatusBadge from "./status/VendorFinanceStatusBadge.js"
import VendorItStatusBadge from "./status/VendorItStatusBadge.js"

interface Props extends StackProps {
  vendor: VendorDetails
  category: FieldCategory | undefined
}

export default function VendorCategory({ vendor, category, ...stackProps }: Props) {
  const toast = useToast()
  const intl = useIntl()

  const vendorSchema = useObjectSchema("Vendor")
  const vendorPatchSchema = useObjectPatchSchema("Vendor")
  const categoryFields = useMemo(() => {
    if (vendorPatchSchema) {
      const { standard, custom } = schemaToFormFields(vendorPatchSchema, "Vendor", category)
      const customWithPath = custom.map((field) => ({ ...field, path: ["custom", field.field_name] }))
      const standardWithPath = standard.map((field) => ({ ...field, path: [field.field_name] }))
      return [...standardWithPath, ...customWithPath]
    }
    return undefined
  }, [vendorPatchSchema, category])

  const initialFormValues = useMemo(() => {
    const initialFormValues: VendorPatch = mutableClone(vendor)
    return initialFormValues as DefaultValues<VendorPatch>
  }, [vendor])

  const [updateVendor] = usePatchVendorV1ByIdMutation()

  const title = useMemo(() => {
    const fieldCategorySchema = FieldCategorySchema.anyOf.find((schema) => schema.const === category)
    return getTitle(
      category ??
        intl.formatMessage({
          defaultMessage: "General",
          description: "General category title",
          id: "general",
        }),
      fieldCategorySchema
    )
  }, [category, intl])

  const submit = useCallback(
    async (outputs: VendorPatch) => {
      try {
        await updateVendor({ id: vendor.id, vendorPatch: outputs }).unwrap()
        toast({
          description: intl.formatMessage(
            {
              id: "vendor.category.saved",
              defaultMessage: "{category} saved",
              description: "Vendor category saved toast message",
            },
            { category: title }
          ),
          status: "success",
        })
      } catch (err) {
        toast({
          description: intl.formatMessage(
            {
              id: "vendor.category.error",
              defaultMessage: "Error saving {category}",
              description: "Vendor category error toast message",
            },
            { category: title }
          ),
          status: "error",
        })
        throw err
      }
    },
    [intl, title, toast, vendor.id, updateVendor]
  )

  const documentDownloadURL = useCallback(
    (path: (string | number)[]) =>
      new URL(`/vendor/v1/${vendor.id}/${path.join("/")}/content`, import.meta.env.VITE_API_BASE_URL).href,
    [vendor.id]
  )

  if (!categoryFields || !vendorSchema) {
    return null
  }

  return (
    <Stack {...stackProps}>
      <HStack justifyContent="space-between">
        <Heading size="xs">{title}</Heading>
        <Spacer />
        {category === "it" ? (
          <VendorItStatusBadge status={vendor.it_status} editVendorId={vendor.id} />
        ) : category === "compliance" ? (
          <VendorComplianceStatusBadge status={vendor.compliance_status} editVendorId={vendor.id} />
        ) : category === "finance" ? (
          <VendorFinanceStatusBadge status={vendor.finance_status} editVendorId={vendor.id} />
        ) : null}
      </HStack>
      {categoryFields.length === 0 ? (
        <FormattedMessage
          defaultMessage="No editable fields in this category"
          description="Helper message when there are no editable fields in this category"
          id="vendor.category.no-editable-fields"
        />
      ) : (
        <SchemaFormPage<VendorPatch>
          initialFormValues={initialFormValues}
          rootSchema={vendorSchema}
          formFields={categoryFields}
          onSubmit={submit}
          documentDownloadURL={documentDownloadURL}
          isEditing={true}
        />
      )}
    </Stack>
  )
}
