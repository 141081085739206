import type { Theme } from "@brm/theme"
import { css, propNames, useMultiStyleConfig, useTheme, type StyleProps } from "@chakra-ui/system"
import { ClassNames } from "@emotion/react"
import deepmerge from "deepmerge"
import { includeKeys } from "filter-obj"
import { forwardRef, type ForwardedRef, type ReactNode } from "react"
import { runIfFn } from "../../../packages/theme/src/utils/run-if-fn.js"

// Allowed ONLY in this file
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import * as reactAria from "react-aria-components"

export type ListBoxProps<T extends object> = reactAria.ListBoxProps<T> &
  React.RefAttributes<HTMLDivElement> &
  StyleProps & {
    className?: string
  }

/**
 * Chakra wrapper around the react-aria `ListBox` component, which is an implementation of the `listbox` ARIA widget.
 */
export const ListBox = <T extends object>(props: ListBoxProps<T>) => {
  const theme = useTheme()
  const listBoxTheme = useMultiStyleConfig("ListBox") as ReturnType<
    NonNullable<Theme["components"]["ListBox"]["baseStyle"]>
  >

  const listBoxThemeStyles = runIfFn(listBoxTheme.list ?? {}, props)
  const propsStyles = includeKeys(props, propNames as (keyof typeof props)[])

  // Convert Chakra style-token props into raw CSS styles
  const rawStyles = css(deepmerge(listBoxThemeStyles, propsStyles))(theme)

  return (
    <ClassNames>
      {({ css, cx }) => (
        // Convert CSS styles into class name
        <reactAria.ListBox {...props} className={cx(css(rawStyles), props.className)} />
      )}
    </ClassNames>
  )
}

export type ListBoxItemProps<T extends object> = reactAria.ListBoxItemProps<T> &
  React.RefAttributes<HTMLDivElement> &
  StyleProps & {
    className?: string
  }

/**
 * Chakra wrapper around the react-aria `ListBoxItem` component, which makes up the children of a {@link ListBox}.
 */
export const ListBoxItem = forwardRef(function ListBoxItem<T extends object>(
  props: ListBoxItemProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const theme = useTheme()
  const listBoxTheme = useMultiStyleConfig("ListBox") as ReturnType<
    NonNullable<Theme["components"]["ListBox"]["baseStyle"]>
  >

  const listBoxItemThemeStyles = runIfFn(listBoxTheme.item ?? {}, props)
  const propsStyles = includeKeys(props, propNames as (keyof typeof props)[])

  // Convert Chakra style-token styles into raw CSS styles
  const rawStyles = css(deepmerge(listBoxItemThemeStyles, propsStyles))(theme)
  return (
    <ClassNames>
      {({ css, cx }) => (
        // Convert CSS styles into class name
        <reactAria.ListBoxItem ref={ref} {...props} className={cx(css(rawStyles), props.className)} />
      )}
    </ClassNames>
  )
})

export const ListBoxSection = ({ title, children }: { title?: string; children: ReactNode }) => {
  return (
    <reactAria.Section>
      {title && <reactAria.Header>{title}</reactAria.Header>}
      {children}
    </reactAria.Section>
  )
}
