import type { InviteUser, Permission, PermissionedEntityType, User } from "@brm/schema-types/types.js"
import type { ModalProps, UseModalProps } from "@chakra-ui/react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostUserV1InviteMutation, usePutUserV1ByIdMutation } from "../../../app/services/generated-api.js"
import BasicOrganizationEntityPicker from "../../../components/Form/BasicOrganizationEntityPicker.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import InviteOrUpdateUserFormContent from "./InviteOrUpdateUserFormContent.js"

interface Props extends Pick<ModalProps, "finalFocusRef">, UseModalProps {
  userToUpdateId?: string
  onSuccess?: (user: User) => void
  initialState?: Partial<User>
  requiredPermission?: Permission
}

const emptyInviteState = {
  email: "",
  first_name: "",
  last_name: "",
  roles: [],
}

export default function InviteOrUpdateUserModal({
  userToUpdateId,
  onSuccess,
  initialState,
  finalFocusRef,
  requiredPermission,
  ...modalProps
}: Props) {
  const toast = useToast()
  const intl = useIntl()

  const [inviteUser, inviteUserResult] = usePostUserV1InviteMutation()
  const [updateUser, updateUserResult] = usePutUserV1ByIdMutation()
  const [newOwnerId, setNewOwnerId] = useState<string | undefined>(undefined)

  const confirmUserUpdate =
    updateUserResult.isError && ("status" in updateUserResult.error ? updateUserResult.error.status === 428 : true)

  const form = useForm<InviteUser>({
    defaultValues: {
      ...emptyInviteState,
      ...initialState,
      first_name: initialState?.first_name ?? "",
      last_name: initialState?.last_name ?? "",
    },
  })

  const onClose = () => {
    form.reset({
      ...emptyInviteState,
      ...initialState,
      first_name: initialState?.first_name ?? "",
      last_name: initialState?.last_name ?? "",
    })
    modalProps.onClose()
  }

  const onSubmit = async (values: InviteUser) => {
    try {
      if (userToUpdateId) {
        const result = await updateUser({
          id: userToUpdateId,
          updateUser: {
            first_name: values.first_name,
            last_name: values.last_name,
            roles: values.roles,
            transferred_user_id: newOwnerId,
          },
        }).unwrap()
        onSuccess?.(result)
        toast({
          description: intl.formatMessage({
            id: "user.edit.toastMsg.success",
            description: "Toast success message when updated a user",
            defaultMessage: "User updated!",
          }),
          status: "success",
        })
        updateUserResult.reset()
      } else {
        const result = await inviteUser({ inviteUser: values }).unwrap()
        onSuccess?.(result)
        toast({
          description: intl.formatMessage({
            id: "user.invite.toastMsg.success",
            description: "Toast success message when inviting a user",
            defaultMessage: "User invited!",
          }),
          status: "success",
        })
        inviteUserResult.reset()
      }
      onClose()
    } catch (_) {
      // error already handled using inviteUserResult and updateUserResult
    }
  }

  return (
    <Modal {...modalProps} size="3xl" autoFocus={false} finalFocusRef={finalFocusRef}>
      <ModalOverlay />
      <ModalContent
        as="form"
        // Stops the native validation from blocking onSubmit
        noValidate={true}
        // Focus modal when it is rendered the first time
        ref={(element: HTMLElement | null) => element?.focus()}
        onSubmit={async (event) => {
          event.stopPropagation()
          await form.handleSubmit(onSubmit)(event)
        }}
      >
        <ModalHeader textAlign="center">
          {userToUpdateId ? (
            <FormattedMessage
              id="user.edit.modal.header"
              description="Heading of user edit modal"
              defaultMessage="Update user"
            />
          ) : (
            <FormattedMessage
              id="organization.sendInvitation.modal.header"
              description="Heading of user invitation modal"
              defaultMessage="Invite a Team Member"
            />
          )}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          {inviteUserResult.isError && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {getAPIErrorMessage(inviteUserResult.error) ?? (
                  <FormattedMessage
                    id="user.invite.toastMsg.error"
                    description="Toast error message when inviting a user fails"
                    defaultMessage="Error inviting user"
                  />
                )}
              </AlertDescription>
            </Alert>
          )}
          {updateUserResult.isError && !confirmUserUpdate && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {getAPIErrorMessage(updateUserResult.error) ?? (
                  <FormattedMessage
                    id="user.update.toastMsg.error"
                    description="Toast error message when updating a user fails"
                    defaultMessage="Error updating user"
                  />
                )}
              </AlertDescription>
            </Alert>
          )}
          <InviteOrUpdateUserFormContent
            form={form}
            userToUpdateId={userToUpdateId}
            requiredPermission={requiredPermission}
          />
        </ModalBody>
        <ModalFooter>
          <Stack gap={2} width="100%">
            {userToUpdateId && confirmUserUpdate && (
              <Alert overflow="visible" status="warning" pb={2} pt={2}>
                <HStack>
                  <AlertIcon />
                  <Box>
                    <FormattedMessage
                      id="organization.view.users.update.newOwnerRequired.message"
                      description="Confirmation text for downgrading a user permissions and requesting a new owner for their requests."
                      defaultMessage="This user an the approver of at least one request. To downgrade their permissions, select a user as the new approver. Only users with the appropriate permissions will be shown"
                    />
                    <BasicOrganizationEntityPicker
                      includedEntities={["user"]}
                      ignoreMap={new Map<PermissionedEntityType, Set<string>>([["user", new Set([userToUpdateId])]])}
                      requiredPermission="workflow:approve"
                      onChangeUser={(user) => setNewOwnerId(user.id)}
                    />
                  </Box>
                </HStack>
              </Alert>
            )}
            <HStack justifyContent="flex-end">
              <Button
                isLoading={userToUpdateId ? updateUserResult.isLoading : inviteUserResult.isLoading}
                isDisabled={userToUpdateId ? updateUserResult.isSuccess : inviteUserResult.isSuccess}
                onClick={onClose}
                colorScheme="gray"
                variant="ghost"
              >
                <FormattedMessage
                  id="organization.invite.modal.submit"
                  description="cancel button text on user invite/update modal"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                isLoading={userToUpdateId ? updateUserResult.isLoading : inviteUserResult.isLoading}
                isDisabled={userToUpdateId ? updateUserResult.isSuccess : inviteUserResult.isSuccess}
                type="submit"
                colorScheme="brand"
              >
                {userToUpdateId ? (
                  <FormattedMessage
                    id="user.update.modal.submit"
                    description="button text on update user modal"
                    defaultMessage="Save changes"
                  />
                ) : (
                  <FormattedMessage
                    id="organization.invite.modal.submit"
                    description="button text on user invite modal"
                    defaultMessage="Send Invite"
                  />
                )}
              </Button>
            </HStack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
