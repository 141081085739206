import type { VendorFinanceStatus } from "@brm/schema-types/types.js"
import { VendorFinanceStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayVendorFinanceStatus, vendorFinanceStatusColors } from "./constants.js"

interface Props {
  status: VendorFinanceStatus | undefined
  /** If editing is enabled, a vendorId needs to be provided */
  editVendorId?: string
}

export default function VendorFinanceStatusBadge({ status, editVendorId }: Props) {
  if (!status) {
    return undefined
  }

  return (
    <UpdateStatusBadge<VendorFinanceStatus, "finance_status">
      status={status}
      fieldName="finance_status"
      objectType="Vendor"
      objectId={editVendorId}
      options={VendorFinanceStatusSchema.anyOf.map((s) => s.const)}
      statusColors={vendorFinanceStatusColors}
      displayStatus={displayVendorFinanceStatus}
      updatePermissions={["vendor:update", "finance:update"]}
    />
  )
}
