import type { ListQueryStringParams, SavedView } from "@brm/schema-types/types.js"
import { omitUndefined } from "@brm/util/omit-nulls.js"

export function savedViewToListQueryStringParams(savedView: SavedView): ListQueryStringParams {
  const { sort_params } = savedView

  return omitUndefined({
    sort: sort_params
      ? {
          by: sort_params.sort ?? sort_params.sort_desc ?? "",
          direction: sort_params.sort_desc ? "DESC" : "ASC",
        }
      : undefined,
  } satisfies ListQueryStringParams)
}
