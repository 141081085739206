import { Icon } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { IconButtonWithTooltip } from "../../IconButtonWithTooltip.js"
import { NotificationsDisabledIcon, NotificationsEnabledIcon } from "../icons.js"
import type { BrmIconButtonProps } from "./types.js"

/**
 * Wrapper around IconButton that adds a default tooltip and aria-label intl string.
 * Also allows for a global setting for what Chakra icon should be used for each button type.
 */
export default function NotificationIconButton({
  iconProps,
  notificationEnabled,
  ...props
}: BrmIconButtonProps & { notificationEnabled: boolean }) {
  const intl = useIntl()

  const labelMessage = notificationEnabled
    ? intl.formatMessage({
        id: "iconButtons.unfollow.label",
        description: "The label to unfollow on an icon button",
        defaultMessage: "Unfollow",
      })
    : intl.formatMessage({
        id: "iconButtons.follow.label",
        description: "The label to follow on an icon button",
        defaultMessage: "Follow",
      })

  return (
    <IconButtonWithTooltip
      label={labelMessage}
      icon={<Icon as={notificationEnabled ? NotificationsEnabledIcon : NotificationsDisabledIcon} {...iconProps} />}
      {...props}
    />
  )
}
