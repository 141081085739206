import { Button, Input, InputGroup, InputRightAddon, Text } from "@chakra-ui/react"
import type { ChangeEventHandler, FC, HTMLInputTypeAttribute } from "react"
import Spinner from "../spinner.js"

interface TrailingButtonInputProps {
  id: string
  buttonText: string
  size: "sm" | "md"
  icon: React.ReactNode
  placeholder?: string
  onClick?: () => void
  onChange?: ChangeEventHandler<HTMLInputElement>
  value?: string
  isLoading: boolean
  type?: HTMLInputTypeAttribute
  buttonType?: "submit" | "button"
}

/**
 * TrailingButtonInput is a form input component that has a button on the right side.
 *
 *
 * @see BrmDesignLibrary https://www.figma.com/file/JCh4Ssbx77GrmVEmx9kKdb/%E2%9D%96-BRM-Design-System?type=design&node-id=3531-403305&mode=design&t=R57bKX7HtKOTaulT-4
 */
const TrailingButtonInput: FC<TrailingButtonInputProps> = ({
  icon,
  id,
  size,
  buttonText,
  placeholder,
  isLoading,
  type,
  onClick,
  onChange,
  value,
  buttonType,
}) => {
  return (
    <InputGroup size={size} variant="outline">
      <Input
        id={id}
        placeholder={placeholder}
        size={size}
        isDisabled={isLoading}
        type={type}
        onChange={onChange}
        value={value}
      />
      <InputRightAddon as={Button} gap={1} isDisabled={isLoading} onClick={onClick} type={buttonType}>
        {isLoading ? <Spinner /> : icon}
        <Text size="sm">{buttonText}</Text>
      </InputRightAddon>
    </InputGroup>
  )
}

export default TrailingButtonInput
