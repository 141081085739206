import type { PersonDetails } from "@brm/schema-types/types.js"
import { useIntl } from "react-intl"
import { useGetLoginV1SummaryQuery } from "../../app/services/generated-api.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import SecurityOverviewTable from "../security/SecurityOverviewTable.js"

interface Props {
  person: PersonDetails
}

export default function PersonSecurity({ person }: Props) {
  const intl = useIntl()
  const { data } = useGetLoginV1SummaryQuery({ personId: person.id })

  if (!data || Object.keys(data).length === 0) {
    return (
      <EmptyTableState
        emptyState={intl.formatMessage({
          id: "security.overview.emptyState",
          description: "Text to display instead of the security table when there are is no data",
          defaultMessage:
            "No security data found. Connect your single sign-on provider to BRM to surface security permissions for your apps",
        })}
      />
    )
  }
  return <SecurityOverviewTable data={data} />
}
