import { isDocumentOrURLStringType, isDocumentType } from "@brm/schema-helpers/schema.js"
import type { FieldChangeTimelineEvent, WorkflowRunMinimal } from "@brm/schema-types/types.js"
import { isApplicableWithDocumentType, isCompliantWithDocumentType } from "@brm/type-helpers/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import { Flex, Text } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { ReactElement } from "react"
import { useLocation } from "react-router-dom"
import type { ReadonlyDeep } from "type-fest"
import { AUTO_SELECT_STEP_HASH } from "../../features/workflows/constants.js"
import { NestedBadge } from "../../features/workflows/run/NestedBadge.js"
import { workflowRunStatusColors } from "../../features/workflows/run/status-colors.js"
import { displayWorkflowStatus } from "../../features/workflows/util.js"
import { timelineEventDocumentDownloadUrl } from "../../util/document.js"
import { matchesWorkflowRunPath, pathForWorkflowRun } from "../../util/json-schema.js"
import { DocumentDisplay } from "../Document/DocumentDisplay.js"
import { Link } from "../Link.js"

export const FieldChangeFieldTimelineEventSubdetail = ({
  event,
  fieldSchema,
}: {
  event: FieldChangeTimelineEvent
  actor: ReactElement
  objectLink?: ReactElement
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined
  fieldLabel: ReactElement | null
}) => {
  const location = useLocation()
  if (
    event.object_value?.object_type === "WorkflowRun" &&
    event.field_name === ("status" satisfies keyof WorkflowRunMinimal) &&
    typeof event.new_value === "string"
  ) {
    if (matchesWorkflowRunPath(location.pathname)) {
      return null
    }

    return (
      <NestedBadge
        as={Link}
        variant="clickable"
        to={{ pathname: pathForWorkflowRun(event.object_value), hash: AUTO_SELECT_STEP_HASH }}
        colorScheme={workflowRunStatusColors[event.object_value.status]}
        leftBadgeContent={displayWorkflowStatus(event.object_value.status)}
        rightContent={<Text>{event.object_value.display_name}</Text>}
      />
    )
  }

  if (
    (isDocumentType(fieldSchema, event.new_value) ||
      (isDocumentOrURLStringType(fieldSchema, event.new_value) && isObject(event.new_value))) &&
    event.new_value
  ) {
    return (
      <Flex mb={4}>
        <DocumentDisplay document={event.new_value} to={timelineEventDocumentDownloadUrl({ eventId: event.id })} />
      </Flex>
    )
  }

  if (
    (isCompliantWithDocumentType(fieldSchema, event.new_value) ||
      isApplicableWithDocumentType(fieldSchema, event.new_value)) &&
    isObject(event.new_value?.document)
  ) {
    return (
      <DocumentDisplay
        document={event.new_value.document}
        to={timelineEventDocumentDownloadUrl({ eventId: event.id })}
      />
    )
  }

  return null
}
