import {
  type BetweenFilter,
  type ExistsFilter,
  type FilterField,
  type GteFilter,
  type LteFilter,
} from "@brm/schema-types/types.js"
import { Center, Flex, Grid } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { useDebouncedCallback } from "use-debounce"
import { DurationFormControl } from "../../../Form/DurationInput.js"
import { NullCheckbox } from "../../../Form/NullCheckbox.js"
import { DATE_DURATION_UNITS } from "../../../Form/duration-unit.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { FilterProps } from "./types.js"

const minValueFromFilterField = (fields?: FilterField) => {
  return fields?.comparator === "between" ? fields.minValue : fields?.comparator === "gte" ? fields.value : ""
}

const maxValueFromFilterField = (fields?: FilterField) => {
  return fields?.comparator === "between" ? fields.maxValue : fields?.comparator === "lte" ? fields.value : ""
}

/** Filters are always in a menu so components like MenuItems must be used instead of Buttons */
export default function DurationRangeFilter({
  filter,
  displayPath,
  fieldSchema,
  isNullable,
  onChange,
}: FilterProps<BetweenFilter | GteFilter | LteFilter | ExistsFilter>) {
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")
  const isNullChecked = filter?.comparator === "exists" ? !filter.value : false

  return (
    <Flex flexDir="column" gap={3} p={3} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC}>
      {isNullable && (
        <NullCheckbox
          fieldName={fieldName}
          fieldSchema={fieldSchema}
          isChecked={isNullChecked}
          onChange={({ target: { checked } }) => {
            if (checked) {
              onChange({ comparator: "exists", value: false })
            }
          }}
        />
      )}
      <DurationRange
        onChange={onChange}
        displayPath={displayPath}
        filter={filter}
        fieldSchema={fieldSchema}
        isNullable={isNullable}
      />
    </Flex>
  )
}

export const DurationRange = ({
  filter,
  onChange,
}: FilterProps<BetweenFilter | GteFilter | LteFilter | ExistsFilter>) => {
  const [minValue, setMinValue] = useState(minValueFromFilterField(filter))
  const [maxValue, setMaxValue] = useState(maxValueFromFilterField(filter))

  const reportChange = (minValue?: string, maxValue?: string) => {
    if (minValue && maxValue) {
      onChange({ comparator: "between", minValue, maxValue })
    } else if (minValue) {
      onChange({ comparator: "gte", value: minValue })
    } else if (maxValue) {
      onChange({ comparator: "lte", value: maxValue })
    }
  }

  const debouncedReportChange = useDebouncedCallback(() => {
    reportChange(minValue, maxValue)
  }, 200)

  const enDash = "–"
  return (
    <Grid gridTemplateRows="auto auto" gridTemplateColumns="1fr auto 1fr" gridColumnGap={2}>
      <DurationFormControl
        display="contents"
        legend={
          <FormattedMessage
            id="menuItem.inputRange.minValue"
            description="The label for the input for the minimum value of the number range filter"
            defaultMessage="Minimum"
          />
        }
        legendProps={{ gridColumn: 1, gridRow: 1 }}
        inputGroupProps={{ gridColumn: 1, gridRow: 2 }}
        value={minValue}
        onChange={(duration) => {
          setMinValue(duration?.toString() ?? "")
          debouncedReportChange()
        }}
        units={DATE_DURATION_UNITS}
      />
      <Center gridRow={2} gridColumn={2}>
        {enDash}
      </Center>
      <DurationFormControl
        display="contents"
        legend={
          <FormattedMessage
            id="menuItem.inputRange.maxValue"
            description="The label for the input for the maximum value of the number range filter"
            defaultMessage="Maximum"
          />
        }
        legendProps={{ gridColumn: 3, gridRow: 1 }}
        inputGroupProps={{ gridColumn: 3, gridRow: 2 }}
        value={maxValue}
        onChange={(duration) => {
          setMaxValue(duration?.toString() ?? "")
          debouncedReportChange()
        }}
        units={DATE_DURATION_UNITS}
      />
    </Grid>
  )
}
