import type { WorkflowRun } from "@brm/schema-types/types.js"
import { Badge, Icon, Text } from "@chakra-ui/react"
import { DotIcon } from "../../../components/icons/icons.js"
import { displayWorkflowStatus } from "../util.js"
import { workflowRunStatusColors } from "./status-colors.js"

export function WorkflowRunStatusBadge({ run }: { run: Pick<WorkflowRun, "status"> }) {
  return (
    <Badge colorScheme={workflowRunStatusColors[run.status]} variant="subtleOutlined" size="sm" gap={1} minW={0}>
      <Icon as={DotIcon} color={`${workflowRunStatusColors[run.status]}.500`} />
      <Text as="span" isTruncated>
        {displayWorkflowStatus(run.status)}
      </Text>
    </Badge>
  )
}
