import { isValidFieldName } from "@brm/schema-helpers/field-names.js"
import type { CustomizableObjectType } from "@brm/schema-types/types.js"
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useEffect, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation } from "../../../app/services/generated-api.js"
import { PlusIcon } from "../../../components/icons/icons.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { CriterionConfigForm } from "./CriterionConfigForm.js"
import { DEFAULT_CRITERION_FORM_STATE } from "./util.js"

const formId = "new-criterion-form"

export const NewCriterionPopover: FunctionComponent<{
  objectType: CustomizableObjectType
  fieldNames?: ReadonlySet<string>
}> = ({ objectType, fieldNames }) => {
  const toast = useToast()
  const intl = useIntl()

  const popover = useDisclosure()
  const { onClose } = popover

  const location = useLocation()
  useEffect(() => {
    onClose()
  }, [location, onClose])

  const [createCustomCriterion, createCustomCriterionStatus] = usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation()

  const defaultValues = { ...DEFAULT_CRITERION_FORM_STATE, object_type: objectType }

  return (
    <Popover isLazy placement="bottom-end" {...popover}>
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={PlusIcon} />}
          variant="outline"
          aria-label={intl.formatMessage({
            defaultMessage: "Add new criterion",
            description: "Label for the button to add a new criterion",
            id: "settings.criteria.addNewCriterion",
          })}
          ml="auto"
        />
      </PopoverTrigger>
      <Portal>
        {fieldNames && (
          <PopoverContent width="md" maxWidth="90vw">
            <PopoverBody>
              <CriterionConfigForm
                id={formId}
                defaultValues={defaultValues}
                validateFieldName={(fieldName) => {
                  if (fieldNames.has(fieldName)) {
                    return intl.formatMessage({
                      defaultMessage:
                        "There is already a criterion with this or a similar name. Consider using the existing field or choosing a different name.",
                      description: "Error message for duplicate criterion name",
                      id: "settings.criteria.newCriterionModal.duplicateName",
                    })
                  }
                  if (!isValidFieldName(fieldName)) {
                    return intl.formatMessage({
                      defaultMessage: "Invalid field name.",
                      description: "Error message for invalid field name",
                      id: "settings.criteria.newCriterionModal.invalidFieldName",
                    })
                  }
                  return true
                }}
                onValidSubmit={async (fieldConfigInput) => {
                  try {
                    await createCustomCriterion({
                      objectType,
                      fieldName: fieldConfigInput.field_name,
                      fieldConfigInput,
                    }).unwrap()
                    toast({
                      description:
                        objectType === "LegalAgreement" ? (
                          <FormattedMessage
                            defaultMessage="Criterion saved. Extracting new field from existing legal agreements."
                            description="Success message for saving the criterion for the legal agreement object type"
                            id="settings.criteria.newCriterionModal.criterionSavedLegalAgreement"
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="Criterion saved"
                            description="Success message for saving the criterion"
                            id="settings.criteria.newCriterionModal.criterionSaved"
                          />
                        ),
                      status: "success",
                    })
                    popover.onClose()
                  } catch (err) {
                    toast({
                      description: getAPIErrorMessage(err) ?? (
                        <FormattedMessage
                          defaultMessage="Error saving the criterion"
                          description="Error message for saving the criterion"
                          id="settings.criteria.newCriterionModal.errorSaving"
                        />
                      ),
                      status: "error",
                    })
                  }
                }}
              />
            </PopoverBody>
            <PopoverFooter>
              <HStack justify="end">
                <Button type="button" onClick={popover.onClose}>
                  <FormattedMessage
                    defaultMessage="Cancel"
                    description="Cancel button label for new criterion modal"
                    id="settings.criteria.newCriterionModal.cancelButton"
                  />
                </Button>
                <Button
                  type="submit"
                  form={formId}
                  colorScheme="brand"
                  isLoading={createCustomCriterionStatus.isLoading}
                >
                  <FormattedMessage
                    defaultMessage="Save"
                    description="Save button label for new criterion modal"
                    id="settings.criteria.newCriterionModal.saveButton"
                  />
                </Button>
              </HStack>
            </PopoverFooter>
          </PopoverContent>
        )}
      </Portal>
    </Popover>
  )
}
