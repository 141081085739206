import { useEffect } from "react"
import type {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkStableEvent,
} from "react-plaid-link"
import { usePlaidLink } from "react-plaid-link"

interface Props {
  isOauth?: boolean
  token: string
  userId: string
  itemId?: number | null
  onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => void
  onExit: (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => void
  onEvent: (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => void
}

// Uses the usePlaidLink hook to manage the Plaid Link creation.  See https://github.com/plaid/react-plaid-link for full usage instructions.
// The link token passed to usePlaidLink cannot be null.  It must be generated outside of this component.

export default function LaunchLink({ onSuccess, onExit, onEvent, token, isOauth, userId, itemId }: Props) {
  const config: PlaidLinkOptionsWithLinkToken = {
    onSuccess,
    onExit,
    onEvent,
    token,
  }

  if (isOauth) {
    config.receivedRedirectUri = window.location.href // add additional receivedRedirectUri config when handling an OAuth reidrect
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    // initiallizes Link automatically
    if (isOauth && ready) {
      open()
    } else if (ready) {
      // regular, non-OAuth case:
      // set link token, userId and itemId in local storage for use if needed later by OAuth

      localStorage.setItem("oauthConfig", JSON.stringify({ userId, itemId, token }))
      open()
    }
  }, [ready, open, isOauth, userId, itemId, token])

  return undefined
}
