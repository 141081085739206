import type { TagProps } from "@chakra-ui/react"
import { Tag } from "@chakra-ui/react"
import { displayScope } from "./util.js"

interface Props extends TagProps {
  scope: string
}

export default function ScopeTag({ scope, ...labelProps }: Props) {
  return <Tag {...labelProps}>{displayScope(scope)}</Tag>
}
