import type { Task, WorkflowStepStandardType } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Avatar, Grid, GridItem, ListItem, Skeleton, Text } from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import type { FC, ReactNode, SVGProps } from "react"
import { useIntl, type IntlShape } from "react-intl"
import { useLocation } from "react-router-dom"
import type { ReadonlyObjectDeep } from "type-fest/source/readonly-deep.js"
import { isNotVoid } from "typed-assert"
import Button from "../../../components/Button/Button.js"
import { ButtonStyles } from "../../../components/Button/types.js"
import { FormattedDate } from "../../../components/FormattedDate.js"
import { VendorIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import { compareLocationToTaskUrl, getTaskPrimarySrcUrl } from "./helper.js"

const TaskItemNetworkWrapper: FC<{
  task: Task
}> = ({ task }) => {
  const objectSchema = useObjectSchema(task.type === "workflow_field_gatherer" ? task.object_type : skipToken)
  return <TaskItem task={task} objectSchema={objectSchema} />
}

export const TaskItem: FC<{
  task: Task
  objectSchema?: ReadonlyObjectDeep<JSONSchemaObject>
}> = ({ task, objectSchema }) => {
  const intl = useIntl()

  // Check if the task is selected by looking at the route and search params
  const location = useLocation()
  const isSelected = compareLocationToTaskUrl(location, task)

  switch (task.type) {
    case "workflow_step_gatherer":
      if (task.pending_steps.length === 1) {
        isNotVoid(task.pending_steps[0])
        return (
          <TaskItemCore
            title={task.workflow_name}
            body={intl.formatMessage(
              {
                id: "inbox.task.workflow_step_gatherer.body.one_step",
                defaultMessage: "Complete the {stepName} Step. The request is due on {dueDate}",
                description: "Task description for workflow step gatherer task",
              },
              {
                stepName: workflowStepStandardTypeToText(task.pending_steps[0], intl),
                dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
              }
            )}
            primaryCTAText="View request"
            primaryCTASrc={getTaskPrimarySrcUrl(task)}
            isSelected={isSelected}
            avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
            fallbackAvatarLogo={VendorIcon}
          />
        )
      }
      if (task.pending_steps.length > 1) {
        return (
          <TaskItemCore
            title={task.workflow_name}
            body={intl.formatMessage(
              {
                id: "inbox.task.workflow_step_gatherer.body.multi_step",
                defaultMessage: "Complete the request steps. The request is due on {dueDate}",
                description: "Task description for workflow step gatherer task",
              },
              {
                dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
              }
            )}
            primaryCTAText="View request"
            primaryCTASrc={getTaskPrimarySrcUrl(task)}
            isSelected={isSelected}
            avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
            fallbackAvatarLogo={VendorIcon}
          />
        )
      }
      throw new Error("workflow gatherer task has no pending steps")

    case "workflow_champ":
      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_champ.body",
              defaultMessage:
                "Complete the request to {workflowKind} {toolDisplayName}. The request is due on {dueDate}",
              description: "Task description for workflow champ task",
            },
            {
              workflowKind: task.workflow_type,
              toolDisplayName: task.tool_display_name,
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText="View request"
          primaryCTASrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )

    case "workflow_run_step_ready_for_review":
      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_run_step_ready_for_review.body",
              defaultMessage:
                "Review the {stepName} Step submitted by {submitterName}. The request is due on {dueDate}",
              description: "Task description for step ready for review task",
            },
            {
              stepName: workflowStepStandardTypeToText(task.workflow_run_step_display_name, intl),
              submitterName: displayPersonName(
                {
                  id: task.submitter_id,
                  first_name: task.submitter_first_name,
                  last_name: task.submitter_last_name,
                },
                intl
              ),
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText="View Request"
          primaryCTASrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )

    case "workflow_field_gatherer": {
      if (!objectSchema) {
        return <Skeleton height="20" borderBottom="1px solid" borderColor="gray.200" />
      }
      const fieldSchema = getSchemaAtPath(objectSchema, [task.field_name])

      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_field_gatherer.body",
              defaultMessage: "Complete the {fieldName} criteria on {stepName} step. The request is due on {dueDate}",
              description: "Task description for workflow field gatherer task",
            },
            {
              fieldName: getTitle(task.field_name, fieldSchema),
              stepName: workflowStepStandardTypeToText(task.workflow_run_step_display_name, intl),
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText="View Request"
          primaryCTASrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )
    }
    case "workflow_run_aggregated_steps_ready_for_review":
      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_run_aggregated_steps_ready_for_review.body",
              defaultMessage: "Review multiple steps submitted by team members. The request is due on {dueDate}",
              description: "Task description for aggregated steps ready for review task",
            },
            {
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText="View Request"
          primaryCTASrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )

    default:
      unreachable(task)
  }
}

export const TaskItemCore: FC<{
  title: string
  body: ReactNode
  primaryCTAText: string
  secondaryCTAText?: string
  primaryCTASrc: string
  secondaryCTAOnClick?: () => void
  avatarSrc?: string
  fallbackAvatarLogo?: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
  dueDate?: string
  isSelected: boolean
}> = ({ avatarSrc, title, body, primaryCTASrc, primaryCTAText, secondaryCTAText, isSelected, fallbackAvatarLogo }) => {
  return (
    <ListItem display="flex" transition="opacity 0.3s ease-in-out" px={4}>
      <Link
        to={primaryCTASrc}
        display="flex"
        _hover={{ textDecoration: "none" }}
        background={isSelected ? "gray.50" : undefined}
        width="100%"
        gap={3}
        overflow="hidden"
      >
        <Grid templateColumns="2rem 1fr" pt="2" columnGap={3} rowGap={1}>
          <GridItem colSpan={1} display="flex" alignItems="center">
            {avatarSrc ? <Avatar size="md" src={avatarSrc} /> : <Avatar size="md" as={fallbackAvatarLogo} />}
          </GridItem>
          <GridItem colSpan={1} overflow="hidden">
            <Text size="sm" fontWeight="medium" isTruncated>
              {title}
            </Text>
            <Text color="gray.600">{body}</Text>
          </GridItem>
          <GridItem colSpan={1}></GridItem>
          <GridItem colSpan={1} display="flex" gap="3" pb="2" borderBottom="1px solid" borderColor="gray.200">
            {secondaryCTAText && <Button size="md" buttonStyles={ButtonStyles.Link} label={secondaryCTAText} />}
            <Button size="md" buttonStyles={ButtonStyles.LinkBrand} label={primaryCTAText} />
          </GridItem>
        </Grid>
      </Link>
    </ListItem>
  )
}

export default TaskItemNetworkWrapper

const workflowStepStandardTypeToText = (step: WorkflowStepStandardType, intl: IntlShape) => {
  switch (step) {
    case "compliance":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.compliance",
        defaultMessage: "Compliance",
        description: "Workflow step standard type compliance",
      })
    case "details":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.details",
        defaultMessage: "Details",
        description: "Workflow step standard type details",
      })
    case "finance":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.finance",
        defaultMessage: "Finance",
        description: "Workflow step standard type finance",
      })
    case "it":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.it",
        defaultMessage: "IT",
        description: "Workflow step standard type it",
      })
    case "legal":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.legal",
        defaultMessage: "Legal",
        description: "Workflow step standard type legal",
      })
    case "close":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.close",
        defaultMessage: "Close",
        description: "Workflow step standard type close",
      })
    case "custom":
      return intl.formatMessage({
        id: "inbox.task.workflow_step_standard_type.custom",
        defaultMessage: "Custom",
        description: "Workflow step standard type custom",
      })
    default:
      unreachable(step)
  }
}
