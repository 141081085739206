import type { Role } from "@brm/schema-types/types.js"
import { Tag } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { getLabelByRoleOrUser } from "./role.js"

interface Props {
  role: Role | "user"
}

export function RoleTag({ role }: Props) {
  const intl = useIntl()
  const labelByRole = getLabelByRoleOrUser(intl)
  return (
    <Tag variant="outline" mr={1}>
      {labelByRole[role]}
    </Tag>
  )
}
