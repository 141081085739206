import type { InputProps } from "@chakra-ui/react"
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react"
import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { EyeIcon, EyeOffIcon } from "../icons/icons.js"

export const passwordField = React.forwardRef<
  HTMLInputElement,
  InputProps & { isInvalid?: boolean; invalidMessage?: string; labelText?: string }
>((props, ref) => {
  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure()
  const inputRef = React.useRef<HTMLInputElement>(null)

  const mergeRef = useMergeRefs(inputRef, ref)
  const onClickReveal = () => {
    onToggle()
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }

  const { invalidMessage, isInvalid, labelText, ...inputProps } = props

  return (
    <FormControl isInvalid={isInvalid} isRequired>
      <FormLabel htmlFor="password">
        {labelText || (
          <FormattedMessage
            id="login.form.password"
            description="Label for login form email field"
            defaultMessage="Password"
          />
        )}
      </FormLabel>
      <InputGroup>
        <Input
          id="password"
          ref={mergeRef}
          name="password"
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          required
          {...inputProps}
        />
        <InputRightElement>
          <IconButton
            variant="link"
            aria-label={
              isOpen
                ? intl.formatMessage({
                    id: "passwordField.maskPasswordButton.mask.label",
                    description: "Label for the mask password button in the password field to mask the password",
                    defaultMessage: "Mask password",
                  })
                : intl.formatMessage({
                    id: "passwordField.maskPasswordButton.reveal.label",
                    description: "Label for the mask password button in the password field to reveal the password",
                    defaultMessage: "Reveal password",
                  })
            }
            icon={isOpen ? <Icon as={EyeOffIcon} /> : <Icon as={EyeIcon} />}
            onClick={onClickReveal}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{invalidMessage}</FormErrorMessage>
    </FormControl>
  )
})
passwordField.displayName = "PasswordField"

export default passwordField
