import { Box, Tooltip, type TextProps } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"

export default function OverflownText({ children, ...props }: TextProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element = ref.current
    if (element) {
      setIsOverflown(element.scrollWidth > element.clientWidth)
    }
  }, [])

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" minW={0} isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  )
}
