import { useRouteError } from "react-router-dom"
import { StatusCodes, getAPIErrorStatusCode } from "../../../util/error.js"
import ErrorBoundary from "../../error/RouteError.js"
import WorkflowRunNoPermissionView from "./WorkflowRunNoPermissionView.js"

const WorkflowRunErrorBoundary = () => {
  const error = useRouteError() as { statusText: string; message: string; error: string; data?: { message?: string } }

  if (getAPIErrorStatusCode(error) === StatusCodes.FORBIDDEN) {
    return <WorkflowRunNoPermissionView />
  }
  return <ErrorBoundary />
}

export default WorkflowRunErrorBoundary
