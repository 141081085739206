import {
  Alert,
  AlertDescription,
  Divider,
  HStack,
  Heading,
  Stack,
  useDisclosure,
  type StackProps,
} from "@chakra-ui/react"
import { useMemo } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { FormattedMessage, useIntl } from "react-intl"
import { useSessionStorage } from "usehooks-ts"
import { Timeline, type TimelineProps } from "../../features/timeline/Timeline.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../../features/workflows/run/utils.js"
import { PAGE_PADDING_X } from "../../util/constant.js"
import CancelIconButton from "../icons/system/CancelIconButton.js"
import CommentIconButton from "../icons/system/CommentIconButton.js"
import { TimelineCommentInput, type TimelineCommentInputProps } from "./TimelineCommentInput.js"

interface Props extends StackProps {
  hideStorageKey: string
  timelineProps: TimelineProps
  heading?: string
}
export default function SessionStorageTimelineContainer({
  timelineProps,
  hideStorageKey,
  heading,
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
  ...stackProps
}: Props & GetOrganizationActorProps & GetLogoForOrganizationProps) {
  const intl = useIntl()

  const [hideTimeline] = useSessionStorage(hideStorageKey, false)
  const headerCommentInput = useDisclosure()

  // For now only tool and vendor timelines can have comments added at the timeline header level
  const timelineCommentProps = useMemo(():
    | Pick<TimelineCommentInputProps, "objectId" | "objectType" | "label">
    | undefined => {
    if (timelineProps.filterParams?.toolId) {
      return {
        objectId: timelineProps.filterParams.toolId,
        objectType: "Tool",
        label: intl.formatMessage({
          defaultMessage: "Tool",
          id: "tool.timelineComment.label",
          description: "Label for tool comments",
        }),
      }
    }
    if (timelineProps.filterParams?.vendorId) {
      return {
        objectId: timelineProps.filterParams.vendorId,
        objectType: "Vendor",
        label: intl.formatMessage({
          defaultMessage: "Vendor",
          id: "vendor.timelineComment.label",
          description: "Label for vendor comments",
        }),
      }
    }
    return undefined
  }, [timelineProps.filterParams, intl])

  if (hideTimeline) {
    return null
  }

  return (
    <Stack
      width="512px"
      minH={0}
      pl={PAGE_PADDING_X}
      py={4}
      flexShrink={0}
      height="100%"
      isolation="isolate"
      {...stackProps}
    >
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <Alert variant="error">
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}
      >
        <HStack justifyContent="space-between">
          <Heading as="h2" size="xs" fontSize="xl">
            {heading ?? (
              <FormattedMessage
                defaultMessage="Activity"
                description="Default heading for the activity timeline"
                id="timeline.heading.default"
              />
            )}
          </Heading>
          {timelineCommentProps &&
            (headerCommentInput.isOpen ? (
              <CancelIconButton variant="ghost" onClick={headerCommentInput.onClose} />
            ) : (
              <CommentIconButton variant="ghost" onClick={headerCommentInput.onOpen} />
            ))}
        </HStack>
        {timelineCommentProps && headerCommentInput.isOpen && (
          <>
            <TimelineCommentInput
              mr={4}
              {...timelineCommentProps}
              onSaveComment={headerCommentInput.onClose}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              autoFocus
            />
            <Divider my={2} />
          </>
        )}
        <Timeline
          {...timelineProps}
          getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
          getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        />
      </ErrorBoundary>
    </Stack>
  )
}
