import { Image, Tooltip, useToast } from "@chakra-ui/react"
import { useState, type FC } from "react"
import { useIntl } from "react-intl"
import braimLogo from "../../../assets/braim.svg"
import {
  useGetDynamoV1ByIdIsDynamoRunningQuery,
  usePostDynamoV1ByToolListingIdMutation,
} from "../../app/services/generated-api.js"
import Button from "../../components/Button/Button.js"
import { ButtonStyles } from "../../components/Button/types.js"
import { SHORT_TOOLTIP_OPEN_DELAY } from "../../util/constant.js"
import { log } from "../../util/logger.js"

export interface TriggerButtonProps {
  onRun: () => void
  isDisabled?: boolean
}

export const TriggerButton: FC<TriggerButtonProps> = ({ onRun, isDisabled }) => {
  const toast = useToast()
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(false)

  return (
    <Tooltip
      openDelay={SHORT_TOOLTIP_OPEN_DELAY}
      isDisabled={isDisabled || isLoading}
      label={intl.formatMessage({
        id: "dynamo.triggerButton.tooltip",
        defaultMessage: "Ask BRM’s AI Agent to gather data from the web",
        description: "Tooltip for the button that triggers AI data gathering",
      })}
    >
      <Button
        buttonStyles={ButtonStyles.Ghost}
        isDisabled={isDisabled}
        size="sm"
        leftIcon={<Image src={braimLogo} height={4} opacity={0.85} />}
        isLoading={isLoading}
        label={intl.formatMessage({
          id: "dynamo.triggerButton.label",
          defaultMessage: "Gather Data",
          description: "Label for the button that triggers AI data gathering",
        })}
        onClick={() => {
          setIsLoading(true)
          try {
            onRun()
            toast({
              status: "success",
              title: "Gathering Data",
            })
          } catch (err) {
            setIsLoading(false)
            log.error("Error running Dynamo", err)
            toast({
              status: "error",
              title: "Failed to start data gathering",
            })
          }
        }}
      />
    </Tooltip>
  )
}

export const DynamoTrigger: FC<{ toolListingId: string }> = ({ toolListingId }) => {
  const [runDynamo] = usePostDynamoV1ByToolListingIdMutation()

  const { data: dynamoRun } = useGetDynamoV1ByIdIsDynamoRunningQuery({
    id: toolListingId,
  })

  if (dynamoRun) {
    return null
  }
  return (
    <TriggerButton
      onRun={async () => {
        await runDynamo({ toolListingId }).unwrap()
      }}
    />
  )
}

export default DynamoTrigger
