import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import {
  isDocumentOrURLStringType,
  isDocumentType,
  isFrequencyOrOneTimeType,
  isWorkflowRunProgressType,
} from "@brm/schema-helpers/schema.js"
import { isCurrencyAmountType, isSpendSummaryType, isStdObjSchema } from "@brm/type-helpers/schema.js"
import { getTitle, isEnumArrayType, isEnumType } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import { Box, Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { log } from "../../../util/logger.js"
import { BackIcon, SearchIcon } from "../../icons/icons.js"
import BooleanFilter from "./FilterTypes/BooleanFilter.js"
import DateRangeFilter from "./FilterTypes/DateSelectorFilter.js"
import DurationRangeFilter from "./FilterTypes/DurationRangeFilter.js"
import EnumSelectorFilter from "./FilterTypes/EnumSelectorFilter.js"
import ExistsFilter from "./FilterTypes/ExistsFilter.js"
import FrequencyRangeFilter from "./FilterTypes/FrequencyRangeFilter.js"
import MoneyRangeFilter from "./FilterTypes/MoneyRangeFilter.js"
import NumberRangeFilter from "./FilterTypes/NumberRangeFilter.js"
import ObjectSelectorSchemaFilter from "./FilterTypes/ObjectSelectorSchemaFilter.js"
import StringFilter from "./FilterTypes/StringFilter.js"
import type { FilterProps } from "./FilterTypes/types.js"
import { isValuesFilter } from "./util.js"

export default function FilterConfigPopoverBody({
  displayPath,
  filter,
  fieldSchema,
  isNullable,
  onBackClick,
  onChange,
}: FilterProps & {
  /** Called when the user clicks the back button inside the popover. */
  onBackClick?: () => void
}) {
  const intl = useIntl()

  const [searchInput, setSearchInput] = useState("")

  if (!isObject(fieldSchema)) {
    return null
  }

  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName)
  const fieldTitle = getTitle(fieldName, fieldSchema)

  const schemaWithValue = { schema: fieldSchema }

  const filterProps = {
    displayPath,
    filter,
    fieldSchema,
    isNullable,
    onChange,
  }

  return (
    <>
      <Flex background="white" zIndex={1} borderBottomWidth="1px" p={2} gap={1} alignItems="center">
        {onBackClick && (
          <IconButton
            variant="ghost"
            onClick={onBackClick}
            icon={<Icon as={BackIcon} />}
            aria-label={intl.formatMessage({
              defaultMessage: "Back to filter popover",
              id: "filter.back",
              description: "Label for back button in filter popover",
            })}
          />
        )}
        {isStdObjSchema(fieldSchema) || isStdObjSchema(fieldSchema.items) ? (
          <InputGroup>
            {!onBackClick && (
              <InputLeftElement>
                <Icon as={SearchIcon} />
              </InputLeftElement>
            )}
            <Input placeholder={fieldTitle} onChange={(e) => setSearchInput(e.target.value)} autoFocus />
          </InputGroup>
        ) : (
          <Text fontWeight="semibold">{fieldTitle}</Text>
        )}
      </Flex>
      <Box>
        {isEnumType(schemaWithValue) ||
        isEnumArrayType(schemaWithValue) ||
        isWorkflowRunProgressType(schemaWithValue) ? (
          (!filter || isValuesFilter(filter)) && <EnumSelectorFilter {...filterProps} filter={filter} />
        ) : isDocumentOrURLStringType(fieldSchema) || isDocumentType(fieldSchema) ? (
          (!filter || filter.comparator === "exists") && <ExistsFilter {...filterProps} filter={filter} />
        ) : (isStdObjSchema(fieldSchema) || isStdObjSchema(fieldSchema.items)) &&
          (!filter || isValuesFilter(filter)) ? (
          <ObjectSelectorSchemaFilter {...filterProps} filter={filter} searchInput={searchInput} />
        ) : isFrequencyOrOneTimeType(fieldSchema) ? (
          (!filter ||
            filter.comparator === "between" ||
            filter.comparator === "gte" ||
            filter.comparator === "lte" ||
            filter.comparator === "exists" ||
            filter.comparator === "eq") && <FrequencyRangeFilter {...filterProps} filter={filter} />
        ) : fieldSchema.type === "string" ? (
          fieldSchema.format === "date" || fieldSchema.format === "date-time" ? (
            <DateRangeFilter {...filterProps} filter={filter} />
          ) : fieldSchema.format === "duration" ? (
            (!filter ||
              filter.comparator === "between" ||
              filter.comparator === "gte" ||
              filter.comparator === "lte" ||
              filter.comparator === "exists") && <DurationRangeFilter {...filterProps} filter={filter} />
          ) : (
            (!filter || filter.comparator === "contains" || filter.comparator === "exists") && (
              <StringFilter {...filterProps} filter={filter} />
            )
          )
        ) : isRichTextType(fieldSchema) ? (
          (!filter || filter.comparator === "contains" || filter.comparator === "exists") && (
            <StringFilter {...filterProps} filter={filter} />
          )
        ) : fieldSchema.type === "boolean" ? (
          (!filter || filter.comparator === "any") && <BooleanFilter {...filterProps} filter={filter} />
        ) : fieldSchema.type === "number" || fieldSchema.type === "integer" ? (
          (!filter ||
            filter.comparator === "between" ||
            filter.comparator === "gte" ||
            filter.comparator === "lte" ||
            filter.comparator === "exists") && <NumberRangeFilter {...filterProps} filter={filter} />
        ) : isCurrencyAmountType(fieldSchema) || isSpendSummaryType(fieldSchema) ? (
          (!filter ||
            filter.comparator === "between" ||
            filter.comparator === "gte" ||
            filter.comparator === "lte" ||
            filter.comparator === "exists") && <MoneyRangeFilter {...filterProps} filter={filter} />
        ) : (
          (() => {
            log.warn("Unknown schema in FilterConfigPopoverBody for display path", displayPath.join("."), fieldSchema)
            return (
              <Text p={2}>
                <FormattedMessage
                  id="unknownSchema"
                  defaultMessage="This column can not be filtered by."
                  description="Text for unknown schema"
                />
              </Text>
            )
          })()
        )}
      </Box>
    </>
  )
}
