import { formatFieldPathLabel } from "@brm/schema-helpers/format.js"
import { isWorkflowRunProgressType } from "@brm/schema-helpers/schema.js"
import {
  isCurrencyAmountType,
  isIntegrationType,
  isPersonType,
  isSpendSummaryType,
  isStdObjSchema,
  isToolType,
} from "@brm/type-helpers/schema.js"
import { getSchemaAtPath, getValueAtPath, isEnumArrayType, isEnumType, isNumericStringType } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { createColumnHelper, type ColumnDef, type ColumnMeta } from "@tanstack/react-table"
import objectPath from "object-path"
import { useMemo } from "react"
import { isDefined } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import { log } from "../../util/logger.js"
import { FieldValue } from "../SchemaForm/FieldValue.js"
import { findClosestFieldsMetadata } from "../SchemaForm/utils.js"
import {
  DATE_COLUMN_SIZE,
  MD_COLUMN_SIZE,
  MONEY_COLUMN_SIZE,
  PRIMARY_COLUMN_SIZE,
  SIMPLE_COUNT_COLUMN_SIZE,
  SM_COLUMN_SIZE,
  TOOL_NAME_COLUMN_SIZE,
  USER_PERSON_COLUMN_SIZE,
  XL_COLUMN_SIZE,
  XS_COLUMN_SIZE,
} from "./const.js"

/**
 * Generates TanStack Table column definitions based on a given JSON schema any shown columns.
 */
export function useSchemaColumns<T>({
  objectSchema,
  shownColumns,
  getDocumentDownloadUrlWithRowValue,
  primaryColumn,
  enableSorting,
}: {
  objectSchema: ReadonlyDeep<JSONSchema> | undefined
  shownColumns: Iterable<string> | undefined
  getDocumentDownloadUrlWithRowValue?: (rowValue: T, fieldPath: readonly (string | number)[]) => string
  primaryColumn?: string | undefined
  enableSorting?: boolean
}): ColumnDef<T>[] | undefined {
  return useMemo(() => {
    if (!objectSchema || !shownColumns) {
      return undefined
    }
    const columnHelper = createColumnHelper<T>()
    return [primaryColumn, ...shownColumns]
      .map((path) => {
        if (path === undefined) {
          return undefined
        }
        const pathArray = path.split(".").filter(Boolean)
        const schema = getSchemaAtPath(objectSchema, pathArray)
        if (!schema) {
          log.warn(`Could not find schema for path: ${path}`)
          return undefined
        }

        const header = formatFieldPathLabel(pathArray, objectSchema)

        return columnHelper.accessor((rowValue) => getValueAtPath(rowValue, pathArray), {
          id: path,
          header,
          cell: ({ getValue, row }) => {
            const value = getValue()
            const parentValue = getValueAtPath(row.original, pathArray.slice(0, -1))
            const closestFieldSourceMetadata = findClosestFieldsMetadata(pathArray, pathArray.length, row.original)
            let fieldMetadata
            if (closestFieldSourceMetadata) {
              const { fieldsMetadata, fieldMetadataPath } = closestFieldSourceMetadata
              fieldMetadata = fieldsMetadata && objectPath.get(fieldsMetadata, fieldMetadataPath.join("."))

              // TODO: complete the migration of the data table to display the api compliance schema
              // I.E. support compound fields like gdpr: {} object
              if (!fieldMetadata) {
                const fieldName = fieldMetadataPath.join("_").replace("_document", "").replace("_compliant", "")
                fieldMetadata = fieldsMetadata && objectPath.get(fieldsMetadata, fieldName)
              }
            }
            return (
              <FieldValue
                value={value}
                parentValue={parentValue}
                fieldMetadata={fieldMetadata}
                fieldPath={pathArray}
                fieldSchema={schema}
                fieldLabel={header}
                logoSize={6}
                avatarSize="sm"
                getDocumentDownloadUrl={
                  getDocumentDownloadUrlWithRowValue
                    ? (fieldPath) => getDocumentDownloadUrlWithRowValue(row.original, fieldPath)
                    : undefined
                }
              />
            )
          },
          enablePinning: true,
          enableSorting: enableSorting ?? true,
          size:
            path === primaryColumn
              ? PRIMARY_COLUMN_SIZE
              : (getCustomColumnSizeFromSchema(objectSchema, pathArray) ?? getColumnSizeFromSchema(schema)),
          meta: getColumnMetaFromSchema(schema, pathArray),
        })
      })
      .filter(isDefined)
  }, [objectSchema, shownColumns, primaryColumn, enableSorting, getDocumentDownloadUrlWithRowValue])
}

function getColumnMetaFromSchema(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  fieldPath: string[]
): ColumnMeta<unknown, unknown> | undefined {
  if (!isObject(schema)) {
    return undefined
  }

  const fieldName = fieldPath.at(-1)
  if (
    fieldPath.length === 1 &&
    ((fieldName === "owner" && isPersonType(schema)) ||
      ((fieldName === "status" ||
        fieldName === "it_status" ||
        fieldName === "compliance_status" ||
        fieldName === "finance_status") &&
        isEnumType({ schema })))
  ) {
    // Cells that have inline editing always need to be rendered, else it's not possible to edit `null` values.
    return { alwaysRenderCell: true }
  }

  if (
    schema.type === "integer" ||
    schema.type === "number" ||
    isNumericStringType(schema) ||
    isCurrencyAmountType(schema) ||
    isSpendSummaryType(schema)
  ) {
    return { textAlign: "right" }
  }

  if (fieldPath.at(-1) === "scopes") {
    return { wrapOverflow: true }
  }

  return undefined
}

function getCustomColumnSizeFromSchema(
  objectSchema: ReadonlyDeep<JSONSchema> | undefined,
  pathArray: readonly (string | number)[]
): number | undefined {
  const fieldName = pathArray.at(-1)
  const objectPath = pathArray.slice(0, -1)
  const parentSchema = getSchemaAtPath(objectSchema, objectPath)

  if (isStdObjSchema(parentSchema)) {
    switch (parentSchema.properties.object_type.const) {
      case "LegalAgreement":
        if (fieldName === "source") {
          return XS_COLUMN_SIZE
        }
    }
  }

  return
}

function getColumnSizeFromSchema(schema: ReadonlyDeep<JSONSchema> | undefined): number {
  if (!isObject(schema)) {
    return SM_COLUMN_SIZE
  }
  if (isCurrencyAmountType(schema)) {
    return MONEY_COLUMN_SIZE
  }
  if (isEnumType({ schema })) {
    return SM_COLUMN_SIZE
  }
  if (schema.type === "integer") {
    return SIMPLE_COUNT_COLUMN_SIZE
  }
  if (isEnumArrayType({ schema })) {
    return MD_COLUMN_SIZE
  }
  if (schema.type === "array") {
    if (isIntegrationType(schema.items)) {
      return XS_COLUMN_SIZE
    }
    // sso_types, mfa_types, employee_groups, categories, erp_tracking_categories, ...
    return XL_COLUMN_SIZE
  }
  if (isStdObjSchema(schema)) {
    if (isPersonType(schema)) {
      return USER_PERSON_COLUMN_SIZE
    }
    if (isToolType(schema)) {
      return TOOL_NAME_COLUMN_SIZE
    }
  }
  if (isWorkflowRunProgressType({ schema })) {
    return XL_COLUMN_SIZE
  }
  if (schema.type === "string") {
    if (schema.format === "date" || schema.format === "date-time") {
      return DATE_COLUMN_SIZE
    }
    if (schema.format === "duration") {
      return XS_COLUMN_SIZE
    }
    if (schema.format) {
      return MD_COLUMN_SIZE
    }
    return XL_COLUMN_SIZE
  }
  if (schema.type === "boolean") {
    return XS_COLUMN_SIZE
  }
  return SM_COLUMN_SIZE
}
