import { getTitle } from "@brm/util/schema.js"
import { Checkbox, type CheckboxProps } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"

interface Props extends CheckboxProps {
  fieldName: string
  fieldSchema: ReadonlyDeep<JSONSchema>
}

export function NullCheckbox({ fieldName, fieldSchema, ...props }: Props) {
  return (
    <Checkbox value="null" {...props}>
      <FormattedMessage
        id="menu.item.null"
        defaultMessage="No {title}"
        description="Option for a menu item checkbox that represents searching for rows without a value"
        values={{ title: getTitle(fieldName, fieldSchema) }}
      />
    </Checkbox>
  )
}
