import { getMarkdownConfig } from "@brm/util/markdown.js"
import { Divider, Image, ListItem, OrderedList, UnorderedList, chakra } from "@chakra-ui/react"
import { memo } from "react"
import Markdown from "react-markdown"
import { LinkOrSpan } from "./Link.js"

const markdownConfig = getMarkdownConfig()

export const RenderedMarkdown = memo(function RenderedComment({ content }: { content: string }) {
  return (
    <Markdown
      {...markdownConfig}
      components={{
        p: ({ node: _, ...props }) => <chakra.p {...props} mb={2} sx={{ "&:last-child": { mb: 0 } }} />,
        hr: ({ node: _, ...props }) => <Divider {...props} />,
        ul: ({ node: _, ...props }) => <UnorderedList {...props} pl={3} />,
        ol: ({ node: _, ...props }) => <OrderedList {...props} pl={3} />,
        li: ({ node: _, ...props }) => <ListItem {...props} />,
        a: ({ node: _, ...props }) => <LinkOrSpan {...props} to={props.href} variant="highlighted" />,
        img: ({ node: _, ...props }) => <Image {...props} />,
        blockquote: ({ node: _, ...props }) => (
          <chakra.blockquote borderLeftWidth={4} borderColor="gray.100" pl={2} mb={2} color="gray.700" {...props} />
        ),
      }}
    >
      {content}
    </Markdown>
  )
})
