import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import {
  isDocumentOrURLStringType,
  isDocumentType,
  isFrequencyOrOneTimeType,
  isWorkflowRunProgressType,
} from "@brm/schema-helpers/schema.js"
import type { ContainsFilter, FilterField, SavedView, SortDirection } from "@brm/schema-types/types.js"
import { FilterFieldSchema } from "@brm/schemas"
import type { Filter } from "@brm/type-helpers/filters.js"
import { NULL_URL_VALUE, valuesForFilterField } from "@brm/type-helpers/filters.js"
import { isCurrencyAmountType, isSpendSummaryType, isStdObjSchema } from "@brm/type-helpers/schema.js"
import { expandUUID, shortenUUID } from "@brm/util/short-uuids.js"
import { isObject } from "@brm/util/type-guard.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import type { SortingState } from "@tanstack/react-table"
import Big from "big.js"
import type { IntlShape } from "react-intl"
import { isPresent } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import {
  UnexpectedSchemaError,
  getSchemaAtPath,
  isEnumArrayType,
  isEnumType,
  isGrowthRateType,
  isNumericStringType,
} from "../../../packages/util/src/schema.js"
import { getDateFilterOptions } from "../components/DataTable/SchemaFilter/options.js"
import { displayPathFromFilterPath } from "../components/DataTable/SchemaFilter/util.js"
import { isCurrencyRangeType } from "./json-schema.js"
import { log } from "./logger.js"

export const TABLE_PAGE_SIZE_OPTIONS = [25, 50, 100] as const
export const TABLE_PAGE_SIZE_DEFAULT = 25
export const TABLE_INITIAL_PAGE = 1

export const TABLE_DEFAULT_PARAMS: TableParamsState<never> = {
  page: TABLE_INITIAL_PAGE,
  pageSize: TABLE_PAGE_SIZE_DEFAULT,
  sorting: [],
  filterMap: new Map<never, Filter<never>>(),
  savedViewState: {},
}

/**
 * The delimiter between multiple values of `any` or `arr_contains` filters.
 *
 * We want to avoid percent encoding in the URL, so this has to be a character that is not used in the values
 * (UUIDs and category names) and not in the percent encode set of `x-www-form-urlencoded`. Only very few
 * characters meet these criteria: https://url.spec.whatwg.org/#ref-for-application-x-www-form-urlencoded-percent-encode-set
 *
 * An alternative would be to repeat the filters for each value for these particular comparators, i.e. interpreting
 * them as a logical OR instead of an AND like for all other filters.
 */
export const FILTER_VALUE_DELIMITER = "*"

export const transformFilterFields = (
  fields: FilterField,
  transformation: (value: string) => string | undefined
): FilterField | undefined => {
  switch (fields.comparator) {
    case "starts_with":
    case "contains":
    case "eq":
    case "ne":
    case "gt":
    case "lt":
    case "range_gte":
    case "gte":
    case "lte": {
      const value = transformation(fields.value)
      return value ? { ...fields, value } : undefined
    }
    case "range_within":
    case "between": {
      const minValue = transformation(fields.minValue)
      const maxValue = transformation(fields.maxValue)
      return minValue && maxValue ? { ...fields, minValue, maxValue } : undefined
    }
    case "any":
    case "arr_contains": {
      const values = fields.values.map(transformation).filter(isPresent)
      return values.length > 0 || fields.includeNull !== undefined ? { ...fields, values } : undefined
    }
    case "exists":
      return fields
    default:
      unreachable(fields)
  }
}

export function formatFilterFromSchema(
  filter: Filter<string>,
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined,
  intl: IntlShape
): Filter<string> | undefined {
  let newFields: FilterField | undefined

  // All filter types are able to use the exists comparator
  if (filter.fields.comparator === "exists") {
    return filter
  }

  if (!isObject(fieldSchema)) {
    log.warn(`Unexpected field schema for filter ${filter.column}`)
    return undefined
  }

  const schemaWithValue = { schema: fieldSchema }
  if (isEnumType(schemaWithValue) || isEnumArrayType(schemaWithValue) || isWorkflowRunProgressType(schemaWithValue)) {
    return filter
  }
  if (fieldSchema.type === "boolean") {
    if (filter.fields.comparator === "any") {
      return filter
    }
  } else if (fieldSchema.type === "string") {
    if (fieldSchema.format === "date" || fieldSchema.format === "date-time" || fieldSchema.format === "duration") {
      if (
        filter.fields.comparator === "between" ||
        filter.fields.comparator === "gte" ||
        filter.fields.comparator === "lte"
      ) {
        return filter
      }

      if (filter.fields.comparator === "eq") {
        // Only date filters that check for eq today are relative dates
        const options = getDateFilterOptions(fieldSchema, intl)
        if (!Object.hasOwn(options, filter.fields.value)) {
          return undefined
        }
        newFields = options[filter.fields.value as keyof typeof options].fields
      }
    } else if (
      (filter.fields.comparator === "starts_with" || filter.fields.comparator === "contains") &&
      filter.fields.value.length > 0
    ) {
      return filter
    }
  } else if (isFrequencyOrOneTimeType(fieldSchema)) {
    if (
      filter.fields.comparator === "between" ||
      filter.fields.comparator === "gte" ||
      filter.fields.comparator === "lte" ||
      filter.fields.comparator === "eq"
    ) {
      return filter
    }
  } else if (isRichTextType(fieldSchema)) {
    if (filter.fields.comparator === "contains") {
      return filter
    }
  } else if (isStdObjSchema(fieldSchema) || isStdObjSchema(fieldSchema.items)) {
    newFields = transformFilterFields(filter.fields, expandUUID)
  } else if (isGrowthRateType(fieldSchema)) {
    newFields = transformFilterFields(filter.fields, toPercentRatioString)
  } else if (
    // This handles both filters on `currency_amount.amount` and `range_within` filters on the whole currency range (?)
    isCurrencyAmountType(fieldSchema) ||
    isSpendSummaryType(fieldSchema) ||
    isCurrencyRangeType(fieldSchema) ||
    isNumericStringType(fieldSchema) ||
    fieldSchema.type === "number"
  ) {
    newFields = transformFilterFields(filter.fields, toNumberString)
  } else if (fieldSchema.type === "integer") {
    newFields = transformFilterFields(filter.fields, toInteger)
  } else {
    log.warn("Unexpected field schema for formatting filter for backend", { fieldSchema, filter })
  }
  return newFields ? { ...filter, fields: newFields } : undefined
}

/**
 * Prevents filters that do not make sense from making it to the api. For example,
 * if a filter is an integer and the value is not an integer, it will be removed.
 *
 * Also, if a filter is valid but empty, it will be removed.
 *
 * Also formats fields like money to be from 2.34 => 234
 *
 * Also formats option values that are short UUIDs to long UUIDs accepted by the API.
 */
export function onlyCompleteFormattedFilters<TPropertyPath extends string>(
  filters: Filter<TPropertyPath>[],
  objectSchema: ReadonlyDeep<JSONSchema>,
  intl: IntlShape
): Filter<TPropertyPath>[][] {
  return (
    filters
      .map((filter) => {
        const fieldSchema = getSchemaAtPath(objectSchema, displayPathFromFilterPath(filter.column.split(".")))
        return formatFilterFromSchema(filter, fieldSchema, intl) as Filter<TPropertyPath>
      })
      .filter(isPresent)
      // For now, we only support ANDing filters together, so we wrap each filter in its own array
      .map((filter) => [filter])
  )
}

export function toInteger(value: string): string | undefined {
  if (!value) {
    return undefined
  }

  try {
    return BigInt(value).toString()
  } catch {
    return undefined
  }
}

/**
 * The user types in a percentage string like 60.5, and we want to convert it to a ratio like 0.605 for the backend
 */
export function toPercentRatioString(value: string): string | undefined {
  if (!value) {
    return undefined
  }

  try {
    return Big(value).div(100).toString()
  } catch {
    return undefined
  }
}

export function toNumberString(value: string): string | undefined {
  if (!value) {
    return undefined
  }

  try {
    return Big(value).toString()
  } catch {
    return undefined
  }
}

export interface SavedViewState {
  /** On saved view create, the savedViewId may be defined without a savedView when the query is still fetching */
  id?: string
  /** If the table's columns have changed from the saved view's columns this is populated. Null will clear out the saved view db value but undefined will not */
  newColumnParams?: readonly string[] | null
  /** If the table's filters have changed from the saved view's filters this is populated. Null will clear out the saved view db value but undefined will not */
  newFilterParams?: Record<string, string> | null
  /** If the table's sort has changed from the saved view's sort this is populated. Null will clear out the saved view db value but undefined will not */
  newSortParams?: { sort?: string; sort_desc?: string } | null
  /** The id could be invalid, so even if it is populated there may be no view */
  view?: SavedView
}

export interface TableParamsState<TPropertyPath extends string> {
  page: number
  pageSize: number

  /**
   * The sorting to apply. These are applied in order.
   */
  sorting: SortingState
  sortingColumns?: readonly TPropertyPath[]

  /**
   * The filters to apply by filter path. These are ANDed together.
   *
   * Note that the filter path does not necessarily need to be identical to the display path rendered in the cell,
   * for example the object filter will filter by the `id` property but the cell displays the whole object.
   */
  filterMap: ReadonlyMap<TPropertyPath, Filter<TPropertyPath>>

  /** If a table has a primary search filter, it is not saved in the view and set separately. Null is used when a the primary filter needs to be cleared */
  primaryFilter?: Filter<TPropertyPath, ContainsFilter> | null

  /** The display paths the user has selected to view, with the specified ordering */
  selectedColumns?: readonly TPropertyPath[]

  /** If the user has a saved view id in the URL. Also includes the object if they have access to it. Is one variable to simplify updateTableParams */
  savedViewState: SavedViewState
}

export function shownColumnsForTableParamState<TDisplayPath extends string>(
  tableParams: TableParamsState<TDisplayPath>,
  defaultPropertyPaths: readonly TDisplayPath[]
): readonly TDisplayPath[] {
  return Array.from(
    new Set([
      ...(tableParams.selectedColumns?.length ? tableParams.selectedColumns : defaultPropertyPaths),
      ...(tableParams.sortingColumns?.length ? tableParams.sortingColumns : []),
      ...Array.from(
        tableParams.filterMap.keys(),
        (filterPath) => displayPathFromFilterPath(filterPath.split(".").filter(Boolean)).join(".") as TDisplayPath
      ),
    ])
  )
}

/**
 * The comparators that get omitted from the filter key on serialization if they are the default comparator for the
 * column. We omit `any` and `arr_contains` as they use `=` semantics, so they are intuitive when read as
 * `column=value` or `column=value1*value2` in the URL. We don't omit other comparators like `gte`, `lt`,
 * `contains`, or `starts_with` because they would be confusing when reading `column=value` while not using `=`
 * semantics.
 */
const comparatorsWithEqualsSemantic = new Set<FilterField["comparator"]>(["eq", "any", "arr_contains"])

/**
 * This is a helper function to serialize the table params into a query string.
 * Returned string includes the '?' prefix.
 */
export function serializeTableQueryParams<TPropertyPath extends string>(
  tableParams: TableParamsState<TPropertyPath>,
  objectSchema: ReadonlyDeep<JSONSchemaObject>
): URLSearchParams {
  const { page, pageSize, sorting, filterMap } = tableParams
  const searchParams = new URLSearchParams()

  // Use the id instead of the view's id, so if the query is still fetching the view we don't lose the saved view id
  if (tableParams.savedViewState.id) {
    searchParams.set("view", shortenUUID(tableParams.savedViewState.id))
  }

  if (tableParams.savedViewState.newColumnParams === undefined && tableParams.savedViewState.id) {
    searchParams.set("cols", "view")
  } else if (tableParams.savedViewState.newColumnParams) {
    // Note: Because `[].join("*")` and `[""].join("*")` both return "", we cannot encode the root property path ("") in the `cols` param.
    // This means that the root property path can be a `primaryColumn` (handled separately), but it can never be a customizable column.
    searchParams.set("cols", tableParams.savedViewState.newColumnParams.join(FILTER_VALUE_DELIMITER))
  }

  searchParams.set("page", page.toString())
  searchParams.set("page_size", pageSize.toString())

  // when deserializing, if this is on, it will load the table with the filter parameters saved to that view
  if (tableParams.savedViewState.newFilterParams === undefined && tableParams.savedViewState.id) {
    searchParams.set("filters", "view")
  } else {
    for (const [key, values] of serializedUrlValuesForFilterMap(filterMap, objectSchema).entries()) {
      // Only serialize filter if it has a value
      if (values.length > 0) {
        searchParams.append(key, values.join(FILTER_VALUE_DELIMITER))
      }
    }
  }

  if (tableParams.primaryFilter) {
    const primaryKey = keyForFilterField(tableParams.primaryFilter, objectSchema)
    const primaryValues = valuesForFilterField(tableParams.primaryFilter.fields)
    searchParams.append(primaryKey, primaryValues.join(FILTER_VALUE_DELIMITER))
  }

  if (tableParams.savedViewState.newSortParams === undefined && tableParams.savedViewState.id) {
    searchParams.set("sort", "view")
  } else {
    for (const { id, desc } of sorting) {
      searchParams.append(desc ? "sort_desc" : "sort", id)
    }
  }

  return searchParams
}

export function serializedUrlValuesForFilterMap<TPropertyPath extends string>(
  filterMap: ReadonlyMap<TPropertyPath, Filter<TPropertyPath>>,
  objectSchema: ReadonlyDeep<JSONSchemaObject>
): Map<string, string[]> {
  const serializedUrlValues = new Map<string, string[]>()
  for (const filter of filterMap.values()) {
    const key = keyForFilterField(filter, objectSchema)
    const values = valuesForFilterField(filter.fields)
    serializedUrlValues.set(key, values)
  }
  return serializedUrlValues
}

export function keyForFilterField<TPropertyPath extends string>(
  filter: Filter<TPropertyPath>,
  objectSchema: ReadonlyDeep<JSONSchemaObject>
): string {
  // Omit comparator if it's the default comparator and it has `=` semantics
  const schema = getSchemaAtPath(objectSchema, displayPathFromFilterPath(filter.column.split(".")))
  return filter.fields.comparator === getDefaultComparatorFromSchema(schema) &&
    comparatorsWithEqualsSemantic.has(filter.fields.comparator)
    ? filter.column
    : `${filter.column}_${filter.fields.comparator}`
}

/** Helper for saving the filters to the backend for saved views */
export function savedViewFilterParams<TPropertyPath extends string>(
  filterMap: ReadonlyMap<TPropertyPath, Filter<TPropertyPath>>,
  objectSchema: ReadonlyDeep<JSONSchemaObject>
): Record<string, string> | null {
  const map = serializedUrlValuesForFilterMap(filterMap, objectSchema)
  if (map.size === 0) {
    return null
  }
  return Object.fromEntries(Array.from(map, ([key, values]) => [key, values.join(FILTER_VALUE_DELIMITER)]))
}

export function savedViewSortingParams(sorting: SortingState): { sort?: string; sort_desc?: string } | null {
  if (!sorting[0]) {
    return null
  }
  return sorting[0].desc ? { sort_desc: sorting[0].id } : { sort: sorting[0].id }
}

export function deserializeTableQueryParams<TPropertyPath extends string>(
  searchParams: URLSearchParams,
  savedViewState: SavedViewState,
  objectSchema: ReadonlyDeep<JSONSchemaObject> | undefined,
  primarySearchColumn?: TPropertyPath
): TableParamsState<TPropertyPath> {
  const page = Number(searchParams.get("page")) || TABLE_INITIAL_PAGE
  const pageSize = Number(searchParams.get("page_size")) || TABLE_PAGE_SIZE_DEFAULT

  const savedView = savedViewState.view

  // If this is set to view, the selected columns will be set from the saved value in the view
  let selectedColumns: TPropertyPath[] | undefined
  if (searchParams.get("cols") === "view") {
    selectedColumns = (savedView?.column_params ?? undefined) as TPropertyPath[] | undefined
  } else {
    const colsParam = searchParams.get("cols")
    // `.filter(Boolean)` filters out the empty string that would come from splitting an empty string.
    // This means that it would also filter out the root property path, but the root property path can only be a primary column, never as a customizable column.
    selectedColumns = colsParam?.split(FILTER_VALUE_DELIMITER).filter(Boolean) as TPropertyPath[] | undefined
    savedViewState.newColumnParams =
      colsParam === null ? undefined : colsParam === "" ? null : colsParam.split(FILTER_VALUE_DELIMITER)
  }
  const sorting: SortingState = []
  const filtersFromUrl = deserializeFilters(searchParams, objectSchema, primarySearchColumn)
  const { primaryFilter } = filtersFromUrl
  let { filterMap } = filtersFromUrl

  // If this is set to view, the filter maps will be set from the saved value in the view
  const useViewFilters = searchParams.get("filters") === "view"
  if (savedView && useViewFilters) {
    filterMap = deserializeFilters(
      new URLSearchParams(savedView.filter_params ?? ""),
      objectSchema,
      primarySearchColumn
    ).filterMap
  }

  if (!useViewFilters) {
    const secondaryFilterParams = Array.from(searchParams).filter(
      ([key]) => isFilterKey(key) && (!primarySearchColumn || !key.startsWith(primarySearchColumn))
    )
    savedViewState.newFilterParams = secondaryFilterParams.length > 0 ? Object.fromEntries(secondaryFilterParams) : null
  }

  // If this is set to view, the sorting will be set from the saved value in the view
  const useViewSort = searchParams.get("sort") === "view"
  const sortParams = savedView && useViewSort ? new URLSearchParams(savedView.sort_params ?? "") : searchParams
  const sortUrlSection = new URLSearchParams()
  const sortingColumns: TPropertyPath[] = []
  for (const [key, value] of sortParams) {
    if ((key === "sort" || key === "sort_desc") && value !== "view") {
      const schema = getSchemaAtPath(objectSchema, value)
      if (!schema) {
        log.warn("Unknown sorting column", { path: value, objectSchema })
        continue
      }

      const column = value as TPropertyPath
      sortingColumns.push(column)
      sorting.push({ id: value, desc: key === "sort_desc" })
      if (!useViewSort) {
        sortUrlSection.append(key, value)
      }
    }
  }

  if (!useViewSort) {
    savedViewState.newSortParams = sortUrlSection.size ? Object.fromEntries(sortUrlSection) : null
  }

  return {
    page,
    pageSize,
    sorting,
    sortingColumns,
    filterMap,
    savedViewState,
    selectedColumns,
    primaryFilter,
  }
}

function getDefaultComparatorFromSchema(schema: ReadonlyDeep<JSONSchema> | undefined): FilterField["comparator"] {
  if (!isObject(schema)) {
    throw new UnexpectedSchemaError("Invalid schema, cannot determine default comparator", schema)
  }
  const schemaWithValue = { schema }
  if (isEnumType(schemaWithValue) || isWorkflowRunProgressType(schemaWithValue)) {
    return "any"
  }
  if (isEnumArrayType(schemaWithValue)) {
    return "arr_contains"
  }
  if (isStdObjSchema(schema)) {
    return "any"
  }
  if (schema.type === "array" && isStdObjSchema(schema.items)) {
    return "arr_contains"
  }
  if (
    schema.type === "integer" ||
    isCurrencyAmountType(schema) ||
    isSpendSummaryType(schema) ||
    isGrowthRateType(schema)
  ) {
    return "between"
  }
  if (isCurrencyRangeType(schema)) {
    return "range_within"
  }
  if (schema.type === "boolean") {
    return "any"
  }
  if (isRichTextType(schema)) {
    return "contains"
  }
  if (isDocumentOrURLStringType(schema) || isDocumentType(schema)) {
    return "exists"
  }
  if (isFrequencyOrOneTimeType(schema)) {
    return "eq"
  }
  if (schema.type === "string") {
    if (schema.format === "date" || schema.format === "duration") {
      return "eq"
    }
    return "contains"
  }

  // TODO exists?
  throw new UnexpectedSchemaError("Unknown schema, cannot determine default comparator", schema)
}

// Filter keys are the of the pattern `${column}_${comparator}`.
// The comparator is optional and defaults to the default comparator for the property schema.
const comparators = Object.keys(FilterFieldSchema.discriminator.mapping).join("|")
const filterKeyRegExp = new RegExp(`^(?<column>[\\w_\\.\\*]*?)(?:_(?<comparator>${comparators}))?$`)

/**
 * Checks if the given search param key is for a filter condition (any key that is not used for another purpose).
 */
const isFilterKey = (key: string) =>
  key !== "sort" &&
  key !== "sort_desc" &&
  key !== "cols" &&
  key !== "view" &&
  key !== "page" &&
  key !== "page_size" &&
  key !== "filters"

function deserializeFilters<TPropertyPath extends string>(
  searchParams: URLSearchParams,
  objectSchema: ReadonlyDeep<JSONSchemaObject> | undefined,
  primarySearchColumn?: TPropertyPath
): {
  filterMap: Map<TPropertyPath, Filter<TPropertyPath>>
  primaryFilter?: Filter<TPropertyPath, ContainsFilter>
} {
  const filterMap = new Map<TPropertyPath, Filter<TPropertyPath>>()

  let primaryFilter: Filter<TPropertyPath, ContainsFilter> | undefined = undefined
  for (const [key, value] of searchParams) {
    if (!isFilterKey(key)) {
      continue
    }

    const filterMatch = key.match(filterKeyRegExp)
    if (!filterMatch?.groups) {
      continue
    }

    let { column, comparator } = filterMatch.groups as {
      column: TPropertyPath
      comparator?: FilterField["comparator"]
    }
    if (!comparator) {
      const schema = getSchemaAtPath(objectSchema!, displayPathFromFilterPath(column.split(".")))
      if (!schema) {
        // Likely a non-filter search param
        log.warn("No schema found for filter column", { column, objectSchema })
        continue
      }
      comparator = getDefaultComparatorFromSchema(schema)
    }

    const fields = convertFilterValueStringToFilterField(value, comparator)
    if (!fields) {
      continue
    }

    if (column === primarySearchColumn && fields.comparator === "contains") {
      primaryFilter = { column, fields }
      continue
    }

    filterMap.set(column, { column, fields })
  }

  return { filterMap, primaryFilter }
}

/**
 * Converts the filter value string that is saved in the url and the given comparator to a FilterFields object.
 */
function convertFilterValueStringToFilterField(
  filterValue: string,
  comparator: FilterField["comparator"]
): FilterField | undefined {
  switch (comparator) {
    case "starts_with":
    case "contains":
    case "eq":
    case "ne":
    case "gt":
    case "lt":
    case "range_gte":
    case "gte":
    case "lte": {
      return { comparator, value: filterValue }
    }
    case "range_within":
    case "between": {
      const [minValue, maxValue] = filterValue.split(FILTER_VALUE_DELIMITER).filter(Boolean)
      return minValue && maxValue ? { comparator, minValue, maxValue } : undefined
    }
    case "any":
    case "arr_contains": {
      const values = new Set<string>()
      let includeNull = false
      filterValue.split(FILTER_VALUE_DELIMITER).forEach((value) => {
        if (value === NULL_URL_VALUE) {
          includeNull = true
        } else if (value) {
          values.add(value)
        }
      })
      return { comparator, values: Array.from(values), includeNull }
    }
    case "exists":
      return { comparator, value: filterValue === "true" }
    default:
      unreachable(comparator)
  }
}

export function packageSortFilterOptionsForAPI<TPropertyPath extends string = string>(
  tableParams: TableParamsState<TPropertyPath>,
  objectSchema: ReadonlyDeep<JSONSchemaObject>,
  intl: IntlShape
) {
  const { page, pageSize, sorting, filterMap } = tableParams
  const by = sorting?.[0]?.id as TPropertyPath
  const sortDirection: SortDirection = sorting?.[0]?.desc ? "DESC" : "ASC"

  const sortParams = by !== undefined ? { by, direction: sortDirection } : undefined
  const filters = Array.from(filterMap.values())
  if (tableParams.primaryFilter) {
    filters.push(tableParams.primaryFilter)
  }
  const filterParams = onlyCompleteFormattedFilters<TPropertyPath>(filters, objectSchema, intl)

  return {
    sort: sortParams,
    filter: filterParams,
    // Pages are all 1-indexed in the UI, but we need 0 indexing to calculate the offset
    offset: (page - 1) * pageSize,
    limit: pageSize,
  }
}

/**
 * Extracts the saved view state from serialized table params.
 */
export function readSavedViewState(searchParams: URLSearchParams, savedViews: SavedView[]): SavedViewState {
  const shortenedViewId = searchParams.get("view")
  const viewId = shortenedViewId && expandUUID(shortenedViewId)

  const savedView =
    (viewId && savedViews.find((savedView) => savedView.id === viewId)) || savedViews.find((view) => view.is_default)

  return { view: savedView, id: savedView?.id }
}
