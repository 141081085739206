import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseModalProps,
} from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "./Link.js"

const contactEmail = "buy@brm.ai"

export const TrialExpiredModal: FunctionComponent<UseModalProps> = (props) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Buy to keep using BRM"
            description="Trial expired modal"
            id="TrialExpiredModal.title"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text>
            <FormattedMessage
              id="TrialExpiredModal.description"
              defaultMessage="Your automagical free trial has expired! To keep on saving money and time email {emailAddress}."
              description="Trial expired modal"
              values={{
                emailAddress: (
                  <Link variant="highlighted" to={`mailto:${contactEmail}`}>
                    {contactEmail}
                  </Link>
                ),
              }}
            />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" as={Link} to={`mailto:${contactEmail}`}>
            <FormattedMessage
              id="TrialExpiredModal.contactUs"
              defaultMessage="Contact BRM"
              description="Trial expired modal contact us button"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
