import { Badge, Button } from "@chakra-ui/react"

export default function SuggestedPromptBadge({ prompt, onClick }: { prompt: string; onClick: () => void }) {
  return (
    <Badge
      width="auto"
      as={Button}
      size="sm"
      colorScheme="gray"
      height="auto"
      borderRadius="2xl"
      variant="subtleOutlined"
      onClick={onClick}
      fontWeight="medium"
      fontSize="sm"
      borderColor="gray.200"
    >
      {prompt}
    </Badge>
  )
}
