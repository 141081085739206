import { Box, Divider, HStack, Stack, Switch, Text, useToast } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetOrganizationV1CurrentOktaConfigQuery,
  useGetOrganizationV1CurrentQuery,
  usePutOrganizationV1UpdateMutation,
} from "../../app/services/generated-api.js"
import { SHORT_TOAST_DURATION } from "../../util/constant.js"
import OktaSettings from "./OktaSettings.js"
import { SettingsHeader } from "./SettingsHeader.js"

export default function SecuritySettings() {
  const toast = useToast()
  const intl = useIntl()
  const { data: organization } = useGetOrganizationV1CurrentQuery()
  const { data: organizationOktaConfig } = useGetOrganizationV1CurrentOktaConfigQuery()
  const [updateOrganization] = usePutOrganizationV1UpdateMutation()

  const successToast = () => {
    toast({
      description: intl.formatMessage({
        id: "organization.settings-change.success",
        description: "Toast success message when saving org settings",
        defaultMessage: "Saved",
      }),
      status: "success",
      duration: SHORT_TOAST_DURATION,
    })
  }

  const errorToast = () => {
    toast({
      description: intl.formatMessage({
        id: "organization.settings-change.failure",
        description: "Toast failure message when saving org settings",
        defaultMessage: "Something went wrong while saving your settings",
      }),
      status: "error",
    })
  }

  if (!organization) {
    return null
  }

  return (
    <>
      <SettingsHeader>
        <FormattedMessage
          defaultMessage="Security"
          description="The heading for the security settings page"
          id="settings.security.heading"
        />
      </SettingsHeader>
      <Stack spacing={2} maxW="960px">
        <HStack justify="space-between">
          <Stack spacing="0.5" fontSize="md">
            <Text fontWeight="medium">
              <FormattedMessage
                id="organization.view.settings.sso-only.title"
                description="Require Users to Login with SSO"
                defaultMessage="Require Users to Login with SSO"
              />
            </Text>
            <Text color="muted">
              <FormattedMessage
                id="organization.view.settings.sso-only.description"
                description="Require all users to log in with SSO, long description."
                defaultMessage="Ensure users in this organization must log in to BRM with single sign-on (SSO) by disabling direct logins"
              />
            </Text>
          </Stack>
          <Switch
            defaultChecked={organization.sso_only}
            onChange={async (e) => {
              try {
                await updateOrganization({
                  updateOrganization: {
                    id: organization.id,
                    sso_only: e.target.checked,
                  },
                }).unwrap()
                successToast()
              } catch (_) {
                errorToast()
              }
            }}
          />
        </HStack>
        <Divider />
        <HStack justify="space-between">
          <Stack spacing="0.5" fontSize="md">
            <Text fontWeight="medium">
              <FormattedMessage
                id="organization.view.settings.open-sign-up.title"
                description="Allow users from the same domain to join BRM"
                defaultMessage="Allow co-workers to join BRM"
              />
            </Text>
            <Text color="muted">
              <FormattedMessage
                id="organization.view.settings.open-sign-up.description"
                description="Allow users from the same domain to join BRM, long description."
                defaultMessage="Allow users with a @{domain} email to join you on BRM"
                values={{
                  domain: organization.email_domain,
                }}
              />
            </Text>
          </Stack>
          <Switch
            defaultChecked={organization.allow_signups}
            onChange={async (e) => {
              try {
                await updateOrganization({
                  updateOrganization: {
                    id: organization.id,
                    allow_signups: e.target.checked,
                  },
                }).unwrap()
                successToast()
              } catch (_) {
                errorToast()
              }
            }}
          />
        </HStack>
        {organizationOktaConfig && (
          <Box mt={4}>
            <OktaSettings organizationId={organization.id} oktaSettings={organizationOktaConfig} />
          </Box>
        )}
      </Stack>
    </>
  )
}
