import type { CustomizableObjectType, FieldConfig, FieldConfigMinimal } from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { dereferenceSchema, getTitle } from "@brm/util/schema.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Switch,
  Tooltip,
  useDisclosure,
  useToast,
  type UseDisclosureReturn,
} from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useCallback, useEffect, useId, useMemo, useRef, useState, type FunctionComponent } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import invariant from "tiny-invariant"
import type { ReadonlyDeep } from "type-fest"
import {
  useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
} from "../../../app/services/generated-api.js"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { CriterionConfigForm } from "./CriterionConfigForm.js"
import { CriterionProvenance } from "./CriterionProvenance.js"

export const ObjectCriteria: FunctionComponent<{
  objectType: CustomizableObjectType
  fields: FieldConfig[]
  defs: Record<string, ReadonlyDeep<JSONSchema>>
}> = ({ objectType, fields, defs }) => {
  const location = useLocation()
  const [expandedIndex, setExpandedIndex] = useState<number[]>([])

  useEffect(() => {
    setExpandedIndex([])
  }, [objectType])

  // Open a criterion if the field name is in the hash
  useEffect(() => {
    const fieldName = location.hash.slice(1)
    if (fieldName) {
      const fieldIndex = fields.findIndex((field) => field.field_name === fieldName)
      setExpandedIndex((indexes) => Array.from(new Set([...indexes, fieldIndex])))
      setTimeout(() => {
        document.getElementById(fieldName)?.scrollIntoView({ behavior: "smooth" })
      }, 100)
    }
  }, [fields, location.hash])

  return (
    <Accordion
      variant="pills"
      allowMultiple
      index={expandedIndex}
      onChange={(index) => {
        invariant(Array.isArray(index), "Expected expanded array to be index")
        setExpandedIndex(index)
      }}
    >
      {fields.map((criterion) => (
        <CriterionAccordionItem
          key={`${criterion.field_name}:${criterion.is_custom}`}
          objectType={objectType}
          fieldConfig={criterion}
          defs={defs}
        />
      ))}
    </Accordion>
  )
}

const CriterionAccordionItem: FunctionComponent<{
  objectType: CustomizableObjectType
  fieldConfig: FieldConfig
  defs: Record<string, ReadonlyDeep<JSONSchema>>
}> = ({ objectType, fieldConfig, defs }) => {
  const intl = useIntl()
  const toast = useToast()

  const dereferencedConfig = useMemo(() => {
    const schema = dereferenceSchema(fieldConfig.field_schema as JSONSchemaObject, defs) as JSONSchemaObject
    return { ...fieldConfig, field_schema: schema }
  }, [defs, fieldConfig])

  const title = getTitle(fieldConfig.field_name, dereferencedConfig.field_schema)
  const description = dereferencedConfig.field_schema.uiDescription ?? dereferencedConfig.field_schema.description

  const [upsertFieldConfig, upsertFieldConfigStatus] = usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation()
  const [deleteCustomField, deleteCustomFieldStatus] = useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation()

  const deleteConfirmationDialog = useDisclosure()

  const formId = `criterion-form-${useId()}`

  const [isEnabled, setIsEnabled] = useState(fieldConfig.is_enabled)
  useEffect(() => {
    setIsEnabled(fieldConfig.is_enabled)
  }, [fieldConfig.is_enabled])

  const onValidSubmit = useCallback(
    async (fieldConfigInput: FieldConfigMinimal): Promise<void> => {
      try {
        await upsertFieldConfig({
          objectType,
          fieldName: fieldConfigInput.field_name,
          fieldConfigInput,
        }).unwrap()
        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Updated criterion"
              description="Updated criterion toast message"
              id="settings.criteria.object.criterionUpdated"
            />
          ),
        })
      } catch (err) {
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred updating the criterion"
              description="Error message when updating a criterion fails"
              id="settings.criteria.object.updateCriterionFailed"
            />
          ),
        })
      }
    },
    [objectType, toast, upsertFieldConfig]
  )

  const categorySchema = FieldCategorySchema.anyOf.find((category) => category.const === fieldConfig.category)

  const configurable = dereferencedConfig.field_schema.configurable !== false

  return (
    <AccordionItem minW={0} display="flex" flexDirection="column">
      <AccordionButton textAlign="left" display="flex" gap={3} minW={0}>
        <AccordionIcon />
        <Flex flexDir="column" gap={0.5} minW={0}>
          <Flex alignItems="center" gap={1} minW={0}>
            <Heading size="xxs" isTruncated mr={1} id={fieldConfig.field_name}>
              {title}
            </Heading>
            {fieldConfig.category && (
              <Badge colorScheme={categorySchema?.colorScheme} variant="subtleOutlined">
                {getTitle(fieldConfig.category, categorySchema)}
              </Badge>
            )}
            {fieldConfig.is_custom && (
              <Tooltip
                label={
                  <Box textAlign="center">
                    <CriterionProvenance fieldConfig={fieldConfig} />
                  </Box>
                }
              >
                <Badge colorScheme="blue" variant="subtleOutlined">
                  <FormattedMessage
                    defaultMessage="Custom"
                    description="Badge for custom criterion"
                    id="settings.criteria.badge.custom"
                  />
                </Badge>
              </Tooltip>
            )}
          </Flex>
          <Box noOfLines={1} color="gray.600" fontSize="sm">
            {description && <RenderedMarkdown content={description} />}
          </Box>
        </Flex>
        {configurable && (
          <HStack ml="auto" onClick={(event) => event.stopPropagation()}>
            <Switch
              isChecked={isEnabled}
              isDisabled={upsertFieldConfigStatus.isLoading}
              name="enabled"
              aria-label={
                isEnabled
                  ? intl.formatMessage({
                      defaultMessage: "Disable criterion",
                      description: "Accessibility label for the disable criterion button",
                      id: "settings.criteria.object.disableCriterion",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Enable criterion",
                      description: "Accessibility label for the enable criterion button",
                      id: "settings.criteria.object.enableCriterion",
                    })
              }
              onChange={async (event) => {
                try {
                  setIsEnabled(event.target.checked)
                  await upsertFieldConfig({
                    objectType,
                    fieldName: fieldConfig.field_name,
                    fieldConfigInput: {
                      ...fieldConfig,
                      is_enabled: event.target.checked,
                    },
                  }).unwrap()
                  toast({
                    description: (
                      <FormattedMessage
                        defaultMessage="Updated criterion"
                        description="Updated criterion toast message"
                        id="settings.criteria.object.criterionUpdated"
                      />
                    ),
                    status: "success",
                  })
                } catch (err) {
                  setIsEnabled(fieldConfig.is_enabled)
                  toast({
                    description: getAPIErrorMessage(err) ?? (
                      <FormattedMessage
                        defaultMessage="Error updating criterion"
                        description="Error message when updating a criterion fails"
                        id="settings.criteria.object.updateCriterionFailed"
                      />
                    ),
                    status: "error",
                  })
                }
              }}
            />
          </HStack>
        )}
      </AccordionButton>

      <AccordionPanel display="flex" flexDir="column" gap={4}>
        <ErrorBoundary fallbackRender={({ error }) => `Error: ${error}`}>
          <CriterionConfigForm fieldConfig={dereferencedConfig} onValidSubmit={onValidSubmit} id={formId} />
        </ErrorBoundary>
        <Divider />
        <HStack>
          <Box color="gray.600" fontSize="sm" mr="auto">
            <CriterionProvenance fieldConfig={fieldConfig} />
          </Box>
          {dereferencedConfig.field_schema.configurable !== false && (
            <>
              {dereferencedConfig.is_custom && (
                <Button
                  colorScheme="error"
                  variant="subtleOutlined"
                  size="sm"
                  onClick={deleteConfirmationDialog.onOpen}
                >
                  <FormattedMessage
                    defaultMessage="Delete"
                    description="Button label for deleting a criterion"
                    id="settings.criteria.object.deleteCriterion"
                  />
                </Button>
              )}
              <Button
                colorScheme="brand"
                type="submit"
                size="sm"
                form={formId}
                isLoading={upsertFieldConfigStatus.isLoading}
              >
                <FormattedMessage
                  defaultMessage="Save"
                  description="Button label for saving a criterion"
                  id="settings.criteria.object.saveCriterion"
                />
              </Button>
            </>
          )}
        </HStack>
        {deleteConfirmationDialog.isOpen && (
          <CriterionDeleteConfirmationDialog
            {...deleteConfirmationDialog}
            title={title}
            onDelete={async () => {
              await deleteCustomField({
                fieldName: dereferencedConfig.field_name,
                objectType: objectType as CustomizableObjectType,
              }).unwrap()
              deleteConfirmationDialog.onClose()
            }}
            isDeleting={deleteCustomFieldStatus.isLoading}
          />
        )}
      </AccordionPanel>
    </AccordionItem>
  )
}

const CriterionDeleteConfirmationDialog: FunctionComponent<
  UseDisclosureReturn & { title: string; onDelete: () => void; isDeleting: boolean }
> = ({ title, onDelete, isDeleting, ...props }) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog leastDestructiveRef={cancelRef} {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <FormattedMessage
              defaultMessage="Delete criterion “{title}”"
              description="Title for the delete criterion confirmation dialog"
              id="settings.criteria.object.deleteCriterionTitle"
              values={{ title }}
            />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage
              defaultMessage="Are you sure you want to delete this criterion? All data stored for this criterion in your BRM will be deleted. This action cannot be undone."
              description="Confirmation message for deleting a criterion"
              id="settings.criteria.object.deleteCriterionConfirmation"
            />
          </AlertDialogBody>
          <AlertDialogFooter gap={1}>
            <Button ref={cancelRef} onClick={props.onClose} isDisabled={isDeleting}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Cancel button label for delete criterion confirmation dialog"
                id="settings.criteria.object.deleteCriterionCancel"
              />
            </Button>
            <Button colorScheme="error" onClick={onDelete} isLoading={isDeleting}>
              <FormattedMessage
                defaultMessage="Delete"
                description="Delete button label for delete criterion confirmation dialog"
                id="settings.criteria.object.deleteCriterionConfirm"
              />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
