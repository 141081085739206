import type { IntegrationSummary } from "@brm/schema-types/types.js"
import { formatDateRange, formatDateTime } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  type CardProps,
} from "@chakra-ui/react"
import { useRef, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1EmailDelegatesQuery,
  useGetUserV1WhoamiQuery,
  usePostUserV1EmailDelegatesMutation,
} from "../../app/services/generated-api.js"
import ObjectSelectorFilter from "../../components/DataTable/SchemaFilter/FilterTypes/ObjectSelectorFilter.js"
import { Link } from "../../components/Link.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { CheckIcon, HelpIcon, MoreMenuIcon, PlusIcon, SearchIcon, UserIcon } from "../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import CreateIntegrationModal from "./CreateIntegrationModal.js"
import { DeleteIntegrationConfirmationModal } from "./DeleteIntegrationConfirmationModal.js"
import type { IntegrationConnectOption } from "./constants.js"

/**
 * This component handles the connection of a single IntegrationConnectOption but also can take in an existing integration if it is already connected.
 * If the integration is already connected, the user is able to disconnect it.
 *
 * Will eventually replace ConnectIntegration.
 */
export const ConnectGmailCard: FunctionComponent<
  {
    integrationConnectOption: IntegrationConnectOption
    connectedIntegration?: IntegrationSummary
  } & CardProps
> = ({ integrationConnectOption, connectedIntegration, ...cardProps }) => {
  const intl = useIntl()

  const { data: currentDelegates } = useGetUserV1EmailDelegatesQuery()
  const currentDelegateIds = new Set(currentDelegates?.map((d) => d.id) ?? [])

  const searchInputRef = useRef<HTMLInputElement>(null)
  const connectModal = useDisclosure()
  const reconnectModal = useDisclosure()

  const disconnectModal = useDisclosure()
  return (
    <>
      <Card variant="outline" padding={0} {...cardProps}>
        <CardBody>
          <Stack>
            <HStack justify="space-between">
              <HStack>
                <IntegrationProviderIcon
                  integration={{
                    provider: integrationConnectOption.provider,
                    merge_hris_type: integrationConnectOption.mergeHrisSource,
                    merge_accounting_type: integrationConnectOption.mergeAccountingSource,
                  }}
                  boxSize={6}
                />
                <Text fontWeight="bold" fontSize="md">
                  {integrationConnectOption.name}
                </Text>
                {connectedIntegration && (
                  <>
                    <Popover isLazy initialFocusRef={searchInputRef} placement="bottom-start">
                      <PopoverTrigger>
                        <Button variant="ghost" fontSize="sm" leftIcon={<Icon as={PlusIcon} />}>
                          <FormattedMessage
                            id="integrations.page.connect.addDelegates"
                            defaultMessage="Add delegates"
                            description="Label for button to add delegates to integration"
                          />
                        </Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <ConnectGmailDelegateSelector
                            searchInputRef={searchInputRef}
                            currentDelegateIds={currentDelegateIds}
                          />
                        </PopoverContent>
                      </Portal>
                    </Popover>
                    <Tooltip
                      shouldWrapChildren
                      fontWeight="light"
                      label={intl.formatMessage({
                        defaultMessage:
                          "<strong>Add delegates to review your agreements</strong><br></br>Delegate reviewers to review agreements found in your Gmail. Only users with the legal role can be delegated. You can add or remove them at any time.",
                        id: "integrations.page.connect.addDelegates.help",
                        description: "Tooltip text for add delegates button",
                      })}
                    >
                      <Icon as={HelpIcon} />
                    </Tooltip>
                    {currentDelegates && currentDelegates.length > 0 && (
                      <AvatarGroup spacing={-1}>
                        {currentDelegates.map((user) => (
                          <Avatar
                            key={user.id}
                            src={getPublicImageGcsUrl(user.profile_image?.gcs_file_name) || ""}
                            name={displayPersonName(user, intl)}
                            icon={<Icon as={UserIcon} />}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                  </>
                )}
              </HStack>
              <Spacer />
              {!connectedIntegration ? (
                <Button onClick={connectModal.onOpen} colorScheme="brand" flexShrink={0}>
                  <FormattedMessage
                    id="integrations.page.connect.button"
                    description="Button text of integration page connect integration option"
                    defaultMessage="Connect"
                  />
                </Button>
              ) : (
                <>
                  {connectedIntegration.status === "disconnected" ? (
                    <Tooltip
                      label={intl.formatMessage({
                        id: "integrations.page.disconnected.tooltip",
                        description: "Tooltip text of integration page disconnected integration option",
                        defaultMessage:
                          "The integration has failed and won’t retrieve new data until it is reconnected.",
                      })}
                    >
                      <Button
                        variant="ghost"
                        fontWeight="semibold"
                        fontSize="sm"
                        color="error.500"
                        onClick={() => reconnectModal.onOpen()}
                      >
                        <FormattedMessage
                          id="integrations.page.reconnect.button"
                          description="Button text of integration page disconnected integration option"
                          defaultMessage="Reconnect"
                        />
                      </Button>
                    </Tooltip>
                  ) : (
                    <HStack textColor="brand.700" fontWeight="semibold" fontSize="sm">
                      <FormattedMessage
                        id="integrations.page.connect.button"
                        description="Button text of integration page connect integration option"
                        defaultMessage="Connected"
                      />
                      <Icon as={CheckIcon} display="inline" />
                    </HStack>
                  )}
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Icon as={MoreMenuIcon} />}
                      aria-label={intl.formatMessage({
                        id: "profile.gmailIntegration.moreMenu",
                        description: "More menu for gmail integration actions",
                        defaultMessage: "IntegrationActions",
                      })}
                      variant="ghost"
                      padding={1}
                    />
                    <MenuList>
                      <MenuItem onClick={disconnectModal.onOpen}>
                        <FormattedMessage
                          id="integrations.page.connect.disconnect"
                          description="Button text of integration page connect integration option"
                          defaultMessage="Disconnect"
                        />
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </HStack>
            <Text color="gray.600" fontSize="sm">
              {integrationConnectOption.description}
            </Text>
          </Stack>
        </CardBody>
        {connectedIntegration && (
          <>
            <Divider borderColor="gray.300" />
            <CardFooter paddingY={4} alignItems="center">
              <Text color="gray.600" fontSize="xs">
                {connectedIntegration.crawler_state?.oldest_crawled_activity_date &&
                connectedIntegration.crawler_last_success ? (
                  <FormattedMessage
                    id="integration.status.crawledRange"
                    description="The time range the integration has crawled"
                    defaultMessage="Emails scanned from {dateRange}."
                    values={{
                      dateRange: formatDateRange(
                        intl,
                        connectedIntegration.crawler_state.oldest_crawled_activity_date,
                        connectedIntegration.crawler_last_success
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="integration.status.updated"
                    description="The date and time the integration last updated data"
                    defaultMessage="Updated: {updatedDateTime}"
                    values={{
                      updatedDateTime: formatDateTime(
                        intl,
                        connectedIntegration.crawler_last_success ?? connectedIntegration.created_at
                      ),
                    }}
                  />
                )}{" "}
                {connectedIntegration.detail_text}
              </Text>
              <Spacer />
              <Button as={Link} fontSize="sm" variant="link" color="brand.500" to="/agreements?source=email">
                <FormattedMessage
                  id="integrations.page.goToAgreements.button"
                  description="Button text of integration page go to agreements option"
                  defaultMessage="Go to agreements"
                />
              </Button>
            </CardFooter>
          </>
        )}
      </Card>

      {connectedIntegration && (
        <CreateIntegrationModal
          integrationId={connectedIntegration.id}
          integrationProvider="gmail_oauth"
          {...reconnectModal}
        />
      )}
      {!connectedIntegration ? (
        <CreateIntegrationModal integrationProvider={integrationConnectOption.provider} {...connectModal} />
      ) : (
        <DeleteIntegrationConfirmationModal {...disconnectModal} integrationBeingDeleted={connectedIntegration} />
      )}
    </>
  )
}

const ConnectGmailDelegateSelector: FunctionComponent<{
  searchInputRef: React.LegacyRef<HTMLInputElement>
  currentDelegateIds: Set<string>
}> = ({ searchInputRef, currentDelegateIds }) => {
  const [searchInput, setSearchInput] = useState("")
  const [updateDelegates] = usePostUserV1EmailDelegatesMutation()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  return (
    <>
      <Flex background="white" zIndex={1} borderBottomWidth="1px" p={2} gap={1} alignItems="center">
        <InputGroup>
          <InputLeftElement>
            <Icon as={SearchIcon} />
          </InputLeftElement>
          <Input ref={searchInputRef} type="search" onChange={(e) => setSearchInput(e.target.value)} autoFocus />
        </InputGroup>
      </Flex>
      <ObjectSelectorFilter
        searchInput={searchInput}
        excludedValues={whoami ? new Set([whoami.id]) : undefined}
        onChange={async (value) => {
          const newDelegateIds = new Set(currentDelegateIds)
          if (currentDelegateIds.has(value)) {
            newDelegateIds.delete(value)
            await updateDelegates({
              body: { delegate_ids: Array.from(newDelegateIds) },
            })
          } else {
            newDelegateIds.add(value)
            await updateDelegates({
              body: { delegate_ids: Array.from(newDelegateIds) },
            })
          }
        }}
        selectedValues={currentDelegateIds}
        selectable={{
          objectType: "User",
          permission: {
            value: "legal:update",
            includeAll: false,
          },
        }}
      />
    </>
  )
}
