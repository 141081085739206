import { Button, Divider, GridItem, HStack, Stack, Text, useDisclosure } from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import type { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

export interface TimelineAlertItemProps {
  description: string
  title: string
  action: ReactNode
  key: string
  icon: ReactNode
}

export const TimelineAlertItem = ({ icon, title, description, action, key }: TimelineAlertItemProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  if (!isOpen) {
    return null
  }
  return (
    <chakra.li
      className="alert-item"
      display="contents"
      key={key}
      borderWidth={1}
      borderColor="gray.200"
      rounded="md"
      padding={2}
    >
      <GridItem justifySelf="center" placeItems="start" display="flex" whiteSpace="nowrap">
        {icon}
      </GridItem>
      <GridItem display="flex" placeItems="center" py={1}>
        <Stack>
          <Text fontSize="md" fontWeight="semibold">
            {title}
          </Text>
          <Text fontSize="sm">{description}</Text>
          <HStack gap={3}>
            {action}
            <Button borderRadius={0} color="gray.600" variant="link" onClick={onClose}>
              <FormattedMessage
                defaultMessage="Dismiss"
                id="timeline.alert.dismiss"
                description="Dismiss alert button"
              />
            </Button>
          </HStack>
        </Stack>
      </GridItem>
      <GridItem placeItems="center" display="flex" />
      <GridItem minH={3} alignSelf="center" colSpan={3} placeItems="center" display="flex">
        <Divider borderColor="gray.300" />
      </GridItem>
    </chakra.li>
  )
}
