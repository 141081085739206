import { Icon, IconButton, Tooltip } from "@chakra-ui/react"
import { forwardRef } from "react"
import { useIntl } from "react-intl"
import { XIcon } from "../icons.js"
import type { BrmIconButtonProps } from "./types.js"

/**
 * Wrapper around IconButton that adds a default tooltip and aria-label intl string.
 * Also allows for a global setting for what Chakra icon should be used for each button type.
 */
const CancelIconButton = forwardRef<HTMLButtonElement, BrmIconButtonProps>(function CancelIconButton(
  { label, iconProps, ...props }: BrmIconButtonProps,
  ref
) {
  const intl = useIntl()

  const labelMessage =
    label ??
    intl.formatMessage({
      id: "iconButtons.cancel.label",
      description: "The label for a cancel button",
      defaultMessage: "Cancel",
    })

  return (
    <Tooltip label={labelMessage}>
      <IconButton icon={<Icon as={XIcon} {...iconProps} />} aria-label={labelMessage} ref={ref} {...props} />
    </Tooltip>
  )
})

export default CancelIconButton
