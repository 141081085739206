import { Outlet } from "react-router-dom"
import PageWrapper from "../PageWrapper.js"

export default function PageContainer() {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  )
}
