import type {
  LegalAgreementListItem,
  LegalAgreementWithRenewal,
  ListQueryStringParams,
  ToolDetails,
} from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { formatDate } from "@brm/util/format-date-time.js"
import {
  Alert,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Select } from "chakra-react-select"
import { useMemo } from "react"
import { Controller, type Control, type UseFormSetValue } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { WorkflowKind } from "../../../../app/services/generated-api.js"
import { usePostLegalV1AgreementsListQuery } from "../../../../app/services/generated-api.js"
import CardWithHoverActions from "../../../../components/CardWithHoverActions.js"
import { ExtractionIcon } from "../../../../components/ExtractionHighlight.js"
import BasicOrganizationEntityPicker from "../../../../components/Form/BasicOrganizationEntityPicker.js"
import { CurrencyAmountFormControl } from "../../../../components/Form/CurrencyAmountInput.js"
import { ToolOptionPicker } from "../../../../components/Form/ToolOptionPicker.js"
import ToolPicker from "../../../../components/Form/ToolPicker.js"
import { VendorOptionPicker } from "../../../../components/Form/VendorOptionPicker.js"
import { chakraStylesForProfilePicturePickerOption } from "../../../../components/Form/constants.js"
import { Link } from "../../../../components/Link.js"
import { ToolLogo } from "../../../../components/icons/Logo.js"
import { AlertIcon, NextIcon } from "../../../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../../../util/url.js"
import type { LegalAgreementOption } from "../../../legal/LegalAgreementPicker.js"
import { displayWorkflowKind } from "../../util.js"
import type { StartWorkflowFormState, WorkflowLegalAgreementSectionFormState } from "./util.js"
import { generateWorkflowDisplayName } from "./util.js"

export default function WorkflowLegalAgreementSection({
  control,
  selectedToolWithDetails,
  selectedLegalAgreement,
  isPreselectedToolRenewal,
  workflowKind,
  setValue,
}: {
  selectedToolWithDetails: ToolDetails | undefined
  selectedLegalAgreement: LegalAgreementOption | null
  isPreselectedToolRenewal: boolean
  control: Control<WorkflowLegalAgreementSectionFormState>
  workflowKind: WorkflowKind | null
  setValue: UseFormSetValue<StartWorkflowFormState>
}) {
  const intl = useIntl()

  const body = useMemo(
    (): ListQueryStringParams => ({
      filter: [
        [
          {
            column: "renewal_successor" satisfies BRMPaths<LegalAgreementListItem>,
            fields: { comparator: "exists", value: false },
          },
        ],
        [
          {
            column: "decision_date" satisfies BRMPaths<LegalAgreementListItem>,
            fields: {
              comparator: "between",
              minValue: Temporal.Now.plainDateISO().toString(),
              maxValue: Temporal.Now.plainDateISO().add({ days: 60 }).toString(),
            },
          },
        ],
        [
          {
            column: "agreement_type" satisfies BRMPaths<LegalAgreementListItem>,
            fields: { comparator: "eq", value: "enterprise" },
          },
        ],
      ],
      sort: { by: "decision_date", direction: "ASC" },
      limit: 3,
    }),
    []
  )

  const { data: upcomingRenewals } = usePostLegalV1AgreementsListQuery({ listQueryStringParams: body })

  const expandedDetails = selectedToolWithDetails ? (
    <>
      <FormControl marginTop={6} isReadOnly>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Tool name"
            description="The label for the tool field in the start workflow modal form"
            id="requests.start.modal.tool.label"
          />
        </FormLabel>
        <ToolOptionPicker value={selectedToolWithDetails} isReadOnly />
      </FormControl>
      <Controller
        name="tool_owner"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} marginTop={6} isRequired>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Tool owner"
                description="The label for the tool owner field in the start workflow modal form"
                id="requests.start.modal.tool.owner.label"
              />
            </FormLabel>
            <BasicOrganizationEntityPicker
              // This is an uncontrolled component so if the owner changes we force re-render to make sure it picks up the new "initialValue"
              key={field.value?.id}
              includedEntities={["person"]}
              initialSelected={field.value}
              onChangePerson={(person) => field.onChange(person)}
              isRequired
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
              }}
            />
          </FormControl>
        )}
      />
      <FormControl marginTop={6} isReadOnly>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Vendor"
            description="The label for the vendor field in the start workflow modal form"
            id="requests.start.modal.vendor.label"
          />
        </FormLabel>
        <VendorOptionPicker value={selectedToolWithDetails.vendor} isReadOnly />
      </FormControl>
      <FormControl marginTop={6} isReadOnly>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Tool description"
            description="The label for the tool description field in the start workflow modal form"
            id="requests.start.modal.tool.description.label"
          />
        </FormLabel>
        <Textarea
          value={selectedToolWithDetails.description || ""}
          placeholder={intl.formatMessage(
            {
              id: "requests.start.modal.tool.description.placeholder",
              defaultMessage: "No description found for {tool}",
              description: "Placeholder shown when there is no description on a displayed tool",
            },
            { tool: selectedToolWithDetails.display_name }
          )}
          isReadOnly
        />
      </FormControl>
      {selectedToolWithDetails.website ? (
        <Link to={selectedToolWithDetails.website} target="_blank" display="inline-block" color="blue.500">
          <HStack marginTop={2} gap={1}>
            <FormattedMessage
              id="requests.start.modal.tool.website"
              description="Link text to click which navigates user to the website of a tool"
              defaultMessage="Learn more about {website}"
              values={{ website: selectedToolWithDetails.website }}
            />

            <Icon as={NextIcon} />
          </HStack>
        </Link>
      ) : null}

      {selectedLegalAgreement && (
        <CurrencyAmountFormControl
          value={selectedLegalAgreement.total_contract_value || null}
          isReadOnly
          legend={
            <FormattedMessage
              defaultMessage="Total contract value"
              description="The label for the total contract value field in the start workflow modal form"
              id="requests.start.modal.agreement.tcv.label"
            />
          }
          marginTop={6}
        />
      )}
    </>
  ) : (
    selectedLegalAgreement && (
      <Text mt={4}>
        <FormattedMessage
          id="requests.start.modal.agreement.no_tools"
          defaultMessage="The selected agreement has no attached tools. Please select a different agreement or edit this one."
          description="Error message shown when a legal agreement is selected for renewal but it has no tools attached"
        />
      </Text>
    )
  )

  return (
    <Box>
      <Text>
        <FormattedMessage
          id="requests.start.modal.agreement.header"
          description="Header for the start workflow modal agreement selection section"
          defaultMessage="1. Confirm existing agreement details"
        />
      </Text>

      <Controller
        name="tool"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: toolField, fieldState: toolFieldState }) => (
          <FormControl isInvalid={toolFieldState.invalid} isRequired marginTop={6}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Tool name"
                description="The label for the tool field in the start workflow modal form"
                id="requests.start.modal.tool.label"
              />
            </FormLabel>
            <ToolPicker
              value={
                toolField.value && toolField.value.id
                  ? { ...toolField.value, object_type: "Tool", id: toolField.value.id, created_at: "", updated_at: "" }
                  : undefined
              }
              onChange={(tool) => {
                toolField.onChange(tool)
                setValue("legal_agreement", null)
              }}
              menuPortalTarget={undefined}
            />
            {toolFieldState.error?.message && <FormErrorMessage>{toolFieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      {!selectedToolWithDetails && upcomingRenewals && upcomingRenewals.items.length > 0 && (
        <Controller
          name="tool"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: toolField }) => (
            <Box marginTop={6}>
              <FormLabel>
                <FormattedMessage
                  id="request.start.modal.upcoming_renewals.heading"
                  description="Heading over section in start renewal modal that shows upcoming renewals and allows users to select one to prefill the modal fields"
                  defaultMessage="Upcoming renewals"
                />
                <ExtractionIcon />
              </FormLabel>
              <Stack gap={4}>
                {upcomingRenewals.items.map((renewalAgreement) => (
                  <CardWithHoverActions
                    key={renewalAgreement.id}
                    onClick={() => {
                      const agreementTool = renewalAgreement.tools[0]
                      toolField.onChange(agreementTool)
                      setValue("legal_agreement", renewalAgreement)
                      if (agreementTool?.display_name && workflowKind) {
                        setValue(
                          "display_name",
                          generateWorkflowDisplayName(agreementTool.display_name, workflowKind, intl)
                        )
                      }
                    }}
                  >
                    <HStack justifyContent="space-between" alignItems="stretch">
                      <HStack alignItems="stretch" minWidth={0}>
                        <Stack justifyContent="center">
                          <ToolLogo
                            logo={getPublicImageGcsUrl(renewalAgreement.tools[0]?.image_asset?.gcs_file_name)}
                          />
                        </Stack>
                        <Stack gap={1} minWidth={0}>
                          <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                            {renewalAgreement.display_name}
                          </Text>
                          <Text fontSize="xs" color="gray.500">
                            {renewalAgreement.tools[0]?.display_name}
                          </Text>
                        </Stack>
                      </HStack>
                      {renewalAgreement.decision_date && (
                        <Stack justifyContent="start" flex={0} alignItems="end">
                          <Text color="error.600" fontSize="sm" display="inline">
                            <FormattedMessage
                              id="request.start.modal.upcoming_renewal.decision_date"
                              description="When the upcoming renewal needs expires and needs another renewal decision to be made"
                              defaultMessage="Renewal Decision Date {at}"
                              values={{ at: formatDate(intl, renewalAgreement.decision_date) }}
                            />
                          </Text>
                        </Stack>
                      )}
                    </HStack>
                  </CardWithHoverActions>
                ))}
              </Stack>
            </Box>
          )}
        />
      )}
      {selectedToolWithDetails &&
        (selectedToolWithDetails.legal_agreements.length > 0 ? (
          <Controller
            name="legal_agreement"
            control={control}
            rules={{
              required: true,
              validate: (value) => !value?.renewal_successor,
            }}
            render={({ field, fieldState }) => (
              <FormControl
                isInvalid={fieldState.invalid}
                isRequired
                marginTop={6}
                isReadOnly={isPreselectedToolRenewal && !!selectedLegalAgreement}
              >
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="Agreement Name"
                    description="The label for the agreement name field in the start workflow modal form"
                    id="requests.start.modal.agreement.name"
                  />
                </FormLabel>
                <Select<LegalAgreementWithRenewal>
                  options={selectedToolWithDetails.legal_agreements.map((legal) => ({ ...legal, tools: [] }))}
                  chakraStyles={chakraStylesForProfilePicturePickerOption((legalAgreement) =>
                    getPublicImageGcsUrl(legalAgreement.vendor?.image_asset?.gcs_file_name)
                  )}
                  getOptionLabel={(legalAgreement) => legalAgreement.display_name}
                  getOptionValue={(legalAgreement) => legalAgreement.id}
                  value={field.value}
                  onChange={(legalAgreement) => {
                    field.onChange(legalAgreement)
                    if (selectedToolWithDetails.display_name && workflowKind) {
                      setValue(
                        "display_name",
                        generateWorkflowDisplayName(selectedToolWithDetails.display_name, workflowKind, intl)
                      )
                    }
                  }}
                />
                {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                {field.value?.renewal_successor && (
                  <Alert status="error" marginTop={2} gap={2}>
                    <Icon as={AlertIcon} color="error" />
                    <Text>
                      <FormattedMessage
                        id="requests.start.renewal.error.already_exists"
                        description="Error message shown if attempting to start a renewal on a tool that already has an active renewal for its active context"
                        defaultMessage="There is already an active {workflowKind} request for this tool: {link}"
                        values={{
                          workflowKind: displayWorkflowKind("renewal"),
                          link: (
                            <Link to={`/requests/${field.value.renewal_successor.id}`} color="blue.500">
                              {field.value.renewal_successor.display_name}
                            </Link>
                          ),
                        }}
                      />
                    </Text>
                  </Alert>
                )}
              </FormControl>
            )}
          />
        ) : (
          <>
            <Text color="error.500" fontSize="sm" marginTop={2}>
              <FormattedMessage
                id="requests.start.renewal.error.no_agreements"
                description="Error message shown if attempting to start a renewal on a tool that has no agreements"
                defaultMessage="No existing agreement found. Create an agreement for {toolName} below or create a purchase request."
                values={{ toolName: selectedToolWithDetails.display_name }}
              />
            </Text>
            <Controller
              name="new_legal_agreement_name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid} isRequired marginTop={4}>
                  <FormLabel>
                    <FormattedMessage
                      defaultMessage="Agreement name"
                      description="The label for the agreement name field in the start workflow modal form"
                      id="requests.start.modal.agreement_name.label"
                    />
                  </FormLabel>
                  <Input onChange={field.onChange} value={field.value || ""} isRequired />
                  {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                </FormControl>
              )}
            />
          </>
        ))}

      {expandedDetails}
    </Box>
  )
}
