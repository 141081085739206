import { chakra } from "@chakra-ui/system"
import type { ReactElement } from "react"
import { FormattedMessage } from "react-intl"

interface Props {
  fieldLabel: ReactElement
  objectLink?: ReactElement
  actor: ReactElement | null
  showFieldName?: boolean
}
export default function DocumentFieldChangeEvent({ fieldLabel, objectLink, actor, showFieldName }: Props) {
  return showFieldName ? (
    objectLink ? (
      <FormattedMessage
        defaultMessage="{actor} added a file to {fieldLabel} for {objectLink}"
        description="Subtitle of a field change event adding a new document"
        id="timeline.field_change.document.subheading.withField.withLink"
        values={{ actor, fieldLabel, objectLink }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{actor} added a file to {fieldLabel}"
        description="Subtitle of a field change event adding a new document"
        id="timeline.field_change.document.subheading.withField"
        values={{ actor, fieldLabel: <chakra.strong fontWeight="medium">{fieldLabel}</chakra.strong> }}
      />
    )
  ) : objectLink ? (
    <FormattedMessage
      defaultMessage="{actor} added a file for {objectLink}"
      description="Subtitle of a field change event adding a new document"
      id="timeline.field_change.document.subheading.withLink"
      values={{ actor, objectLink }}
    />
  ) : (
    <FormattedMessage
      defaultMessage="{actor} added a file"
      description="Subtitle of a field change event adding a new document"
      id="timeline.field_change.document.subheading"
      values={{ actor }}
    />
  )
}
