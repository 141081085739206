import { hasPermission, ROLES_BY_PERMISSION } from "@brm/schema-helpers/role.js"
import type { InviteUser, Permission } from "@brm/schema-types/types.js"
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack } from "@chakra-ui/react"
import { Controller, type UseFormReturn } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { getLabelByRole } from "../role.js"
import { RoleSelect } from "../RoleSelect.js"
import InviteEmailAutocomplete from "./InviteEmailAutocomplete.js"

export default function InviteOrUpdateUserFormContent({
  form,
  userToUpdateId,
  hideRoleEditing,
  requiredPermission,
}: {
  userToUpdateId?: string
  hideRoleEditing?: boolean
  requiredPermission?: Permission
  form: UseFormReturn<InviteUser>
}) {
  const intl = useIntl()
  const labelByRole = getLabelByRole(intl)

  return (
    <>
      <Stack gap={2}>
        <Controller
          name="email"
          control={form.control}
          rules={{
            required: intl.formatMessage({
              id: "user.email.required.error",
              description: "Error message when the user is missing an email",
              defaultMessage: "Email is required",
            }),
          }}
          render={({ field, fieldState }) => {
            const isReadOnly = !!userToUpdateId
            return (
              <FormControl isRequired={!isReadOnly} isReadOnly={isReadOnly} isInvalid={fieldState.invalid}>
                <FormLabel htmlFor="email">
                  <FormattedMessage
                    id="organization.sendInvitation.email"
                    description="Input for a new email to send an invitation"
                    defaultMessage="Email"
                  />
                </FormLabel>
                {isReadOnly ? (
                  <Input id="email" {...field} />
                ) : (
                  <InviteEmailAutocomplete
                    onChangeSelectedPerson={(person) => {
                      field.onChange(person.email)
                      if (person.first_name) {
                        form.setValue("first_name", person.first_name)
                      }
                      if (person.last_name) {
                        form.setValue("last_name", person.last_name)
                      }
                    }}
                    email={field.value}
                    onChangeEmail={(email) => field.onChange(email)}
                  />
                )}
              </FormControl>
            )
          }}
        />
        <Flex alignItems="flex-start" gap={2}>
          <Controller
            name="first_name"
            control={form.control}
            rules={{
              required: intl.formatMessage({
                id: "user.firstName.required.error",
                description: "Error message when the user is missing a first name",
                defaultMessage: "First name is required",
              }),
            }}
            render={({ field, fieldState }) => (
              <FormControl isRequired={true} isInvalid={fieldState.invalid}>
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage
                    defaultMessage="First name:"
                    description="Form input label of the first name input in the user invite modal"
                    id="organization.sendInvitation.firstName.label"
                  />
                </FormLabel>
                <Box>
                  <Input {...field} id={field.name} />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </Box>
              </FormControl>
            )}
          />
          <Controller
            name="last_name"
            control={form.control}
            rules={{
              required: intl.formatMessage({
                id: "user.lastName.required.error",
                description: "Error message when the user is missing a last name",
                defaultMessage: "Last name is required",
              }),
            }}
            render={({ field, fieldState }) => (
              <FormControl isRequired={true} isInvalid={fieldState.invalid}>
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage
                    defaultMessage="Last name:"
                    description="Form input label of the last name input in the user invite modal"
                    id="organization.sendInvitation.lastName.label"
                  />
                </FormLabel>
                <Box>
                  <Input {...field} id={field.name} />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </Box>
              </FormControl>
            )}
          />
        </Flex>
      </Stack>
      {!hideRoleEditing && (
        <Controller
          name="roles"
          control={form.control}
          rules={{
            validate: (roles) =>
              requiredPermission && !hasPermission(roles, requiredPermission)
                ? intl.formatMessage(
                    {
                      id: "organization.sendInvitation.roles.error",
                      description:
                        "Error message when the invited user does not have a required role to perform an action",
                      defaultMessage: "The user assigned for this must be given one of the following roles: {roles}",
                    },
                    {
                      roles: intl.formatList(
                        ROLES_BY_PERMISSION[requiredPermission].map((role) => labelByRole[role]),
                        { type: "disjunction" }
                      ),
                    }
                  )
                : true,
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <RoleSelect onChange={(roles) => field.onChange(roles)} roles={field.value} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      )}
    </>
  )
}
