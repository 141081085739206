import type { IntegrationProvider, LoginScopeSummary } from "@brm/schema-types/types.js"
import { Box, Flex, HStack, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import type { ExpandedState } from "@tanstack/react-table"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useMemo, useState } from "react"
import type { IntlShape } from "react-intl"
import { useIntl } from "react-intl"
import LoginScopeRiskCell from "../../components/DataTable/CellRenderer/LoginScopeRiskCell.js"
import ProviderCell from "../../components/DataTable/CellRenderer/ProviderCell.js"
import { Link } from "../../components/Link.js"
import { LoginScopeAppIcon } from "../../components/icons/LoginScopeAppIcons.js"
import { ToolLogo } from "../../components/icons/Logo.js"
import { ChevronDownIcon, ChevronRightIcon } from "../../components/icons/icons.js"
import type { ScopeSummaryRow } from "../../util/login-scope.js"
import { packageLoginScopesForDataTable } from "../../util/login-scope.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import ScopeTag from "./ScopeTag.js"

function SecurityCell({
  providerName,
  groupName,
  toolName,
  description,
  toolId,
  logoGcsFileName,
}: {
  providerName?: string
  groupName?: string
  toolName?: string
  description?: string
  toolId?: string
  logoGcsFileName?: string | null
}) {
  if (providerName) {
    return <ProviderCell provider={providerName as IntegrationProvider} />
  }
  if (groupName) {
    return (
      <Flex align="center" gap={2}>
        <LoginScopeAppIcon boxSize={6} appType={groupName} />
        <Text>{groupName}</Text>
      </Flex>
    )
  }
  if (toolName) {
    return (
      <Link
        // Prevent the table from flashing and expanding before the link navigation occurs
        onClick={(e) => e.stopPropagation()}
        to={`/tools/${toolId}`}
      >
        <HStack>
          <ToolLogo logo={getPublicImageGcsUrl(logoGcsFileName)} />
          <Text fontWeight="medium">{toolName}</Text>
        </HStack>
      </Link>
    )
  }
  if (description) {
    return <Text>{description}</Text>
  }
  return null
}

function ExpandedRowIcon({ canExpand, isExpanded }: { canExpand: boolean; isExpanded: boolean }) {
  if (!canExpand) {
    return null
  }
  if (isExpanded) {
    return <Icon as={ChevronDownIcon} />
  }
  return <Icon as={ChevronRightIcon} />
}

const columnHelper = createColumnHelper<ScopeSummaryRow>()

const getColumns = (intl: IntlShape) => [
  columnHelper.accessor("provider", {
    cell: ({ row, getValue }) => {
      return (
        <Flex pl={`${row.depth * 2}rem`} align="center" gap={2}>
          <ExpandedRowIcon canExpand={row.getCanExpand()} isExpanded={row.getIsExpanded()} />
          <SecurityCell
            providerName={getValue()}
            groupName={row.original.resourceGroup}
            toolName={row.original.displayName}
            description={row.original.description}
            toolId={row.original.toolId}
            logoGcsFileName={row.original.logoGcsFileName}
          />
        </Flex>
      )
    },
    size: 700,
    meta: {
      hideHeader: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor("riskScore", {
    cell: ({ getValue }) => {
      return <LoginScopeRiskCell riskScore={getValue()} />
    },
    header: intl.formatMessage({
      id: "tool.table.header.name",
      description: "Tool table column header - name",
      defaultMessage: "Risk Score",
    }),
    size: 200,
    enableSorting: false,
  }),
  columnHelper.accessor("scope", {
    cell: ({ getValue }) => {
      const scope = getValue()
      return scope ? <ScopeTag scope={scope} /> : null
    },
    header: intl.formatMessage({
      id: "security.overview.header.scope",
      description: "Security overview table column header - scope",
      defaultMessage: "Scope",
    }),
    size: 300,
    enableSorting: false,
  }),
  columnHelper.accessor("toolCount", {
    cell: ({ getValue }) => getValue(),
    header: intl.formatMessage({
      id: "tool.table.header.name",
      description: "Security overview table column header - tools",
      defaultMessage: "Tools",
    }),
    enableSorting: false,
  }),
]

interface Props {
  data: LoginScopeSummary
}

export default function SecurityOverviewTable({ data }: Props) {
  const intl = useIntl()
  const collator = useMemo(() => new Intl.Collator(intl.locale), [intl.locale])
  const scopeSummaryData = useMemo(() => packageLoginScopesForDataTable(data, collator), [collator, data])

  // Initially expand
  const initialExpandedState: Record<number, boolean> = useMemo(() => {
    const expandedMap: Record<number, boolean> = {}
    scopeSummaryData.forEach((_, idx) => {
      expandedMap[idx] = true
    })
    return expandedMap
  }, [scopeSummaryData])

  const [expanded, setExpanded] = useState<ExpandedState>(initialExpandedState)
  const table = useReactTable<ScopeSummaryRow>({
    columns: getColumns(intl),
    data: scopeSummaryData,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getRowCanExpand: (row) => !!row.subRows?.length,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <Box border="1px solid" borderColor="gray.100" borderRadius="lg">
      <Box overflowX="auto">
        <Box minWidth={table.getCenterTotalSize()}>
          <Table borderTopRadius="lg" overflow="hidden">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th width={header.getSize()} key={header.id} borderTopWidth={0} background="gray.50">
                        <Flex onClick={header.column.getToggleSortingHandler()}>
                          {header.column.columnDef.meta?.hideHeader ? null : (
                            <Flex>{flexRender(header.column.columnDef.header, header.getContext())}</Flex>
                          )}
                        </Flex>
                      </Th>
                    )
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr
                    key={row.id}
                    onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : undefined}
                    cursor={row.getCanExpand() ? "pointer" : "default"}
                    _hover={row.getCanExpand() ? { bg: "gray.100" } : undefined}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}
