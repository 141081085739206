import type { WorkflowStepStandardType } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Icon } from "@chakra-ui/react"
import FeaturedIcon from "../../components/FeaturedIcon/FeaturedIcon.js"
import {
  ComplianceIcon,
  FinanceIcon,
  ITIcon,
  LegalIcon,
  RequestCloseIcon,
  RequestDetailsIcon,
} from "../../components/icons/icons.js"

/**
 * Assign an icon to a step based on step type
 */
export default function WorkflowStepIcon({ stepType }: { stepType: WorkflowStepStandardType }) {
  switch (stepType) {
    case "compliance":
      return <FeaturedIcon icon={<Icon as={ComplianceIcon} color="purple.700" />} backgroundColor="purple.100" />
    case "finance":
      return <FeaturedIcon icon={<Icon as={FinanceIcon} color="green.700" />} backgroundColor="green.100" />
    case "legal":
      return <FeaturedIcon icon={<Icon as={LegalIcon} color="blue.700" />} backgroundColor="blue.100" />
    case "it":
      return <FeaturedIcon icon={<Icon as={ITIcon} color="orange.700" />} backgroundColor="orange.100" />
    case "close":
      return <FeaturedIcon icon={<Icon as={RequestCloseIcon} color="brand.700" />} backgroundColor="brand.100" />
    case "details":
    case "custom":
      return <FeaturedIcon icon={<Icon as={RequestDetailsIcon} />} />
    default:
      unreachable(stepType)
  }
}
