import { isWorkflowRunProgressType } from "@brm/schema-helpers/schema.js"
import type { AnyFilter, ArrContainsFilter } from "@brm/schema-types/types.js"
import { getEnumOptions, isEnumArrayType, isEnumType } from "@brm/util/schema.js"
import { Checkbox, Flex } from "@chakra-ui/react"
import { Fragment, useState } from "react"
import { isNotUndefined } from "typed-assert"
import { log } from "../../../../util/logger.js"
import { NullCheckbox } from "../../../Form/NullCheckbox.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { FilterProps } from "./types.js"

/**
 * Enum Selector Filter only works with the options and tags filter types and the "any", "arr_contains" comparators
 */
export default function EnumSelectorFilter({
  fieldSchema,
  isNullable,
  displayPath,
  filter,
  onChange,
}: FilterProps<ArrContainsFilter | AnyFilter>) {
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")

  let schemaWithValue = { schema: fieldSchema }
  if (isWorkflowRunProgressType(schemaWithValue)) {
    fieldSchema = schemaWithValue.schema.properties.status
    schemaWithValue = { schema: fieldSchema }
  }

  const defaultComparator = isEnumArrayType({ schema: fieldSchema }) ? "arr_contains" : "any"

  const [isNullChecked, setIsNullChecked] = useState(filter ? filter.includeNull : false)
  const [values, setValues] = useState<ReadonlySet<string>>(new Set(filter?.values))

  const toggleValues = (checked: boolean, toggledValues: ReadonlySet<string>) => {
    const comparator = filter?.comparator ?? defaultComparator
    const newValues = checked ? new Set([...values, ...toggledValues]) : values.difference(toggledValues)
    setValues(newValues)
    if (newValues.size > 0 || isNullChecked) {
      // Only sync valid filters back up to the filter state
      onChange({ comparator, values: Array.from(newValues), includeNull: isNullChecked })
    }
  }

  const nullOption = isNullable && (
    <NullCheckbox
      isChecked={isNullChecked}
      fieldName={fieldName}
      fieldSchema={fieldSchema}
      p={2}
      onChange={({ target: { checked } }) => {
        setIsNullChecked(checked)
        if (checked) {
          if (!filter) {
            onChange({ comparator: defaultComparator, values: [], includeNull: true })
          } else if (filter) {
            onChange({ ...filter, includeNull: checked })
          }
        }
      }}
    />
  )

  if (!isEnumType(schemaWithValue) && !isEnumArrayType(schemaWithValue)) {
    log.error("Unexpected EnumSelector schema", { fieldSchema })
    return null
  }

  return (
    <Flex flexDir="column" p={2} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC} overflow="auto">
      {nullOption}
      {getEnumOptions(schemaWithValue.schema, false).map((option, index) => {
        if ("anyOf" in option) {
          // Enum group
          const groupValues = new Set(option.anyOf.map((o) => o.const))
          const isChecked = option.anyOf.every((o) => values.has(o.const))
          return (
            <Fragment key={`group-${index}`}>
              <Checkbox
                isIndeterminate={!isChecked && option.anyOf.some((o) => values.has(o.const))}
                isChecked={isChecked}
                onChange={({ target: { checked } }) => toggleValues(checked, groupValues)}
                p={2}
              >
                {option.title}
              </Checkbox>
              <Flex flexDir="column" role="group">
                {option.anyOf.map((option) => (
                  <Checkbox
                    key={option.const}
                    value={option.const}
                    isChecked={values.has(option.const)}
                    onChange={({ target: { checked } }) => toggleValues(checked, new Set([option.const]))}
                    position="relative"
                    p={2}
                    pl={8}
                    _before={{
                      // eslint-disable-next-line prefer-smart-quotes/prefer
                      content: "''",
                      top: 0,
                      bottom: 0,
                      position: "absolute",
                      left: "calc(var(--chakra-space-3) + 2.6px)",
                      height: "100%",
                      width: "2px",
                      background: "gray.100",
                    }}
                  >
                    {option.title}
                  </Checkbox>
                ))}
              </Flex>
            </Fragment>
          )
        }
        return (
          <Checkbox
            p={2}
            key={option.const}
            value={option.const}
            isChecked={values.has(option.const)}
            onChange={({ target: { checked } }) => toggleValues(checked, new Set([option.const]))}
          >
            {option.title}
          </Checkbox>
        )
      })}
    </Flex>
  )
}
