import type { ImageProps } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import gmailLogo from "../../../assets/google/gmail.png"
import googleLogo from "../../../assets/google/google.png"
import adminLogo from "../../../assets/google/google_admin.png"
import calendarLogo from "../../../assets/google/google_calendar.png"
import contactsLogo from "../../../assets/google/google_contacts.png"
import driveLogo from "../../../assets/google/google_drive.png"
import { OktaIcon } from "./provider-icons.js"

export function LoginScopeAppIcon({ appType, ...props }: { appType: string } & Pick<ImageProps, "boxSize">) {
  let imageSrc
  switch (appType) {
    case "Admin":
      imageSrc = <Image src={adminLogo} {...props} />
      break
    case "Calendar":
      imageSrc = <Image src={calendarLogo} {...props} />
      break
    case "Gmail":
      imageSrc = <Image src={gmailLogo} {...props} />
      break
    case "Drive":
      imageSrc = <Image src={driveLogo} {...props} />
      break
    case "Contacts":
      imageSrc = <Image src={contactsLogo} {...props} />
      break
    case "Google Sign-in":
      imageSrc = <Image src={googleLogo} {...props} />
      break
    case "Okta Sign-in":
      imageSrc = <OktaIcon {...props} />
      break
    default:
      break
  }
  return imageSrc
}
