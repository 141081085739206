import type { FieldConfig } from "@brm/schema-types/types.js"
import { Text, chakra } from "@chakra-ui/react"
import deepEqual from "fast-deep-equal"
import { FormattedMessage } from "react-intl"
import { Timestamp } from "../../../components/Timestamp.js"
import { selectRelativeTimeUnit } from "../../../util/timestamp.js"

export function CriterionProvenance({ fieldConfig }: { fieldConfig: FieldConfig }) {
  // Standard fields don't really get "created" by the user, so the creation of the metadata is considered an update.
  const createdAt = fieldConfig.is_custom ? fieldConfig.created_at : null
  const createdBy = fieldConfig.is_custom ? fieldConfig.created_by : null
  const updatedAt = fieldConfig.updated_at ?? (fieldConfig.is_custom ? fieldConfig.created_at : null)
  const updatedBy = fieldConfig.updated_by ?? (fieldConfig.is_custom ? fieldConfig.created_by : null)

  const showCreationLine = fieldConfig.is_custom && createdBy && createdAt
  const showUpdateLine =
    updatedBy &&
    updatedAt &&
    (updatedBy.id !== createdBy?.id ||
      !createdAt ||
      // Don't show the updated line if the time values will render as the same value (e.g. both are "now" or "a week ago")
      !deepEqual(selectRelativeTimeUnit(updatedAt), selectRelativeTimeUnit(createdAt)))

  if (!showCreationLine && !showUpdateLine) {
    return null
  }

  return (
    <>
      {showCreationLine && (
        <Text>
          <chakra.strong fontWeight="medium">
            <FormattedMessage
              defaultMessage="Created by {firstName} {lastName}, {dateTime}"
              description="Tooltip label for criterion created by user"
              id="settings.criteria.object.createdBy"
              values={{
                firstName: createdBy.first_name,
                lastName: createdBy.last_name,
                dateTime: <Timestamp dateTime={createdAt} />,
              }}
            />
          </chakra.strong>
        </Text>
      )}
      <Text fontWeight="normal">
        {showUpdateLine && (
          <FormattedMessage
            defaultMessage="Last updated by {firstName} {lastName}, {dateTime}"
            description="Tooltip label for criterion updated by user"
            id="settings.criteria.object.updatedBy"
            values={{
              firstName: updatedBy.first_name,
              lastName: updatedBy.last_name,
              dateTime: <Timestamp dateTime={updatedAt} />,
            }}
          />
        )}
      </Text>
    </>
  )
}
