/* eslint-disable no-console */
import { isObject } from "@brm/util/type-guard.js"
import * as Sentry from "@sentry/browser"

/**
 * logging wrapper - logs to console with the corresponding severity, if error logs to Sentry as well
 */
export const log = {
  ...console,
  error: (message: string, err: unknown, extra: Record<string, unknown> = {}) => {
    // Don't log to Sentry if the error is a 4xx client error, only server 5xx or other unknown errors.
    // 4xx is for errors that need to be fixed by the user and get reported in toasts.
    if (
      !(isObject(err) && "status" in err && typeof err.status === "number" && err.status >= 400 && err.status < 500)
    ) {
      const sentryContext = {
        message,
        ...extra,
      }
      // eslint-disable-next-line no-restricted-properties
      Sentry.captureException(err, sentryContext ? { extra: sentryContext } : undefined)
    }

    if (extra) {
      console.error(message, extra, err)
    } else {
      console.error(message, err)
    }
  },
}
