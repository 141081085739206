import type { LoginMinimal, ToolDetails } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Heading, Stack } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostToolV1ByIdLoginsListQuery } from "../../app/services/generated-api.js"
import DataTable from "../../components/DataTable/DataTable.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import TablePageHeader from "../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../components/DataTable/SchemaTableColumnCustomization.js"
import { useSchemaColumns } from "../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../components/DataTable/use-schema-table-params.js"
import {
  TABLE_DEFAULT_PARAMS,
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
} from "../../util/schema-table.js"
import { useObjectSchema } from "../../util/use-schema.js"
import LoginScopeSummary from "../security/LoginScopeSummary.js"
import { TOOL_LOGINS_TABLE_ID } from "./constants.js"

interface Props {
  tool: ToolDetails
}

const defaultColumns = [
  "authorized_at",
  "person",
  "integration",
  "app_name",
  "event_type",
  "scopes",
] satisfies BRMPaths<LoginMinimal>[]

export default function ToolSecurity({ tool }: Props) {
  const intl = useIntl()

  const loginSchema = useObjectSchema("Login")

  useLocalStorageTableParamsSync(TOOL_LOGINS_TABLE_ID)
  const { tableParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TABLE_DEFAULT_PARAMS,
    objectSchema: loginSchema,
    savedViews: [],
  })

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const columns = useSchemaColumns<LoginMinimal>({ objectSchema: loginSchema, shownColumns })

  const apiParams = useMemo(
    () => loginSchema && tableParams && packageSortFilterOptionsForAPI(tableParams, loginSchema, intl),
    [tableParams, loginSchema, intl]
  )

  const { data, isFetching } = usePostToolV1ByIdLoginsListQuery(
    apiParams ? { id: tool.id, listQueryStringParams: apiParams } : skipToken
  )

  if (!tableParams || !updateTableParams || !loginSchema || !shownColumns || !columns) {
    return null
  }

  return (
    <Stack width="full" gap={4}>
      <Heading size="xs">
        <FormattedMessage
          id="security.permissions.heading"
          description="Heading for permissions overview section"
          defaultMessage="Permissions"
        />
      </Heading>
      <LoginScopeSummary scopes={tool.scope_summary} />

      <Heading size="xs">
        {data ? (
          <FormattedMessage
            id="security.activity.table.heading.withCount"
            description="Heading for login activity table on tool page"
            defaultMessage="App Activity ({total})"
            values={{ total: data.total }}
          />
        ) : (
          <FormattedMessage
            id="security.activity.table.heading"
            description="Heading for login activity table on tool page"
            defaultMessage="App Activity"
          />
        )}
      </Heading>

      <TablePageHeader
        tableId={TOOL_LOGINS_TABLE_ID}
        selectedColumns={shownColumns}
        filterMap={tableParams.filterMap}
        onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
        objectSchema={loginSchema}
        afterSavedView={
          <SchemaTableColumnCustomization
            objectSchema={loginSchema}
            primaryColumn={undefined}
            activeColumns={shownColumns}
            onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
          />
        }
      />
      {data ? (
        <DataTable
          borderWidth="1px"
          overflow="auto"
          data={data.logins}
          columns={columns}
          sorting={tableParams.sorting}
          onSortingChange={(sorting) =>
            sorting instanceof Function
              ? updateTableParams({ sorting: sorting(tableParams.sorting) })
              : updateTableParams({ sorting })
          }
          paginationProps={{
            page: tableParams.page,
            pageSize: tableParams.pageSize,
            onPageChange: (page) => updateTableParams({ page }),
            onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
            totalListElements: data.total,
          }}
        />
      ) : (
        !isFetching &&
        tableParams.filterMap.size === 0 && (
          <EmptyTableState
            emptyState={intl.formatMessage({
              id: "tool.security.emptyState",
              description: "Text to display instead of the security table when there is no data for the tool",
              defaultMessage:
                "No security data found. This tool does not require any permissions or your single sign-on provider is not integrated with BRM",
            })}
          />
        )
      )}
    </Stack>
  )
}
