import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import { isFrequencyOrOneTimeType, isWorkflowRunProgressType } from "@brm/schema-helpers/schema.js"
import type { FilterField } from "@brm/schema-types/types.js"
import {
  BOOLEAN_FALSE_URL_VALUE,
  BOOLEAN_TRUE_URL_VALUE,
  NULL_URL_VALUE,
  valuesForFilterField,
} from "@brm/type-helpers/filters.js"
import { isCurrencyAmountType, isSpendSummaryType, isStdObjSchema } from "@brm/type-helpers/schema.js"
import { formatCurrency, formatCurrencyRange } from "@brm/util/currency/format.js"
import { safeBig } from "@brm/util/currency/parse.js"
import {
  formatDate,
  formatDateRange,
  formatDuration,
  formatDurationRange,
  getOneTimeFrequencyDisplayName,
} from "@brm/util/format-date-time.js"
import { getEnumOptions, getTitle, isEnumArrayType, isEnumType } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import { Badge, Text } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { isPresent } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import { USD } from "../../../util/constant.js"
import { log } from "../../../util/logger.js"
import { ObjectReferenceAppliedFilterValue } from "./ObjectReferenceAppliedFilterValue.js"
import { getDateFilterOptions } from "./options.js"

/**
 * This component is used to render what is shown in the filter header as a summary of the selected values.
 */
export default function AppliedFilterValue({
  fieldSchema,
  filter,
}: {
  fieldSchema: ReadonlyDeep<JSONSchema>
  filter: FilterField
}) {
  const intl = useIntl()

  const values = valuesForFilterField(filter)

  // Most filter types share the same logic for rendering the exists filter
  if (filter?.comparator === "exists") {
    return filter.value ? (
      <FormattedMessage
        defaultMessage="Present"
        description="Used to display text showing that a table is being filtered for rows that have a value in a column"
        id="filterSummary.existsFilter.true"
      />
    ) : (
      <FormattedMessage
        defaultMessage="Not Assigned"
        description="Used to display text showing that a table is being filtered for rows that do not have a value in a column"
        id="filterSummary.existsFilter.false"
      />
    )
  }

  if (!isObject(fieldSchema)) {
    return null
  }

  if (isStdObjSchema(fieldSchema) || isStdObjSchema(fieldSchema.items)) {
    if (filter.comparator !== "any" && filter.comparator !== "arr_contains") {
      throw new Error(`Filter of type "${filter.comparator}" cannot be applied to object reference fields`)
    }
    return <ObjectReferenceAppliedFilterValue filter={filter} fieldSchema={fieldSchema} />
  }
  if (isCurrencyAmountType(fieldSchema) || isSpendSummaryType(fieldSchema)) {
    let moneyText: string | undefined
    switch (filter?.comparator) {
      case "range_within":
      case "between": {
        moneyText = formatCurrencyRange(intl, filter.minValue, filter.maxValue, USD) ?? "…"
        break
      }
      case "lte":
      case "gt":
      case "range_gte":
      case "gte":
      case "lt": {
        moneyText = formatCurrency({ amount: filter.value, currency_code: USD }, intl)
        break
      }
    }
    return <Text fontWeight="medium">{moneyText}</Text>
  }
  if (isFrequencyOrOneTimeType(fieldSchema)) {
    let durationText: string | undefined
    switch (filter?.comparator) {
      case "between": {
        durationText = formatDurationRange(intl, filter.minValue, filter.maxValue)
        break
      }
      case "lte":
      case "gt":
      case "gte":
      case "lt": {
        durationText = formatDuration(intl, filter.value)
        break
      }
      case "eq": {
        durationText = getOneTimeFrequencyDisplayName(intl)
        break
      }
    }
    return <Text fontWeight="medium">{durationText}</Text>
  }
  if (fieldSchema.type === "string") {
    if (fieldSchema.format === "date") {
      let dateText: string | undefined
      switch (filter?.comparator) {
        case "eq": {
          const options = getDateFilterOptions(fieldSchema, intl)
          dateText = Object.hasOwn(options, filter.value)
            ? options[filter.value as keyof typeof options].displayName
            : filter.value
          break
        }
        case "between": {
          dateText = formatDateRange(intl, filter.minValue, filter.maxValue)
          break
        }
        case "lte":
        case "gt":
        case "gte":
        case "lt": {
          dateText = formatDate(intl, filter.value)
          break
        }
      }
      return <Text fontWeight="medium">{dateText}</Text>
    }
    if (fieldSchema.format === "duration") {
      let durationText: string | undefined
      switch (filter?.comparator) {
        case "between": {
          durationText = formatDurationRange(intl, filter.minValue, filter.maxValue)
          break
        }
        case "lte":
        case "gt":
        case "gte":
        case "lt": {
          durationText = formatDuration(intl, filter.value)
          break
        }
      }
      return <Text fontWeight="medium">{durationText}</Text>
    }
    return <Text fontWeight="medium">{values[0]}</Text>
  }
  if (isRichTextType(fieldSchema)) {
    return <Text fontWeight="medium">{values[0]}</Text>
  }
  if (fieldSchema.type === "number" || fieldSchema.type === "integer") {
    const numberFormatter = new Intl.NumberFormat(intl.locale, { style: fieldSchema.numberStyle })
    let numberText: string | undefined
    switch (filter?.comparator) {
      case "between": {
        // The filter state currently stores percentages as a number between 0-100 (even though to the backend, we send a number between 0-1)
        const minValue = safeBig(filter.minValue)?.div(fieldSchema.numberStyle === "percent" ? 100 : 1)
        const maxValue = safeBig(filter.maxValue)?.div(fieldSchema.numberStyle === "percent" ? 100 : 1)

        numberText =
          minValue && maxValue
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              numberFormatter.formatRange(minValue.toString() as any, maxValue.toString() as any)
            : "…"
        break
      }
      case "lte":
      case "gt":
      case "gte":
      case "lt": {
        const value = safeBig(filter.value)?.div(fieldSchema.numberStyle === "percent" ? 100 : 1)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        numberText = value ? numberFormatter.format(value as any) : "…"
        break
      }
    }
    return <Text fontWeight="medium">{numberText}</Text>
  }
  if (fieldSchema.type === "boolean") {
    return (
      <Text fontWeight="medium">
        {intl.formatList(
          values
            .map((value): string | undefined => {
              switch (value) {
                case BOOLEAN_TRUE_URL_VALUE:
                  return intl.formatMessage({
                    id: "booleanFilter.value.yes",
                    description: "Text in a filter summary indicating yes",
                    defaultMessage: "Yes",
                  })
                case BOOLEAN_FALSE_URL_VALUE:
                  return intl.formatMessage({
                    id: "booleanFilter.value.no",
                    description: "Text in a filter summary indicating no",
                    defaultMessage: "No",
                  })
                case NULL_URL_VALUE:
                  return intl.formatMessage({
                    defaultMessage: "Not Assigned",
                    description:
                      "Used to display text showing that a table is being filtered for rows that do not have a value in a column",
                    id: "filterSummary.existsFilter.false",
                  })
                default:
                  return undefined
              }
            })
            .filter(isPresent),
          { type: "disjunction" }
        )}
      </Text>
    )
  }
  const schemaWithValue = { schema: fieldSchema }
  if (isEnumType(schemaWithValue) || isEnumArrayType(schemaWithValue) || isWorkflowRunProgressType(schemaWithValue)) {
    if (values.length === 0)
      return (
        <Text fontWeight="medium">
          <FormattedMessage
            defaultMessage="0 selected"
            id="filterSummary.enumSelector.emptyState"
            description="Text shown in a filter summary when there is nothing selected"
          />
        </Text>
      )
    const enumOptions = getEnumOptions(
      isWorkflowRunProgressType(schemaWithValue) ? schemaWithValue.schema.properties.status : schemaWithValue.schema
    )
    return values.map((value) => {
      if (value === NULL_URL_VALUE) {
        return (
          <FormattedMessage
            key={value}
            defaultMessage="Not Assigned"
            id="filterSummary.enumSelector.null"
            description="Text shown in a filter summary when a null value is selected"
          />
        )
      }
      const enumMemberSchema = enumOptions.find((s) => s.const === value)
      return (
        <Badge key={value} colorScheme={enumMemberSchema?.colorScheme} variant="subtleOutlined">
          {getTitle(value, enumMemberSchema)}
        </Badge>
      )
    })
  }

  log.warn("Unknown applied filter field schema", { fieldSchema, filter })
  return <FormattedList value={values} />
}
