import type { ToolStatus } from "@brm/schema-types/types.js"
import { ToolStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayToolStatus, toolStatusColors } from "./constants.js"

interface Props {
  status: ToolStatus
  /** If editing is enabled, a toolId needs to be provided */
  editToolId?: string
}

export default function ToolStatusBadge({ status, editToolId }: Props) {
  return (
    <UpdateStatusBadge<ToolStatus, "status">
      status={status}
      fieldName="status"
      objectType="Tool"
      objectId={editToolId}
      options={ToolStatusSchema.anyOf.map((s) => s.const)}
      statusColors={toolStatusColors}
      displayStatus={displayToolStatus}
      updatePermissions={["tool:update"]}
    />
  )
}
