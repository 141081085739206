import { Box, HStack, List, Tag, Tooltip } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

interface Props {
  displayNames: string[]
  limit?: number
}

/**
 * Renders a list of tags as a group with a max amount of tags.
 *
 * Also forces the text inside tags to tuncate instead of wrapping/overflowing.
 */
export default function TagGroup({ displayNames, limit }: Props) {
  return (
    <HStack flexWrap="wrap">
      {displayNames.slice(0, limit).map((displayName) => (
        <Tag flexShrink={0} size="sm" key={displayName} variant="outline" isTruncated>
          {displayName}
        </Tag>
      ))}
      {limit && displayNames.length > limit && (
        <Tooltip
          label={
            <List>
              {displayNames.slice(limit).map((displayName) => (
                <Box key={displayName}>{displayName}</Box>
              ))}
            </List>
          }
          shouldWrapChildren
        >
          <Tag size="sm" variant="outline" isTruncated>
            <FormattedMessage
              id="tag.group.more"
              description="Title of a tag that shows the additional hidden tags amount"
              defaultMessage="+{count}"
              values={{ count: displayNames.length - limit }}
            />
          </Tag>
        </Tooltip>
      )}
    </HStack>
  )
}
