import type { ToolFinanceStatus } from "@brm/schema-types/types.js"
import { ToolFinanceStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayToolFinanceStatus, toolFinanceColors } from "./constants.js"

interface Props {
  status: ToolFinanceStatus | undefined
  /** If editing is enabled, a toolId needs to be provided */
  editToolId?: string
}

export default function ToolFinanceStatusBadge({ status, editToolId }: Props) {
  if (!status) {
    return undefined
  }
  return (
    <UpdateStatusBadge<ToolFinanceStatus, "finance_status">
      status={status}
      fieldName="finance_status"
      objectType="Tool"
      objectId={editToolId}
      options={ToolFinanceStatusSchema.anyOf.map((s) => s.const)}
      statusColors={toolFinanceColors}
      displayStatus={displayToolFinanceStatus}
      updatePermissions={["tool:update", "finance:update"]}
    />
  )
}
