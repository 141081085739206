import type {
  DurationString,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  LegalClausesFieldsMetadata,
} from "@brm/schema-types/types.js"
import { LegalClausesSchema } from "@brm/schemas"
import { formatDate, formatDuration } from "@brm/util/format-date-time.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Box,
  Center,
  HStack,
  Icon,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Text,
  Tooltip,
  chakra,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { capitalCase } from "change-case"
import { includeKeys } from "filter-obj"
import type { ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import braimLogo from "../../../assets/braim.svg"
import { getDisplayUrl } from "../../util/url.js"
import { Link } from "../Link.js"
import { RenderedMarkdown } from "../RenderedMarkdown.js"
import { LinkSourceIcon, ListingInformationIcon, SORIcon, ThreeStarsIcon, UserIcon } from "../icons/icons.js"
import { FIELD_SOURCE_COLORS } from "./utils.js"

const MAX_DURATION = "PT5M"
const ZERO_DURATION = "PT0M"
const DEFAULT_MIN_DURATION = "PT2M"

export default function FieldSourceIcon({
  fieldMetadata,
  boxSize,
  fieldLabel,
  fieldIcon,
}: {
  fieldMetadata: FieldMetadataWithSuggestions | LegalClausesFieldsMetadata
  boxSize?: number
  fieldLabel?: string
  fieldIcon?: ReactNode
}) {
  const intl = useIntl()

  let type
  if (!("type" in fieldMetadata)) {
    const clausesMetadata = includeKeys(fieldMetadata, (k) => k in LegalClausesSchema.properties)
    const fieldSourceTypeSet = new Set(
      (Object.values(clausesMetadata) as FieldSourceOutputProperties[]).map((source) => source.type)
    )
    if (fieldSourceTypeSet.size === 0) {
      return null
    }
    if (fieldSourceTypeSet.size === 1) {
      const [first] = fieldSourceTypeSet
      type = first
    } else {
      // Find the first none empty icon type when there are multiple types
      type = Array.from(fieldSourceTypeSet).find((type) => type !== "user")
    }
  } else {
    type = fieldMetadata.type
  }

  if (!type) {
    return null
  }

  // Short circuit for new AI citations popover
  if (type === "listing" && fieldMetadata.rationale && fieldMetadata.links && fieldMetadata.read_time_estimate) {
    let cappedDuration: DurationString = fieldMetadata.read_time_estimate
    if (Temporal.Duration.compare(fieldMetadata.read_time_estimate, MAX_DURATION) > 0) {
      cappedDuration = MAX_DURATION
    }

    if (Temporal.Duration.compare(fieldMetadata.read_time_estimate, ZERO_DURATION) === 0) {
      cappedDuration = DEFAULT_MIN_DURATION
    }

    return (
      <Popover trigger="hover" placement="right-start">
        <PopoverTrigger>
          <Image
            src={braimLogo}
            height={boxSize ? boxSize : 4}
            opacity={0.85}
            display="inline-flex"
            alignItems="center"
          />
        </PopoverTrigger>

        <Portal>
          <PopoverContent bottom={3} width="md">
            <PopoverBody display="flex" flexDirection="column" padding={3}>
              <Stack direction="row" gap={2}>
                {fieldIcon}
                <Center>
                  <Text fontSize="md" fontWeight="semibold">
                    {fieldLabel}
                  </Text>
                </Center>
                <Spacer />
                {fieldMetadata?.source_updated_at ? (
                  <Center>
                    <Text color="gray.600">{`${intl.formatMessage({
                      id: "field.source.listing.popover.source_updated_at",
                      description: "The date the listing citation was updated",
                      defaultMessage: "Updated",
                    })} ${formatDate(intl, fieldMetadata.source_updated_at)}`}</Text>
                  </Center>
                ) : null}
              </Stack>

              <Text mt={4}>
                <FormattedMessage
                  id="field.source.listing.popover.summary"
                  description="Tooltip text on BRM global listing field source indicator"
                  defaultMessage="Summary"
                />
              </Text>

              <Box color="gray.600">
                <RenderedMarkdown content={fieldMetadata.rationale} />
              </Box>

              {fieldMetadata.links.length > 0 && (
                <>
                  <Text mt={4}>
                    <FormattedMessage
                      id="field.source.listing.popover.browsed-links"
                      description="Tooltip text on BRM global listing field source indicator"
                      defaultMessage="Browsed Links"
                    />
                  </Text>

                  <Stack gap={0}>
                    {fieldMetadata.links.map((link) => (
                      <Link
                        key={link}
                        to={link}
                        variant="highlighted"
                        target="_blank"
                        rel="noreferrer"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        _hover={{ textDecoration: "underline" }}
                      >
                        {getDisplayUrl(new URL(link))}
                      </Link>
                    ))}
                  </Stack>
                </>
              )}

              <Text mt={4}>
                <FormattedMessage
                  id="field.source.listing.popover.estimated_time_saved"
                  description="Tooltip text on BRM global listing field source indicator"
                  defaultMessage="Estimated Time Saved"
                />
              </Text>

              <Text color="gray.600">{formatDuration(intl, cappedDuration)}</Text>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    )
  }

  const getIcon = (fieldMetadata: FieldSourceOutputProperties) => {
    const { type, assigned_by_metadata } = fieldMetadata
    if (!type) {
      return null
    }
    const color = `${FIELD_SOURCE_COLORS[type]}.700`
    switch (type) {
      case "link":
        return <Icon as={LinkSourceIcon} color={color} boxSize={boxSize} />
      case "listing":
        return <Icon as={ListingInformationIcon} color={color} boxSize={boxSize} />
      case "sor":
        return <Icon as={SORIcon} boxSize={boxSize} />
      case "document":
      case "transaction":
      case "derived":
        return <Icon as={ThreeStarsIcon} color={color} boxSize={boxSize} />
      case "user":
        return assigned_by_metadata ? <Icon as={UserIcon} color={color} boxSize={boxSize} /> : null
      default:
        return unreachable(type)
    }
  }

  const icon = getIcon(fieldMetadata)
  let tooltipText: string | null = null
  switch (type) {
    case "link":
      tooltipText = intl.formatMessage({
        id: "field.source.link.tooltip",
        description: "Tooltip text on BRM link field source indicator",
        defaultMessage: "Data gathered externally through BRM Link",
      })
      break
    case "listing":
      tooltipText = intl.formatMessage({
        id: "field.source.listing.tooltip",
        description: "Tooltip text on BRM global listing field source indicator",
        defaultMessage: "Data from a BRM standard listing",
      })
      break
    case "sor":
      tooltipText = intl.formatMessage({
        id: "field.source.sor.tooltip",
        description: "Tooltip text on BRM sor field source indicator",
        defaultMessage: "Data from your organization",
      })
      break
    case "document":
    case "transaction":
      tooltipText = intl.formatMessage({
        id: "field.source.extraction.tooltip",
        description: "Tooltip text on BRM extraction field source indicator",
        defaultMessage: "Data automagically extracted by BRM",
      })
      break
    case "derived":
      if (fieldMetadata.derived_from) {
        tooltipText = intl.formatMessage(
          {
            id: "field.source.derived.tooltip",
            description: "Tooltip text on BRM derived field source indicator",
            defaultMessage: "Data derived from {derivedValues}",
          },
          // TODO pass in parentSchema to get the field name's title
          { derivedValues: intl.formatList(fieldMetadata.derived_from.map((field) => capitalCase(field))) }
        )
      }
      break
    case "user":
      tooltipText = fieldMetadata.assigned_by_metadata
        ? intl.formatMessage(
            {
              id: "field.source.assignedByUser.tooltip",
              description: "Tooltip text on BRM assigned by user field source indicator",
              defaultMessage: "Manually assigned by {user}",
            },
            {
              user: fieldMetadata.source_display_name,
            }
          )
        : null
      break
    default:
      unreachable(type)
  }

  if (!icon || !tooltipText) {
    return null
  }

  return (
    <Tooltip
      label={
        <HStack>
          {icon}
          <Text>{tooltipText}</Text>
        </HStack>
      }
    >
      <chakra.span display="inline-flex" alignItems="center" height="1lh">
        {icon}
      </chakra.span>
    </Tooltip>
  )
}
