/** This also determines the ordering of the tabs */
export const vendorTabs = ["overview", "general", "compliance", "it", "finance", "legal", "transactions"] as const
export type VendorTab = (typeof vendorTabs)[number]

export const vendorTabsSet = new Set<string>(vendorTabs)

export function isVendorTab(tab: string): tab is VendorTab {
  return vendorTabsSet.has(tab)
}

export const VENDOR_TIMELINE_HIDE_STORAGE_KEY = "vendor-timeline-hidden"
