import type { IntegrationPublicFields } from "@brm/schema-types/types.js"
import { Box, HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import { LinkOrSpan } from "../../Link.js"
import { IntegrationProviderIcon } from "../../icons/IntegrationProviderIcon.js"
import { LinkExternalIcon } from "../../icons/icons.js"

interface Props {
  integration?: Pick<
    IntegrationPublicFields,
    "provider" | "display_name" | "merge_hris_type" | "merge_accounting_type"
  > | null
  onlyIcon?: boolean
  link?: string
}

export default function IntegrationCell({ integration, onlyIcon, link }: Props) {
  if (!integration?.provider) {
    return null
  }

  const icon = <IntegrationProviderIcon boxSize="6" integration={integration} />

  return (
    <HStack as={LinkOrSpan} to={link} gap={1}>
      {/* If the integration has a data source (it is a unified api provider) the data source takes precedence */}
      {onlyIcon ? (
        <Tooltip label={integration.display_name}>
          <Box>{icon}</Box>
        </Tooltip>
      ) : (
        icon
      )}
      {!onlyIcon && <Text>{integration.display_name}</Text>}
      {link && <Icon as={LinkExternalIcon} />}
    </HStack>
  )
}
