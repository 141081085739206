import { displayPersonName } from "@brm/util/names.js"
import { Avatar, Box, Button, Card, CardBody, Divider, Grid, GridItem, Text } from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import type { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import placeHolderAvatar from "../../../assets/place_holder_avatar_01.png"
import { Link } from "../../components/Link.js"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor.js"
import { timelineEventPrimaryIconBoxSize } from "../../components/Timeline/common.js"
import { Timestamp } from "../../components/Timestamp.js"
import { timelineGridGapPx, timelineIconsWidthPx } from "../timeline/constants.js"

const MockedCommentSection = () => {
  const { code } = useParams<{ code: string }>()

  return (
    <Box position="relative" paddingTop={4}>
      <Grid
        pointerEvents="none"
        userSelect="none"
        filter="blur(8px)"
        as="ul"
        templateColumns={`[icons] ${timelineIconsWidthPx}px [text] minmax(0, 1fr) [integration] auto`}
        columnGap={`${timelineGridGapPx}px`}
      >
        <MockedComment commentText="Lorem ipsum dolor sit amet, id paulo iriure lobortis pro. Id pro dicit habemus suavitate. No duo quod soleat, eu prima labore mediocritatem pro. Ex mea sint tib" />
        <MockedComment
          commentText="Lorem ipsum dolor sit amet, id paulo iriure lobortis pro. Id pro dicit habemus suavitate. No duo quod soleat, eu prima labore mediocritatem pro. Ex mea sint tibique.
        
        Id pro dicit habemus suavitate. No duo quod soleat, eu prima labore mediocritatem pro. Ex mea sint tibique"
        />
      </Grid>
      <Card
        borderWidth="thin"
        borderColor="gray.200"
        boxShadow="xl"
        borderRadius="lg"
        position="absolute"
        left="0"
        top="30%"
        right="0"
        width="15rem"
        height="5rem"
        background="white"
        marginX="auto"
      >
        <CardBody display="flex" alignItems="center" justifyContent="center">
          <Button as={Link} to={`/create_account/${code}`} variant="solid" colorScheme="brand" size="sm">
            <FormattedMessage
              defaultMessage="Sign in to see comments"
              description="Button text for signing in to see comments"
              id="mockedCommentSection.button.signInToSeeComments"
            />
          </Button>
        </CardBody>
      </Card>
    </Box>
  )
}

export default MockedCommentSection

interface CommentForLoggedOutUserProps {
  commentText: string
}

const MockedComment: FC<CommentForLoggedOutUserProps> = (props) => {
  const { commentText } = props
  const intl = useIntl()
  const personName = displayPersonName({ id: "1", first_name: "Jackson", last_name: "Reed" }, intl)
  return (
    <chakra.li className="event-item" display="contents">
      <GridItem justifySelf="center" placeItems="center" display="flex" whiteSpace="nowrap">
        <Avatar boxSize={timelineEventPrimaryIconBoxSize} name={personName} src={placeHolderAvatar} />
      </GridItem>
      <GridItem display="flex" placeItems="center">
        <Box w="full">
          <Text as="span" color="gray.600" mr={1}>
            <Link fontWeight="medium" to="/">
              {personName}
            </Link>
          </Text>
          {/* Timestamp */}
          <Text as="span" fontSize="xs" color="gray.600">
            <Timestamp dateTime="2024-05-17T16:55:08.840753+00:00" />
          </Text>
        </Box>
      </GridItem>
      <GridItem display="flex" justifyContent="center" py={1}>
        <Divider borderColor="gray.300" orientation="vertical" minH={2} />
      </GridItem>
      <GridItem gridColumn="2 / span 2" mt={1} mb={0}>
        <Box mb={5} className="comment-body">
          <Box position="relative" minW={0} flex={1}>
            <RichTextEditor
              initialValue={[
                {
                  type: "paragraph",
                  children: [
                    {
                      text: commentText,
                    },
                  ],
                },
              ]}
              isReadOnly
            />
          </Box>
        </Box>
      </GridItem>
    </chakra.li>
  )
}
