import { maxValueFromFilterField, minValueFromFilterField } from "@brm/type-helpers/filters.js"
import { Flex, Icon, chakra } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { InputRange } from "../../../Form/InputRange.js"
import { CheckIcon } from "../../../icons/icons.js"
import { ListBox, ListBoxItem } from "../../../ListBox.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC, formatComparator } from "../constants.js"
import { getDateFilterOptions } from "../options.js"
import type { FilterProps } from "./types.js"

/** Filters are always in a menu so components like MenuItems must be used instead of Buttons */
export default function DateRangeFilter({ filter, fieldSchema, isNullable, displayPath, onChange }: FilterProps) {
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")

  const intl = useIntl()

  // Normally dates are compared using filters like "gt" or "lt" or "between", but dates that come from the selection record will
  // save a filter that is "eq" to keys like "this_week" or "this_month". The "eq" filters will get translated to a date range in the
  // packageSortFilterOptionsForAPI function. This allows the filter "this_week" to always be relative to the current week.
  const checkboxValue = filter?.comparator === "eq" ? filter.value : undefined

  const options = getDateFilterOptions(fieldSchema, intl)

  const [minValue, setMinValue] = useState(minValueFromFilterField(filter))
  const [maxValue, setMaxValue] = useState(maxValueFromFilterField(filter))

  const handleChange = () => {
    setMinValue(minValue ?? "")
    setMaxValue(maxValue ?? "")
    if (minValue && maxValue) {
      onChange({ comparator: "between", minValue, maxValue })
    } else if (minValue) {
      onChange({ comparator: "gte", value: minValue })
    } else if (maxValue) {
      onChange({ comparator: "lte", value: maxValue })
    }
  }

  const isNullChecked = filter?.comparator === "exists" ? !filter.value : false

  const nullValueLabel = intl.formatMessage({
    id: "menu.item.null",
    defaultMessage: "Not Assigned",
    description: "Option for a menu item that represents searching for rows without a value",
  })

  return (
    <Flex flexDir="column" maxH={FILTER_POPOVER_MAX_HEIGHT_CALC} overflow="auto">
      <ListBox
        aria-label={intl.formatMessage({
          id: "filter.date.menu.ariaLabel",
          defaultMessage: "Date filter menu",
          description: "Aria label for the date filter menu",
        })}
        autoFocus
      >
        {isNullable && (
          <ListBoxItem
            textValue={nullValueLabel}
            onAction={() => onChange({ comparator: "exists", value: false })}
            justifyContent="space-between"
          >
            {nullValueLabel}
            {isNullChecked && <Icon as={CheckIcon} />}
          </ListBoxItem>
        )}
        {Object.entries(options).map(([key, value]) => {
          const fieldComparatorLabel = `${formatComparator({ comparator: "eq", value: key }, fieldSchema, intl)} ${value.displayName}`
          return (
            <ListBoxItem
              key={key}
              textValue={fieldComparatorLabel}
              // This will get translated to an actual date in packageSortFilterOptionsForAPI
              onAction={() => onChange({ comparator: "eq", value: key })}
            >
              <chakra.span _firstLetter={{ textTransform: "capitalize" }}>{fieldComparatorLabel}</chakra.span>
              {checkboxValue === key && <Icon as={CheckIcon} ml="auto" />}
            </ListBoxItem>
          )
        })}
      </ListBox>
      <InputRange
        borderTopWidth={1}
        p={3}
        leftInputProps={{
          type: "date",
          width: 140,
          max: maxValue,
          value: minValue,
          onChange: (e) => {
            setMinValue(e.target.value)
          },
          onBlur: handleChange,
          label: (
            <FormattedMessage
              defaultMessage="After"
              description="Label for the date input that sets the minimum date for a date range filter"
              id="filter.date.after"
            />
          ),
        }}
        rightInputProps={{
          type: "date",
          width: 140,
          min: minValue,
          value: maxValue,
          onChange: (e) => {
            setMaxValue(e.target.value)
          },
          onBlur: handleChange,
          label: (
            <FormattedMessage
              defaultMessage="Before"
              description="Label for the date input that sets the maximum date for a date range filter"
              id="filter.date.before"
            />
          ),
        }}
      />
    </Flex>
  )
}
