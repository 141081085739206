import { Flex, useDisclosure } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { useEffect, useMemo } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { SAVED_PATH } from "../../util/constant.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import NavSideBar from "../Nav/NavSideBar.js"
import { NAVBAR_WIDTH } from "../Nav/constants.js"
import { TrialExpiredModal } from "../TrialExpiredModal.js"

declare global {
  interface Window {
    pylon: unknown
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Pylon?: ((action: "show" | "hide") => void) | undefined
    pendo?: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BuildingBlocks?: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        BuildingBlockResourceCenter?: {
          getResourceCenter: () => { show: () => void }
          dismissResourceCenter: () => void
        }
      }
    }
  }
}

export default function LoggedInContainer() {
  const navigate = useNavigate()
  const trialExpiredModal = useDisclosure({ defaultIsOpen: true })

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const trialExpired = useMemo(
    () =>
      !!whoami?.organization.trial_expires_at &&
      Temporal.Instant.compare(whoami.organization.trial_expires_at, Temporal.Now.instant()) < 0,
    [whoami]
  )
  const trialExpiredModalIsOpen = trialExpired && trialExpiredModal.isOpen

  // Redirect users to the seller container if they are a seller
  useEffect(() => {
    if (whoami && whoami.organization.is_seller && !whoami.organization.is_buyer) {
      navigate("/seller")
    }
  }, [navigate, whoami])

  // Enable Pylon Chat
  useEffect(() => {
    if (whoami) {
      window.pylon = {
        chat_settings: {
          app_id: import.meta.env.VITE_PYLON_APP_ID,
          email: whoami.email,
          name: whoami.person.display_name,
          avatar_url: getPublicImageGcsUrl(whoami.profile_image?.gcs_file_name),
        },
      }
    }
  }, [whoami])

  useEffect(() => {
    const savedPath = window.sessionStorage.getItem(SAVED_PATH)
    if (savedPath === null) return
    window.sessionStorage.removeItem(SAVED_PATH)
    const url = new URL(savedPath)
    navigate({
      pathname: url.pathname,
      search: url.search,
      hash: url.hash,
    })
  }, [navigate])

  return (
    <>
      <Flex height="100%">
        <NavSideBar />
        <Flex flexDir="column" flexGrow={1} flexShrink={1} minW={0} left={`${NAVBAR_WIDTH}px`}>
          <Outlet />
        </Flex>
      </Flex>
      {trialExpiredModalIsOpen && <TrialExpiredModal {...trialExpiredModal} isOpen={trialExpiredModalIsOpen} />}
    </>
  )
}
