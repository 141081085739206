import type { IntegrationSummary } from "@brm/schema-types/types.js"
import { formatDateRange, formatDateTime } from "@brm/util/format-date-time.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Badge, Stack, Tag, Text, Tooltip } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, useIntl } from "react-intl"

interface Props {
  integration: IntegrationSummary
}

const getCrawlerStatusText = (crawlerStatus: IntegrationSummary["status"], intl: IntlShape) => {
  switch (crawlerStatus) {
    case "connected":
      return (
        <Badge colorScheme="green" size="sm">
          <FormattedMessage
            id="table.cell.integration.status.success"
            description="Integration table status cell success"
            defaultMessage="Success"
          />
        </Badge>
      )
    case "failing":
      return (
        <Tooltip
          label={intl.formatMessage({
            id: "table.cell.integration.status.tooltip.failing",
            description: "The tooltip for the tag that explains the failing status of an integration",
            defaultMessage:
              "The integration is currently experiencing errors. These issues are likely temporary and will be resolved automatically.",
          })}
          shouldWrapChildren
        >
          <Badge colorScheme="yellow" size="sm">
            <FormattedMessage
              id="table.cell.integration.status.failing"
              description="Integration table status cell failing"
              defaultMessage="Error Encountered"
            />
          </Badge>
        </Tooltip>
      )
    case "disconnected":
      return (
        <Tooltip
          label={intl.formatMessage({
            id: "table.cell.integration.status.tooltip.disconnected",
            description: "The tooltip for the tag that explains the disconnected status of an integration",
            defaultMessage:
              "The integration has failed and won’t retrieve new data unless reconnected by an administrator. Contact support if you need help reconnecting your integration.",
          })}
          shouldWrapChildren
        >
          <Badge colorScheme="error" size="sm">
            <FormattedMessage
              id="table.cell.integration.status.disconnected"
              description="Integration table status cell disconnected"
              defaultMessage="Disconnected"
            />
          </Badge>
        </Tooltip>
      )
    case "connecting":
      return (
        <Tooltip
          label={intl.formatMessage({
            id: "table.cell.integration.status.tooltip.connecting",
            description: "The tooltip for the tag that explains the connecting status of an integration",
            defaultMessage: "Connecting integrations are waiting for the data source to become ready for crawling.",
          })}
          shouldWrapChildren
        >
          <Badge colorScheme="yellow" size="sm">
            <FormattedMessage
              id="table.cell.integration.status.connecting"
              description="Integration table status cell connecting"
              defaultMessage="Connecting"
            />
          </Badge>
        </Tooltip>
      )
    case "disabled":
      return (
        <Badge colorScheme="gray" size="sm">
          <FormattedMessage
            id="table.cell.integration.status.disabled"
            description="Integration table status cell disabled"
            defaultMessage="Disabled"
          />
        </Badge>
      )
    case "deleting":
      return (
        <Tooltip
          label={intl.formatMessage({
            id: "table.cell.integration.status.tooltip.deleting",
            description: "The tooltip for the tag that explains the deleting status of an integration",
            defaultMessage: "Integration will be removed after all data is deleted.",
          })}
          shouldWrapChildren
        >
          <Tag colorScheme="error" size="sm">
            <FormattedMessage
              id="table.cell.integration.status.deleting"
              description="Integration table status cell deleting"
              defaultMessage="Deleting"
            />
          </Tag>
        </Tooltip>
      )
    case "resetting":
      return (
        <Tooltip
          label={intl.formatMessage({
            id: "table.cell.integration.status.tooltip.resetting",
            description: "The tooltip for the tag that explains the resetting status of an integration",
            defaultMessage:
              "Integration is resetting to get the most updated data, it will be fully reconnected shortly.",
          })}
          shouldWrapChildren
        >
          <Tag colorScheme="gray" size="sm">
            <FormattedMessage
              id="table.cell.integration.status.resetting"
              description="Integration table status cell resetting"
              defaultMessage="Resetting"
            />
          </Tag>
        </Tooltip>
      )
    default:
      unreachable(crawlerStatus)
  }
}

export default function IntegrationStatusCell({ integration }: Props) {
  const intl = useIntl()
  const { status, crawler_last_success, crawler_state, disabled_at } = integration

  return (
    <Stack justify="center" align="center">
      {getCrawlerStatusText(status, intl)}
      <Text color="muted" fontSize="xs">
        {disabled_at ? (
          <FormattedMessage
            id="table.cell.integration.status.disabled"
            description="formatted integration status cell"
            defaultMessage="Disabled: {disabledDateTime}"
            values={{
              disabledDateTime: formatDateTime(intl, disabled_at),
            }}
          />
        ) : crawler_state?.oldest_crawled_activity_date &&
          crawler_last_success &&
          // Merge integrations save the data range for the time merge ingested the data and not the time the actual transactions were created
          integration.provider !== "merge_accounting_link_token" ? (
          <FormattedMessage
            id="table.cell.integration.status.crawledRange"
            description="The time range the integration has crawled"
            defaultMessage="Gathered data from {dateRange}"
            values={{
              dateRange: formatDateRange(intl, crawler_state.oldest_crawled_activity_date, crawler_last_success),
            }}
          />
        ) : crawler_last_success ? (
          <FormattedMessage
            id="table.cell.integration.status.updated"
            description="formatted integration status cell"
            defaultMessage="Updated: {updatedDateTime}"
            values={{
              updatedDateTime: formatDateTime(intl, crawler_last_success),
            }}
          />
        ) : null}
      </Text>
    </Stack>
  )
}
