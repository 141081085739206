import type { Task } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { Location } from "react-router-dom"

export const getTaskPrimarySrcUrl = (task: Task) => {
  const { pathName, searchParams } = getTaskPathNameAndSearchParams(task)
  return `${pathName}?${searchParams.toString()}`
}

const getTaskPathNameAndSearchParams = (task: Task): { pathName: string; searchParams: URLSearchParams } => {
  switch (task.type) {
    case "workflow_champ":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        searchParams: new URLSearchParams({ task: task.type }),
      }
    case "workflow_step_gatherer":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        searchParams: new URLSearchParams({ step: task.pending_step_ids[0] || "", task: task.type }),
      }
    case "workflow_run_step_ready_for_review":
    case "workflow_run_aggregated_steps_ready_for_review":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        searchParams: new URLSearchParams({
          step: task.workflow_run_step_id,
          task: task.type,
        }),
      }
    case "workflow_field_gatherer":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        searchParams: new URLSearchParams({
          step: task.workflow_run_step_id,
          task: task.type,
          type: task.object_type,
          object: task.object_id,
          field: task.field_name,
        }),
      }
    default:
      unreachable(task)
  }
}

export const compareLocationToTaskUrl = (location: Location, task: Task) => {
  const { pathname, search } = location
  const searchParams = new URLSearchParams(search)
  const taskUrlData = getTaskPathNameAndSearchParams(task)
  let searchParamsMatch = true
  taskUrlData.searchParams.forEach((value, key) => {
    if (searchParams.get(key) !== value) {
      searchParamsMatch = false
    }
  })
  return pathname === taskUrlData.pathName && searchParamsMatch
}
