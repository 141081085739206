import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import {
  isDocumentOrURLStringType,
  isDocumentType,
  isFrequencyOrOneTimeType,
  isWorkflowRunProgressType,
} from "@brm/schema-helpers/schema.js"
import {
  isCurrencyAmountType,
  isPersonType,
  isSpendSummaryType,
  isStdObjSchema,
  isToolType,
  isUserType,
  isVendorType,
} from "@brm/type-helpers/schema.js"
import { isEnumArrayType, isEnumType, unwrapNullableSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { FunctionComponent, SVGProps } from "react"
import type { ReadonlyDeep } from "type-fest"
import { isCurrencyRangeType, isToolListingArrayType, isToolListingType } from "../../../util/json-schema.js"
import {
  CalendarIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentIcon,
  FilterBooleanIcon,
  FilterCurrencyIcon,
  FilterEnumArrayIcon,
  FilterEnumIcon,
  FilterMiscIcon,
  FilterNumberIcon,
  FilterPercentIcon,
  FilterStandardObjectIcon,
  FilterStatusIcon,
  FilterTextIcon,
  FilterUriIcon,
  MailIcon,
  ToolIcon,
  UserIcon,
  UsersIcon,
  VendorIcon,
} from "../../icons/icons.js"

export const FieldSchemaIcon: FunctionComponent<
  { fieldSchema?: ReadonlyDeep<JSONSchema>; displayPath: string[] } & SVGProps<SVGSVGElement>
> = ({ fieldSchema, displayPath, ...props }) => {
  if (!isObject(fieldSchema)) {
    return null
  }
  fieldSchema = unwrapNullableSchema(fieldSchema)
  if (displayPath.at(-1)?.endsWith("status") || isWorkflowRunProgressType({ schema: fieldSchema })) {
    return <FilterStatusIcon {...props} />
  }
  if (displayPath.at(-1)?.endsWith("payment_methods")) {
    return <CreditCardIcon {...props} />
  }
  if (fieldSchema.type === "boolean") {
    return <FilterBooleanIcon {...props} />
  }
  if (isEnumType({ schema: fieldSchema })) {
    return <FilterEnumIcon {...props} />
  }
  if (isEnumArrayType({ schema: fieldSchema })) {
    return <FilterEnumArrayIcon {...props} />
  }
  if (fieldSchema.type === "number") {
    if (fieldSchema.numberStyle === "percent") {
      return <FilterPercentIcon {...props} />
    }
    return <FilterNumberIcon {...props} />
  }
  if (isPersonType(fieldSchema) || isUserType(fieldSchema)) {
    return <UserIcon {...props} />
  }
  if (isPersonType(fieldSchema.items) || isUserType(fieldSchema.items)) {
    return <UsersIcon {...props} />
  }
  if (
    isToolType(fieldSchema) ||
    isToolListingType(fieldSchema) ||
    isToolListingArrayType(fieldSchema) ||
    isToolType(fieldSchema.items)
  ) {
    return <ToolIcon {...props} />
  }
  if (isVendorType(fieldSchema)) {
    return <VendorIcon {...props} />
  }
  if (isDocumentOrURLStringType(fieldSchema) || isDocumentType(fieldSchema)) {
    return <DocumentIcon {...props} />
  }
  if (isStdObjSchema(fieldSchema) || isStdObjSchema(fieldSchema.items)) {
    return <FilterStandardObjectIcon {...props} />
  }
  if (isCurrencyAmountType(fieldSchema) || isCurrencyRangeType(fieldSchema) || isSpendSummaryType(fieldSchema)) {
    return <FilterCurrencyIcon {...props} />
  }
  if (isRichTextType(fieldSchema)) {
    return <FilterTextIcon {...props} />
  }
  if (fieldSchema.type === "integer") {
    return <FilterNumberIcon {...props} />
  }
  if (isFrequencyOrOneTimeType(fieldSchema)) {
    return <ClockIcon {...props} />
  }
  if (fieldSchema.type === "string") {
    if (fieldSchema.format === "date" || fieldSchema.format === "date-time") {
      return <CalendarIcon {...props} />
    }
    if (fieldSchema.format === "duration") {
      return <ClockIcon {...props} />
    }
    if (fieldSchema.format === "uri" || fieldSchema.format === "uri-reference" || fieldSchema.format === "hostname") {
      return <FilterUriIcon {...props} />
    }
    if (fieldSchema.format === "email") {
      return <MailIcon {...props} />
    }
    return <FilterMiscIcon {...props} />
  }
  return null
}
