import type { ImageProps } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import csvIcon from "../../../assets/file_icon/csv.svg"
import docIcon from "../../../assets/file_icon/doc.svg"
import docxIcon from "../../../assets/file_icon/docx.svg"
import fileIcon from "../../../assets/file_icon/file.svg"
import gifIcon from "../../../assets/file_icon/gif.svg"
import htmlIcon from "../../../assets/file_icon/html.svg"
import imgIcon from "../../../assets/file_icon/img.svg"
import jpegIcon from "../../../assets/file_icon/jpeg.svg"
import jsonIcon from "../../../assets/file_icon/json.svg"
import pdfIcon from "../../../assets/file_icon/pdf.svg"
import pngIcon from "../../../assets/file_icon/png.svg"
import pptIcon from "../../../assets/file_icon/ppt.svg"
import pptxIcon from "../../../assets/file_icon/pptx.svg"
import tiffIcon from "../../../assets/file_icon/tiff.svg"
import txtIcon from "../../../assets/file_icon/txt.svg"
import webpIcon from "../../../assets/file_icon/webp.svg"
import xlsIcon from "../../../assets/file_icon/xls.svg"
import xlsxIcon from "../../../assets/file_icon/xlsx.svg"

export interface FileTypeIconProps extends ImageProps {
  mimeType: string | null
}

export const FileTypeIcon: FunctionComponent<FileTypeIconProps> = ({ mimeType, ...props }) => {
  let iconSrc: string
  switch (mimeType) {
    case "application/pdf":
      iconSrc = pdfIcon
      break
    case "text/csv":
      iconSrc = csvIcon
      break
    case "text/plain":
      iconSrc = txtIcon
      break
    case "application/msword":
      iconSrc = docIcon
      break
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      iconSrc = docxIcon
      break
    case "application/vnd.ms-excel":
      iconSrc = xlsIcon
      break
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      iconSrc = xlsxIcon
      break
    case "image/jpeg":
      iconSrc = jpegIcon
      break
    case "image/png":
      iconSrc = pngIcon
      break
    case "application/vnd.ms-powerpoint":
      iconSrc = pptIcon
      break
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      iconSrc = pptxIcon
      break
    case "application/json":
      iconSrc = jsonIcon
      break
    case "image/webp":
      iconSrc = webpIcon
      break
    case "image/gif":
      iconSrc = gifIcon
      break
    case "text/html":
      iconSrc = htmlIcon
      break
    case "image/tiff":
      iconSrc = tiffIcon
      break
    default:
      if (mimeType?.startsWith("image/")) {
        iconSrc = imgIcon
      } else {
        iconSrc = fileIcon
      }
  }
  return <Image {...props} src={iconSrc} />
}
