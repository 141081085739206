import { hasPermission } from "@brm/schema-helpers/role.js"
import type { IntegrationProvider, IntegrationSummary } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Box, HStack, useDisclosure, useToast } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { useEffect, useState } from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useGetIntegrationV1Query,
  useGetUserV1WhoamiQuery,
  usePostIntegrationV1ByIdDisableMutation,
  usePostIntegrationV1ByIdEnableMutation,
  usePostIntegrationV1CrawlByIdMutation,
} from "../../app/services/generated-api.js"
import IntegrationCell from "../../components/DataTable/CellRenderer/IntegrationCell.js"
import IntegrationStatusCell from "../../components/DataTable/CellRenderer/IntegrationStatusCell.js"
import DataTable from "../../components/DataTable/DataTable.js"
import DeleteIconButton from "../../components/icons/system/DeleteIconButton.js"
import DisableIconButton from "../../components/icons/system/DisableIconButton.js"
import EditIconButton from "../../components/icons/system/EditIconButton.js"
import EnableIconButton from "../../components/icons/system/EnableIconButton.js"
import RefreshIconButton from "../../components/icons/system/RefreshIconButton.js"
import { useDocumentVisibility } from "../../util/visibility.js"
import ConnectIntegration from "../integrations/ConnectIntegration.js"
import CreateIntegrationModal from "../integrations/CreateIntegrationModal.js"
import { DeleteIntegrationConfirmationModal } from "../integrations/DeleteIntegrationConfirmationModal.js"
import { SettingsHeader } from "./SettingsHeader.js"

const columnHelper = createColumnHelper<IntegrationSummary>()

const getColumns = (intl: IntlShape, actionsComponent: (integration: IntegrationSummary) => JSX.Element | null) => [
  columnHelper.accessor("provider", {
    header: intl.formatMessage({
      id: "integrations.table.header.integration",
      description: "Integration table column header for integration name",
      defaultMessage: "Integration",
    }),
    enableColumnFilter: false,
    enableSorting: false,
    size: 180,
    cell: (info) => <IntegrationCell integration={info.row.original} />,
  }),
  columnHelper.accessor("status", {
    header: intl.formatMessage({
      id: "integrations.table.header.status",
      description: "Integration table column header for integration status",
      defaultMessage: "Status",
    }),
    enableColumnFilter: false,
    enableSorting: false,
    size: 420,
    cell: (info) => <IntegrationStatusCell integration={info.row.original} />,
    meta: { textAlign: "center" },
  }),
  columnHelper.display({
    id: "integration-actions",
    size: 170,
    cell: (item) => actionsComponent(item.row.original),
  }),
]

export default function IntegrationList() {
  const intl = useIntl()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const visibility = useDocumentVisibility()

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: integrations } = useGetIntegrationV1Query(undefined, {
    pollingInterval: visibility === "visible" ? 2_000 : 0,
  })
  const [crawlIntegration] = usePostIntegrationV1CrawlByIdMutation()
  const [disableIntegration, disableIntegrationResult] = usePostIntegrationV1ByIdDisableMutation()
  const [enableIntegration, enableIntegrationResult] = usePostIntegrationV1ByIdEnableMutation()

  const [integrationBeingDeleted, setIntegrationBeingDeleted] = useState<IntegrationSummary>()
  const deleteModalState = useDisclosure()

  const [integrationProvider, setIntegrationProvider] = useState<IntegrationProvider>()
  const [integrationId, setIntegrationId] = useState<string>()
  const editModalState = useDisclosure()

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      toast({
        description: intl.formatMessage({
          id: "organization.connectIntegration.oauthCallback.errorToastMsg",
          description: "Toast message when connecting an integration that does a callback returns an error",
          defaultMessage: error,
        }),
        status: "error",
        position: "top",
        onCloseComplete: () => setSearchParams(),
      })
    }
  }, [searchParams, intl, toast, setSearchParams])

  const actionsDisabled = (integration: IntegrationSummary): boolean => {
    switch (integration.status) {
      case "connected":
      case "disconnected":
      case "disabled":
      case "failing":
      case "connecting":
        return false
      case "deleting":
      case "resetting":
        return true
      default:
        unreachable(integration.status)
    }
  }

  if (!integrations) {
    return null
  }

  const reconnectIntegration = (integration: IntegrationSummary) => {
    setIntegrationProvider(integration.provider)
    setIntegrationId(integration.id)
    editModalState.onOpen()
  }

  const refreshIntegration = async (integration: IntegrationSummary) => {
    if (integration.status === "disconnected") {
      if (hasPermission(whoami?.roles, "integration:update")) {
        reconnectIntegration(integration)
      } else {
        toast({
          description: intl.formatMessage({
            id: "organization.refreshIntegration.errorToastMsg",
            description: "Toast message when reconnecting a disconnected integration without permission",
            defaultMessage:
              "This integration needs to be reconnected before it can be refreshed. Please ask an administrator to reconnect the integration.",
          }),
          status: "error",
        })
      }
      return
    }

    await crawlIntegration({
      id: integration.id,
    })
    toast({
      description: intl.formatMessage({
        id: "organization.refreshIntegration.toastMsg",
        description: "Toast message when refreshing a integration",
        defaultMessage: "Refresh of integration triggered. It may take a few minutes to complete.",
      }),
      status: "success",
    })
  }

  return (
    <Box>
      <SettingsHeader>
        <FormattedMessage
          defaultMessage="Integrations"
          description="Integrations settings page header"
          id="integrations.page.header"
        />
      </SettingsHeader>
      <Box overflow="auto">
        <DataTable
          data={integrations}
          columns={getColumns(intl, (integration: IntegrationSummary) => (
            <HStack justify="end">
              <RefreshIconButton
                variant="outline"
                isDisabled={actionsDisabled(integration)}
                onClick={async () => {
                  await refreshIntegration(integration)
                }}
              />
              {hasPermission(whoami?.roles, "integration:update") && (
                <EditIconButton
                  variant="outline"
                  label={intl.formatMessage({
                    id: "iconButtons.reconnect.label",
                    description: "The label for a reconnect button",
                    defaultMessage: "Reconnect",
                  })}
                  isDisabled={actionsDisabled(integration)}
                  onClick={() => reconnectIntegration(integration)}
                />
              )}

              {hasPermission(whoami?.roles, "integration:update") && integration.status === "disabled" && (
                <EnableIconButton
                  variant="outline"
                  isDisabled={actionsDisabled(integration)}
                  isLoading={
                    enableIntegrationResult.isLoading && enableIntegrationResult.originalArgs?.id === integration.id
                  }
                  onClick={async () => {
                    await enableIntegration({
                      id: integration.id,
                    })
                  }}
                />
              )}

              {hasPermission(whoami?.roles, "integration:update") && integration.status !== "disabled" && (
                <DisableIconButton
                  variant="outline"
                  isLoading={
                    disableIntegrationResult.isLoading && disableIntegrationResult.originalArgs?.id === integration.id
                  }
                  isDisabled={actionsDisabled(integration)}
                  onClick={async () => {
                    await disableIntegration({
                      id: integration.id,
                    })
                  }}
                />
              )}

              {hasPermission(whoami?.roles, "integration:delete") && (
                <DeleteIconButton
                  variant="outline"
                  isDisabled={actionsDisabled(integration)}
                  onClick={async () => {
                    setIntegrationBeingDeleted(integration)
                    deleteModalState.onOpen()
                  }}
                />
              )}
            </HStack>
          ))}
        />
      </Box>
      <Box pt={6}>
        <ConnectIntegration
          openIntegrationModal={(key: IntegrationProvider, integrationId?: string) => {
            setIntegrationProvider(key)
            setIntegrationId(integrationId)
            editModalState.onOpen()
          }}
        />
      </Box>
      {integrationProvider && (
        <CreateIntegrationModal
          integrationId={integrationId}
          integrations={integrations}
          integrationProvider={integrationProvider}
          {...editModalState}
        />
      )}
      {integrationBeingDeleted && (
        <DeleteIntegrationConfirmationModal {...deleteModalState} integrationBeingDeleted={integrationBeingDeleted} />
      )}
    </Box>
  )
}
