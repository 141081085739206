import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import {
  isDocumentOrURLStringType,
  isDocumentsType,
  isDocumentType,
  isFrequencyOrOneTimeType,
} from "@brm/schema-helpers/schema.js"
import type {
  AgreementType,
  Applicable,
  ApplicableWithDocument,
  CommentCounts,
  CompliantWithDocument,
  CurrencyAmount,
  DateString,
  DocumentMinimal,
  DocumentOrURLString,
  FieldMetadataWithSuggestions,
  FieldSourceInputProperties,
  FieldSourceOutputProperties,
  FormFieldConfig,
  HttpUrlString,
  InvoiceInterval,
  LegalClauses,
  LegalClausesFieldsMetadata,
  ObjectType,
  PickableEntityFilter,
  Suggestion,
  ToolOptionWithVendor,
  VendorOptionWithTools,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import { DateDurationString, FieldSourceInputPropertiesSchema, LegalClausesSchema } from "@brm/schemas"
import { decisionDateDerivedFromFieldsSet, derivedDecisionDate } from "@brm/type-helpers/legal.js"
import {
  isApplicableWithDocumentType,
  isCompliantWithDocumentType,
  isCurrencyAmountType,
  isPersonType,
  isStdObjSchema,
} from "@brm/type-helpers/schema.js"
import { formatDuration, getOneTimeFrequencyDisplayName } from "@brm/util/format-date-time.js"
import {
  getEnumOptions,
  getSchemaAtPath,
  getTitle,
  isBooleanType,
  isEnumArrayType,
  isEnumType,
  isIntegerType,
  isNullableSchema,
  isNumberType,
  isStringType,
  unwrapNullableSchema,
} from "@brm/util/schema.js"
import { isObject, isObjectWithId, isTruthy } from "@brm/util/type-guard.js"
import * as cfWorkerJsonSchema from "@cfworker/json-schema"
import { Box, Flex, FormControl, FormErrorMessage, Input, Textarea } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { Select as MultiSelect } from "chakra-react-select"
import { excludeKeys } from "filter-obj"
import objectPath from "object-path"
import type { ReactNode } from "react"
import { memo, useCallback, useMemo, useRef, useState } from "react"
import type { Control, ControllerRenderProps, RefCallBack, SetValueConfig, Validate } from "react-hook-form"
import { Controller, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import ExtractLegalDocumentUpload from "../../features/legal/ExtractLegalDocumentUpload.js"
import LegalClausesSelector from "../../features/legal/LegalClausesSelector.js"
import {
  ToolListingMultiPickerWithModal,
  ToolOptionPickerCreatableWithModal,
} from "../../features/tool/NewToolModal.js"
import {
  canApproveWorkflowRunStep,
  type GetLogoForOrganizationProps,
  type GetOrganizationActorProps,
  type WorkflowRunWithExternalFlag,
} from "../../features/workflows/run/utils.js"
import {
  isLegalClausesType,
  isToolListingArrayType,
  isToolOptionsType,
  isToolReferenceType,
  isVendorOptionType,
} from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import { DocumentUpload, type DocumentChangeHandler } from "../Document/DocumentUpload.js"
import { DocumentUploadOrUrlInput, UrlInput } from "../Document/DocumentUploadOrUrlInput.js"
import BasicOrganizationEntityPicker from "../Form/BasicOrganizationEntityPicker.js"
import { BooleanSelect } from "../Form/BooleanSelect.js"
import { CurrencyAmountInputGroup } from "../Form/CurrencyAmountInput.js"
import { DATE_DURATION_UNITS, DATE_TIME_DURATION_UNITS } from "../Form/duration-unit.js"
import { DurationInputGroup } from "../Form/DurationInput.js"
import { EnumSelect } from "../Form/EnumSelect.js"
import { FrequencyInputGroup } from "../Form/FrequencyInput.js"
import ToolPicker from "../Form/ToolPicker.js"
import { VendorOptionPicker } from "../Form/VendorOptionPicker.js"
import RichTextDisplay from "../RichTextEditor/RichTextDisplay.js"
import RichTextEditor from "../RichTextEditor/RichTextEditor.js"
import { DEFAULT_PICKABLE_ENTITIES, EMPTY_RICH_TEXT_BODY } from "../RichTextEditor/util/common.js"
import { DatePickerInput } from "./DatePicker.js"
import type { DynamicFormFieldWrapperProps } from "./DynamicFormFieldWrapper.js"
import { DynamicFormFieldWrapper } from "./DynamicFormFieldWrapper.js"
import { InputWithSuggestions } from "./InputWithSuggestions.js"
import type { SchemaFormFieldApproval, TypedSuggestion } from "./types.js"
import { type ValueWithSource } from "./types.js"
import { fieldHasFormValue, formatValidationErrors, getDefaultUserFieldSource } from "./utils.js"

export interface DynamicFormProps {
  formField: FormFieldConfig
  path: (string | number)[]
  /** react-hook-form `useForm().control` */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>

  /** react-hook-form `useForm().getValues()` function */
  getValue: (pathString: string) => unknown

  /** react-hook-form `useForm().setValue()` function */
  setValue: (pathString: string, value: unknown, options?: SetValueConfig) => void

  /** react-hook-form `useForm().resetField()` function */
  resetField: (pathString: string, options?: SetValueConfig) => void

  /** The workflow run. Used for fetching field timeline items. */
  workflowRun?: WorkflowRunWithExternalFlag

  /** The workflow run step. Used for posting comments and modifying approval status of step fields. */
  workflowRunStep?: WorkflowRunStepWithContext

  /** The counts of comments by object ID and field name. */
  commentCounts?: CommentCounts

  /** The full base schema, used to determine types. */
  rootBaseSchema: ReadonlyDeep<JSONSchema>
  isReadOnly: boolean
  getDocumentDownloadUrl?: (path: (string | number)[], document: DocumentMinimal) => string
  onDocumentClick?: (document: DocumentMinimal) => void
  /** Some forms submit onBlur, but the onBlur event for DocumentUpload is not intuitively triggered by user actions, so the onBlur action can be passed in here and called on DocumentUpload change  */
  onDocumentChange?: DocumentChangeHandler
  renderFieldWrapper?: (wrapperProps: DynamicFormFieldWrapperProps) => ReactNode

  uncontrolledComponentResetId: number
  incrementUncontrolledComponentResetId: () => void

  /** Renderer to display field source information */
  renderFieldSource?: (provenancePath: (string | number)[], fieldSource?: FieldSourceOutputProperties) => ReactNode
  /** Compact vs full size view for styling */
  format?: "full" | "compact"
  /** Callback to trigger provenance highlighting */
  onProvenanceClick?: (path: (string | number)[], fieldSource?: FieldSourceOutputProperties) => void
  /** Will render accept buttons per field if non empty */
  onVerifyField?: (fieldSourcePath: (string | number)[]) => void
  /** Selected document to be set active in document fields */
  selectedDocument?: DocumentMinimal
  /** Will have large box shadow, used to indicate the field is currently viewed in document viewer */
  isActive?: boolean
}

function DynamicFormPropsAreEqual(prevProps: DynamicFormProps, nextProps: DynamicFormProps) {
  return (
    prevProps.formField === nextProps.formField &&
    prevProps.path.join(".") === nextProps.path.join(".") &&
    prevProps.control === nextProps.control &&
    prevProps.getValue === nextProps.getValue &&
    prevProps.setValue === nextProps.setValue &&
    prevProps.resetField === nextProps.resetField &&
    prevProps.workflowRun === nextProps.workflowRun &&
    prevProps.workflowRunStep === nextProps.workflowRunStep &&
    prevProps.commentCounts === nextProps.commentCounts &&
    prevProps.rootBaseSchema === nextProps.rootBaseSchema &&
    prevProps.isReadOnly === nextProps.isReadOnly &&
    prevProps.getDocumentDownloadUrl === nextProps.getDocumentDownloadUrl &&
    prevProps.onDocumentClick === nextProps.onDocumentClick &&
    prevProps.onDocumentChange === nextProps.onDocumentChange &&
    prevProps.renderFieldWrapper === nextProps.renderFieldWrapper &&
    prevProps.uncontrolledComponentResetId === nextProps.uncontrolledComponentResetId &&
    prevProps.incrementUncontrolledComponentResetId === nextProps.incrementUncontrolledComponentResetId &&
    prevProps.renderFieldSource === nextProps.renderFieldSource &&
    prevProps.format === nextProps.format &&
    prevProps.onProvenanceClick === nextProps.onProvenanceClick &&
    prevProps.onVerifyField === nextProps.onVerifyField &&
    prevProps.selectedDocument === nextProps.selectedDocument &&
    prevProps.isActive === nextProps.isActive
  )
}

/**
 * Renders a flat list of form fields
 */
export const DynamicFormField = memo(function DynamicForm(
  props: DynamicFormProps & GetLogoForOrganizationProps & GetOrganizationActorProps
): ReactNode {
  const {
    path,
    formField,
    control,
    getValue,
    setValue,
    resetField,
    commentCounts,
    rootBaseSchema,
    workflowRun,
    workflowRunStep,
    getDocumentDownloadUrl,
    onDocumentClick,
    onDocumentChange,
    renderFieldWrapper,
    uncontrolledComponentResetId,
    incrementUncontrolledComponentResetId,
    renderFieldSource,
    onProvenanceClick,
    getLogoToShowByOrganizationId,
    getOrganizationActorWhenActorMissing,
    format = "full",
    onVerifyField,
    selectedDocument,
    isActive,
  } = props
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const value = getValue(path.join("."))
  const suggestionRef = useRef<HTMLInputElement | null>(null)
  const isRequired = formField.is_required

  const setSuggestionRef = useCallback(
    (fieldRef?: RefCallBack) => (e: HTMLInputElement) => {
      fieldRef?.(e)
      suggestionRef.current = e
    },
    []
  )

  const schemaWithNullable = useMemo(() => getSchemaAtPath(rootBaseSchema, path, false), [rootBaseSchema, path])
  const fieldCanBeNull = isNullableSchema(schemaWithNullable)
  const schema = schemaWithNullable ? unwrapNullableSchema(schemaWithNullable) : undefined
  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(() => {
    return workflowRun?.id
      ? {
          ...(workflowRun.id && {
            requesting_entity: {
              object_type: "WorkflowRun",
              object_id: workflowRun.id,
            },
          }),
          entities: [...DEFAULT_PICKABLE_ENTITIES, "workflow_seller", "workflow_buyer"],
        }
      : { entities: DEFAULT_PICKABLE_ENTITIES }
  }, [workflowRun?.id])

  const [provenancePath, setProvenancePath] = useState<(string | number)[]>(path)
  const parentObjectPath = formField.is_custom ? path.slice(0, -2) : path.slice(0, -1)

  const parentObject = getValue(parentObjectPath.join("."))
  const parentId = isObjectWithId(parentObject) ? parentObject.id : undefined
  const parentType =
    isObject(parentObject) && "object_type" in parentObject && typeof parentObject.object_type === "string"
      ? (parentObject.object_type as ObjectType)
      : undefined
  const parentObjectBaseSchema = getSchemaAtPath(rootBaseSchema, parentObjectPath)

  const decisionDateDeps = Array.from(decisionDateDerivedFromFieldsSet, (field) =>
    [...parentObjectPath, field].join(".")
  )
  const decisionDateDerivedFromValues = useWatch({
    control,
    name: decisionDateDeps,
    disabled: formField.field_name !== "decision_date",
  })

  const fieldMetadata = useWatch({
    control,
    name: [
      ...parentObjectPath,
      "fields_metadata",
      formField.is_custom ? `custom.${formField.field_name}` : formField.field_name,
    ].join("."),
  }) as FieldMetadataWithSuggestions | LegalClausesFieldsMetadata | undefined

  const updateFields = useCallback(
    (fieldSource?: FieldSourceInputProperties | LegalClausesFieldsMetadata) => {
      if (formField.field_name && whoami) {
        const fieldSourceProps = FieldSourceInputPropertiesSchema.properties
        if (formField.field_name === "clauses" && fieldSource) {
          const clausesMetadata = excludeKeys(
            (getValue([...parentObjectPath, "fields_metadata", formField.field_name].join(".")) ||
              {}) as LegalClausesFieldsMetadata,
            [...Object.keys(LegalClausesSchema.properties), ...Object.keys(fieldSourceProps)]
          )
          setValue(
            [...parentObjectPath, "fields_metadata", formField.field_name].join("."),
            {
              ...clausesMetadata,
              ...fieldSource,
              verified: false,
              updated_at: Temporal.Now.instant().toString(),
            } satisfies FieldMetadataWithSuggestions,
            {
              shouldDirty: true,
            }
          )
        } else {
          const maybeCustomFieldName = formField.is_custom ? ["custom", formField.field_name] : [formField.field_name]
          const otherFieldMetadata = excludeKeys(
            getValue([...parentObjectPath, "fields_metadata", ...maybeCustomFieldName].join(".")) || {},
            (k) => k in fieldSourceProps
          )
          setValue(
            [...parentObjectPath, "fields_metadata", ...maybeCustomFieldName].join("."),
            {
              ...otherFieldMetadata,
              ...(fieldSource || getDefaultUserFieldSource(intl, whoami)),
              verified: false,
              updated_at: Temporal.Now.instant().toString(),
            } satisfies FieldMetadataWithSuggestions,
            {
              shouldDirty: true,
            }
          )
        }
      }

      const decisionDateMetadataPath = [...parentObjectPath, "fields_metadata", "decision_date"].join(".")
      const decisionDatePath = [...parentObjectPath, "decision_date"].join(".")
      const decisionDateMetadata = getValue(decisionDateMetadataPath) as FieldMetadataWithSuggestions | undefined
      if (
        formField.field_name &&
        decisionDateDerivedFromFieldsSet.has(formField.field_name) &&
        (!decisionDateMetadata || decisionDateMetadata.type === "derived")
      ) {
        const derivedDate = derivedDecisionDate({
          end_date: getValue([...parentObjectPath, "end_date"].join(".")) as string | undefined,
          auto_renewal_opt_out_period: getValue([...parentObjectPath, "auto_renewal_opt_out_period"].join(".")) as
            | string
            | undefined,
          invoice_interval: getValue([...parentObjectPath, "invoice_interval"].join(".")) as
            | InvoiceInterval
            | undefined,
          agreement_type: getValue([...parentObjectPath, "agreement_type"].join(".")) as AgreementType | undefined,
          first_invoice_date: getValue([...parentObjectPath, "first_invoice_date"].join(".")) as string | undefined,
        })

        setValue(decisionDatePath, derivedDate?.decision_date ?? null, { shouldDirty: true })
        setValue(decisionDateMetadataPath, derivedDate?.fields_metadata ?? {}, { shouldDirty: true })
      }
    },
    [formField.field_name, getValue, parentObjectPath, setValue, formField.is_custom, intl, whoami]
  )

  const suggestedValues: Suggestion[] | undefined = useMemo(() => {
    if (formField.field_name === "decision_date") {
      const depObject = decisionDateDerivedFromValues
        .map((val, i) => {
          // decisionDateDeps will have a value of the entire path from the root but we only want to get the last field
          const key = decisionDateDeps[i]?.split(".").pop()
          if (!key) return {}
          return {
            [key]: val,
          }
        })
        .reduce((a, b) => ({ ...a, ...b }))

      const derivedDate = derivedDecisionDate(depObject)
      if (derivedDate) {
        return [
          {
            value: derivedDate.decision_date,
            field_sources: [
              {
                ...derivedDate.fields_metadata,
                source_display_name: intl.formatMessage({
                  defaultMessage: "BRM Suggestion",
                  description: "The option label for a field calculated from other fields",
                  id: "schemaForm.decisionDate.option.derived",
                }),
              },
            ],
          },
        ] satisfies Suggestion[]
      }
    }
    return fieldMetadata?.suggestions
  }, [decisionDateDeps, decisionDateDerivedFromValues, fieldMetadata?.suggestions, formField.field_name, intl])

  if (!isObject(schema)) {
    return null
  }

  const commentCount = parentId ? commentCounts?.[parentId]?.[formField.field_name] : undefined
  const fieldApprovals = workflowRunStep && "field_approvals" in workflowRunStep ? workflowRunStep.field_approvals : {}
  const fieldApproval = parentId ? fieldApprovals[parentId]?.[formField.field_name] : undefined
  const fieldGatherers = workflowRun?.field_gatherers || {}
  const fieldGatherer = parentId ? fieldGatherers[parentId]?.[formField.field_name] : undefined
  const fieldIsApproved = fieldApproval?.status === "approved" || workflowRunStep?.status === "approved"
  const fieldApprovalMetadata: SchemaFormFieldApproval = fieldApproval && { ...fieldApproval, fieldIsApproved }

  const isReadOnly =
    props.isReadOnly ||
    Boolean(schema.readOnly) ||
    // An approved field is read-only for everyone that does not have approval permission
    ((fieldApproval?.status === "approved" || workflowRunStep?.status === "approved") &&
      !!workflowRunStep &&
      !!workflowRun &&
      !canApproveWorkflowRunStep(whoami, workflowRunStep, workflowRun))

  // Get last element in path, which is the field name without any nested objects in between
  const title = getTitle(formField.field_name ?? "", schema)
  const description = schema.uiDescription || schema.description
  const controllerProps = { name: path.join("."), control }

  const wrapperProps: Omit<DynamicFormFieldWrapperProps, "children"> &
    GetLogoForOrganizationProps &
    GetOrganizationActorProps = {
    formField,
    description,
    label: title,
    workflowRun,
    fieldMetadata,
    objectId: parentId,
    objectType: parentType,
    getValue,
    path,
    fieldName: formField.field_name,
    fieldFilledOut: fieldHasFormValue(schema, value),
    isCustomField: formField.is_custom,
    commentCount,
    workflowRunStep,
    fieldApproval: fieldApprovalMetadata,
    fieldGatherer,
    renderFieldWrapper,
    getLogoToShowByOrganizationId,
    getOrganizationActorWhenActorMissing,
    renderFieldSource:
      fieldMetadata && renderFieldSource
        ? () =>
            renderFieldSource(
              provenancePath,
              // For almost all fields, the fieldMetadata a singular fieldMetadata object. For “clauses”, it is an object with nested field metadata for each clause
              // We will check if the provenance path has been modified to something longer than the actual path, then get the metadata pointing to the specific
              // sub-metadata
              provenancePath.length > path.length
                ? objectPath.get(fieldMetadata, provenancePath.slice(parentObjectPath.length + 1))
                : fieldMetadata
            )
        : undefined,
    onVerifyField: onVerifyField
      ? () =>
          onVerifyField([
            ...parentObjectPath,
            ...(formField.field_name
              ? [formField.is_custom ? `custom.${formField.field_name}` : formField.field_name]
              : []),
          ])
      : undefined,
    fieldRef: suggestionRef,
    isActive,
  }

  /**
   * Change handler that wraps the original onChange handler and also updates the field source metadata to the provided value
   */
  const onChangeWithFieldSource = (onChange: ControllerRenderProps["onChange"]) => {
    return (value: unknown, fieldSource?: FieldMetadataWithSuggestions | LegalClausesFieldsMetadata) => {
      onChange(value)
      // Check if the containing object supports field source metadata
      updateFields(fieldSource)
    }
  }

  const requiredMessage = intl.formatMessage({
    defaultMessage: "Please provide an answer to this field",
    description: "Error message for required field",
    id: "form.error.required",
  })

  // If you see validation errors without the code entering this block, check to see if the surrounding html form has a `noValidate` attribute
  const validate: Validate<unknown, unknown> = (value) => {
    // Rudimentary support for the AJV's `transform` keyword so that we don't fail on leading/trailing whitespace
    if (typeof value === "string" && schema.transform?.includes("trim")) {
      value = value.trim()
    }
    if (formField.is_required && !fieldHasFormValue(schema, value)) {
      return requiredMessage
    }
    if (value !== null && value !== undefined) {
      // The root schema validates the general shape of the data, e.g. a string being a URL or a date, min/max for numbers, etc.
      //
      // We pass an empty object as the "lookup" argument to avoid infinite recursion on $refs. The schema is assumed to be
      // already dereferenced at this point. Attempting to dereference it again (within the cfworker validate implementation)
      // will infinitely recurse.
      const baseSchemaResult = cfWorkerJsonSchema.validate(value, schema as cfWorkerJsonSchema.Schema, undefined, {})
      if (baseSchemaResult.errors[0]) {
        return formatValidationErrors({
          errors: baseSchemaResult.errors,
          schema: baseSchemaResult,
          fieldName: formField.field_name,
        })
      }
    }
    return true
  }

  const schemaWithValues = { schema, value }
  if (schema.displayable === false || schema.schemaFormDisabled) {
    return null
  }

  if (isCompliantWithDocumentType(schema, value)) {
    const urlSuggestions: ValueWithSource<HttpUrlString>[] = (
      suggestedValues as TypedSuggestion<CompliantWithDocument>[]
    )
      ?.map(({ value, field_sources }) => {
        if (value?.document && typeof value.document === "string") {
          return {
            value: value.document,
            field_sources,
          } as TypedSuggestion<string>
        }
        return undefined
      })
      .filter(isTruthy)

    const booleanSuggestions: ValueWithSource<Applicable>[] = (
      suggestedValues as TypedSuggestion<CompliantWithDocument>[]
    )
      ?.map(({ value, field_sources }) => {
        return { value: value.compliant, field_sources }
      })
      .filter(isTruthy)

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <BooleanSelect
                ref={setSuggestionRef(field.ref)}
                value={field.value?.compliant ?? null}
                onChange={(compliant) =>
                  onChangeWithFieldSource(field.onChange)(
                    // set document null here as a fallback to make sure the whole object is always present
                    // If there is a document in the field value, it will override that
                    compliant ? { document: null, ...field.value, compliant } : { compliant, document: null }
                  )
                }
                allowNull
                menuPortalTarget={document.body}
                isRequired={isRequired}
                isReadOnly={isReadOnly}
                suggestions={booleanSuggestions}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />

              {field.value?.compliant && (!isReadOnly || value?.document) && (
                // Document upload field is never required
                <Flex mt={2} direction="column">
                  <DocumentUploadOrUrlInput
                    setSuggestionRef={setSuggestionRef()}
                    selectedDocument={selectedDocument}
                    value={field.value?.document ?? null}
                    onDocumentChange={onDocumentChange}
                    onChange={({ value: documentOrURL, field_sources }) => {
                      if (typeof documentOrURL === "string") {
                        onChangeWithFieldSource(field.onChange)(
                          { ...field.value, document: documentOrURL },
                          field_sources?.[0]
                        )
                      } else {
                        onChangeWithFieldSource(field.onChange)({ ...field.value, document: documentOrURL })
                      }
                    }}
                    isReadOnly={isReadOnly}
                    getLinkDestination={
                      getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                    }
                    urlSuggestions={urlSuggestions}
                    fieldMetadata={fieldMetadata}
                    fieldApproval={fieldApprovalMetadata}
                  />
                </Flex>
              )}
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.format === "date") {
    const suggestions: ValueWithSource<Temporal.PlainDate>[] = (suggestedValues as TypedSuggestion<DateString>[])?.map(
      (suggestion) => ({
        value: Temporal.PlainDate.from(suggestion.value),
        field_sources: suggestion.field_sources,
      })
    )
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <DatePickerInput
                {...field}
                ref={setSuggestionRef(field.ref)}
                // If the SchemaForm is a full format, the date picker will be very wide and have a lot of empty space
                // So to avoid this we set the width of the DatePicker to fit-content instead of matching the SchemaForm width.
                calendarWidth={format === "full" ? "fit-content" : "full"}
                onChange={onChangeWithFieldSource(field.onChange)}
                value={field.value ?? ""}
                isReadOnly={isReadOnly}
                isInvalid={fieldState.invalid}
                suggestions={suggestions}
                ariaLabel={intl.formatMessage({
                  id: "form.date.ariaLabel",
                  defaultMessage: "Enter date",
                  description: "Date input field aria label",
                })}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isFrequencyOrOneTimeType(schema, value)) {
    const frequencySuggestions = (suggestedValues as TypedSuggestion<string>[] | undefined)?.map((suggestion) => {
      if (suggestion.value === "one_time") {
        return {
          value: "one_time" as const,
          field_sources: suggestion.field_sources,
          label: getOneTimeFrequencyDisplayName(intl),
        }
      }
      const duration = Temporal.Duration.from(suggestion.value)
      return {
        value: duration,
        field_sources: suggestion.field_sources,
        label: formatDuration(intl, duration),
      }
    })

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl as="fieldset" isReadOnly={isReadOnly} isInvalid={fieldState.invalid} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty} labelProps={{ as: "legend" }}>
              <FrequencyInputGroup
                ref={setSuggestionRef(field.ref)}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata}
                suggestedOptions={frequencySuggestions}
                value={field.value}
                onChange={(value, fieldSource) =>
                  onChangeWithFieldSource(field.onChange)(value?.toString() ?? null, fieldSource)
                }
                units={schema.pattern === DateDurationString.pattern ? DATE_DURATION_UNITS : DATE_TIME_DURATION_UNITS}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.format === "duration") {
    const durationSuggestions: ValueWithSource<Temporal.Duration>[] =
      (suggestedValues as TypedSuggestion<string>[])?.map((suggestion) => {
        const duration = Temporal.Duration.from(suggestion.value)
        return {
          value: duration,
          field_sources: suggestion.field_sources,
          label: formatDuration(intl, duration),
        }
      }) || []
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl as="fieldset" isReadOnly={isReadOnly} isInvalid={fieldState.invalid} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty} labelProps={{ as: "legend" }}>
              <DurationInputGroup
                ref={setSuggestionRef(field.ref)}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata}
                suggestions={durationSuggestions}
                value={field.value}
                onChange={(value, fieldSource) =>
                  onChangeWithFieldSource(field.onChange)(value?.toString() ?? null, fieldSource)
                }
                units={schema.pattern === DateDurationString.pattern ? DATE_DURATION_UNITS : DATE_TIME_DURATION_UNITS}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.format === "uri") {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <UrlInput
                {...field}
                setSuggestionRef={setSuggestionRef()}
                onChange={({ value, field_sources }) => {
                  if (typeof value === "string") {
                    if (field_sources && field_sources[0]) {
                      onChangeWithFieldSource(field.onChange)(value, field_sources[0])
                      return
                    }
                    onChangeWithFieldSource(field.onChange)(value)
                  }
                }}
                value={field.value ?? ""}
                isReadOnly={isReadOnly}
                isInvalid={fieldState.invalid}
                suggestions={suggestedValues as ValueWithSource<string>[]}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isIntegerType(schema, value) || isNumberType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <Input
                {...field}
                type="number"
                value={field.value ?? ""}
                onChange={({ target: { valueAsNumber } }) =>
                  onChangeWithFieldSource(field.onChange)(isNaN(valueAsNumber) ? null : valueAsNumber)
                }
                step={isIntegerType(schema, value) ? 1 : undefined}
                placeholder={schema.placeholder}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.singleLine) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
              <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
                <InputWithSuggestions
                  {...field}
                  ref={setSuggestionRef(field.ref)}
                  fieldApproval={fieldApprovalMetadata}
                  fieldMetadata={fieldMetadata as FieldMetadataWithSuggestions}
                  onChange={onChangeWithFieldSource(field.onChange)}
                  value={field.value ?? ""}
                  placeholder={schema.placeholder}
                  pattern={schema.pattern}
                  inputMode={schema.inputMode}
                  isReadOnly={isReadOnly}
                  suggestions={suggestedValues as TypedSuggestion<string>[]}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </DynamicFormFieldWrapper>
            </FormControl>
          )
        }}
      />
    )
  }
  if (isStringType(schema, value)) {
    return (
      // Multi-line text
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <Textarea
                {...field}
                rows={4}
                onChange={onChangeWithFieldSource(field.onChange)}
                value={field.value ?? ""}
                placeholder={schema.placeholder}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isRichTextType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              {isReadOnly ? (
                <Box borderWidth="1px" px={3} py={2} borderRadius="lg" overflowWrap="anywhere">
                  <RichTextDisplay
                    content={field.value ?? EMPTY_RICH_TEXT_BODY}
                    getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  />
                </Box>
              ) : (
                <RichTextEditor
                  key={uncontrolledComponentResetId}
                  ref={field.ref}
                  onChange={onChangeWithFieldSource(field.onChange)}
                  initialValue={field.value ?? EMPTY_RICH_TEXT_BODY}
                  placeholder={schema.placeholder}
                  isReadOnly={isReadOnly}
                  hasError={!!fieldState.error}
                  pickableEntityFilters={pickableEntityFilters}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  disableMentions={!whoami}
                />
              )}
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isDocumentOrURLStringType(schema, value)) {
    const urlSuggestions: ValueWithSource<HttpUrlString>[] = (suggestedValues as TypedSuggestion<DocumentOrURLString>[])
      ?.map(({ value, field_sources }) => {
        if (value && typeof value === "string") {
          return { value, field_sources } as TypedSuggestion<string>
        }
        return undefined
      })
      .filter(isTruthy)

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <DocumentUploadOrUrlInput
                setSuggestionRef={setSuggestionRef()}
                onDocumentClick={onDocumentClick}
                selectedDocument={selectedDocument}
                value={field.value}
                onDocumentChange={onDocumentChange}
                onChange={({ value: documentOrURL, field_sources }) =>
                  onChangeWithFieldSource(field.onChange)(documentOrURL, field_sources?.[0])
                }
                isReadOnly={isReadOnly}
                getLinkDestination={
                  getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                }
                ref={field.ref}
                urlSuggestions={urlSuggestions}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (
    isDocumentsType(schema, value) &&
    isStdObjSchema(parentObjectBaseSchema) &&
    parentObjectBaseSchema.properties.object_type.const === "LegalAgreement" &&
    formField.field_name === "documents" &&
    !formField.is_custom
  ) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <ExtractLegalDocumentUpload
                ref={field.ref}
                onDocumentClick={onDocumentClick}
                selectedDocument={selectedDocument}
                value={field.value ?? []}
                onDocumentChange={(documents, type, document) => {
                  if (documents.length === 0) {
                    setValue(path.join("."), [], { shouldDirty: true })
                  } else {
                    onChangeWithFieldSource(field.onChange)(documents)
                  }
                  onDocumentChange?.(documents, type, document)
                }}
                getLinkDestination={
                  getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                }
                multiple={true}
                isReadOnly={isReadOnly}
                workflowRunStepId={workflowRunStep?.id}
                fieldName={formField.field_name}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isDocumentType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <DocumentUpload
                selectedDocument={selectedDocument}
                onDocumentClick={onDocumentClick}
                ref={field.ref}
                value={field.value ? [field.value] : []}
                onChange={(documents, type, document) => {
                  const [firstDocument] = documents
                  if (!firstDocument) {
                    setValue(path.join("."), undefined, { shouldDirty: true })
                  } else {
                    onChangeWithFieldSource(field.onChange)(firstDocument)
                  }
                  onDocumentChange?.(documents, type, document)
                }}
                multiple={false}
                isReadOnly={isReadOnly}
                getLinkDestination={
                  getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                }
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isCurrencyAmountType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl as="fieldset" isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty} labelProps={{ as: "legend" }}>
              <CurrencyAmountInputGroup
                key={uncontrolledComponentResetId}
                ref={setSuggestionRef(field.ref)}
                value={field.value}
                onChange={onChangeWithFieldSource(field.onChange)}
                isReadOnly={isReadOnly}
                suggestions={suggestedValues as TypedSuggestion<CurrencyAmount>[]}
                forceRerender={incrementUncontrolledComponentResetId}
                menuPortalTarget={document.body}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata as Exclude<typeof fieldMetadata, LegalClausesFieldsMetadata>}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isApplicableWithDocumentType(schema, value)) {
    const urlSuggestions: ValueWithSource<HttpUrlString>[] = (
      suggestedValues as TypedSuggestion<ApplicableWithDocument>[]
    )
      ?.map(({ value, field_sources }) => {
        if (value?.document && typeof value.document === "string") {
          return {
            value: value.document,
            field_sources,
          } as TypedSuggestion<string>
        }
        return undefined
      })
      .filter(isTruthy)

    const booleanSuggestions: ValueWithSource<Applicable>[] = (
      suggestedValues as TypedSuggestion<ApplicableWithDocument>[]
    )
      ?.map(({ value, field_sources }) => {
        return { value: value.applicable, field_sources }
      })
      .filter(isTruthy)

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <BooleanSelect
                ref={setSuggestionRef(field.ref)}
                value={field.value?.applicable ?? null}
                onChange={(applicable) =>
                  onChangeWithFieldSource(field.onChange)(
                    // set document null here as a fallback to make sure the whole object is always present
                    // If there is a document in the field value, it will override that
                    applicable ? { document: null, ...field.value, applicable } : { applicable, document: null }
                  )
                }
                allowNull
                menuPortalTarget={document.body}
                isRequired={isRequired}
                isReadOnly={isReadOnly}
                suggestions={booleanSuggestions}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />

              {value?.applicable && (!isReadOnly || value?.document) && (
                // Document upload field is never required
                <Flex mt={2} direction="column">
                  <DocumentUploadOrUrlInput
                    setSuggestionRef={setSuggestionRef()}
                    onDocumentClick={onDocumentClick}
                    selectedDocument={selectedDocument}
                    value={field.value?.document ?? null}
                    onDocumentChange={onDocumentChange}
                    onChange={({ value: documentOrURL, field_sources }) => {
                      if (typeof documentOrURL === "string") {
                        onChangeWithFieldSource(field.onChange)(
                          { ...field.value, document: documentOrURL },
                          field_sources?.[0]
                        )
                      } else {
                        onChangeWithFieldSource(field.onChange)({ ...field.value, document: documentOrURL })
                      }
                    }}
                    isReadOnly={isReadOnly}
                    getLinkDestination={
                      getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                    }
                    urlSuggestions={urlSuggestions}
                    fieldMetadata={fieldMetadata}
                    fieldApproval={fieldApprovalMetadata}
                  />
                </Flex>
              )}
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isBooleanType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <BooleanSelect
                {...field}
                ref={setSuggestionRef(field.ref)}
                menuPortalTarget={document.body}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
                onChange={onChangeWithFieldSource(field.onChange)}
                isRequired
                allowNull={fieldCanBeNull}
                isReadOnly={isReadOnly}
                suggestions={suggestedValues as TypedSuggestion<boolean>[]}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isEnumType(schemaWithValues)) {
    const schema = schemaWithValues.schema
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl
            isInvalid={fieldState.invalid}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            defaultValue={getEnumOptions(schema)[0]?.const}
          >
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <EnumSelect
                {...field}
                ref={setSuggestionRef(field.ref)}
                suggestions={suggestedValues as TypedSuggestion<string>[]}
                value={field.value}
                menuPortalTarget={document.body}
                schema={schema}
                allowNull={fieldCanBeNull}
                onChange={onChangeWithFieldSource(field.onChange)}
                isReadOnly={isReadOnly}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isEnumArrayType(schemaWithValues)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => {
          const noneOption = {
            label: intl.formatMessage({
              defaultMessage: "None",
              description: "Label for none option in multi select",
              id: "form.select.none",
            }),
            value: null,
          }
          const options = [
            ...getEnumOptions(schemaWithValues.schema).map((option) => ({
              label: option.title,
              value: option.const,
            })),
            noneOption,
          ]
          const values: Set<unknown> | null | undefined = field.value && new Set(field.value)
          return (
            <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
              <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
                <MultiSelect<{ label: string; value: unknown }, true>
                  isReadOnly={isReadOnly}
                  isMulti={true}
                  placeholder={intl.formatMessage({
                    id: "form.multi-select.placeholder",
                    defaultMessage: "Select all that apply...",
                    description: "Placeholder for multi selection input",
                  })}
                  value={options.filter((t) => values?.has(t.value))}
                  options={options}
                  onChange={(selectedOptions) => {
                    const onChange = onChangeWithFieldSource(field.onChange)
                    if (selectedOptions.some((option) => option.value === null)) {
                      onChange([])
                    } else {
                      onChange(selectedOptions.map((v) => v.value))
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
                  }}
                  chakraStyles={{
                    placeholder: (provided) => (field.value ? { ...provided, color: "inherit" } : provided),
                  }}
                  ref={(select) => {
                    field.ref(select?.inputRef)
                  }}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </DynamicFormFieldWrapper>
            </FormControl>
          )
        }}
      />
    )
  }
  if (isToolListingArrayType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <ToolListingMultiPickerWithModal
                inputRef={field.ref}
                value={field.value ?? []}
                onChange={(value) => onChangeWithFieldSource(field.onChange)(value)}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isLegalClausesType(schema, value)) {
    const selected = provenancePath.at(-1) as keyof LegalClauses
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <LegalClausesSelector
                ref={setSuggestionRef(field.ref)}
                fieldMetadata={fieldMetadata as LegalClausesFieldsMetadata}
                fieldApproval={fieldApprovalMetadata}
                getDefaultFieldSource={() => (whoami ? getDefaultUserFieldSource(intl, whoami) : {})}
                tagOnClick={(clause: keyof LegalClauses) => {
                  if (path) {
                    const newPath = [...path, clause]
                    setProvenancePath?.(newPath)
                    onProvenanceClick?.(newPath, (fieldMetadata as LegalClausesFieldsMetadata)?.[clause])
                  }
                }}
                menuPortalTarget={document.body}
                value={value ?? {}}
                onChangeClauses={onChangeWithFieldSource(field.onChange)}
                isReadOnly={isReadOnly}
                suggestions={suggestedValues as TypedSuggestion<keyof LegalClauses>[]}
                selected={selected}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isToolOptionsType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              {/* TODO: support picking multiple tools */}
              <ToolOptionPickerCreatableWithModal
                inputRef={setSuggestionRef(field.ref)}
                autoFocus={false}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
                suggestions={suggestedValues as TypedSuggestion<ToolOptionWithVendor>[]}
                allowCreate={true}
                value={field.value?.[0]}
                onCreate={(tool) => {
                  // If we could not create a tool successfully, reset the field
                  if (!tool || !tool.vendor) {
                    resetField([...parentObjectPath, formField.field_name].join("."))
                    return
                  }

                  onChangeWithFieldSource(field.onChange)([tool])
                  // If there is no vendorOption property in the parent schema, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isVendorOptionType(unwrapNullableSchema(parentObjectBaseSchema.properties?.vendor))
                  ) {
                    return
                  }

                  // Otherwise set the value and field source metadata for the vendor
                  setValue([...parentObjectPath, "vendor"].join("."), tool.vendor)
                  if (whoami) {
                    setValue(
                      [...parentObjectPath, "fields_metadata", "vendor"].join("."),
                      getDefaultUserFieldSource(intl, whoami),
                      {
                        shouldDirty: true,
                      }
                    )
                  }
                }}
                onChange={(tool, fieldSource) => {
                  onChangeWithFieldSource(field.onChange)([tool], fieldSource)
                  // If there is no vendorOption property in the parent schema or if the tool update is undefined, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isVendorOptionType(unwrapNullableSchema(parentObjectBaseSchema.properties?.vendor)) ||
                    !tool?.vendor
                  ) {
                    return
                  }

                  const vendor = tool.vendor
                  if (vendor && whoami) {
                    // Otherwise set the value and field source metadata for the vendor
                    setValue([...parentObjectPath, "vendor"].join("."), vendor)
                    const newFieldSource = fieldSource || getDefaultUserFieldSource(intl, whoami)
                    if (newFieldSource) {
                      setValue([...parentObjectPath, "fields_metadata", "vendor"].join("."), newFieldSource, {
                        shouldDirty: true,
                      })
                    }
                  }
                }}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isToolReferenceType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <ToolPicker
                value={field.value}
                onChange={(tool) => onChangeWithFieldSource(field.onChange)(tool)}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isVendorOptionType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <VendorOptionPicker
                value={field.value}
                ref={setSuggestionRef(field.ref)}
                onChange={(vendor, fieldSource) => {
                  onChangeWithFieldSource(field.onChange)(vendor, fieldSource)
                  // If there is no toolOptions property in the parent schema, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isToolOptionsType(parentObjectBaseSchema.properties?.tools)
                  ) {
                    return
                  }
                  const firstTool = vendor?.tools?.[0]
                  // Otherwise set the value and field source metadata for the tools, including removing the tools if the vendor has no tools
                  // TODO handle multiple tools better
                  if (firstTool && whoami) {
                    setValue([...parentObjectPath, "tools"].join("."), [firstTool], {
                      shouldDirty: true,
                    })
                    const newFieldSource = fieldSource || getDefaultUserFieldSource(intl, whoami)
                    if (newFieldSource) {
                      setValue([...parentObjectPath, "fields_metadata", "tools"].join("."), newFieldSource, {
                        shouldDirty: true,
                      })
                    }
                  }
                }}
                suggestions={suggestedValues as TypedSuggestion<VendorOptionWithTools>[]}
                menuPortalTarget={document.body}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }
  if (isPersonType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired={isRequired}>
            <DynamicFormFieldWrapper {...wrapperProps} isDirty={fieldState.isDirty}>
              <BasicOrganizationEntityPicker
                value={field.value}
                includedEntities={["person"]}
                onChange={(person) => onChangeWithFieldSource(field.onChange)(person)}
                isReadOnly={isReadOnly}
                menuPortalTarget={document.body}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </DynamicFormFieldWrapper>
          </FormControl>
        )}
      />
    )
  }

  log.error("Encountered unknown schema type", { schema })
  return null
}, DynamicFormPropsAreEqual)
