import type { VendorComplianceStatus } from "@brm/schema-types/types.js"
import { VendorComplianceStatusSchema } from "@brm/schemas"
import UpdateStatusBadge from "../../../components/UpdateStatusBadge.js"
import { displayVendorComplianceStatus, vendorComplianceStatusColors } from "./constants.js"

interface Props {
  status: VendorComplianceStatus | undefined
  /** If editing is enabled, a vendorId needs to be provided */
  editVendorId?: string
}

export default function VendorComplianceStatusBadge({ status, editVendorId }: Props) {
  if (!status) {
    return undefined
  }

  return (
    <UpdateStatusBadge<VendorComplianceStatus, "compliance_status">
      status={status}
      fieldName="compliance_status"
      objectType="Vendor"
      objectId={editVendorId}
      options={VendorComplianceStatusSchema.anyOf.map((s) => s.const)}
      statusColors={vendorComplianceStatusColors}
      displayStatus={displayVendorComplianceStatus}
      updatePermissions={["vendor:update", "compliance:update"]}
    />
  )
}
