import type { Theme } from "@brm/theme"
import { ButtonGroup, IconButton, type ButtonGroupProps, type IconButtonProps } from "@chakra-ui/react"
import { forwardRef, useStyleConfig } from "@chakra-ui/system"

/**
 * An attached button group that can reveal one or more attached {@link RevealingIconButton}s on hover or focus.
 */
export const RevealingButtonGroup = forwardRef<Omit<ButtonGroupProps, "variant" | "isAttached">, "div">(
  function RevealingButtonGroup(props, ref) {
    const fullProps: ButtonGroupProps = {
      ...props,
      variant: "ghost",
      isAttached: true,
    }
    const buttonTheme = useStyleConfig("Button", fullProps) as ReturnType<
      NonNullable<Theme["components"]["Button"]["baseStyle"]>
    >
    return (
      <ButtonGroup
        {...fullProps}
        ref={ref}
        minW={0}
        borderRadius={buttonTheme.borderRadius}
        className="revealing-button-group"
        sx={{
          // eslint-disable-next-line prefer-smart-quotes/prefer
          '&:is(:hover, :has([aria-current="page"])) .chakra-button': {
            ...buttonTheme._hover,
          },
        }}
      />
    )
  }
)

export const RevealingIconButton = forwardRef<Omit<IconButtonProps, "variant">, "button">(
  function RevealingIconButton(props, ref) {
    return (
      <IconButton
        {...props}
        ref={ref}
        variant="ghost"
        color="transparent"
        className="revealing-icon-button"
        sx={{
          ...props.sx,
          ".chakra-button__group:is(:hover, :has(:focus-visible)) &": {
            color: "blackAlpha.600",
          },
        }}
        _hover={{ color: "black !important" }}
      />
    )
  }
)
