import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import { isDocumentOrURLStringType, isDocumentType, isFrequencyOrOneTimeType } from "@brm/schema-helpers/schema.js"
import {
  ApplicableWithDocumentSchemaRef,
  CompliantWithDocumentSchemaRef,
  CurrencyAmountSchemaRef,
  DateDurationString,
  DateDurationStringRef,
  DateStringRef,
  DocumentMinimalSchemaRef,
  DocumentOrURLStringSchemaRef,
  HttpUrlStringRef,
  PersonReferenceSchema,
  RichTextSchemaRef,
  ToolReferenceSchema,
} from "@brm/schemas"
import {
  isApplicableWithDocumentType,
  isCompliantWithDocumentType,
  isCurrencyAmountType,
  isStdObjSchema,
} from "@brm/type-helpers/schema.js"
import { getTitle, isEnumArrayType, isEnumType, unwrapNullableSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { type IntlFormatters } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { log } from "../../../util/logger.js"
import type { JSONSchemaObjectOnly } from "./types.js"

export const dataTypeOptions: ReadonlyDeep<JSONSchemaObjectOnly[]> = [
  { anyOf: [{ const: "", title: "" }] }, // Enum aka select
  { type: "array", items: { anyOf: [{ const: "", title: "" }] } }, // Array of enums aka Multi-select
  { type: "boolean" },
  { type: "string", singleLine: true },
  { type: "string" },
  { type: "integer" },
  HttpUrlStringRef,
  DateStringRef,
  DateDurationStringRef,
  CurrencyAmountSchemaRef,
  DocumentMinimalSchemaRef,
  DocumentOrURLStringSchemaRef,
  CompliantWithDocumentSchemaRef,
  ToolReferenceSchema,
  PersonReferenceSchema,
] as const

export function getDataTypeLabel(schema: JSONSchemaObject | undefined, intl: IntlFormatters): string {
  if (!schema) {
    return ""
  }
  schema = unwrapNullableSchema(schema) as JSONSchemaObject
  if (schema.$ref === HttpUrlStringRef.$ref || schema.format === "uri") {
    return intl.formatMessage({
      id: "dataType.url",
      defaultMessage: "URL",
      description: "Label for URL data type",
    })
  }
  if (schema.$ref === CurrencyAmountSchemaRef.$ref || isCurrencyAmountType(schema)) {
    return intl.formatMessage({
      id: "dataType.currencyAmount",
      defaultMessage: "Currency amount",
      description: "Label for currency amount data type",
    })
  }
  if (schema.$ref === DateStringRef.$ref || schema.format === "date") {
    return intl.formatMessage({
      id: "dataType.date",
      defaultMessage: "Date",
      description: "Label for date data type",
    })
  }
  if (
    schema.$ref === DateDurationStringRef.$ref ||
    (schema.format === "duration" && schema.pattern === DateDurationString.pattern)
  ) {
    return intl.formatMessage({
      id: "dataType.dateDuration",
      defaultMessage: "Date duration",
      description: "Label for date duration data type",
    })
  }
  if (
    schema.$ref === CompliantWithDocumentSchemaRef.$ref ||
    schema.$ref === ApplicableWithDocumentSchemaRef.$ref ||
    isCompliantWithDocumentType(schema) ||
    isApplicableWithDocumentType(schema)
  ) {
    return intl.formatMessage({
      id: "dataType.compliantWithDocument",
      defaultMessage: "Yes/No + Document",
      description: "Label for boolean with document data type",
    })
  }
  if (schema.$ref === DocumentOrURLStringSchemaRef.$ref || isDocumentOrURLStringType(schema)) {
    return intl.formatMessage({
      id: "dataType.documentOrUrl",
      defaultMessage: "Document or URL",
      description: "Label for document or URL data type",
    })
  }
  if (schema.$ref === DocumentMinimalSchemaRef.$ref || isDocumentType(schema)) {
    return intl.formatMessage({
      id: "dataType.document",
      defaultMessage: "Document",
      description: "Label for document data type",
    })
  }
  if (schema.$ref === RichTextSchemaRef.$ref || isRichTextType(schema)) {
    return intl.formatMessage({
      id: "dataType.richText",
      defaultMessage: "Text (formatted)",
      description: "Label for rich text data type",
    })
  }
  if (isEnumType({ schema })) {
    return intl.formatMessage({
      defaultMessage: "Single select",
      description: "Label for single select data type",
      id: "dataType.select",
    })
  }
  if (isEnumArrayType({ schema })) {
    return intl.formatMessage({
      defaultMessage: "Multi select",
      description: "Label for multi select data type",
      id: "dataType.multiSelect",
    })
  }
  if (isFrequencyOrOneTimeType(schema)) {
    return intl.formatMessage({
      defaultMessage: "Frequency",
      description: "Label for frequency data type",
      id: "dataType.frequency",
    })
  }
  switch (schema.type) {
    case "string":
      if (schema.singleLine) {
        return intl.formatMessage({
          id: "dataType.textSingleLine",
          defaultMessage: "Text (single line)",
          description: "Label for single line text data type",
        })
      }
      return intl.formatMessage({
        id: "dataType.textMultipleLines",
        defaultMessage: "Text (multiple lines)",
        description: "Label for multiple lines text data type",
      })
    case "number":
    case "integer":
      return intl.formatMessage({
        id: "dataType.number",
        defaultMessage: "Number",
        description: "Label for number data type",
      })
    case "boolean":
      return intl.formatMessage({
        id: "dataType.yesNo",
        defaultMessage: "Yes/No",
        description: "Label for boolean data type",
      })
    case "object":
      if (isStdObjSchema(schema)) {
        return getTitle(schema.properties.object_type.const, schema)
      }
      return intl.formatMessage({
        id: "dataType.object",
        defaultMessage: "Object",
        description: "Label for object data type",
      })
    case "array":
      if (schema.items && isObject(schema.items)) {
        return intl.formatMessage(
          {
            id: "dataType.list",
            defaultMessage: "List ({itemsType})",
            description: "Label for list data type",
          },
          { itemsType: getDataTypeLabel(schema.items, intl) }
        )
      }
      return intl.formatMessage({
        id: "dataType.list.unknown",
        defaultMessage: "List",
        description: "Label for list data type with unknown items type",
      })
  }

  log.warn("Unrecognized field config data type", { schema })
  return intl.formatMessage({
    id: "dataType.unknown",
    defaultMessage: "Other",
    description: "Label for unknown data type",
  })
}
