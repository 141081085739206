import type { LegalClauses } from "@brm/schema-types/types.js"
import type { IntlShape } from "react-intl"

export function getLegalClausesDisplayNames(intl: IntlShape): Record<keyof LegalClauses, string> {
  return {
    arbitration: intl.formatMessage({
      id: "context.legalAgreement.clauses.arbitration",
      description: "Display tag for the arbitration clause of the legal clauses section",
      defaultMessage: "Arbitration",
    }),
    assignment: intl.formatMessage({
      id: "context.legalAgreement.clauses.assignment",
      description: "Display tag for the assignment clause of the legal clauses section",
      defaultMessage: "Assignment",
    }),
    confidentiality: intl.formatMessage({
      id: "context.legalAgreement.clauses.confidentiality",
      description: "Display tag for the confidentiality clause of the legal clauses section",
      defaultMessage: "Confidentiality",
    }),
    data_privacy: intl.formatMessage({
      id: "context.legalAgreement.clauses.data_privacy",
      description: "Display tag for the data privacy clause of the legal clauses section",
      defaultMessage: "Data Privacy",
    }),
    data_processing: intl.formatMessage({
      id: "context.legalAgreement.clauses.data_processing",
      description: "Display tag for the data processing clause of the legal clauses section",
      defaultMessage: "Data Processing",
    }),
    data_protection: intl.formatMessage({
      id: "context.legalAgreement.clauses.data_protection",
      description: "Display tag for the data protection clause of the legal clauses section",
      defaultMessage: "Data Protection",
    }),
    dispute_resolution: intl.formatMessage({
      id: "context.legalAgreement.clauses.dispute_resolution",
      description: "Display tag for the dispute resolution clause of the legal clauses section",
      defaultMessage: "Dispute Resolution",
    }),
    force_majeure: intl.formatMessage({
      id: "context.legalAgreement.clauses.force_majeure",
      description: "Display tag for the force majeure clause of the legal clauses section",
      defaultMessage: "Force Majeure",
    }),
    governing_law: intl.formatMessage({
      id: "context.legalAgreement.clauses.governing_law",
      description: "Display tag for the governing law clause of the legal clauses section",
      defaultMessage: "Governing Law",
    }),
    indemnification: intl.formatMessage({
      id: "context.legalAgreement.clauses.indemnification",
      description: "Display tag for the indemnification clause of the legal clauses section",
      defaultMessage: "Indemnification",
    }),
    intellectual_property: intl.formatMessage({
      id: "context.legalAgreement.clauses.intellectual_property",
      description: "Display tag for the intellectual property clause of the legal clauses section",
      defaultMessage: "Intellectual Property",
    }),
    license: intl.formatMessage({
      id: "context.legalAgreement.clauses.license",
      description: "Display tag for the license clause of the legal clauses section",
      defaultMessage: "License",
    }),
    limitation_of_liability: intl.formatMessage({
      id: "context.legalAgreement.clauses.limitation_of_liability",
      description: "Display tag for the limitation of liability clause of the legal clauses section",
      defaultMessage: "Limitation of Liability",
    }),
    non_disclosure: intl.formatMessage({
      id: "context.legalAgreement.clauses.non_disclosure",
      description: "Display tag for the non-disclosure clause of the legal clauses section",
      defaultMessage: "Non Disclosure",
    }),
    non_solicitation: intl.formatMessage({
      id: "context.legalAgreement.clauses.non_solicitation",
      description: "Display tag for the non-solicitation clause of the legal clauses section",
      defaultMessage: "Non Solicitation",
    }),
    representation: intl.formatMessage({
      id: "context.legalAgreement.clauses.representation",
      description: "Display tag for the representation clause of the legal clauses section",
      defaultMessage: "Representation",
    }),
    termination: intl.formatMessage({
      id: "context.legalAgreement.clauses.termination",
      description: "Display tag for the termination clause of the legal clauses section",
      defaultMessage: "Termination",
    }),
    warranty: intl.formatMessage({
      id: "context.legalAgreement.clauses.warranty",
      description: "Display tag for the warranty clause of the legal clauses section",
      defaultMessage: "Warranty",
    }),
  }
}
