import type { AnyFilter, ArrContainsFilter, FilterField } from "@brm/schema-types/types.js"
import { isStdObjSchema } from "@brm/type-helpers/schema.js"
import { getTitle } from "@brm/util/schema.js"
import { shortenUUID } from "@brm/util/short-uuids.js"
import { useState } from "react"
import { useIntl } from "react-intl"
import { isNotUndefined, isString } from "typed-assert"
import { isSpecificEmployeeGroupSchema, isSpecificErpTrackingCategorySchema } from "../../../../util/json-schema.js"
import { log } from "../../../../util/logger.js"
import ObjectSelectorFilter from "./ObjectSelectorFilter.js"
import type { FilterProps, SelectableObjectType } from "./types.js"

interface ObjectSelectorSchemaFilterProps extends FilterProps<ArrContainsFilter | AnyFilter> {
  searchInput: string
}

/**
 * The object selector filter filters columns that are either an object reference (to another object with `id`) or
 * an array of object references (with `id` properties) by a list of options the user can select from which is
 * fetched from the backend.
 *
 * Depending on whether the column is a single object or an array of objects, the filter uses the `any` or
 * `arr_contains` filter, respectively.
 *
 * Examples: `tool.owner`, `tool.categories`.
 */
export default function ObjectSelectorSchemaFilter({
  displayPath,
  fieldSchema,
  isNullable,
  filter,
  onChange,
  searchInput,
}: ObjectSelectorSchemaFilterProps) {
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")

  const intl = useIntl()

  const objectType = isStdObjSchema(fieldSchema)
    ? fieldSchema.properties.object_type.const
    : isStdObjSchema(fieldSchema.items)
      ? fieldSchema.items.properties.object_type.const
      : undefined

  isString(objectType, "Expected field schema for EntitySelectorFilter to have object_type")

  // If the field is an array, both sides of the comparison will be arrays, so we need to use the `arr_contains`
  // operator instead of the `any` operator.
  const defaultComparator: FilterField["comparator"] = fieldSchema.type === "array" ? "arr_contains" : "any"

  const [isNullChecked, setIsNullChecked] = useState(filter?.includeNull ?? false)
  const [values, setValues] = useState<ReadonlySet<string>>(new Set(filter?.values))

  const onValueChecked = (value: string) => {
    const shortValue = shortenUUID(value)
    const checked = !values.has(shortValue)
    const comparator = filter?.comparator ?? defaultComparator
    const newValues = checked ? [...values, shortValue] : Array.from(values).filter((v) => v !== shortValue)
    setValues(new Set(newValues))
    if (newValues.length > 0 || isNullChecked) {
      // Only report valid filters back to the URL
      onChange({ comparator, values: newValues, includeNull: isNullChecked })
    }
  }

  let selectable: SelectableObjectType | undefined
  switch (objectType) {
    case "Tool":
    case "Vendor":
    case "Person":
    case "User":
    case "ToolCategory":
    case "Integration":
    case "WorkflowRun":
      selectable = {
        objectType,
      }
      break
    case "ErpTrackingCategory":
      selectable = {
        objectType,
        categoryType: isSpecificErpTrackingCategorySchema(fieldSchema.items)
          ? fieldSchema.items.properties.type.const
          : undefined,
      }
      break
    case "EmployeeGroup":
      selectable = {
        objectType,
        groupType: isSpecificEmployeeGroupSchema(fieldSchema.items)
          ? fieldSchema.items.properties.group_type.const
          : undefined,
      }
      break
  }

  if (!selectable) {
    log.error("Unexpected object type", objectType)
    return null
  }

  return (
    <ObjectSelectorFilter
      searchInput={searchInput}
      selectable={selectable}
      selectedValues={new Set(filter?.values)}
      onChange={onValueChecked}
      nullableOption={
        isNullable
          ? {
              checked: isNullChecked,
              title: intl.formatMessage(
                {
                  id: "menu.item.null",
                  defaultMessage: "No {title}",
                  description: "Option for a menu item that represents searching for rows without a value",
                },
                { title: getTitle(fieldName, fieldSchema) }
              ),
              onChange: () => {
                const newChecked = !isNullChecked
                setIsNullChecked(newChecked)
                if (!filter) {
                  onChange({ comparator: defaultComparator, values: [], includeNull: true })
                } else if (filter) {
                  onChange({ ...filter, includeNull: newChecked })
                }
              },
            }
          : undefined
      }
    />
  )
}
