import { useEffect, useState } from "react"

/**
 * Returns whether the document is visible or not.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden#usage_notes for additional `prerender` value.
 */
export function useDocumentVisibility(): DocumentVisibilityState | "prerender" {
  const [visibility, setVisibility] = useState<DocumentVisibilityState>(document.visibilityState)

  useEffect(() => {
    const handleVisibilityChange = () => setVisibility(document.visibilityState)
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange)
  }, [])

  return visibility
}
