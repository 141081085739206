import type { CurrencyRange } from "@brm/schema-types/types.js"

export const DEFAULT_CURRENCY = "USD"

// Default USD options
export const STANDARD_CURRENCY_RANGES: CurrencyRange[] = [
  { min: "0", max: "4999", currency_code: DEFAULT_CURRENCY },
  { min: "5000", max: "24999", currency_code: DEFAULT_CURRENCY },
  { min: "25000", max: "49999", currency_code: DEFAULT_CURRENCY },
  { min: "50000", max: "99999", currency_code: DEFAULT_CURRENCY },
  { min: "100000", max: "249999", currency_code: DEFAULT_CURRENCY },
  { min: "250000", max: null, currency_code: DEFAULT_CURRENCY },
]
