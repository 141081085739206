import type { FieldMetadataWithSuggestions, FieldSourceOutputProperties, Suggestion } from "@brm/schema-types/types.js"
import { max } from "@brm/util/date-time.js"
import { isEmpty } from "@brm/util/type-guard.js"
import { Temporal } from "@js-temporal/polyfill"
import type { FieldValues, UseFormReturn } from "react-hook-form"
import type { SchemaFormFieldApproval } from "../components/SchemaForm/types.js"
import { FIELD_SOURCE_COLORS } from "../components/SchemaForm/utils.js"

export function initializeReactHookFromState<T extends FieldValues>(form: UseFormReturn<T>) {
  // It's important to access this property once, as react-hook-form optimizes performance by only updating
  // `formState` if it was initially accessed once (by using a Proxy). If it was only accessed later in the
  // callbacks, it would not have been updated before. We use Reflect.get() so that dead code elimination doesn't
  // remove it. See https://react-hook-form.com/docs/useform/formstate#rules
  Reflect.get(Reflect.get(form, "formState"), "isDirty") satisfies (typeof form)["formState"]["isDirty"]
  Reflect.get(Reflect.get(form, "formState"), "dirtyFields") satisfies (typeof form)["formState"]["dirtyFields"]
}

interface NewOption {
  isNew: boolean
  colorScheme?: string
  fieldSource?: FieldSourceOutputProperties
}

/** Returns a boolean indicating whether the field is new or not as well as the colorscheme for the option. */
export function isNewOption(
  optionFieldSources?: Suggestion["field_sources"],
  fieldMetadata?: FieldMetadataWithSuggestions,
  fieldApproval?: SchemaFormFieldApproval
): NewOption {
  // If field is approved we do not care to show the new value tag
  if (fieldApproval?.fieldIsApproved) {
    return { isNew: false }
  }
  const updatedAt = fieldMetadata?.updated_at
  // A field can either be verified or approved
  const verifiedAt = fieldMetadata?.verified_at || fieldApproval?.updated_at
  if (isEmpty(optionFieldSources)) {
    return { isNew: false }
  }

  let fieldUpdatedAt: Temporal.Instant | undefined
  if (updatedAt && verifiedAt) {
    fieldUpdatedAt = max(Temporal.Instant.from(updatedAt), Temporal.Instant.from(verifiedAt))
  } else if (verifiedAt) {
    fieldUpdatedAt = Temporal.Instant.from(verifiedAt)
  } else if (updatedAt) {
    fieldUpdatedAt = Temporal.Instant.from(updatedAt)
  }

  // Once the first occurrence of the field is shown it should never be shown as new again
  const oldestFieldSource = optionFieldSources
    ?.filter((s): s is FieldMetadataWithSuggestions & { source_updated_at: string } => !isEmpty(s.source_updated_at))
    ?.sort((a, b) =>
      Temporal.Instant.compare(Temporal.Instant.from(a.source_updated_at), Temporal.Instant.from(b.source_updated_at))
    )[0]

  const colorScheme = oldestFieldSource?.type && FIELD_SOURCE_COLORS[oldestFieldSource.type]
  return {
    isNew: !!(
      !fieldUpdatedAt ||
      (oldestFieldSource &&
        Temporal.Instant.compare(fieldUpdatedAt, Temporal.Instant.from(oldestFieldSource.source_updated_at)) === -1)
    ),
    colorScheme,
    fieldSource: oldestFieldSource,
  }
}

/**
 * Looks through all suggestion values of a field and returns the first one that is new as well as its colorscheme.
 * If no suggestion is new, returns undefined.
 */
export function fieldHasNewOption(
  fieldMetadata?: FieldMetadataWithSuggestions,
  fieldApproval?: SchemaFormFieldApproval
): NewOption | undefined {
  return fieldMetadata?.suggestions
    ?.map((suggestion) => isNewOption(suggestion.field_sources, fieldMetadata, fieldApproval))
    .find(({ isNew }) => isNew)
}
