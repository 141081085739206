import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { log } from "../../util/logger.js"

interface FlagDataModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (userMessage: string) => Promise<void>
}

export function FlagDataModal({ isOpen, onClose, onSubmit }: FlagDataModalProps) {
  const intl = useIntl()
  const toast = useToast()
  const initialFocusRef = useRef<HTMLTextAreaElement>(null)

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      userMessage: "",
    },
  })

  const requiredErrorMessage = intl.formatMessage({
    id: "requests.run.step.approval.undo.reason.required",
    description: "Form error message showing that the description is required",
    defaultMessage: "Please provide a description of the bad data",
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
      }}
      initialFocusRef={initialFocusRef}
      size="md"
    >
      <ModalOverlay />
      <ModalContent
        onContextMenu={(e) => e.stopPropagation()}
        as={chakra.form}
        onSubmit={async (event) => {
          event.preventDefault()
          event.stopPropagation()
          await handleSubmit(async (data) => {
            try {
              await onSubmit(data.userMessage)
              reset()
              onClose()
              toast({
                title: intl.formatMessage({
                  id: "flagData.success.toast",
                  description: "Success toast title for flagging data",
                  defaultMessage: "Report sent",
                }),
                status: "success",
                duration: 3000,
              })
            } catch (err) {
              log.error("Error sending dynamo telemetry data report", err)
              toast({
                title: intl.formatMessage({
                  id: "flagData.error.toast",
                  description: "Error toast title for flagging data",
                  defaultMessage: "Error sending report",
                }),
                status: "error",
                duration: 3000,
              })
            }
          })(event)
        }}
      >
        <ModalHeader>
          <FormattedMessage
            id="flagData.modal.title"
            description="Title of the flag data modal"
            defaultMessage="Report bad data"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Controller
            name="userMessage"
            control={control}
            rules={{
              required: requiredErrorMessage,
              validate: (value) => {
                if (value.length <= 5) {
                  return requiredErrorMessage
                }
                return true
              },
            }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel>
                  <FormattedMessage
                    id="flagData.modal.textarea.label"
                    description="Label for the flag data textarea"
                    defaultMessage="Describe the issue with the data"
                  />
                </FormLabel>
                <Textarea {...field} ref={initialFocusRef} rows={5} maxHeight={250} />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" type="submit">
            <FormattedMessage
              id="flagData.modal.submit"
              defaultMessage="Send to BRM"
              description="Submit button text for flag data modal"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
