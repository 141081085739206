import type { ImageElement } from "@brm/schema-types/types.js"
import { Box, Modal, ModalContent, ModalOverlay, chakra, useDisclosure } from "@chakra-ui/react"
import { useReadOnly, useSelected, type RenderElementProps } from "slate-react"
import type { ExtendedElementProps } from "./types.js"

export const Image = ({
  attributes,
  children,
  element,
}: RenderElementProps & { element: ImageElement } & ExtendedElementProps) => {
  const selected = useSelected()
  const isReadonly = useReadOnly()

  const imgModal = useDisclosure()

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false}>
        <Box
          height="auto"
          contentEditable={false}
          // 2px margin to support the button focus outlines
          margin="2px"
        >
          <chakra.img
            src={element.url}
            onClick={isReadonly ? imgModal.onOpen : undefined}
            onDoubleClick={isReadonly ? undefined : imgModal.onOpen}
            // Width of the image will not exceed the container width
            // If the container is larger, the image will be max 600px wide
            maxW="min(600px, 100%)"
            width="auto"
            background="transparent"
            borderRadius="4px"
            outline={selected ? "2px solid var(--chakra-colors-brand-500)" : "none"}
          />
        </Box>
        {imgModal.isOpen && (
          <Modal onClose={imgModal.onClose} isOpen={imgModal.isOpen} isCentered size="full">
            <ModalOverlay backdropFilter="auto" backdropBlur="4px" />
            <ModalContent
              background="transparent"
              boxShadow="none"
              onClick={imgModal.onClose}
              justifyContent="center"
              alignItems="center"
            >
              <chakra.img src={element.url} padding="32px" maxHeight="100vh" background="transparent" />
            </ModalContent>
          </Modal>
        )}
      </div>
    </div>
  )
}
