import { Icon, IconButton, Tooltip, type IconButtonProps } from "@chakra-ui/react"
import type { Except } from "type-fest"
import { useSessionStorage } from "usehooks-ts"
import { DrawerIcon } from "./icons/icons.js"

interface Props extends Except<IconButtonProps, "aria-label"> {
  hideStorageKey: string
  label: string
}

/**
 * For use with session storage sidebars (eg. timeline). A button that toggles showing/hiding a timeline sidebar through session storage.
 */
export default function ShowHideDrawerButton({ hideStorageKey, label, ...iconButtonProps }: Props) {
  const [isHidden, setIsHidden] = useSessionStorage(hideStorageKey, false)
  return (
    <Tooltip closeOnClick={false} closeOnEsc={false} label={label}>
      <IconButton
        variant="outline"
        icon={<Icon as={DrawerIcon} />}
        aria-label={label}
        {...iconButtonProps}
        onClick={() => setIsHidden(!isHidden)}
      />
    </Tooltip>
  )
}
