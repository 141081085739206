import { Icon } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import FeaturedIcon from "../../components/FeaturedIcon/FeaturedIcon.js"
import { AlertIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import { TimelineAlertItem } from "../timeline/TimelineAlertItem.js"

export const DuplicateSubscriptionAlert = ({ toolOrVendorName }: { toolOrVendorName: string }) => {
  const intl = useIntl()

  return (
    <TimelineAlertItem
      key={`duplicate-subscription-alert-${toolOrVendorName}`}
      icon={
        <FeaturedIcon icon={<Icon as={AlertIcon} color="warning.700" boxSize={5} />} backgroundColor="warning.200" />
      }
      description={intl.formatMessage(
        {
          id: "tool.notification.duplicate.subscriptions.description",
          description: "duplicate subscriptions alert description",
          defaultMessage: "BRM intelligence has detected duplicative subscription agreements for {toolOrVendor}.",
        },
        {
          toolOrVendor: toolOrVendorName,
        }
      )}
      title={intl.formatMessage({
        id: "tool.notification.duplicate.subscriptions.title",
        description: "duplicate subscriptions found alert title",
        defaultMessage: "Duplicate subscriptions found",
      })}
      action={
        <Link color="brand.700" fontWeight="semibold" fontSize="sm" to="../overview#agreements" relative="path">
          <FormattedMessage
            id="tool.notification.agreementDetails.button"
            description="Notification view agreement details button"
            defaultMessage="View agreement details"
          />
        </Link>
      }
    />
  )
}
