import { formatDuration, getOneTimeFrequencyDisplayName, presetFrequencyOptions } from "@brm/util/format-date-time.js"
import { Temporal } from "@js-temporal/polyfill"
import { useMemo, type FunctionComponent } from "react"

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { FormattedMessage, FormattedDate as IntlFormattedDate, useIntl } from "react-intl"

const isPlainDate = (value: unknown): value is Temporal.PlainDate =>
  Object.prototype.toString.call(value) === "[object Temporal.PlainDate]"

export const FormattedDate: FunctionComponent<
  { value: string | Temporal.PlainDate } & React.ComponentProps<typeof IntlFormattedDate>
> = ({ value, ...rest }) => {
  if (isPlainDate(value) || /^\d{4}-\d{2}-\d{2}$/u.test(value)) {
    // For plain dates, we need to set a midnight time, because if only a YYYY-MM-DD string is provided, the legacy
    // Date() constructor will interpret the time as midnight *UTC*, which shifts the date off by one in timezones
    // west of UTC. This workaround can likely be removed once Temporal ships in browsers with updated Intl APIs
    // that directly accept Temporal.PlainDate.
    value = Temporal.PlainDate.from(value).toPlainDateTime({ hour: 0, minute: 0, second: 0 }).toString()
  }
  return <IntlFormattedDate value={value} year="numeric" month="short" day="numeric" {...rest} />
}
export const FormattedDuration: FunctionComponent<{ value: string }> = ({ value }) => {
  const intl = useIntl()
  return <>{formatDuration(intl, value)}</>
}

export const FormattedFrequency: FunctionComponent<{ value: string }> = ({ value }) => {
  const intl = useIntl()
  const presets = useMemo(() => presetFrequencyOptions(intl), [intl])

  if (value === "one_time") {
    return getOneTimeFrequencyDisplayName(intl)
  }
  const matchingPreset = presets.find(
    (preset) =>
      preset.value !== "one_time" &&
      Temporal.Duration.compare(preset.value, value, { relativeTo: Temporal.Now.plainDateISO() }) === 0
  )
  if (matchingPreset) {
    return matchingPreset.label
  }
  return (
    <FormattedMessage
      id="frequency.value"
      defaultMessage="every {frequency}"
      description="Frequency display value"
      values={{ frequency: formatDuration(intl, value) }}
    />
  )
}
