import type { Person } from "@brm/schema-types/types.js"
import { displayPersonName, type DisplayablePerson } from "@brm/util/names.js"
import { useIntl } from "react-intl"
import type { CellSize } from "../../components/DataTable/CellRenderer/util.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import PickableEntityDisplay from "../organization/PickableEntityDisplay.js"

export interface PersonDisplayProps {
  person: Pick<Person, "profile_image"> & DisplayablePerson
  subText?: string | null
  size?: CellSize
  organizationLogoGcsFileName?: string | null
}

export default function PersonDisplay({
  organizationLogoGcsFileName,
  person,
  subText,
  size = "md",
}: PersonDisplayProps) {
  const intl = useIntl()
  return (
    <PickableEntityDisplay
      image={getPublicImageGcsUrl(person.profile_image?.gcs_file_name)}
      size={size}
      displayText={displayPersonName(person, intl)}
      subText={subText}
      organizationLogoGcsFileName={organizationLogoGcsFileName}
    />
  )
}
