import type { CurrencyAmount } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { useIntl } from "react-intl"

export const FormattedCurrency: React.FunctionComponent<{
  currencyAmount: CurrencyAmount
}> = ({ currencyAmount }) => {
  const intl = useIntl()
  return <>{formatCurrency(currencyAmount, intl)}</>
}
