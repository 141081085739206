import type { AgreementEffectiveStatus } from "@brm/schema-types/types.js"
import { Badge } from "@chakra-ui/react"
import { displayLegalAgreementStatus, legalAgreementStatusColors } from "./helpers.js"

interface Props {
  status: AgreementEffectiveStatus | null
}

export default function LegalAgreementStatusBadge({ status }: Props) {
  if (!status || status === "unknown") return null

  return (
    <Badge variant="subtleOutlined" colorScheme={legalAgreementStatusColors(status)} size="sm" fontSize="xs">
      {displayLegalAgreementStatus(status)}
    </Badge>
  )
}
