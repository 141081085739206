import type { WorkflowRun } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { HStack, Icon, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { FormattedDate } from "../../../components/FormattedDate.js"
import { Timestamp } from "../../../components/Timestamp.js"
import { ClockIcon } from "../../../components/icons/icons.js"

// Displays the due date of a workflow run if it is not yet completed
// Otherwise, displays the completed date taking into account the final status of the workflow run
export default function WorkflowRunDueDateIndicator({ workflowRun }: { workflowRun: WorkflowRun }) {
  const intl = useIntl()
  if (workflowRun.completed_at) {
    if (workflowRun.status === "in_progress") {
      throw new Error(
        "WorkflowRunDueDateIndicator: completed_at and status are inconsistent. A workflow run with status in_progress should not have completed_at date set"
      )
    }
    return workflowRun.status === "approved" ? (
      <Text color="brand.500">
        <FormattedMessage
          id="requests.completed.date"
          defaultMessage="Completed on {completedDate}"
          description="Date the request was completed on"
          values={{ completedDate: <FormattedDate value={workflowRun.completed_at} /> }}
        />
      </Text>
    ) : workflowRun.status === "rejected" ? (
      <Text color="error">
        <FormattedMessage
          id="requests.completed.date"
          defaultMessage="Rejected on {completedDate}"
          description="Date the request was rejected on"
          values={{ completedDate: <FormattedDate value={workflowRun.completed_at} /> }}
        />
      </Text>
    ) : workflowRun.status === "cancelled" ? (
      <Text>
        <FormattedMessage
          id="requests.completed.date"
          defaultMessage="Canceled on {completedDate}"
          description="Date the request was canceled on"
          values={{ completedDate: <FormattedDate value={workflowRun.completed_at} /> }}
        />
      </Text>
    ) : (
      unreachable(workflowRun.status)
    )
  }

  return (
    <HStack>
      <Icon as={ClockIcon} />
      <Timestamp
        dateTime={workflowRun.target_date}
        linkProps={{
          fontWeight: "semibold",
        }}
        prefix={intl.formatMessage({
          id: "due.prefix",
          defaultMessage: "Due ",
          description:
            "Prefix for a due date timestamp that will be inserted before the timestamp content. Ex/ `Due 2 days ago` or `Due in a month`",
        })}
      />
    </HStack>
  )
}
