import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  Card,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1SettingsQuery,
  usePostUserV1SettingsTotpDisableMutation,
  usePostUserV1SettingsTotpEnableMutation,
} from "../../app/services/generated-api.js"
import PasswordField from "../../components/Form/PasswordField.js"
import { getAPIErrorMessage } from "../../util/error.js"

export default function AuthenticationSettings() {
  const { data: settings } = useGetUserV1SettingsQuery()
  const [enableTwoFA, enableResult] = usePostUserV1SettingsTotpEnableMutation()
  const [disableTwoFA, disableResult] = usePostUserV1SettingsTotpDisableMutation()
  const { isOpen: isSetupOpen, onOpen: onSetupOpen, onClose: onSetupClose } = useDisclosure()
  const { isOpen: isDisableOpen, onOpen: onDisableOpen, onClose: onDisableClose } = useDisclosure()
  const [token, setToken] = useState("")
  const [password, setPassword] = useState("")
  const intl = useIntl()
  const enableFocusRef = useRef(null)
  const disableCancelRef = useRef(null)

  if (!settings) {
    return null
  }

  const enableErrorMessage =
    enableResult.isError &&
    (getAPIErrorMessage(enableResult.error) ??
      intl.formatMessage({
        id: "organization.view.settings.2fa.enable-error",
        description: "generic 2fa enable error message",
        defaultMessage: "Failed to enable 2FA. Refresh the page and try again.",
      }))

  const disableErrorMessage =
    disableResult.isError &&
    (getAPIErrorMessage(disableResult.error) ??
      intl.formatMessage({
        id: "organization.view.settings.2fa.disable-error",
        description: "generic 2fa disable error message",
        defaultMessage: "Failed to disable 2FA. Refresh the page and try again.",
      }))

  return (
    <>
      <Card variant="outline" padding={5} flexDir="row" alignItems="center" justifyContent="space-between" gap={4}>
        <Stack spacing="0.5" fontSize="md">
          <Text fontWeight="medium">
            <FormattedMessage
              id="organization.view.settings.2fa.title"
              description="Require Users to Login with 2FA"
              defaultMessage="Require Two-Factor Authentication (2FA)"
            />
          </Text>
          <Text color="gray.600" fontSize="sm">
            <FormattedMessage
              id="organization.view.settings.2fa.description"
              description="Require 2fa long description"
              defaultMessage="Use a second factor in addition to your password when logging in to increase security."
            />
          </Text>
        </Stack>
        <Switch
          isChecked={settings?.totp_enabled}
          onChange={async (e) => {
            if (e.target.checked) {
              onSetupOpen()
            } else {
              onDisableOpen()
            }
          }}
        />
      </Card>

      <Modal isOpen={isSetupOpen} onClose={onSetupClose} initialFocusRef={enableFocusRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={5}>
            <Image src={`${import.meta.env.VITE_API_BASE_URL}/user/v1/settings/totp/qr`} />

            <FormattedMessage
              id="organization.view.settings.2fa.instructions"
              description="2fa instructions"
              defaultMessage="Use your preferred authenticator app to scan the QR code above and add a new account. Enter the token below to enable two factor authentication."
            />

            <Input
              value={token}
              ref={enableFocusRef}
              onChange={(e) => setToken(e.target.value)}
              mt={5}
              type="text"
              name="token"
              id="token"
              inputMode="numeric"
              pattern="[0-9]*"
              autoComplete="one-time-code"
            />
            {enableErrorMessage && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                <AlertDescription>{enableErrorMessage}</AlertDescription>
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onSetupClose}>
              <FormattedMessage
                id="organization.view.settings.2fa.cancel"
                description="Cancel 2fa"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              isLoading={enableResult.isLoading}
              colorScheme="brand"
              mr={3}
              onClick={async () => {
                await enableTwoFA({ body: { token } }).unwrap()
                setToken("")
                onSetupClose()
              }}
            >
              <FormattedMessage
                id="organization.view.settings.2fa.enable"
                description="Enable 2fa"
                defaultMessage="Enable"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isDisableOpen} leastDestructiveRef={disableCancelRef} onClose={onDisableClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <FormattedMessage
                id="organization.view.settings.2fa.disable-title"
                description="2fa disable"
                defaultMessage="Disable"
              />
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb={5}>
                <FormattedMessage
                  id="organization.view.settings.2fa.disable-description"
                  description="2fa disable description"
                  defaultMessage="Are you sure you want to disable 2FA? You will be able to log in with username and password only."
                />
              </Text>
              <PasswordField onChange={(e) => setPassword(e.target.value)} />
              {disableErrorMessage && (
                <Alert status="error" mt={5}>
                  <AlertIcon />
                  <AlertDescription>{disableErrorMessage}</AlertDescription>
                </Alert>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={disableCancelRef} onClick={onDisableClose}>
                <FormattedMessage
                  id="organization.view.settings.2fa.disable-cancel"
                  description="2fa cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                colorScheme="error"
                onClick={async () => {
                  await disableTwoFA({ body: { password } }).unwrap()
                  setPassword("")
                  onDisableClose()
                }}
                ml={3}
              >
                <FormattedMessage
                  id="organization.view.settings.2fa.disable-title"
                  description="2fa disable"
                  defaultMessage="Disable"
                />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
