import type { OrganizationOktaSSOConfig } from "@brm/schema-types/types.js"
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  chakra,
  useBoolean,
  useToast,
} from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePutOrganizationV1UpdateMutation } from "../../app/services/generated-api.js"
import { prependHttps } from "../../util/url.js"

export default function OktaSettings({
  organizationId,
  oktaSettings,
}: {
  organizationId: string
  oktaSettings: OrganizationOktaSSOConfig
}) {
  const toast = useToast()
  const intl = useIntl()
  const [updateOrganization, { isLoading }] = usePutOrganizationV1UpdateMutation()
  const [showClientSecret, setShowClientSecret] = useBoolean(false)

  const form = useForm({
    defaultValues: {
      okta_domain: oktaSettings.okta_domain ?? "",
      okta_client_id: oktaSettings.okta_client_id ?? "",
      okta_client_secret: oktaSettings.okta_client_secret ?? "",
    },
  })

  const successToast = () => {
    toast({
      description: intl.formatMessage({
        id: "organization.okta.settings-change.success",
        description: "Toast success message when saving org settings",
        defaultMessage: "Okta settings saved successfully",
      }),
      status: "success",
    })
  }

  const errorToast = () => {
    toast({
      description: intl.formatMessage({
        id: "organization.settings-change.failure",
        description: "Toast failure message when saving org settings",
        defaultMessage: "Something went wrong while saving your settings",
      }),
      status: "error",
    })
  }

  return (
    <Stack spacing={2}>
      <Heading as="h2" size="xs">
        <FormattedMessage
          id="organization.view.okta.settings"
          description="Organization okta authentication settings"
          defaultMessage="Okta Single Sign On"
        />
      </Heading>
      <Stack
        as={chakra.form}
        gap={4}
        onSubmit={form.handleSubmit(async (values) => {
          try {
            await updateOrganization({
              updateOrganization: {
                id: organizationId,
                okta_client_id: values.okta_client_id,
                okta_client_secret: values.okta_client_secret,
                okta_domain: prependHttps(values.okta_domain),
              },
            }).unwrap()
            successToast()
          } catch (_) {
            errorToast()
          }
        })}
      >
        <Controller
          control={form.control}
          name="okta_client_id"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor={field.name}>
                <FormattedMessage
                  id="organization.okta.settings.form.okta_client_id"
                  description="Label for Okta client ID field in organization settings update form"
                  defaultMessage="Client ID"
                />
              </FormLabel>
              <Input {...field} id={field.name} type="text" data-1p-ignore />
            </FormControl>
          )}
        />
        <Controller
          control={form.control}
          name="okta_client_secret"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor={field.name}>
                <FormattedMessage
                  id="organization.okta.settings.form.okta_client_secret"
                  description="Label for Okta client secret field in organization settings update form"
                  defaultMessage="Client Secret"
                />
              </FormLabel>
              <InputGroup>
                <Input {...field} id={field.name} type={showClientSecret ? "text" : "password"} data-1p-ignore />
                <InputRightElement mr={2}>
                  <Button size="xs" onClick={setShowClientSecret.toggle}>
                    {showClientSecret ? (
                      <FormattedMessage
                        id="form.input.hide"
                        defaultMessage="Hide"
                        description="Button label to hide a secret input value"
                      />
                    ) : (
                      <FormattedMessage
                        id="form.input.show"
                        defaultMessage="Show"
                        description="Button label to show a secret input value"
                      />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        />
        <Controller
          control={form.control}
          name="okta_domain"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor={field.name}>
                <FormattedMessage
                  id="organization.okta.settings.form.okta_domain"
                  description="Label for Okta domain field in organization settings update form"
                  defaultMessage="Okta Domain"
                />
              </FormLabel>
              <Input
                {...field}
                id={field.name}
                placeholder={intl.formatMessage({
                  id: "organization.okta.settings.form.okta_domain.placeholder",
                  defaultMessage: "https://example.okta.com",
                  description: "Placeholder for Okta domain field in organization settings update form",
                })}
              />
            </FormControl>
          )}
        />
        <HStack justifyContent="end">
          <Button type="submit" colorScheme="brand" isLoading={isLoading}>
            <FormattedMessage
              id="organization.okta.settings.form.submit"
              description="Button label for Okta settings update form"
              defaultMessage="Save"
            />
          </Button>
        </HStack>
      </Stack>
    </Stack>
  )
}
