import { hasPermission } from "@brm/schema-helpers/role.js"
import type { ToolDetails } from "@brm/schema-types/types.js"
import { Box, HStack, Heading, Stack } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { AgreementTcvChartCard } from "../charts/AgreementTcvChartCard.js"
import { SpendChartCard } from "../charts/SpendChartCard.js"
import { UserActivityChartCard } from "../charts/UserActivityChartCard.js"
import { smallChartHeight } from "../charts/constants.js"
import { getDefaultSpendChartXAxisProps, getDefaultSpendChartYAxisProps } from "../charts/utils.js"
import { LegalAgreementOverview } from "../legal/LegalAgreementOverview.js"
import { WorkflowRunSorOverview } from "../workflows/run/WorkflowRunSorOverview.js"
import ToolDepartmentPie from "./charts/ToolDepartmentPie.js"
import ToolUsagePie from "./charts/ToolUsagePie.js"

interface Props {
  tool: ToolDetails
}

export default function ToolOverview({ tool }: Props) {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const location = useLocation()
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const canViewSpend = (tool.owner && tool.owner.user_id === whoami?.id) || hasPermission(whoami?.roles, "spend:read")
  const agreementHash = location.hash.includes("agreements")

  useEffect(() => {
    if (agreementHash && ref.current) {
      ref.current.scrollIntoView()
      // Reset hash to avoid scrolling to the same position on re-renders
      navigate({ ...location, hash: "" })
    }
  }, [agreementHash, location, navigate])

  return (
    <Stack width="full" gap={8}>
      <WorkflowRunSorOverview workflowRuns={tool.workflow_runs} sorDisplayName={tool.display_name} />
      {canViewSpend && (
        <Stack className="spendSection" sx={{ "&:has(> .chartsContainer:empty)": { display: "none" } }}>
          <Heading size="xs">
            <FormattedMessage id="tool.header.spend" description="Tool view spend" defaultMessage="Spend" />
          </Heading>
          <HStack flexWrap="wrap" gap={6} className="chartsContainer">
            <SpendChartCard
              entityParams={{ type: "tool", id: tool.id }}
              chartHeight={smallChartHeight}
              axisProps={[getDefaultSpendChartYAxisProps(intl), getDefaultSpendChartXAxisProps(intl)]}
              xyChartProps={{ margin: { left: 50, top: 20, right: 30, bottom: 40 } }}
            />
            <AgreementTcvChartCard agreements={tool.legal_agreements} entityParams={{ type: "tool", id: tool.id }} />
          </HStack>
        </Stack>
      )}
      <Stack className="userSection" sx={{ "&:has(> .chartsContainer:empty)": { display: "none" } }}>
        <Heading size="xs">
          <FormattedMessage
            id="tool.header.users"
            description="Heading for users that have logged into the tool"
            defaultMessage="Users"
          />
        </Heading>
        <HStack flexWrap="wrap" gap={6} className="chartsContainer">
          <UserActivityChartCard toolListingId={tool.tool_listing_id} chartHeight={smallChartHeight} />
          <ToolUsagePie usersCount={tool.okta_users_count} />
          <ToolDepartmentPie
            departmentUserCounts={Object.values(tool.department_users)}
            totalUsers={tool.total_people ?? 0}
          />
        </HStack>
      </Stack>
      <Box ref={ref}>
        <LegalAgreementOverview tool={tool} />
      </Box>
    </Stack>
  )
}
