import type { FormFieldConfig, WorkflowConditionGroupInput } from "@brm/schema-types/types.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { Box, Button, HStack, Icon, Spinner, Stack, Text, useToast } from "@chakra-ui/react"
import { forwardRef, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { type Descendant } from "slate"
import { usePostWorkflowV1DefinitionsConditionalMutation } from "../../../app/services/generated-api.js"
import { EMPTY_RICH_TEXT_BODY, isEmptyRichText } from "../../../components/RichTextEditor/util/common.js"
import SuggestedPromptBadge from "../../../components/SuggestedPromptBadge/SuggestedPromptBadge.js"
import { WriteIcon } from "../../../components/icons/icons.js"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"
import { useObjectSchemasMap } from "../../../util/use-schema.js"
import type { ConditionGroupProps } from "./ConditionGroup.js"
import ConditionGroup from "./ConditionGroup.js"
import ConditionalEditor from "./ConditionalEditor.js"
import {
  getSuggestedConditionalPrompts,
  serializeConditionalGenerationInput,
  type SuggestedConditionalPrompt,
} from "./utils.js"

interface ConditionalPromptProps extends ConditionGroupProps {
  getEligibleFields: () => FormFieldConfig[]
  onChange: (conditionalSchema: WorkflowConditionGroupInput) => void
}

const ConditionalPrompt = forwardRef<{ focus: () => void }, ConditionalPromptProps>(function ConditionalPrompt(
  { getEligibleFields, onChange, ...conditionGroupProps },
  ref
) {
  const intl = useIntl()
  const toast = useToast()

  // Force a reset of the conditional group rendering when we generate a new full conditional
  const [conditionalResetId, setConditionalResetId] = useState<number>(0)
  const [conditionalPromptResetId, setConditionalPromptResetId] = useState<number>(0)
  const [conditionalPrompt, setConditionalPrompt] = useState<Descendant[]>(EMPTY_RICH_TEXT_BODY)
  const [generateConditional, { isLoading: generateConditionalIsLoading }] =
    usePostWorkflowV1DefinitionsConditionalMutation()

  const eligibleFields = useMemo(() => getEligibleFields(), [getEligibleFields])
  const uniqueObjectTypes = useMemo(
    () => Array.from(new Set(eligibleFields.map((field) => field.object_type))),
    [eligibleFields]
  )
  const objectSchemas = useObjectSchemasMap(uniqueObjectTypes)
  const eligibleFieldsWithDisplayName = useMemo(() => {
    // Augment the display name from the field schema to the eligible fields array
    return eligibleFields.map((field) => {
      const schema = objectSchemas?.[field.object_type]
      const fieldSchema = getSchemaAtPath(schema, field.field_name)
      return {
        ...field,
        display_name: getTitle(field.field_name, fieldSchema),
      }
    })
  }, [eligibleFields, objectSchemas])

  const suggestedPrompts = useMemo(() => {
    const eligibleFieldPrompts: SuggestedConditionalPrompt[] = []
    const promptsMap = getSuggestedConditionalPrompts(intl)
    for (const field of eligibleFields) {
      const prompt = promptsMap[field.object_type]?.[field.field_name]
      if (prompt) {
        eligibleFieldPrompts.push(prompt)
        if (eligibleFieldPrompts.length === 3) {
          // Return early if we have 3 prompts
          return eligibleFieldPrompts
        }
      }
    }
    // Return any prompts that were found, can be empty
    return eligibleFieldPrompts
  }, [intl, eligibleFields])

  const handleGenerateConditional = async () => {
    try {
      const promptText = conditionalPrompt.map(serializeConditionalGenerationInput).join("\n")
      const conditionalSchema = await generateConditional({
        body: {
          prompt: promptText,
          eligible_fields: eligibleFields,
        },
      }).unwrap()
      onChange(structuredClone(conditionalSchema))
      setConditionalResetId((prev) => prev + 1)
    } catch (err) {
      log.error("Failed to generate conditional", err)
      toast({
        isClosable: true,
        duration: LONG_TOAST_DURATION,
        status: "error",
        description:
          getAPIErrorMessage(err) ??
          intl.formatMessage({
            id: "request.config.step.prompt.error",
            description: "Error message when generating a conditional fails",
            defaultMessage: "Something went wrong while generating a conditional",
          }),
      })
    }
  }

  return (
    <Stack>
      <Box>
        <ConditionalEditor
          ref={ref}
          key={conditionalPromptResetId}
          initialValue={conditionalPrompt}
          onChange={(value) => setConditionalPrompt(value)}
          eligibleFields={eligibleFieldsWithDisplayName}
          containerProps={{
            onKeyDown: async (event) => {
              if (event.key === "Enter" && (event.metaKey || event.ctrlKey) && !generateConditionalIsLoading) {
                await handleGenerateConditional()
              }
            },
          }}
        />
      </Box>
      {isEmptyRichText(conditionalPrompt) && suggestedPrompts.length > 0 && (
        <HStack flexWrap="wrap">
          {suggestedPrompts.map((prompt) => (
            <SuggestedPromptBadge
              key={prompt.label}
              prompt={prompt.label}
              onClick={() => {
                setConditionalPrompt(prompt.richTextPrompt)
                // Reset the conditional editor to trigger a re-render of the editor
                setConditionalPromptResetId((prev) => prev + 1)
              }}
            />
          ))}
        </HStack>
      )}
      <HStack justify="flex-end">
        {generateConditionalIsLoading ? (
          <HStack>
            <Spinner />
            <Text>
              <FormattedMessage
                id="request.config.step.prompt.button.loading"
                description="Button text to add a new conditional prompt to a step"
                defaultMessage="Generating conditional..."
              />
            </Text>
          </HStack>
        ) : (
          <Button colorScheme="brand" leftIcon={<Icon as={WriteIcon} />} onClick={handleGenerateConditional}>
            <FormattedMessage
              id="request.config.step.prompt.button"
              description="Button text to add a new conditional prompt to a step"
              defaultMessage="Generate"
            />
          </Button>
        )}
      </HStack>
      <ConditionGroup key={conditionalResetId} {...conditionGroupProps} />
    </Stack>
  )
})

export default ConditionalPrompt
