import type { PermissionedEntityType, User } from "@brm/schema-types/types.js"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  chakra,
  Stack,
  useToast,
} from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useDeleteUserV1ByIdMutation,
  useGetUserV1DeletionRequirementsByIdQuery,
} from "../../app/services/generated-api.js"
import OrganizationEntityPicker from "../../components/Form/OrganizationEntityPicker.js"
import { getAPIErrorMessage } from "../../util/error.js"

export function DeleteUserConfirmationModal({
  userBeingDeleted,
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
  userBeingDeleted: User
}) {
  const intl = useIntl()
  const toast = useToast()

  const cancelDeleteUserRef = useRef<HTMLButtonElement>(null)
  const { data: deletionRequirement, refetch: refetchDeletionRequirement } = useGetUserV1DeletionRequirementsByIdQuery({
    id: userBeingDeleted.id,
  })
  const [deleteUser, deleteUserResult] = useDeleteUserV1ByIdMutation()
  const [newOwnerId, setNewOwnerId] = useState<string | undefined>()

  useEffect(() => {
    if (
      deleteUserResult.isError &&
      ("status" in deleteUserResult.error ? deleteUserResult.error.status === 428 : true)
    ) {
      void refetchDeletionRequirement()
    }
  }, [refetchDeletionRequirement, deleteUserResult.isError, deleteUserResult.error])

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelDeleteUserRef}>
      <AlertDialogOverlay>
        {userBeingDeleted && (
          <AlertDialogContent width="400px" gap={2}>
            <AlertDialogCloseButton />
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <FormattedMessage
                id="organization.view.users.delete.confirm.header"
                description="Modal header for delete user confirmation"
                defaultMessage="Delete user"
              />
            </AlertDialogHeader>

            <AlertDialogBody color="gray.600">
              {deletionRequirement?.requirement ? (
                <Stack gap={2}>
                  <chakra.span>
                    <FormattedMessage
                      id="organization.view.users.delete.newOwnerRequired.message"
                      description="Confirmation text for user deletion and requesting a new owner for the their requests."
                      defaultMessage="Select a new owner for <strong>{email}’s</strong> pending requests, tools and vendors. The new owner will be notified of the transfer. This action cannot be undone."
                      values={{
                        email: userBeingDeleted.email,
                        strong: (content) => <strong>{content}</strong>,
                      }}
                    />
                  </chakra.span>
                  <chakra.span color="gray.700" fontWeight="medium" pt={2}>
                    <FormattedMessage
                      id="organization.view.users.delete.newOwnerRequired.label"
                      description="Label for the new owner picker in the delete user confirmation modal"
                      defaultMessage="New Owner"
                    />
                  </chakra.span>
                  <OrganizationEntityPicker
                    includedEntities={["user"]}
                    ignoreMap={new Map<PermissionedEntityType, Set<string>>([["user", new Set([userBeingDeleted.id])]])}
                    permission={
                      deletionRequirement.requirement === "replacement_approver"
                        ? {
                            value: "workflow:approve",
                            includeAll: true,
                            defaultInviteRole: "compliance_approver",
                          }
                        : undefined
                    }
                    onChangeUser={(user) => user && setNewOwnerId(user.id)}
                    // The entity picker is rendered in a modal, so we shouldn't make the portal target document body
                    menuPortalTarget={undefined}
                  />
                </Stack>
              ) : (
                <FormattedMessage
                  id="organization.view.users.delete.confirm.body"
                  description="Body text of the user deletion confirmation modal"
                  defaultMessage="Are you sure you want to delete <strong>{email}</strong>? This action cannot be undone."
                  values={{
                    email: userBeingDeleted.email,
                    strong: (content) => <strong>{content}</strong>,
                  }}
                />
              )}
            </AlertDialogBody>

            <AlertDialogFooter pt={4}>
              <Button ref={cancelDeleteUserRef} onClick={onClose} variant="outline" flexGrow={1}>
                <FormattedMessage
                  id="organization.view.users.delete.confirm.keep"
                  description="Button text for keeping user in the delete user confirmation modal."
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                colorScheme="error"
                flexGrow={1}
                onClick={async () => {
                  try {
                    await deleteUser({
                      id: userBeingDeleted.id,
                      body: {
                        newOwnerId,
                      },
                    }).unwrap()
                    toast({
                      description: intl.formatMessage({
                        id: "organization.deleteUser.toastMsg.success",
                        description: "Toast success message when deleting a user",
                        defaultMessage: "User deleted!",
                      }),
                      status: "success",
                    })
                    onClose()
                  } catch (_) {
                    toast({
                      description: intl.formatMessage({
                        id: "organization.deleteUser.toastMsg.failure",
                        description: "Toast failure message when deleting a user",
                        defaultMessage: getAPIErrorMessage(deleteUserResult.error) ?? "Failed to delete user",
                      }),
                      status: "error",
                    })
                  }
                }}
                ml={3}
              >
                <FormattedMessage
                  id="organization.view.users.delete.confirm.delete"
                  description="Button text for deleting user in the delete user confirmation modal."
                  defaultMessage="Delete"
                />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        )}
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
