import type { IntegrationProvider, LoginScopeSummary, Scope } from "@brm/schema-types/types.js"
import { isNotUndefined } from "typed-assert"

// Custom ordering to show gmail scope groups in for all scenarios
const SCOPE_GROUP_ORDERING = ["Gmail", "Calendar", "Admin", "Drive", "Contacts", "Google Sign-in", "Other"]

interface ToolScopeSummary {
  groupName: string
  scopes: Scope[]
}

export function packageLoginScopesByResourceGroup(
  scopeSummary: Record<string, Scope[]>
): Record<IntegrationProvider, ToolScopeSummary[]> {
  const groupedScopes: Record<string, ToolScopeSummary[]> = {}
  Object.entries(scopeSummary).forEach(([provider, providerScopes]) => {
    const groups: Record<string, Scope[]> = {}
    providerScopes.forEach((scope) => {
      const resourceGroup = scope.resource_group || "Other"
      const maybeExists = groups[resourceGroup]
      if (maybeExists) {
        maybeExists.push(scope)
      } else {
        groups[resourceGroup] = [scope]
      }
    })
    const scopeGroupsForProvider = Object.entries(groups).map(([groupName, scopes]) => {
      const sortedScopes = scopes.sort((a, b) => {
        return b.risk_score - a.risk_score
      })
      return {
        groupName,
        scopes: sortedScopes,
      }
    })
    const sortedGroups = scopeGroupsForProvider.sort((a, b) => {
      return (
        SCOPE_GROUP_ORDERING.findIndex((el) => el === a.groupName) -
        SCOPE_GROUP_ORDERING.findIndex((el) => el === b.groupName)
      )
    })
    groupedScopes[provider] = sortedGroups
  })
  return groupedScopes
}

export interface ScopeSummaryRow {
  provider?: string
  resourceGroup?: string
  scope?: string
  description?: string
  toolId?: string
  logoGcsFileName?: string | null
  displayName?: string
  toolCount?: number
  riskScore?: number
  subRows?: ScopeSummaryRow[]
}

interface ToolScopesMap {
  displayName: string
  toolId: string
  logoGcsFileName?: string | null
  riskScore: number
  scopes: { scope: string; description: string; riskScore: number }[]
}

interface GroupToolsMap {
  resourceGroup: string
  toolsMap: Record<string, ToolScopesMap>
}

export function packageLoginScopesForDataTable(
  scopeSummary: LoginScopeSummary,
  collator: Intl.Collator
): ScopeSummaryRow[] {
  const byProvider: ScopeSummaryRow[] = []
  Object.entries(scopeSummary).forEach(([provider, providerScopes]) => {
    const groups: Record<string, GroupToolsMap> = {}

    providerScopes.forEach((scope) => {
      // Package tools by resource group
      const resourceGroup = scope.resource_group || "Other"
      if (!groups[resourceGroup]) {
        groups[resourceGroup] = {
          resourceGroup,
          toolsMap: {},
        }
      }
      const groupsMap = groups[resourceGroup]
      isNotUndefined(groupsMap)
      const currentScope = {
        scope: scope.scope,
        description: scope.description || "",
        riskScore: scope.risk_score,
      }
      scope.tools.forEach((tool) => {
        const toolMap = groupsMap.toolsMap[tool.display_name]
        if (!toolMap) {
          groupsMap.toolsMap[tool.display_name] = {
            displayName: tool.display_name,
            toolId: tool.id,
            logoGcsFileName: tool.image_asset?.gcs_file_name,
            riskScore: scope.risk_score,
            scopes: [currentScope],
          }
        } else {
          // Update risk score for tool if current scope risk score is higher
          toolMap.riskScore = Math.max(toolMap.riskScore, scope.risk_score)
          toolMap.scopes.push(currentScope)
        }
      })
    })

    const groupsByProvider = Object.values(groups).map((toolByGroup) => {
      const toolsForGroup = Object.values(toolByGroup.toolsMap).map((scopesByTool) => {
        // Sort scopes by highest risk score first
        scopesByTool.scopes.sort((a, b) => {
          return b.riskScore - a.riskScore
        })
        return {
          displayName: scopesByTool.displayName,
          toolId: scopesByTool.toolId,
          logoGcsFileName: scopesByTool.logoGcsFileName,
          riskScore: scopesByTool.riskScore,
          subRows: scopesByTool.scopes,
        }
      })
      // Sort tools by highest risk score first
      // secondarily by tool name
      toolsForGroup.sort((a, b) => {
        if (a.riskScore === b.riskScore) {
          return collator.compare(a.displayName, b.displayName)
        }
        return b.riskScore - a.riskScore
      })
      return {
        resourceGroup: toolByGroup.resourceGroup,
        toolCount: Object.keys(toolByGroup.toolsMap).length,
        subRows: toolsForGroup,
      }
    })

    groupsByProvider.sort((a, b) => {
      return (
        SCOPE_GROUP_ORDERING.findIndex((el) => el === a.resourceGroup) -
        SCOPE_GROUP_ORDERING.findIndex((el) => el === b.resourceGroup)
      )
    })

    byProvider.push({
      provider,
      subRows: groupsByProvider,
    })
  })

  byProvider.sort((a, b) => collator.compare(a.provider ?? "", b.provider ?? ""))

  return byProvider
}
