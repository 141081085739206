import type { PickableEntityFilter } from "@brm/schema-types/types.js"
import { useMemo } from "react"
import { usePostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveMutation } from "../../../../../app/services/generated-api.js"
import type { GetLogoForOrganizationProps } from "../../utils.js"
import WorkflowRunStepUnapproveButtonCore from "./WorkflowRunStepUnapproveButtonCore.js"

export default function WorkflowRunStepUnapproveButton({
  workflowRunStepId,
  workflowRunId,
  workflowRunStepDisplayName,
  workflowRunStepGathererName,
  getLogoToShowByOrganizationId,
}: {
  workflowRunStepId: string
  workflowRunId: string
  workflowRunStepDisplayName: string
  workflowRunStepGathererName: string
} & GetLogoForOrganizationProps) {
  const formId = `unapprove-step-form:${workflowRunStepId}`
  const [unapproveStep, { isLoading }] = usePostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveMutation()

  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(
    () =>
      workflowRunId
        ? {
            requesting_entity: { object_type: "WorkflowRun", object_id: workflowRunId },
            entities: ["workflow_seller", "workflow_buyer"],
          }
        : {},
    [workflowRunId]
  )

  return (
    <WorkflowRunStepUnapproveButtonCore
      formId={formId}
      workflowRunStepDisplayName={workflowRunStepDisplayName}
      workflowRunStepGathererName={workflowRunStepGathererName}
      getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
      onUnapprove={async (reason) => {
        await unapproveStep({ workflowRunStepId, body: { reason } }).unwrap()
      }}
      isSubmitting={isLoading}
      pickableEntityFilters={pickableEntityFilters}
    />
  )
}
