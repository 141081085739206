import { hasPermission } from "@brm/schema-helpers/role.js"
import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type {
  WorkflowRunFieldPath,
  WorkflowRunStatePatch,
  WorkflowRunStepDecision,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import type { Theme } from "@brm/theme"
import { displayPersonName } from "@brm/util/names.js"
import { dereferenceSchema } from "@brm/util/schema.js"
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
  useTheme,
  useToast,
  type MenuItemProps,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useSearchParams } from "react-router-dom"
import type { ReadonlyDeep } from "type-fest"
import { isInstanceOf, isNotVoid } from "typed-assert"
import { useResizeObserver, useSessionStorage } from "usehooks-ts"
import { churnEmailAssistEnabled } from "../../../app/feature-flags.js"
import {
  useGetUserV1WhoamiQuery,
  useLazyGetTimelineV1WorkflowRunByWorkflowRunIdQuery,
  useLazyGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery,
  usePostWorkflowV1StepRunsByIdDraftMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionMutation,
} from "../../../app/services/generated-api.js"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { useContextMenu } from "../../../components/ContextMenu/context-menu.js"
import ToolCell from "../../../components/DataTable/CellRenderer/ToolCell.js"
import { DividedRowSubHeader } from "../../../components/DividedRowSubHeader.js"
import FeaturedIcon from "../../../components/FeaturedIcon/FeaturedIcon.js"
import { Link } from "../../../components/Link.js"
import { LogoHeader } from "../../../components/LogoHeader.js"
import ShowHideTimelineButton from "../../../components/ShowHideDrawerButton.js"
import SessionStorageTimelineContainer from "../../../components/Timeline/SessionStorageTimelineContainer.js"
import { logoHeaderBoxSize } from "../../../components/constants.js"
import { RequestLogo } from "../../../components/icons/Logo.js"
import {
  CancelIcon,
  ChampIcon,
  ChangeApproversIcon,
  EditIcon,
  InfoIcon,
  MailIcon,
  MoreMenuIcon,
  TrashIcon,
  UserEditIcon,
} from "../../../components/icons/icons.js"
import { sortedBfs } from "../../../util/graph.js"
import { log } from "../../../util/logger.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import PickableEntityDisplay from "../../organization/PickableEntityDisplay.js"
import SellerUsersAvatarGroup from "../SellerUsersAvatarGroup.js"
import WorkflowRunKindBadge from "../WorkflowRunKindBadge.js"
import WorkflowStepIcon from "../WorkflowStepIcon.js"
import { CancelWorkflowRunModal } from "./CancelWorkflowRunModal.js"
import { ChurnConfirmationDialog, ChurnEmailModal } from "./ChurnEmailModal.js"
import { DeleteWorkflowRunModal } from "./DeleteWorkflowRunModal.js"
import SellerDueDateIndicator from "./SellerDueDateIndicator.js"
import { ShareBrmLinkPopover } from "./ShareBrmLinkPopover.js"
import WorkflowRunContextSummary from "./WorkflowRunContextSummary.js"
import WorkflowRunContextSummaryExternal from "./WorkflowRunContextSummaryExternal.js"
import WorkflowRunDueDateIndicator from "./WorkflowRunDueDateIndicator.js"
import { WorkflowRunStatusBadge } from "./WorkflowRunStatusBadge.js"
import WorkflowRunStatusIcon from "./WorkflowRunStatusIcon.js"
import WorkflowRunStepHeader from "./WorkflowRunStepHeader.js"
import { UpdateWorkflowRunModal } from "./WorkflowRunUpdateModal.js"
import { BrmLinkWorkflowRunStepForm } from "./step/BrmLinkWorkflowRunStepForm.js"
import { WorkflowRunStepForm } from "./step/WorkflowRunStepForm.js"
import WorkflowRunStepSubmitButtons from "./step/WorkflowRunStepSubmitButtons.js"
import WorkflowRunStepsUpdateModal from "./step/WorkflowRunStepsUpdateModal.js"
import WorkflowRunStepStatusText from "./step/status/WorkflowRunStepStatusText.js"
import {
  WORKFLOW_RUN_STEP_FORM_ID,
  WORKFLOW_SIDE_BAR_MINIMIZED_STORAGE_KEY,
  WORKFLOW_TIMELINE_HIDE_STORAGE_KEY,
  canEditWorkflowRunStep,
  canEditWorkflowRunStepUsers,
  workflowRunStepSortFunction,
  type OrganizationActor,
  type WorkflowRunWithExternalFlag,
} from "./utils.js"

/**
 * Displays the given workflow run, which may have come from the workflow run endpoint or from the workflow run link endpoint.
 */
export function WorkflowRunOverview({
  workflowRun,
  autoSelectStep,
}: {
  workflowRun: WorkflowRunWithExternalFlag
  autoSelectStep?: boolean
}) {
  const intl = useIntl()
  const theme = useTheme<Theme>()
  const navigate = useNavigate()
  const toast = useToast()
  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps } = useContextMenu<HTMLDivElement>({
    betsyEnabled: true,
  })

  // Hide timeline sidebar if container shrinks below certain size
  const [isTimelineHidden, setIsTimelineHidden] = useSessionStorage(WORKFLOW_TIMELINE_HIDE_STORAGE_KEY, false)
  const [isSidebarMinimized] = useSessionStorage(WORKFLOW_SIDE_BAR_MINIMIZED_STORAGE_KEY, false)
  const containerRef = useRef<HTMLDivElement>(null)
  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      if (width !== undefined && width < 992 && !isTimelineHidden) {
        setIsTimelineHidden(true)
      }
    },
  })

  const editWorkflowNameModal = useDisclosure()
  const editWorkflowOwnerModal = useDisclosure()
  const editWorkflowStepApproversModal = useDisclosure()
  const editWorkflowStepOwnersModal = useDisclosure()
  const cancelWorkflowModal = useDisclosure()
  const deleteWorkflowModal = useDisclosure()
  const churnConfirmationDialog = useDisclosure()
  const churnModal = useDisclosure()

  const [searchParams, setSearchParams] = useSearchParams()

  const closeStepParams = useMemo(() => {
    const params = new URLSearchParams(searchParams)
    params.delete("step")
    params.delete("object")
    params.delete("type")
    params.delete("field")
    params.delete("event")
    return params
  }, [searchParams])

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [submitForm, submitResult] = usePostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionMutation()
  const [updateDraftState] = usePostWorkflowV1StepRunsByIdDraftMutation()
  const [fetchStepTimelineEvents, stepTimelineEventsResult] =
    useLazyGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery({
      pollingInterval: undefined,
      refetchOnFocus: true,
    })

  const contextSchema = useMemo(
    () => dereferenceSchema(workflowRun.context_schema as ReadonlyDeep<JSONSchemaObject>),
    [workflowRun.context_schema]
  )

  const stepId = searchParams.get("step")
  const selectedStep = workflowRun.steps.find((step) => step.id === stepId) ?? null
  const [fetchWorkflowTimelineEvents, workflowTimelineEventsResult] =
    useLazyGetTimelineV1WorkflowRunByWorkflowRunIdQuery({
      pollingInterval: stepId === null ? 60_000 : undefined,
      refetchOnFocus: true,
    })

  useEffect(() => {
    if (whoami) {
      void fetchWorkflowTimelineEvents({
        workflowRunId: workflowRun.id,
      })
    }
  }, [fetchWorkflowTimelineEvents, workflowRun, whoami])

  const tool = workflowRun.new_legal_agreement.tools[0]

  useEffect(() => {
    if (selectedStep?.id && whoami) {
      void fetchStepTimelineEvents({
        workflowRunId: workflowRun.id,
        workflowRunStepId: selectedStep?.id,
      })
    }
  }, [fetchStepTimelineEvents, selectedStep, workflowRun, whoami])

  const onClickStep = (step: WorkflowRunStepWithContext | null) => {
    if (!step) {
      setSearchParams(closeStepParams)
    } else {
      const newSearch = new URLSearchParams(searchParams)
      newSearch.set("step", step.id)
      newSearch.delete("object")
      newSearch.delete("type")
      newSearch.delete("field")
      newSearch.delete("event")
      setSearchParams(newSearch)
    }
  }

  const saveDraft = useCallback(
    async (state: WorkflowRunStatePatch, deletedFieldPaths: WorkflowRunFieldPath[]) => {
      if (selectedStep) {
        await updateDraftState({
          id: selectedStep.id,
          workflowRunStepDraftStateUpdateInput: {
            state,
            deleted_field_paths: deletedFieldPaths,
            submit: false,
          },
        })
      }
    },
    [selectedStep, updateDraftState]
  )

  const sortedSteps = useMemo(() => {
    return sortedBfs(workflowRun.steps, workflowRunStepSortFunction)
  }, [workflowRun.steps])

  useEffect(() => {
    if (autoSelectStep && !selectedStep) {
      const autoSelectedStep =
        sortedSteps.find((s) => s.status === "in_progress" || s.status === "cancelled" || s.status === "rejected") ??
        sortedSteps.at(-1)
      if (autoSelectedStep) {
        const newSearch = new URLSearchParams(searchParams)
        newSearch.set("step", autoSelectedStep.id)
        // This navigation takes away the autoSelectStep hash which we want because it allows us to navigate normally to the WorkflowRunOverview with no selectedStep
        setSearchParams(newSearch, { replace: true })
      }
    }
  }, [sortedSteps, autoSelectStep, selectedStep, searchParams, setSearchParams])

  const getOrganizationActorWhenActorMissing = useCallback((): OrganizationActor => {
    return {
      displayName: workflowRun.new_legal_agreement.vendor?.display_name || "",
      logo: workflowRun.new_legal_agreement.vendor?.image_asset?.gcs_file_name,
    }
  }, [workflowRun])

  const getLogoToShowByOrganizationId = useCallback(
    (organizationId: string) => {
      // Only show the secondary logo for user entities that are not in the same organization as the user
      if (organizationId !== whoami?.organization_id) {
        if (workflowRun.is_external) {
          return workflowRun.buyer_organization_image_asset?.gcs_file_name
        }
        // If this is an external user show the secondary logo for the vendor
        return workflowRun.new_legal_agreement.vendor?.image_asset?.gcs_file_name
      }
      return undefined
    },
    [workflowRun, whoami?.organization_id]
  )

  const submit = async (state: WorkflowRunStatePatch, event: SubmitEvent) => {
    isNotVoid(selectedStep, "Step must be selected when submitting a decision")
    isInstanceOf(event.submitter, HTMLButtonElement)
    const decision = event.submitter.name as WorkflowRunStepDecision // see the names of the two submit buttons below in the submitButtons prop
    try {
      const submitFormResponse = await submitForm({
        workflowRunStepId: selectedStep.id,
        workflowStepRunSubmission: {
          decision,
          state,
        },
      }).unwrap()
      const { workflow_run, workflow_run_step } = submitFormResponse
      if (churnEmailAssistEnabled && workflow_run_step.status === "rejected") {
        churnConfirmationDialog.onOpen()
      } else {
        const newStepStatus = workflow_run_step.status
        let toastTitle: string = intl.formatMessage(
          {
            id: "requests.run.submit.success.title",
            description: "The toast title shown when a request step is submitted successfully",
            defaultMessage: "{stepName} submitted",
          },
          { stepName: workflow_run_step.display_name }
        )
        let toastDescription: string | undefined
        // All request steps approved - workflow run is approved
        if (submitFormResponse.workflow_run.status === "approved") {
          toastDescription = intl.formatMessage({
            id: "requests.run.submit.success.closed.description",
            description:
              "The toast description shown when a request step is submitted successfully and the request run as a whole is closed",
            defaultMessage:
              "Nice Job! All steps of this request have been completed. The request has been approved and is now closed",
          })
          toastTitle = intl.formatMessage(
            {
              id: "requests.run.submit.success.closed.title",
              description:
                "The toast title shown when the final request step is submitted successfully and the request run is closed",
              defaultMessage: "{runName} completed",
            },
            { runName: workflow_run.display_name }
          )
        } else if (newStepStatus === "pending_submitted") {
          const firstApprovalStep = workflow_run_step.approval_steps[0]
          if (firstApprovalStep) {
            // Step was pending, submitted and has an approver
            toastDescription = intl.formatMessage(
              {
                id: "requests.run.submit.success.pending_submitted.with_approver.description",
                description:
                  "The toast description shown when a request step is submitted successfully for approval from a pending state",
                defaultMessage:
                  "Great work! Once all previous steps are approved, BRM will let {approvers} know the information is ready to review",
              },
              { approvers: intl.formatList(firstApprovalStep.approvers.map((a) => displayPersonName(a.user, intl))) }
            )
          } else {
            // Step was pending, submitted and no approver
            toastDescription = intl.formatMessage({
              id: "requests.run.submit.success.pending_submitted.with_approver.description",
              description:
                "The toast description shown when a request step is submitted successfully from a pending state",
              defaultMessage:
                "Great work! Once all previous steps are approved, this step will automatically move to the next stage",
            })
          }
        } else if (newStepStatus === "submitted") {
          const nextApprovalStep = getCurrentApprovalStep(workflow_run_step.approval_steps)
          if (nextApprovalStep) {
            // If an approver submitted the step, we don't want to show the toast mentioning that the approvers will be notified
            if (!nextApprovalStep.approvers.some((a) => a.user.id === whoami?.id)) {
              // Step was in_progress, submitted and has an approval step
              toastDescription = intl.formatMessage(
                {
                  id: "requests.run.submit.success.in_progress.with_approver.description",
                  description: "The toast description shown when a request step is submitted successfully for approval",
                  defaultMessage:
                    "Great work! {approvers} has been notified that <strong>{stepName}</strong> is ready for review. You’ll be notified when they approve or reject the submitted information",
                },
                {
                  approvers: intl.formatList(nextApprovalStep.approvers.map((a) => displayPersonName(a.user, intl))),
                  stepName: workflow_run_step.display_name,
                }
              )
            }
          } else {
            log.error("Step was submitted but has no approver", null, { step: submitFormResponse })
          }
        } else if (newStepStatus === "completed") {
          toastDescription = intl.formatMessage(
            {
              id: "requests.run.submit.success.in_progress.description",
              description: "The toast description shown when a request step is submitted successfully",
              defaultMessage:
                "Great work! <strong>{stepName}</strong> is complete. Owners of the next steps have been notified to take action on this request",
            },
            { stepName: workflow_run_step.display_name }
          )
        } else if (newStepStatus === "approved") {
          // Step was submitted, approved
          toastDescription = intl.formatMessage({
            id: "requests.run.approve.success.description",
            description: "The toast description shown when a request step is approved successfully",
            defaultMessage: "Owners of the next steps have been notified to take action on this request",
          })
          toastTitle = intl.formatMessage(
            {
              id: "requests.run.submit.success.title",
              description: "The toast title shown when a request step is approved successfully",
              defaultMessage: "{stepName} approved",
            },
            { stepName: workflow_run_step.display_name }
          )
        }
        toast({
          title: toastTitle,
          description: toastDescription,
          status: "success",
        })
      }
    } catch {
      toast({
        description: intl.formatMessage({
          id: "request.step.submit.error",
          description: "Step submit error toast title",
          defaultMessage: "There was an error while submitting this request form",
        }),
        status: "error",
      })
    }
  }

  const showStepEditOptions =
    hasPermission(whoami?.roles, "workflow_run_step:update") &&
    sortedSteps.some((step) => canEditWorkflowRunStepUsers(step, workflowRun))
  const showDeleteOption = hasPermission(whoami?.roles, "workflow_run:delete")

  const menuActions = useCallback(
    (additionalProps?: MenuItemProps) => (
      <>
        <MenuItem {...additionalProps} onClick={editWorkflowNameModal.onOpen} icon={<Icon as={EditIcon} />}>
          <FormattedMessage
            id="requests.run.menu.option.edit.name"
            description="Request actions menu item option to rename the request"
            defaultMessage="Rename request"
          />
        </MenuItem>
        <MenuItem {...additionalProps} onClick={editWorkflowOwnerModal.onOpen} icon={<Icon as={ChampIcon} />}>
          <FormattedMessage
            id="requests.run.menu.option.edit.champ"
            description="Request actions menu item option to reassign the champ"
            defaultMessage="Change champ"
          />
        </MenuItem>
        {showStepEditOptions && (
          <MenuItem {...additionalProps} onClick={editWorkflowStepOwnersModal.onOpen} icon={<Icon as={UserEditIcon} />}>
            <FormattedMessage
              id="requests.run.menu.option.edit.gatherers"
              description="Request actions menu item option to reassign the gatherers"
              defaultMessage="Change gatherers"
            />
          </MenuItem>
        )}
        {showStepEditOptions && (
          <MenuItem
            {...additionalProps}
            onClick={editWorkflowStepApproversModal.onOpen}
            icon={<Icon as={ChangeApproversIcon} />}
          >
            <FormattedMessage
              id="requests.run.menu.option.edit.approvers"
              description="Request actions menu item option to reassign the approvers"
              defaultMessage="Change approvers"
            />
          </MenuItem>
        )}
        <MenuItem {...additionalProps} onClick={cancelWorkflowModal.onOpen} icon={<Icon as={CancelIcon} />}>
          <FormattedMessage
            id="requests.run.menu.option.cancel"
            description="Request actions menu item option to cancel the request"
            defaultMessage="Cancel request"
          />
        </MenuItem>
        {showDeleteOption && (
          <MenuItem {...additionalProps} onClick={deleteWorkflowModal.onOpen} icon={<Icon as={TrashIcon} />}>
            <FormattedMessage
              id="requests.run.menu.option.delete"
              description="Request actions menu item option to delete the request"
              defaultMessage="Delete request"
            />
          </MenuItem>
        )}
      </>
    ),
    [
      cancelWorkflowModal.onOpen,
      deleteWorkflowModal.onOpen,
      editWorkflowNameModal.onOpen,
      editWorkflowOwnerModal.onOpen,
      editWorkflowStepApproversModal.onOpen,
      editWorkflowStepOwnersModal.onOpen,
      showDeleteOption,
      showStepEditOptions,
    ]
  )

  return (
    <Stack
      {...subjectProps.baseProps}
      ref={containerRef}
      spacing={0}
      flexGrow={1}
      flexShrink={1}
      minHeight={0}
      minWidth={0}
      sx={{ containerName: "workflow-run-overview", containerType: "inline-size" }}
    >
      <LogoHeader
        width="full"
        minWidth={0}
        logoElement={
          <RequestLogo logo={getPublicImageGcsUrl(tool?.image_asset?.gcs_file_name)} boxSize={logoHeaderBoxSize} />
        }
        heading={
          <HStack>
            <Heading display="flex" size="sm" flexShrink={1} minW="6ch">
              <Text as="span" isTruncated>
                {workflowRun.display_name}
              </Text>
            </Heading>
            <WorkflowRunKindBadge kind={workflowRun.kind} />
          </HStack>
        }
        showSearch={!workflowRun.is_external}
        rightActions={
          <>
            {/* Right hand side buttons */}
            {churnEmailAssistEnabled &&
              workflowRun.kind === "renewal" &&
              workflowRun.status === "rejected" &&
              !workflowRun.is_external && (
                <>
                  <Button
                    variant="subtleOutlined"
                    colorScheme="error"
                    size="sm"
                    leftIcon={<Icon as={MailIcon} />}
                    {...churnModal.getButtonProps()}
                  >
                    <Text as="span" textOverflow="ellipsis" overflow="hidden">
                      <FormattedMessage
                        defaultMessage="Send cancellation notice to {vendor}"
                        description="Button to send a cancellation request to the vendor"
                        id="renewal.sendCancellation"
                        values={{ vendor: workflowRun.new_legal_agreement.vendor?.display_name }}
                      />
                    </Text>
                  </Button>
                  {churnModal.isOpen && <ChurnEmailModal {...churnModal} workflowRun={workflowRun} />}
                </>
              )}
            {workflowRun.seller_users?.length > 0 && (
              <Box hideBelow="lg" alignContent="center">
                <SellerUsersAvatarGroup
                  sellerUsers={workflowRun.seller_users}
                  sellerLogoGcsFileName={workflowRun.new_legal_agreement.vendor?.image_asset?.gcs_file_name}
                />
              </Box>
            )}
            {workflowRun.status === "in_progress" && (
              <ShareBrmLinkPopover
                workflowRun={workflowRun}
                vendorName={workflowRun.new_legal_agreement.vendor?.display_name}
                isSignedOutView={!whoami}
              />
            )}
            {!workflowRun.is_external && (
              <>
                <Menu placement="bottom-end" isLazy>
                  <MenuButton as={IconButton} icon={<Icon as={MoreMenuIcon} />} variant="outline" />
                  <MenuList>{menuActions()}</MenuList>
                </Menu>
                {editWorkflowNameModal.isOpen && (
                  <UpdateWorkflowRunModal
                    {...editWorkflowNameModal}
                    workflowRun={workflowRun}
                    updateFields={["display_name"]}
                  />
                )}
                {editWorkflowOwnerModal.isOpen && (
                  <UpdateWorkflowRunModal
                    workflowRun={workflowRun}
                    {...editWorkflowOwnerModal}
                    updateFields={["owner_id"]}
                  />
                )}
                {editWorkflowStepOwnersModal.isOpen && (
                  <WorkflowRunStepsUpdateModal
                    workflowRun={workflowRun}
                    workflowRunSteps={sortedSteps}
                    updateField="owner"
                    {...editWorkflowStepOwnersModal}
                  />
                )}
                {editWorkflowStepApproversModal.isOpen && (
                  <WorkflowRunStepsUpdateModal
                    workflowRun={workflowRun}
                    workflowRunSteps={sortedSteps}
                    updateField="approval_steps"
                    {...editWorkflowStepApproversModal}
                  />
                )}
                {cancelWorkflowModal.isOpen && (
                  <CancelWorkflowRunModal {...cancelWorkflowModal} workflowRun={workflowRun} />
                )}
                {deleteWorkflowModal.isOpen && (
                  <DeleteWorkflowRunModal
                    {...deleteWorkflowModal}
                    workflowRun={workflowRun}
                    onDelete={() => navigate("/requests")}
                  />
                )}
              </>
            )}

            {!workflowRun.is_external && (
              <ShowHideTimelineButton
                hideStorageKey={WORKFLOW_TIMELINE_HIDE_STORAGE_KEY}
                label={intl.formatMessage({
                  id: "timeline.showHide.tooltip",
                  description: "Label shown on button that can toggle showing/hiding a timeline sidebar",
                  defaultMessage: "Show/Hide Activity",
                })}
              />
            )}
          </>
        }
      >
        <DividedRowSubHeader>
          <PickableEntityDisplay
            displayText={displayPersonName(workflowRun.owner, intl)}
            image={getPublicImageGcsUrl(workflowRun.owner.profile_image?.gcs_file_name)}
          />
          {tool ? <ToolCell tool={tool} /> : null}
          {!workflowRun.is_external ? <WorkflowRunStatusBadge run={workflowRun} /> : null}
          {!workflowRun.is_external ? <WorkflowRunDueDateIndicator workflowRun={workflowRun} /> : null}
          {workflowRun.is_external ? <SellerDueDateIndicator workflowRun={workflowRun} /> : null}
        </DividedRowSubHeader>
      </LogoHeader>
      {/* Content area */}
      <Flex minHeight={0} minWidth={0} flexGrow={1} flexShrink={1} borderTopStyle="solid" borderTopWidth={1}>
        <Flex minHeight={0} minWidth={0} flexGrow={1} flexShrink={1} justifyContent="space-between">
          {/* Left tabs area */}
          <Flex
            flexDirection="column"
            flexShrink={0}
            px={6}
            pt={6}
            overflowY="auto"
            gap={4}
            borderRight={1}
            borderStyle="solid"
            borderColor="gray.200"
          >
            <CardWithHoverActions
              selected={!selectedStep}
              flexShrink={0}
              width={isSidebarMinimized ? "fit-content" : "auto"}
              onClick={() => onClickStep(null)}
              overflow="hidden"
              ml={7}
              minimized={isSidebarMinimized}
              tooltipProps={{
                placement: "right",
                label: intl.formatMessage({
                  id: "request.steps.nav.context",
                  description: "Request steps display to view request context",
                  defaultMessage: "Context",
                }),
              }}
            >
              <HStack spacing={4}>
                <FeaturedIcon icon={<Icon as={InfoIcon} color="blue.700" />} backgroundColor="blue.100" />
                {!isSidebarMinimized && (
                  <Text
                    fontWeight="medium"
                    sx={{
                      [`@container workflow-run-overview (width < ${theme.breakpoints.xl})`]: {
                        display: "none",
                      },
                    }}
                  >
                    <FormattedMessage
                      id="request.steps.nav.context"
                      description="Request steps display to view request context"
                      defaultMessage="Context"
                    />
                  </Text>
                )}
              </HStack>
            </CardWithHoverActions>
            {!isSidebarMinimized && (
              <Heading
                as="h2"
                size="xs"
                fontSize="xl"
                sx={{
                  [`@container workflow-run-overview (width < ${theme.breakpoints.xl})`]: {
                    display: "none",
                  },
                }}
                ml={8}
              >
                <FormattedMessage
                  id="request.steps.heading"
                  description="Heading on request steps navigation section"
                  defaultMessage="Request Steps"
                />
              </Heading>
            )}
            <Stack gap={4}>
              {sortedSteps.map((step, idx) => (
                <WorkflowRunStepCard
                  key={step.id}
                  step={step}
                  workflowRun={workflowRun}
                  selected={step.id === selectedStep?.id}
                  onClick={() => onClickStep(step)}
                  showConnector={idx !== sortedSteps.length - 1}
                  minimized={isSidebarMinimized}
                />
              ))}
            </Stack>
          </Flex>

          {/* Form area */}
          {!workflowRun.is_external && selectedStep ? (
            <WorkflowRunStepForm
              contextSchema={contextSchema}
              // Force rerender on switching step to prevent stale form state
              key={selectedStep.id}
              // Render the scrolling container in a different stacking context so the
              // scrollbar does not render over other elements in Safari
              // https://linear.app/brm/issue/RD-1905/ui-glitch-scrollbar-overlays-drop-down-menu-in-currency-input
              isolation="isolate"
              headerComponent={
                <WorkflowRunStepHeader
                  workflowRunStep={selectedStep}
                  getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                />
              }
              minH={0}
              p={2}
              id={WORKFLOW_RUN_STEP_FORM_ID}
              workflowRunStep={selectedStep}
              workflowRun={workflowRun}
              onValidSubmit={submit}
              saveDraft={saveDraft}
              isReadOnly={!canEditWorkflowRunStep(whoami, selectedStep, workflowRun)}
              submitButtons={
                <WorkflowRunStepSubmitButtons
                  run={workflowRun}
                  step={selectedStep}
                  isLoading={submitResult.isLoading}
                  submitDecision={submitResult.originalArgs?.workflowStepRunSubmission.decision}
                />
              }
              getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            />
          ) : workflowRun.is_external && selectedStep ? (
            // Force rerender on switching step to prevent stale form state
            <BrmLinkWorkflowRunStepForm
              key={selectedStep.id}
              workflowRun={workflowRun}
              getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              stepTimelineEvents={stepTimelineEventsResult.data || []}
            />
          ) : (
            // Default show context
            <Stack p={6} minHeight={0} minW={400} overflowY="auto" flexGrow={1}>
              {workflowRun.is_external ? (
                <WorkflowRunContextSummaryExternal
                  timelineEvents={workflowTimelineEventsResult.data || []}
                  workflowRun={workflowRun}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
                />
              ) : (
                <WorkflowRunContextSummary
                  timelineEvents={workflowTimelineEventsResult.data || []}
                  workflowRun={workflowRun}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
                />
              )}
            </Stack>
          )}
        </Flex>

        {!workflowRun.is_external && (
          <SessionStorageTimelineContainer
            borderLeft={1}
            borderColor="gray.200"
            borderStyle="solid"
            hideStorageKey={WORKFLOW_TIMELINE_HIDE_STORAGE_KEY}
            timelineProps={{ filterParams: { workflowRunId: workflowRun.id } }}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            sx={{
              [`@container workflow-run-overview (width < ${theme.breakpoints["3xl"]})`]: {
                width: "320px",
              },
            }}
          />
        )}
      </Flex>

      {churnConfirmationDialog.isOpen && workflowRun.kind === "renewal" && !workflowRun.is_external && (
        <ChurnConfirmationDialog
          {...churnConfirmationDialog}
          onDraftEmailClick={churnModal.onOpen}
          workflowRun={workflowRun}
        />
      )}

      <Portal>
        <Menu {...menuProps}>
          <MenuList {...menuListProps}>
            {menuListProps.children}
            {menuActions(menuItemProps)}
          </MenuList>
        </Menu>
      </Portal>
      {betsyProps?.BetsyModal}
    </Stack>
  )
}

function WorkflowRunStepCard({
  step,
  workflowRun,
  onClick,
  selected,
  showConnector = true,
  minimized,
}: {
  step: WorkflowRunStepWithContext
  workflowRun: WorkflowRunWithExternalFlag
  onClick: () => void
  selected?: boolean
  showConnector?: boolean
  minimized?: boolean
}) {
  const [searchParams] = useSearchParams()
  const theme = useTheme<Theme>()
  return (
    <HStack>
      <Tooltip label={<WorkflowRunStepStatusText step={step} workflowRun={workflowRun} fontWeight="semibold" />}>
        <Box position="relative">
          <Box
            // eslint-disable-next-line prefer-smart-quotes/prefer
            content="''"
            position="absolute"
            top="100%"
            left="50%"
            transform="translateX(-50%)"
            display={showConnector ? "block" : "none"}
            width="2px"
            height="64px"
            backgroundColor={step.status === "completed" || step.status === "approved" ? "brand.600" : "gray.200"}
          />
          <WorkflowRunStatusIcon status={step.status} />
        </Box>
      </Tooltip>
      <CardWithHoverActions
        selected={selected}
        flexGrow={1}
        onClick={onClick}
        overflow="hidden"
        cardBodyProps={{ as: "div", display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}
        minimized={minimized}
        tooltipProps={{
          label: step.display_name,
          placement: "right",
        }}
      >
        <Link
          to={{ search: new URLSearchParams({ ...Object.fromEntries(searchParams), step: step.id }).toString() }}
          display="contents"
        >
          <WorkflowStepIcon stepType={step.type} />
          {!minimized && (
            <chakra.span
              fontWeight="medium"
              mr={4}
              sx={{
                [`@container workflow-run-overview (width < ${theme.breakpoints.xl})`]: {
                  display: "none",
                },
              }}
            >
              {step.display_name}
            </chakra.span>
          )}
        </Link>
      </CardWithHoverActions>
    </HStack>
  )
}
