import { Flex } from "@chakra-ui/react"
import type { PersonDisplayProps } from "../../../features/person/PersonDisplay.js"
import PersonDisplay from "../../../features/person/PersonDisplay.js"
import { Link } from "../../Link.js"

export default function PersonCell(props: PersonDisplayProps) {
  const { id } = props.person
  return (
    <Flex flexShrink={1} height="100%" alignItems="center">
      <Link to={`/people/${id}`} maxW="100%">
        <PersonDisplay {...props} />
      </Link>
    </Flex>
  )
}
