/**
 * Yields all the parents of a given DOM element, starting with the immediate parent and ending with the document's
 * root element.
 */
export function* walkDOMParents(element: Element): Generator<HTMLElement> {
  let current: HTMLElement | null = element.parentElement
  while (true) {
    if (current === null) {
      return
    }
    yield current
    current = current.parentElement
  }
}
