import { WorkflowPurchaseKindSchema, WorkflowRenewalKindSchema } from "@brm/schemas"
import { getMarkdownConfig, normalizeGptMarkdownMath } from "@brm/util/markdown.js"
import {
  Avatar,
  Box,
  Button,
  Divider,
  HStack,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { AnimatedAreaSeries, AnimatedAxis, Tooltip, XYChart } from "@visx/xychart"
import type { ReactNode } from "react"
import { Fragment, memo, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import Markdown from "react-markdown"
import rehypeKatex from "rehype-katex"
import remarkMath from "remark-math"
import { usePatchToolV1ByIdMutation } from "../../app/services/generated-api.js"
import { LinkOrSpan } from "../../components/Link.js"
import { log } from "../../util/logger.js"
import InviteOrUpdateUserModal from "../organization/invites/InviteOrUpdateUserModal.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"

import "katex/dist/katex.min.css"
import type { Descendant } from "slate"
import RichTextDisplay from "../../components/RichTextEditor/RichTextDisplay.js"

const RenderedMarkdown = memo(function RenderMarkdown({ content }: { content: string }) {
  const markdownConfig = getMarkdownConfig({
    remarkPlugins: [[remarkMath, { singleDollarTextMath: false }]],
    rehypePlugins: [rehypeKatex],
  })
  return (
    <Markdown
      {...markdownConfig}
      allowedElements={[...(markdownConfig.allowedElements ? markdownConfig.allowedElements : []), "code"]}
      components={{
        p: ({ node: _, ...props }) => (
          <chakra.p
            {...props}
            mb={2}
            sx={{
              "&:last-child": { mb: 0 },
            }}
          />
        ),
        hr: ({ node: _, ...props }) => <Divider {...props} />,
        ul: ({ node: _, ...props }) => <UnorderedList {...props} pl={3} />,
        ol: ({ node: _, ...props }) => <OrderedList {...props} pl={3} />,
        li: ({ node: _, ...props }) => <ListItem {...props} />,
        a: ({ node: _, ...props }) => <LinkOrSpan target="_blank" {...props} to={props.href} variant="highlighted" />,
        img: ({ node: _, ...props }) => <Image {...props} />,
        blockquote: ({ node: _, ...props }) => (
          <chakra.blockquote borderLeftWidth={4} borderColor="gray.100" pl={2} mb={2} color="gray.700" {...props} />
        ),
        code: ({ node: _, ...props }) => (
          <chakra.code
            {...props}
            px={1}
            py={0.5}
            rounded="sm"
            whiteSpace="pre-wrap"
            sx={{
              "&:last-child": { mb: 0 },
            }}
          />
        ),
      }}
    >
      {normalizeGptMarkdownMath(content)}
    </Markdown>
  )
})

export interface ChatMessageAuthor {
  name: string
  renderedName?: JSX.Element
  image?: string
  role: "user" | "assistant"
}

interface Props {
  author: ChatMessageAuthor
  content: string
  isLoading: boolean
  status?: string
  after?: ReactNode
  richText?: Descendant[]
}

interface SpendChartDatum {
  // eslint-disable-next-line no-restricted-globals
  x: Date
  y: string
}

const parseChartData = (chartDefinition: string) => {
  const chartNameRegex = /Chart Name: (.+)/u
  const xAxisRegex = /X Axis: \[(.+)\]/u
  const yAxisRegex = /Y Axis: \[(.+)\]/u

  const chartNameMatch = chartDefinition.match(chartNameRegex)
  const xAxisMatch = chartDefinition.match(xAxisRegex)
  const yAxisMatch = chartDefinition.match(yAxisRegex)

  const chartName = chartNameMatch && chartNameMatch[1] ? chartNameMatch[1] : ""
  const xAxisData = xAxisMatch && xAxisMatch[1] ? xAxisMatch[1].split(", ") : []
  const yAxisData = yAxisMatch && yAxisMatch[1] ? yAxisMatch[1].split(", ") : []

  return {
    chartName,
    xAxisData,
    yAxisData,
  }
}

const parseActionData = (actionData: string) => {
  const nameRegex = /Name: (.+)/u
  const typeRegex = /Type: (.+)/u
  const firstNameRegex = /First Name: (.+)/u
  const lastNameRegex = /Last Name: (.+)/u
  const emailRegex = /Email: (.+)/u
  const toolIdRegex = /Tool ID: (.+)/u
  const ownerIdRegex = /Owner ID: (.+)/u
  const requestTypeRegex = /Request Type: (.+)/u

  const nameMatch = actionData.match(nameRegex)
  const typeMatch = actionData.match(typeRegex)
  const firstNameMatch = actionData.match(firstNameRegex)
  const lastNameMatch = actionData.match(lastNameRegex)
  const emailMatch = actionData.match(emailRegex)
  const toolIdMatch = actionData.match(toolIdRegex)
  const ownerIdMatch = actionData.match(ownerIdRegex)
  const requestTypeMatch = actionData.match(requestTypeRegex)

  const name = nameMatch && nameMatch[1] ? nameMatch[1] : ""
  const type = typeMatch && typeMatch[1] ? typeMatch[1] : ""
  const firstName = firstNameMatch && firstNameMatch[1] ? firstNameMatch[1] : ""
  const lastName = lastNameMatch && lastNameMatch[1] ? lastNameMatch[1] : ""
  const email = emailMatch && emailMatch[1] ? emailMatch[1] : ""
  const toolId = toolIdMatch && toolIdMatch[1] ? toolIdMatch[1] : ""
  const ownerId = ownerIdMatch && ownerIdMatch[1] ? ownerIdMatch[1] : ""
  const requestType = requestTypeMatch && requestTypeMatch[1] ? requestTypeMatch[1] : ""

  return {
    name,
    type,
    firstName,
    lastName,
    email,
    toolId,
    ownerId,
    requestType,
  }
}

export const ChatMessage = (props: Props) => {
  const [updateOwner, updateOwnerResult] = usePatchToolV1ByIdMutation()
  const inviteModal = useDisclosure()
  const workflowRunModal = useDisclosure()
  const intl = useIntl()
  const toast = useToast()
  const { author, content, isLoading, richText } = props
  const [richTextRenderKey, setRichTextRenderKey] = useState(0)

  useEffect(() => {
    // RichTextEditor is an uncontrolled component that needs to be re-rendered when the value changes.
    // Without this, Rich Text Editors in the table will not update when filtering or sorting.
    setRichTextRenderKey((key) => key + 1)
  }, [richText])

  const renderMessage = (m: string) => {
    let message =
      isLoading && author.role === "assistant"
        ? `${m} ⬤ ${props.status && props.status !== "waiting" ? props.status : ""}`
        : m
    let preExtraMessage = ""
    const extras: Array<React.ReactElement> = []
    if (message.includes("<action>")) {
      preExtraMessage = message.substring(0, message.indexOf("<action>"))
      if (isLoading && !message.includes("</action>")) {
        return (
          <Fragment>
            <RenderedMarkdown content={preExtraMessage} />
            <RenderedMarkdown content="Preparing Action...  ⬤" />
          </Fragment>
        )
      }
      const actionData = message.substring(
        message.indexOf("<action>") + "<action>".length,
        message.indexOf("</action>")
      )
      message = message.substring(message.indexOf("</action>") + "</action>".length)
      const { type, name, firstName, lastName, email, toolId, ownerId, requestType } = parseActionData(actionData)

      if (type === "invite" && name && firstName && lastName && email) {
        extras.push(
          <>
            <InviteOrUpdateUserModal
              isOpen={inviteModal.isOpen}
              onClose={inviteModal.onClose}
              initialState={{
                first_name: firstName,
                last_name: lastName,
                email,
                roles: [],
              }}
            />
            <Button minWidth="50%" alignSelf="center" colorScheme="brand" mb={1} onClick={inviteModal.onOpen}>
              {name}
            </Button>
          </>
        )
      } else if (type === "change_owner" && name && ownerId && toolId) {
        extras.push(
          <Button
            isLoading={updateOwnerResult.isLoading}
            minWidth="50%"
            alignSelf="center"
            colorScheme="brand"
            mb={1}
            onClick={async () => {
              try {
                await updateOwner({
                  id: toolId,
                  toolPatch: { id: toolId, object_type: "Tool", owner: { id: ownerId } },
                }).unwrap()
                toast({
                  description: intl.formatMessage({
                    id: "betsy.owner.change",
                    description: "Toast success message when updating owner of a tool",
                    defaultMessage: "Owner updated successfully",
                  }),
                  status: "success",
                })
              } catch (err) {
                toast({
                  description: intl.formatMessage({
                    id: "betsy.owner.change",
                    description: "Toast failure message when updating owner of a tool",
                    defaultMessage: "Failed to update owner",
                  }),
                  status: "error",
                })
                log.error("failed to update owner", err)
              }
            }}
          >
            {name}
          </Button>
        )
      } else if (type === "create_request") {
        const requestKind =
          requestType === WorkflowRenewalKindSchema.const || requestType === WorkflowPurchaseKindSchema.const
            ? requestType
            : undefined
        extras.push(
          <>
            <Button minWidth="50%" alignSelf="center" colorScheme="brand" mb={1} onClick={workflowRunModal.onOpen}>
              {name}
            </Button>
            <StartWorkflowModal kind={requestKind} toolId={toolId} {...workflowRunModal} />
          </>
        )
      }
    }
    if (message.includes("<chart>")) {
      preExtraMessage = message.substring(0, message.indexOf("<chart>"))
      if (isLoading && !message.includes("</chart>")) {
        return (
          <Fragment>
            <RenderedMarkdown content={preExtraMessage} />
            <RenderedMarkdown content="Preparing Chart...  ⬤" />
          </Fragment>
        )
      }
      /* eslint-disable no-restricted-globals */
      const chartData = message.substring(message.indexOf("<chart>") + "<chart>".length, message.indexOf("</chart>"))
      message = message.substring(message.indexOf("</chart>") + "</chart>".length)

      const { chartName, xAxisData, yAxisData } = parseChartData(chartData)
      const data: SpendChartDatum[] = xAxisData.map((x, i) => ({
        x: new Date(
          Temporal.PlainDate.from(x)
            .toPlainDateTime({ hour: 0, minute: 0, second: 0 })
            .toZonedDateTime(Temporal.Now.timeZoneId()).epochMilliseconds
        ),
        y: yAxisData[i] ?? "",
      }))

      extras.push(
        <>
          <Text align="center" mb={1}>
            {chartName}
          </Text>
          <XYChart
            margin={{ left: 30, top: 5, right: 0, bottom: 40 }}
            xScale={{ type: "time" }}
            yScale={{ type: "linear" }}
            width={500}
            height={300}
          >
            <AnimatedAxis
              numTicks={Math.min(data.length, 5)}
              orientation="bottom"
              tickFormat={(value: Date) =>
                // eslint-disable-next-line no-restricted-properties
                intl.formatDate(value, { year: "2-digit", month: "short", day: "numeric" })
              }
            />
            <AnimatedAxis
              orientation="left"
              tickFormat={(amount) =>
                intl.formatNumber(amount, {
                  compactDisplay: "short",
                  notation: "compact",
                  style: "currency",
                  currency: "USD",
                })
              }
            />
            <AnimatedAreaSeries
              fillOpacity={0.2}
              data={data}
              dataKey="Spend"
              xAccessor={(d) => d.x}
              yAccessor={(d) => parseFloat(d.y ?? "50")}
            />
            <Tooltip<SpendChartDatum>
              snapTooltipToDatumX={true}
              snapTooltipToDatumY={true}
              showVerticalCrosshair={true}
              renderTooltip={(data) => (
                <>
                  <Text fontWeight="bold" mb={1}>
                    {`$${data.tooltipData?.nearestDatum?.datum?.y}`}
                  </Text>
                  <Text fontWeight="normal" mb={1}>
                    {
                      // eslint-disable-next-line no-restricted-properties
                      intl.formatDate(data.tooltipData?.nearestDatum?.datum?.x, {
                        year: "2-digit",
                        month: "short",
                        day: "numeric",
                      })
                    }
                  </Text>
                </>
              )}
            />
          </XYChart>
        </>
      )
    }
    return (
      <>
        <Stack spacing="2">
          <Fragment>
            <RenderedMarkdown content={preExtraMessage} />
            <HStack alignItems="start">{extras}</HStack>
            <RenderedMarkdown content={message} />
          </Fragment>
        </Stack>
        {!isLoading && extras.length === 0 && props.after}
      </>
    )
  }

  return (
    <HStack align="flex-start" gap="5">
      <Box pt="1">
        <Avatar size="sm" src={author.image} name={author.name} />
      </Box>
      <Stack width="100%" spacing="1">
        {author.renderedName ? author.renderedName : <Text fontWeight="medium">{author.name}</Text>}
        {author.role === "user" && richText ? (
          <RichTextDisplay key={richTextRenderKey} content={richText} />
        ) : (
          renderMessage(content)
        )}
      </Stack>
    </HStack>
  )
}
