import type { LegalAgreementMinimal } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import type { CardProps } from "@chakra-ui/react"
import { Button, Flex, HStack, Heading, Spinner, Stack, Tooltip } from "@chakra-ui/react"
import Big from "big.js"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { hasPresentKey } from "ts-is-present"
import type { SetNonNullable, SetRequired } from "type-fest"
import { isNotUndefined } from "typed-assert"
import type { GetSpendV1ByTypeAndIdApiArg } from "../../app/services/generated-api.js"
import { useGetSpendV1ByTypeAndIdQuery } from "../../app/services/generated-api.js"
import { FormattedCurrency } from "../../components/FormattedCurrency.js"
import { Link } from "../../components/Link.js"
import { StatusCodes, getAPIErrorStatusCode } from "../../util/error.js"
import ChartCard from "./ChartCard.js"

interface Props extends CardProps {
  entityParams: Pick<GetSpendV1ByTypeAndIdApiArg, "id" | "type">
}

type ValidActiveLegalAgreement = SetNonNullable<
  SetRequired<LegalAgreementMinimal, "total_contract_value" | "start_date">,
  "total_contract_value" | "start_date"
>

export const AgreementTcvChartCard = ({
  agreements,
  entityParams,
  ...cardProps
}: Props & { agreements: LegalAgreementMinimal[] }) => {
  // For now only allow active agreements with a tcv and start date onto the chart
  const validActiveAgreements = useMemo(() => {
    return agreements
      .filter((agreement) => agreement.effective_status === "active")
      .filter(hasPresentKey("total_contract_value"))
      .filter(hasPresentKey("start_date"))
      .map(
        (agreement): ValidActiveLegalAgreement => ({
          ...agreement,
          total_contract_value: agreement.total_contract_value,
          start_date: agreement.start_date,
        })
      )
  }, [agreements])

  if (validActiveAgreements.length === 0) {
    return null
  }

  return (
    <ValidAgreementTcvChartCard validAgreements={validActiveAgreements} entityParams={entityParams} {...cardProps} />
  )
}

const ValidAgreementTcvChartCard = ({
  validAgreements,
  entityParams,
  ...cardProps
}: Props & { validAgreements: ValidActiveLegalAgreement[] }) => {
  const [selectedAgreement, setSelectedAgreement] = useState(validAgreements[0])
  isNotUndefined(selectedAgreement)

  const { data, isLoading, error } = useGetSpendV1ByTypeAndIdQuery({
    ...entityParams,
    startDate: selectedAgreement.start_date,
    currencyCode: selectedAgreement.total_contract_value.currency_code,
  })

  const intl = useIntl()

  if (getAPIErrorStatusCode(error) === StatusCodes.FORBIDDEN) {
    return null
  }

  const totalAmount = new Big(selectedAgreement.total_contract_value.amount)
  const spentAmount = data ? new Big(data.total.amount) : undefined
  const remainingAmount = spentAmount ? totalAmount.sub(spentAmount) : undefined

  return (
    <ChartCard<ValidActiveLegalAgreement>
      {...cardProps}
      title={intl.formatMessage({
        defaultMessage: "Total Contract Value (TCV)",
        id: "agreement.tcv",
        description: "The label for the TCV chart section",
      })}
      description={
        <Heading size="md" color="gray.900">
          <FormattedCurrency currencyAmount={selectedAgreement.total_contract_value} />
        </Heading>
      }
      selectProps={{
        options: validAgreements,
        value: selectedAgreement,
        getOptionLabel: (selection) => selection.display_name,
        getOptionValue: (selection) => selection.id,
        onChange: (newSelection) => {
          if (newSelection) {
            setSelectedAgreement(newSelection)
          }
        },
      }}
      footer={
        <Button as={Link} to={`#agreement=${selectedAgreement.id}`} variant="outline">
          <FormattedMessage
            id="agreement.viewAgreement"
            description="Button to view the agreement"
            defaultMessage="Agreement details"
          />
        </Button>
      }
    >
      {spentAmount && remainingAmount ? (
        <Stack fontSize="md" flexGrow={1} gap={3} justifyContent="center" alignItems="center" color="gray.600">
          {spentAmount.eq(0) ? (
            <FormattedMessage
              id="agreement.tcv.noSpend"
              description="Message for when an agreement has no spend yet"
              defaultMessage="This agreement has no spend yet"
            />
          ) : (
            <>
              <HStack width="full" gap={0}>
                <Flex height={4} borderWidth="1px" backgroundColor="blue.400" flexGrow={spentAmount.toNumber()} />
                <Flex
                  height={4}
                  borderWidth="1px"
                  borderLeftWidth="0px"
                  backgroundColor="white"
                  flexGrow={Math.max(totalAmount.sub(spentAmount).toNumber(), 0)}
                />
              </HStack>

              {remainingAmount.gte(0) ? (
                <FormattedMessage
                  id="agreement.tcv.remainingSpend"
                  description="The amount left in the TCV over a contract period date range"
                  defaultMessage="{amountLeft} Remaining"
                  values={{
                    amountLeft: formatCurrency(
                      {
                        currency_code: selectedAgreement.total_contract_value.currency_code,
                        amount: remainingAmount.toString(),
                      },
                      intl
                    ),
                  }}
                />
              ) : (
                <Tooltip
                  label={intl.formatMessage({
                    id: "agreement.tcv.overSpend.tooltip",
                    description: "Tooltip for over spend on TCV chart",
                    defaultMessage:
                      "Total outgoing payments can be slightly larger than TCV due to taxes and fees. Consult transaction receipts or invoices for details.",
                  })}
                  shouldWrapChildren
                >
                  <FormattedMessage
                    id="agreement.tcv.overSpend"
                    description="The amount over the TCV over a contract period date range"
                    defaultMessage="{amountOver} Over"
                    values={{
                      amountOver: formatCurrency(
                        {
                          currency_code: selectedAgreement.total_contract_value.currency_code,
                          amount: remainingAmount.abs().toString(),
                        },
                        intl
                      ),
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </Stack>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <FormattedMessage
          id="spendChart.failedToLoad"
          description="failed to fetch spend chart data"
          defaultMessage="Failed to load spend data"
        />
      )}
    </ChartCard>
  )
}
