import type { DraggableProvided, DraggableStateSnapshot, DraggingStyle } from "@hello-pangea/dnd"
import type { ReactElement } from "react"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"

/**
 * Use this when you need a dragabble component inside a menu or popover. This is a missing functionality from the library but this
 * was the recommended community solution.
 * See full thread here: https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-1010053365
 */
export const useDraggableInPortal = () => {
  const element = useRef<HTMLDivElement>(document.createElement("div")).current

  useEffect(() => {
    if (!element) {
      return
    }

    element.style.pointerEvents = "none"
    element.style.position = "absolute"
    element.style.height = "100%"
    element.style.width = "100%"
    element.style.top = "0"

    document.body.appendChild(element)

    return () => {
      document.body.removeChild(element)
    }
  }, [element])

  // The drag and drop library we use hello-pangea/dnd uses position fixed for draggables. This is a problem when the draggable
  // is inside a menu or popover because the menu or popover is positioned relative to the body and not the parent element.
  // This function is a helper that will render the draggable in a portal if it is fixed.
  const renderFixedDraggableInPortal =
    (render: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => ReactElement) =>
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      const result = render(provided, snapshot)
      const style = provided.draggableProps.style as DraggingStyle
      if (style.position === "fixed") {
        return createPortal(result, element)
      }
      return result
    }

  return renderFixedDraggableInPortal
}
