import type {
  LinkWorkflowSellerInvite,
  ToolOptionWithVendor,
  UserPicker,
  WorkflowKind,
  WorkflowRunInput,
} from "@brm/schema-types/types.js"
import { WorkflowPurchaseKindSchema, WorkflowRenewalKindSchema } from "@brm/schemas"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  useToast,
  type ModalProps,
  type UseModalProps,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { ReactElement } from "react"
import { useEffect, useState } from "react"
import { useForm, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { isNotNull } from "typed-assert"
import avatarGroup from "../../../../../assets/avatarGroup.png"
import {
  useGetToolV1ByIdQuery,
  useGetUserV1WhoamiQuery,
  usePostToolV1Mutation,
  usePostWorkflowV1RunsMutation,
} from "../../../../app/services/generated-api.js"
import { CancelButton } from "../../../../components/buttons.js"
import { NextIcon, SendIcon } from "../../../../components/icons/icons.js"
import Spinner from "../../../../components/spinner.js"
import { getAPIErrorMessage } from "../../../../util/error.js"
import { getProgress } from "../../../../util/progress.js"
import type { LegalAgreementOption } from "../../../legal/LegalAgreementPicker.js"
import { displayWorkflowKind } from "../../util.js"
import WorkflowConfirmSection from "./WorkflowConfirmSection.js"
import WorkflowInviteSection from "./WorkflowInviteSection.js"
import type { WorkflowKindSectionFormState } from "./WorkflowKindSection.js"
import WorkflowKindSection from "./WorkflowKindSection.js"
import WorkflowLegalAgreementSection from "./WorkflowLegalAgreementSection.js"
import WorkflowSection from "./WorkflowSection.js"
import WorkflowToolSection from "./WorkflowToolSection.js"
import type {
  WorkflowInviteSectionFormState,
  WorkflowLegalAgreementSectionFormState,
  WorkflowSectionFormState,
  WorkflowToolSectionFormState,
} from "./util.js"
import {
  WorkflowKindSectionFields,
  WorkflowLegalAgreementSectionFields,
  WorkflowSectionFields,
  WorkflowToolSectionFields,
  generateWorkflowDisplayName,
  type StartWorkflowFormState,
} from "./util.js"

type Props = UseModalProps &
  Pick<ModalProps, "returnFocusOnClose"> & {
    initialLegalAgreement?: LegalAgreementOption
    kind?: WorkflowKind
    toolId?: string
  }

const PURCHASE_STEPS = 4
const RENEWAL_STEPS = 4

type ModalScreens =
  | "workflowSelection"
  | "toolSelection"
  | "newToolGeneration"
  | "legalAgreement"
  | "workflow"
  | "confirmation"
  | "inviteNewUsers"

export default function StartWorkflowModal(props: Props) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  if (!whoami) {
    return null
  }
  return (
    <StartWorkflowModalInternal
      initialUser={{
        id: whoami.id,
        first_name: whoami.first_name,
        last_name: whoami.last_name,
        display_name: displayPersonName(whoami, intl),
        email: whoami.email,
        object_type: "User",
        organization_id: whoami.organization_id,
        image_asset: whoami.profile_image,
        roles: whoami.roles,
        type: "user",
      }}
      {...props}
    />
  )
}

function StartWorkflowModalInternal({
  initialLegalAgreement,
  isOpen,
  onClose,
  kind,
  toolId,
  returnFocusOnClose,
  initialUser,
}: Props & { initialUser: UserPicker }) {
  const toast = useToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const [createWorkflowRun, { isLoading }] = usePostWorkflowV1RunsMutation()

  const [createNewToolListing, { data: newToolListing }] = usePostToolV1Mutation()

  const legalAgreementFirstTool = initialLegalAgreement?.tools[0]
  const preselectedRenewalTool: ToolOptionWithVendor | undefined =
    legalAgreementFirstTool && initialLegalAgreement.vendor
      ? { ...legalAgreementFirstTool, vendor: initialLegalAgreement.vendor }
      : undefined

  const isPreselectedRenewal = kind === WorkflowRenewalKindSchema.const || !!preselectedRenewalTool

  const form = useForm<StartWorkflowFormState>({
    defaultValues: {
      workflow_kind: isPreselectedRenewal ? WorkflowRenewalKindSchema.const : kind || null,
      tool: isPreselectedRenewal && preselectedRenewalTool ? preselectedRenewalTool : null,
      tool_owner: null,
      display_name: "",
      workflow_owner: initialUser,
      departments: [],
      target_date: null,
      legal_agreement: isPreselectedRenewal && initialLegalAgreement ? initialLegalAgreement : null,
      new_legal_agreement_name: null,
      is_new_tool: null,
      new_tool_url: null,
      new_tool_display_name: null,
      recipients: [{ first_name: "", last_name: "", email: "" }],
      message: null,
      seller_due_date: null,
      skipped_invites: false,
    } satisfies StartWorkflowFormState,
  })

  const onSubmit = form.handleSubmit(async (values: StartWorkflowFormState) => {
    isNotNull(values.workflow_kind)
    isNotNull(values.workflow_owner)
    isNotNull(values.target_date)
    isNotNull(values.tool_owner)

    const workflowLinkInvites: LinkWorkflowSellerInvite | null = values.skipped_invites
      ? null
      : {
          recipients: values.recipients,
          message: values.message,
          due_date: values.seller_due_date,
        }

    let workflowRunInput: WorkflowRunInput
    if (values.workflow_kind === WorkflowPurchaseKindSchema.const) {
      isNotNull(values.tool)
      workflowRunInput = {
        kind: WorkflowPurchaseKindSchema.const,
        tools: [values.tool],
        tool_owner_id: values.tool_owner?.id ?? null,
        display_name: values.display_name,
        owner_id: values.workflow_owner.id,
        target_date: values.target_date,
        employee_group_ids: values.departments.map((department) => department.id),
        link_invite: workflowLinkInvites,
      }
    } else if (values.workflow_kind === WorkflowRenewalKindSchema.const) {
      if (values.legal_agreement) {
        workflowRunInput = {
          kind: values.workflow_kind,
          active_legal_agreement_id: values.legal_agreement.id,
          tool_owner_id: values.tool_owner?.id ?? null,
          display_name: values.display_name,
          owner_id: values.workflow_owner.id,
          target_date: values.target_date,
          employee_group_ids: values.departments.map((department) => department.id),
          link_invite: workflowLinkInvites,
        }
      } else {
        isNotNull(values.tool, "Expected tool to be selected if no legal agreement is selected for renewal")
        workflowRunInput = {
          kind: values.workflow_kind,
          tools: [values.tool],
          new_legal_agreement_name: values.new_legal_agreement_name || undefined,
          tool_owner_id: values.tool_owner?.id ?? null,
          display_name: values.display_name,
          owner_id: values.workflow_owner.id,
          target_date: values.target_date,
          employee_group_ids: values.departments.map((department) => department.id),
          link_invite: workflowLinkInvites,
        }
      }
    } else {
      unreachable(values.workflow_kind)
    }

    try {
      const newWorkflow = await createWorkflowRun({ workflowRunInput }).unwrap()
      const detailStep = newWorkflow.steps.find((step) => step.type === "details")
      onClose()
      // navigate to the details step of the request
      const pathname = `/requests/${newWorkflow.id}`
      if (detailStep) {
        navigate({
          pathname,
          search: new URLSearchParams({ step: detailStep.id }).toString(),
        })
      } else {
        navigate(pathname)
      }
    } catch (err: unknown) {
      const errorDescription = getAPIErrorMessage(err)
      toast({
        title: intl.formatMessage(
          {
            id: "requests.start.error.toast",
            description: "Start request error toast title",
            defaultMessage: "There was an issue creating this {workflowKind} request",
          },
          { workflowKind: displayWorkflowKind(values.workflow_kind) }
        ),
        description: errorDescription,
        status: "error",
      })
      // The invite screen doesn't have a back button so we revert to a previous screen to let the user potentially resolve the error.
      setModalState("confirmation")
    }
  })

  const workflowKind = form.watch("workflow_kind")

  const selectedToolId = form.watch("tool")?.id || toolId

  const isNewTool = form.watch("is_new_tool")
  const newToolDisplayName = form.watch("new_tool_display_name")

  const { data: fetchedTool, isFetching: toolIsFetching } = useGetToolV1ByIdQuery(
    selectedToolId ? { id: selectedToolId } : skipToken
  )
  const selectedToolWithDetails = selectedToolId ? fetchedTool : undefined

  const [modalState, setModalState] = useState<ModalScreens>(
    isPreselectedRenewal ? "legalAgreement" : kind === "purchase" ? "toolSelection" : "workflowSelection"
  )

  useEffect(() => {
    form.setValue("tool_owner", null)
  }, [selectedToolId, form])

  useEffect(() => {
    if (selectedToolWithDetails?.owner) {
      form.setValue("tool_owner", {
        type: "person",
        image_asset: selectedToolWithDetails.owner.profile_image,
        ...selectedToolWithDetails.owner,
      })
    }
  }, [selectedToolWithDetails?.owner, form])

  useEffect(() => {
    if (!form.watch("tool") && selectedToolWithDetails) {
      form.setValue("tool", selectedToolWithDetails)
    }
  }, [selectedToolWithDetails, form])

  useEffect(() => {
    if (!newToolListing || !workflowKind) {
      return
    }

    form.setValue("new_tool_display_name", null)
    form.setValue("new_tool_url", null)
    form.setValue("is_new_tool", false)
    form.setValue("tool", newToolListing)
    form.setValue("display_name", generateWorkflowDisplayName(newToolListing.display_name, workflowKind, intl))

    setModalState("toolSelection")
  }, [newToolListing, workflowKind, form, intl])

  const screens: Record<ModalScreens, ReactElement> = {
    workflowSelection: (
      <>
        <ModalBody>
          <WorkflowKindSection
            control={form.control as unknown as Control<WorkflowKindSectionFormState>}
            startPurchase={async () => {
              // trigger form validation
              const isValid = await form.trigger(WorkflowKindSectionFields)
              if (!isValid) return
              setModalState("toolSelection")
            }}
            startRenewal={async () => {
              // trigger form validation
              const isValid = await form.trigger(WorkflowKindSectionFields)
              if (!isValid) return
              setModalState("legalAgreement")
            }}
            closeModal={onClose}
          />
        </ModalBody>
        <ModalFooter justifyContent="end">
          <CancelButton onClick={onClose} />
        </ModalFooter>
      </>
    ),
    toolSelection: (
      <>
        <ModalBody>
          <WorkflowToolSection
            control={form.control as unknown as Control<WorkflowToolSectionFormState>}
            selectedToolWithDetails={selectedToolWithDetails}
            selectedToolOption={form.watch("tool")}
            isNewTool={isNewTool}
            newToolDisplayName={newToolDisplayName}
            workflowKind={workflowKind}
            setValue={form.setValue}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Progress
            value={
              workflowKind === "purchase"
                ? getProgress(1, PURCHASE_STEPS)
                : workflowKind === "renewal"
                  ? getProgress(1, RENEWAL_STEPS)
                  : 0
            }
            flex={1}
            size="sm"
            maxWidth={200}
          />
          <Flex gap={2}>
            <Button variant="ghost" onClick={() => setModalState("workflowSelection")}>
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              isLoading={toolIsFetching}
              colorScheme="brand"
              onClick={async () => {
                if (isNewTool) {
                  const isValidUrl = await form.trigger("new_tool_url")
                  // we can navigate to the tool loading creation screen
                  if (!isValidUrl) return

                  setModalState("newToolGeneration")

                  try {
                    await createNewToolListing({
                      body: {
                        user_provided_url: form.getValues().new_tool_url ?? "",
                        user_provided_display_name: form.getValues().new_tool_display_name ?? "",
                      },
                    }).unwrap()
                  } catch (err) {
                    const errorDescription = getAPIErrorMessage(err)
                    toast({
                      title: intl.formatMessage(
                        {
                          id: "requests.start.error.toast",
                          description: "Start request error toast title",
                          defaultMessage: "There was an issue creating this {workflowKind} request",
                        },
                        { workflowKind: displayWorkflowKind(workflowKind ?? "purchase") }
                      ),
                      description: errorDescription,
                      status: "error",
                      duration: null,
                    })

                    // navigate back to the tool selection screen
                    if (workflowKind === "purchase") setModalState("toolSelection")
                  }

                  return
                }

                // trigger form validation
                const isValid = await form.trigger(WorkflowToolSectionFields)
                if (!isValid) return
                setModalState("workflow")
              }}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    newToolGeneration: (
      <>
        <ModalBody>
          <Center height="100%">
            <Flex flexDirection="column" gap={3}>
              <Center>
                <Spinner size="md" colorScheme="brand" />
              </Center>
              <FormattedMessage
                id="requests.start.modal.newTool.loading"
                defaultMessage="Loading..."
                description="Request start modal continue button text"
              />
            </Flex>
          </Center>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Progress value={getProgress(1, PURCHASE_STEPS)} flex={1} size="sm" maxWidth={200} />
          {/* buttons section */}
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={() => {
                setModalState("toolSelection")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button colorScheme="brand" isLoading={toolIsFetching} rightIcon={<Icon as={NextIcon} />}>
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    legalAgreement: (
      <>
        <ModalBody overflowY="auto">
          <WorkflowLegalAgreementSection
            control={form.control as unknown as Control<WorkflowLegalAgreementSectionFormState>}
            selectedToolWithDetails={selectedToolWithDetails}
            selectedLegalAgreement={form.watch("legal_agreement")}
            isPreselectedToolRenewal={isPreselectedRenewal}
            workflowKind={workflowKind}
            setValue={form.setValue}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Progress
            value={
              workflowKind === "purchase"
                ? getProgress(2, PURCHASE_STEPS)
                : workflowKind === "renewal"
                  ? getProgress(2, RENEWAL_STEPS)
                  : 0
            }
            flex={1}
            size="sm"
            maxWidth={200}
          />
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={() => {
                setModalState("workflowSelection")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              colorScheme="brand"
              onClick={async () => {
                const isValid = await form.trigger(WorkflowLegalAgreementSectionFields)
                if (!isValid) return
                setModalState("workflow")
              }}
              isLoading={toolIsFetching}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    workflow: (
      <>
        <ModalBody>
          <WorkflowSection
            control={form.control as unknown as Control<WorkflowSectionFormState>}
            workflowKind={workflowKind}
            departments={form.watch("departments")}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Progress
            value={
              workflowKind === "purchase"
                ? getProgress(2, PURCHASE_STEPS)
                : workflowKind === "renewal"
                  ? getProgress(2, RENEWAL_STEPS)
                  : 0
            }
            flex={1}
            size="sm"
            maxWidth={200}
          />
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={() => {
                if (workflowKind === "purchase") setModalState("toolSelection")
                if (workflowKind === "renewal") setModalState("legalAgreement")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              colorScheme="brand"
              onClick={async () => {
                // trigger form validation
                const isValid = await form.trigger(WorkflowSectionFields)
                if (!isValid) return
                setModalState("confirmation")
              }}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    confirmation: (
      <>
        <ModalBody>
          <WorkflowConfirmSection
            control={form.control}
            selectedAgreement={form.watch("legal_agreement")}
            selectedTool={form.watch("tool")}
            newLegalAgreementName={form.watch("new_legal_agreement_name")}
            departments={form.watch("departments")}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Progress
            value={
              workflowKind === "purchase"
                ? getProgress(3, PURCHASE_STEPS)
                : workflowKind === "renewal"
                  ? getProgress(3, RENEWAL_STEPS)
                  : 0
            }
            flex={1}
            size="sm"
            maxWidth={200}
          />
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={() => {
                setModalState("workflow")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => {
                setModalState("inviteNewUsers")
              }}
              isLoading={isLoading}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    inviteNewUsers: (
      <>
        <ModalBody>
          <WorkflowInviteSection control={form.control as unknown as Control<WorkflowInviteSectionFormState>} />
        </ModalBody>
        <ModalFooter justifyContent="flex-end" gap={2}>
          <Button
            variant="ghost"
            onClick={async (event) => {
              form.setValue("skipped_invites", true)
              await onSubmit(event)
            }}
            isDisabled={isLoading}
          >
            <FormattedMessage
              id="requests.start.modal.invite.skip"
              defaultMessage="Invite seller later"
              description="Invite seller later button text"
            />
          </Button>
          <Button colorScheme="brand" onClick={onSubmit} isLoading={isLoading} rightIcon={<Icon as={SendIcon} />}>
            <FormattedMessage
              id="requests.start.modal.inviteSellers"
              defaultMessage="Invite seller"
              description="Request start modal invite seller button text"
            />
          </Button>
        </ModalFooter>
      </>
    ),
  }

  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
      returnFocusOnClose={returnFocusOnClose}
    >
      <ModalOverlay />
      <ModalContent height={680}>
        <StartWorkflowModalHeader modalState={modalState} />
        {screens[modalState]}
      </ModalContent>
    </Modal>
  )
}

interface StartWorkflowModalHeaderProps {
  modalState: ModalScreens
  vendorName?: string
}

const StartWorkflowModalHeader: React.FC<StartWorkflowModalHeaderProps> = (props) => {
  switch (props.modalState) {
    case "workflowSelection":
    case "toolSelection":
    case "newToolGeneration":
    case "legalAgreement":
    case "workflow":
    case "confirmation":
      return (
        <ModalHeader>
          <Text>
            <FormattedMessage
              id="requests.start.modal.title.test"
              description="Title of the start request modal"
              defaultMessage="Create New Request"
            />
          </Text>
          <ModalCloseButton />
        </ModalHeader>
      )
    case "inviteNewUsers":
      return (
        <ModalHeader>
          <Stack alignItems="center">
            <Image src={avatarGroup} alt="Invite new users" padding={4} width="30%" />
            <Text>
              <FormattedMessage
                id="requests.start.modal.inviteNewUsers.header"
                defaultMessage="Send Link to Seller"
                description="Request start modal invite new users text"
              />
            </Text>
            <Text textAlign="center" fontSize="sm" fontWeight="normal">
              <FormattedMessage
                id="requests.start.modal.inviteNewUsers.subheading"
                defaultMessage="Invite members of the sales team to help you complete the request. You will be able to view their inputs and make changes before you submit the step for approval. You may invite others to collaborate at any time."
                description="Request start modal invite new users text"
              />
            </Text>
          </Stack>
        </ModalHeader>
      )
    default:
      unreachable(props.modalState)
  }
}
