import type { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie"
import type { ReactNode } from "react"

interface PieArcProps<Datum> {
  color: string
  path: ProvidedProps<Datum>["path"]
  arc: PieArcDatum<Datum>
  label?: ReactNode
}

export function PieArc<Datum>({ path, arc, color, label }: PieArcProps<Datum>) {
  const pathValue = path(arc) ?? ""
  // Finds the middle angle of the pie arc in radians and normalizes it to be between -pi/2 and pi/2
  const middleAngle = Math.PI / 2 - (arc.startAngle + (arc.endAngle - arc.startAngle) / 2)

  const radius = path.outerRadius()(arc)
  const normalX = Math.cos(middleAngle)
  const normalY = Math.sin(-middleAngle)

  // Adds a 8px padding from the edge of the pie to the label
  const labelX = normalX * (radius + 8)
  const labelY = normalY * (radius + 8)

  const textAnchor =
    // If the label is near (within 0.05 radians of) the top or the bottom of the pie,
    // anchor the label by the middle of the text. Otherwise, render it on the side of the pie
    Math.abs(Math.abs(middleAngle) - Math.PI / 2) < 0.05
      ? "middle"
      : Math.abs(middleAngle) < Math.PI / 2
        ? "start"
        : "end"

  return (
    <>
      <path d={pathValue} fill={color} />
      {label && (
        <text fontSize={12} fontWeight={600} x={labelX} y={labelY} dominantBaseline="middle" textAnchor={textAnchor}>
          {label}
        </text>
      )}
    </>
  )
}
