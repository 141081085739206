import type { WorkflowRunMinimal } from "@brm/schema-types/types.js"
import type { UseModalProps } from "@chakra-ui/react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDeleteWorkflowV1RunsByIdMutation } from "../../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"

interface Props extends UseModalProps {
  workflowRun: WorkflowRunMinimal
  onDelete?: () => void
}

export function DeleteWorkflowRunModal({ workflowRun, isOpen, onClose, onDelete }: Props) {
  const intl = useIntl()
  const toast = useToast()

  const [deleteWorkflowRun, deleteWorkflowRunResult] = useDeleteWorkflowV1RunsByIdMutation()
  const cancelDeleteIntegrationRef = useRef<HTMLButtonElement>(null)

  const onDeleteWorkflowClick = async () => {
    try {
      await deleteWorkflowRun({ id: workflowRun.id }).unwrap()
      toast({
        description: intl.formatMessage(
          {
            id: "requests.run.delete.success.toast.title",
            description: "Toast message shown when request deletion succeeds",
            defaultMessage: "<strong>{workflowName}</strong> successfully deleted",
          },
          { workflowName: workflowRun.display_name }
        ),
        status: "success",
      })
      onDelete?.()
      onClose()
    } catch (err) {
      log.error("Error deleting request", err, { workflowRun })
      const title =
        getAPIErrorMessage(err) ||
        intl.formatMessage(
          {
            defaultMessage: "Something went wrong while deleting <strong>{workflowName}</strong>",
            description: "The toast title for the error toast when a request could not be deleted",
            id: "requests.modal.delete.error.toast.title",
          },
          { workflowName: workflowRun.display_name }
        )
      toast({ status: "error", title })
    }
  }

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelDeleteIntegrationRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <FormattedMessage
              id="requests.delete.modal.header"
              description="Modal header for delete request confirmation"
              defaultMessage="Delete Request"
            />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage
              id="requests.delete.modal.body"
              description="Text shown in modal confirming that user wants to permanently delete a request"
              defaultMessage="Are you sure you want to delete <strong>{name}</strong>? Deleting the request will also delete the in-progress legal agreement if it is not signed yet. You cannot undo this action afterwards."
              values={{
                name: workflowRun.display_name,
                strong: (content) => <strong>{content}</strong>,
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter gap={2}>
            <Button ref={cancelDeleteIntegrationRef} onClick={onClose}>
              <FormattedMessage
                id="requests.delete.modal.keep"
                description="Button text for keeping the request in the delete confirmation modal"
                defaultMessage="Keep"
              />
            </Button>
            <Button colorScheme="error" isLoading={deleteWorkflowRunResult.isLoading} onClick={onDeleteWorkflowClick}>
              <FormattedMessage
                id="request.delete.modal.delete"
                description="Button text for delete request button in the delete confirmation modal"
                defaultMessage="Delete"
              />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
