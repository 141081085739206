import type { Permission, User } from "@brm/schema-types/types.js"
import type { ModalProps, UseModalProps } from "@chakra-ui/react"
import InviteOrUpdateUserModal from "../invites/InviteOrUpdateUserModal.js"

interface Props extends Pick<ModalProps, "finalFocusRef">, UseModalProps {
  user: Pick<User, "id" | "email" | "first_name" | "last_name" | "roles" | "profile_image">
  onSuccess?: (user: User) => void
  requiredPermission?: Permission
}

export function UserUpdateModal({ user, ...props }: Props) {
  return (
    <InviteOrUpdateUserModal
      userToUpdateId={user.id}
      initialState={{
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        roles: user.roles,
      }}
      {...props}
    />
  )
}
