import { IconButton, Tooltip, type IconButtonProps, type TooltipProps } from "@chakra-ui/react"
import type { FunctionComponent } from "react"

export const IconButtonWithTooltip: FunctionComponent<
  Omit<IconButtonProps, "aria-label"> & Pick<TooltipProps, "placement"> & { label: string }
> = ({ label, placement, ...props }) => (
  <Tooltip label={label} placement={placement}>
    <IconButton {...props} aria-label={label} />
  </Tooltip>
)
