import type {
  BillPayment,
  CreditCardTransaction,
  Expense,
  Transaction,
  TransactionPaymentMethod,
} from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { IntlShape } from "react-intl"
import { isPresent } from "ts-is-present"

export const displayPaymentMethod = (paymentMethod: TransactionPaymentMethod | null, intl: IntlShape) => {
  switch (paymentMethod) {
    case "bill_payment":
      return intl.formatMessage({
        id: "payments.payment_method.bill_payment",
        defaultMessage: "Bill Payment",
        description: "The label for the payment method `bill_payment`",
      })
    case "credit_card":
      return intl.formatMessage({
        id: "payments.payment_method.credit_card",
        defaultMessage: "Credit Card",
        description: "The label for the payment method `credit_card`",
      })
    case null:
      return intl.formatMessage({
        id: "payment.method.other",
        description: "The label for the payment method `other`",
        defaultMessage: "Other",
      })
    default:
      unreachable(paymentMethod)
  }
}

// Only one of expense or bill payment should be populated
export function getCategories(transaction: Transaction): string[] | null {
  if (transaction.expense) {
    return transaction.expense.line_items?.map((item) => item.account?.display_name).filter(isPresent)
  }

  if (transaction.bill_payment?.bill) {
    const bill = transaction.bill_payment.bill
    return bill.line_items?.map((item) => item.account?.display_name).filter(isPresent)
  }

  return null
}

export function deepLinkForCC(ccTransaction: CreditCardTransaction): string | undefined {
  switch (ccTransaction.integration.provider) {
    case "ramp_client_creds":
    case "ramp_oauth":
      return `https://app.ramp.com/expenses/transactions/all/${ccTransaction.external_transaction_id}`
    case "brex_apikey":
    case "brex_oauth": {
      if (!ccTransaction.external_expense_id) {
        return undefined
      }
      // Brex support has confirmed that the base64 encoding of the Expense:ID is the deeplink
      const deeplinkId = btoa(`Expense:${ccTransaction.external_expense_id}`)
      return `https://dashboard.brex.com/p/expenses?expenseId=${deeplinkId}`
    }
    default:
      return undefined
  }
}

export function deepLinkForExpense(expense: Expense): string | undefined {
  switch (expense.integration.provider) {
    case "quickbooks_oauth":
      return `https://app.qbo.intuit.com/app/expense?&txnId=${expense.external_id}`
    case "merge_accounting_link_token":
      if (!expense.merge_remote_id) {
        return undefined
      }

      switch (expense.integration.merge_accounting_type) {
        case "netsuite":
          return expense.integration.external_id
            ? `https://${expense.integration.external_id}.app.netsuite.com/app/accounting/transactions/transaction.nl?id=${expense.merge_remote_id}`
            : undefined
        case "quickbooks-online":
          return `https://app.qbo.intuit.com/app/expense?&txnId=${expense.merge_remote_id}`
        default:
          return undefined
      }
    default:
      return undefined
  }
}

export function deepLinkForBillPayment(billPayment: BillPayment): string | undefined {
  switch (billPayment.integration.provider) {
    case "quickbooks_oauth":
      return `https://app.qbo.intuit.com/app/billpayment?txnId=${billPayment.external_id}`
    case "merge_accounting_link_token":
      if (!billPayment.merge_remote_id) {
        return undefined
      }

      switch (billPayment.integration.merge_accounting_type) {
        case "netsuite":
          return billPayment.integration.external_id
            ? `https://${billPayment.integration.external_id}.app.netsuite.com/app/accounting/transactions/transaction.nl?id=${billPayment.merge_remote_id}`
            : undefined
        case "quickbooks-online":
          return `https://app.qbo.intuit.com/app/billpayment?txnId=${billPayment.merge_remote_id}`
        default:
          return undefined
      }
    default:
      return undefined
  }
}
