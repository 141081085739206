import { Center, Spinner } from "@chakra-ui/react"
import { useEffect, type FC } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { isString } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1LinksByCodeWorkflowRunQuery,
} from "../../../app/services/generated-api.js"
import { useDocumentVisibility } from "../../../util/visibility.js"
import { WorkflowRunOverview } from "./WorkflowRunOverview.js"

/**
 * The form that is presented to the recipient of a BRM link to contribute to a workflow_run_step.
 * The data goes into a staging area of the link until accepted by the step owner.
 */
export const BrmLinkWorkflowRunPage: FC = () => {
  const { code } = useParams<{ code: string }>()
  const navigate = useNavigate()
  isString(code, "Missing link auth code")

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const documentVisibility = useDocumentVisibility()
  const {
    data: workflowRun,
    error,
    isLoading,
  } = useGetWorkflowV1LinksByCodeWorkflowRunQuery(
    { code },
    {
      // Make sure submissions in other tabs move the workflow in this tab without needing a manual reload
      pollingInterval: documentVisibility !== "hidden" ? 5_000 : undefined,
    }
  )

  useEffect(() => {
    // If the user is signed into the buyer organization we redirect them to the workflow run page
    if (whoami && workflowRun && whoami.organization_id === workflowRun.owner.organization_id) {
      navigate(`/requests/${workflowRun.id}`)
    }
  }, [navigate, whoami, workflowRun])

  if (error) {
    throw error
  }

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  if (!workflowRun) {
    return null
  }

  return <WorkflowRunOverview workflowRun={{ ...workflowRun, is_external: true }} />
}
