import { Button, Icon, Text, type ButtonProps } from "@chakra-ui/react"
import type { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { ChevronUpIcon, CommentIcon } from "../../../../../components/icons/icons.js"

export const StepLevelCommentsButton: FC<
  {
    isOpen: boolean
    commentCount?: number
    onClose: () => void
    onOpen: () => void
  } & ButtonProps
> = ({ isOpen, commentCount, onClose, onOpen, ...props }) => {
  const intl = useIntl()

  if (isOpen) {
    return (
      <Button
        size="sm"
        variant="link"
        leftIcon={<Icon as={ChevronUpIcon} />}
        colorScheme="brand"
        onClick={onClose}
        {...props}
      >
        <FormattedMessage
          defaultMessage="Hide activity"
          description="Text on button to hide comments on a step"
          id="workflowRun.form.step.timeline.hide"
        />
      </Button>
    )
  }
  return (
    <Button
      size="sm"
      variant={commentCount ? "subtleOutlined" : "ghost"}
      leftIcon={<Icon as={CommentIcon} />}
      colorScheme={commentCount ? "brand" : "gray"}
      iconSpacing={0}
      gap={2}
      onClick={onOpen}
      aria-label={intl.formatMessage(
        {
          defaultMessage: "{count} {count, plural, =1 {comment} other {comments}}",
          description: "Button ARIA label to view comments on a field",
          id: "workflowRun.form.field.timeline.aria.label",
        },
        { count: commentCount ?? 0 }
      )}
      {...props}
    >
      {commentCount !== undefined && commentCount >= 1 && <Text>{commentCount}</Text>}
    </Button>
  )
}
