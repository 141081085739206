import { Icon } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { IconButtonWithTooltip } from "../../IconButtonWithTooltip.js"
import { ChevronRightIcon } from "../icons.js"
import type { BrmIconButtonProps } from "./types.js"

/**
 * Wrapper around IconButton that adds a default tooltip and aria-label intl string.
 * Also allows for a global setting for what Chakra icon should be used for each button type.
 */
export default function NextIconButton({ label, iconProps, ...props }: BrmIconButtonProps) {
  const intl = useIntl()

  const labelMessage =
    label ??
    intl.formatMessage({
      id: "iconButtons.next.label",
      description: "The label for a next button",
      defaultMessage: "Next",
    })

  return <IconButtonWithTooltip label={labelMessage} icon={<Icon as={ChevronRightIcon} {...iconProps} />} {...props} />
}
