import type { ListQueryStringParams, PersonListItem } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Flex } from "@chakra-ui/react"
import { useMemo } from "react"
import type { Person } from "../../../app/services/generated-api.js"
import { usePostPersonV1Query } from "../../../app/services/generated-api.js"
import type { Props as ComboBoxProps } from "../../../components/ComboBox/ComboBox.js"
import ComboBox from "../../../components/ComboBox/ComboBox.js"
import PersonDisplay from "../../person/PersonDisplay.js"

interface Props {
  onChangeSelectedPerson: (person: Person) => void
  onChangeEmail: (email: string) => void
  email: string
}

export default function InviteEmailAutocomplete(props: Props & Pick<ComboBoxProps<Person>, "inputProps">) {
  const { email, onChangeEmail, onChangeSelectedPerson } = props

  const apiParams = useMemo((): ListQueryStringParams => {
    const filter: Filter<BRMPaths<PersonListItem>>[][] = [
      [
        {
          column: "employment_status",
          fields: { comparator: "ne", value: "inactive" },
        },
      ],
      // AND
      [
        {
          // person has no linked user
          column: "user_id",
          fields: { comparator: "exists", value: false },
        },
        // OR
        {
          // person is linked to a deleted user
          column: "user.status",
          fields: { comparator: "eq", value: "deleted" },
        },
      ],
    ]
    if (email) {
      filter.push([
        {
          column: "email",
          fields: { comparator: "contains", value: email },
        },
        // OR
        {
          column: "display_name",
          fields: { comparator: "contains", value: email },
        },
      ])
    }
    return { filter, limit: 10 }
  }, [email])

  const { data: personsData } = usePostPersonV1Query({ listQueryStringParams: apiParams })
  return (
    <ComboBox<Person>
      autocompleteItems={personsData?.persons}
      renderAutocompleteItem={(person) => (
        <Flex padding={2} cursor="pointer">
          <PersonDisplay key={person.id} person={person} />
        </Flex>
      )}
      itemToString={(person) => person?.email ?? ""}
      onSelectedItemChange={onChangeSelectedPerson}
      getAutocompleteItemKey={(person) => person.id}
      onInputValueChange={onChangeEmail}
      {...props}
    />
  )
}
