import type { VendorTool } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { Button, Card, Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "../../components/Link.js"
import { ToolLogo } from "../../components/icons/Logo.js"
import { NextIcon } from "../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../util/url.js"

export interface Props {
  tools: VendorTool[]
}

export const ToolCardsList = ({ tools }: Props) => {
  const intl = useIntl()
  return tools.length > 0 ? (
    <Stack gap={4} flex={1} minW="fit-content">
      {tools.map((tool) => (
        <Card variant="outline" borderRadius="xl" px={6} py={5} key={tool.id}>
          <HStack justify="space-between">
            <HStack flexShrink={0}>
              <ToolLogo logo={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)} boxSize={8} />
              <Flex direction="column">
                <Text fontSize="lg" fontWeight="semibold">
                  {tool.display_name}
                </Text>
                <Text color="gray.600" whiteSpace="nowrap">
                  {tool.owner ? (
                    <FormattedMessage
                      id="vendor.toolCardsList.ownerText"
                      defaultMessage="Owned by: {ownerName}"
                      description="Text on tool card on vendor page to show tool owner"
                      values={{ ownerName: displayPersonName(tool.owner, intl) }}
                    />
                  ) : (
                    <FormattedMessage
                      id="vendor.toolCardsList.noOwner"
                      defaultMessage="Owner: None"
                      description="Text on tool card on vendor page to show missing owner"
                    />
                  )}
                </Text>
              </Flex>
            </HStack>
            <Button
              variant="outline"
              as={Link}
              to={`/tools/${tool.id}`}
              fontSize="xs"
              rightIcon={<Icon as={NextIcon} fontSize="2xs" />}
              flexShrink={0}
            >
              <FormattedMessage
                id="vendor.toolCardsList.detailText"
                defaultMessage="Tool Details"
                description="Button text on tool card on vendor page to navigate to tool detail page"
              />
            </Button>
          </HStack>
        </Card>
      ))}
    </Stack>
  ) : (
    <Text>
      <FormattedMessage
        id="vendor.toolCardsList.empty"
        defaultMessage="No tools found for this vendor"
        description="Message displayed in vendor tool tab when no tools are found"
      />
    </Text>
  )
}
