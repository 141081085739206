import type { Transaction, TransactionMinimal } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { useLocation, useNavigate } from "react-router-dom"
import DataTable from "../../../components/DataTable/DataTable.js"
import { useSchemaColumns } from "../../../components/DataTable/use-schema-columns.js"
import { useObjectSchema } from "../../../util/use-schema.js"

const defaultColumns: BRMPaths<Transaction>[] = ["transacted_at", "currency_amount", "person", "receipts"]

export default function AssociatedTransactions({
  transactions,
  legalAgreementId,
}: {
  transactions?: TransactionMinimal[]
  legalAgreementId: string
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const hashParams = new URLSearchParams(location.hash.slice(1))
  const transactionSchema = useObjectSchema("ReconciledTransaction")
  const columns = useSchemaColumns<TransactionMinimal>({
    objectSchema: transactionSchema,
    shownColumns: defaultColumns,
    enableSorting: false,
  })
  if (!transactionSchema || !transactions || !columns) {
    return null
  }
  return (
    <DataTable
      borderWidth="1px"
      overflow="auto"
      data={transactions}
      columns={columns}
      onRowClick={(transaction) => {
        const hash = new URLSearchParams(hashParams)
        hash.set("transaction", transaction.id)
        hash.set("agreement", legalAgreementId)

        return navigate({
          search: location.pathname === "/agreements" ? location.search : undefined,
          hash: `#${hash}`,
        })
      }}
    />
  )
}
