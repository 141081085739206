import { getTitle } from "@brm/util/schema.js"
import { Checkbox, Divider, FormControl, Heading, Input, Stack, Text, Textarea } from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Controller, type Control } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useObjectSchema } from "../../../util/use-schema.js"
import FieldSchemaTypeSelect from "../../settings/criteria/FieldSchemaTypeSelect.js"
import { type ConfigureWorkflowStepsFormState } from "./utils.js"

export default function FieldDefinition({
  fieldIndex,
  stepIndex,
  control,
  field,
}: {
  fieldIndex: number
  stepIndex: number
  control: Control<ConfigureWorkflowStepsFormState>
  field: ConfigureWorkflowStepsFormState["steps"][number]["fields"][number]
}) {
  const objectSchema = useObjectSchema(field.object_type)
  const fieldSchema = field.is_custom
    ? objectSchema?.properties?.custom?.properties[field.field_name]
    : (objectSchema?.properties?.[field.field_name] as JSONSchemaObject | undefined)

  if (!fieldSchema) {
    return null
  }

  const title = getTitle(field.field_name, fieldSchema)

  return (
    <Stack py={4}>
      <Heading as="h2" size="sm" px={4}>
        {title}
      </Heading>
      <Divider />
      <Stack px={4}>
        <Text fontWeight="medium">
          <FormattedMessage
            id="workflows.definition.fieldDefinition.label.name"
            description="Label for the field name"
            defaultMessage="Field name"
          />
        </Text>
        <Input value={title} isReadOnly />
        <Text fontWeight="medium">
          <FormattedMessage
            id="workflows.definition.fieldDefinition.label.description"
            description="Label for the field description"
            defaultMessage="Field description"
          />
        </Text>
        <Textarea value={fieldSchema.description || fieldSchema.uiDescription} isReadOnly resize="none" />
        <Text fontWeight="medium">
          <FormattedMessage
            id="workflows.definition.fieldDefinition.label.data_type"
            description="Label for the field data type"
            defaultMessage="Field data type"
          />
        </Text>
        <FieldSchemaTypeSelect value={fieldSchema} onChange={() => {}} isReadOnly={true} />
        <Controller
          name={`steps.${stepIndex}.fields.${fieldIndex}.is_internal_only`}
          control={control}
          render={({ field }) => (
            <FormControl>
              <Checkbox isChecked={!field.value} onChange={(e) => field.onChange(!e.target.checked)}>
                <FormattedMessage
                  defaultMessage="Share with seller"
                  id="requests.config.external.shareWithSeller"
                  description="request config field toggle checkbox that selects whether a field can be shared with sellers"
                />
                <Text fontWeight="light">
                  <FormattedMessage
                    defaultMessage="Allow input to be requested from the seller in requests"
                    id="requests.config.external.askSeller.description"
                    description="request config field toggle checkbox subtext explaining what it means to ask the seller for input"
                  />
                </Text>
              </Checkbox>
            </FormControl>
          )}
        />
        {!fieldSchema.requestConfigReadOnly && (
          <Controller
            name={`steps.${stepIndex}.fields.${fieldIndex}.is_required`}
            control={control}
            render={({ field }) => (
              <FormControl>
                <Checkbox isChecked={field.value} onChange={(e) => field.onChange(e.target.checked)}>
                  <FormattedMessage
                    defaultMessage="Required"
                    id="requests.config.required"
                    description="request config field toggle checkbox that selects whether a field is required or not"
                  />
                </Checkbox>
              </FormControl>
            )}
          />
        )}
      </Stack>
    </Stack>
  )
}
