import { isWorkflowRunProgressType } from "@brm/schema-helpers/schema.js"
import type { AnyFilter, ArrContainsFilter, FilterField } from "@brm/schema-types/types.js"
import { isCurrencyAmountType, isSpendSummaryType, isStdObjSchema } from "@brm/type-helpers/schema.js"
import { unwrapNullableSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { ReadonlyDeep } from "type-fest"

/**
 * Returns if the filter is a filter that has an array of values and isNullable.
 */
export function isValuesFilter(filter?: FilterField): filter is AnyFilter | ArrContainsFilter {
  switch (filter?.comparator) {
    case "arr_contains":
    case "any":
      return true
  }
  return false
}

/**
 * Given a display path in the data and a schema for that field, returns the path to use for filtering.
 * For example, the filter path for a field that is a standard object is the path to the `id` property of that object.
 * The filter path for an array of standard objects is a path like `foo.bar.*.id`, with a wildcard in place of the array index.
 * In other cases, it is the same as the display path.
 */
export function filterPathFromDisplayPath(
  displayPath: readonly string[],
  schema: ReadonlyDeep<JSONSchema>
): readonly string[] {
  schema = unwrapNullableSchema(schema)
  if (!isObject(schema)) {
    return displayPath
  }
  if (isStdObjSchema(schema)) {
    return [...displayPath, "id"]
  }
  if (isStdObjSchema(schema.items)) {
    const field = schema.items.properties.object_type.const === "ErpTrackingCategory" ? "display_name" : "id"
    return [...displayPath, "*", field]
  }
  if (isSpendSummaryType(schema)) {
    // Note: It's possible to filter by the growth by selecting that filter/column specifically, but the "primary"
    // value of this cell is the current amout.
    return [...displayPath, "currency_amount", "amount"]
  }
  if (isCurrencyAmountType(schema)) {
    return [...displayPath, "amount"]
  }
  if (isWorkflowRunProgressType({ schema })) {
    return [...displayPath, "status"]
  }
  return displayPath
}

/**
 * The inverse of {@link filterPathFromDisplayPath}.
 */
export function displayPathFromFilterPath(filterPath: string[]): string[] {
  const wildcardIndex = filterPath.indexOf("*")
  if (wildcardIndex !== -1) {
    return filterPath.slice(0, wildcardIndex)
  }
  if (filterPath.at(-1) === "id") {
    return filterPath.slice(0, -1)
  }
  if (filterPath.at(-4) === "spend" && filterPath.at(-2) === "currency_amount" && filterPath.at(-1) === "amount") {
    return filterPath.slice(0, -2)
  }
  if (filterPath.at(-1) === "amount") {
    return filterPath.slice(0, -1)
  }
  if (filterPath.at(-2) === "progress" && filterPath.at(-1) === "status") {
    return filterPath.slice(0, -1)
  }
  return filterPath
}
