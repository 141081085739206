import type { FormFieldConfig, ObjectType } from "@brm/schema-types/types.js"
import { Box, chakra, Flex, Portal, Text, useMultiStyleConfig, type FlexProps, type Theme } from "@chakra-ui/react"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type KeyboardEventHandler,
  type RefObject,
} from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { createEditor, Editor, Range, Transforms, type Descendant } from "slate"
import {
  Editable,
  ReactEditor,
  Slate,
  useSlate,
  withReact,
  type RenderElementProps,
  type RenderLeafProps,
} from "slate-react"
import type { EditableProps } from "slate-react/dist/components/editable.js"
import { ListBox, ListBoxItem } from "../../../components/ListBox.js"
import { Element } from "../../../components/RichTextEditor/Element.js"
import { Leaf } from "../../../components/RichTextEditor/Leaf.js"
import type { TagPickerListRef } from "../../../components/RichTextEditor/TagPickerList.js"
import { EMPTY_RICH_TEXT_BODY } from "../../../components/RichTextEditor/util/common.js"
import { insertFieldTag, withFieldTags } from "../../../components/RichTextEditor/util/field-tag.js"
import { getUniqueFormFieldKey } from "./utils.js"

const ConditionalEditor = forwardRef<
  { focus: () => void },
  {
    initialValue: Descendant[]
    onChange: (value: Descendant[]) => void
    eligibleFields: (FormFieldConfig & { display_name: string })[]
    containerProps?: FlexProps
  }
>(function ConditionalEditor({ initialValue, onChange, eligibleFields, containerProps }, ref) {
  const intl = useIntl()

  const editor = useMemo(() => {
    return withFieldTags(withReact(createEditor()))
  }, [])

  /** Field tag state */
  const [fieldTagRange, setFieldTagRange] = useState<Range | null>(null)
  const fieldTagSearch = fieldTagRange ? Editor.string(editor, fieldTagRange).substring(1) : ""
  const fieldTagOptionsRef = useRef<TagPickerListRef | null>(null)

  const insertFieldTagElement = useCallback(
    (fieldName: string, objectType: ObjectType, isCustom: boolean) => {
      if (fieldTagRange) {
        Transforms.select(editor, fieldTagRange)
        insertFieldTag(editor, fieldName, objectType, isCustom)
      }
      setFieldTagRange(null)
      editor.onChange()
    },
    [editor, fieldTagRange]
  )

  const richTextContainerRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => ({
    focus: () => {
      richTextContainerRef.current?.scrollIntoView({ block: "center" })
      ReactEditor.focus(editor)
      setTimeout(() => {
        Transforms.select(editor, Editor.end(editor, []))
      }, 10)
    },
  }))

  const decorate = useCallback<NonNullable<EditableProps["decorate"]>>(
    ([_, path]) => {
      if (fieldTagRange) {
        const intersection = Range.intersection(fieldTagRange, Editor.range(editor, path))

        if (intersection === null) {
          return []
        }

        const range = {
          highlighted: true,
          ...intersection,
        }

        return [range]
      }
      return []
    },
    [editor, fieldTagRange]
  )

  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === "@") {
        if (!fieldTagRange && editor.selection && Range.isCollapsed(editor.selection)) {
          const characterBeforePoint = Editor.before(editor, editor.selection.anchor, { unit: "character" })
          const characterBeforeRange =
            characterBeforePoint && Editor.range(editor, characterBeforePoint, editor.selection.anchor)
          const characterBefore = characterBeforeRange && Editor.string(editor, characterBeforeRange)
          // If the user types @ and the user selection is collapsed, set the current position as the mention range
          if (!characterBefore || /\s/u.test(characterBefore)) {
            const newRange = Editor.range(
              editor,
              editor.selection.anchor,
              Editor.after(editor, editor.selection.anchor, { unit: "character" })
            )
            setFieldTagRange(newRange)
          }
        }
        return
      }

      if (fieldTagRange) {
        // Arrow key navigation and selection of mention options
        switch (event.key) {
          case "ArrowDown": {
            event.preventDefault()
            fieldTagOptionsRef.current?.incrementIndex()
            break
          }
          case "ArrowUp": {
            event.preventDefault()
            fieldTagOptionsRef.current?.decrementIndex()
            break
          }
          case "Tab":
          case "Enter": {
            event.preventDefault()
            fieldTagOptionsRef.current?.selectCurrent()
            setFieldTagRange(null)
            break
          }
          case "Escape": {
            setFieldTagRange(null)
            break
          }
        }
      }
    },
    [editor, setFieldTagRange, fieldTagRange]
  )

  const textAreaStyleConfig = useMultiStyleConfig("Textarea", {
    size: "sm",
  }) as NonNullable<Theme["components"]["Textarea"]["baseStyle"]>

  return (
    <Flex flexDir="column" ref={richTextContainerRef} sx={textAreaStyleConfig} {...containerProps}>
      <Slate
        editor={editor}
        initialValue={initialValue ?? EMPTY_RICH_TEXT_BODY}
        onSelectionChange={(newSelection) => {
          if (!fieldTagRange) {
            return
          }
          if (!newSelection) {
            setFieldTagRange(null)
            return
          }
          const intersecting = Range.intersection(newSelection, fieldTagRange)
          if (!intersecting) {
            setFieldTagRange(null)
          }
        }}
        onValueChange={(value) => {
          onChange(value)
          // Check if the mention range should change
          if (fieldTagRange) {
            if (editor.selection && Range.isCollapsed(editor.selection)) {
              const cursorPoint = editor.selection.anchor
              if (cursorPoint) {
                // The potential new mention range is the current mention range start up to the cursor
                const newRange = Editor.range(editor, fieldTagRange.anchor, cursorPoint)
                const mentionText = Editor.string(editor, newRange)
                if (mentionText.startsWith("@")) {
                  setFieldTagRange(newRange)
                } else {
                  // If the @ has been deleted, clear the mention range
                  setFieldTagRange(null)
                }
              }
            }
          }
        }}
      >
        <Editable
          decorate={decorate}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={intl.formatMessage({
            id: "conditional-editor.placeholder",
            defaultMessage: "Describe a condition in your own words. Try typing @ to mention a field.",
            description: "Placeholder text for the conditional editor",
          })}
          spellCheck
          onBlur={(event) => {
            // If an input is in a popover, prevent popover from closing when clicking on mention options
            if (event.relatedTarget?.matches(".mention-option")) {
              event.stopPropagation()
            } else {
              setFieldTagRange(null)
            }
          }}
          tabIndex={0}
          onKeyDown={onKeyDown}
          style={{
            outline: "none",
            boxShadow: "none",
          }}
        />
        {fieldTagRange && (
          <Portal>
            <ConditionalFieldPickerList
              ref={fieldTagOptionsRef}
              fieldTagSearch={fieldTagSearch}
              fieldTagRange={fieldTagRange}
              resetFieldTagRange={() => setFieldTagRange(null)}
              insertFieldTagElement={insertFieldTagElement}
              fieldsList={eligibleFields}
            />
          </Portal>
        )}
      </Slate>
    </Flex>
  )
})

export default ConditionalEditor

interface ConditionalFieldPickerListProps {
  fieldTagSearch: string
  insertFieldTagElement: (fieldName: string, objectType: ObjectType, isCustom: boolean) => void
  resetFieldTagRange: () => void
  fieldTagRange: Range | null
  fieldsList: (FormFieldConfig & { display_name: string })[]
}

const ConditionalFieldPickerList = forwardRef<TagPickerListRef, ConditionalFieldPickerListProps>(function TagPickerList(
  { fieldTagSearch, fieldTagRange, resetFieldTagRange, insertFieldTagElement, fieldsList },
  ref
) {
  const intl = useIntl()
  const editor = useSlate()
  const containerRef = useRef<HTMLDivElement>(null)
  const [mentionIndex, setMentionIndex] = useState<number>(0)
  const filteredFieldsList = useMemo(() => {
    return fieldsList.filter((field) => field.display_name.toLowerCase().includes(fieldTagSearch.toLowerCase()))
  }, [fieldsList, fieldTagSearch])
  const fieldsLength = filteredFieldsList.length

  useEffect(() => {
    // Reset the selection index to 0 when the search query changes
    setMentionIndex(0)
  }, [fieldTagSearch])

  useEffect(() => {
    // useEffect to set the position of the mention option list to appear under the current selection
    if (fieldTagRange) {
      const domRange = ReactEditor.toDOMRange(editor, fieldTagRange)
      const rect = domRange.getBoundingClientRect()

      setTimeout(() => {
        // SetTimeout: If typing "@m" then deleting the "m" then retyping the "m", the ref is null
        const top = rect.top + window.scrollY
        if (top > window.innerHeight - 300) {
          containerRef.current?.style.setProperty("bottom", `${window.innerHeight - rect.top + 4}px`)
        } else {
          containerRef.current?.style.setProperty("top", `${top + 24}px`)
        }
        containerRef.current?.style.setProperty("left", `${rect.left + window.scrollX}px`)
        containerRef.current?.style.setProperty("display", "flex")
      }, 1)
    }
  }, [editor, fieldTagRange])

  const onAction = useCallback(
    (field: FormFieldConfig) => {
      insertFieldTagElement(field.field_name, field.object_type, field.is_custom)
    },
    [insertFieldTagElement]
  )

  useImperativeHandle(
    ref,
    () => ({
      incrementIndex: () => {
        setMentionIndex((i) => (i + 1) % fieldsLength)
      },
      decrementIndex: () => {
        setMentionIndex((i) => (i - 1 < 0 ? fieldsLength - 1 : i - 1))
      },
      selectCurrent: () => {
        if (mentionIndex < fieldsLength) {
          const field = filteredFieldsList[mentionIndex]
          if (field) {
            onAction(field)
          }
        }
      },
    }),
    [fieldsLength, onAction, mentionIndex, setMentionIndex, filteredFieldsList]
  )
  return (
    <Box
      ref={containerRef}
      position="absolute"
      boxShadow="xl"
      borderRadius="lg"
      borderWidth={1}
      overflow="hidden"
      background="gray.50"
      minWidth="250px"
    >
      <ListBox
        width="full"
        aria-label={intl.formatMessage({
          id: "conditional-editor.field-listbox-label",
          defaultMessage: "Field tag list",
          description: "Label for the listbox of fields to mention",
        })}
        height="full"
        overflowY="auto"
        maxH="340px"
      >
        {fieldsLength === 0 ? (
          <ListBoxItem
            className="mention-option"
            textValue={intl.formatMessage({
              id: "conditional-editor.no-available-fields.textValue",
              defaultMessage: "No available fields",
              description: "No available fields text for conditional editor",
            })}
            onAction={resetFieldTagRange}
          >
            <FormattedMessage
              id="conditional-editor.no-available-fields"
              defaultMessage="No available fields <light>(Dismiss)</light>"
              description="No available fields text for conditional editor"
              values={{
                light: (content) => (
                  <chakra.span verticalAlign="middle" color="gray.500">
                    {content}
                  </chakra.span>
                ),
              }}
            />
          </ListBoxItem>
        ) : (
          filteredFieldsList.map((field, i) => (
            <ConditionalFieldPickerListItem
              key={getUniqueFormFieldKey(field)}
              field={field}
              isSelected={mentionIndex === i}
              onAction={onAction}
              containerRef={containerRef}
            />
          ))
        )}
      </ListBox>
    </Box>
  )
})

const ConditionalFieldPickerListItem = ({
  field,
  isSelected,
  onAction,
  containerRef,
}: {
  field: FormFieldConfig & { display_name: string }
  isSelected: boolean
  onAction: (field: FormFieldConfig) => void
  containerRef?: RefObject<HTMLDivElement>
}) => {
  const optionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isSelected && optionRef?.current && containerRef?.current) {
      const containerRect = containerRef.current?.getBoundingClientRect()
      const bounding = optionRef.current?.getBoundingClientRect()
      const isVisible =
        bounding.top >= containerRect.top &&
        bounding.bottom <= containerRect.bottom &&
        bounding.left >= containerRect.left &&
        bounding.right <= containerRect.right
      if (!isVisible) {
        optionRef?.current?.scrollIntoView()
      }
    }
  }, [containerRef, optionRef, isSelected])

  return (
    <ListBoxItem
      ref={optionRef}
      textValue={field.field_name}
      // Classname is used to identify the mention option in blur handler so blur events do not bubble up to the parent of the editor
      className="mention-option"
      background={isSelected ? "brand.100" : "transparent"}
      onAction={() => onAction(field)}
    >
      <Text>
        {field.display_name}{" "}
        <FormattedMessage
          id="fieldTag.objectType"
          description="Object type indicator on a field tag"
          defaultMessage="({objectType})"
          values={{ objectType: field.object_type }}
        />
      </Text>
    </ListBoxItem>
  )
}
