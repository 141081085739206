import type { ImageAsset, ImageElement } from "@brm/schema-types/types.js"
import type { CreateToastFnReturn } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"
import { Transforms } from "slate"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import { imageDownloadUrl } from "../../../util/document.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import type { CustomEditor } from "../types.js"

/** Image support */
export const withImages = (
  editor: CustomEditor,
  intl: IntlShape,
  uploadImage: (file: File) => Promise<ImageAsset>,
  toast: CreateToastFnReturn
) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const { files } = data
    if (files && files.length > 0) {
      for (const file of files) {
        if (file.type.startsWith("image/")) {
          uploadImage(file)
            .then((imageAsset) => {
              const url = imageDownloadUrl(imageAsset.id)
              insertImage(
                editor,
                url,
                imageAsset.file_name ??
                  intl.formatMessage({
                    id: "richText.image.upload.fileName.placeholder",
                    description:
                      "Placeholder default file name used to show image if a file name is not provided in the upload",
                    defaultMessage: "[Uploaded Image]",
                  })
              )
            })
            .catch((err) => handleImageUploadError(err, toast, intl))
        }
      }
    }

    insertData(data)
  }

  return editor
}

export const insertImage = (editor: CustomEditor, url: string, fileName: string) => {
  const text = { text: "" }
  const image: ImageElement = { type: "image", url, file_name: fileName, children: [text] }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  })
}

export const handleImageUploadError = (err: unknown, toast: CreateToastFnReturn, intl: IntlShape) => {
  toast({
    description:
      getAPIErrorMessage(err) ??
      intl.formatMessage({
        id: "richText.image.upload.error",
        description: "Error toast displayed when a user uploads an image to a rich text input and an error occurs",
        defaultMessage: "Something went wrong while uploading your image",
      }),
    status: "error",
    duration: LONG_TOAST_DURATION,
  })
}
