/* eslint-disable @typescript-eslint/no-restricted-imports */
import type { LinkProps as ChakraLinkProps, ComponentWithAs, HTMLChakraProps } from "@chakra-ui/react"
import { Link as ChakraLink, Text, forwardRef } from "@chakra-ui/react"
import type { ForwardedRef, FunctionComponent } from "react"
import type { LinkProps as ReactRouterLinkProps, To } from "react-router-dom"
import { Link as ReactRouterLink } from "react-router-dom"

export type LinkProps = Omit<ChakraLinkProps, "href"> & ReactRouterLinkProps

/**
 * Chakra's `<Link>` component wrapped to use `<Link>` from `react-router`.
 * This should always be used in place of Chakra's or React Router's `<Link>`.
 *
 * Works with both absolute and relative URLs in the `to` prop.
 *
 * Chakra's `<Link>`, if used without `as={ReactRouterLink}` (which is easy to forget), will cause hard page reloads.
 */
export const Link: ComponentWithAs<"a", LinkProps> = forwardRef<LinkProps, "a">(function Link({ to, ...props }, ref) {
  if (typeof to === "string") {
    let isExternal = false
    try {
      isExternal = new URL(to, window.location.href).origin !== window.location.origin
    } catch {
      // Ignore invalid URLs
    }
    if (isExternal) {
      // External link, do not use react-router and always open in a new tab
      return <ChakraLink target="_blank" {...props} href={to} ref={ref} />
    }
  }
  return <ChakraLink {...props} to={to} as={ReactRouterLink} ref={ref} />
})

export type LinkOrSpanProps = Omit<LinkProps, "to"> & {
  to?: To | null
} & HTMLChakraProps<"span">

/**
 * Renders a Link if `to` is defined, otherwise renders a span.
 */
export const LinkOrSpan: FunctionComponent<LinkOrSpanProps> = forwardRef(
  ({ to, ...props }, ref: ForwardedRef<HTMLAnchorElement | HTMLSpanElement>) =>
    to ? <Link to={to} {...props} ref={ref} /> : <Text as="span" {...props} ref={ref} />
)
