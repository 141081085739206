import type { PersonListItem, TableIdentifier } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import type { TableParamsState } from "../../util/schema-table.js"

export const TOOL_TIMELINE_HIDE_STORAGE_KEY = "tool-timeline-hidden"
export const TOOL_LOGINS_TABLE_ID: TableIdentifier = "tool-logins"

export const TOOL_USERS_TABLE_ID: TableIdentifier = "tool-users"

export const TOOL_USERS_TABLE_DEFAULT_PARAMS: Partial<TableParamsState<BRMPaths<PersonListItem>>> = {
  sorting: [{ id: "display_name" satisfies BRMPaths<PersonListItem>, desc: false }],
}

/** This also determines the ordering of the tabs */
export const toolTabs = [
  "overview",
  "general",
  "compliance",
  "it",
  "finance",
  "users",
  "transactions",
  "security",
] as const
export type ToolTab = (typeof toolTabs)[number]

export const toolTabsSet = new Set<string>(toolTabs)

export function isToolTab(tab: string): tab is ToolTab {
  return toolTabsSet.has(tab)
}
