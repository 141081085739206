import mapObject, { mapObjectSkip } from "map-obj"
export const isTruthy = <T>(value: T): value is Exclude<T, null | undefined | false | "" | 0> => !!value

export const isObject = (value: unknown): value is object => typeof value === "object" && value !== null

export const hasOwnProperty = <T extends object, K extends string>(
  obj: T,
  property: K
): obj is T & Record<K, unknown> => Object.hasOwn(obj, property)

export const isEmpty = (value: unknown): boolean =>
  value === null ||
  value === undefined ||
  value === "" ||
  (isObject(value) && !Array.isArray(value) && Object.keys(value).length === 0) ||
  (Array.isArray(value) && value.length === 0)

export const omitEmpty = <T extends object>(obj: T): Partial<T> => {
  return mapObject(
    obj,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key, value) => (isEmpty(value) ? mapObjectSkip : ([key, value] as any)),
    { deep: true }
  ) as Partial<T>
}

export const isObjectWithId = (value: unknown): value is { id: string } =>
  isObject(value) && hasOwnProperty(value, "id") && typeof value.id === "string"

export function hasDisplayName<T>(value: T): value is T & { display_name: string } {
  return isObject(value) && "display_name" in value && typeof value.display_name === "string"
}
