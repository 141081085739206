import type {
  DocumentMinimal,
  DocumentOrURLString,
  FieldMetadataWithSuggestions,
  HttpUrlString,
} from "@brm/schema-types/types.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Box,
  Button,
  Grid,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { forwardRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import { Link } from "../Link.js"
import FieldSourceIcon from "../SchemaForm/FieldSourceIcon.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "../SchemaForm/types.js"
import { ChevronDownIcon, LinkExternalIcon } from "../icons/icons.js"
import { DocumentUpload, type DocumentChangeHandler, type DocumentUploadProps } from "./DocumentUpload.js"

interface UrlInputProps {
  value: HttpUrlString | null
  onChange: (value: ValueWithSource<HttpUrlString | null>) => void
  isDisabled?: boolean
  isInvalid?: boolean
  isReadOnly?: boolean
  suggestions?: ValueWithSource<HttpUrlString>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
  /** Set suggestion ref for new field tag on click. Usually this is the same as the input ref, but this component currently has a separate button for suggestions. */
  setSuggestionRef?: (e: HTMLInputElement) => void
}

export const UrlInput = forwardRef<HTMLInputElement, UrlInputProps>(function UrlInput(
  {
    value,
    onChange,
    isReadOnly,
    isDisabled,
    isInvalid,
    suggestions = [],
    fieldMetadata,
    fieldApproval,
    setSuggestionRef,
  }: UrlInputProps,
  ref
) {
  const intl = useIntl()

  return (
    <HStack>
      <InputGroup>
        <Input
          ref={ref}
          type="url"
          onChange={(event) => {
            event.preventDefault()
            onChange({ value: event.target.value })
          }}
          value={typeof value === "string" ? value : ""}
          placeholder={intl.formatMessage({
            id: "form.uri.placeholder",
            defaultMessage: "https://example.com",
            description: "Placeholder text for URI input field",
          })}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
        />
        {!isInvalid && typeof value === "string" && URL.canParse(value) && (
          <InputRightElement>
            <Tooltip
              label={intl.formatMessage({
                defaultMessage: "Open URL in new tab",
                description: "Tooltip label for URI input field button",
                id: "form.uri.open",
              })}
            >
              <Button as={Link} variant="link" to={value} target="_blank" rel="noopener noreferrer">
                <Icon as={LinkExternalIcon} />
              </Button>
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>

      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          icon={<Icon as={ChevronDownIcon} />}
          variant="outline"
          isDisabled={suggestions.length === 0}
          // Directly use set suggestion ref as input ref once this component is merged into one input
          ref={(e) => {
            setSuggestionRef?.(e as HTMLInputElement)
          }}
        />
        <MenuList width="sm">
          {suggestions.map(({ value, field_sources }) => {
            const { isNew, colorScheme } = isNewOption(field_sources, fieldMetadata, fieldApproval)
            return (
              <MenuItem
                key={value}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  onChange({ value, field_sources })
                }}
                display="flex"
                alignItems="space-between"
                gap={3}
                backgroundColor={isNew ? `${colorScheme}.50` : undefined}
              >
                <Text flexGrow={1} textOverflow="ellipsis" overflow="hidden">
                  {value}
                </Text>

                {field_sources?.map((fieldMetadata) => (
                  <FieldSourceIcon key={fieldMetadata.type} fieldMetadata={fieldMetadata} boxSize={4} />
                ))}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    </HStack>
  )
})

interface DocumentUploadOrUrlInputProps
  extends Pick<DocumentUploadProps, "isReadOnly" | "getLinkDestination" | "selectedDocument" | "onDocumentClick"> {
  value: DocumentOrURLString | null
  onChange: (value: ValueWithSource<DocumentOrURLString | null>) => void
  isDisabled?: boolean
  isInvalid?: boolean
  documentSuggestions?: ValueWithSource<DocumentMinimal>[]
  urlSuggestions?: ValueWithSource<HttpUrlString>[]
  onDocumentChange?: DocumentChangeHandler
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
  /** Set suggestion ref for new field tag on click. Usually this is the same as the input ref, but this component currently has a separate button for suggestions. */
  setSuggestionRef?: (e: HTMLInputElement) => void
}

export const DocumentUploadOrUrlInput = forwardRef<HTMLInputElement, DocumentUploadOrUrlInputProps>(
  function DocumentUploadOrUrlInput(
    {
      value,
      onChange,
      isReadOnly,
      getLinkDestination,
      isDisabled,
      isInvalid,
      urlSuggestions = [],
      onDocumentChange,
      selectedDocument,
      onDocumentClick,
      fieldApproval,
      fieldMetadata,
      setSuggestionRef,
    }: DocumentUploadOrUrlInputProps,
    ref
  ) {
    return (
      <Grid gap={2} templateColumns="1fr auto 1fr" alignItems="center">
        <UrlInput
          ref={ref}
          onChange={onChange}
          value={typeof value === "string" ? value : ""}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          suggestions={urlSuggestions}
          fieldMetadata={fieldMetadata}
          fieldApproval={fieldApproval}
          setSuggestionRef={setSuggestionRef}
        />

        <Text fontWeight="medium" color="gray.500" textTransform="uppercase">
          <FormattedMessage
            id="document.or.url.input.or"
            description="Text on a document upload OR url string input field"
            defaultMessage="or"
          />
        </Text>

        <Box>
          <DocumentUpload
            onDocumentClick={onDocumentClick}
            value={isObject(value) ? [value] : []}
            onChange={(documents, type, document) => {
              onChange({ value: documents[0] ?? null })
              onDocumentChange?.(documents, type, document)
            }}
            multiple={false}
            isReadOnly={isReadOnly}
            getLinkDestination={getLinkDestination}
            selectedDocument={selectedDocument}
          />
        </Box>
      </Grid>
    )
  }
)
