import type { UserWithProvider } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { Avatar, AvatarBadge, Flex, Icon, Tooltip } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { UserIcon } from "../../icons/icons.js"
import { providerIcons } from "../../icons/providers.js"
import { LinkOrSpan } from "../../Link.js"

export default function UserWithProviderBadge({
  user,
  textOnly,
  includeName,
}: {
  user: UserWithProvider
  textOnly?: boolean
  includeName?: boolean
}) {
  const intl = useIntl()
  const name = displayPersonName(user, intl)
  return (
    <Tooltip
      isDisabled={textOnly}
      shouldWrapChildren
      label={
        <FormattedMessage
          id="integration.tooltip.collected"
          description="The tooltip on an UserIcon that highlights a specific integration which has collected the data."
          defaultMessage="<strong>Collected from {name}</strong><br></br>{email}"
          values={{
            name,
            email: user.email,
          }}
        />
      }
    >
      <LinkOrSpan to={textOnly ? null : `/people/${user.person.id}`}>
        <Flex alignItems="center" gap={1}>
          <Avatar
            src={getPublicImageGcsUrl(user.profile_image?.gcs_file_name)}
            icon={<Icon as={UserIcon} />}
            name={name}
          >
            <AvatarBadge as={providerIcons[user.integration_provider]} borderRadius={0} boxSize="1.25em" />
          </Avatar>
          {includeName ? name : null}
        </Flex>
      </LinkOrSpan>
    </Tooltip>
  )
}
