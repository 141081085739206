import { isPlainDate } from "@brm/util/date-time.js"
import { Temporal } from "@js-temporal/polyfill"

export type ThresholdUnits = "second" | "minute" | "hour" | "day" | "week" | "month" | "year"

/**
 * Selects the best value and unit for relative time formatting.
 */
export function selectRelativeTimeUnit(
  dateTime: string,
  thresholds?: Partial<Record<ThresholdUnits, number>>
): { value: number; unit: Intl.RelativeTimeFormatUnitSingular } {
  if (isPlainDate(dateTime) || /^\d{4}-\d{2}-\d{2}$/u.test(dateTime)) {
    // For plain dates, we need to set a midnight time, because if only a YYYY-MM-DD string is provided, the legacy
    // Date() constructor will interpret the time as midnight *UTC*, which shifts the date off by one in timezones
    // west of UTC. This workaround can likely be removed once Temporal ships in browsers with updated Intl APIs
    // that directly accept Temporal.PlainDate.
    dateTime = Temporal.PlainDate.from(dateTime)
      // Set the time component to the current time of the day in the user's timezone.
      // We do this because the current time should not affect day calculations. Without this,
      // the unit calculation will round half days up or down.
      .toPlainDateTime(Temporal.Now.plainTimeISO())
      .toZonedDateTime(Temporal.Now.timeZoneId())
      .toString()
    if (!thresholds) {
      thresholds = { second: 0, minute: 0, hour: 0, day: 14 }
    }
  } else if (!thresholds) {
    thresholds = { second: 0 }
  }

  const date = Temporal.Instant.from(dateTime).epochSeconds
  const now = Temporal.Now.instant().epochSeconds
  const diffInSeconds = now - date

  const intervals: { unit: ThresholdUnits; seconds: number }[] = [
    { unit: "year", seconds: 1 * 60 * 60 * 24 * 365 },
    { unit: "month", seconds: 1 * 60 * 60 * 24 * 30 },
    { unit: "week", seconds: 1 * 60 * 60 * 24 * 7 },
    { unit: "day", seconds: 1 * 60 * 60 * 24 },
    { unit: "hour", seconds: 1 * 60 * 60 },
    { unit: "minute", seconds: 1 * 60 },
    { unit: "second", seconds: 1 },
  ]
  // Find the largest unit that is less than the difference in seconds.
  let intervalIndex = 0
  while (intervalIndex < intervals.length) {
    const interval = intervals[intervalIndex]
    // This will never be true, it's just to make TypeScript happy.
    if (!interval) {
      intervalIndex++
      continue
    }
    const count = Math.floor(diffInSeconds / interval.seconds)
    if (count >= 1) {
      break
    }

    // Break to prevent index from going out of bounds
    if (intervalIndex === intervals.length - 1) {
      break
    }
    intervalIndex++
  }

  let roundUp = 0

  // Find the smallest unit that is greater than the found unit and does not exceed the threshold.
  while (intervalIndex > 0) {
    const currentInterval = intervals[intervalIndex]
    if (!currentInterval) {
      throw new Error("Interval out of bounds")
    }
    const count = Math.floor(diffInSeconds / currentInterval.seconds)

    const currentThreshold = thresholds?.[currentInterval.unit]

    // If the threshold is 0 we want to take the next largest unit
    if (thresholds[currentInterval.unit] === 0) {
      intervalIndex--
      continue
    }
    // If the count is greater than the threshold, we want to move to the next largest unit and round up.
    if (currentThreshold && count > currentThreshold) {
      intervalIndex--
      roundUp = 1
      continue
    }
    break
  }

  const interval = intervals[intervalIndex]
  if (!interval) {
    throw new Error("Unit should be set")
  }

  const count = Math.floor(diffInSeconds / interval.seconds) + roundUp

  return { value: -count, unit: interval.unit }
}

/**
 * Formats a datetime to a relative time string.
 */
export function formatRelativeTime({
  dateTime,
  style,
  numeric,
  locale,
}: {
  dateTime: string
  style: Intl.RelativeTimeFormatStyle
  numeric?: Intl.RelativeTimeFormatNumeric
  locale?: string
  thresholds: Partial<Record<"second" | "minute" | "hour" | "day" | "month" | "year", number>>
}): string {
  const formatter = new Intl.RelativeTimeFormat(locale || "en", { style, numeric: numeric || "auto" })
  const { unit, value } = selectRelativeTimeUnit(dateTime)
  return formatter.format(value, unit)
}
