import type {
  DateString,
  FieldMetadata,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
} from "@brm/schema-types/types.js"
import {
  isValidPlainDate,
  legacyDateToPlainDate,
  localeFirstDayOfWeek,
  plainDateToLegacyDate,
} from "@brm/util/date-time.js"
import {
  Button,
  Circle,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  useStyleConfig,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { DateField, DateInput, DateSegment } from "react-aria-components"
// chakra-dayzed-datepicker requires date-fns library: https://github.com/aboveyunhai/chakra-dayzed-datepicker?tab=readme-ov-file#install-the-dependency
import { parseDate } from "@internationalized/date"
import { CalendarPanel, Month_Names_Full, Weekday_Names_Short } from "chakra-dayzed-datepicker"
import type { InputProps } from "chakra-react-select"
import type { Ref } from "react"
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import { FormattedDate } from "../FormattedDate.js"
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftDoubleIcon,
  ChevronLeftIcon,
  ChevronRightDoubleIcon,
  ChevronRightIcon,
} from "../icons/icons.js"
import OptionWithFieldSource from "./OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "./types.js"

interface DatePickerInputProps {
  value: DateString | null
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  isInvalid?: boolean
  suggestions?: ValueWithSource<Temporal.PlainDate>[]
  isDisabled?: boolean
  /** Width when the calendar picker is open. If the user is looking at the suggestions dropdown the width will always be "full" */
  calendarWidth?: InputProps["width"]
  ariaLabel?: InputProps["aria-label"]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
}

// Just a placeholder option to open up the calendar
interface CustomDateOption {
  isCustomOption: boolean
}

const isCustomOption = (
  option: ValueWithSource<Temporal.PlainDate | undefined> | CustomDateOption
): option is CustomDateOption => "isCustomOption" in option

const Calendar = ({
  onChange,
  value,
}: {
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  // chakra-dayzed-datepicker library requires a Date object
  // eslint-disable-next-line no-restricted-globals
  value: Date | undefined
}) => {
  // eslint-disable-next-line no-restricted-globals
  const today = useMemo(() => new Date(), [])
  const intl = useIntl()

  /** The first day of the week with 1 = Monday, 7 = Sunday. Default to Sunday if we don't have locale info. */
  const firstDayOfWeek = useMemo(() => localeFirstDayOfWeek(intl.locale) ?? 7, [intl.locale])

  return (
    <CalendarPanel
      dayzedHookProps={{
        showOutsideDays: true,
        onDateSelected: ({ date }) => {
          onChange(legacyDateToPlainDate(date).toString())
        },
        selected: value,
        date: value,
      }}
      configs={{
        dateFormat: "YYYY-MM-DD",
        monthNames: Month_Names_Full,
        dayNames: Weekday_Names_Short,
        // chakra-dayzed-datepicker uses 0..6 with 0 = Sunday, 6 = Saturday
        // Intl.Locale's and Temporal uses 1..7 with 1 = Monday, 7 = Sunday
        firstDayOfWeek: (firstDayOfWeek % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      }}
      propsConfigs={{
        dateHeadingProps: {
          fontSize: "md",
          // Fix width so arrows don't move around when switching months
          width: "130px",
        },
        calendarPanelProps: {
          contentProps: {
            border: 0,
            paddingX: 6,
            paddingY: 0,
          },
          dividerProps: {
            display: "none",
          },
          headerProps: {
            justifyContent: "space-between",
            width: "full",
          },
          wrapperProps: {
            width: "auto",
            justifyContent: "center",
          },
          bodyProps: {
            // Fix height so calendar doesn't jump around when switching months
            height: "300px",
          },
        },
        dateNavBtnProps: {
          variant: "ghost",
          color: "gray.500",
          size: "md",
          sx: {
            // Hide the default "<<" "<" ">" ">>" text content
            fontSize: 0,
            ".date-nav-icon": {
              fontSize: "initial",
            },
            // The props are shared between all four nav buttons, so we use CSS to show the correct icon for each.
            "&:nth-of-type(1) .date-nav-icon:not(.left-double)": {
              display: "none",
            },
            "&:nth-of-type(2) .date-nav-icon:not(.left)": {
              display: "none",
            },
            "&:nth-of-type(3) .date-nav-icon:not(.right)": {
              display: "none",
            },
            "&:nth-of-type(4) .date-nav-icon:not(.right-double)": {
              display: "none",
            },
          },
          leftIcon: (
            <>
              <Icon as={ChevronLeftDoubleIcon} className="date-nav-icon left-double" />
              <Icon as={ChevronLeftIcon} className="date-nav-icon left" />
              <Icon as={ChevronRightIcon} className="date-nav-icon right" />
              <Icon as={ChevronRightDoubleIcon} className="date-nav-icon right-double" />
            </>
          ),
          iconSpacing: 0,
        },
        weekdayLabelProps: {
          height: "40px",
          width: "40px",
          alignContent: "center",
          fontWeight: "medium",
        },
        dayOfMonthBtnProps: {
          defaultBtnProps: {
            borderRadius: "full",
            fontWeight: "normal",
            alignItems: "center",
            _hover: {
              backgroundColor: "gray.50",
            },
            height: "40px",
            width: "40px",
          },
          selectedBtnProps: {
            backgroundColor: "brand.700",
            color: "white",
            _hover: {
              backgroundColor: "brand.700",
            },
          },
          todayBtnProps: {
            flexDirection: "column",
            rightIcon: (
              <Circle
                bgColor={today.getDate() === value?.getDate() ? "white" : "brand.700"}
                style={{
                  width: "5px",
                  height: "5px",
                  position: "absolute",
                  left: "Calc(50% - 2.5px)",
                  bottom: "3px",
                }}
              />
            ),
          },
        },
      }}
    />
  )
}

const Option = ({
  option,
  onClick,
  isSelected,
  fieldMetadata,
  fieldApproval,
}: {
  option: ValueWithSource<Temporal.PlainDate | undefined> | CustomDateOption | null
  onClick?: () => void
  isSelected: boolean
  fieldMetadata?: FieldMetadata
  fieldApproval?: SchemaFormFieldApproval
}) => {
  let contents
  if (option === null) {
    contents = null
  } else if (isCustomOption(option)) {
    contents = (
      <Text flex={1}>
        <FormattedMessage
          defaultMessage="Custom Date"
          description="The label for a custom date option in the date picker"
          id="datePicker.option.customDate"
        />
      </Text>
    )
  } else {
    const fieldSources = option?.field_sources as FieldSourceOutputProperties[] | undefined
    if (!option.value) {
      return null
    }
    contents = (
      <>
        <OptionWithFieldSource fieldSources={fieldSources}>
          <FormattedDate value={option.value.toString()} month="numeric" />
        </OptionWithFieldSource>
        {isSelected && <Icon as={CheckIcon} boxSize={3} />}
      </>
    )
  }
  const newOption =
    option !== null && !isCustomOption(option)
      ? isNewOption(option.field_sources, fieldMetadata, fieldApproval)
      : undefined
  return (
    <Button
      backgroundColor="white"
      _hover={{
        backgroundColor: "gray.50",
      }}
      _active={{
        backgroundColor: "gray.200",
      }}
      _focusVisible={{
        boxShadow: "none",
        backgroundColor: "gray.100",
      }}
      textAlign="left"
      fontWeight="regular"
      display="flex"
      onClick={onClick}
      role="option"
      alignItems="center"
      borderRadius={0}
      {...(newOption?.isNew && { backgroundColor: `${newOption.colorScheme}.50` })}
    >
      {contents}
    </Button>
  )
}

export const DatePickerInput = forwardRef(function DatePickerInput(
  props: DatePickerInputProps,
  ref: Ref<HTMLInputElement | null>
) {
  const {
    onChange,
    value,
    isReadOnly,
    isInvalid,
    isDisabled,
    suggestions,
    calendarWidth,
    ariaLabel,
    fieldMetadata,
    fieldApproval,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: calendarIsOpen, onOpen: onCalendarOpen, onClose: onCalendarClose } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => inputRef.current)
  const options = useMemo(
    () => [null, ...(suggestions || []), { isCustomOption: true } satisfies CustomDateOption],
    [suggestions]
  )
  const date = value ? Temporal.PlainDate.from(value) : undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputStyleConfig = useStyleConfig("Input") as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuStyleConfig = useStyleConfig("Menu") as any
  const showCalendar = calendarIsOpen || !suggestions || suggestions.length === 0

  const closeAll = useCallback(() => {
    onClose()
    onCalendarClose()
    inputRef.current?.blur()
  }, [onClose, onCalendarClose, inputRef])

  return (
    <Popover
      onOpen={onOpen}
      onClose={closeAll}
      isOpen={!isReadOnly && isOpen}
      closeOnBlur={true}
      matchWidth={true}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Button
          isDisabled={isDisabled}
          onClick={onOpen}
          isActive={!isReadOnly}
          sx={{
            ...inputStyleConfig.field,
            justifyContent: "start",
            cursor: "auto",
            fontWeight: "regular",
            background: 0,
            _active: { background: 0 },
            _hover: { borderColor: 0 },
            ...(isOpen && inputStyleConfig.field._focus),
            ...(isReadOnly && inputStyleConfig.field._readOnly),
            ...(isInvalid && inputStyleConfig.field._invalid),
          }}
          onBlur={(e) => {
            if (!optionsRef.current?.contains(e.relatedTarget as Node)) {
              closeAll()
            }
          }}
        >
          <DateField
            isReadOnly={isReadOnly}
            value={value ? parseDate(value?.toString()) : null}
            aria-label={ariaLabel}
            shouldForceLeadingZeros={true}
            onChange={(v) => {
              const newDate = v?.toString()
              if (newDate && isValidPlainDate(newDate)) {
                onChange?.(newDate)
              }
            }}
            ref={inputRef}
            onFocus={onOpen}
            onBlur={(e) => {
              if (!optionsRef.current?.contains(e.relatedTarget as Node)) {
                closeAll()
              }
            }}
          >
            <DateInput style={{ display: "flex" }}>{(segment) => <DateSegment segment={segment} />}</DateInput>
          </DateField>
          <Icon
            as={ChevronDownIcon}
            height="20px"
            width="20px"
            marginRight={2}
            marginLeft={1}
            position="absolute"
            right={0}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width={showCalendar ? calendarWidth : "full"}
        sx={{ ...menuStyleConfig.list, borderRadius: "lg" }}
        ref={optionsRef}
      >
        <PopoverBody padding={0}>
          {showCalendar ? (
            <Flex direction="column" py={5}>
              <Calendar
                value={date && plainDateToLegacyDate(date)}
                onChange={(v) => {
                  onChange(v)
                  onClose()
                  onCalendarClose()
                }}
              />
              <HStack justifyContent="center" gap={16}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    onClose()
                    onCalendarClose()
                    onChange(null)
                  }}
                  color="gray.500"
                >
                  <FormattedMessage
                    id="calendar.clear"
                    defaultMessage="Clear"
                    description="Clear button on calendar input"
                  />
                </Button>
                <Button variant="ghost" onClick={() => onChange(Temporal.Now.plainDateISO().toString())}>
                  <FormattedMessage
                    id="calendar.today"
                    defaultMessage="Today"
                    description="Button on calendar input to set the value to today"
                  />
                </Button>
              </HStack>
            </Flex>
          ) : (
            <Stack gap={0}>
              {options.map((option, i) => (
                <Option
                  fieldApproval={fieldApproval}
                  fieldMetadata={fieldMetadata}
                  option={option}
                  key={i}
                  isSelected={
                    option === null
                      ? value === null
                      : !isCustomOption(option) && option?.value && value === option.value.toString()
                  }
                  onClick={() => {
                    if (option === null) {
                      onChange(null)
                      onClose()
                      onCalendarClose()
                    } else if (!isCustomOption(option)) {
                      onChange(option.value.toString(), option.field_sources?.[0])
                      onClose()
                      onCalendarClose()
                    } else {
                      onCalendarOpen()
                    }
                  }}
                />
              ))}
            </Stack>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
})
