import { isObject } from "@brm/util/type-guard.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  chakra,
  useToast,
} from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostUserV1SigninOktaInitiateMutation } from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"
import { BackIcon } from "../../components/icons/icons.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { LoginBaseContainer } from "./LoginBaseContainer.js"

/**
 * When successfully logged in Pendo is initialized in userSlice.ts in response to the whoami API call.
 * Initialize it explicitly here because users aren't logged in during login
 */
pendo.initialize()

interface LoginFormState {
  email: string
}

export default function LoginWithSso() {
  const intl = useIntl()
  const toast = useToast()
  const [initiateOktaSso, initiateOktaSsoResult] = usePostUserV1SigninOktaInitiateMutation()

  const form = useForm<LoginFormState>({
    defaultValues: {
      email: "",
    },
    reValidateMode: "onSubmit",
  })

  const errorMessage =
    initiateOktaSsoResult.isError &&
    (getAPIErrorMessage(initiateOktaSsoResult.error) ??
      intl.formatMessage({
        id: "login.wrong-user-or-password",
        description: "Failed login unknown",
        defaultMessage: "Login failed",
      }))

  return (
    <LoginBaseContainer
      heading={intl.formatMessage({
        id: "login.heading",
        description: "Heading on login page",
        defaultMessage: "Log in with SSO",
      })}
    >
      <Stack
        spacing="5"
        as={chakra.form}
        onSubmit={form.handleSubmit(async (formValues) => {
          try {
            const oktaSsoResult = await initiateOktaSso({ body: { email: formValues.email } }).unwrap()
            const redirectUrl = new URL(`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/signin/okta`)
            redirectUrl.searchParams.append("iss", oktaSsoResult.oktaDomain)
            window.location.href = redirectUrl.toString()
          } catch (err) {
            if (isObject(err) && "status" in err && err.status !== 401) {
              toast({
                status: "error",
                description: intl.formatMessage({
                  id: "login.error.toast",
                  description: "Login error toast message",
                  defaultMessage: "Something went wrong while logging in. Please try again or contact support.",
                }),
              })
            }
          }
        })}
      >
        <Controller
          control={form.control}
          name="email"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor="email">
                <FormattedMessage
                  id="login.form.email"
                  description="Label for login form email field"
                  defaultMessage="Email"
                />
              </FormLabel>
              <Input {...field} id="email" type="email" />
            </FormControl>
          )}
        />
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Button isLoading={initiateOktaSsoResult.isLoading} id="sso-login" colorScheme="brand" type="submit">
          <FormattedMessage
            id="login.form.signin"
            description="Login form sign in button text"
            defaultMessage="Log in"
          />
        </Button>
      </Stack>
      <Link to="/login">
        <HStack gap={1}>
          <Icon as={BackIcon} />
          <FormattedMessage id="login.form.back" description="Navigate to login page" defaultMessage="Back to Login" />
        </HStack>
      </Link>
    </LoginBaseContainer>
  )
}
