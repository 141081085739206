import { displayNameByType } from "@brm/schema-helpers/integrations.js"
import type { IntegrationProvider } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { useState, type ReactNode } from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import type { GetIntegrationV1ApiResponse } from "../../app/services/generated-api.js"
import {
  usePostIntegrationV1CreateMutation,
  usePostIntegrationV1UpdateMutation,
} from "../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { prependHttps } from "../../util/url.js"
import { isDirectCreateIntegrationProviderType } from "./api-provider-type.js"

interface Props {
  integrationId?: string
  integrationProvider: IntegrationProvider
  isOpen: boolean
  onClose: () => void
  integrations?: GetIntegrationV1ApiResponse
}

export default function CreateIntegrationModal({
  integrationId,
  integrationProvider,
  isOpen,
  onClose,
  integrations,
}: Props) {
  const toast = useToast()
  const intl = useIntl()

  const [apiKey, setApiKey] = useState<string | undefined>()
  const [clientId, setClientId] = useState<string | undefined>()
  const [resourceDomain, setResourceDomain] = useState<string | undefined>()
  const [clientSecret, setClientSecret] = useState<string | undefined>()
  const [privateKey, setPrivateKey] = useState<string | undefined>()

  const [createIntegration, createIntegrationResult] = usePostIntegrationV1CreateMutation()
  const [updateIntegration, updateIntegrationResult] = usePostIntegrationV1UpdateMutation()

  const providerDisplayName = displayNameByType[integrationProvider]

  const handleCreateIntegration = async () => {
    if (!integrationProvider || !isDirectCreateIntegrationProviderType(integrationProvider)) {
      return
    }

    const onSuccess = () => {
      onClose()
      toast({
        description: intl.formatMessage({
          id: "integrations.createApi.toastMsg.success",
          description: "Toast success message when creating an integration",
          defaultMessage: "Integration added!",
        }),
        status: "success",
      })
    }

    const onError = (e: unknown) => {
      const errMessage =
        getAPIErrorMessage(e) ??
        intl.formatMessage({
          id: "integrations.createApi.toastMsg.error",
          description: "Toast error message when creating an integration fails",
          defaultMessage: "Could not create integration",
        })

      toast({
        description: errMessage,
        status: "error",
      })
    }

    switch (integrationProvider) {
      case "ramp_client_creds":
        isNotUndefined(clientId, "clientId is required")
        isNotUndefined(clientSecret, "clientSecret is required")
        try {
          await createIntegration({
            createIntegration: {
              auth_type: "oauth_client_credentials",
              display_name: providerDisplayName,
              provider: integrationProvider,
              client_id: clientId,
              client_secret: clientSecret,
            },
          }).unwrap()
          onSuccess()
        } catch (err) {
          onError(err)
        }
        break
      case "brex_apikey":
      case "mercury_apikey":
      case "okta_apikey":
        isNotUndefined(apiKey, "apiKey is required")
        try {
          await createIntegration({
            createIntegration: {
              auth_type: "apikey",
              display_name: providerDisplayName,
              provider: integrationProvider,
              api_key: apiKey,
              resource_domain: resourceDomain ? prependHttps(resourceDomain) : resourceDomain,
            },
          }).unwrap()
          onSuccess()
        } catch (err) {
          onError(err)
        }
        break
      case "okta_oauth":
        isNotUndefined(clientId, "clientId is required")
        isNotUndefined(resourceDomain, "resourceDomain is required")
        isNotUndefined(privateKey, "privateKey is required")
        try {
          await createIntegration({
            createIntegration: {
              auth_type: "domain_private_key",
              display_name: providerDisplayName,
              provider: integrationProvider,
              resource_domain: prependHttps(resourceDomain),
              client_id: clientId,
              private_key: privateKey,
            },
          }).unwrap()
          onSuccess()
        } catch (err) {
          onError(err)
        }
        break
      default:
        unreachable(integrationProvider)
    }
  }

  const handleUpdateIntegration = async () => {
    if (!integrationId || !isDirectCreateIntegrationProviderType(integrationProvider)) {
      return
    }

    try {
      await updateIntegration({
        updateIntegration: {
          integration_id: integrationId,
          api_key: apiKey,
          client_id: clientId,
          resource_domain: resourceDomain,
          client_secret: clientSecret,
        },
      }).unwrap()
      onClose()
      toast({
        description: intl.formatMessage({
          id: "integrations.updateApi.toastMsg.success",
          description: "Toast success message when updating an integration",
          defaultMessage: "Integration updated!",
        }),
        status: "success",
      })
    } catch (err) {
      // Eventually have backend pass back different intl ids
      const errMessage =
        getAPIErrorMessage(err) ??
        intl.formatMessage({
          id: "integrations.updateApi.toastMsg.error",
          description: "Toast error message when updating an integration fails",
          defaultMessage: "Could not update integration",
        })
      toast({
        description: errMessage,
        status: "error",
      })
    }
  }

  const renderModalBody = (): ReactNode => {
    switch (integrationProvider) {
      case "okta_apikey":
        return apiKeyDomainModalBody(providerDisplayName, setApiKey, setResourceDomain)
      case "okta_oauth":
        return domainPrivateKeyModalBody(providerDisplayName, setClientId, setResourceDomain, setPrivateKey)
      case "ramp_client_creds":
        return clientCredsModalBody(providerDisplayName, setClientId, setClientSecret)
      case "brex_oauth":
      case "google_oauth":
      case "gmail_oauth":
      case "quickbooks_oauth":
      case "ramp_oauth":
      case "slack_oauth":
        return oauthModalBody(intl, providerDisplayName)
      case "brex_apikey":
      case "mercury_apikey":
        return apiKeyModalBody(providerDisplayName, setApiKey)
      case "merge_hris_link_token":
      case "merge_accounting_link_token":
        // TODO: reconnect merge link token flow
        return null
      case "plaid_access_token":
        return null
      default:
        unreachable(integrationProvider)
    }
  }

  let modalBody = renderModalBody()
  if (
    !integrationId &&
    integrations &&
    // Will need to fix this if we accept multiple ways of connecting an integration
    integrations.filter((i) => i.provider === integrationProvider).length > 0
  ) {
    modalBody = (
      <Stack spacing={6}>
        <Text>
          <FormattedMessage
            id="integrations.page.modal.header"
            description="Warning message on the connect integration modal of a potential duplicate integration"
            defaultMessage="You have an existing {name} connection. Continue only if you are connecting a different {name} account."
            values={{ name: providerDisplayName }}
          />
        </Text>
        {modalBody}
      </Stack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form
          style={{ display: "contents" }}
          // If an API provider, make an API request on submit
          onSubmit={
            isDirectCreateIntegrationProviderType(integrationProvider)
              ? async (event) => {
                  event.preventDefault()
                  if (integrationId) {
                    await handleUpdateIntegration()
                  } else {
                    await handleCreateIntegration()
                  }
                }
              : undefined
          }
          // If not an API provider, redirect to OAuth URL on submit
          action={`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/connect/${integrationProvider}`}
        >
          {/* If not an API provider, include ?integrationId= search parameter */}
          {integrationId && <Input type="hidden" name="integrationId" value={integrationId} />}
          <ModalHeader>
            <FormattedMessage
              id="integrations.page.modal.header"
              description="Heading of the connect integration modal"
              defaultMessage="Connect {name}"
              values={{ name: providerDisplayName }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBody}</ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              colorScheme="brand"
              width="100%"
              isLoading={createIntegrationResult.isLoading || updateIntegrationResult.isLoading}
            >
              {integrationId ? (
                <FormattedMessage
                  id="integrations.page.modal.footer.update"
                  description="Heading of integration page"
                  defaultMessage="Update Integration"
                />
              ) : (
                <FormattedMessage
                  id="integrations.page.modal.footer.create"
                  description="Heading of integration page"
                  defaultMessage="Create Integration"
                />
              )}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
function apiKeyModalBody(
  providerDisplayName: string | undefined,
  setApiKey: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  return (
    <FormControl isRequired>
      <FormLabel htmlFor="api-key">
        <FormattedMessage
          id="integrations.page.modal.api_key"
          description="Integration modal form label for api key input"
          defaultMessage="{name} API key"
          values={{ name: providerDisplayName }}
        />
      </FormLabel>
      <Input id="api-key" onChange={(e) => setApiKey(e.target.value)} type="password" />
    </FormControl>
  )
}

function apiKeyDomainModalBody(
  providerDisplayName: string | undefined,
  setApiKey: React.Dispatch<React.SetStateAction<string | undefined>>,
  setResourceDomain: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  return (
    <Flex direction="column" gap={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="resource-domain">
          <FormattedMessage
            id="integrations.page.modal.resource_domain"
            description="Integration modal form label for resource domain input"
            defaultMessage="{name} Resource Domain"
            values={{ name: providerDisplayName }}
          />
        </FormLabel>
        <Input
          id="resource-domain"
          onChange={(e) => setResourceDomain(e.target.value)}
          spellCheck={false}
          placeholder="e.g. https://example.okta.com"
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="api-key">
          <FormattedMessage
            id="integrations.page.modal.api_key"
            description="Integration modal form label for api key input"
            defaultMessage="{name} API Key"
            values={{ name: providerDisplayName }}
          />
        </FormLabel>
        <Input id="api-key" onChange={(e) => setApiKey(e.target.value)} type="password" />
      </FormControl>
    </Flex>
  )
}

function domainPrivateKeyModalBody(
  providerDisplayName: string | undefined,
  setClientId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setResourceDomain: React.Dispatch<React.SetStateAction<string | undefined>>,
  setPrivateKey: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  return (
    <Flex direction="column" gap={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="client-id">
          <FormattedMessage
            id="integrations.page.modal.client_id"
            description="Integration modal form label for client ID input"
            defaultMessage="{name} Client ID"
            values={{ name: providerDisplayName }}
          />
        </FormLabel>
        <Input id="client-id" onChange={(e) => setClientId(e.target.value)} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="resource-domain">
          <FormattedMessage
            id="integrations.page.modal.resource_domain"
            description="Integration modal form label for resource domain input"
            defaultMessage="{name} Resource Domain"
            values={{ name: providerDisplayName }}
          />
        </FormLabel>
        <Input
          id="resource-domain"
          onChange={(e) => setResourceDomain(e.target.value)}
          type="url"
          spellCheck={false}
          placeholder="e.g. https://example.okta.com"
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="private-key">
          <FormattedMessage
            id="integrations.page.modal.private_key"
            description="Integration modal form label for private key text area"
            defaultMessage="{name} Private Key"
            values={{ name: providerDisplayName }}
          />
        </FormLabel>
        <Textarea id="private-key" onChange={(e) => setPrivateKey(e.target.value)} spellCheck={false} />
      </FormControl>
    </Flex>
  )
}

function clientCredsModalBody(
  providerDisplayName: string,
  setClientId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setClientSecret: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  return (
    <Flex direction="column" gap={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="client-id">
          <FormattedMessage
            id="integrations.page.modal.client_id"
            description="Integration modal form label for client id input"
            defaultMessage="{name} Client ID"
            values={{ name: providerDisplayName }}
          />
        </FormLabel>
        <Input id="client-id" onChange={(e) => setClientId(e.target.value)} fontFamily="monospace" spellCheck={false} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="client-secret">
          <FormattedMessage
            id="integrations.page.modal.client_secret"
            description="Integration modal form label for client secret input"
            defaultMessage="Client Secret"
          />
        </FormLabel>
        <Textarea
          id="client-secret"
          onChange={(e) => setClientSecret(e.target.value)}
          fontFamily="monospace"
          spellCheck={false}
        />
      </FormControl>
    </Flex>
  )
}

function oauthModalBody(intl: IntlShape, providerDisplayName: string) {
  const instructions = [
    intl.formatMessage(
      {
        id: "modal.oauth.connect.instruction.1",
        description: "First instruction on how the OAuth flow to add an integration works",
        defaultMessage: "After you select create you will be transferred to {name}",
      },
      { name: providerDisplayName }
    ),
    intl.formatMessage(
      {
        id: "modal.oauth.connect.instruction.2",
        description: "Second instruction on how the OAuth flow to add an integration works",
        defaultMessage: "Authenticate with {name}",
      },
      { name: providerDisplayName }
    ),
    intl.formatMessage({
      id: "modal.oauth.connect.instruction.3",
      description: "Third instruction on how the OAuth flow to add an integration works",
      defaultMessage: "You will be directed back to BRM",
    }),
  ]
  return (
    <Stack spacing={4}>
      {instructions.map((instruction, index) => {
        const number = `${index + 1}.`
        return (
          <HStack key={index} alignItems="start">
            <Text fontWeight="bold">{number}</Text>
            <Text>{instruction}</Text>
          </HStack>
        )
      })}
    </Stack>
  )
}
