import type { CurrencyAmount, FieldMetadata } from "@brm/schema-types/types.js"
import { isTruthy } from "@brm/util/type-guard.js"
import { Flex } from "@chakra-ui/react"
import { EMPTY_VALUE_SYMBOL } from "../../../util/constant.js"
import { ExtractionHighlight } from "../../ExtractionHighlight.js"
import { FormattedCurrency } from "../../FormattedCurrency.js"
import PercentArrowIcon from "../../icons/PercentArrowIcon.js"

export default function MoneyCell({
  currencyAmount,
  percentGrowth,
  source,
}: {
  currencyAmount: CurrencyAmount | null | undefined
  percentGrowth?: number | null
  source?: FieldMetadata
}) {
  return (
    <Flex flexDir="column" display="inline-flex">
      <ExtractionHighlight source={source}>
        {currencyAmount ? <FormattedCurrency currencyAmount={currencyAmount} /> : EMPTY_VALUE_SYMBOL}
      </ExtractionHighlight>
      {currencyAmount && isTruthy(percentGrowth) && <PercentArrowIcon percent={percentGrowth} />}
    </Flex>
  )
}
