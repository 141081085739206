import type { FieldMetadata } from "@brm/schema-types/types.js"
import type { IconProps } from "@chakra-ui/react"
import { Icon, Tooltip, chakra } from "@chakra-ui/react"
import { type FunctionComponent, type ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { ThreeStarsIcon } from "./icons/icons.js"
export const ExtractionHighlight: FunctionComponent<{
  source: FieldMetadata | undefined
  isDirty?: boolean
  children: ReactNode
}> = ({ source, isDirty, children }) => {
  return (
    <chakra.span position="relative">
      {children}
      {(source?.type === "document" || source?.type === "transaction" || source?.type === "derived") && !isDirty && (
        <Tooltip
          openDelay={500}
          label={
            <FormattedMessage
              defaultMessage="Automagically extracted"
              description="The tooltip text for the extraction highlight icon"
              id="extraction.tooltip"
            />
          }
        >
          <chakra.sup position="absolute" top="-2px" right="-14px">
            <ExtractionIcon />
          </chakra.sup>
        </Tooltip>
      )}
    </chakra.span>
  )
}

export const ExtractionIcon: FunctionComponent<IconProps> = (props) => {
  return <Icon as={ThreeStarsIcon} boxSize={3} ml="3px" color="purple.700" {...props} />
}
