import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit"
import { isRejectedWithValue } from "@reduxjs/toolkit"
import { SAVED_PATH } from "../util/constant.js"

export const queryErrorHandler: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // redirect to login on 401 unauthorized errors
    if (
      typeof action.payload === "object" &&
      action.payload !== null &&
      "status" in action.payload &&
      action.payload.status === 401
    ) {
      // do not redirect from any login pages
      if (window.location.pathname.startsWith("/login")) {
        return next(action)
      }

      // whoami requests happen opportunistically to see if a user is logged in on pages that do
      // not require authentication, therefore we don't want to to login if the user is not logged
      // in on these request
      // TODO: find a better way to distinguish between logged in and public routes in the frontend
      const arg = action.meta?.arg
      if (typeof arg === "object" && arg !== null && "endpointName" in arg && arg?.endpointName === "getUserV1Whoami") {
        return next(action)
      }

      window.sessionStorage.setItem(SAVED_PATH, window.location.href)
      window.location.href = "/login"
    }

    // sentryReduxEnhancer (ui/src/app/store.ts) will send failed redux actions to Sentry
    // eslint-disable-next-line no-console
    console.error("Rejected Action", action)
  }

  return next(action)
}
