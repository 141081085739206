/**
 * Uploads the given selected user file to the given signed document upload URL.
 * Resolves when the upload is complete.
 */
export async function uploadFile(document: { upload_url: string }, file: File): Promise<void> {
  const uploadResponse = await fetch(document.upload_url, { method: "PUT", body: file })
  if (!uploadResponse.ok) {
    throw new Error(`Failed to upload file: ${uploadResponse.status} ${uploadResponse.statusText}`)
  }
}
