import type { FlexProps, TableCellProps } from "@chakra-ui/react"
import { Box, Flex, Icon, Table, Tbody, Td, Th, Thead, Tooltip, Tr, chakra } from "@chakra-ui/react"
import type {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ColumnPinningState,
  ExpandedState,
  OnChangeFn,
  RowData,
  SortingState,
  TableOptions,
  VisibilityState,
} from "@tanstack/react-table"
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table"
import type { EventHandler, KeyboardEventHandler, ReactNode, SyntheticEvent } from "react"
import { useIntl } from "react-intl"
import { Key } from "ts-key-enum"
import { EMPTY_VALUE_SYMBOL } from "../../util/constant.js"
import { ArrowDownIcon, ArrowUpIcon, EyeOffIcon } from "../icons/icons.js"
import type { PaginationProps } from "./Pagination.js"
import Pagination from "./Pagination.js"

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    /** Instead rendering the table placeholder, allow the cell content to handle the nullish values. */
    alwaysRenderCell?: boolean
    wrapOverflow?: boolean
    hideHeader?: boolean
    /** @default "left"  */
    textAlign?: "left" | "center" | "right"
  }
}

/**
 * Helper used to determine if we should show a placeholder for a cell or not.
 *
 * Values like 0 and false are not considered nullish values.
 * undefined is also handled separately because it will show a different placeholder icon.
 */
function isNullishValue(val: unknown): boolean {
  return (
    val === null ||
    val === "" ||
    (Array.isArray(val) && val.length === 0) ||
    (typeof val === "object" && Object.keys(val).length === 0)
  )
}

const stopPropagation: EventHandler<SyntheticEvent> = (event) => event.stopPropagation()

export interface DataTableProps<DataRow>
  extends Pick<TableOptions<DataRow>, "onExpandedChange" | "getSubRows" | "getRowCanExpand">,
    FlexProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnDef<DataRow, any>>
  data: Array<DataRow>
  expanded?: ExpandedState
  columnPinning?: ColumnPinningState
  columnOrder?: ColumnOrderState
  columnVisibility?: VisibilityState
  sorting?: SortingState
  onSortingChange?: OnChangeFn<SortingState>
  columnFilters?: ColumnFiltersState
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>
  /**
   * Render the given placeholder if cell value is an empty array,
   * object without properties or empty string. Allows consumer to
   * skip checks in renderers if all columns use the same placeholder
   * logic.
   */
  placeHolder?: string | ReactNode
  /** By default onRowClick expands the row if there are child/leaf row elements */
  onRowClick?: (row: DataRow) => void
  paginationProps?: PaginationProps
  /** If this is passed in the first column and column header will be sticky */
  isSticky?: boolean
}

export default function DataTable<DataRow>({
  columns,
  data,
  columnPinning,
  columnOrder,
  columnVisibility,
  sorting,
  onSortingChange,
  columnFilters,
  onColumnFiltersChange,
  placeHolder = EMPTY_VALUE_SYMBOL,
  paginationProps,
  // Expansion
  expanded,
  onExpandedChange,
  onRowClick,
  getRowCanExpand,
  getSubRows,
  isSticky,
  ...restProps
}: DataTableProps<DataRow>) {
  const intl = useIntl()
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange,
    enablePinning: true,

    manualFiltering: true,
    onColumnFiltersChange,

    // Without this, @tanstack/table will check the value of the first row to check if it's a string to determine
    // whether it should be descending, which causes issues when the cell is nullable as the type changes depending
    // on whether the column is currently sorted or not, resulting in ascending order to never be accessible if the
    // first row is `null`.
    sortDescFirst: true,
    manualSorting: true,
    enableMultiSort: false,

    columnResizeMode: "onChange",

    // Nesting
    onExpandedChange,
    getRowCanExpand,
    getSubRows,
    getExpandedRowModel: getExpandedRowModel(),

    state: {
      sorting,
      columnFilters,
      columnOrder,
      // Despite the typing, Tanstack API cannot actually take an undefined variable here
      columnPinning: columnPinning ?? {},
      columnVisibility,
      expanded,
    },
  })

  const renderCell = (cell: Cell<DataRow, unknown>, alwaysRenderCell?: boolean) => {
    // If we cannot get the value, default to render the cell content
    if (!cell.column.accessorFn || alwaysRenderCell) {
      return flexRender(cell.column.columnDef.cell, cell.getContext())
    }

    const value = cell.getValue()
    // If a value is undefined, the user has no permission to see it.
    if (value === undefined) {
      return <Icon as={EyeOffIcon} />
    }

    // If a value is null, we should render the placeholder if it exists, otherwise default to the cell content again
    return placeHolder && isNullishValue(value)
      ? placeHolder
      : flexRender(cell.column.columnDef.cell, cell.getContext())
  }

  const gridTemplateColumns = table
    .getVisibleLeafColumns()
    .map((column) => `${column.getSize()}px`)
    .join(" ")

  return (
    <Flex flexDir="column" borderWidth={0} flexGrow={1} minHeight={0} {...restProps}>
      <Flex
        flexGrow={1}
        minHeight={0}
        flexShrink={1}
        width="100%"
        height="100%"
        borderBottomWidth={isSticky ? "1px" : undefined}
      >
        <Table
          // Plain tables don't support stickiness, scrolling and fixed widths together.
          // Note that any grid properties on the children will be ignored if display is not set to grid here.
          display={isSticky ? "grid" : undefined}
          // Last column to take up remaining space
          gridTemplateColumns={`${gridTemplateColumns} 1fr`}
          justifyContent="start" // Justify cells left if there are less columns than the container width
          alignContent="start" // Align rows to the top if there are less rows that the container height
          layout="fixed"
          overscrollBehaviorX="contain"
          overflow="auto"
          isolation="isolate"
          minHeight={0}
        >
          <Thead overflow="hidden" display={isSticky ? "contents" : undefined}>
            <Tr overflow="hidden" display={isSticky ? "contents" : undefined}>
              {table.getLeafHeaders().map((header, idx) => {
                const hover = header.column.getCanSort() ? { bg: "gray.100", cursor: "pointer" } : {}

                const stickyProps: TableCellProps | null = isSticky
                  ? header.column.getIsPinned()
                    ? {
                        paddingLeft: 4,
                        borderRightWidth: "1px",
                        position: "sticky",
                        zIndex: 2,
                        left: 0,
                        top: 0,
                      }
                    : {
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }
                  : null

                const onKeyDown: KeyboardEventHandler<HTMLTableCellElement> = ({
                  key,
                  currentTarget: th,
                  shiftKey,
                }) => {
                  const tr = th.parentElement as HTMLTableRowElement
                  switch (key) {
                    case Key.Enter:
                    case " ":
                    case Key.ArrowUp:
                    case Key.ArrowDown: {
                      // Enter/Space toggles, arrow up/down set direction explicitly.
                      header.column.toggleSorting(
                        key === Key.Enter || key === " " ? undefined : key === Key.ArrowDown,
                        shiftKey
                      )
                      break
                    }
                    case Key.ArrowRight:
                      // Move focus to next column
                      Array.from(tr.cells)
                        .slice(th.cellIndex + 1)
                        .find((th) => th.tabIndex === 0)
                        ?.focus()
                      break
                    case Key.ArrowLeft:
                      // Move focus to previous column
                      Array.from(tr.cells)
                        .slice(0, th.cellIndex)
                        .findLast((th) => th.tabIndex === 0)
                        ?.focus()
                      break
                  }
                }

                const resizeHandler = header.getResizeHandler()
                return (
                  <Th
                    key={header.id}
                    width={`${header.column.getSize()}px`}
                    background="gray.50"
                    gridColumn={idx + 1}
                    gridRow={1}
                    alignContent="center"
                    tabIndex={header.column.getCanSort() ? 0 : -1}
                    userSelect="none"
                    pointerEvents={header.column.getIsResizing() ? "none" : undefined}
                    position="relative"
                    {...stickyProps}
                    _hover={hover}
                    onKeyDown={header.column.getCanSort() ? onKeyDown : undefined}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex>
                      <Tooltip
                        label={flexRender(header.column.columnDef.header, header.getContext())}
                        openDelay={650}
                        placement="top"
                      >
                        <chakra.span
                          flexGrow={1}
                          textAlign={header.column.columnDef.meta?.textAlign}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </chakra.span>
                      </Tooltip>
                      {header.column.getIsSorted() ? (
                        <chakra.span pl={1}>
                          {header.column.getIsSorted() === "desc" ? (
                            <Icon
                              as={ArrowDownIcon}
                              aria-label={intl.formatMessage({
                                id: "dataTable.sorted.descending.label",
                                description:
                                  "The label for the icon indicating the table is being sorted by the column in descending order.",
                                defaultMessage: "Sorted descending",
                              })}
                            />
                          ) : (
                            <Icon
                              as={ArrowUpIcon}
                              aria-label={intl.formatMessage({
                                id: "dataTable.sorted.ascending.label",
                                description:
                                  "The label for the icon indicating the table is being sorted by the column in ascending order.",
                                defaultMessage: "Sorted ascending",
                              })}
                            />
                          )}
                        </chakra.span>
                      ) : null}
                    </Flex>
                    {/* Resize handle: The draggable area is a little larger than the visible handle. */}
                    <Flex
                      position="absolute"
                      top="1px"
                      bottom="1px"
                      right="0"
                      width="4px"
                      cursor="ew-resize"
                      zIndex={1}
                      justifyContent="center"
                      sx={{
                        "&:hover > .visible-handle": {
                          background: "brand.300",
                        },
                      }}
                      onMouseDown={(event) => {
                        // Prevent selection in Safari
                        event.preventDefault()
                        resizeHandler(event)
                      }}
                      onTouchStart={resizeHandler}
                      userSelect="none"
                      onClick={stopPropagation}
                    >
                      <Box
                        className="visible-handle"
                        width="2px"
                        background={header.column.getIsResizing() ? "brand.300" : undefined}
                        opacity={0.75}
                      />
                    </Flex>
                  </Th>
                )
              })}
              {/* For taking up the remaining space */}
              <Th role="presentation" aria-hidden="true" background="gray.50" position="sticky" top={0}></Th>
            </Tr>
          </Thead>
          <Tbody display={isSticky ? "contents" : undefined}>
            {table.getRowModel().rows.map((row, rowIdx) => {
              const hoverStyleProps =
                (row.getCanExpand() && row.getLeafRows().length > 0) || onRowClick
                  ? {
                      sx: {
                        "&:hover td": {
                          background: "gray.50",
                          cursor: "pointer",
                        },
                      },
                    }
                  : undefined
              return (
                <Tr
                  key={row.id}
                  display={isSticky ? "contents" : undefined}
                  // Note: This is just an additional way to toggle the row, tables should also render expand buttons
                  // in the first cell.
                  onClick={(event) => {
                    // Don't toggle when trying to select text
                    if (document.getSelection()?.toString()) {
                      return
                    }
                    if (
                      event.target instanceof Element &&
                      (event.target.matches("button, a") || event.target.closest("button, a"))
                    ) {
                      return
                    }
                    if (onRowClick) {
                      onRowClick(row.original)
                    } else {
                      row.toggleExpanded()
                    }
                  }}
                  backgroundColor="white"
                  {...hoverStyleProps}
                >
                  {row.getVisibleCells().map((cell, columnIdx) => {
                    const { wrapOverflow } = cell.column.columnDef.meta || {}
                    const overflowProps: TableCellProps = {
                      overflow: wrapOverflow ? "initial" : "hidden",
                      textOverflow: wrapOverflow ? "initial" : "ellipsis",
                      whiteSpace: wrapOverflow ? "normal" : "nowrap",
                      sx: wrapOverflow
                        ? undefined
                        : {
                            // We can't set this on the table cell directly because it has to be `display:
                            // -webkit-box` which would override `display: table-cell` and break the table layout
                            p: {
                              display: "-webkit-box",
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              WebkitBoxOrient: "vertical",
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              WebkitLineClamp: "1",
                              overflow: "hidden",
                            },
                          },
                    }
                    const stickyColProps: TableCellProps =
                      isSticky && cell.column.getIsPinned()
                        ? {
                            zIndex: 1,
                            borderRightWidth: "1px",
                            paddingLeft: 4,
                            position: "sticky",
                            left: 0,
                          }
                        : {}

                    return (
                      <Td
                        key={cell.id}
                        width={`${cell.column.getSize()}px`}
                        // Needed to set the sticky cell's background so the scrollable cells are hidden
                        backgroundColor="inherit"
                        gridColumn={columnIdx + 1}
                        gridRow={rowIdx + 2} // +2 to account for header row
                        alignContent="center"
                        textAlign={cell.column.columnDef.meta?.textAlign}
                        {...overflowProps}
                        {...stickyColProps}
                      >
                        {renderCell(cell, cell.column.columnDef.meta?.alwaysRenderCell)}
                      </Td>
                    )
                  })}
                  {/* For taking up the remaining space */}
                  <Td role="presentation" aria-hidden="true" />
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Flex>
      {paginationProps ? <Pagination {...paginationProps} /> : null}
    </Flex>
  )
}
