import type { FieldCounts, WorkflowRunStepStatus } from "@brm/schema-types/types.js"
import { Flex, HStack, Icon, Spacer, Stack, Text } from "@chakra-ui/react"
import { Progress } from "@mantine/core"
import type { FC } from "react"
import { FormattedMessage } from "react-intl"
import { CheckCircleIcon } from "../../../../components/icons/icons.js"
import { showApprovedFieldCounts } from "../utils.js"
export const WorkflowRunStepProgressBar: FC<{
  fieldCounts: FieldCounts
  stepStatus: WorkflowRunStepStatus
}> = ({ fieldCounts, stepStatus }) => {
  const { approved, completed, completed_required, required, total } = fieldCounts
  if (total === 0) {
    return null
  }
  const requiredPct = (completed_required * 100) / total
  const optionalCount = completed - completed_required
  const optionalPct = (optionalCount * 100) / total
  const totalOptional = total - required

  return (
    <Stack maxW={240} flex={1}>
      {showApprovedFieldCounts(stepStatus) ? (
        <>
          <Flex alignItems="center" gap={1} justifyContent="end">
            <Text fontSize="xs">
              <FormattedMessage
                defaultMessage="Approved {approved}/{total}"
                description="Text showing the number of optional fields completed"
                id="workflow.run.step.optionalFields"
                values={{
                  approved,
                  total,
                }}
              />
            </Text>
            {approved === total && <Icon as={CheckCircleIcon} color="brand.600" />}
          </Flex>
          <Progress value={(approved * 100) / total} color="brand.7" />
        </>
      ) : (
        <>
          <HStack justifyContent="end">
            {required > 0 && (
              <Flex alignItems="center" gap={1}>
                <Text fontSize="xs">
                  <FormattedMessage
                    defaultMessage="Required {completedRequired}/{totalRequired}"
                    description="Text showing the number of required fields completed"
                    id="workflow.run.step.requiredFields"
                    values={{
                      completedRequired: completed_required,
                      totalRequired: required,
                    }}
                  />
                </Text>
                {completed_required === required && <Icon as={CheckCircleIcon} color="brand.600" />}
              </Flex>
            )}
            {totalOptional > 0 && (
              <>
                <Spacer />
                <Text fontSize="xs" color="gray.600">
                  <FormattedMessage
                    defaultMessage="Optional {optional}/{totalOptional}"
                    description="Text showing the number of optional fields completed"
                    id="workflow.run.step.optionalFields"
                    values={{
                      optional: optionalCount,
                      totalOptional,
                    }}
                  />
                </Text>
              </>
            )}
          </HStack>
          <Progress.Root size="md">
            <Progress.Section value={requiredPct} color="brand.7" />
            <Progress.Section value={optionalPct} color="gray.4" />
          </Progress.Root>
        </>
      )}
    </Stack>
  )
}
