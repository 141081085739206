import type {
  IntegrationAPIKeyProvider,
  IntegrationOAuthClientCredsProvider,
  IntegrationPrivateKeyDomainProvider,
  IntegrationProvider,
} from "@brm/schema-types/types.js"
import {
  IntegrationAPIKeyProviderSchema,
  IntegrationOAuthClientCredsProviderSchema,
  IntegrationPrivateKeyDomainProviderSchema,
} from "@brm/schemas"

const IntegrationAPIKeyProviderKeys = new Set<string>(IntegrationAPIKeyProviderSchema.enum)
const IntegrationOAuthClientCredsProviderKeys = new Set<string>(IntegrationOAuthClientCredsProviderSchema.enum)
const IntegrationPrivateKeyDomainProviderKeys = new Set<string>(IntegrationPrivateKeyDomainProviderSchema.enum)
export type DirectCreateIntegrationProviderType =
  | IntegrationAPIKeyProvider
  | IntegrationOAuthClientCredsProvider
  | IntegrationPrivateKeyDomainProvider

// This should encompass all integrations that are not OAuth2 connections and are not from a unified provider like merge
export const isDirectCreateIntegrationProviderType = (
  provider: IntegrationProvider
): provider is DirectCreateIntegrationProviderType => {
  return (
    IntegrationAPIKeyProviderKeys.has(provider) ||
    IntegrationOAuthClientCredsProviderKeys.has(provider) ||
    IntegrationPrivateKeyDomainProviderKeys.has(provider)
  )
}
