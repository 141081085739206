import { Editor, Element as SlateElement, Transforms } from "slate"
import type { CustomEditor, CustomElementType, CustomTextMark } from "../types.js"

/** Rich Text Options */
export const HOTKEYS: Record<string, CustomTextMark> = {
  b: "bold",
  i: "italic",
  u: "underline",
}

export const isMarkActive = (editor: CustomEditor, format: CustomTextMark) => {
  const marks = Editor.marks(editor)

  return marks ? marks[format as keyof typeof marks] === true : false
}

export const toggleMark = (editor: CustomEditor, format: CustomTextMark) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor: CustomEditor, format: CustomElementType) => {
  const { selection } = editor
  if (!selection) {
    return false
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  )

  return !!match
}

export const toggleBlock = (editor: CustomEditor, format: CustomElementType) => {
  const isActive = isBlockActive(editor, format)
  const LIST_TYPES: CustomElementType[] = ["numbered-list", "bulleted-list"]

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  })

  const newProperties: Partial<SlateElement> = {
    type: isActive ? "paragraph" : isListType(format) ? "list-item" : format,
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isListType(format)) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const LIST_TYPES: CustomElementType[] = ["bulleted-list", "numbered-list"]

export function isListType(type: CustomElementType): type is "bulleted-list" | "numbered-list" {
  return LIST_TYPES.includes(type)
}
