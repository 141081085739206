import type { PersonPicker, PickableEntity, UserPicker } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tooltip,
  chakra,
  useDisclosure,
} from "@chakra-ui/react"
import { useVirtualizer } from "@tanstack/react-virtual"
import React, { useRef, useState, type FC, type ReactElement, type ReactNode } from "react"
import { useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import PickableEntityDisplay from "../features/organization/PickableEntityDisplay.js"
import PersonDisplay from "../features/person/PersonDisplay.js"
import type { GetLogoForOrganizationProps } from "../features/workflows/run/utils.js"
import { SHORT_TOOLTIP_OPEN_DELAY } from "../util/constant.js"
import PersonCell from "./DataTable/CellRenderer/PersonCell.js"
import { FILTER_POPOVER_ITEM_HEIGHT } from "./DataTable/SchemaFilter/constants.js"
import { AddUserIcon, UserIcon } from "./icons/icons.js"
import { ListBox, ListBoxItem } from "./ListBox.js"

const NoSelectedEntityCell = ({ noEntitySelectedLabel }: { noEntitySelectedLabel: string }) => {
  return <PickableEntityDisplay icon={<Icon as={UserIcon} />} displayText={noEntitySelectedLabel} />
}

interface UserPersonEntityCellProps {
  canEdit: boolean
  entity: PickableEntity | null
  pickableEntities: (UserPicker | PersonPicker)[] | undefined
  showTooltip?: boolean
  toolTipLabel?: ReactNode
  /** By default this will have a delay of SHORT_TOOLTIP_OPEN_DELAY */
  tooltipOpenDelay?: number
  onSelect: (entity: UserPicker | PersonPicker | null) => Promise<void>
  /** String shown to user explaining what to search for */
  searchForEntityLabel: string
  /** String to display next to the empty state */
  noEntitySelectedLabel: string
  /** Icon to display in the empty state. When set overrides default empty state behavior */
  emptyStateIcon?: ReactElement
}

export const UserPersonEntityCell: FC<UserPersonEntityCellProps & GetLogoForOrganizationProps> = ({
  canEdit,
  entity,
  showTooltip,
  tooltipOpenDelay = SHORT_TOOLTIP_OPEN_DELAY,
  pickableEntities,
  toolTipLabel,
  onSelect,
  noEntitySelectedLabel,
  emptyStateIcon,
  searchForEntityLabel: searchForEntityPlaceholder,
  getLogoToShowByOrganizationId,
}) => {
  const intl = useIntl()

  const popoverDisclosure = useDisclosure()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchInput, setSearchInput] = useState("")

  if (entity?.type === "variable") {
    // Not yet implemented
    return undefined
  }

  const organizationLogo =
    entity?.type === "user" && getLogoToShowByOrganizationId
      ? getLogoToShowByOrganizationId(entity.organization_id)
      : null

  if (!canEdit || !pickableEntities) {
    return (
      <Tooltip openDelay={tooltipOpenDelay} isDisabled={!showTooltip} label={toolTipLabel}>
        <chakra.span textOverflow="ellipsis">
          {entity?.type === "user" || entity?.type === "person" ? (
            <PersonCell
              person={{ ...entity, profile_image: entity.image_asset }}
              organizationLogoGcsFileName={organizationLogo}
            />
          ) : (
            <NoSelectedEntityCell noEntitySelectedLabel={noEntitySelectedLabel} />
          )}
        </chakra.span>
      </Tooltip>
    )
  }

  return (
    <Popover {...popoverDisclosure} initialFocusRef={searchInputRef} placement="bottom-start" isLazy>
      <Tooltip openDelay={tooltipOpenDelay} isDisabled={!showTooltip} label={toolTipLabel}>
        {/* Tooltip and Popover cannot share the same DOM element as a trigger, need this Box to isolate them see: https://github.com/chakra-ui/chakra-ui/issues/2843 */}
        <Flex overflow="hidden">
          <PopoverTrigger>
            {emptyStateIcon && entity === null ? (
              <IconButton
                variant="ghost"
                onClick={popoverDisclosure.isOpen ? popoverDisclosure.onClose : popoverDisclosure.onOpen}
                icon={<Icon as={AddUserIcon} />}
                aria-label={intl.formatMessage({
                  defaultMessage: "Add entity",
                  id: "entity.add",
                  description: "Label for button to add an entity",
                })}
              />
            ) : (
              <Button
                variant="ghost"
                onClick={popoverDisclosure.isOpen ? popoverDisclosure.onClose : popoverDisclosure.onOpen}
              >
                {entity?.type === "user" || entity?.type === "person" ? (
                  <PersonDisplay
                    person={{ ...entity, profile_image: entity.image_asset }}
                    organizationLogoGcsFileName={organizationLogo}
                  />
                ) : (
                  <NoSelectedEntityCell noEntitySelectedLabel={noEntitySelectedLabel} />
                )}
              </Button>
            )}
          </PopoverTrigger>
        </Flex>
      </Tooltip>
      <PopoverContent width="14rem">
        <Flex borderBottomWidth="1px" p={2}>
          <Input
            ref={searchInputRef}
            placeholder={searchForEntityPlaceholder}
            value={searchInput}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => setSearchInput(event.currentTarget.value)}
          />
        </Flex>
        <EntityCellMenuItemList
          noEntitySelectedLabel={noEntitySelectedLabel}
          entities={pickableEntities
            .filter((p): p is PersonPicker | UserPicker => p.type === "person" || p.type === "user")
            .filter(
              (p) => !searchInput || displayPersonName(p, intl).toLowerCase().includes(searchInput.toLowerCase())
            )}
          onSelect={async (entity) => {
            popoverDisclosure.onClose()
            await onSelect(entity)
          }}
          getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        />
      </PopoverContent>
    </Popover>
  )
}

const EntityCellMenuItemList = React.memo(function EntityCellMenuItemList({
  entities,
  onSelect,
  noEntitySelectedLabel,
  getLogoToShowByOrganizationId,
}: {
  entities: (UserPicker | PersonPicker)[]
  onSelect: (person: UserPicker | PersonPicker | null) => Promise<void>
  noEntitySelectedLabel: string
} & GetLogoForOrganizationProps) {
  const intl = useIntl()
  const parentRef = useRef<HTMLDivElement>(null)

  // pre-pend null option to allow users to unset the current selection
  const options: (UserPicker | PersonPicker | null)[] = [null, ...entities]
  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => FILTER_POPOVER_ITEM_HEIGHT,
    overscan: 5,
  })

  return (
    <Box ref={parentRef} maxH="20rem" overflowY="auto" overflowX="hidden">
      <Stack gap={0} height={`${virtualizer.getTotalSize()}px`}>
        <ListBox
          autoFocus
          aria-label={intl.formatMessage({
            defaultMessage: "Select entity",
            id: "entity.select",
            description: "Label for the entity picker",
          })}
        >
          {virtualizer.getVirtualItems().map((virtualRow, index) => {
            const pickableEntity = options[virtualRow.index]
            isNotUndefined(pickableEntity)

            if (!pickableEntity) {
              return (
                <ListBoxItem
                  key={virtualRow.key}
                  onAction={async () => {
                    await onSelect(null)
                  }}
                  textValue={noEntitySelectedLabel}
                >
                  <PickableEntityDisplay icon={<Icon as={UserIcon} />} displayText={noEntitySelectedLabel} />
                </ListBoxItem>
              )
            }
            const organizationLogo =
              pickableEntity.type === "user" && getLogoToShowByOrganizationId
                ? getLogoToShowByOrganizationId(pickableEntity.organization_id)
                : null

            return (
              <ListBoxItem
                height={`${virtualRow.size}px`}
                transform={`translateY(${virtualRow.start - index * virtualRow.size}px)`}
                key={virtualRow.key}
                onAction={async () => {
                  await onSelect(pickableEntity)
                }}
                textValue={displayPersonName(pickableEntity, intl)}
              >
                <PersonDisplay
                  organizationLogoGcsFileName={organizationLogo}
                  person={{ ...pickableEntity, profile_image: pickableEntity.image_asset }}
                />
              </ListBoxItem>
            )
          })}
        </ListBox>
      </Stack>
    </Box>
  )
})
