import { AbsoluteCenter, Button, HStack, Heading, Icon, Text, VStack } from "@chakra-ui/react"
import type { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "../../../components/Link.js"
import { BackIcon } from "../../../components/icons/icons.js"

const WorkflowRunNoPermissionView: FC = () => {
  const intl = useIntl()

  const message = intl.formatMessage({
    defaultMessage: "You do not have permission to view this request",
    description: "Header explaining you don’t have permissions to view this request",
    id: "errors.workflowRunNoPermissionView.header",
  })

  const details: string | undefined = intl.formatMessage({
    defaultMessage: "To gain access contact your BRM admin",
    description: "Subheader explaining you should contact an admin if you think you should have access",
    id: "errors.workflowRunNoPermissionView.subheader",
  })

  return (
    <AbsoluteCenter>
      <VStack>
        <Heading size="lg" paddingBottom={4}>
          {message}
        </Heading>
        <Text fontSize="medium" textColor="gray.600" paddingBottom={8}>
          {details}
        </Text>
        <HStack>
          <Button as={Link} to="/" colorScheme="brand" size="lg" leftIcon={<Icon as={BackIcon} />}>
            <FormattedMessage
              defaultMessage="Back to home"
              description="Button to send users back to brm homepage"
              id="errors.notFoundErrorView.homeButton"
            />
          </Button>
        </HStack>
      </VStack>
    </AbsoluteCenter>
  )
}

export default WorkflowRunNoPermissionView
