import {
  type BetweenFilter,
  type EqFilter,
  type ExistsFilter,
  type GteFilter,
  type LteFilter,
} from "@brm/schema-types/types.js"
import { getOneTimeFrequencyDisplayName } from "@brm/util/format-date-time.js"
import { Checkbox, Flex } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { NullCheckbox } from "../../../Form/NullCheckbox.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import { DurationRange } from "./DurationRangeFilter.js"
import type { FilterProps } from "./types.js"

/** Filters are always in a menu so components like MenuItems must be used instead of Buttons */
export default function FrequencyRangeFilter({
  filter,
  displayPath,
  fieldSchema,
  isNullable,
  onChange,
}: FilterProps<BetweenFilter | GteFilter | LteFilter | ExistsFilter | EqFilter>) {
  const intl = useIntl()
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")
  const isNullChecked = filter?.comparator === "exists" ? !filter.value : false
  const isOneTimeChecked = filter?.comparator === "eq" ? filter.value === "one_time" : false
  return (
    <Flex flexDir="column" gap={3} p={3} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC}>
      {isNullable && (
        <NullCheckbox
          fieldName={fieldName}
          fieldSchema={fieldSchema}
          isChecked={isNullChecked}
          onChange={({ target: { checked } }) => {
            if (checked) {
              onChange({ comparator: "exists", value: false })
            }
          }}
        />
      )}
      <Checkbox
        isChecked={isOneTimeChecked}
        onChange={({ target: { checked } }) => {
          if (checked) {
            onChange({ comparator: "eq", value: "one_time" })
          }
        }}
      >
        {getOneTimeFrequencyDisplayName(intl)}
      </Checkbox>
      <DurationRange
        onChange={onChange}
        displayPath={displayPath}
        filter={filter?.comparator === "eq" ? undefined : filter}
        fieldSchema={fieldSchema}
        isNullable={isNullable}
      />
    </Flex>
  )
}
