import type { CommentTimelineEvent, TimelineEventReplyTimelineEvent } from "@brm/schema-types/types.js"
import { ButtonGroup, Icon, useToast } from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useGetUserV1WhoamiQuery,
  usePostTimelineV1EventsByIdReactionsMutation,
} from "../../app/services/generated-api.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import { CommentIcon, EditIcon, TrashIcon } from "../icons/icons.js"
import { ReactionPopover } from "./ReactionPopover.js"

export const CommentActions: FunctionComponent<{
  event: CommentTimelineEvent | TimelineEventReplyTimelineEvent
  onClickReply?: () => void
  onClickEdit: () => void
  onClickDelete: () => void
}> = ({ event, onClickReply, onClickEdit, onClickDelete }) => {
  const intl = useIntl()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const [, setSearchParams] = useSearchParams()

  const [addReaction] = usePostTimelineV1EventsByIdReactionsMutation()

  const handleAddReaction = async (emoji: string, timelineEventId: string) => {
    setSearchParams(
      (current) => {
        current.delete("event")
        return current
      },
      { replace: true }
    )
    try {
      await addReaction({
        id: timelineEventId,
        reactionInput: {
          emoji,
        },
      }).unwrap()
    } catch (_) {
      toast({
        description: intl.formatMessage({
          id: "request.comment.reaction.add.error",
          description: "Toast error message when adding a reaction fails",
          defaultMessage: "There was an error adding your reaction",
        }),
        status: "error",
      })
    }
  }

  const replyLabel = intl.formatMessage({
    defaultMessage: "Add reply",
    description: "Aria and tooltip for the reply button on the field timeline comment",
    id: "timeline.comment.reply",
  })
  const editLabel = intl.formatMessage({
    defaultMessage: "Edit comment",
    description: "Aria and tooltip label for the edit button on the field timeline comment",
    id: "timeline.comment.edit",
  })
  const deleteLabel = intl.formatMessage({
    defaultMessage: "Delete comment",
    description: "Aria and tooltip label for the delete button on the field timeline comment",
    id: "timeline.comment.delete",
  })

  const showReplyButton = onClickReply && event.type === "comment" && !event.workflow_run_id

  return (
    <ButtonGroup
      size="sm"
      isAttached
      visibility="hidden"
      sx={{
        ".comment-body:hover &": {
          visibility: "visible",
        },
      }}
    >
      {showReplyButton && (
        <IconButtonWithTooltip
          variant="outline"
          background="white"
          borderRight="none"
          borderRightRadius={0}
          paddingInline={1}
          label={replyLabel}
          icon={<Icon as={CommentIcon} />}
          onClick={onClickReply}
        />
      )}
      {/* ReactionPopover's Tooltip and Popover wrapping over the IconButton messes with the ButtonGroup so we need to set the border radius manually */}
      <ReactionPopover
        onSelect={(emoji) => handleAddReaction(emoji, event.id)}
        buttonProps={{
          paddingInline: 1,
          borderRight: whoami?.id === event.actor.id ? "none" : undefined,
          borderRightRadius: whoami?.id === event.actor.id ? 0 : "lg",
          borderLeft: showReplyButton ? "none" : undefined,
          borderLeftRadius: showReplyButton ? 0 : "lg",
        }}
      />
      {/* Only the acting user that created the comment can edit or delete it */}
      {whoami?.id === event.actor.id && (
        <>
          <IconButtonWithTooltip
            variant="outline"
            background="white"
            borderLeft="none"
            borderRight="none"
            borderRadius={0}
            paddingInline={1}
            label={editLabel}
            icon={<Icon as={EditIcon} />}
            onClick={onClickEdit}
          />
          <IconButtonWithTooltip
            variant="outline"
            background="white"
            borderLeft="none"
            paddingInline={1}
            label={deleteLabel}
            icon={<Icon as={TrashIcon} />}
            onClick={onClickDelete}
          />
        </>
      )}
    </ButtonGroup>
  )
}
