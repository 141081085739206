import type { FieldMetadata, FieldMetadataWithSuggestions, OneTimeFrequency } from "@brm/schema-types/types.js"
import { formatDuration, getOneTimeFrequencyDisplayName, presetFrequencyOptions } from "@brm/util/format-date-time.js"
import type { StyleProps } from "@chakra-ui/react"
import { Flex, Stack, Text } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Select } from "chakra-react-select"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState, type Ref } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../SchemaForm/OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "../SchemaForm/types.js"
import type { DurationUnit } from "./duration-unit.js"
import { CustomDurationInput } from "./DurationInput.js"

type FrequencyDuration = Temporal.Duration | OneTimeFrequency

const isEqual = (duration1: FrequencyDuration | null, duration2: FrequencyDuration | null) => {
  if (!duration1 || !duration2 || duration1 === "one_time" || duration2 === "one_time") {
    return duration1 === duration2
  }
  return Temporal.Duration.compare(duration1, duration2, { relativeTo: Temporal.Now.plainDateISO() }) === 0
}

type FrequencySelectOption = ValueWithSource<FrequencyDuration | null> & {
  label?: string
}

interface OtherOption {}

export interface FrequencyInputGroupProps extends StyleProps {
  value: FrequencyDuration | null
  units: DurationUnit[]
  /**
   * suggestedOptions, if provided will render in a select input that maps to a duration value
   * allowing for custom one-click suggestions to be highlighted to the user
   */
  suggestedOptions?: FrequencySelectOption[]
  onChange: (value: FrequencyDuration | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  isDisabled?: boolean
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
}

/**
 * A frequency can be either a duration or a one-time frequency
 */
export const FrequencyInputGroup = forwardRef(function FrequencyInputGroup(
  props: FrequencyInputGroupProps,
  ref: Ref<HTMLInputElement | null>
) {
  const {
    value,
    onChange,
    isReadOnly = false,
    suggestedOptions,
    isDisabled,
    fieldMetadata,
    fieldApproval,
    ...rest
  } = props
  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  // The select options of the frequency input, comprised of the preset
  const selectOptions = useMemo(() => {
    const frequencyPresetOptions: FrequencySelectOption[] = presetFrequencyOptions(intl)
    const suggestionOptionsNotMatchingPresets: FrequencySelectOption[] = []
    for (const suggestion of suggestedOptions ?? []) {
      if (!suggestion.value) {
        continue
      }
      const matchingPreset = frequencyPresetOptions.find((option) => isEqual(option.value, suggestion.value))
      if (matchingPreset) {
        matchingPreset.field_sources = suggestion.field_sources
      } else {
        suggestionOptionsNotMatchingPresets.push({
          value: suggestion.value === "one_time" ? ("one_time" as const) : Temporal.Duration.from(suggestion.value),
          label:
            suggestion.value === "one_time"
              ? getOneTimeFrequencyDisplayName(intl)
              : formatDuration(intl, Temporal.Duration.from(suggestion.value)),
          field_sources: suggestion.field_sources,
        })
      }
    }
    return [...suggestionOptionsNotMatchingPresets, ...frequencyPresetOptions]
  }, [intl, suggestedOptions])

  const frequency = value === "one_time" || !value ? value : Temporal.Duration.from(value)

  const selectedOption = selectOptions?.find((option) => isEqual(option.value, frequency))
  const selectedValue = {
    value: frequency,
    label:
      (selectedOption?.label ||
        (frequency &&
          formatDuration(intl, frequency, {
            unitDisplay: "long",
          }))) ??
      "",
  }
  // A frequency value is set but it is not one of the preset options
  const isCustomValue = Boolean(frequency && !selectedOption)
  const [showCustomInputs, setShowCustomInputs] = useState(false)

  const otherOptionMessage = intl.formatMessage({
    defaultMessage: "Other",
    description: "Label for the other option",
    id: "components.Form.FrequencyInput.other",
  })

  // If there are no select options, only use custom duration input
  if ((!selectOptions || selectOptions.length === 0) && frequency !== "one_time") {
    return <CustomDurationInput duration={frequency} {...props} />
  }
  return (
    <Stack>
      <Flex alignItems="center" gap={1} flexBasis="min-content" flexGrow={0} {...rest}>
        <Select<FrequencySelectOption | OtherOption>
          ref={(select) => {
            inputRef.current = select?.inputRef ?? null
          }}
          openMenuOnFocus={true}
          value={selectedValue}
          options={[{ value: null, label: "" }, ...selectOptions, {}]}
          isDisabled={isDisabled}
          placeholder={intl.formatMessage({
            id: "form.select.placeholder",
            defaultMessage: "Select an option...",
            description: "Placeholder for selection input",
          })}
          formatOptionLabel={(option, { context }) => {
            if (context === "menu") {
              return "label" in option ? (
                <OptionWithFieldSource fieldSources={option.field_sources}>{option.label}</OptionWithFieldSource>
              ) : (
                otherOptionMessage
              )
            }
            return showCustomInputs ? otherOptionMessage : "label" in option && option.label
          }}
          isOptionSelected={(option, [selectedOption]) =>
            selectedOption && "value" in selectedOption && "value" in option
              ? isEqual(option.value, selectedOption.value)
              : isCustomValue
          }
          isReadOnly={isReadOnly}
          isSearchable={false}
          onChange={(option) => {
            if (option && "value" in option) {
              const optionFieldSource = option.field_sources?.[0]
              onChange(option?.value ?? null, optionFieldSource)
              setShowCustomInputs(false)
            } else {
              setShowCustomInputs(true)
            }
          }}
          styles={{
            menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
          }}
          chakraStyles={{
            container: (styles) => ({ ...styles, flexGrow: 1, minWidth: "7em" }),
            option: (provided, { data }) => {
              const newOption =
                "field_sources" in data ? isNewOption(data.field_sources, fieldMetadata, fieldApproval) : undefined
              return {
                ...provided,
                ...(newOption?.isNew &&
                  newOption?.colorScheme && {
                    backgroundColor: `${newOption.colorScheme}.50`,
                  }),
              }
            },
          }}
          menuPortalTarget={document.body}
        />
      </Flex>
      {showCustomInputs && (
        <>
          <Text color="gray.700" fontSize="sm" fontWeight="medium">
            <FormattedMessage
              defaultMessage="Other (please specify)"
              description="Label for a custom duration input field"
              id="components.Form.FrequencyInput.other"
            />
          </Text>
          <CustomDurationInput duration={frequency === "one_time" ? null : frequency} {...props} />
        </>
      )}
    </Stack>
  )
})
