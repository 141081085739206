import { Text, chakra } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { type ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { FieldValue } from "../../SchemaForm/FieldValue.js"

export default function ArrayDiffFieldTimelineEvent({
  addedValues,
  removedValues,
  fieldSchema,
  fieldLabel,
  fieldPath,
  actor,
  objectLink,
  showFieldName,
}: {
  addedValues: unknown[]
  removedValues: unknown[]
  actor: ReactElement
  objectLink?: ReactElement
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined
  fieldLabel: ReactElement
  fieldPath: readonly (string | number)[]
  showFieldName?: boolean
}) {
  const addedValuesText = (
    <chakra.strong fontWeight="medium">
      <FieldValue value={addedValues} fieldPath={fieldPath} fieldSchema={fieldSchema} />
    </chakra.strong>
  )
  const removedValuesText = (
    <chakra.strong fontWeight="medium">
      <FieldValue value={removedValues} fieldPath={fieldPath} fieldSchema={fieldSchema} />
    </chakra.strong>
  )
  return (
    <Text as="span" color="gray.600">
      {addedValues.length > 0 && removedValues.length > 0 ? (
        showFieldName ? (
          objectLink ? (
            <FormattedMessage
              defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText} from {fieldLabel} for {objectLink}"
              description="Field change event text describing added values for an object"
              id="timeline.field_change.array_diff.added.withField.withLink"
              values={{ actor, addedValuesText, removedValuesText, fieldLabel, objectLink }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText} from {fieldLabel}"
              description="Field change event text describing added values"
              id="timeline.field_change.array_diff.added.withField"
              values={{ actor, addedValuesText, removedValuesText, fieldLabel }}
            />
          )
        ) : objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText} for {objectLink}"
            description="Field change event text describing added values for an object"
            id="timeline.field_change.array_diff.added.withLink"
            values={{ actor, addedValuesText, removedValuesText, objectLink }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText}"
            description="Field change event text describing added values"
            id="timeline.field_change.array_diff.added"
            values={{ actor, addedValuesText, removedValuesText }}
          />
        )
      ) : addedValues.length > 0 ? (
        showFieldName ? (
          objectLink ? (
            <FormattedMessage
              defaultMessage="{actor} added {addedValuesText} to {fieldLabel} for {objectLink}"
              description="Field change event text describing added values for an object"
              id="timeline.field_change.array_diff.added.withField.withLink"
              values={{ actor, addedValuesText, fieldLabel, objectLink }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{actor} added {addedValuesText} to {fieldLabel}"
              description="Field change event text describing added values"
              id="timeline.field_change.array_diff.added.withField"
              values={{ actor, addedValuesText, fieldLabel }}
            />
          )
        ) : objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} added {addedValuesText} for {objectLink}"
            description="Field change event text describing added values for an object"
            id="timeline.field_change.array_diff.added.withLink"
            values={{ actor, addedValuesText, objectLink }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} added {addedValuesText}"
            description="Field change event text describing added values"
            id="timeline.field_change.array_diff.added"
            values={{ actor, addedValuesText }}
          />
        )
      ) : removedValues.length > 0 ? (
        showFieldName ? (
          objectLink ? (
            <FormattedMessage
              defaultMessage="{actor} removed {removedValuesText} from {fieldLabel} for {objectLink}"
              description="Field change event text describing removed values for an object"
              id="timeline.field_change.array_diff.removed.withField.withLink"
              values={{ actor, removedValuesText, fieldLabel, objectLink }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{actor} removed {removedValuesText} from {fieldLabel}"
              description="Field change event text describing removed values"
              id="timeline.field_change.array_diff.removed.withField"
              values={{ actor, removedValuesText, fieldLabel }}
            />
          )
        ) : objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} removed {removedValuesText} for {objectLink}"
            description="Field change event text describing removed values for an object"
            id="timeline.field_change.array_diff.removed.withLink"
            values={{ actor, removedValuesText, objectLink }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} removed {removedValuesText}"
            description="Field change event text describing removed values"
            id="timeline.field_change.array_diff.removed"
            values={{ actor, removedValuesText }}
          />
        )
      ) : showFieldName ? (
        objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} set {fieldLabel} to <strong>None</strong> for {objectLink}"
            description="Field change event text when an actor sets a field from null to None for an object"
            id="timeline.field_change.array_diff.setNone.withField.withLink"
            values={{ actor, fieldLabel, objectLink }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} set {fieldLabel} to <strong>None</strong>"
            description="Field change event text when an actor sets a field from null to None"
            id="timeline.field_change.array_diff.setNone.withField"
            values={{ actor, fieldLabel }}
          />
        )
      ) : objectLink ? (
        <FormattedMessage
          defaultMessage="{actor} set to <strong>None</strong> for {objectLink}"
          description="Field change event text when an actor sets a field from null to None for an object"
          id="timeline.field_change.array_diff.setNone.withLink"
          values={{ actor, objectLink }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{actor} set to <strong>None</strong>"
          description="Field change event text when an actor sets a field from null to None"
          id="timeline.field_change.array_diff.setNone"
          values={{ actor }}
        />
      )}
    </Text>
  )
}
