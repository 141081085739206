import type { WorkflowRunStepDecision, WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import type { ModalProps, UseModalProps } from "@chakra-ui/react"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { WORKFLOW_RUN_STEP_FORM_ID } from "../../features/workflows/run/utils.js"

interface Props extends UseModalProps, Pick<ModalProps, "returnFocusOnClose"> {
  workflowRunStep: Pick<WorkflowRunStepWithContext, "id" | "display_name">
}

export default function StepApprovalConfirmationModal({ workflowRunStep, ...modalProps }: Props) {
  return (
    <Modal {...modalProps} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Approve {stepName}"
            description="Modal header for approving a request step"
            id="step.approval.modal.header"
            values={{ stepName: workflowRunStep.display_name }}
          />
        </ModalHeader>
        <ModalBody as={Stack} gap={4}>
          <Text fontWeight="normal" color="gray.600">
            <FormattedMessage
              defaultMessage="All fields for {stepName} have been approved. Do you want to approve the entire step so the request can proceed?"
              id="step.approval.modal.confirmation.description"
              description="Modal text prompting the user to approve the entire request step if all of the individual fields have been approved"
              values={{ stepName: workflowRunStep.display_name }}
            />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            form={WORKFLOW_RUN_STEP_FORM_ID}
            name={"proceed" satisfies WorkflowRunStepDecision}
            colorScheme="brand"
            flex={1}
            onClick={modalProps.onClose}
          >
            <FormattedMessage
              defaultMessage="Approve {stepName}"
              description="Modal confirmation button to approve the entire step"
              id="step.approval.modal.confirm"
              values={{ stepName: workflowRunStep.display_name }}
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
