import type { WorkflowRun, WorkflowRunStep, WorkflowRunStepsUpdatePatch } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePatchWorkflowV1RunsByIdStepsMutation } from "../../../../app/services/generated-api.js"
import OrganizationEntityPicker from "../../../../components/Form/OrganizationEntityPicker.js"
import { getAPIErrorMessage } from "../../../../util/error.js"
import { canEditWorkflowRunStepUsers, getMinimalRoleForPermission, getPermissionRequiredToEditStep } from "../utils.js"

interface Props extends UseModalProps {
  workflowRun: Pick<WorkflowRun, "id" | "status">
  workflowRunSteps: Pick<WorkflowRunStep, "id" | "approval_steps" | "owner" | "display_name" | "status" | "type">[]
  updateField: keyof Omit<WorkflowRunStepsUpdatePatch[number], "id">
}
export default function WorkflowRunStepsUpdateModal({
  workflowRun,
  workflowRunSteps,
  updateField,
  ...modalProps
}: Props) {
  const intl = useIntl()
  const toast = useToast()
  const [updateWorkflowRunSteps, { isLoading }] = usePatchWorkflowV1RunsByIdStepsMutation()

  const form = useForm<{
    steps: Pick<WorkflowRunStep, "id" | "approval_steps" | "owner" | "display_name" | "status">[]
  }>({
    defaultValues: { steps: workflowRunSteps },
  })
  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent
        as={chakra.form}
        onSubmit={form.handleSubmit(async (formValues) => {
          try {
            const filteredSteps: WorkflowRunStepsUpdatePatch = formValues.steps
              .filter((step) => canEditWorkflowRunStepUsers(step, workflowRun))
              .map((step) => ({
                id: step.id,
                owner: step.owner,
                approval_steps: step.approval_steps,
              }))
            await updateWorkflowRunSteps({
              id: workflowRun.id,
              workflowRunStepsUpdatePatch: filteredSteps,
            }).unwrap()
            toast({
              status: "success",
              description: intl.formatMessage({
                id: "requests.step.approver.change.toast.success.title",
                description: "Toast title when changing the approver of a request step succeeds",
                defaultMessage: "Request steps updated",
              }),
            })
            modalProps.onClose()
          } catch (err) {
            toast({
              status: "error",
              title: intl.formatMessage({
                id: "requests.step.approver.change.toast.error.title",
                description: "Toast title when changing the approver of a request step fails",
                defaultMessage: "Failed to update request steps",
              }),
              description: getAPIErrorMessage(err),
            })
          }
        })}
      >
        <ModalCloseButton />
        <ModalHeader>
          {updateField === "approval_steps" ? (
            <FormattedMessage
              id="requests.step.approver.change.approvers.modal.header"
              description="Modal header on modal where users can change the approver of request steps"
              defaultMessage="Change Approvers"
            />
          ) : (
            <FormattedMessage
              id="requests.step.approver.change.gatherers.modal.header"
              description="Modal header on modal where users can change the gatherers of request steps"
              defaultMessage="Change Gatherers"
            />
          )}
        </ModalHeader>
        <ModalBody as={Stack} gap={4}>
          {updateField === "approval_steps"
            ? workflowRunSteps.map((step, stepIdx) =>
                step.approval_steps.map((approvalStep, approvalStepIdx) => (
                  <Stack key={approvalStep.id}>
                    <Text fontWeight="semibold">
                      <FormattedMessage
                        id="approvalStep.update.heading"
                        description="Heading for approval step in the modal where users can change the approver of request steps"
                        defaultMessage="{stepName} Approval Step {approvalStepIdx}"
                        values={{ approvalStepIdx: approvalStepIdx + 1, stepName: step.display_name }}
                      />
                    </Text>
                    {approvalStep.approvers.map((approver, approverIdx) => (
                      <Controller
                        key={approver.id}
                        name={`steps.${stepIdx}.approval_steps.${approvalStepIdx}.approvers.${approverIdx}.user`}
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            isInvalid={fieldState.invalid}
                            key={step.id}
                            isDisabled={!canEditWorkflowRunStepUsers(step, workflowRun)}
                          >
                            <OrganizationEntityPicker
                              includedEntities={["user"]}
                              onChangeUser={(user) => {
                                field.onChange(user)
                              }}
                              initialSelected={
                                field.value
                                  ? {
                                      type: "user",
                                      display_name: displayPersonName(field.value, intl),
                                      image_asset: field.value.profile_image,
                                      ...field.value,
                                    }
                                  : null
                              }
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
                              }}
                              permission={{
                                value: getPermissionRequiredToEditStep(step.type),
                                includeAll: true,
                                defaultInviteRole: getMinimalRoleForPermission(
                                  getPermissionRequiredToEditStep(step.type)
                                ),
                              }}
                            />
                            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    ))}
                  </Stack>
                ))
              )
            : workflowRunSteps.map((step, idx) => (
                <Controller
                  key={step.id}
                  name={`steps.${idx}.owner`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid}
                      key={step.id}
                      isDisabled={!canEditWorkflowRunStepUsers(step, workflowRun)}
                    >
                      <FormLabel>
                        <FormattedMessage
                          defaultMessage="{stepName} Gatherer"
                          description="The label of the gatherer input in the request step edit modal"
                          id="requests.step.edit.gatherer.label"
                          values={{ stepName: step.display_name }}
                        />
                      </FormLabel>
                      <OrganizationEntityPicker
                        includedEntities={["user"]}
                        onChangeUser={(user) => {
                          field.onChange(user)
                        }}
                        initialSelected={{
                          type: "user",
                          display_name: displayPersonName(field.value, intl),
                          image_asset: field.value?.profile_image,
                          ...field.value,
                        }}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
                        }}
                      />
                      <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
              ))}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button type="button" onClick={modalProps.onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="The label of the cancel button in the request step edit modal"
              id="requests.step.edit.button.cancel"
            />
          </Button>
          <Button type="submit" colorScheme="brand" isLoading={isLoading}>
            <FormattedMessage
              defaultMessage="Save"
              description="The label of the save button in the request step edit modal"
              id="requests.step.edit.button.save"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
