import type { WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import { Flex, Heading, Stack } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useLazyGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery } from "../../../app/services/generated-api.js"
import { StepLevelCommentsButton } from "./step/comment/StepLevelCommentButton.js"
import { StepLevelCommentSection } from "./step/comment/StepLevelCommentSection.js"
import { WorkflowRunStepStatusBadge } from "./step/status/WorkflowRunStepStatusBadge.js"
import WorkflowRunStepAvatars from "./step/WorkflowRunStepAvatars.js"
import { WorkflowRunStepProgressBar } from "./step/WorkflowRunStepStepsCompletedBar.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "./utils.js"

export default function WorkflowRunStepHeader({
  workflowRunStep,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
}: {
  workflowRunStep: WorkflowRunStepWithContext
} & GetLogoForOrganizationProps &
  GetOrganizationActorProps) {
  const [stepLevelCommentsIsOpen, setStepLevelCommentsIsOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const isStepLevelCommentSelected =
    searchParams.get("event") && searchParams.get("step") && !searchParams.get("object") && !searchParams.get("field")

  const onCloseStepLevelComments = useCallback(() => {
    if (isStepLevelCommentSelected) {
      const newSearch = new URLSearchParams(searchParams)
      newSearch.delete("event")
      setSearchParams(newSearch)
    }
    setStepLevelCommentsIsOpen(false)
  }, [isStepLevelCommentSelected, searchParams, setStepLevelCommentsIsOpen, setSearchParams])
  const [fetchStepTimelineEvents, stepTimelineEventsResult] =
    useLazyGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery({
      pollingInterval: 60_000,
      refetchOnFocus: true,
    })

  useEffect(() => {
    if (stepLevelCommentsIsOpen) {
      void fetchStepTimelineEvents({
        workflowRunId: workflowRunStep.workflow_run_id,
        workflowRunStepId: workflowRunStep.id,
      })
    }
  }, [fetchStepTimelineEvents, stepLevelCommentsIsOpen, workflowRunStep.id, workflowRunStep.workflow_run_id])

  // If a workflow step comment is selected open the comment step drawer if a non step comment is selected close the drawer
  useEffect(() => {
    setStepLevelCommentsIsOpen((isOpen) => {
      return (
        Boolean(
          searchParams.get("event") &&
            searchParams.get("step") &&
            !searchParams.get("object") &&
            !searchParams.get("field")
        ) ||
        (isOpen &&
          Boolean(
            !searchParams.get("event") &&
              searchParams.get("step") &&
              !searchParams.get("object") &&
              !searchParams.get("field")
          ))
      )
    })
  }, [searchParams])
  return (
    <Flex px={6} py={2} borderBottomWidth={1} minW={0}>
      <Flex flexDirection="column" flexGrow={1} minW={0}>
        <Flex gap={2} alignItems="center">
          <Stack minW={0}>
            <Flex minW={0} flexWrap="wrap" columnGap={1} alignItems="center">
              <Heading
                as="h2"
                size="xs"
                fontSize="xl"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                minW={0}
              >
                {workflowRunStep.display_name}
              </Heading>
              <WorkflowRunStepStatusBadge step={workflowRunStep} />
            </Flex>
            <WorkflowRunStepAvatars step={workflowRunStep} />
          </Stack>
          <StepLevelCommentsButton
            isOpen={stepLevelCommentsIsOpen}
            commentCount={workflowRunStep.comment_count}
            onOpen={() => setStepLevelCommentsIsOpen(true)}
            onClose={onCloseStepLevelComments}
            ml="auto"
            flexShrink={0}
          />
          {workflowRunStep.field_counts.total > 0 && (
            <WorkflowRunStepProgressBar
              stepStatus={workflowRunStep.status}
              fieldCounts={workflowRunStep.field_counts}
            />
          )}
        </Flex>
        {stepLevelCommentsIsOpen && (
          <StepLevelCommentSection
            workflowRunStepId={workflowRunStep.id}
            workflowRunId={workflowRunStep.workflow_run_id}
            workflowRunStepName={workflowRunStep.display_name}
            timelineEvents={stepTimelineEventsResult.data || []}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
          />
        )}
      </Flex>
    </Flex>
  )
}
