import { EmployeeGroupTypeSchema } from "@brm/schemas"
import { getEnumMemberTitle } from "@brm/util/schema.js"
import { shortenUUID } from "@brm/util/short-uuids.js"
import { Avatar, Flex, HStack, Icon, Text, chakra } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useVirtualizer } from "@tanstack/react-virtual"
import { useMemo, useRef } from "react"
import { useIntl } from "react-intl"
import invariant from "tiny-invariant"
import { hasPresentKey } from "ts-is-present"
import { isNotUndefined } from "typed-assert"
import {
  useGetEmployeeGroupV1Query,
  useGetErpTrackingCategoryV1Query,
  useGetIntegrationV1Query,
  useGetToolV1CategoriesQuery,
  useGetToolV1PickerOptionsQuery,
  useGetVendorV1PickerOptionsQuery,
  useGetWorkflowV1RunsPickablesQuery,
  usePostOrganizationV1PermissionedPickableEntitiesQuery,
} from "../../../../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../../../../util/url.js"
import { ListBox, ListBoxItem } from "../../../ListBox.js"
import { CheckIcon, UserIcon, XIcon } from "../../../icons/icons.js"
import { FILTER_POPOVER_ITEM_HEIGHT, FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { SelectableObjectType } from "./types.js"

interface ObjectSelectorFilterProps {
  selectable: SelectableObjectType
  searchInput: string
  selectedValues: Set<string>
  excludedValues?: Set<string>
  onChange: (value: string) => Promise<void> | void
  nullableOption?: {
    onChange: () => void
    title: string
    checked: boolean
  }
}

/**
 * The object selector filter filters columns that are either an object reference (to another object with `id`) or
 * an array of object references (with `id` properties) by a list of options the user can select from which is
 * fetched from the backend.
 */
export default function ObjectSelectorFilter({
  nullableOption,
  selectedValues,
  excludedValues,
  onChange,
  searchInput,
  selectable,
}: ObjectSelectorFilterProps) {
  const intl = useIntl()

  const parentRef = useRef<HTMLDivElement>(null)

  const toolsResult = useGetToolV1PickerOptionsQuery(selectable.objectType === "Tool" ? { search: "" } : skipToken)
  const vendorResult = useGetVendorV1PickerOptionsQuery(selectable.objectType === "Vendor" ? { search: "" } : skipToken)
  const personsOrUsersResult = usePostOrganizationV1PermissionedPickableEntitiesQuery(
    selectable.objectType === "Person" || selectable.objectType === "User"
      ? {
          body: {
            filter: {
              entities: [selectable.objectType.toLowerCase() as Lowercase<typeof selectable.objectType>],
              permission: selectable.objectType === "User" ? selectable.permission : undefined,
            },
          },
        }
      : skipToken
  )
  const toolCategoriesResult = useGetToolV1CategoriesQuery(
    selectable.objectType === "ToolCategory" ? undefined : skipToken
  )
  const integrationsResult = useGetIntegrationV1Query(selectable.objectType === "Integration" ? undefined : skipToken)
  const employeeGroupsResult = useGetEmployeeGroupV1Query(
    selectable.objectType === "EmployeeGroup" ? { groupType: selectable.groupType } : skipToken
  )
  const erpTrackingCategoriesResult = useGetErpTrackingCategoryV1Query(
    selectable.objectType === "ErpTrackingCategory" ? { type: selectable.categoryType } : skipToken
  )
  const workflowRunsResult = useGetWorkflowV1RunsPickablesQuery(
    selectable.objectType === "WorkflowRun" ? undefined : skipToken
  )

  interface Option {
    value: string
    display_name: string
    image_url?: string | null
  }

  const options = useMemo(
    (): Option[] | undefined =>
      toolCategoriesResult.data?.map((tc) => ({ ...tc, value: tc.id })) ??
      integrationsResult.data?.map((i) => ({ ...i, value: i.id })) ??
      employeeGroupsResult.data?.map((group) => ({
        ...group,
        value: group.id,
        display_name:
          group.display_name ??
          intl.formatMessage(
            {
              id: "employee.group.department.unknown",
              defaultMessage: "Unknown {groupType}",
              description: "Fallback name for department without display name",
            },
            { groupType: getEnumMemberTitle(group.group_type, EmployeeGroupTypeSchema) }
          ),
      })) ??
      personsOrUsersResult.data?.map((person): Option => {
        invariant(
          person.type === "person" || person.type === "user",
          "Expected pickable entity result to be a person or user"
        )
        return {
          value: person.id,
          display_name: person.display_name,
          image_url: getPublicImageGcsUrl(person.image_asset?.gcs_file_name) ?? null,
        }
      }) ??
      toolsResult.data
        ?.filter(hasPresentKey("id"))
        .map((t) => ({ ...t, value: t.id, image_url: getPublicImageGcsUrl(t.image_asset?.gcs_file_name) })) ??
      vendorResult.data
        ?.filter(hasPresentKey("id"))
        .map((t) => ({ ...t, value: t.id, image_url: getPublicImageGcsUrl(t.image_asset?.gcs_file_name) })) ??
      (erpTrackingCategoriesResult.data &&
        Array.from(
          // A lot orgs have the same erp tracking category display name for many subsidiaries so it is better to filter on the display name
          new Set<string>(erpTrackingCategoriesResult.data.map((trackingCategory) => trackingCategory.display_name)),
          (displayName): Option => ({
            value: displayName,
            display_name: displayName,
          })
        )) ??
      workflowRunsResult.data?.map((run) => ({ display_name: run.display_name, value: run.id })),
    [
      intl,
      employeeGroupsResult.data,
      erpTrackingCategoriesResult.data,
      integrationsResult.data,
      personsOrUsersResult.data,
      toolCategoriesResult.data,
      toolsResult.data,
      vendorResult.data,
      workflowRunsResult.data,
    ]
  )

  const visibleOptions = useMemo(() => {
    const lowerCaseSearchInput = searchInput.toLocaleLowerCase(intl.locale).trim()
    let visibleOptions = lowerCaseSearchInput
      ? options?.filter((option) => option.display_name.toLocaleLowerCase(intl.locale).includes(lowerCaseSearchInput))
      : options

    // filter out options that have been explicitly excluded
    if (excludedValues) {
      visibleOptions = visibleOptions?.filter((option) => !excludedValues.has(option.value))
    }

    // pre-pend null option if we allow the users to select a filter where the entity, like an owner or a department is null
    return [...(nullableOption ? [null] : []), ...(visibleOptions ?? [])]
  }, [searchInput, intl.locale, options, excludedValues, nullableOption])

  const virtualizer = useVirtualizer({
    count: visibleOptions.length,
    getScrollElement: () => parentRef.current,
    getItemKey: (index) => String(visibleOptions[index]?.value ?? null),
    estimateSize: () => FILTER_POPOVER_ITEM_HEIGHT,
    overscan: 5,
  })

  return (
    <Flex flexDir="column" ref={parentRef} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC} overflowY="auto" minW={0}>
      <Flex flexDir="column" height={`${virtualizer.getTotalSize()}px`} minW={0}>
        <ListBox
          aria-label={intl.formatMessage(
            {
              id: "filter.objectSelector.menu.label",
              defaultMessage: "Select {objectType} to filter by",
              description: "Label for the object filter select listbox",
            },
            { objectType: selectable.objectType }
          )}
          autoFocus
          selectedKeys={selectedValues}
        >
          {virtualizer.getVirtualItems().map((virtualRow, index) => {
            const option = visibleOptions[virtualRow.index]
            isNotUndefined(option)

            if (option === null) {
              isNotUndefined(nullableOption)
              return (
                <ListBoxItem
                  key={virtualRow.key}
                  textValue={nullableOption.title}
                  display="flex"
                  gap={2}
                  onAction={nullableOption.onChange}
                >
                  {(selectable.objectType === "Person" || selectable.objectType === "User") && (
                    <Avatar
                      referrerPolicy="no-referrer"
                      // If there is no display name we show the user icon which is uses white text
                      textColor="black"
                      size="xs"
                      icon={<Icon as={XIcon} />}
                    />
                  )}
                  {nullableOption.title}
                  <Icon as={CheckIcon} opacity={nullableOption.checked ? 1 : 0} ml="auto" />
                </ListBoxItem>
              )
            }

            const { value, display_name, image_url } = option
            return (
              <ListBoxItem
                key={virtualRow.key}
                textValue={display_name}
                height={`${virtualRow.size}px`}
                transform={`translateY(${virtualRow.start - index * virtualRow.size}px)`}
                onAction={() => onChange(value)}
              >
                {image_url || image_url === null ? (
                  <HStack textOverflow="ellipsis" overflow="hidden" minW={0} flexShrink={1}>
                    <Avatar
                      referrerPolicy="no-referrer"
                      name={display_name}
                      // If there is no display name we show the user icon which is uses white text
                      textColor={display_name ? "black" : "white"}
                      src={image_url ?? undefined}
                      size="xs"
                      icon={<Icon as={UserIcon} />}
                    />
                    <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" minW={0}>
                      {display_name}
                    </Text>
                  </HStack>
                ) : (
                  <chakra.span textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" minW={0} flexShrink={1}>
                    {display_name}
                  </chakra.span>
                )}
                <Icon
                  as={CheckIcon}
                  opacity={selectedValues.has(value) || selectedValues.has(shortenUUID(value)) ? 1 : 0}
                  ml="auto"
                />
              </ListBoxItem>
            )
          })}
        </ListBox>
      </Flex>
    </Flex>
  )
}
