import type { ToolComplianceStatus, ToolFinanceStatus, ToolItStatus, ToolStatus } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { ThemeTypings } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"

export const displayToolStatus = (toolStatus: ToolStatus, intl: IntlShape) => {
  switch (toolStatus) {
    case "approved":
      return intl.formatMessage({
        id: "tool.status.approved",
        defaultMessage: "Approved",
        description: "The label for the tool status `approved`",
      })
    case "canceling":
      return intl.formatMessage({
        id: "tool.status.canceling",
        defaultMessage: "Canceling",
        description: "The label for the tool status `canceling`",
      })
    case "churned":
      return intl.formatMessage({
        id: "tool.status.churned",
        defaultMessage: "Churned",
        description: "The label for the tool status `churned`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "tool.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the tool status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "tool.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the tool status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "tool.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the tool status `unknown`",
      })
    case "warning":
      return intl.formatMessage({
        id: "tool.status.warning",
        defaultMessage: "Warning",
        description: "The label for the tool status `warning`",
      })
    default:
      unreachable(toolStatus)
  }
}

export const toolStatusColors: Record<ToolStatus, ThemeTypings["colorSchemes"]> = {
  approved: "green",
  in_review: "green",
  canceling: "yellow",
  warning: "yellow",
  unknown: "gray",
  churned: "error",
  rejected: "error",
}

export const displayToolComplianceStatus = (status: ToolComplianceStatus, intl: IntlShape) => {
  switch (status) {
    case "approved":
      return intl.formatMessage({
        id: "tool.compliance.status.approved",
        defaultMessage: "Approved",
        description: "The label for the tool compliance status `approved`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "tool.compliance.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the tool compliance status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "tool.compliance.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the tool compliance status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "tool.compliance.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the tool compliance status `unknown`",
      })
    default:
      unreachable(status)
  }
}

export const toolComplianceColors: Record<ToolComplianceStatus, ThemeTypings["colorSchemes"]> = {
  approved: "green",
  in_review: "green",
  unknown: "gray",
  rejected: "error",
}

export const displayToolItStatus = (status: ToolItStatus, intl: IntlShape) => {
  switch (status) {
    case "approved":
      return intl.formatMessage({
        id: "tool.it.status.approved",
        defaultMessage: "Approved",
        description: "The label for the tool IT status `approved`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "tool.it.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the tool IT status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "tool.it.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the tool IT status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "tool.it.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the tool IT status `unknown`",
      })
    default:
      unreachable(status)
  }
}

export const toolItColors: Record<ToolItStatus, ThemeTypings["colorSchemes"]> = {
  approved: "green",
  in_review: "green",
  unknown: "gray",
  rejected: "error",
}

export const displayToolFinanceStatus = (status: ToolFinanceStatus, intl: IntlShape) => {
  switch (status) {
    case "paid":
      return intl.formatMessage({
        id: "tool.finance.status.paid",
        defaultMessage: "Paid",
        description: "The label for the tool finance status `paid`",
      })
    case "unpaid":
      return intl.formatMessage({
        id: "tool.finance.status.unpaid",
        defaultMessage: "Unpaid",
        description: "The label for the tool finance status `unpaid`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "tool.finance.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the tool finance status `in_review`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "tool.finance.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the tool finance status `unknown`",
      })
    case "paid_unapproved":
      return intl.formatMessage({
        id: "tool.finance.status.paid_unapproved",
        defaultMessage: "Paid (unapproved)",
        description: "The label for the tool finance status `paid_unapproved`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "tool.finance.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the tool finance status `rejected`",
      })
    default:
      unreachable(status)
  }
}

export const toolFinanceColors: Record<ToolFinanceStatus, ThemeTypings["colorSchemes"]> = {
  paid: "green",
  unpaid: "green",
  in_review: "green",
  unknown: "gray",
  paid_unapproved: "error",
  rejected: "error",
}
