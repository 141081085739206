import type { LegalClauses } from "@brm/schema-types/types.js"
import { chakra } from "@chakra-ui/react"
import { Fragment, type ReactElement } from "react"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { getLegalClausesDisplayNames } from "../../../features/legal/legal-clause-labels.js"

export default function LegalClausesFieldChangeEvent({
  oldClauses,
  newClauses,
  objectLink,
  actor,
  fieldLabel,
  showFieldName,
}: {
  oldClauses: LegalClauses | undefined
  newClauses: LegalClauses | undefined
  objectLink?: ReactElement
  actor: ReactElement
  fieldLabel: ReactElement | null
  showFieldName?: boolean
}) {
  const intl = useIntl()
  const legalClauseDisplayNames = getLegalClausesDisplayNames(intl)
  const addedValues =
    (newClauses &&
      Object.keys(newClauses).filter(
        (clauseKey) => newClauses[clauseKey as keyof LegalClauses] && !oldClauses?.[clauseKey as keyof LegalClauses]
      )) ??
    []
  const removedValues =
    (oldClauses &&
      Object.keys(oldClauses).filter(
        (clauseKey) => oldClauses[clauseKey as keyof LegalClauses] && !newClauses?.[clauseKey as keyof LegalClauses]
      )) ??
    []
  const addedValuesText = (
    <chakra.strong fontWeight="medium">
      <FormattedList
        value={addedValues.map((clauseKey) => (
          <Fragment key={clauseKey}>{legalClauseDisplayNames[clauseKey as keyof LegalClauses]}</Fragment>
        ))}
        type="conjunction"
      />
    </chakra.strong>
  )

  const removedValuesText = (
    <chakra.strong fontWeight="medium">
      <FormattedList
        value={removedValues.map((clauseKey) => (
          <Fragment key={clauseKey}>{legalClauseDisplayNames[clauseKey as keyof LegalClauses]}</Fragment>
        ))}
        type="conjunction"
      />
    </chakra.strong>
  )
  return addedValues.length > 0 && removedValues.length > 0 ? (
    showFieldName ? (
      objectLink ? (
        <FormattedMessage
          defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText} from {fieldLabel} for {objectLink}"
          description="Field change event text describing added values for an object"
          id="timeline.field_change.array_diff.added.withField.withLink"
          values={{ actor, addedValuesText, removedValuesText, fieldLabel, objectLink }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText} from {fieldLabel}"
          description="Field change event text describing added values"
          id="timeline.field_change.array_diff.added.withField"
          values={{ actor, addedValuesText, removedValuesText, fieldLabel }}
        />
      )
    ) : objectLink ? (
      <FormattedMessage
        defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText} for {objectLink}"
        description="Field change event text describing added values for an object"
        id="timeline.field_change.array_diff.added.withLink"
        values={{ actor, addedValuesText, removedValuesText, objectLink }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{actor} added {addedValuesText} and removed {removedValuesText}"
        description="Field change event text describing added values"
        id="timeline.field_change.array_diff.added"
        values={{ actor, addedValuesText, removedValuesText }}
      />
    )
  ) : addedValues.length > 0 ? (
    showFieldName ? (
      objectLink ? (
        <FormattedMessage
          defaultMessage="{actor} added {addedValuesText} to {fieldLabel} for {objectLink}"
          description="Field change event text describing added values for an object"
          id="timeline.field_change.array_diff.added.withField.withLink"
          values={{ actor, addedValuesText, fieldLabel, objectLink }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{actor} added {addedValuesText} to {fieldLabel}"
          description="Field change event text describing added values"
          id="timeline.field_change.array_diff.added.withField"
          values={{ actor, addedValuesText, fieldLabel }}
        />
      )
    ) : objectLink ? (
      <FormattedMessage
        defaultMessage="{actor} added {addedValuesText} for {objectLink}"
        description="Field change event text describing added values for an object"
        id="timeline.field_change.array_diff.added.withLink"
        values={{ actor, addedValuesText, objectLink }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{actor} added {addedValuesText}"
        description="Field change event text describing added values"
        id="timeline.field_change.array_diff.added"
        values={{ actor, addedValuesText }}
      />
    )
  ) : removedValues.length > 0 ? (
    showFieldName ? (
      objectLink ? (
        <FormattedMessage
          defaultMessage="{actor} removed {removedValuesText} from {fieldLabel} for {objectLink}"
          description="Field change event text describing removed values for an object"
          id="timeline.field_change.array_diff.removed.withField.withLink"
          values={{ actor, removedValuesText, fieldLabel, objectLink }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{actor} removed {removedValuesText} from {fieldLabel}"
          description="Field change event text describing removed values"
          id="timeline.field_change.array_diff.removed.withField"
          values={{ actor, removedValuesText, fieldLabel }}
        />
      )
    ) : objectLink ? (
      <FormattedMessage
        defaultMessage="{actor} removed {removedValuesText} for {objectLink}"
        description="Field change event text describing removed values for an object"
        id="timeline.field_change.array_diff.removed.withLink"
        values={{ actor, removedValuesText, objectLink }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{actor} removed {removedValuesText}"
        description="Field change event text describing removed values"
        id="timeline.field_change.array_diff.removed"
        values={{ actor, removedValuesText }}
      />
    )
  ) : null
}
