import { maxDocumentSize } from "@brm/type-helpers/document.js"
import { List, ListItem, Text, type CreateToastFnReturn } from "@chakra-ui/react"
import { ErrorCode, type FileRejection } from "react-dropzone"
import { FormattedMessage, FormattedNumber } from "react-intl"

/**
 * An `onDropRejected` handler for `react-dropzone` that shows a toast with a human-readable error message per file depending on the error code.
 */
export const onDropRejected =
  (toast: CreateToastFnReturn, { maxFiles }: { maxFiles: number }) =>
  (fileRejections: FileRejection[]): void => {
    const errorItemPrefix = "- "
    toast({
      status: "error",
      duration: 30_000,
      description: (
        <>
          <Text fontWeight="semibold" color="error.700">
            <FormattedMessage
              defaultMessage="The following {fileCount, plural, one {file} other {files}} could not be uploaded:"
              description="The description for the error toast when one or more files were rejected"
              id="documentUpload.dropzone.rejectedFiles.title"
              values={{ fileCount: fileRejections.length }}
            />
          </Text>
          <List>
            {fileRejections.map(({ file, errors }, index) => (
              <ListItem
                key={`${index}:${file.name}:${file.size}`}
                display="flex"
                flexDirection="column"
                color="error.700"
              >
                <Text as="div" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  {file.name}
                </Text>
                <List ml={3}>
                  {errors.map((error) => (
                    <ListItem key={error.code}>
                      {errorItemPrefix}
                      {error.code === ErrorCode.FileInvalidType ? (
                        <FormattedMessage
                          defaultMessage="File type is not supported."
                          description="The error message for document upload when the file type is unsupported"
                          id="documentUpload.unsupportedFileTypeError"
                        />
                      ) : error.code === ErrorCode.FileTooLarge ? (
                        <FormattedMessage
                          defaultMessage="Document is {fileSize} large, the maximum is {maxFileSize}."
                          description="The error message for document upload when the file is too large"
                          id="documentUpload.unsupportedFileTypeError"
                          values={{
                            fileSize: (
                              <FormattedNumber
                                value={file.size}
                                style="unit"
                                unitDisplay="narrow"
                                unit="byte"
                                notation="compact"
                              />
                            ),
                            maxFileSize: (
                              <FormattedNumber
                                value={maxDocumentSize}
                                style="unit"
                                unitDisplay="narrow"
                                unit="byte"
                                notation="compact"
                              />
                            ),
                          }}
                        />
                      ) : error.code === ErrorCode.TooManyFiles ? (
                        <FormattedMessage
                          defaultMessage="File had to be omitted because only {maxFiles, number} can be uploaded at a time. Please try again after uploading the current batch."
                          description="The error message for document upload when too many files were uploaded"
                          id="documentUpload.unsupportedFileTypeError"
                          values={{ maxFiles }}
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Unknown error"
                          description="The error message for document upload when the error is unknown"
                          id="documentUpload.unknownError"
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        </>
      ),
    })
  }
