import { Tag } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

interface Props {
  riskScore?: number
}

const getRiskScoreText = (riskScore?: number) => {
  switch (riskScore) {
    case 1:
      return (
        <Tag colorScheme="orange" size="sm">
          <FormattedMessage
            id="table.cell.security.risk.warning"
            description="Security overview table risk cell warning"
            defaultMessage="Warning"
          />
        </Tag>
      )
    case 2:
      return (
        <Tag colorScheme="error" size="sm">
          <FormattedMessage
            id="table.cell.security.risk.critical"
            description="Security overview table risk cell failure"
            defaultMessage="Critical"
          />
        </Tag>
      )
    default:
      return null
  }
}

export default function LoginScopeRiskCell({ riskScore }: Props) {
  return getRiskScoreText(riskScore)
}
