import { getLegalAgreementVerificationStatusSchema } from "@brm/schema-helpers/legal.js"
import { hasPermission } from "@brm/schema-helpers/role.js"
import type { AgreementEffectiveStatus, ToolMinimal } from "@brm/schema-types/types.js"
import { getTitle } from "@brm/util/schema.js"
import type { CardProps } from "@chakra-ui/react"
import { Badge, Box, Button, Card, Grid, HStack, Icon, Stack, Text, Tooltip, useDisclosure } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import MoneyCell from "../../components/DataTable/CellRenderer/MoneyCell.js"
import DateRange from "../../components/DateRange.js"
import FeaturedIcon from "../../components/FeaturedIcon/FeaturedIcon.js"
import {
  AlertIcon,
  CalendarIcon,
  CheckIcon,
  ChevronRightIcon,
  ToolIcon,
  UserEditIcon,
  ZapIcon,
  ZapOffIcon,
} from "../../components/icons/icons.js"
import { Link, LinkOrSpan } from "../../components/Link.js"
import { Timestamp } from "../../components/Timestamp.js"
import { EMPTY_VALUE_SYMBOL } from "../../util/constant.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"
import type { LegalAgreementOption } from "./LegalAgreementPicker.js"
import LegalAgreementStatusBadge from "./LegalAgreementStatusBadge.js"

interface Props extends CardProps {
  legalAgreement: LegalAgreementOption
  isPrimary?: boolean
  hideRenewalOption?: boolean
  isOwner?: boolean
  // Is duplicative subscription agreement, deprecate once we have tasks/inbox
  isDuplicate?: boolean
  tool?: ToolMinimal
}

const statusesExpectingSigner: AgreementEffectiveStatus[] = ["upcoming", "active"]

export const LegalAgreementCard = ({
  legalAgreement,
  hideRenewalOption,
  isDuplicate,
  isPrimary,
  isOwner,
  tool,
  ...cardProps
}: Props) => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const intl = useIntl()
  const startWorkflowDisclosure = useDisclosure()

  const verificationStatusSchema = getLegalAgreementVerificationStatusSchema(legalAgreement?.verification_status)
  const userCanUpdate = isOwner || hasPermission(whoami?.roles, "legal:update")
  const canRenewAgreement = !hideRenewalOption && legalAgreement.effective_status === "expired"

  return (
    <Card variant="outline" px={6} py={4} {...cardProps}>
      <Stack>
        <HStack alignItems="center" gap={2} flexWrap="wrap">
          <LinkOrSpan
            to={userCanUpdate ? `#agreement=${legalAgreement.id}` : undefined}
            fontSize="lg"
            fontWeight="medium"
            whiteSpace="nowrap"
            flexShrink={1}
            minW="5ch"
            isTruncated
          >
            {legalAgreement.display_name}
          </LinkOrSpan>
          {isDuplicate && (
            <Tooltip
              label={intl.formatMessage({
                defaultMessage:
                  "BRM has detected duplicative subscription agreements. Consider consolidating to a team plan.",
                description: "Tooltip for duplicate agreement badge",
                id: "agreement.primary.tooltip",
              })}
            >
              <Box>
                <FeaturedIcon
                  icon={<Icon as={AlertIcon} color="warning.700" boxSize={5} />}
                  backgroundColor="warning.200"
                />
              </Box>
            </Tooltip>
          )}
          <Badge variant="subtleOutlined" size="md" colorScheme={verificationStatusSchema?.colorScheme}>
            {getTitle(legalAgreement.verification_status, verificationStatusSchema)}
          </Badge>
          {isPrimary ? (
            <Tooltip
              label={intl.formatMessage({
                defaultMessage: "This is the primary agreement",
                description: "Tooltip for primary agreement badge",
                id: "agreement.primary.tooltip",
              })}
            >
              <HStack alignItems="center" gap={1}>
                <LegalAgreementStatusBadge status={legalAgreement.effective_status} />
                <Icon as={CheckIcon} />
              </HStack>
            </Tooltip>
          ) : (
            <LegalAgreementStatusBadge status={legalAgreement.effective_status} />
          )}
          <Text as="span" fontSize="xs" color="gray.600">
            <Timestamp dateTime={legalAgreement.created_at} />
          </Text>
          {legalAgreement.renewal_successor ? (
            <Link to={`/requests/${legalAgreement.renewal_successor.id}`} justifySelf="flex-end" ml="auto">
              <HStack alignItems="center">
                <Text color="brand.700" fontWeight="semibold" fontSize="sm" whiteSpace="nowrap">
                  <FormattedMessage
                    id="agreement.viewRenewalRequest"
                    description="Text on a link to view a renewal request of a legal agreement"
                    defaultMessage="View renewal"
                  />
                </Text>
                <Icon as={ChevronRightIcon} />
              </HStack>
            </Link>
          ) : (
            canRenewAgreement &&
            userCanUpdate && (
              <Button variant="link" onClick={startWorkflowDisclosure.onOpen} justifySelf="flex-end" ml="auto">
                <HStack alignItems="center">
                  <Text color="brand.700" fontWeight="semibold" fontSize="sm" whiteSpace="nowrap">
                    <FormattedMessage
                      id="agreement.startRenewal"
                      description="Text on a link to start a renewal request of a legal agreement"
                      defaultMessage="Start renewal"
                    />
                  </Text>
                  <Icon as={ChevronRightIcon} />
                </HStack>
              </Button>
            )
          )}
        </HStack>
        <Grid
          paddingTop={2}
          gridTemplateColumns="repeat(auto-fill, minmax(14rem, 1fr))"
          columnGap={4}
          rowGap={1}
          fontSize="sm"
          fontWeight="medium"
        >
          {/* SignerField */}
          {legalAgreement.agreement_type === "enterprise" && (
            <HStack alignItems="center">
              <Icon as={UserEditIcon} />
              <Text
                color={
                  !legalAgreement.is_signed && statusesExpectingSigner.includes(legalAgreement.effective_status)
                    ? "error.500"
                    : "black"
                }
              >
                {legalAgreement.buyer_signer_name ? (
                  // Move to tooltip
                  <Tooltip
                    label={
                      <FormattedMessage
                        id="agreement.signedBy"
                        description="agreement signer"
                        defaultMessage="Signed By: {signer}"
                        values={{ signer: legalAgreement.buyer_signer_name }}
                      />
                    }
                  >
                    {legalAgreement.buyer_signer_name}
                  </Tooltip>
                ) : legalAgreement.is_signed ? (
                  <FormattedMessage
                    id="agreement.signed.true"
                    description="Description for an agreement that is signed without a signer name"
                    defaultMessage="Signed by both parties"
                  />
                ) : (
                  <FormattedMessage
                    id="agreement.notSigned"
                    description="Description for an agreement that is not signed"
                    defaultMessage="Not Signed"
                  />
                )}
              </Text>
            </HStack>
          )}
          {/* DateField */}
          <HStack alignItems="center">
            <Icon as={CalendarIcon} />
            <Text>
              <DateRange
                startDate={legalAgreement.start_date}
                endDate={legalAgreement.end_date}
                defaultLabel={EMPTY_VALUE_SYMBOL}
              />
            </Text>
          </HStack>
          {(isOwner || hasPermission(whoami?.roles, "legal:read")) && (
            <HStack alignItems="center">
              <FormattedMessage
                id="agreement.total_contract_value"
                description="Abbreviation for Total Contract Value"
                defaultMessage="TCV"
              />
              <MoneyCell currencyAmount={legalAgreement.total_contract_value} />
            </HStack>
          )}
          {/* RenewalField */}
          {legalAgreement.auto_renews !== null && legalAgreement.auto_renews !== undefined && (
            <HStack alignItems="center">
              {legalAgreement.auto_renews === true ? (
                <>
                  <Icon as={ZapIcon} />
                  <FormattedMessage id="agreement.renews" description="agreement renews" defaultMessage="Auto renews" />
                </>
              ) : legalAgreement.auto_renews === false ? (
                <>
                  <Icon as={ZapOffIcon} />
                  <FormattedMessage
                    id="agreement.noRenewal"
                    description="agreement no renewal"
                    defaultMessage="Does not auto renew"
                  />
                </>
              ) : null}
            </HStack>
          )}
          {tool && (
            <HStack alignItems="center">
              <Icon as={ToolIcon}></Icon>
              <Link to={`/tools/${tool.id}`}>{tool.display_name}</Link>
            </HStack>
          )}
        </Grid>
      </Stack>
      {startWorkflowDisclosure.isOpen && (
        <StartWorkflowModal {...startWorkflowDisclosure} initialLegalAgreement={legalAgreement} />
      )}
    </Card>
  )
}
