import type { FilterField } from "@brm/schema-types/types.js"
import { localeFirstDayOfWeek, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from "@brm/util/date-time.js"
import { isObject } from "@brm/util/type-guard.js"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { IntlShape } from "react-intl"
import type { ReadonlyDeep } from "type-fest"

export const defaultCurrencyFilterOptions: [`${number}` | null, `${number}` | null][] = [
  ["0", "4999"],
  ["5000", "24999"],
  ["25000", "49999"],
  ["50000", "99999"],
  ["100000", "249999"],
  ["250000", null],
]

export interface DateSelection {
  displayName: string
  fields: FilterField
}

export function getDateFilterOptions(
  fieldSchema: ReadonlyDeep<JSONSchema>,
  intl: IntlShape,
  currentDate = Temporal.Now.plainDateISO()
) {
  return isObject(fieldSchema) && fieldSchema.defaultFilter === "lt"
    ? getDateLessThanFilterOptions(intl, currentDate)
    : getDateGreaterThanEqualFilterOptions(intl, currentDate)
}

/**
 * Returns the options the user can choose from when filtering a date column with an `lt` (less than) filter.
 *
 * @param intl Intl object to calculate the first day of the week and to format the display strings of each option.
 * @param currentDate The date to use as the current date. Defaults to the current date. Can be overridden for testing.
 */
export function getDateLessThanFilterOptions(intl: IntlShape, currentDate = Temporal.Now.plainDateISO()) {
  const startOfWeekDate = startOfWeek(currentDate, localeFirstDayOfWeek(intl.locale))
  const startOfMonthDate = startOfMonth(currentDate)
  const startOfQuarterDate = startOfQuarter(currentDate)
  const startOfYearDate = startOfYear(currentDate)

  return {
    past_due: {
      displayName: intl.formatMessage({
        id: "currentDate.is.lessThan.today",
        defaultMessage: "Today",
        description: "text showing the option to filter a date column being past due (date is before today)",
      }),
      fields: { comparator: "lt", value: currentDate.toString() },
    },
    this_week: {
      displayName: intl.formatMessage({
        id: "currentDate.is.lessThan.thisWeek",
        defaultMessage: "End of this Week",
        description:
          "text showing the option to filter a date column being this week (date is before the end of this week)",
      }),
      fields: { comparator: "lt", value: startOfWeekDate.add({ weeks: 1 }).toString() },
    },
    this_month: {
      displayName: intl.formatMessage({
        id: "currentDate.is.lessThan.thisMonth",
        defaultMessage: "End of this Month",
        description:
          "text showing the option to filter a date column being this month (date is before the end of this month)",
      }),
      fields: { comparator: "lt", value: startOfMonthDate.add({ months: 1 }).toString() },
    },
    this_quarter: {
      displayName: intl.formatMessage({
        id: "workflowRun.completeByFilter.thisQuarter",
        description:
          "text showing the option to filter a date column being this quarter (date is before the end of this quarter)",
        defaultMessage: "End of this Quarter",
      }),
      fields: { comparator: "lt", value: startOfQuarterDate.add({ months: 3 }).toString() },
    },
    this_year: {
      displayName: intl.formatMessage({
        id: "workflowRun.completeByFilter.thisYear",
        description:
          "text showing the option to filter a date column being this year (date is before the end of this year)",
        defaultMessage: "End of this Year",
      }),
      fields: { comparator: "lt", value: startOfYearDate.add({ years: 1 }).toString() },
    },
  } satisfies Record<string, DateSelection>
}

/**
 * Returns the options the user can choose from when filtering a date column with an `gte` (greater than equals) filter.
 *
 * @param intl Intl object to calculate the first day of the week and to format the display strings of each option.
 * @param currentDate The date to use as the current date. Defaults to the current date. Can be overridden for testing.
 */
export function getDateGreaterThanEqualFilterOptions(intl: IntlShape, currentDate = Temporal.Now.plainDateISO()) {
  const startOfWeekDate = startOfWeek(currentDate, localeFirstDayOfWeek(intl.locale))
  const startOfMonthDate = startOfMonth(currentDate)
  const startOfQuarterDate = startOfQuarter(currentDate)
  const startOfYearDate = startOfYear(currentDate)

  return {
    today: {
      displayName: intl.formatMessage({
        id: "currentDate.is.greaterThanOrEq.today",
        defaultMessage: "Today",
        description: "text showing the option to filter a date column being today or later (date is today or after)",
      }),
      fields: { comparator: "gte", value: currentDate.toString() },
    },
    this_week: {
      displayName: intl.formatMessage({
        id: "currentDate.is.greaterThanOrEq.thisWeek",
        defaultMessage: "Start of this Week",
        description: "text showing the option to filter a date column being this week (date is after this week)",
      }),
      fields: { comparator: "gte", value: startOfWeekDate.toString() },
    },
    this_month: {
      displayName: intl.formatMessage({
        id: "currentDate.is.greaterThanOrEq.thisMonth",
        defaultMessage: "Start of this Month",
        description: "text showing the option to filter a date column being this month (date is after this month)",
      }),
      fields: { comparator: "gte", value: startOfMonthDate.toString() },
    },
    this_quarter: {
      displayName: intl.formatMessage({
        id: "currentDate.is.greaterThanOrEq.thisQuarter",
        defaultMessage: "Start of this Quarter",
        description: "text showing the option to filter a date column being this quarter (date is after this quarter)",
      }),
      fields: { comparator: "gte", value: startOfQuarterDate.toString() },
    },
    this_year: {
      displayName: intl.formatMessage({
        id: "currentDate.is.greaterThanOrEq.thisYear",
        defaultMessage: "Start of this Year",
        description: "text showing the option to filter a date column being this year (date is after this year)",
      }),
      fields: { comparator: "gte", value: startOfYearDate.toString() },
    },
  } satisfies Record<string, DateSelection>
}
