import type { WorkflowConditionGroupInput } from "@brm/schema-types/types.js"
import { Box } from "@chakra-ui/react"
import { Select } from "chakra-react-select"

interface ConditionalToggleProps {
  conditionalToggle: null | "conditional"
  setConditionalToggle: (value: null | "conditional") => void
  onChangeConditional: (conditionalSchema: WorkflowConditionGroupInput | null) => void
  labels: {
    conditional: string
    always: string
  }
}

export default function ConditionalToggle({
  conditionalToggle,
  setConditionalToggle,
  onChangeConditional,
  labels,
}: ConditionalToggleProps) {
  return (
    <Box w={225}>
      <Select<{ value: null | "conditional" }>
        variant="unstyled"
        value={{ value: conditionalToggle }}
        onChange={(option) => {
          if (option?.value === null) {
            onChangeConditional(null)
          }
          setConditionalToggle(option?.value ?? null)
        }}
        isSearchable={false}
        options={[{ value: null }, { value: "conditional" }]}
        formatOptionLabel={({ value }) => {
          return value === "conditional" ? labels.conditional : labels.always
        }}
      />
    </Box>
  )
}
