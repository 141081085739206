import type { WorkflowRunStepStatus } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Icon, type IconProps, type StyleProps } from "@chakra-ui/react"
import { CircleCheckIcon, CircleDotIcon, CircleXIcon } from "../../../components/icons/icons.js"

const defaultStatusIconProps: IconProps = {
  borderRadius: "full",
  background: "white",
  color: "brand.600",
  boxSize: 5,
  verticalAlign: "middle",
}

export default function WorkflowRunStatusIcon({ status, ...props }: { status: WorkflowRunStepStatus } & StyleProps) {
  switch (status) {
    case "approved":
    case "completed":
      return <Icon as={CircleCheckIcon} {...defaultStatusIconProps} {...props} />
    case "in_progress":
    case "submitted":
      return <Icon as={CircleDotIcon} {...defaultStatusIconProps} {...props} />
    case "pending":
    case "pending_submitted":
      return <Icon as={CircleDotIcon} {...defaultStatusIconProps} color="gray.200" {...props} />
    case "rejected":
      return <Icon as={CircleXIcon} {...defaultStatusIconProps} color="error.500" {...props} />
    case "cancelled":
    case "skipped":
      return <Icon as={CircleXIcon} {...defaultStatusIconProps} color="gray.200" {...props} />
    default:
      unreachable(status)
  }
}
