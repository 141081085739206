import { LinearGradient } from "@visx/gradient"
import { AreaSeries, Axis, BarSeries, BarStack, Tooltip, XYChart } from "@visx/xychart"
import { defaultAccentColor } from "./constants.js"
import type { SeriesChartProps } from "./types.js"

export default function SeriesChart<Datum extends object, AccessorType extends string = string>({
  width,
  height,
  datumTransformers,
  chartData,
  axisProps,
  xyChartProps,
  accentColor = defaultAccentColor,
}: SeriesChartProps<Datum, AccessorType>) {
  return (
    <XYChart
      height={height}
      width={width}
      xScale={chartData.type === "stacked-bar" ? { type: "band", padding: 0.4 } : { type: "time" }}
      yScale={{ type: "linear" }}
      margin={{
        top: 20,
        bottom: 20,
        // Stacked bar charts seem to have some inherent left right padding
        left: chartData.type === "stacked-bar" ? 0 : 20,
        right: chartData.type === "stacked-bar" ? 0 : 20,
      }}
      {...xyChartProps}
    >
      {axisProps?.map((axisProp, index) => <Axis {...axisProp} key={index} />)}
      <LinearGradient id="area-gradient-line" from={accentColor} to="transparent" />
      {chartData.type === "line" && (
        <AreaSeries
          dataKey="amount"
          data={chartData.data}
          xAccessor={datumTransformers.xAccessor}
          yAccessor={datumTransformers.yAccessor}
          fill="url(#area-gradient-line)"
          fillOpacity={0.2}
          lineProps={{ stroke: "var(--chakra-colors-blue-500)" }}
        />
      )}
      {chartData.type === "stacked-bar" && (
        <BarStack>
          {chartData.data.map(({ key, intervals }) => (
            <BarSeries
              key={key}
              dataKey={key}
              data={intervals}
              colorAccessor={() => chartData.colorAccessor?.(key)}
              xAccessor={datumTransformers.xAccessor}
              yAccessor={datumTransformers.yAccessor}
            />
          ))}
        </BarStack>
      )}

      <Tooltip<Datum>
        snapTooltipToDatumX={true}
        snapTooltipToDatumY={true}
        showVerticalCrosshair={true}
        verticalCrosshairStyle={{ stroke: "var(--chakra-colors-gray-800)", strokeWidth: 1, strokeOpacity: 0.5 }}
        zIndex="var(--chakra-zIndices-tooltip)"
        renderTooltip={({ tooltipData }) => {
          const datum = tooltipData?.nearestDatum?.datum
          if (!datum || !datumTransformers.tooltip) {
            return null
          }

          if (chartData.type === "stacked-bar" && datumTransformers.stackedTooltip && datumTransformers.accessorKey) {
            const nearestDatum = tooltipData?.nearestDatum?.datum
            if (!nearestDatum) return datumTransformers.stackedTooltip([])

            const stackedData: Datum[] = []
            for (const key of Object.keys(tooltipData.datumByKey)) {
              const nearestDatumOfKey = chartData.accessors[key as AccessorType]?.get(
                datumTransformers.accessorKey(nearestDatum)
              )

              if (nearestDatumOfKey) {
                stackedData.push(nearestDatumOfKey)
              }
            }
            return datumTransformers.stackedTooltip(stackedData)
          }

          return datumTransformers.tooltip(datum)
        }}
      />
    </XYChart>
  )
}
