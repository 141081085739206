// This feature only works in orgs explicitly allow listed in the betsy route
// This includes all tool usage by the agent, e.g. browsing and code execution
export const newBetsyEnabled = localStorage.getItem("newBetsyEnabled") === "true"

// Felix's Hackathon projects
export const churnEmailAssistEnabled = localStorage.getItem("churnEmailAssistEnabled") === "true"
export const dealEvalAssistEnabled = localStorage.getItem("dealEvalAssistEnabled") === "true"

// Feature flag to enable seeing pendo resource center launcher in the UI
export const pendoResourceCenterEnabled = localStorage.getItem("pendoResourceCenterEnabled") === "true"

// Feature flag to enable conditionals in the request config UI
export const conditionalsEnabled = localStorage.getItem("conditionalsEnabled") === "true"

export const plaidEnabled = localStorage.getItem("plaidEnabled") === "true"

// Feature flag to enable tasks in inbox
export const tasksEnabled = localStorage.getItem("tasksEnabled") === "true"

export const dynamoTelemetryEnabled = (organizationId: string | null | undefined) => {
  const brmOrgs = [
    "b2837438-e84f-46fe-b3fd-5d93a42932b0", // BRM staging
    "b5e93bff-56ef-4bb9-818c-f03e6cb155ff", // BRM prod
  ]

  if (organizationId && brmOrgs.includes(organizationId)) {
    return true
  }

  return localStorage.getItem("dynamoTelemetryEnabled") === "true"
}
