import type { RefObject } from "react"
import { useEffect, useLayoutEffect, useRef, useState } from "react"

/**
 * Hook to allow tracking an element's width. Listens to window resizes.
 *
 * The hook will reset the returned `width` to `baseWidth` before measuring to ensure that layouts get
 * recalculated by the browser based on the `baseWidth`.
 *
 * @param baseWidth The minimum width to use before the element is measured.
 * @returns The measured width and a ref to attach to the element that is being measured.
 */
export function useWidth<T extends Element>(baseWidth = 1): { width: number; ref: RefObject<T> } {
  const [measuring, setMeasuring] = useState(true)
  const [width, setWidth] = useState<number>(baseWidth)
  const ref = useRef<T>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setMeasuring(false)
        setWidth(entry.contentRect.width)
      }
    })

    // Start observing the component
    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    // Specify how to clean up after this effect
    return () => resizeObserver.disconnect()
  }, [])

  // Explicitly run this layout effect one every render, otherwise it doesn't work.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!measuring || !ref.current) {
      return
    }
    setWidth(ref.current.clientWidth)
    setMeasuring(false)
  })

  return {
    width: measuring ? baseWidth : width,
    ref,
  }
}
