import { titleToFieldName } from "@brm/util/schema.js"
import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  Input,
  List,
  ListItem,
} from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { Controller, useFieldArray, type UseFormReturn } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { PlusIcon, XIcon } from "../../../components/icons/icons.js"
import type { CriterionFormState } from "./types.js"

export const EnumOptionEditor: FunctionComponent<{
  form: UseFormReturn<CriterionFormState>

  /**
   * Whether `const` values will be updated when the `title` is changed.
   * After a field has been created, the `const` values cannot be changed anymore because it would require wide-reaching migrations.
   * The user-facing `title` can always be renamed though, making this just an internal implementation detail.
   */
  updateConstValues: boolean

  /** The location of the root schema for the enum (i.e. where the `anyOf` keyword sits) */
  enumRootPath: "dataType" | "dataType.items"

  isReadOnly?: boolean
}> = ({ form, enumRootPath, isReadOnly, updateConstValues }) => {
  const intl = useIntl()
  const arrayField = useFieldArray({
    name: `${enumRootPath}.anyOf`,
    control: form.control,
  })
  return (
    <Card variant="outline">
      <CardBody>
        <Flex flexDir="column" gap={3}>
          <List display="contents">
            {arrayField.fields.map((enumMemberSchema, index) => (
              <ListItem key={enumMemberSchema.id}>
                <Controller
                  control={form.control}
                  name={`${enumRootPath}.anyOf.${index}.title`}
                  rules={{ required: true }}
                  shouldUnregister
                  render={({ field, fieldState }) => (
                    <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={isReadOnly}>
                      <Flex gap={1} alignItems="center">
                        <Input
                          {...field}
                          onChange={({ target: { value } }) => {
                            field.onChange(value)
                            if (updateConstValues) {
                              form.setValue(`${enumRootPath}.anyOf.${index}.const`, titleToFieldName(value, intl))
                            }
                          }}
                          placeholder={intl.formatMessage({
                            defaultMessage: `Option ${index + 1}…`,
                            description: "Placeholder for enum option input field",
                            id: "enumOptionEditor.option.placeholder",
                          })}
                          aria-label={intl.formatMessage({
                            defaultMessage: "Option label",
                            description: "Aria label for enum option input field",
                            id: "enumOptionEditor.option.ariaLabel",
                          })}
                        />
                        {!isReadOnly && (
                          <IconButton
                            icon={<Icon as={XIcon} />}
                            size="sm"
                            variant="ghost"
                            aria-label={intl.formatMessage({
                              defaultMessage: "Remove option",
                              description: "Aria label for remove button of enum option input field",
                              id: "enumOptionEditor.option.remove.ariaLabel",
                            })}
                            onClick={() => arrayField.remove(index)}
                          />
                        )}
                      </Flex>
                      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              </ListItem>
            ))}
          </List>
          {!isReadOnly && (
            <Button
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={PlusIcon} />}
              onClick={() => arrayField.append({ const: "", title: "" })}
              alignSelf="start"
            >
              <FormattedMessage
                defaultMessage="Add value"
                description="Add option button label"
                id="enumOptionEditor.button.addValue"
              />
            </Button>
          )}
        </Flex>
      </CardBody>
    </Card>
  )
}
