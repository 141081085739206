import type { FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { formatDuration } from "@brm/util/format-date-time.js"
import { capitalize } from "@brm/util/string.js"
import type { FormControlProps, StyleProps } from "@chakra-ui/react"
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Select } from "chakra-react-select"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState, type ReactNode, type Ref } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../SchemaForm/OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "../SchemaForm/types.js"
import type { DurationUnit } from "./duration-unit.js"
import { DATE_DURATION_UNITS } from "./duration-unit.js"

const findUnitWithNonZeroValue = (units: DurationUnit[], duration: Temporal.Duration): DurationUnit | undefined =>
  units.find((unit) => duration[`${unit}s`])

const unitDisplay = "long"

type DurationFormControlProps = FormControlProps &
  DurationInputGroupProps & {
    legend: ReactNode
    legendProps?: StyleProps
    inputGroupProps?: StyleProps
    errorMessage?: ReactNode
  }

/**
 * A simple input field for ISO8601 durations, allowing to input one value and selecting one unit.
 * All other units will be zero.
 * Customize the units that are allowed by passing a constant from `duration-unit.ts` as the `units` prop.
 */

export const DurationFormControl = forwardRef<HTMLInputElement, DurationFormControlProps>(function DurationFormControl(
  {
    legend,
    legendProps,
    inputGroupProps,
    errorMessage,
    isReadOnly = false,
    value,
    onChange,
    units,
    predefinedOptions,
    ...formControlProps
  }: DurationFormControlProps,
  ref
) {
  return (
    <FormControl as="fieldset" isReadOnly={isReadOnly} width="auto" {...formControlProps}>
      <FormLabel as="legend" {...legendProps}>
        {legend}
      </FormLabel>
      <DurationInputGroup
        ref={ref}
        value={value}
        onChange={onChange}
        isReadOnly={isReadOnly}
        units={units}
        predefinedOptions={predefinedOptions}
        {...inputGroupProps}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
})

type PredefinedOption = ValueWithSource<Temporal.Duration | null | undefined> & {
  label?: string
}

interface UnitOption {
  value: DurationUnit | null
}

interface OtherOption {}

export interface DurationInputGroupProps extends StyleProps {
  value: Temporal.DurationLike | string | null | undefined
  units: DurationUnit[]
  predefinedOptions?: PredefinedOption[]
  onChange: (value: Temporal.Duration | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  suggestions?: PredefinedOption[]
  isDisabled?: boolean
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
}

/** Arbitrary limit to avoid overflowing into Infinity/MAX_SAFE_INTEGER or trigger exponential notation */
const MAX = 1000
const MIN = 0

const isEqual = (duration1: Temporal.Duration | null | undefined, duration2: Temporal.Duration | null | undefined) => {
  if (!duration1 || !duration2) {
    return duration1 === duration2
  }
  return Temporal.Duration.compare(duration1, duration2, { relativeTo: Temporal.Now.plainDateISO() }) === 0
}

/**
 * A simple input field for ISO8601 durations, allowing to input one value and selecting one unit.
 * All other units will be zero.
 * Customize the units that are allowed by passing a constant from `duration-unit.ts` as the `units` prop.
 *
 * In most cases you should use {@link DurationFormControl} to render the label correctly as a legend.
 */
export const DurationInputGroup = forwardRef(function DurationInputGroup(
  props: DurationInputGroupProps,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()
  const {
    value,
    onChange,
    isReadOnly = false,
    predefinedOptions,
    suggestions,
    isDisabled,
    fieldMetadata,
    fieldApproval,
    ...rest
  } = props

  const duration = value ? Temporal.Duration.from(value) : value === null ? value : undefined
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  const selectedOption =
    suggestions?.find((option) => isEqual(option.value, duration)) ||
    predefinedOptions?.find((option) => isEqual(option.value, duration))
  const selectedValue = {
    value: duration,
    label:
      (selectedOption?.label ||
        (duration &&
          formatDuration(intl, duration, {
            unitDisplay,
          }))) ??
      "",
  }
  // A frequency value is set but it is not one of the preset options
  const isCustomValue = Boolean(duration && !selectedOption)
  const [showCustomInputs, setShowCustomInputs] = useState(false)

  const options = useMemo(
    () => [
      {
        options: suggestions ?? [],
        label: intl.formatMessage({
          defaultMessage: "Extracted Options",
          description: "Label for extracted options",
          id: "components.Form.DurationInput.optgroup.extracted",
        }),
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Standard Options",
          description: "Label for predefined options",
          id: "components.Form.DurationInput.optgroup.standard",
        }),
        options:
          // Filter out any predefined options that are already in the suggestions
          [
            ...(predefinedOptions?.filter(
              (option) =>
                !suggestions?.some((suggestedOption) => option.value?.toString() === suggestedOption.value?.toString())
            ) ?? []),
            {},
          ],
      },
    ],
    [intl, predefinedOptions, suggestions]
  )

  const otherOptionMessage = intl.formatMessage({
    defaultMessage: "Other",
    description: "Label for the other option",
    id: "components.Form.DurationInput.other",
  })

  // If there are no predefined & suggested options, only use custom duration input
  if ((!suggestions || suggestions.length === 0) && (!predefinedOptions || predefinedOptions.length === 0)) {
    return <CustomDurationInput duration={duration} {...props} />
  }

  return (
    <Stack>
      <Flex alignItems="center" gap={1} flexBasis="min-content" flexGrow={0} {...rest}>
        <Select<PredefinedOption | OtherOption>
          ref={(select) => {
            inputRef.current = select?.inputRef ?? null
          }}
          openMenuOnFocus={true}
          value={selectedValue}
          options={options}
          isDisabled={isDisabled}
          placeholder={intl.formatMessage({
            id: "form.select.placeholder",
            defaultMessage: "Select an option...",
            description: "Placeholder for selection input",
          })}
          formatOptionLabel={(option, { context }) => {
            if (context === "menu") {
              return "label" in option ? (
                <OptionWithFieldSource fieldSources={option.field_sources}>{option.label}</OptionWithFieldSource>
              ) : (
                otherOptionMessage
              )
            }
            return showCustomInputs ? otherOptionMessage : "label" in option && option.label
          }}
          isOptionSelected={(option, [selectedOption]) =>
            selectedOption && "value" in selectedOption && "value" in option
              ? isEqual(option.value, selectedOption.value)
              : isCustomValue
          }
          isReadOnly={isReadOnly}
          isSearchable={false}
          onChange={(option) => {
            if (option && "value" in option) {
              const optionFieldSource = option.field_sources?.[0]
              onChange(option?.value ?? null, optionFieldSource)
              setShowCustomInputs(false)
            } else {
              setShowCustomInputs(true)
            }
          }}
          styles={{
            menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
          }}
          chakraStyles={{
            container: (styles) => ({ ...styles, flexGrow: 1, minWidth: "7em" }),
            option: (provided, { data }) => {
              const newOption =
                "field_sources" in data ? isNewOption(data.field_sources, fieldMetadata, fieldApproval) : undefined
              return {
                ...provided,
                ...(newOption?.isNew &&
                  newOption?.colorScheme && {
                    backgroundColor: `${newOption.colorScheme}.50`,
                  }),
              }
            },
          }}
          menuPortalTarget={document.body}
        />
      </Flex>
      {showCustomInputs && (
        <>
          <Text color="gray.700" fontSize="sm" fontWeight="medium">
            <FormattedMessage
              defaultMessage="Other (please specify)"
              description="Label for a custom duration input field"
              id="components.Form.DurationInput.other"
            />
          </Text>
          <CustomDurationInput duration={duration} {...props} />
        </>
      )}
    </Stack>
  )
})

const durationUnitOptions: UnitOption[] = DATE_DURATION_UNITS.map((unit) => ({ value: unit }))

export const CustomDurationInput = (
  props: Omit<DurationInputGroupProps, "value"> & {
    duration: Temporal.Duration | null | undefined
  }
) => {
  const intl = useIntl()
  const {
    duration,
    units,
    onChange,
    isReadOnly = false,
    isDisabled = false,
    predefinedOptions: _po,
    suggestions: _s,
    fieldMetadata: _fm,
    fieldApproval: _fa,
    ...rest
  } = props
  const selectedUnit = (duration && findUnitWithNonZeroValue(units, duration)) ?? null
  const numberFieldValue = selectedUnit && duration?.[`${selectedUnit}s`]
  return (
    <Flex alignItems="center" gap={1} flexBasis="min-content" flexGrow={0} {...rest}>
      <NumberInput
        isDisabled={isDisabled}
        value={numberFieldValue ?? ""}
        onChange={(_, valueAsNumber) => {
          if (isNaN(valueAsNumber)) {
            onChange(null)
            return
          }
          if (valueAsNumber < MIN || valueAsNumber > MAX) {
            return
          }
          if (selectedUnit) {
            onChange(Temporal.Duration.from({ [`${selectedUnit}s`]: valueAsNumber }))
          } else {
            onChange(Temporal.Duration.from({ months: valueAsNumber }))
          }
        }}
        isReadOnly={isReadOnly}
        step={1}
        min={0}
        max={MAX}
        flexGrow={0}
        flexBasis="6ch"
      >
        <NumberInputField
          p={1}
          textAlign="right"
          pr={8}
          inputMode="numeric"
          pattern="[0-9]*"
          minWidth="calc(2ch + var(--number-input-input-padding) + 0.5rem)"
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Select<UnitOption>
        // Only show the clear button if the input has a value
        isClearable={Boolean(duration)}
        value={{ value: selectedUnit }}
        options={durationUnitOptions}
        isDisabled={isDisabled}
        formatOptionLabel={(option) =>
          option.value
            ? capitalize(
                intl
                  .formatNumberToParts(numberFieldValue ?? 0, {
                    style: "unit",
                    unit: option.value,
                    unitDisplay,
                  })
                  .find((part) => part.type === "unit")?.value ?? ""
              )
            : null
        }
        isReadOnly={isReadOnly}
        isSearchable={false}
        onChange={(option) => {
          if (option) {
            onChange(Temporal.Duration.from({ [`${option.value}s`]: numberFieldValue ?? 1 }))
          } else {
            onChange(null)
          }
        }}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        chakraStyles={{
          container: (styles) => ({ ...styles, flexGrow: 1, minWidth: "7em" }),
        }}
        menuPortalTarget={document.body}
      />
    </Flex>
  )
}
