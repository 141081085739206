import type { ContainsFilter, ExistsFilter } from "@brm/schema-types/types.js"
import { Flex, Input } from "@chakra-ui/react"
import { useState } from "react"
import { isNotUndefined } from "typed-assert"
import { useDebouncedCallback } from "use-debounce"
import { NullCheckbox } from "../../../Form/NullCheckbox.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { FilterProps } from "./types.js"

export default function StringFilter({
  fieldSchema,
  displayPath,
  filter,
  isNullable,
  onChange,
}: FilterProps<ContainsFilter | ExistsFilter>) {
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")

  const [isNullChecked, setIsNullChecked] = useState(filter?.comparator === "exists" ? !filter.value : false)
  const [searchInput, setSearchInput] = useState(filter?.comparator === "contains" ? filter.value : "")

  const debouncedOnChange = useDebouncedCallback(onChange, 200)

  return (
    <Flex flexDir="column" gap={3} p={3} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC}>
      {isNullable && (
        <NullCheckbox
          fieldName={fieldName}
          fieldSchema={fieldSchema}
          isChecked={isNullChecked}
          onChange={({ target: { checked } }) => {
            setSearchInput("")
            setIsNullChecked(checked)
            if (checked) {
              onChange({ comparator: "exists", value: false })
            } else if (!searchInput) {
              onChange({ comparator: "exists", value: true })
            }
          }}
        />
      )}
      <Input
        value={searchInput}
        placeholder={fieldSchema.placeholder}
        autoFocus
        onChange={(e) => {
          const searchInput = e.target.value
          if (searchInput) {
            setIsNullChecked(false)
            debouncedOnChange({ comparator: "contains", value: searchInput })
          } else if (!isNullChecked) {
            debouncedOnChange({ comparator: "exists", value: true })
          }
          setSearchInput(searchInput)
        }}
      />
    </Flex>
  )
}
