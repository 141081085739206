import type { DocumentType } from "@brm/schema-types/types.js"
import type { IntlShape } from "react-intl"

export function geDocumentTypeLabels(intl: IntlShape): Record<DocumentType | "Unknown", string> {
  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    OrderForm: intl.formatMessage({
      defaultMessage: "Order Form",
      description: "The label for an order form document",
      id: "document.link.label.orderForm",
    }),
    MSA: intl.formatMessage({
      defaultMessage: "MSA",
      description: "The label for a MSA document",
      id: "document.link.label.msa",
    }),
    SLA: intl.formatMessage({
      defaultMessage: "SLA",
      description: "The label for an SLA document",
      id: "document.link.label.sla",
    }),
    NDA: intl.formatMessage({
      defaultMessage: "NDA",
      description: "The label for an NDA document",
      id: "document.link.label.nda",
    }),
    MNDA: intl.formatMessage({
      defaultMessage: "MNDA",
      description: "The label for an MNDA document",
      id: "document.link.label.mnda",
    }),
    BAA: intl.formatMessage({
      defaultMessage: "BAA",
      description: "The label for a BAA document",
      id: "document.link.label.baa",
    }),
    DPA: intl.formatMessage({
      defaultMessage: "DPA",
      description: "The label for a DPA document",
      id: "document.link.label.dpa",
    }),
    EULA: intl.formatMessage({
      defaultMessage: "EULA",
      description: "The label for an EULA document",
      id: "document.link.label.eula",
    }),
    SOW: intl.formatMessage({
      defaultMessage: "SOW",
      description: "The label for an SOW document",
      id: "document.link.label.sow",
    }),
    POC: intl.formatMessage({
      defaultMessage: "POC",
      description: "The label for a Proof of Concept document",
      id: "document.link.label.poc",
    }),
    TOS: intl.formatMessage({
      defaultMessage: "TOS",
      description: "The label for a Terms of Service document",
      id: "document.link.label.tos",
    }),
    PLA: intl.formatMessage({
      defaultMessage: "PLA",
      description: "The label for a Product Licensing Agreement document",
      id: "document.link.label.pla",
    }),
    Addendum: intl.formatMessage({
      defaultMessage: "Addendum",
      description: "The label for an Addendum document",
      id: "document.link.label.addendum",
    }),
    Quote: intl.formatMessage({
      defaultMessage: "Quote",
      description: "The label for a quote document",
      id: "document.link.label.quote",
    }),

    // null value
    Unknown: intl.formatMessage({
      defaultMessage: "Unknown",
      description: "The label for a document without a type",
      id: "document.link.label.other",
    }),
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
