import type { ButtonProps } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"

/**
 * Generic cancel button, e.g. for modals.
 */
export const CancelButton: FunctionComponent<ButtonProps> = (props) => (
  <Button type="button" {...props}>
    <FormattedMessage
      defaultMessage="Cancel"
      description="The label for a generic cancel button"
      id="button.cancel.label"
    />
  </Button>
)
