import type { Role } from "@brm/schema-types/types.js"

// We don't want to expose approver roles separately in the badge UI so we map them here
const roleToBadgeRole: Record<Role, Role> = {
  admin: "admin",
  compliance: "compliance",
  compliance_approver: "compliance",
  finance: "finance",
  finance_approver: "finance",
  it: "it",
  it_approver: "it",
  legal: "legal",
  legal_approver: "legal",
  super_admin: "super_admin",
}

export const getRolesToShowFromRoles = (roles: Array<Role>): Array<Role | "user"> => {
  const rolesToShow = Array.from(new Set(roles.map((role) => roleToBadgeRole[role])))
  return rolesToShow.length === 0 ? ["user"] : rolesToShow
}
