import { isPendingDocumentExtractionStatus } from "@brm/schema-helpers/document.js"
import { getLegalAgreementVerificationStatusSchema, legalAgreementOwnerIds } from "@brm/schema-helpers/legal.js"
import { hasPermission } from "@brm/schema-helpers/role.js"
import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import type {
  DocumentMinimal,
  DocumentWithExtraction,
  FieldMetadata,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  LegalAgreementDetails,
  LegalAgreementInput,
  LegalAgreementListItem,
  LegalAgreementMinimal,
  LegalClausesFieldsMetadata,
  ToolOption,
  ToolWithStandardObjects,
  UserWithOrganization,
  Vendor,
  VendorOption,
} from "@brm/schema-types/types.js"
import { FieldSourceInputPropertiesSchema } from "@brm/schemas"
import { formatDate } from "@brm/util/format-date-time.js"
import { getDiscriminatorSchema, getTitle } from "@brm/util/schema.js"
import { safeTrim } from "@brm/util/string.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  Badge,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  type MenuItemProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import { excludeKeys } from "filter-obj"
import { useCallback, useEffect, useMemo, useState, type FunctionComponent, type ReactNode } from "react"
import type { Control, FieldPath, Path, SetValueConfig } from "react-hook-form"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { Location } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { isPresent } from "ts-is-present"
import type { Except } from "type-fest"
import { isNotUndefined } from "typed-assert"
import zIndices from "../../../../../packages/theme/src/foundations/z-index.js"
import {
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery,
  useGetUserV1WhoamiQuery,
  useLazyGetLegalV1AgreementsByIdQuery,
  usePostLegalV1AgreementsByIdVerificationMutation,
  usePostLegalV1AgreementsListQuery,
  usePostLegalV1AgreementsMutation,
} from "../../../app/services/generated-api.js"
import { useContextMenu } from "../../../components/ContextMenu/context-menu.js"
import UserWithProviderBadge from "../../../components/DataTable/CellRenderer/UserWithProviderBadge.js"
import { DocumentViewerWithHighlighting } from "../../../components/Document/DocumentViewerWithHighlighting.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import { DynamicFormField, type DynamicFormProps } from "../../../components/SchemaForm/DynamicForm.js"
import { FieldSourceFooter } from "../../../components/SchemaForm/FieldSourceFooter.js"
import { getDefaultUserFieldSource } from "../../../components/SchemaForm/utils.js"
import { Timestamp } from "../../../components/Timestamp.js"
import {
  BackIcon,
  CheckIcon,
  EditIcon,
  MoreMenuIcon,
  NextIcon,
  TrashIcon,
  TwoStarsIcon,
} from "../../../components/icons/icons.js"
import { ownDocumentDownloadUrl } from "../../../util/document.js"
import { StatusCodes, getAPIErrorMessage, getAPIErrorStatusCode } from "../../../util/error.js"
import { initializeReactHookFromState } from "../../../util/form.js"
import { log } from "../../../util/logger.js"
import { packageSortFilterOptionsForAPI } from "../../../util/schema-table.js"
import { useObjectInputSchema, useObjectSchema } from "../../../util/use-schema.js"
import { useDocumentVisibility } from "../../../util/visibility.js"
import { AgreementListSidebar } from "../AgreementListSidebar.js"
import { DeleteLegalAgreementModal } from "../DeleteLegalAgreementModal.js"
import type { AssociatedDocumentsFormState } from "./AssociatedDocuments.js"
import { AssociatedDocuments } from "./AssociatedDocuments.js"
import AssociatedTransactions from "./AssociatedTransactions.js"

export interface FullAccessLegalAgreement
  extends Required<LegalAgreementMinimal>,
    Pick<LegalAgreementDetails, "email_source" | "transactions"> {
  documents: Required<DocumentWithExtraction>[]
  tools: ToolWithStandardObjects[]
  vendor: Vendor | null
}
export interface LegalAgreementEditorDrawerContentDrawerProps {
  vendor?: VendorOption
  tool?: ToolOption
  openRecordUploadModal?: boolean
}

const getFieldSourceLink = (location: Location, fieldSource: FieldMetadata, legalAgreementId?: string) => {
  const hashParams = new URLSearchParams(location.hash.slice(1))
  switch (fieldSource.type) {
    case "document":
      hashParams.set("document", fieldSource.id ?? "")
      hashParams.set("agreement", legalAgreementId ?? "")
      return {
        search: location.pathname === "/agreements" ? location.search : undefined,
        hash: `#${hashParams}`,
      }
    case "transaction":
      hashParams.set("transaction", fieldSource.id ?? "")
      hashParams.set("agreement", legalAgreementId ?? "")
      return {
        search: location.pathname === "/agreements" ? location.search : undefined,
        hash: `#${hashParams}`,
      }
    case "user":
      if (fieldSource.assigned_by_metadata?.object_field_source) {
        hashParams.set(
          fieldSource.assigned_by_metadata.object_field_source.type,
          fieldSource.assigned_by_metadata.object_field_source.id
        )
        hashParams.set("agreement", legalAgreementId ?? "")
        return {
          search: location.pathname === "/agreements" ? location.search : undefined,
          hash: `#${hashParams}`,
        }
      }
      return undefined
    default:
      return undefined
  }
}

/**
 * This component can be used in any page where a legal agreement is being edited or created by checking the hash params of the page.
 * If the hash params contain an `agreement` key, the component will render the legal agreement editor.
 *
 * This component also handles the conditional rendering of the drawer and legalAgreement data fetching so LegalAgreementEditor itself
 * does not need to be conditionally rendered in the parent component.
 */
export const LegalAgreementEditor: FunctionComponent<LegalAgreementEditorDrawerContentDrawerProps> = ({
  vendor,
  tool,
  openRecordUploadModal,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const hashParams = new URLSearchParams(location.hash.slice(1))
  const legalAgreementId = hashParams.get("agreement")
  const documentId = hashParams.get("document")
  const transactionId = hashParams.get("transaction")

  // If hashParams does not have an agreement, we should not render anything
  if (legalAgreementId === null) {
    return null
  }

  const onClose = () => {
    hashParams.delete("agreement")
    hashParams.delete("document")
    hashParams.delete("transaction")
    navigate({ search: location.search, hash: `#${hashParams}` })
  }

  // The api fetches must be done in a separate component so that the onClose resets all api state.
  return (
    <LegalAgreementEditorDrawer
      vendor={vendor}
      tool={tool}
      openRecordUploadModal={openRecordUploadModal}
      // Ensure that empty string gets converted to null
      legalAgreementId={legalAgreementId || null}
      documentId={documentId || null}
      transactionId={transactionId || null}
      onClose={onClose}
    />
  )
}

/**
 * Given there exists legal agreement in the hash params, this component will fetch and render the legal agreement editor drawer.
 * Or render a create legal agreement drawer if there is no existing legalAgreementId.
 */
const LegalAgreementEditorDrawer: FunctionComponent<
  LegalAgreementEditorDrawerContentDrawerProps & {
    legalAgreementId: string | null
    documentId: string | null
    transactionId: string | null
    onClose: () => void
  }
> = ({ vendor, tool, openRecordUploadModal, legalAgreementId: urlAgreementId, documentId, transactionId, onClose }) => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const intl = useIntl()

  const [fetchAgreement, { data: legalAgreement, isError, error }] = useLazyGetLegalV1AgreementsByIdQuery()
  const transaction =
    legalAgreement?.transactions?.find((transaction) => transaction.id === transactionId) ||
    legalAgreement?.transactions?.[0]
  const currentDocument =
    (legalAgreement?.agreement_type === "subscription"
      ? transaction?.receipts[0]?.document
      : (legalAgreement?.documents.find((d) => d.id === documentId) ?? legalAgreement?.documents[0])) ?? undefined

  const legalAgreementId = urlAgreementId || legalAgreement?.id
  const documentUrlResult = useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery(
    currentDocument && legalAgreementId ? { documentId: currentDocument.id, id: legalAgreementId } : skipToken
  )
  const receiptUrlResult = useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery(
    transaction?.id && transaction.receipts[0]
      ? { id: transaction.id, receiptId: transaction?.receipts[0]?.id }
      : skipToken
  )
  const downloadUrl =
    legalAgreement?.agreement_type === "subscription"
      ? receiptUrlResult.data?.download_url
      : documentUrlResult.data?.download_url

  useEffect(() => {
    async function fetchData() {
      if (legalAgreementId) {
        await fetchAgreement({ id: legalAgreementId })
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [fetchAgreement, legalAgreementId])

  return (
    <Drawer size="full" isOpen={true} onClose={onClose} blockScrollOnMount={false} autoFocus={false}>
      <DrawerOverlay zIndex="docked" />
      {isError && (
        <DrawerContent flexDirection="column">
          <Alert status="error">
            <AlertDescription>
              {getAPIErrorStatusCode(error) === StatusCodes.FORBIDDEN ? (
                <FormattedMessage
                  defaultMessage="You do not have permissions to read this legal agreement."
                  description="Error message when user lacks permissions to read this legal agreement"
                  id="legalAgreementEditorDrawer.noPermissions"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Something went wrong loading the legal agreement."
                  description="Error message when legal agreement fails to load"
                  id="legalAgreementEditorDrawer.loadError"
                />
              )}
            </AlertDescription>
          </Alert>
        </DrawerContent>
      )}
      {whoami && (!legalAgreementId || legalAgreement) && (
        <LegalAgreementEditorDrawerContent
          assignToCriteriaFieldSource={{
            type: transaction ? "transaction" : "document",
            id: transaction?.id ?? currentDocument?.id,
            source_display_name: transaction
              ? `${transaction?.merchant_identification} ${transaction?.transacted_at && formatDate(intl, Temporal.PlainDate.from(transaction.transacted_at))}`
              : (currentDocument?.file_name ?? undefined),
          }}
          legalAgreement={legalAgreement as FullAccessLegalAgreement | undefined}
          tool={tool}
          whoami={whoami}
          vendor={vendor}
          openRecordUploadModal={openRecordUploadModal}
          onClose={onClose}
          currentDocument={currentDocument}
          refetchAgreement={fetchAgreement}
          downloadUrl={downloadUrl}
        />
      )}
    </Drawer>
  )
}

// This is a separate component so that it can receive the fetched `legalAgreement` as opposed to just the ID and doesn't have to deal with data fetching.
const LegalAgreementEditorDrawerContent: FunctionComponent<{
  /** Used for filling the tool when in the context of a tool page. */
  tool?: ToolOption
  /** Used for filling the vendor when in the context of a vendor page. */
  vendor?: VendorOption
  /** Decides over whether edit or add mode is rendered. */
  legalAgreement?: FullAccessLegalAgreement
  whoami: UserWithOrganization
  openRecordUploadModal?: boolean
  /** Document to view in document viewer */
  currentDocument?: DocumentWithExtraction
  /** Object type used for assigning criteria field source. */
  assignToCriteriaFieldSource?: FieldSourceOutputProperties
  /** Document url for document viewer */
  downloadUrl?: string
  refetchAgreement: (args: { id: string }) => void
  onClose: () => void
}> = ({
  legalAgreement,
  onClose,
  whoami,
  currentDocument,
  downloadUrl,
  refetchAgreement,
  tool,
  vendor,
  assignToCriteriaFieldSource,
}) => {
  const toast = useToast()
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const documentVisibility = useDocumentVisibility()
  const legalAgreementSchema = useObjectSchema("LegalAgreement")
  const apiParams = useMemo(
    () =>
      legalAgreementSchema &&
      packageSortFilterOptionsForAPI<string>(
        {
          sorting: [{ id: "verification_status", desc: false }],
          sortingColumns: ["verification_status"],
          page: 1,
          pageSize: 100,
          filterMap: new Map([
            [
              "verification_status",
              {
                column: "verification_status",
                fields: { comparator: "any", includeNull: false, values: ["in_review", "draft"] },
              },
            ],
          ]),
          savedViewState: {},
        },
        legalAgreementSchema,
        intl
      ),
    [legalAgreementSchema, intl]
  )

  const { data: pendingAgreements } = usePostLegalV1AgreementsListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken,
    {
      pollingInterval: documentVisibility === "visible" ? 5000 : undefined,
    }
  )

  const [createLegalAgreement] = usePostLegalV1AgreementsMutation()
  const [verifyLegalAgreement] = usePostLegalV1AgreementsByIdVerificationMutation()
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])

  const [activeTab, setActiveTab] = useState(0)
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(legalAgreement?.display_name ? false : true)
  const [selectedProvenancePath, setSelectedProvenancePath] = useState<(string | number)[]>()
  const [deleteMessage, setDeleteMessage] = useState<string | undefined>()
  const [nextAgreement, setNextAgreement] = useState<LegalAgreementListItem | undefined>()

  const form = useForm<LegalAgreementInput>({
    defaultValues:
      legalAgreement ??
      ({
        object_type: "LegalAgreement",
        display_name: "",
        agreement_type: "enterprise",
        tools: [tool].filter(isPresent),
        vendor,
        documents: [],
        fields_metadata: {},
        custom: {},
      } satisfies LegalAgreementInput),
    shouldFocusError: false,
  })

  const pathString = selectedProvenancePath?.join(".")
  const verificationStatusSchema = getLegalAgreementVerificationStatusSchema(legalAgreement?.verification_status)
  const agreementsIndex = pendingAgreements?.items.findIndex((agreement) => agreement.id === legalAgreement?.id) ?? -1

  useEffect(() => {
    if (legalAgreement) {
      incrementUncontrolledComponentResetId()
      form.reset(legalAgreement)
      setIsEditingDisplayName(false)
    }
  }, [legalAgreement, form, incrementUncontrolledComponentResetId])

  useEffect(() => {
    // Poll for document status updates every second
    const interval = setInterval(async () => {
      if (
        document.visibilityState !== "hidden" &&
        legalAgreement &&
        legalAgreement.documents.find((document) => isPendingDocumentExtractionStatus(document.extraction_status))
      ) {
        refetchAgreement({ id: legalAgreement.id })
      }
    }, 5000)
    return () => clearTimeout(interval)
  }, [legalAgreement, refetchAgreement])

  initializeReactHookFromState(form)
  const rootSchema = useObjectInputSchema("LegalAgreement")
  const discriminatedRootSchema = getDiscriminatorSchema(rootSchema, form.watch())

  const legalFormFields = useMemo(
    () => discriminatedRootSchema && schemaToFormFields(discriminatedRootSchema, "LegalAgreement", "all"),
    [discriminatedRootSchema]
  )

  const getValue = useCallback((path: string) => form.watch((path || undefined) as Path<LegalAgreementInput>), [form])
  // setValue is expecting a path from the shape of the formState. We could make SchemaForm generically typed
  // to support this, but due to the dynamic nature of the form generation we will keep as is for now.
  const setValue = form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void
  const toolVendorOwners = useMemo(() => {
    if (!legalAgreement) {
      return new Set<string>()
    }
    return legalAgreementOwnerIds(legalAgreement)
  }, [legalAgreement])

  const deleteLegalAgreementModal = useDisclosure()

  const onProvenanceClick = useCallback(
    (provenancePath: (number | string)[], fieldSource?: FieldSourceOutputProperties) => {
      if (!fieldSource || !("id" in fieldSource)) {
        return
      }
      const destinationLink = getFieldSourceLink(location, fieldSource, legalAgreement?.id)
      if (destinationLink) {
        navigate(destinationLink)
      }

      setSelectedProvenancePath(provenancePath)
    },
    [legalAgreement?.id, location, navigate]
  )

  const renderFieldSource = useCallback(
    (path: (string | number)[], fieldSource?: FieldSourceOutputProperties): ReactNode => {
      if (!fieldSource) {
        return null
      }

      return (
        <FieldSourceFooter
          fieldSource={fieldSource}
          rootSchema={rootSchema}
          onClick={() => onProvenanceClick(path, fieldSource)}
        />
      )
    },
    [onProvenanceClick, rootSchema]
  )

  // We cannot directly pass in the form state to the api because there are certain fields that need to be formatted before sending to the api
  const formatInputForApi = (values: LegalAgreementInput): LegalAgreementInput => {
    return {
      ...values,
      display_name: values.display_name.trim(),
      // Mark any field that was edited as now coming from "user" input as opposed to extraction, but preserve
      // all previous source metadata for all other fields.
      fields_metadata: {
        ...values.fields_metadata,
      },
      ...(values.agreement_type === "enterprise"
        ? {
            buyer_signer_name: safeTrim(values.buyer_signer_name),
            buyer_signer_title: safeTrim(values.buyer_signer_title),
            vendor_signer_name: safeTrim(values.vendor_signer_name),
            vendor_signer_title: safeTrim(values.vendor_signer_title),
          }
        : {}),
      auto_renewal_opt_out_period: values.auto_renews ? values.auto_renewal_opt_out_period : undefined,
      start_date: safeTrim(values.start_date),
      end_date: safeTrim(values.end_date),
      first_invoice_date: safeTrim(values.first_invoice_date),
    }
  }

  const onVerifyField = useCallback(
    async (fieldPath: (string | number)[] | undefined, values: LegalAgreementInput) => {
      if (!legalAgreement) {
        return
      }
      const legalAgreementInput = formatInputForApi(values)
      try {
        const updatedAgreement = await verifyLegalAgreement({
          id: legalAgreement.id,
          agreementVerificationRequest: { input: legalAgreementInput, field_path: fieldPath },
        }).unwrap()
        refetchAgreement({ id: legalAgreement.id })
        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Legal agreement saved successfully"
              description="The toast description for a successful save of a legal agreement"
              id="legalAgreement.modal.toast.success"
            />
          ),
        })
        if (updatedAgreement.verification_status === "verified") {
          setNextAgreement(
            pendingAgreements && pendingAgreements.total > 1 && agreementsIndex !== -1
              ? pendingAgreements.items[(agreementsIndex + 1) % pendingAgreements.total]
              : undefined
          )
        }
      } catch (err) {
        log.error("Error saving legal agreement", err, { legalAgreement: legalAgreementInput })
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred saving the legal agreement"
              description="The toast description for an error when saving a legal agreement"
              id="legalAgreement.modal.toast.error"
            />
          ),
        })
      }
    },
    [legalAgreement, verifyLegalAgreement, refetchAgreement, toast, pendingAgreements, agreementsIndex]
  )

  const onDisplayNameSave = useCallback(async () => {
    if (legalAgreement) {
      const displayNameFieldProps = getValue("fields_metadata.display_name") as FieldMetadata
      await onVerifyField(["display_name"], {
        ...(form.formState.defaultValues as LegalAgreementInput),
        agreement_type: legalAgreement.agreement_type,
        display_name: getValue("display_name") as string,
        fields_metadata: {
          display_name: { ...displayNameFieldProps, ...getDefaultUserFieldSource(intl, whoami) },
        },
      })
      setIsEditingDisplayName(false)
    }
  }, [form.formState.defaultValues, getValue, legalAgreement, onVerifyField, intl, whoami])

  const onDiscard = useCallback(() => {
    form.reset()
    onClose()
  }, [form, onClose])

  const getDocumentDownloadURL = useCallback(
    (path: (string | number)[], document: DocumentMinimal) => {
      // If the legal agreement is new or the document was just changed, we can access the documents through the owned-documents route (since the user would have just uploaded them)
      if (!legalAgreement || form.getFieldState(path.join(".") as FieldPath<LegalAgreementInput>).isDirty) {
        return ownDocumentDownloadUrl(document)
      }
      // Else access them through the legal agreement ID
      return new URL(
        `/legal/v1/agreements/${legalAgreement.id}/${path.join("/")}/content`,
        import.meta.env.VITE_API_BASE_URL
      ).href
    },
    [form, legalAgreement]
  )
  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps } = useContextMenu<HTMLDivElement>({
    betsyEnabled: true,
  })

  const menuActions = useCallback(
    (additionalProps?: MenuItemProps) => (
      <>
        <MenuItem
          key="rename-agreement"
          icon={<Icon as={EditIcon} />}
          iconSpacing={0}
          onClick={() => setIsEditingDisplayName(true)}
          {...additionalProps}
        >
          <FormattedMessage
            defaultMessage="Rename agreement"
            description="The label for the rename legal agreement option in the options menu"
            id="legalAgreement.modal.options.rename"
          />
        </MenuItem>
        {legalAgreement && (toolVendorOwners.has(whoami.id) || hasPermission(whoami.roles, "legal:delete")) && (
          <MenuItem
            key="delete-agreement"
            icon={<Icon as={TrashIcon} />}
            iconSpacing={0}
            onClick={deleteLegalAgreementModal.onOpen}
            {...additionalProps}
          >
            <FormattedMessage
              defaultMessage="Delete agreement"
              description="The label for the delete legal agreement option in the options menu"
              id="legalAgreement.modal.options.delete"
            />
          </MenuItem>
        )}
      </>
    ),

    [legalAgreement, toolVendorOwners, whoami, setIsEditingDisplayName, deleteLegalAgreementModal]
  )

  const legalFormProps: Except<DynamicFormProps, "path" | "formField" | "rootBaseSchema"> = {
    control: form.control,
    getValue,
    setValue,
    resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
    isReadOnly: false,
    uncontrolledComponentResetId,
    incrementUncontrolledComponentResetId,
    renderFieldSource,
    format: "compact",
    onProvenanceClick,
    getDocumentDownloadUrl: getDocumentDownloadURL,
    onVerifyField: legalAgreement
      ? (field: (number | string)[] | undefined) => onVerifyField(field, form.getValues())
      : undefined,
  }

  const contextMenuActions = useMemo(() => menuActions(menuItemProps), [menuActions, menuItemProps])

  return (
    <DrawerContent
      {...subjectProps.baseProps}
      // Disabling animation because in Chrome it causes the footer buttons to occasionally disappear.
      motionProps={{
        initial: "none",
        animate: "none",
        exit: "none",
      }}
      flexDirection="row"
      justifyContent="space-between"
      zIndex="docked"
      containerProps={{
        zIndex: zIndices.docked,
      }}
    >
      <Menu {...menuProps}>
        <MenuList zIndex={zIndices.docked} {...menuListProps}>
          {menuListProps.children}
          {contextMenuActions}
        </MenuList>
      </Menu>

      {betsyProps?.BetsyModal}
      {location.pathname.startsWith("/agreements") && (
        <AgreementListSidebar selectedAgreementId={legalAgreement?.id} agreementList={pendingAgreements} />
      )}
      <Flex flexGrow={1} height="100%" align="center" justifyContent="center" shadow="xl">
        <DocumentViewerWithHighlighting
          requestingEntity={{
            object_type: "LegalAgreement",
            object_id: legalAgreement?.id,
          }}
          document={currentDocument}
          menuActions={contextMenuActions}
          fieldMetadata={
            selectedProvenancePath &&
            (getValue(["fields_metadata", ...selectedProvenancePath].join(".")) as FieldMetadataWithSuggestions)
          }
          path={selectedProvenancePath}
          onAssignToCriteriaSuccess={(path, newVal, source) => {
            setValue(path.join("."), newVal)
            const existingFieldMetadata = excludeKeys(
              getValue(["fields_metadata", ...path].join(".")) || {},
              (k) => k in FieldSourceInputPropertiesSchema.properties
            )
            if (
              assignToCriteriaFieldSource?.id &&
              (assignToCriteriaFieldSource.type === "document" || assignToCriteriaFieldSource?.type === "transaction")
            ) {
              // Set assigned by metadata
              if (path.includes("clauses")) {
                setValue(["fields_metadata", ...path].join("."), {
                  ...existingFieldMetadata,
                  ...getDefaultUserFieldSource(intl, whoami),
                  assigned_by_metadata: {
                    source,
                    object_field_source: {
                      id: assignToCriteriaFieldSource.id,
                      type: assignToCriteriaFieldSource.type,
                      source_display_name: assignToCriteriaFieldSource.source_display_name,
                    },
                  },
                } satisfies FieldMetadataWithSuggestions)
                const clausesMetadata = excludeKeys(
                  (getValue(["fields_metadata", ...path.slice(0, -1)].toString()) || {}) as LegalClausesFieldsMetadata,
                  Array.from(Object.keys(FieldSourceInputPropertiesSchema.properties))
                )
                setValue(["fields_metadata", "clauses"].join("."), {
                  ...clausesMetadata,
                  verified: false,
                  updated_at: Temporal.Now.instant().toString(),
                } satisfies FieldMetadataWithSuggestions)
              } else {
                setValue(["fields_metadata", ...path].join("."), {
                  ...existingFieldMetadata,
                  ...getDefaultUserFieldSource(intl, whoami),
                  verified: false,
                  assigned_by_metadata: {
                    source,
                    object_field_source: {
                      id: assignToCriteriaFieldSource.id,
                      type: assignToCriteriaFieldSource.type,
                      source_display_name: assignToCriteriaFieldSource.source_display_name,
                    },
                  },
                } satisfies FieldMetadataWithSuggestions)
              }
            }
            incrementUncontrolledComponentResetId()
          }}
          value={pathString && getValue(pathString)}
          rootSchema={rootSchema}
          downloadUrl={downloadUrl}
          onAddDocument={() => setActiveTab(1)}
          closeButton={
            <IconButtonWithTooltip
              icon={<Icon as={BackIcon} />}
              onClick={onDiscard}
              variant="ghost"
              label={intl.formatMessage({
                defaultMessage: "Back",
                id: "documentViewer.backButton.tooltip",
                description: "label for previous page button in document viewer toolbar",
              })}
            />
          }
        />
      </Flex>
      <Flex
        as="form"
        maxWidth="lg"
        minWidth={0}
        height="100%"
        flexDirection="column"
        width="100%"
        noValidate
        onSubmit={form.handleSubmit(async (values: LegalAgreementInput) => {
          setActiveTab(0)
          const legalAgreementInput = formatInputForApi(values)
          try {
            const createdAgreement = await createLegalAgreement({ legalAgreementInput }).unwrap()
            refetchAgreement({ id: createdAgreement.id })
            hashParams.set("agreement", createdAgreement.id)
            navigate(`#${hashParams}`)
            toast({
              status: "success",
              description: (
                <FormattedMessage
                  defaultMessage="Legal agreement saved successfully"
                  description="The toast description for a successful save of a legal agreement"
                  id="legalAgreement.modal.toast.success"
                />
              ),
            })
          } catch (err) {
            log.error("Error creating legal agreement", err, { legalAgreement: legalAgreementInput })
          }
        })}
      >
        <DrawerHeader>
          <HStack alignItems="start" justifyContent="space-between">
            <Stack flexGrow={1}>
              <HStack align="center">
                {isEditingDisplayName ? (
                  <>
                    <Controller
                      rules={{
                        validate: (value) => {
                          if (isEmpty(value.trim())) {
                            return intl.formatMessage({
                              defaultMessage: "Please enter a display name",
                              description: "Error message for display name required field",
                              id: "form.error.display_name.required",
                            })
                          }
                          return true
                        },
                      }}
                      control={form.control}
                      name="display_name"
                      render={({ field, fieldState }) => (
                        <FormControl as="fieldset" isInvalid={fieldState.invalid} isRequired>
                          <Input
                            autoFocus
                            width="full"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={intl.formatMessage({
                              defaultMessage: "Enter Agreement name",
                              id: "legalAgreement.modal.displayName.input",
                              description: "The placeholder for the agreement name input",
                            })}
                            onBlur={onDisplayNameSave}
                          />
                          <FormErrorMessage fontWeight="normal">{fieldState.error?.message}</FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    {/* Only edit mode needs check button, other wise display name will be saved along with rest of form */}
                    {legalAgreement && (
                      <IconButton
                        icon={<Icon as={CheckIcon} color="success.600" />}
                        aria-label={intl.formatMessage({
                          defaultMessage: "Save agreement name",
                          id: "legalAgreement.modal.displayName.save",
                          description: "The aria-label for the save button for the agreement name",
                        })}
                        onClick={onDisplayNameSave}
                        variant="ghost"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Heading
                      fontWeight="semibold"
                      fontSize="lg"
                      isTruncated
                      textOverflow="ellipsis"
                      overflow="hidden"
                      maxW="270px"
                    >
                      {getValue("display_name") as string}
                    </Heading>
                    {legalAgreement?.verification_status && (
                      <Badge variant="subtleOutlined" size="md" colorScheme={verificationStatusSchema?.colorScheme}>
                        {getTitle(legalAgreement.verification_status, verificationStatusSchema)}
                      </Badge>
                    )}
                  </>
                )}
              </HStack>

              {legalAgreement && legalAgreement.updated_at && (
                <Text fontSize="xs" fontWeight="light">
                  <FormattedMessage
                    id="legalAgreement.modal.lastUpdated"
                    description="Last updated timestamp for legal agreement"
                    defaultMessage="Last saved {timestamp}"
                    values={{ timestamp: <Timestamp dateTime={legalAgreement.updated_at} /> }}
                  />
                </Text>
              )}
            </Stack>
            {legalAgreement?.email_source?.map((userWithProvider) => (
              <UserWithProviderBadge key={userWithProvider.id} user={userWithProvider} />
            ))}
            <HStack>
              {!isEditingDisplayName && (
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Agreement options button",
                      description: "The icon label for a button to open the options menu",
                      id: "legalAgreement.modal.options.iconButton",
                    })}
                    size="sm"
                    icon={<Icon as={MoreMenuIcon} />}
                    variant="ghost"
                  />
                  <MenuList fontSize="sm">{menuActions()}</MenuList>
                </Menu>
              )}
            </HStack>
          </HStack>
        </DrawerHeader>
        <DrawerBody
          as={Stack}
          direction="column"
          spacing={6}
          divider={<StackDivider />}
          style={{ scrollbarWidth: "none" }}
        >
          {discriminatedRootSchema && (
            <>
              {currentDocument?.summary && (
                <Accordion allowToggle defaultIndex={0} marginBottom={2}>
                  <AccordionItem border={0}>
                    <h2>
                      <AccordionButton
                        flex={1}
                        justifyContent="space-between"
                        paddingX={0}
                        _hover={{ backgroundColor: "white" }}
                      >
                        <Flex gap={1} justifyContent="start">
                          <FormattedMessage
                            defaultMessage="Document Summary"
                            description="The title for the agreement executive summary section"
                            id="legalAgreement.modal.summary.title"
                          />
                          <Icon as={TwoStarsIcon} color="purple.700" />
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderWidth={1}
                      borderColor="gray.200"
                      borderRadius="md"
                      backgroundColor="gray.50"
                      padding={2}
                    >
                      <RenderedMarkdown content={currentDocument.summary} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
              <Tabs index={activeTab} onChange={(i) => setActiveTab(i)}>
                <TabList justifyContent="space-around" color="gray.500">
                  <Tab fontWeight="semibold" width="50%">
                    <FormattedMessage
                      defaultMessage="Agreement Criteria"
                      description="The tab title for agreement criteria"
                      id="legalAgreement.modal.tab.title.criteria"
                    />
                  </Tab>
                  <Tab fontWeight="semibold" width="50%" flexDirection="row" gap={2}>
                    {legalAgreement?.agreement_type === "subscription" ? (
                      <FormattedMessage
                        defaultMessage="Transactions"
                        description="The tab title for associated transactions"
                        id="legalAgreement.modal.tab.title.transactions"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Documents"
                        description="The tab title for associated documents"
                        id="legalAgreement.modal.tab.title.documents"
                      />
                    )}

                    <Badge
                      colorScheme={activeTab === 1 ? "green" : "gray"}
                      size="sm"
                      variant="subtleOutlined"
                      borderRadius="full"
                      borderColor={!activeTab ? "gray.200" : undefined}
                    >
                      {/* Only show count if document is displayable */}
                      {legalAgreement?.agreement_type === "subscription"
                        ? (legalAgreement.transactions?.length ?? 0)
                        : form.getValues().documents.length}
                    </Badge>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel paddingX={0}>
                    {legalFormFields && (
                      <Stack>
                        {[...legalFormFields.standard, ...legalFormFields.custom].map((field) => {
                          const path = field.is_custom ? ["custom", field.field_name] : [field.field_name]
                          return (
                            <DynamicFormField
                              {...legalFormProps}
                              key={path.join(".")}
                              formField={field}
                              path={path}
                              rootBaseSchema={discriminatedRootSchema}
                              isActive={selectedProvenancePath?.join(".").includes(path.join("."))}
                            />
                          )
                        })}
                      </Stack>
                    )}
                  </TabPanel>
                  <TabPanel paddingX={0}>
                    {legalAgreement?.agreement_type === "subscription" ? (
                      <AssociatedTransactions
                        transactions={legalAgreement.transactions}
                        legalAgreementId={legalAgreement.id}
                      />
                    ) : (
                      <AssociatedDocuments
                        control={form.control as unknown as Control<AssociatedDocumentsFormState>}
                        legalAgreementId={legalAgreement?.id}
                        legalAgreementInput={formatInputForApi(form.getValues())}
                        onDocumentAdded={(extractionResponse) => {
                          if (!extractionResponse.agreement_id) {
                            return
                          }
                          refetchAgreement({ id: extractionResponse.agreement_id })
                        }}
                        submitDocuments={async () => {
                          isNotUndefined(legalAgreement, "Legal agreement must be defined to remove a document")
                          await onVerifyField(["documents"], {
                            ...(form.formState.defaultValues as LegalAgreementInput),
                            agreement_type: legalAgreement.agreement_type,
                            documents: form.getValues().documents,
                          })
                        }}
                        onLastDocumentRemoved={async () => {
                          setDeleteMessage(
                            intl.formatMessage({
                              id: "legalAgreement.modal.deleteLast.document.message",
                              defaultMessage:
                                "You have removed the last document from this agreement. Do you want to delete the agreement as well? It cannot be restored.",
                              description:
                                "Error message shown when the user tries to delete an agreement with only one document",
                            })
                          )
                          deleteLegalAgreementModal.onOpen()
                        }}
                      />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        </DrawerBody>
        {(legalAgreement?.verification_status !== "verified" || nextAgreement) && (
          <DrawerFooter gap={2} justifyContent="space-between" flexDirection="row-reverse">
            {legalAgreement?.verification_status === "verified" ? (
              <Flex gap={2} fontWeight="semibold" color="brand.700">
                <FormattedMessage
                  id="legalAgreement.modal.verification.message"
                  defaultMessage="All criteria accepted"
                  description="Message shown when the legal agreement has been verified"
                />
                <Icon as={CheckIcon} />
              </Flex>
            ) : legalAgreement ? (
              <Button
                colorScheme="brand"
                onClick={() => onVerifyField(undefined, form.getValues())}
                rightIcon={<Icon as={CheckIcon} />}
              >
                {intl.formatMessage({
                  id: "legalAgreement.modal.acceptAll.button",
                  defaultMessage: "Accept all",
                  description: "Button to accept all criteria",
                })}
              </Button>
            ) : (
              <Button colorScheme="brand" gap={2} type="submit">
                {intl.formatMessage({
                  id: "legalAgreement.modal.create.button",
                  defaultMessage: "Create agreement",
                  description: "Button to create an agreement",
                })}
              </Button>
            )}
            {legalAgreement?.verification_status === "verified" &&
              (nextAgreement ? (
                <Button
                  gap={2}
                  onClick={() => {
                    hashParams.set("agreement", nextAgreement.id)
                    navigate(`#${hashParams}`)
                    setNextAgreement(undefined)
                  }}
                  variant="outline"
                >
                  {intl.formatMessage({
                    id: "legalAgreement.modal.nextAgreement.button",
                    defaultMessage: "Next agreement",
                    description: "Button to navigate to the next legal agreement",
                  })}
                  <Icon as={NextIcon} />
                </Button>
              ) : (
                <Button onClick={onClose} variant="outline">
                  {intl.formatMessage({
                    id: "legalAgreement.modal.close.button",
                    defaultMessage: "Close",
                    description: "Button to close the legal agreement modal",
                  })}
                </Button>
              ))}
          </DrawerFooter>
        )}
      </Flex>
      {legalAgreement && (
        <DeleteLegalAgreementModal
          {...deleteLegalAgreementModal}
          legalAgreementBeingDeleted={{ id: legalAgreement.id }}
          deletionMessage={deleteMessage}
          onCancel={() => {
            setDeleteMessage(undefined)
            deleteLegalAgreementModal.onClose()
          }}
          onDelete={() => {
            setDeleteMessage(undefined)
            deleteLegalAgreementModal.onClose()
            onClose()
          }}
        />
      )}
    </DrawerContent>
  )
}
