import { Flex, HStack } from "@chakra-ui/react"
import { Children, type ReactNode } from "react"
import { isPresent } from "ts-is-present"

/** Used as the child of LogoHeader to display a horizontal row of elements spaced with vertical dividers in various detail pages */
export const DividedRowSubHeader = ({ children }: { children: ReactNode }) => {
  return (
    <HStack gap={3} fontSize="sm" fontWeight="medium" minW={0}>
      {Children.toArray(children)
        .filter(isPresent)
        .map((child, index, array) => (
          <Flex key={index} paddingRight={3} borderRightWidth={index !== array.length - 1 ? 1 : 0}>
            {child}
          </Flex>
        ))}
    </HStack>
  )
}
