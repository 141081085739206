import type { Transaction } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { formatDate } from "@brm/util/format-date-time.js"
import {
  Alert,
  AlertDescription,
  Box,
  Flex,
  HStack,
  Heading,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetTransactionV1ByIdQuery } from "../../app/services/generated-api.js"
import CreditCardCell from "../../components/DataTable/CellRenderer/CreditCardCell.js"
import IntegrationCell from "../../components/DataTable/CellRenderer/IntegrationCell.js"
import PersonCell from "../../components/DataTable/CellRenderer/PersonCell.js"
import DocumentViewer from "../../components/Document/DocumentViewer.js"
import { VendorLogo } from "../../components/icons/Logo.js"
import { transactionReceiptDocumentDownloadUrl } from "../../util/document.js"
import { StatusCodes, getAPIErrorStatusCode } from "../../util/error.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import ReceiptButton from "./ReceiptButton.js"
import {
  deepLinkForBillPayment,
  deepLinkForCC,
  deepLinkForExpense,
  displayPaymentMethod,
  getCategories,
} from "./util.js"

const transactionDetailWidth = 400

export const TransactionDetailModal = ({
  transactionId,
  receiptId,
  onClose,
}: {
  transactionId: string
  receiptId: string | null
  onClose: () => void
}) => {
  const transactionResult = useGetTransactionV1ByIdQuery({ id: transactionId })

  return (
    <Modal size="full" isOpen={true} onClose={onClose} blockScrollOnMount={false} scrollBehavior="inside">
      <ModalOverlay />
      {transactionResult.isError && (
        <ModalContent flexDir="row" gap={0} background="transparent">
          <Spacer onClick={onClose} />
          <Box width={transactionDetailWidth} background="white">
            <Alert status="error">
              <AlertDescription>
                {getAPIErrorStatusCode(transactionResult.error) === StatusCodes.FORBIDDEN ? (
                  <FormattedMessage
                    id="transactionDetailDrawer.noPermission"
                    description="Error message when user doesn’t have permission to view transactions"
                    defaultMessage="You don’t have permission to view transactions."
                  />
                ) : (
                  <FormattedMessage
                    id="transactionDetailDrawer.loadError"
                    description="Error message when transaction fails to load"
                    defaultMessage="Something went wrong loading the transaction."
                  />
                )}
              </AlertDescription>
            </Alert>
          </Box>
        </ModalContent>
      )}
      {transactionResult.data && (
        <TransactionDetailModalContent transaction={transactionResult.data} receiptId={receiptId} onClose={onClose} />
      )}
    </Modal>
  )
}

// This is a separate component so that it can receive the fetched `transaction` as opposed to just the ID and doesn't have to deal with data fetching.
const TransactionDetailModalContent: FunctionComponent<{
  transaction: Transaction
  receiptId: string | null
  onClose: () => void
}> = ({ transaction, receiptId, onClose }) => {
  const intl = useIntl()

  const fieldDetail = (title: string, value: string | JSX.Element) => {
    return (
      <Stack gap={1}>
        <Text fontWeight="medium">{title}</Text>
        {typeof value === "string" ? <Text>{value}</Text> : value}
      </Stack>
    )
  }

  const deletedAccountText = intl.formatMessage({
    id: "transaction.deletedAccount",
    description: "Message shown instead of account name when the bank account and erp account are missing",
    defaultMessage: "DELETED ACCOUNT",
  })

  const receipts = transaction.receipts
  const receipt = receipts.find((r) => r.id === receiptId) ?? receipts[0]
  const categories = getCategories(transaction)

  return (
    <ModalContent flexDirection="row" gap={0} p={0} height="100dvh" background="transparent">
      <Flex flexGrow={1} height="100%" alignItems="center" justifyContent="center" onClick={onClose}>
        {receipt ? (
          <DocumentViewer
            document={receipt.document}
            downloadUrl={transactionReceiptDocumentDownloadUrl(transaction, receipt)}
          />
        ) : (
          <Spacer />
        )}
      </Flex>
      <Stack width={transactionDetailWidth} background="var(--modal-bg)" shadow="xl" pt={4}>
        <ModalHeader>
          <Stack gap={2}>
            <FormattedMessage
              id="transactionDetailDrawer.title"
              description="Title for the transaction detail drawer"
              defaultMessage="Transaction Detail"
            />
            <HStack gap={2}>
              <VendorLogo logo={getPublicImageGcsUrl(transaction.vendor.image_asset?.gcs_file_name)} boxSize={8} />
              <Heading size="md">{formatCurrency(transaction.currency_amount, intl)}</Heading>
            </HStack>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} direction="column" spacing={4} divider={<StackDivider />}>
          <Stack gap={4}>
            {transaction.memo &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.memo",
                  description: "Label for the transaction memo",
                  defaultMessage: "Memo",
                }),
                transaction.memo
              )}
            {fieldDetail(
              intl.formatMessage({
                id: "transactionDetailDrawer.vendor.name",
                description: "Label for the vendor name",
                defaultMessage: "Vendor",
              }),
              transaction.vendor.display_name
            )}
            {transaction.tool &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.tool.name",
                  description: "Label for the tool name",
                  defaultMessage: "Tool",
                }),
                transaction.tool.display_name
              )}
            {fieldDetail(
              intl.formatMessage({
                id: "transactionDetailDrawer.transactionDate",
                description: "Label for the transaction date",
                defaultMessage: "Payment date",
              }),
              formatDate(intl, transaction.transacted_at)
            )}
            {categories &&
              categories.length > 0 &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.category",
                  description: "Label for the expense category",
                  defaultMessage: "Expense Category",
                }),
                categories.length === 1 ? (
                  categories[0]!
                ) : (
                  <List>
                    {categories.map((category) => (
                      <li key={category}>{category}</li>
                    ))}
                  </List>
                )
              )}
            {transaction.erp_tracking_categories.departments.length > 0 &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.erpDepartments",
                  description: "Label for the ERP departments",
                  defaultMessage: "Accounting Categories - Department",
                }),
                transaction.erp_tracking_categories.departments.length === 1 ? (
                  transaction.erp_tracking_categories.departments[0]!.display_name
                ) : (
                  <Box>
                    {transaction.erp_tracking_categories.departments.map((department) => (
                      <li key={department.id}>{department.display_name}</li>
                    ))}
                  </Box>
                )
              )}
            {transaction.erp_tracking_categories.classes.length > 0 &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.erpClasses",
                  description: "Label for the ERP classes",
                  defaultMessage: "Accounting Categories - Class",
                }),
                transaction.erp_tracking_categories.classes.length === 1 ? (
                  transaction.erp_tracking_categories.classes[0]!.display_name
                ) : (
                  <Box>
                    {transaction.erp_tracking_categories.classes.map((cls) => (
                      <li key={cls.id}>{cls.display_name}</li>
                    ))}
                  </Box>
                )
              )}
          </Stack>
          <Stack gap={4}>
            {fieldDetail(
              intl.formatMessage({
                id: "transactionDetailDrawer.paymentMethod",
                description: "Label for the payment method",
                defaultMessage: "Payment Method",
              }),
              displayPaymentMethod(transaction.payment_method, intl)
            )}
            {fieldDetail(
              intl.formatMessage({
                id: "transactionDetailDrawer.source",
                description: "Label for the source of the transaction",
                defaultMessage: "Source",
              }),
              <HStack>
                {transaction.cc_transaction && (
                  <IntegrationCell
                    integration={transaction.cc_transaction.integration}
                    link={deepLinkForCC(transaction.cc_transaction)}
                  />
                )}
                {transaction.bill_payment && (
                  <IntegrationCell
                    integration={transaction.bill_payment.integration}
                    link={deepLinkForBillPayment(transaction.bill_payment)}
                  />
                )}
                {transaction.expense && (
                  <IntegrationCell
                    integration={transaction.expense.integration}
                    link={deepLinkForExpense(transaction.expense)}
                  />
                )}
              </HStack>
            )}
            {transaction.card &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.card",
                  description: "Label for the card used for the transaction",
                  defaultMessage: "Card",
                }),
                <CreditCardCell card={transaction.card} />
              )}
            {transaction.bank_account &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.bankAccount",
                  description: "Label for the bank account used for the transaction",
                  defaultMessage: "Bank Account",
                }),
                transaction.bank_account.display_name ?? deletedAccountText
              )}
            {(transaction.bill_payment || transaction.expense) &&
              fieldDetail(
                intl.formatMessage({
                  id: "transactionDetailDrawer.erpAccount",
                  description: "Label for the erp account used for the transaction",
                  defaultMessage: "ERP Account",
                }),
                transaction.bill_payment?.account?.display_name ??
                  transaction.expense?.account?.display_name ??
                  deletedAccountText
              )}
            {fieldDetail(
              intl.formatMessage({
                id: "transactionDetailDrawer.paidBy",
                description: "Label for the person who paid for the transaction",
                defaultMessage: "Paid by",
              }),
              transaction.person ? (
                <PersonCell person={transaction.person} />
              ) : (
                intl.formatMessage({
                  id: "transactionDetailDrawer.unknown",
                  description: "Label when the person who paid for the transaction is unknown",
                  defaultMessage: "Unknown",
                })
              )
            )}
          </Stack>
          {transaction.receipts.length > 1 &&
            fieldDetail(
              intl.formatMessage({
                id: "transactionDetailDrawer.receipts",
                description: "Label for the transaction’s receipts",
                defaultMessage: "Receipts",
              }),
              <HStack gap={1}>
                {transaction.receipts.map((receipt, index) => {
                  const isActive = receipt.id === receiptId || (index === 0 && !receiptId)
                  return (
                    <ReceiptButton
                      transaction_id={transaction.id}
                      receipt={receipt}
                      key={receipt.id}
                      aria-pressed={isActive}
                      isActive={isActive}
                    />
                  )
                })}
              </HStack>
            )}
        </ModalBody>
      </Stack>
    </ModalContent>
  )
}
