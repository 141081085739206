import { displayNameByType } from "@brm/schema-helpers/integrations.js"
import type { IntegrationProvider } from "@brm/schema-types/types.js"
import { HStack, Text } from "@chakra-ui/react"
import { IntegrationProviderIcon } from "../../icons/IntegrationProviderIcon.js"

interface Props {
  provider?: IntegrationProvider
}

export default function ProviderCell({ provider }: Props) {
  if (!provider) {
    return null
  }

  return (
    <HStack>
      <IntegrationProviderIcon boxSize="6" integration={{ provider }} />
      <Text>{displayNameByType[provider]}</Text>
    </HStack>
  )
}
