export enum ButtonStyles {
  SolidBrand = "SolidBrand",
  SolidDestructive = "SolidDestructive",
  OutlinedGray = "OutlinedGray",
  Ghost = "Ghost",
  GhostBrand = "GhostBrand",
  GhostDestructive = "GhostDestructive",
  Link = "Link",
  LinkBrand = "LinkBrand",
}
