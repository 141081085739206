import type {
  DocumentBackgroundExtractionResponse,
  DocumentExtractionLegalAgreementInput,
  DocumentWithExtraction,
  MoveDocumentCreateAction,
  MoveDocumentUpdateAction,
} from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Icon, List, Stack, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState, type ReactNode } from "react"
import type { Control } from "react-hook-form"
import { Controller, useFieldArray, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { usePostDocumentV1MoveToAgreementMutation, type DocumentMinimal } from "../../../app/services/generated-api.js"
import { MoveDocumentIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import ExtractLegalDocumentUpload from "../ExtractLegalDocumentUpload.js"

export interface AssociatedDocumentsFormState {
  documents: DocumentWithExtraction[]
}

export function AssociatedDocuments({
  control,
  legalAgreementId,
  legalAgreementInput,
  onDocumentAdded,
  onLastDocumentRemoved,
  submitDocuments,
}: {
  control: Control<AssociatedDocumentsFormState>
  legalAgreementId: string | undefined
  legalAgreementInput: DocumentExtractionLegalAgreementInput | undefined
  onDocumentAdded?: (extractionResult: DocumentBackgroundExtractionResponse) => void
  onLastDocumentRemoved?: () => Promise<void>
  /** Used to trigger form submission during the onDocumentChange method. Submits the form with the original values but updated documents. */
  submitDocuments: () => Promise<void>
}): ReactNode {
  const intl = useIntl()
  const location = useLocation()
  const toast = useToast()
  const documentsField = useFieldArray({ control, name: "documents" })
  const [selectedDocument, setSelectedDocument] = useState<Pick<DocumentMinimal, "id"> | undefined>(undefined)
  const hashParams = new URLSearchParams(location.hash.slice(1))
  const uploadedDocuments = useWatch({ control, name: "documents" })
  const selectedDocumentId = hashParams.get("document") || uploadedDocuments[0]?.id
  const [moveDocument, { isLoading }] = usePostDocumentV1MoveToAgreementMutation()

  useEffect(() => {
    if (selectedDocumentId) {
      const document = uploadedDocuments?.find((document) => document.id === selectedDocumentId)
      setSelectedDocument(document)
    }
  }, [selectedDocumentId, uploadedDocuments])

  return (
    <Stack spacing={4}>
      <Controller
        name="documents"
        control={control}
        render={({ field }) => (
          <List spacing={4} display="flex" flexDirection="column">
            <ExtractLegalDocumentUpload
              isMovingDocument={isLoading}
              onMoveDocument={async (
                documentId: string,
                newAgreement: MoveDocumentCreateAction | MoveDocumentUpdateAction
              ) => {
                if (legalAgreementId) {
                  try {
                    // Will create a new agreement if new_agreement_id is empty
                    const response = await moveDocument({
                      moveDocumentRequest: {
                        document_id: documentId,
                        old_agreement_id: legalAgreementId,
                        new_agreement: newAgreement,
                      },
                    }).unwrap()
                    if (field.value.length === 1) {
                      await onLastDocumentRemoved?.()
                    }
                    hashParams.set("agreement", response.id)
                    hashParams.delete("document")
                    toast({
                      icon: <Icon boxSize={6} as={MoveDocumentIcon} />,
                      status: "success",
                      duration: LONG_TOAST_DURATION,
                      description: (
                        <Text isTruncated textOverflow="ellipsis" overflow="hidden">
                          <FormattedMessage
                            id="associatedRecords.moveToAgreement.success"
                            description="Toast shown when document is successfully moved to new agreement"
                            defaultMessage="Document successfully moved to {newAgreement}"
                            values={{
                              newAgreement: (
                                <Link
                                  to={{
                                    search: location.pathname === "/agreements" ? location.search : undefined,
                                    hash: `#${hashParams}`,
                                  }}
                                  onClick={() => toast.closeAll()}
                                  textDecoration="underline"
                                  textUnderlineOffset="3px"
                                >
                                  {response.display_name}
                                </Link>
                              ),
                            }}
                          />
                        </Text>
                      ),
                    })
                  } catch (err) {
                    toast({
                      status: "error",
                      description: intl.formatMessage({
                        id: "associatedRecords.moveToAgreement.error",
                        description: "Toast shown when document is fails to be moved to new agreement",
                        defaultMessage: "Document failed to be moved",
                      }),
                    })
                  }
                }
              }}
              selectedDocument={selectedDocument}
              ref={field.ref}
              multiple={true}
              onDocumentChange={async (documents, type, document) => {
                switch (type) {
                  case "remove": {
                    documentsField.remove(
                      field.value.findIndex((fieldValueDocument) => fieldValueDocument.id === document.id)
                    )
                    await submitDocuments()

                    if (documents.length === 0) {
                      await onLastDocumentRemoved?.()
                    }
                    break
                  }
                  case "add":
                    documentsField.append(document)
                    break

                  default:
                    unreachable(type)
                }
              }}
              getLinkDestination={(document) => {
                const documentHash = new URLSearchParams(hashParams)
                documentHash.set("document", document.id)
                documentHash.set("agreement", legalAgreementId ?? "")

                return {
                  search: location.pathname === "/agreements" ? location.search : undefined,
                  hash: `#${documentHash}`,
                }
              }}
              value={uploadedDocuments}
              onExtractionEnqueued={onDocumentAdded}
              legalAgreementId={legalAgreementId}
              legalAgreementInput={legalAgreementInput}
              fieldName="documents"
            />
          </List>
        )}
      />
    </Stack>
  )
}
