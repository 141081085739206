import type { ExistsFilter } from "@brm/schema-types/types.js"
import { FormControl, Radio } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { FilterProps } from "./types.js"

export default function ExistsFilter({ filter, onChange }: FilterProps<ExistsFilter>) {
  return (
    <FormControl
      as="fieldset"
      display="flex"
      flexDir="column"
      gap={3}
      p={3}
      maxH={FILTER_POPOVER_MAX_HEIGHT_CALC}
      overflow="auto"
    >
      <Radio
        value="null"
        isChecked={!filter?.value}
        onChange={({ target: { checked } }) => onChange({ comparator: "exists", value: !checked })}
      >
        <FormattedMessage
          id="filter.value.null"
          defaultMessage="Not assigned"
          description="Option for a filter that represents searching for rows without a value"
        />
      </Radio>
      <Radio
        value="present"
        isChecked={filter?.value}
        onChange={({ target: { checked } }) => onChange({ comparator: "exists", value: checked })}
      >
        <FormattedMessage
          id="filter.value.notNull"
          defaultMessage="Assigned"
          description="Option for a filter that represents searching for rows without a value"
        />
      </Radio>
    </FormControl>
  )
}
