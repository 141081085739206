import React, { useCallback, useMemo } from "react"
import type { Descendant } from "slate"
import { createEditor } from "slate"
import { Editable, Slate, withReact, type RenderElementProps, type RenderLeafProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { Element } from "./Element.js"
import { Leaf } from "./Leaf.js"
export interface RichTextDisplayProps {
  content: Descendant[]
}

/**
 * RichTextDisplay is a read-only component that displays rich text content. It uses Slate to render the content.
 */
const RichTextDisplay = React.memo(function RichTextDisplay({
  content,
  getLogoToShowByOrganizationId,
}: RichTextDisplayProps & GetLogoForOrganizationProps) {
  const editor = useMemo(() => withReact(createEditor()), [])

  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element {...props} isReadOnly getLogoToShowByOrganizationId={getLogoToShowByOrganizationId} />
    ),
    [getLogoToShowByOrganizationId]
  )

  return (
    <Slate editor={editor} initialValue={content}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck={false}
        readOnly
        aria-readonly="true"
      />
    </Slate>
  )
})

export default RichTextDisplay
