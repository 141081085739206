import type { AnyFilter } from "@brm/schema-types/types.js"
import { BOOLEAN_FALSE_URL_VALUE, BOOLEAN_TRUE_URL_VALUE } from "@brm/type-helpers/filters.js"
import { Checkbox, Flex } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { NullCheckbox } from "../../../Form/NullCheckbox.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { FilterProps } from "./types.js"

export default function BooleanFilter({
  filter,
  displayPath,
  fieldSchema,
  isNullable,
  onChange,
}: FilterProps<AnyFilter>) {
  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")

  const [isNullChecked, setIsNullChecked] = useState(filter?.comparator === "any" ? filter.includeNull : false)
  const [values, setValues] = useState<ReadonlySet<string>>(new Set(filter?.values))

  const onValueChecked = (checked: boolean, value: string) => {
    const comparator = filter?.comparator ?? "any"
    const currentValues = filter?.values ?? []
    const newValues = checked ? [...currentValues, value] : currentValues.filter((v) => v !== value)
    setValues(new Set(newValues))
    if (newValues.length > 0 || isNullChecked) {
      // Only report back filter changes if the filter is valid (at least one value)
      onChange({ comparator, values: newValues, includeNull: isNullChecked })
    }
  }

  return (
    <Flex flexDir="column" gap={3} p={3} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC}>
      {isNullable && (
        <NullCheckbox
          isChecked={isNullChecked}
          fieldName={fieldName}
          fieldSchema={fieldSchema}
          onChange={({ target: { checked } }) => {
            setIsNullChecked(checked)
            if (values.size > 0 || checked) {
              // Only report back filter changes if the filter is valid (at least one value)
              onChange({ comparator: "any", values: Array.from(values), includeNull: checked })
            }
          }}
        />
      )}
      <Checkbox
        value={BOOLEAN_TRUE_URL_VALUE}
        onChange={({ target: { checked } }) => onValueChecked(checked, BOOLEAN_TRUE_URL_VALUE)}
        isChecked={values.has(BOOLEAN_TRUE_URL_VALUE)}
      >
        <FormattedMessage
          id="booleanFilter.value.yes"
          description="Option in a menu to sort a yes or no column by Yes"
          defaultMessage="Yes"
        />
      </Checkbox>
      <Checkbox
        value={BOOLEAN_FALSE_URL_VALUE}
        onChange={({ target: { checked } }) => onValueChecked(checked, BOOLEAN_FALSE_URL_VALUE)}
        isChecked={values.has(BOOLEAN_FALSE_URL_VALUE)}
      >
        <FormattedMessage
          id="booleanFilter.value.no"
          description="Option in a menu to sort a yes or no column by No"
          defaultMessage="No"
        />
      </Checkbox>
    </Flex>
  )
}
