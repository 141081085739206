import { hasPermission } from "@brm/schema-helpers/role.js"
import type { PersonEmploymentStatus, TimePeriod, ToolListItem } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { safeBig } from "@brm/util/currency/parse.js"
import { shortenUUID } from "@brm/util/short-uuids.js"
import type { FlexboxProps } from "@chakra-ui/react"
import { Box, chakra, Flex, Grid, GridItem, HStack, Icon, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Select } from "chakra-react-select"
import { useState, type FunctionComponent, type ReactElement } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { type To } from "react-router-dom"
import upcomingRenewalsEmptyState from "../../../assets/upcoming_renewals_card_empty_state.svg"
import {
  useGetSavedViewV1ByTableIdentifierQuery,
  useGetStatisticsV1AgreementsQuery,
  useGetStatisticsV1EmailIntegrationsQuery,
  useGetStatisticsV1PersonsQuery,
  useGetStatisticsV1ToolsQuery,
  useGetUserV1WhoamiQuery,
  usePostToolV1ListQuery,
} from "../../app/services/generated-api.js"
import UserWithProviderBadge from "../../components/DataTable/CellRenderer/UserWithProviderBadge.js"
import { FormattedDate } from "../../components/FormattedDate.js"
import {
  AgreementIcon,
  ArrowNarrowUpIcon,
  CalendarDateIcon,
  FilterCurrencyIcon,
  MailIcon,
  ToolIcon,
  UsersIcon,
} from "../../components/icons/icons.js"
import { ToolLogo } from "../../components/icons/Logo.js"
import PercentArrowIcon from "../../components/icons/PercentArrowIcon.js"
import { Link, LinkOrSpan } from "../../components/Link.js"
import Spinner from "../../components/spinner.js"
import { colorForPercent } from "../../util/percent.js"
import { savedViewToListQueryStringParams } from "../../util/saved-view.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { useDocumentVisibility } from "../../util/visibility.js"
import { displayTimePeriod } from "../charts/utils.js"
import { TOOL_TABLE_ID } from "../tool/tool-list/constants.js"
import SummaryCard from "./SummaryCard.js"

const HOME_SUMMARY_LINK_MAP = {
  paidToolsCount: `/tools?${"spend.total" satisfies BRMPaths<ToolListItem>}_exists=true&sort_desc=${"spend.total" satisfies BRMPaths<ToolListItem>}`,
  mtdSpend: `/tools?sort_desc=${"spend.mtd" satisfies BRMPaths<ToolListItem>}`,
  ytdSpend: `/tools?sort_desc=${"spend.ytd" satisfies BRMPaths<ToolListItem>}`,
  ltmSpend: `/tools?sort_desc=${"spend.ltm" satisfies BRMPaths<ToolListItem>}`,
}

const pollingInterval = 15_000

export default function HomeSummaryCards(props: FlexboxProps) {
  const intl = useIntl()

  const visibility = useDocumentVisibility()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const { data: toolsData } = useGetStatisticsV1ToolsQuery(undefined, {
    pollingInterval: visibility === "visible" ? pollingInterval : 0,
  })

  const { data: personsData } = useGetStatisticsV1PersonsQuery(undefined, {
    pollingInterval: visibility === "visible" ? pollingInterval : 0,
  })

  const { data: emailIntegrationsData } = useGetStatisticsV1EmailIntegrationsQuery(undefined, {
    pollingInterval: visibility === "visible" ? pollingInterval : 0,
  })

  const { data: agreementsData } = useGetStatisticsV1AgreementsQuery(undefined, {
    pollingInterval: visibility === "visible" ? pollingInterval : 0,
  })

  const { data: savedViewData } = useGetSavedViewV1ByTableIdentifierQuery({
    tableIdentifier: TOOL_TABLE_ID,
  })

  const upcomingRenewalView = savedViewData?.find((view) => view.is_shared && view.name === "Upcoming Renewals")
  const upcomingRenewalListQuery = upcomingRenewalView
    ? savedViewToListQueryStringParams(upcomingRenewalView)
    : undefined

  // TODO put this in a separate component which takes in the already loaded upcomingRenewalView
  const { data: upcomingRenewalsData, isLoading: upcomingRenewalsIsLoading } = usePostToolV1ListQuery({
    listQueryStringParams: {
      limit: 3,
      sort: {
        by: "primary_legal_agreement.decision_date" satisfies BRMPaths<ToolListItem>,
        direction: "ASC",
        nulls: "LAST",
      },
      filter: [
        [
          {
            column: "primary_legal_agreement.decision_date" satisfies BRMPaths<ToolListItem>,
            fields: { comparator: "gt", value: Temporal.Now.plainDateISO().subtract({ days: 30 }).toString() },
          },
        ],
      ],
      ...upcomingRenewalListQuery,
    },
  })

  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriod>("last_twelve_months")
  const selectablePeriods: TimePeriod[] = []
  if (toolsData?.ltm_spend_statistic) {
    selectablePeriods.push("last_twelve_months")
  }
  if (toolsData?.ytd_spend_statistic) {
    selectablePeriods.push("year_to_date")
  }
  if (toolsData?.mtd_spend_statistic) {
    selectablePeriods.push("month_to_date")
  }

  let toolSpendBody: ReactElement | undefined
  let toolLink: string | undefined
  switch (selectedPeriod) {
    case "last_twelve_months": {
      if (toolsData?.ltm_spend_statistic) {
        toolLink = HOME_SUMMARY_LINK_MAP.ltmSpend
        const increasePercent = safeBig(toolsData.ltm_spend_statistic.increase_percent)
        toolSpendBody = (
          <>
            <Text pt={4} fontSize="3xl" fontWeight="semibold">
              {formatCurrency(toolsData.ltm_spend_statistic.currency_amount, intl)}{" "}
              <Text as="span" fontSize="md" fontWeight="medium" color="gray.600">
                <FormattedMessage
                  id="home.tool.summary.spend.ltm"
                  description="in the last 12 months"
                  defaultMessage="in the last 12 months"
                />
              </Text>
            </Text>
            {increasePercent && (
              <Flex gap={1} pt={2} fontSize="sm" color={colorForPercent(increasePercent.toNumber())}>
                <PercentArrowIcon percent={increasePercent.toNumber()} />
                <FormattedMessage
                  id="yearOverYear.percentChange"
                  description="Abbreviation for year-over-year"
                  defaultMessage="YoY"
                />
              </Flex>
            )}
          </>
        )
      }
      break
    }
    case "year_to_date": {
      if (toolsData?.ytd_spend_statistic) {
        toolLink = HOME_SUMMARY_LINK_MAP.ytdSpend
        const increasePercent = safeBig(toolsData.ytd_spend_statistic.increase_percent)
        toolSpendBody = (
          <>
            <Text pt={4} fontSize="3xl" fontWeight="semibold">
              {formatCurrency(toolsData.ytd_spend_statistic.currency_amount, intl)}{" "}
              <Text as="span" fontSize="md" fontWeight="medium" color="gray.600">
                <FormattedMessage
                  id="home.tool.summary.spend.ytd"
                  description="in the last year"
                  defaultMessage="in the last year"
                />
              </Text>
            </Text>
            {increasePercent && (
              <Flex gap={1} pt={2} fontSize="sm" color={colorForPercent(increasePercent.toNumber())}>
                <PercentArrowIcon percent={increasePercent.toNumber()} />
                <FormattedMessage
                  id="yearOverYear.percentChange"
                  description="Abbreviation for year-over-year"
                  defaultMessage="YoY"
                />
              </Flex>
            )}
          </>
        )
      }
      break
    }
    case "month_to_date": {
      if (toolsData?.mtd_spend_statistic) {
        toolLink = HOME_SUMMARY_LINK_MAP.mtdSpend
        const increasePercent = safeBig(toolsData.mtd_spend_statistic.increase_percent)
        toolSpendBody = (
          <>
            <Text pt={4} fontSize="3xl" fontWeight="semibold">
              {formatCurrency(toolsData.mtd_spend_statistic.currency_amount, intl)}{" "}
              <Text as="span" fontSize="md" fontWeight="medium" color="gray.600">
                <FormattedMessage
                  id="home.tool.summary.spend.mtd"
                  description="in the last month"
                  defaultMessage="in the last month"
                />
              </Text>
            </Text>
            {increasePercent && (
              <Flex gap={1} pt={2} fontSize="sm" color={colorForPercent(increasePercent.toNumber())}>
                <PercentArrowIcon percent={increasePercent.toNumber()} />
                <FormattedMessage
                  id="monthOverMonth.percentChange"
                  description="Abbreviation for month-over-month"
                  defaultMessage="MoM"
                />
              </Flex>
            )}
          </>
        )
      }
      break
    }
  }

  return (
    <Grid {...props} gap={6} templateColumns="repeat(auto-fit, minmax(350px, 1fr))" autoRows="1fr">
      {hasPermission(whoami?.roles, "spend:read") && (
        <GridItem>
          <SummaryCard
            icon={<Icon as={FilterCurrencyIcon} />}
            title={intl.formatMessage({
              id: "toolSummary.statistic.Spend",
              defaultMessage: "Tool Spend",
              description: "Title for the total the amount spent on tools",
            })}
            headerAction={
              selectablePeriods.length > 1 ? (
                <Box maxW="400px" flexShrink={1} fontWeight="normal">
                  <Select
                    size="sm"
                    options={selectablePeriods.map((timePeriod) => ({
                      value: timePeriod,
                      label: displayTimePeriod(timePeriod),
                    }))}
                    value={{ value: selectedPeriod, label: displayTimePeriod(selectedPeriod) }}
                    onChange={(newSelection) => {
                      if (newSelection) {
                        setSelectedPeriod(newSelection.value)
                      }
                    }}
                  />
                </Box>
              ) : undefined
            }
            footer={{
              link: toolLink ?? "/settings/integrations#tab=accounting",
              text: toolLink
                ? intl.formatMessage({
                    id: "toolSummary.statistic.viewAll",
                    defaultMessage: "View by spend",
                    description: "Button text to link users to view tools",
                  })
                : intl.formatMessage({
                    id: "toolSummary.footer.addPaymentIntegration",
                    defaultMessage: "Connect spend management",
                    description: "Button text to link users to add payment integration",
                  }),
            }}
          >
            {toolSpendBody}
          </SummaryCard>
        </GridItem>
      )}

      <GridItem>
        <SummaryCard
          icon={<Icon as={ToolIcon} />}
          title={intl.formatMessage({
            id: "toolSummary.statistic.toolCount",
            description: "Title for the total number of paid tools the organization is using",
            defaultMessage: "Paid Tools",
          })}
          footer={{
            link: toolsData?.paid_count.value ? "/tools#tab=paid" : "/settings/integrations#tab=accounting",
            text: toolsData?.paid_count.value
              ? intl.formatMessage({
                  id: "toolSummary.footer.paidTools",
                  description: "Button text to link users to view paid tools",
                  defaultMessage: "View paid tools",
                })
              : intl.formatMessage({
                  id: "toolSummary.footer.addPaymentIntegration",
                  description: "Button text to link users to add payment integration",
                  defaultMessage: "Connect spend management",
                }),
          }}
        >
          <Text pt={4} fontSize="3xl" fontWeight="medium">
            {toolsData?.paid_count.value ?? 0}
          </Text>
          <IncreaseValueLink
            increaseValue={toolsData?.paid_count.increase_value}
            to={toolsData?.paid_count.increase_value ? "/tools?sort_desc=created_at#tab=paid" : null}
            message={intl.formatMessage(
              {
                id: "toolSummary.statistic.toolCount.increase",
                description: "Title for the total number of paid tools the organization is using",
                defaultMessage: "{value, plural, =0 {No new tools} one {1 new tool} other {# new tools}}",
              },

              { value: toolsData?.paid_count.increase_value ?? 0 }
            )}
          />
        </SummaryCard>
      </GridItem>

      <GridItem>
        <SummaryCard
          icon={<Icon as={UsersIcon} />}
          title={intl.formatMessage({
            id: "home.statistic.userCount",
            description: "Title for the total number of users the organization has",
            defaultMessage: "People",
          })}
          footer={{
            link: personsData?.employed_count.value
              ? `/people?employment_status=${"active" satisfies PersonEmploymentStatus}`
              : "/settings/integrations#tab=hr",
            text: personsData?.employed_count.value
              ? intl.formatMessage({
                  id: "home.summary.viewAll",
                  description: "Button text to link users to view people",
                  defaultMessage: "View people",
                })
              : intl.formatMessage({
                  id: "home.summary.addHRIntegration",
                  description: "Button text to link users to add HR integration",
                  defaultMessage: "Connect HRIS",
                }),
          }}
        >
          <Text pt={4} fontSize="3xl" fontWeight="medium">
            {personsData?.employed_count.value ?? 0}
          </Text>
          <IncreaseValueLink
            increaseValue={personsData?.employed_count.increase_value}
            to={
              personsData?.employed_count.increase_value
                ? `/people?employment_status=${"active" satisfies PersonEmploymentStatus}&sort_desc=created_at`
                : null
            }
            message={intl.formatMessage(
              {
                id: "home.statistic.userCount.increase",
                description: "Title for the total number of users the organization has",
                defaultMessage: "{value, plural, =0 {No new employees} one {1 new employee} other {# new employees}}",
              },
              { value: personsData?.employed_count.increase_value ?? 0 }
            )}
          />
        </SummaryCard>
      </GridItem>

      <GridItem>
        <SummaryCard
          icon={<Icon as={MailIcon} />}
          title={intl.formatMessage({
            id: "home.statistic.emailAgreementCount",
            description: "Title for the total number of agreements found in email",
            defaultMessage: "Agreements found via Email",
          })}
          footer={{
            link: emailIntegrationsData?.current_user_connected
              ? "/agreements?source=email#tab=all"
              : "/settings/profile",
            text: emailIntegrationsData?.current_user_connected
              ? intl.formatMessage({
                  id: "home.summary.viewAgreements",
                  description: "Button text to link users to view agreements",
                  defaultMessage: "View agreements",
                })
              : intl.formatMessage({
                  id: "home.summary.addEmailIntegration",
                  description: "Button text to link users to add email integration",
                  defaultMessage: "Connect your email",
                }),
          }}
        >
          <Text pt={4} fontSize="3xl" fontWeight="medium">
            {emailIntegrationsData?.linked_agreement_count.value ?? 0}{" "}
            <Text as="span" fontSize="md" fontWeight="medium" color="gray.600">
              <Tooltip
                label={
                  <Stack py={2} gap={2}>
                    {emailIntegrationsData?.user_integrations?.map((userWithProvider) => (
                      <UserWithProviderBadge key={userWithProvider.id} user={userWithProvider} textOnly includeName />
                    ))}
                  </Stack>
                }
                shouldWrapChildren
              >
                <FormattedMessage
                  id="home.statistic.emailAgreementCount.from"
                  description="Title for the total number of agreements found via email"
                  defaultMessage="from {integration_count} {integration_count, plural, =0 {inboxes} one {inbox} other {inboxes}}"
                  values={{
                    integration_count: emailIntegrationsData?.user_integrations?.length ?? 0,
                  }}
                />
              </Tooltip>
            </Text>
          </Text>
          <IncreaseValueLink
            increaseValue={emailIntegrationsData?.linked_agreement_count.increase_value}
            to={
              emailIntegrationsData?.linked_agreement_count.increase_value
                ? `/agreements?source=email&created_at_gte=${Temporal.Now.plainDateISO().subtract({ days: 30 }).toString()}&sort_desc=created_at#tab=all`
                : null
            }
            message={intl.formatMessage(
              {
                id: "home.statistic.emailAgreementCount.increase",
                description: "Title for the total number of agreements found via email",
                defaultMessage:
                  "{value, plural, =0 {No new agreements} one {1 new agreement} other {# new agreements}}",
              },
              { value: emailIntegrationsData?.linked_agreement_count.increase_value ?? 0 }
            )}
          />
        </SummaryCard>
      </GridItem>

      <GridItem>
        <SummaryCard
          icon={<Icon as={AgreementIcon} />}
          title={intl.formatMessage({
            id: "home.statistic.agreementCount",
            description: "Title for the section about agreements",
            defaultMessage: "Agreements",
          })}
          footer={{
            link: agreementsData?.verified_count ? "/agreements#tab=pending_review" : "/agreements#upload=true",
            text: agreementsData?.verified_count
              ? intl.formatMessage({
                  id: "home.statistic.agreementCount.view",
                  description: "Title for the link to view agreements",
                  defaultMessage: "View agreements",
                })
              : intl.formatMessage({
                  id: "home.statistic.agreementCount.upload",
                  description: "Title for the link to upload agreements",
                  defaultMessage: "Upload agreements",
                }),
          }}
        >
          <Text pt={4} fontSize="3xl" fontWeight="medium">
            {agreementsData ? `${agreementsData.verified_count}/${agreementsData.total_count}` : "0"}{" "}
            <Text as="span" fontSize="md" fontWeight="medium" color="gray.600">
              <FormattedMessage
                id="home.statistic.agreementCount.verified.text"
                description="subtitle following a fraction that displays verified agreements over total agreements"
                defaultMessage="verified,"
              />{" "}
              <LinkOrSpan
                to={agreementsData ? `/agreements#agreement=${agreementsData.unverified_agreement_id}` : null}
              >
                <FormattedMessage
                  id="home.statistic.agreementCount.pending.link"
                  description="Title for the link to pending agreements"
                  defaultMessage="{pending_count} pending"
                  values={{
                    pending_count: agreementsData ? agreementsData.total_count - agreementsData.verified_count : 0,
                  }}
                />
              </LinkOrSpan>
            </Text>
          </Text>
          {agreementsData && (
            <Flex pt={2} fontSize="sm">
              <FormattedMessage
                id="home.statistic.agreementCount.percent"
                description="Title for the total number of agreements found via email"
                defaultMessage="{percent} verified"
                values={{
                  percent: (
                    <FormattedNumber
                      value={
                        agreementsData.total_count > 0 ? agreementsData.verified_count / agreementsData.total_count : 0
                      }
                      style="percent"
                      minimumFractionDigits={0}
                      maximumFractionDigits={1}
                    />
                  ),
                }}
              />
            </Flex>
          )}
        </SummaryCard>
      </GridItem>

      <GridItem>
        <SummaryCard
          icon={<Icon as={CalendarDateIcon} />}
          title={intl.formatMessage({
            id: "home.statistic.renewalCount",
            description: "Title for the section about renewals",
            defaultMessage: "Upcoming Renewals",
          })}
          footer={
            upcomingRenewalsData?.tools
              ? {
                  link: upcomingRenewalView
                    ? `/tools?view=${shortenUUID(upcomingRenewalView.id)}&sort=${upcomingRenewalListQuery?.sort ? ("primary_legal_agreement.decision_date" satisfies BRMPaths<ToolListItem>) : "view"}`
                    : `/tools?sort_desc=${"primary_legal_agreement.decision_date" satisfies BRMPaths<ToolListItem>}`,
                  text: intl.formatMessage({
                    id: "home.statistic.renewalCount.view",
                    description: "Title for the link to view renewals",
                    defaultMessage: "View all",
                  }),
                }
              : undefined
          }
        >
          {upcomingRenewalsData?.tools && upcomingRenewalsData.tools.length > 0 ? (
            <Stack gap={1} paddingTop={2}>
              {upcomingRenewalsData.tools.map((tool) => (
                <HStack key={tool.id} whiteSpace="nowrap">
                  <Link
                    to={`/tools/${tool.id}/overview#agreement=${tool.primary_legal_agreement?.id}`}
                    display="flex"
                    alignItems="center"
                    fontWeight="medium"
                    isTruncated
                    gap={2}
                  >
                    <ToolLogo display="inline-flex" logo={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)} />
                    <chakra.span isTruncated lineHeight="shorter">
                      {tool.primary_legal_agreement?.display_name}
                    </chakra.span>
                  </Link>
                  <Spacer />
                  {tool.primary_legal_agreement?.decision_date && (
                    <Tooltip
                      shouldWrapChildren
                      label={intl.formatMessage({
                        id: "home.statistic.renewalDate.tooltip",
                        description: "Tooltip for the decision date of a renewal",
                        defaultMessage: "Decision date",
                      })}
                    >
                      <chakra.span color="gray.600">
                        <FormattedDate value={tool.primary_legal_agreement.decision_date} year={undefined} />
                      </chakra.span>
                    </Tooltip>
                  )}
                </HStack>
              ))}
            </Stack>
          ) : upcomingRenewalsIsLoading ? (
            <Flex justifyContent="center" alignItems="center" height="full">
              <Spinner />
            </Flex>
          ) : (
            <Stack alignItems="center" pt={2}>
              <chakra.img src={upcomingRenewalsEmptyState} />
              <FormattedMessage
                id="home.statistic.renewalCount.noUpcomingRenewals"
                description="Title for the link to view renewals"
                defaultMessage="Your upcoming renewals will appear here"
              />
            </Stack>
          )}
        </SummaryCard>
      </GridItem>
    </Grid>
  )
}

const IncreaseValueLink: FunctionComponent<{
  increaseValue?: number
  to?: To | null
  message: string
}> = ({ increaseValue, to, message }) => {
  return (
    <Flex pt={2} fontSize="sm" color={increaseValue ? "success.600" : "gray.600"}>
      <LinkOrSpan to={to}>
        {increaseValue ? (
          <Text as="span" fontSize="xs">
            <Icon as={ArrowNarrowUpIcon} />
          </Text>
        ) : undefined}
        {message}
      </LinkOrSpan>
    </Flex>
  )
}
