import { isPlainDate } from "@brm/util/date-time.js"
import { Tooltip } from "@chakra-ui/react"
import type { ReactNode } from "react"
import { FormattedRelativeTime } from "react-intl"
import { selectRelativeTimeUnit, type ThresholdUnits } from "../util/timestamp.js"
import { FormattedDate } from "./FormattedDate.js"
import type { LinkOrSpanProps } from "./Link.js"
import { LinkOrSpan } from "./Link.js"

const UPDATABLE_UNITS = new Set<Intl.RelativeTimeFormatUnitSingular>(["second", "minute", "hour"])

/**
 * Renders a relative timestamp like "2 hours ago", "1 day ago" etc with a tooltip revealing the exact date and time.
 */
export const Timestamp: React.FunctionComponent<{
  dateTime: string
  style?: Intl.RelativeTimeFormatStyle
  numeric?: Intl.RelativeTimeFormatNumeric
  linkProps?: LinkOrSpanProps
  prefix?: ReactNode
  thresholds?: Partial<Record<ThresholdUnits, number>>
  // If true, will display the date without timestamp
  displayDate?: boolean
}> = ({ dateTime, style, linkProps, prefix, thresholds, displayDate, numeric }) => {
  const isDate = displayDate || isPlainDate(dateTime) || /^\d{4}-\d{2}-\d{2}$/u.test(dateTime)

  return (
    <Tooltip
      label={
        <FormattedDate
          value={dateTime}
          dateStyle="full"
          timeStyle={isDate ? undefined : "short"}
          year={undefined}
          month={undefined}
          day={undefined}
        />
      }
    >
      <LinkOrSpan {...linkProps} whiteSpace="nowrap">
        {prefix}
        <RelativeDateTimeDisplay dateTime={dateTime} style={style} numeric={numeric} thresholds={thresholds} />
      </LinkOrSpan>
    </Tooltip>
  )
}

/**
 * Renders a relative timestamp, accepting instants in time as well as plain dates.
 * If a plain date is provided, will render the relative time in "days"
 */
export const RelativeDateTimeDisplay: React.FunctionComponent<{
  dateTime: string
  style?: Intl.RelativeTimeFormatStyle
  numeric?: Intl.RelativeTimeFormatNumeric
  thresholds?: Partial<Record<"second" | "minute" | "hour" | "day" | "week" | "month" | "year", number>>
}> = ({ dateTime, style, numeric, thresholds }) => {
  const { unit, value } = selectRelativeTimeUnit(dateTime, thresholds)

  // We don't like yesterday showing up when doing "narrow" even though we do like things like "now"
  // so this is a hacky to get rid of yesterday
  if (unit === "day" && value === -1 && style === "narrow" && (numeric === "auto" || numeric === undefined)) {
    numeric = "always"
  }

  return (
    <time dateTime={dateTime}>
      {
        <FormattedRelativeTime
          numeric={numeric || "auto"}
          style={style}
          // Will throw if used on a unit larger than hour
          updateIntervalInSeconds={UPDATABLE_UNITS.has(unit) ? 60 : undefined}
          unit={unit}
          value={value}
        />
      }
    </time>
  )
}
