/**
 * Gets a strings last character. Handles Unicode properly.
 */
export const getLastCharacter = (str: string, locale: string): string | undefined => {
  // Not supported in Firefox
  if (!Intl.Segmenter) {
    return str.match(/.$/u)?.[0] || undefined
  }
  return Array.from(new Intl.Segmenter(locale).segment(str)).at(-1)?.segment
}

/**
 * Capitalizes the first letter of the first word. Handles Unicode properly.
 */
export const capitalize = (str: string): string => str.replace(/\b\p{Letter}/u, (substr) => substr.toLocaleUpperCase())

/**
 * Removes all non-latin letters from a string. Handles Unicode properly.
 */
export const onlyLatinLetters = (str: string): string => str.replace(/[^a-z]/giu, "")

/**
 * A trim that works safely with null and undefined values, returning the respective value if the input was not a string.
 */
export const safeTrim = (str: string | null | undefined): string | null | undefined =>
  str === undefined ? undefined : str === null ? null : str.trim()

export const NUMERIC_STRING_PATTERN: RegExp = /^-?\d+(?:\.\d+)?$/u

/**
 * Postgres cannot save null character strings into the db, so we need to replace them before saving.
 */
export const removeNullCharacters = (str: string | undefined): string | undefined =>
  str ? str.replace(/\0/gu, "") : str
