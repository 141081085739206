import { displayNameByType } from "@brm/schema-helpers/integrations.js"
import type { IntegrationProvider, Scope } from "@brm/schema-types/types.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import LoginScopeRiskCell from "../../components/DataTable/CellRenderer/LoginScopeRiskCell.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { LoginScopeAppIcon } from "../../components/icons/LoginScopeAppIcons.js"
import { packageLoginScopesByResourceGroup } from "../../util/login-scope.js"

interface Props {
  scopes: Record<string, Scope[]>
}

export default function LoginScopeSummary({ scopes }: Props) {
  const intl = useIntl()
  const collator = useMemo(() => new Intl.Collator(intl.locale), [intl.locale])
  const groupedScopes = packageLoginScopesByResourceGroup(scopes)
  return (
    <Accordion allowToggle defaultIndex={0}>
      {Object.entries(groupedScopes)
        .toSorted(([a], [b]) => collator.compare(a, b))
        .map(([provider, providerScopes]) => {
          const totalNumScopes = Object.values(providerScopes).reduce(
            (partialSum, scopeArray) => partialSum + scopeArray.scopes.length,
            0
          )
          return (
            <AccordionItem key={provider}>
              <h2>
                <AccordionButton>
                  <Flex as="span" flex="1" justifyContent="space-between">
                    <Flex align="center" gap={2}>
                      <IntegrationProviderIcon
                        boxSize="8"
                        integration={{ provider: provider as IntegrationProvider }}
                      />
                      <Text>{displayNameByType[provider as IntegrationProvider]}</Text>
                    </Flex>
                    <Flex align="center" gap={2}>
                      <FormattedMessage
                        id="security.permissions.scopes.count"
                        description="Number of oauth scopes permissioned to a provider"
                        defaultMessage="{numScopes} {numScopes, plural,
                                            one {scope}
                                            other {scopes}
                                        }"
                        values={{
                          numScopes: totalNumScopes,
                        }}
                      />
                      <AccordionIcon />
                    </Flex>
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel py={0}>
                <Accordion allowMultiple defaultIndex={Array.from(Object.keys(providerScopes).keys())}>
                  {providerScopes.map((s) => (
                    <AccordionItem key={s.groupName} borderBottom={0}>
                      <h2>
                        <AccordionButton>
                          <Flex flex={1} justify="space-between" align="center">
                            <Flex gap={2} align="center">
                              <LoginScopeAppIcon appType={s.groupName} boxSize={4} />
                              {s.groupName}
                            </Flex>
                            <Flex align="center" gap={2}>
                              <FormattedMessage
                                id="security.permissions.scopes.group.count"
                                description="Number of oauth scopes permissioned to a provider by resource group"
                                defaultMessage="{numScopes} {numScopes, plural,
                                            one {scope}
                                            other {scopes}
                                        }"
                                values={{
                                  numScopes: s.scopes.length,
                                }}
                              />
                              <AccordionIcon />
                            </Flex>
                          </Flex>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel py={0}>
                        {s.scopes.map((scope, idx) => (
                          <Flex key={idx} justifyContent="space-between">
                            <Box py={1}>
                              <Box fontWeight="bold">{scope.description}</Box>
                              <Box fontWeight="light" fontSize="sm">
                                {scope.scope}
                              </Box>
                            </Box>
                            <Flex align="center">
                              <LoginScopeRiskCell riskScore={scope.risk_score} />
                            </Flex>
                          </Flex>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
    </Accordion>
  )
}
