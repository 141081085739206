import { isEmpty } from "@brm/util/type-guard.js"
import { SearchIcon } from "@brmlabs/react-pdf-viewer-search"
import {
  Box,
  Center,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  type ChakraProps,
  type StyleProps,
} from "@chakra-ui/react"
import { forwardRef, useImperativeHandle, useRef, type Ref } from "react"
import { useIntl } from "react-intl"
import { ListBox } from "./ListBox.js"

export const ListBoxWithInput = forwardRef(function ListBoxWithInput(
  {
    ariaLabel,
    iconButton,
    placeholder,
    value,
    onChange,
    children,
    isLoading,
    emptyMessage,
    ...rest
  }: {
    ariaLabel?: string
    iconButton?: React.ReactNode
    placeholder?: string
    ref?: React.RefObject<HTMLInputElement>
    /**
     * The value of the input
     */
    value: string
    /**
     * The onChange event handler for the input
     */
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    /**
     * The listbox items to render
     */
    children: React.ReactNode
    isLoading?: boolean
    /**
     * The message to display when the listbox is empty
     */
    emptyMessage?: string
  } & StyleProps &
    ChakraProps,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()
  const defaultEmptyMessage = intl.formatMessage({
    id: "listBoxWithInput.emptyMessage",
    defaultMessage: "No results found",
    description: "The default empty message for the listbox with input",
  })
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  return (
    <Stack {...rest}>
      <HStack
        borderBottomColor="gray.200"
        borderBottomWidth="1px"
        backgroundColor="white"
        gap={1}
        paddingLeft={!iconButton ? 2 : undefined}
      >
        {iconButton}
        <InputGroup justifyContent="start">
          <InputLeftElement width="fit-content">
            <Icon as={SearchIcon} color="gray.400" />
          </InputLeftElement>
          <Input
            autoFocus
            paddingLeft={6}
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            variant="outline"
            border="none"
            _focus={{ boxShadow: "none" }}
          />
        </InputGroup>
      </HStack>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : !isEmpty(children) ? (
        <ListBox maxHeight="300px" overflowY="auto" aria-label={ariaLabel}>
          {children}
        </ListBox>
      ) : (
        <Box padding={2}>
          <Text>{emptyMessage ?? defaultEmptyMessage}</Text>
        </Box>
      )}
    </Stack>
  )
})
