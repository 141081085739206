import type { FormFieldConfig } from "@brm/schema-types/types.js"

export const DETAILS_FIELDS: FormFieldConfig[] = [
  { field_name: "overview", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  { field_name: "roi_analysis", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  {
    field_name: "selection_rationale",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: true,
    is_required: false,
  },
  {
    field_name: "risks_limitations",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "implementation_details",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "support_from_vendor",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  { field_name: "tools_considered", object_type: "Tool", is_custom: false, is_internal_only: true, is_required: false },
  {
    field_name: "requested_spend",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: true,
    is_required: false,
  },
  {
    field_name: "requested_spend_interval",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: true,
    is_required: false,
  },
]

/**
 * Fields on the tool that get added to the Compliance or IT step depending on the criteria category configuration.
 */
export const TOOL_FIELDS: FormFieldConfig[] = [
  { field_name: "privacy_policy", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: false },
  {
    field_name: "terms_of_service",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  { field_name: "soc2", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  {
    field_name: "customer_security_impact_level",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  { field_name: "data_stored", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  { field_name: "is_critical", object_type: "Tool", is_custom: false, is_internal_only: true, is_required: false },
  { field_name: "stores_pii", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: false },
  { field_name: "is_subprocessor", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: false },
  { field_name: "subprocessors", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: false },
  { field_name: "sso_types", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  { field_name: "mfa_types", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  { field_name: "scim", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  {
    field_name: "access_control_types",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: false,
    is_required: true,
  },
  { field_name: "deployment_types", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: true },
  { field_name: "integrates_with", object_type: "Tool", is_custom: false, is_internal_only: false, is_required: false },
  { field_name: "requested_spend", object_type: "Tool", is_custom: false, is_internal_only: true, is_required: true },
  {
    field_name: "requested_spend_interval",
    object_type: "Tool",
    is_custom: false,
    is_internal_only: true,
    is_required: true,
  },
]

export const LEGAL_AGREEMENT_FIELDS: FormFieldConfig[] = [
  {
    field_name: "documents",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "total_contract_value",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "start_date",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "end_date",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "invoice_interval",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "first_invoice_date",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "decision_date",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: true,
    is_required: false,
  },
  {
    field_name: "net_payment_term",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "auto_renews",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "auto_renewal_opt_out_period",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
]

export const CLOSE_FIELDS: FormFieldConfig[] = [
  {
    field_name: "documents",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "total_contract_value",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "start_date",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "end_date",
    object_type: "LegalAgreement",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "w9",
    object_type: "Vendor",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
  {
    field_name: "bank_info",
    object_type: "Vendor",
    is_custom: false,
    is_internal_only: false,
    is_required: false,
  },
]
