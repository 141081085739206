import type { User, WorkflowRunStep, WorkflowRunStepLinkStateMinimal } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { ThemeTypings } from "@chakra-ui/react"
import type { WorkflowRunStatus } from "../../../app/services/generated-api.js"

export const workflowRunStatusColors: Record<WorkflowRunStatus, ThemeTypings["colorSchemes"]> = {
  in_progress: "brand",
  approved: "green",
  rejected: "error",
  cancelled: "gray",
}

export function getStepStatusColor(step: Pick<WorkflowRunStep, "status">): ThemeTypings["colorSchemes"] {
  switch (step.status) {
    case "pending":
    case "pending_submitted":
    case "cancelled":
    case "skipped":
      return "gray"
    case "in_progress":
      return "brand"
    case "submitted":
      return "blue"
    case "completed":
    case "approved":
      return "green"
    case "rejected":
      return "error"
    default:
      unreachable(step.status)
  }
}

export function getLinkStateStatusColor(
  linkState: WorkflowRunStepLinkStateMinimal,
  step: WorkflowRunStep,
  whoami: User | undefined
): ThemeTypings["colorSchemes"] {
  if (step.status === "in_progress") {
    if (!whoami) {
      return "brand"
    }
    if (linkState.submitted_at) {
      return "blue.600"
    }
  }
  return "gray.300"
}
