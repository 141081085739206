import type { DocumentMinimal, ExtractedFieldConfig } from "@brm/schema-types/types.js"
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { useState, type FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { usePostDocumentV1ByIdExtractFormMutation } from "../../../app/services/generated-api.js"
import { DocumentUpload } from "../../../components/Document/DocumentUpload.js"
import { ExtractionIcon } from "../../../components/ExtractionHighlight.js"

export interface FormUploadModalProps extends UseModalProps {
  onExtracted: (fields: ExtractedFieldConfig[], document: DocumentMinimal) => void
}

export const FormUploadModal: FunctionComponent<FormUploadModalProps> = ({ onExtracted, ...modalProps }) => {
  const toast = useToast()
  const [document, setDocument] = useState<DocumentMinimal>()

  const [extractFields, extractFieldsStatus] = usePostDocumentV1ByIdExtractFormMutation()
  const onSubmit = async (document: DocumentMinimal) => {
    try {
      const fields = await extractFields({ id: document.id }).unwrap()
      onExtracted(fields, document)
    } catch (_) {
      toast({
        description: (
          <FormattedMessage
            defaultMessage="Error extracting criteria from document"
            description="Toast message when there is an error extracting criteria from a document"
            id="settings.criteria.form_upload_modal.error"
          />
        ),
        status: "error",
      })
    }
  }

  return (
    <Modal {...modalProps} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Generate custom criteria from document"
            description="Modal header for uploading a document to generate custom criteria"
            id="settings.criteria.form_upload_modal.header"
          />{" "}
          <ExtractionIcon boxSize={undefined} />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody gap={4} display="flex" flexDirection="column">
          <Text>
            <FormattedMessage
              defaultMessage="Upload a questionnaire and BRM will import your questions as BRM standard and custom criteria."
              description="Text to explain the purpose of the document upload modal"
              id="settings.criteria.form_upload_modal.description"
            />
          </Text>
          <Flex height="17rem" flexDir="column" justifyContent="center">
            <DocumentUpload
              value={document ? [document] : []}
              multiple={false}
              onChange={([document]) => setDocument(document)}
              dropzoneProps={{ height: "16rem" }}
              dedupeDocumentByHash
            />
          </Flex>
          <Text>
            <FormattedMessage
              defaultMessage="Supported file types: PDF, CSV, DOCX, PNG, JPEG, EML, and more. Extraction may take a few minutes."
              description="Text to explain the supported file types for document upload"
              id="settings.criteria.form_upload_modal.supported_file_types"
            />
          </Text>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button onClick={modalProps.onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="Button text to cancel uploading a document"
              id="settings.criteria.form_upload_modal.cancel"
            />
          </Button>
          <Button
            colorScheme="brand"
            onClick={document && (() => onSubmit(document))}
            isDisabled={!document}
            isLoading={extractFieldsStatus.isLoading}
          >
            <FormattedMessage
              defaultMessage="Extract criteria"
              description="Button text to extract criteria from uploaded document"
              id="settings.criteria.form_upload_modal.extract_criteria"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
