import type { DocumentMinimal } from "@brm/schema-types/types.js"
import { inlineDocumentMimeTypes } from "@brm/type-helpers/document.js"
import { Button, Img, Stack, Table, Tbody, Td, Text, Tr, useToast } from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import { parse } from "csv-parse/browser/esm/sync"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useGetDocumentV1OwnByIdUrlQuery } from "../../app/services/generated-api.js"
import { log } from "../../util/logger.js"

export default function DocumentViewer({
  document,
  downloadUrl,
}: {
  document?: DocumentMinimal | null
  downloadUrl: string
}) {
  const documentMimeType = document?.mime_type
  return document ? (
    documentMimeType && inlineDocumentMimeTypes.some((type) => documentMimeType.startsWith(type)) ? (
      documentMimeType.startsWith("image/") ? (
        <Img maxH="100%" maxW="100%" objectFit="contain" src={downloadUrl} onClick={(e) => e.stopPropagation()} />
      ) : documentMimeType.startsWith("text/csv") ? (
        <CsvViewer document={document} />
      ) : (
        <chakra.iframe
          src={downloadUrl}
          width="100%"
          height="100%"
          allow=""
          {...{ csp: "" }} // The csp attribute is missing from the types
          // Note we cannot use the sandbox attribute because it blocks the PDF viewer in Chrome, see
          // https://chromium.googlesource.com/chromium/src/+/48927999f8445cf01628dc3904a317e9d7a43964
          // https://github.com/whatwg/html/issues/3958
        />
      )
    ) : (
      <Stack align="center" flexGrow={1}>
        <Text fontSize="2xl" color="gray.500">
          <FormattedMessage
            defaultMessage="File type can not be displayed in preview"
            description="Placeholder for when no preview"
            id="document.iframe.noPreview"
          />
        </Text>
        <Button as="a" href={downloadUrl} target="_blank" rel="noopener noreferrer" mt={4}>
          <FormattedMessage defaultMessage="Download" description="file download" id="document.iframe.download" />
        </Button>
      </Stack>
    )
  ) : (
    <Text fontSize="2xl" color="gray.500">
      <FormattedMessage
        defaultMessage="No document"
        description="Placeholder for when no document is available in the document viewer iframe"
        id="document.iframe.noDocument"
      />
    </Text>
  )
}

function CsvViewer({ document }: { document: DocumentMinimal }) {
  const toast = useToast()

  const downloadUrlResult = useGetDocumentV1OwnByIdUrlQuery({ id: document.id })

  const [csv, setCsv] = useState<string[][]>()

  useEffect(() => {
    if (!downloadUrlResult.data) {
      return
    }
    fetch(downloadUrlResult.data.download_url)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error(`Failed to fetch CSV: ${resp.status} ${resp.statusText}`)
        }
        if (!resp.headers.get("Content-Type")?.startsWith("text/csv")) {
          throw new Error(`Expected CSV, got ${resp.headers.get("Content-Type")}`)
        }
        return resp.text()
      })
      .then((csv) => {
        setCsv(parse(csv))
      })
      .catch((err) => {
        log.error("Error fetching CSV", err)
        toast({
          description: (
            <FormattedMessage
              defaultMessage="Error fetching CSV"
              description="Toast message when there is an error fetching a CSV"
              id="document.viewer.csv.error"
            />
          ),
          status: "error",
        })
      })
  }, [downloadUrlResult.data, toast])

  if (!csv) {
    return null
  }

  return (
    <Table variant="simple" maxWidth="100%" maxHeight="100%" minH={0} minW={0} m={4}>
      <Tbody>
        {csv.map((row, index) => (
          <Tr key={index}>
            {row.map((value, index) => (
              <Td key={index} borderWidth="1px" whiteSpace="pre-wrap" maxWidth="40ch" minWidth="20ch">
                {value}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
