import type { DateString, SpendInterval, SpendQueryKind, SpendResponse, TimePeriod } from "@brm/schema-types/types.js"
import { safeBig } from "@brm/util/currency/parse.js"
import { legacyDateToYearMonth, plainDateToLegacyDate } from "@brm/util/date-time.js"
import { formatYearMonth } from "@brm/util/format-date-time.js"
import type { CardProps } from "@chakra-ui/react"
import {
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Legend, LegendItem, LegendLabel } from "@visx/legend"
import { scaleOrdinal } from "@visx/scale"
import type { AxisProps } from "@visx/xychart/lib/components/axis/Axis.js"
import Big from "big.js"
import type React from "react"
import type { FunctionComponent, SVGProps } from "react"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { GetSpendV1ByTypeAndIdApiArg } from "../../app/services/generated-api.js"
import { useGetSpendV1ByTypeAndIdQuery } from "../../app/services/generated-api.js"
import { FormattedCurrency } from "../../components/FormattedCurrency.js"
import PercentArrowIcon from "../../components/icons/PercentArrowIcon.js"
import { BarChartIcon, LineChartIcon } from "../../components/icons/icons.js"
import { DEFAULT_CURRENCY } from "../../util/currency.js"
import { StatusCodes, getAPIErrorStatusCode } from "../../util/error.js"
import { colorForPercent } from "../../util/percent.js"
import { useWidth } from "../home/use-width.js"
import ChartCard from "./ChartCard.js"
import SeriesChart from "./SeriesChart.js"
import { paymentMethodColors } from "./constants.js"
import { type AllPaymentMethods, type OptionWithLabel, type SpendDatum, type XYChartMinimalProps } from "./types.js"
import {
  abbreviatedMonthAxisProps,
  displayTimePeriod,
  getChartTimePeriodDateParams,
  spendChartTimePeriodOptions,
} from "./utils.js"

type SpendChartType = "line" | "stacked-bar"
const iconByChartType: Record<SpendChartType, React.ComponentType<SVGProps<SVGSVGElement>>> = {
  line: LineChartIcon,
  "stacked-bar": BarChartIcon,
}

interface Props extends CardProps {
  chartHeight: number
  entityParams: Pick<GetSpendV1ByTypeAndIdApiArg, "id" | "type">
  xyChartProps?: XYChartMinimalProps
  axisProps?: AxisProps[]
  startingPeriod?: TimePeriod
  pollingInterval?: number
  spendChartTypes?: SpendChartType[]
}

const CHART_HEIGHT = "22.5rem"
const LEGEND_GLYPH_SIZE = 12
export const SpendChartCard: FunctionComponent<Props> = ({
  chartHeight,
  entityParams,
  xyChartProps,
  axisProps,
  startingPeriod = "last_twelve_months",
  spendChartTypes = ["stacked-bar", "line"],
  ...cardProps
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriod>(startingPeriod)
  const [selectedPeriodChanged, setSelectedPeriodChanged] = useState(false)
  const dateParams = getChartTimePeriodDateParams(selectedPeriod)

  const {
    data: currentData,
    isLoading: isCurrentDataLoading,
    error: currentDataError,
  } = useGetSpendV1ByTypeAndIdQuery({
    ...entityParams,
    startDate: dateParams.startDate,
    endDate: dateParams.endDate,
    interval: dateParams.interval,
    currencyCode: DEFAULT_CURRENCY,
  })
  const {
    data: previousData,
    isLoading: isPreviousDataLoading,
    error: previousDataError,
  } = useGetSpendV1ByTypeAndIdQuery({
    ...entityParams,
    startDate: dateParams.prevStartDate,
    endDate: dateParams.prevEndDate,
    currencyCode: DEFAULT_CURRENCY,
  })

  const [chartType, setChartType] = useState(spendChartTypes[0] ?? "line")

  const intl = useIntl()

  const displayLabelForPaymentMethod = useMemo<Record<AllPaymentMethods, string>>(() => {
    return {
      bill_payment: intl.formatMessage({
        id: "spendChart.bar.billPaymentLabel",
        description: "The label for the bill payment currency amount",
        defaultMessage: "Bill Payment",
      }),
      credit_card: intl.formatMessage({
        id: "spendChart.bar.creditCardLabel",
        description: "The label for the credit card currency amount",
        defaultMessage: "Credit Card",
      }),
      other: intl.formatMessage({
        id: "spendChart.bar.otherPaymentsLabel",
        description: "The label for the other payments amount",
        defaultMessage: "Other",
      }),
    }
  }, [intl])

  const { billPaymentMap, creditCardMap, otherMap, spendChange } = useMemo(() => {
    const billPaymentMap: Map<DateString, SpendDatum> = new Map()
    const creditCardMap: Map<DateString, SpendDatum> = new Map()
    const otherMap: Map<DateString, SpendDatum> = new Map()

    const currentSpendTotal = currentData ? Big(currentData.total.amount) : undefined
    const previousSpendTotal = previousData ? Big(previousData.total.amount) : undefined
    const spendChange =
      currentSpendTotal && previousSpendTotal && !previousSpendTotal.eq(0)
        ? currentSpendTotal.minus(previousSpendTotal).div(previousSpendTotal)
        : undefined

    currentData?.intervals?.forEach((interval) => {
      const date = plainDateToLegacyDate(interval.start_date)
      billPaymentMap.set(date.toISOString(), {
        start_date: date,
        currency_amount: interval.bill_payment_currency_amount,
        display_label: displayLabelForPaymentMethod.bill_payment,
      })
      creditCardMap.set(date.toISOString(), {
        start_date: date,
        currency_amount: interval.credit_card_currency_amount,
        display_label: displayLabelForPaymentMethod.credit_card,
      })
      otherMap.set(date.toISOString(), {
        start_date: date,
        currency_amount: interval.other_currency_amount,
        display_label: displayLabelForPaymentMethod.other,
      })
    })
    return {
      billPaymentMap,
      creditCardMap,
      otherMap,
      spendChange,
      currentSpendTotal,
      previousSpendTotal,
    }
  }, [currentData, previousData, displayLabelForPaymentMethod])

  if (
    getAPIErrorStatusCode(currentDataError) === StatusCodes.FORBIDDEN ||
    getAPIErrorStatusCode(previousDataError) === StatusCodes.FORBIDDEN ||
    !currentData?.intervals ||
    (!selectedPeriodChanged &&
      currentData.intervals.every((interval) => safeBig(interval.currency_amount.amount)?.eq(0)))
  ) {
    return null
  }

  const ariaLabelByChartType: Record<SpendChartType, string> = {
    line: intl.formatMessage({
      id: "spendChartCard.lineButton",
      description: "aria label for show lineChart button",
      defaultMessage: "Show Line chart",
    }),
    "stacked-bar": intl.formatMessage({
      id: "spendChartCard.barButton",
      description: "aria label for show barChart button",
      defaultMessage: "Show Bar chart",
    }),
  }

  const chartTitleByType: Record<SpendQueryKind, string> = {
    vendor: intl.formatMessage({
      id: "spendChart.title",
      description: "The spend chart title",
      defaultMessage: "Vendor Spend",
    }),
    organization: intl.formatMessage({
      id: "spendChart.title",
      description: "The spend chart title",
      defaultMessage: "Organization Spend",
    }),
    tool: intl.formatMessage({
      id: "spendChart.title",
      description: "The spend chart title",
      defaultMessage: "Tool Spend",
    }),
  }

  const accessors: Record<AllPaymentMethods, Map<DateString, SpendDatum>> = {
    bill_payment: billPaymentMap,
    credit_card: creditCardMap,
    other: otherMap,
  }

  // The ChartCard component is 22.5rem high
  if (isCurrentDataLoading || isPreviousDataLoading) {
    return <Skeleton height={CHART_HEIGHT} />
  }

  return (
    <ChartCard<OptionWithLabel<TimePeriod>>
      {...cardProps}
      height={CHART_HEIGHT}
      title={chartTitleByType[entityParams.type]}
      description={
        currentData && (
          <HStack gap={4}>
            <Heading size="md" color="gray.900">
              <FormattedCurrency currencyAmount={currentData.total} />
            </Heading>
            {spendChange && (
              <Flex gap={1} fontSize="sm" color={colorForPercent(spendChange.toNumber())}>
                <PercentArrowIcon percent={spendChange.toNumber()} />
                <FormattedMessage
                  id="spendChart.spendChange.percent"
                  description="Abbreviation for year-over-year"
                  defaultMessage="YoY"
                />
              </Flex>
            )}
          </HStack>
        )
      }
      selectProps={{
        options: spendChartTimePeriodOptions(),
        value: { value: selectedPeriod, label: displayTimePeriod(selectedPeriod) },
        onChange: (newSelection) => {
          if (newSelection) {
            setSelectedPeriodChanged(true)
            setSelectedPeriod(newSelection.value)
          }
        },
      }}
      legend={
        <Legend
          scale={scaleOrdinal({
            domain: Array.from(Object.keys(paymentMethodColors)),
            range: Array.from(Object.values(paymentMethodColors)),
          })}
        >
          {(labels) => (
            <HStack hidden={chartType !== "stacked-bar"} flexWrap="wrap" gap={4}>
              {labels.map((label) => {
                const key = label.datum as AllPaymentMethods
                return (
                  <LegendItem key={key}>
                    <svg width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE}>
                      <rect fill={label.value} width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE} />
                    </svg>
                    <LegendLabel align="left" margin="0 0 0 4px">
                      <Text fontSize="xs">{displayLabelForPaymentMethod[key]}</Text>
                    </LegendLabel>
                  </LegendItem>
                )
              })}
            </HStack>
          )}
        </Legend>
      }
      footer={
        spendChartTypes.length > 1 && (
          <ButtonGroup gap="0" isAttached>
            {spendChartTypes.map((type) => (
              <IconButton
                key={type}
                size="sm"
                variant="outline"
                aria-label={ariaLabelByChartType[type]}
                icon={<Icon as={iconByChartType[type]} />}
                isActive={type === chartType}
                aria-pressed={type === chartType}
                onClick={(e) => {
                  setChartType(type)
                  e.stopPropagation()
                }}
              />
            ))}
          </ButtonGroup>
        )
      }
    >
      {currentData && currentData.intervals && previousData ? (
        <SpendChart
          currentData={{ ...currentData, intervals: currentData.intervals }}
          accessors={accessors}
          chartHeight={chartHeight}
          chartType={chartType}
          axisProps={axisProps}
          xyChartProps={xyChartProps}
        />
      ) : isCurrentDataLoading || isPreviousDataLoading ? (
        <Spinner />
      ) : (
        <Text color="gray.600" display="flex" py={6} px={5}>
          <FormattedMessage
            id="spendChart.failedToLoad"
            description="failed to fetch spend chart data"
            defaultMessage="Failed to load spend data"
          />
        </Text>
      )}
    </ChartCard>
  )
}

interface ChartProps {
  currentData: SpendResponse & { intervals: SpendInterval[] }
  accessors: Record<AllPaymentMethods, Map<DateString, SpendDatum>>
  xyChartProps?: XYChartMinimalProps
  axisProps?: AxisProps[]
  chartHeight: number
  chartType: SpendChartType
}

const SpendChart: FunctionComponent<ChartProps> = ({
  currentData,
  accessors,
  xyChartProps,
  axisProps,
  chartHeight,
  chartType,
}) => {
  const intl = useIntl()
  const { width, ref } = useWidth<HTMLDivElement>(0)

  return (
    <Stack ref={ref} flexGrow={1} gap={0}>
      {safeBig(currentData.total.amount)?.eq(0) ? (
        <Text fontSize="lg" color="gray.600" alignSelf="center" align="center" py={8}>
          <FormattedMessage
            id="spendChart.emptyState"
            description="The spend chart empty state"
            defaultMessage="No spending data to display this period"
          />
        </Text>
      ) : width > 0 ? (
        <SeriesChart<SpendDatum, AllPaymentMethods>
          xyChartProps={xyChartProps}
          width={width}
          height={chartHeight}
          axisProps={axisProps ?? [abbreviatedMonthAxisProps(intl)]}
          chartData={
            chartType === "stacked-bar"
              ? {
                  type: "stacked-bar",
                  accessors,
                  colorAccessor: (paymentMethod) => paymentMethodColors[paymentMethod],
                  data: Object.entries(accessors).map(([key, value]) => ({
                    key: key as AllPaymentMethods,
                    intervals: Array.from(value.values()),
                  })),
                }
              : {
                  type: "line",
                  data: currentData.intervals.map(({ start_date, currency_amount }) => ({
                    // visx / d3 uses legacy Date objects for time scales. We do the conversion manually with the Temporal to
                    // make sure there are no off-by-one/timezone bugs.
                    start_date: plainDateToLegacyDate(start_date),
                    currency_amount,
                    display_label: intl.formatMessage({
                      id: "spendChart.amountLabel",
                      description: "The label for the amount in the spend chart",
                      defaultMessage: "Amount",
                    }),
                  })),
                }
          }
          datumTransformers={{
            tooltip: (datum) => (
              <Stack spacing={1}>
                <Text fontWeight="normal" mb={1}>
                  {formatYearMonth(intl, legacyDateToYearMonth(datum.start_date), {
                    year: "numeric",
                    month: "long",
                  })}
                </Text>
                <Text>
                  {datum.display_label} <FormattedCurrency currencyAmount={datum.currency_amount} />
                </Text>
              </Stack>
            ),
            stackedTooltip: (data) => {
              const firstDatum = data[0]
              if (!firstDatum) return null

              let total: Big = new Big(0)
              data.forEach((datum) => {
                total = total.plus(datum.currency_amount.amount)
              })

              const nonZeroData = data.filter((datum) => !safeBig(datum.currency_amount.amount)?.eq(0))

              return (
                <Stack spacing={1}>
                  <Text fontWeight="normal">
                    {formatYearMonth(intl, legacyDateToYearMonth(firstDatum.start_date), {
                      year: "numeric",
                      month: "long",
                    })}
                  </Text>
                  {nonZeroData.map((datum) => (
                    <div key={datum.display_label}>
                      {datum.display_label} <FormattedCurrency currencyAmount={datum.currency_amount} />
                    </div>
                  ))}
                  {nonZeroData.length !== 1 && (
                    <>
                      {nonZeroData.length > 1 && <Divider />}
                      <div>
                        <FormattedMessage
                          id="spendChart.total"
                          description="The total amount of the stacked bar"
                          defaultMessage="Total: {total}"
                          values={{
                            total: (
                              <FormattedCurrency
                                currencyAmount={{ amount: total.toString(), currency_code: DEFAULT_CURRENCY }}
                              />
                            ),
                          }}
                        />
                      </div>
                    </>
                  )}
                </Stack>
              )
            },
            accessorKey: (datum) => datum.start_date.toISOString(),
            xAccessor: (datum) => datum && datum.start_date,
            yAccessor: (datum) => datum && parseFloat(datum.currency_amount.amount),
          }}
        />
      ) : null}
    </Stack>
  )
}
