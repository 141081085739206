import type { CurrencyAmount, FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { COMMON_CURRENCIES, MORE_CURRENCIES } from "@brm/util/currency/consts.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { getPrecision } from "@brm/util/currency/precision.js"
import { isEmpty } from "@brm/util/type-guard.js"
import type { FormControlProps, InputProps } from "@chakra-ui/react"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  css,
  useDisclosure,
  useStyleConfig,
  useTheme,
} from "@chakra-ui/react"
import { Select, chakraComponents, type GroupBase, type Props } from "chakra-react-select"
import equal from "fast-deep-equal"
import type { ForwardedRef, ReactNode, Ref, RefObject } from "react"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { FormattedDisplayName, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../SchemaForm/OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "../SchemaForm/types.js"

function withoutSymbol(parts: Intl.NumberFormatPart[]): string {
  return parts
    .filter((part) => part.type !== "currency")
    .map((part) => part.value)
    .join("")
    .trim()
}

const defaultCurrency = "USD"

type CurrencyAmountFormControlProps = FormControlProps &
  CurrencyAmountInputGroupProps & {
    legend: ReactNode
    errorMessage?: ReactNode
  }

/**
 * A compound select box + input for a currency amount.
 */
export const CurrencyAmountFormControl = forwardRef(function CurrencyAmountFormControl(
  {
    legend,
    errorMessage,
    value,
    onChange,
    isReadOnly,
    background,
    ...formControlProps
  }: CurrencyAmountFormControlProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <FormControl as="fieldset" isReadOnly={isReadOnly} {...formControlProps}>
      <FormLabel as="legend">{legend}</FormLabel>
      <CurrencyAmountInputGroup
        ref={ref}
        value={value}
        onChange={onChange}
        isReadOnly={isReadOnly}
        background={background}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

interface CurrencyAmountInputGroupProps {
  value: CurrencyAmount | null
  onChange?: (value: CurrencyAmount | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  background?: InputProps["background"]
  suggestions?: ValueWithSource<CurrencyAmount>[]
  disabled?: boolean
  forceRerender?: () => void
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
}

export const CurrencyAmountInputGroup = forwardRef(function CurrencyAmountInputGroup(
  {
    value,
    onChange,
    isReadOnly,
    background,
    disabled,
    suggestions,
    forceRerender,
    fieldMetadata,
    fieldApproval,
    menuPortalTarget,
  }: CurrencyAmountInputGroupProps & Pick<Props, "menuPortalTarget">,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()
  const theme = useTheme()

  // Keep ref to move focus to the amount input when the currency code is changed.
  const amountInputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => amountInputRef.current)
  const inputGroupRef = useRef<HTMLDivElement>(null)

  // Keep internal state of currency code so that the currency code can be set even if amount is empty.
  const [currencyCode, setCurrencyCode] = useState<string>(value?.currency_code ?? defaultCurrency)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (value && value.currency_code !== currencyCode) {
      setCurrencyCode(value.currency_code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.currency_code])
  const decimals = getPrecision(currencyCode)

  // If isReadOnly, override background setting to be gray.50
  const bgColor = isReadOnly ? "gray.50" : background

  // react-currency-input doesn't expose the isReadOnly prop, so we need to hook into the input rendering with a
  // closure. useMemo() is needed so the component reference doesn't change on each render and cause a re-render
  // and focus loss. Note: If isReadOnly DOES change, this WILL cause a focus loss, so any dependencies of this
  // useMemo() must not be updated while typing.
  const customInput = useMemo(
    () =>
      forwardRef<HTMLInputElement>(function CustomInput(props, ref) {
        return (
          <Input ref={ref} {...props} isReadOnly={isReadOnly} sx={{ _focus: { outline: "none", boxShadow: "none" } }} />
        )
      }),
    [isReadOnly]
  )

  // Trim decimals on first render according to the currency on render because our backend always returns 4
  // decimals for simplicity. The NumericString is essentially a US locale floating number without decimal
  // separators and without a currency sign, so we can use Intl.NumberFormat for this.
  function trimOrPadAmount(amount: string): string {
    return withoutSymbol(
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyCode,
        useGrouping: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).formatToParts(amount as any)
    )
  }

  // Intentionally only compute once on first render and when the currency selector needs to be rerendered (see
  // `key` prop of <CurrencyInput> below).
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValue = useMemo<string>(() => (value?.amount && trimOrPadAmount(value.amount)) || "", [currencyCode])
  const inputStyleConfig = useStyleConfig("Input")

  return (
    <Flex
      ref={inputGroupRef}
      // Only open dropdown when suggestions are available. Otherwise drop down is only openable via currency selector
      onFocus={suggestions && suggestions.length > 0 ? onOpen : undefined}
      onBlur={onClose}
      // Input like styles
      borderRadius={8}
      borderWidth={1}
      borderColor={isOpen ? "brand.500" : "gray.200"}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sx={isOpen ? (inputStyleConfig as any)?.field?._focus : {}}
    >
      <CurrencyInput
        ref={amountInputRef}
        // Need to force a rerender of the component when currency changes as it does not update when intlConfig
        // changes.
        key={currencyCode}
        intlConfig={{ locale: intl.locale, currency: currencyCode }}
        // Use defaultValue to allow CurrencyInput to be uncontrolled/have its own internal state for the current
        // input value (which may not always be a valid NumericString, e.g. right after the user typed a decimal
        // point)
        defaultValue={defaultValue}
        // Doesn't work unfortunately because empty string triggers the default locale option.
        // prefix=""
        onValueChange={(amount?: string) => {
          const trimmedAmount = amount && trimOrPadAmount(amount)
          // Don't call onChange when value is the same to prevent unnecessary re-renders.
          if (value?.amount && trimmedAmount === trimOrPadAmount(value.amount)) {
            return
          }
          // isNaN protects against some edge cases of copy-pasting invalid number strings I saw in testing.
          if (!trimmedAmount || isNaN(parseFloat(amount))) {
            onChange?.(null)
          } else {
            onChange?.({ amount: trimmedAmount, currency_code: currencyCode })
          }
        }}
        // Don't show the currency in the placeholder to not mislead users into assuming they have to type in a
        // currency symbol.
        placeholder={withoutSymbol(intl.formatNumberToParts(0, { style: "currency", currency: currencyCode }))}
        customInput={customInput}
        disabled={disabled}
        style={{
          flexGrow: 1,
          flexShrink: 1,
          borderWidth: 0,
          // Evaluate all Chakra color token references
          ...css({ background: bgColor })(theme),
        }}
        allowNegativeValue={false}
        // Needs to be dynamic per currency, default is 2 for all currencies
        decimalsLimit={decimals}
        // Needs to be set explicitly because decimalsLimit: 0 is ignored and defaults to 2
        allowDecimals={decimals > 0}
        // Do NOT set decimalScale={decimals}. react-currency-input uses Intl.NumberFormat() with default
        // minimum/maximumFractionDigits under the hood, which sometimes uses different defaults for some
        // currencies than our mapping. This is because our mapping (as well as Safari and Firefox) reference ISO
        // 4217, which is often more legalistic than what is used in practice. Chrome/Chromium however uses
        // Unicode CLDR, which is more practical. There are a handful of currencies that have 0 decimals in
        // Chrome but 2-3 decimals in Safari/Firefox and our mapping. In practice this is not a big problem,
        // because the user will still be able to enter data, just with less precision. If we set decimalScale
        // though, which tries padding and trimming the value, it causes problems if our mapping and
        // Intl.NumberFormat() disagree. This would be fixed if react-currency-input passed decimalsLimit and
        // decimalsScale through to Intl.NumberFormat(). Further reading:
        // - https://github.com/tc39/ecma402/issues/134
        // - https://bugs.chromium.org/p/chromium/issues/detail?id=737967
        // - https://cldr.unicode.org/
        // - https://www.six-group.com/dam/download/financial-information/data-center/iso-currrency/lists/list-one.xml
        //
        // Do NOT set fixedDecimalValue, see https://github.com/cchanxzy/react-currency-input-field/issues/292
      />
      <CurrencySelect
        disabled={disabled}
        value={{ amount: value?.amount || "", currency_code: currencyCode }}
        suggestions={suggestions}
        onChange={(option) => {
          let fieldSource = option.field_sources?.[0]
          const newValue = {
            amount: trimOrPadAmount(option.value?.amount || ""),
            currency_code: option.value?.currency_code || "",
          }
          // Fill fieldSource if selected option values match a suggestion
          if (!fieldSource) {
            const matchingSuggestion = suggestions?.find(
              (suggestion) =>
                trimOrPadAmount(suggestion.value.amount) === newValue.amount &&
                suggestion.value.currency_code === newValue.currency_code
            )
            fieldSource = matchingSuggestion?.field_sources?.[0]
          }
          setCurrencyCode(newValue?.currency_code ?? defaultCurrency)

          // Update only when value changes or suggested value is selected to prevent fieldSource resetting
          if (newValue && (suggestions?.some((suggestion) => equal(option, suggestion)) || !equal(newValue, value))) {
            onChange?.(newValue, fieldSource)
          }
          // Force rerender of the currency input
          forceRerender?.()
          onClose()
        }}
        isReadOnly={isReadOnly}
        isOpen={isOpen}
        parentRef={inputGroupRef}
        fieldMetadata={fieldMetadata}
        fieldApproval={fieldApproval}
        onClose={onClose}
        onOpen={onOpen}
        menuPortalTarget={menuPortalTarget}
      />
    </Flex>
  )
})

/**
 * A select box for a currency code.
 */
const CurrencySelect = forwardRef(function CurrencySelect(
  {
    value,
    onChange,
    isReadOnly,
    disabled,
    suggestions,
    isOpen,
    parentRef,
    fieldMetadata,
    onOpen,
    onClose,
    fieldApproval,
    menuPortalTarget,
  }: {
    /** The current 3-character ISO currency code. */
    value: CurrencyAmount

    /** Called with the new currency code. */
    onChange: (value: ValueWithSource<CurrencyAmount | undefined>) => void
    isReadOnly?: boolean
    disabled?: boolean
    suggestions?: ValueWithSource<CurrencyAmount>[]
    fieldMetadata?: FieldMetadataWithSuggestions
    isOpen?: boolean
    parentRef?: RefObject<HTMLDivElement>
    onOpen?: () => void
    onClose?: () => void
    fieldApproval?: SchemaFormFieldApproval
  } & Pick<Props, "menuPortalTarget">,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  return (
    // Make sure that Enter in the menu doesn't submit a surrounding form
    <Box display="contents">
      <Select<
        ValueWithSource<CurrencyAmount | undefined>,
        false,
        GroupBase<ValueWithSource<CurrencyAmount | undefined>>
      >
        value={{ value, field_sources: fieldMetadata ? [fieldMetadata] : [] }}
        isOptionSelected={(option) => {
          if (!option.value) {
            return false
          }
          const amountEquals = formatCurrency(option.value, intl) === formatCurrency(value, intl)
          const fieldSourceEquals =
            option.field_sources?.some((source) => source.id === fieldMetadata?.id) ||
            (isEmpty(option.field_sources) && fieldMetadata?.type === "user")

          return amountEquals && fieldSourceEquals
        }}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SingleValue: (props) => (
            <chakraComponents.SingleValue {...props}>{props.data.value?.currency_code}</chakraComponents.SingleValue>
          ),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option: (props) => {
            const fieldSources = props.data.field_sources
            return fieldSources && fieldSources.length > 0 ? (
              <chakraComponents.Option {...props}>
                <OptionWithFieldSource fieldSources={fieldSources}>
                  {formatCurrency(props.data.value, intl)}
                </OptionWithFieldSource>
              </chakraComponents.Option>
            ) : (
              <chakraComponents.Option {...props}>
                <Text as="span" mr={2}>
                  {props.data.value?.currency_code}
                </Text>
                <Text
                  as="span"
                  fontSize="sm"
                  opacity={0.7}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <FormattedDisplayName value={props.data.value?.currency_code ?? ""} type="currency" fallback="none" />
                </Text>
              </chakraComponents.Option>
            )
          },
        }}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        menuIsOpen={isOpen}
        isSearchable={false}
        menuPortalTarget={menuPortalTarget}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        chakraStyles={{
          container: (styles) => ({ ...styles, flexGrow: 0, flexShrink: 0, flexBasis: "96px" }),
          indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
          // Remove padding from the group heading because we only want to display the bottom border and no text.
          groupHeading: (styles) => ({ ...styles, borderBottomWidth: 1, px: 2, height: 0, padding: 0 }),
          dropdownIndicator: (styles) => ({
            ...styles,
            px: 1,
            display: isReadOnly ? "none" : "flex",
            background: "none",
          }),

          // This is a hack to make the menu the same width as the input field.
          // TODO: To improve this, we should override react-select's input with the currency input
          menu: (styles) => ({ ...styles, width: parentRef?.current?.clientWidth, right: 0 }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              ...(isNew && {
                backgroundColor: `${colorScheme}.50`,
              }),
            }
          },
          control: (styles) => ({
            ...styles,
            borderWidth: 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _focus: { ...(styles as any)._focus, boxShadow: "none" },
          }),
          valueContainer: (styles) => ({ ...styles, paddingInlineStart: 4, paddingInlineEnd: 1 }),
        }}
        options={[
          {
            options: suggestions || [],
          },
          {
            options: COMMON_CURRENCIES.map((currency) => ({
              value: { amount: value.amount, currency_code: currency },
              fieldSources: [],
            })),
          },
          {
            options: MORE_CURRENCIES.map((currency) => ({
              value: { amount: value.amount, currency_code: currency },
              fieldSources: [],
            })),
          },
        ]}
        onChange={(v) => {
          if (v) {
            onChange(v)
          }
        }}
        isReadOnly={isReadOnly}
        isDisabled={disabled}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
      />
    </Box>
  )
})
