export function isApplePlatform() {
  const platform = navigator.platform.toUpperCase()
  return platform.includes("MAC") || platform.includes("IPAD")
}

export function isSafari() {
  return navigator.userAgent.includes("AppleWebKit") && !navigator.userAgent.includes("Chrome")
}

export function isChromium() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(window as any).chrome
}
