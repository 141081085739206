import { HStack, Icon, Text } from "@chakra-ui/react"
import { FormattedNumber } from "react-intl"
import { colorForPercent } from "../../util/percent.js"
import { ArrowNarrowDownIcon, ArrowNarrowUpIcon } from "./icons.js"

export default function PercentArrowIcon({ percent }: { percent: number }) {
  return (
    <HStack color={colorForPercent(percent)} fontSize="xs" justifyContent="right" gap={0}>
      {percent > 0 && <Icon as={ArrowNarrowUpIcon} />}
      {percent < 0 && <Icon as={ArrowNarrowDownIcon} />}
      <Text as="span">
        <FormattedNumber value={percent} style="percent" minimumFractionDigits={0} maximumFractionDigits={1} />
      </Text>
    </HStack>
  )
}
