import { Button, chakra } from "@chakra-ui/react"
import { useEffect, useRef, useState, type FunctionComponent, type ReactNode } from "react"
import { FormattedMessage } from "react-intl"

/**
 * Truncates the given text to the given number of lines with ellipses, and shows a "Show more"/"Show less" button
 * (if truncated). Uses CSS `line-clamp` and therefor supports rich formatted content as `children`.
 */
export const Truncate: FunctionComponent<{ children: ReactNode; noOfLines: number }> = ({ children, noOfLines }) => {
  const [textExpanded, setTextExpanded] = useState(false)
  const resizeObserver = useRef<ResizeObserver | null>(null)
  useEffect(() => {
    return () => {
      resizeObserver.current?.disconnect()
      resizeObserver.current = null
    }
  }, [])
  return (
    <chakra.span>
      <chakra.span
        noOfLines={textExpanded ? undefined : noOfLines}
        data-expanded={textExpanded}
        ref={(element) => {
          if (element) {
            element.dataset.truncated = (element.scrollHeight > element.clientHeight).toString()
            resizeObserver.current = new ResizeObserver(() => {
              element.dataset.truncated = (element.scrollHeight > element.clientHeight).toString()
            })
            resizeObserver.current.observe(element)
          } else {
            resizeObserver.current?.disconnect()
            resizeObserver.current = null
          }
        }}
      >
        {children}
      </chakra.span>

      <Button
        variant="link"
        colorScheme="brand"
        onClick={() => setTextExpanded((s) => !s)}
        fontSize="xs"
        // Screen readers always expose the entire text, so the button would be confusing.
        aria-hidden="true"
        sx={{
          // eslint-disable-next-line prefer-smart-quotes/prefer
          '[data-expanded="false"][data-truncated="false"] + &': {
            display: "none",
          },
        }}
      >
        {textExpanded ? (
          <FormattedMessage id="tool.context.text.show_less" description="Show less text" defaultMessage="Show less" />
        ) : (
          <FormattedMessage id="tool.context.text.show_more" description="Show more text" defaultMessage="Show more" />
        )}
      </Button>
    </chakra.span>
  )
}
