import type { TimePeriod } from "@brm/schema-types/types.js"
import { TimePeriodSchema } from "@brm/schemas"
import { getCurrentTimePeriod } from "@brm/type-helpers/time-period.js"
import { legacyDateToPlainDate } from "@brm/util/date-time.js"
import { formatDate, formatYearMonth } from "@brm/util/format-date-time.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { AxisProps } from "@visx/xychart/lib/components/axis/Axis.js"
import type { IntlFormatters, IntlShape } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { DEFAULT_CURRENCY } from "../../util/currency.js"
import { defaultGridStroke, defaultTickLabelProps } from "./constants.js"
import { type ChartTimePeriodDateParams } from "./types.js"

export const abbreviatedMonthAxisProps = (intl: IntlShape): AxisProps => ({
  orientation: "bottom",
  tickLength: 0,
  hideAxisLine: true,
  // visx / d3 uses legacy Dates for time scales
  // eslint-disable-next-line no-restricted-globals
  tickFormat: (date: Date) => formatYearMonth(intl, legacyDateToPlainDate(date), { month: "short" }),
})

export const abbreviatedDateAxisProps = (intl: IntlShape): AxisProps => ({
  orientation: "bottom",
  tickLength: 0,
  hideAxisLine: true,
  // visx / d3 uses legacy Dates for time scales
  // Format ticks as "M/d"
  // eslint-disable-next-line no-restricted-globals
  tickFormat: (date: Date) => formatDate(intl, legacyDateToPlainDate(date), { month: "numeric", day: "numeric" }),
})

export const displayTimePeriod = (timePeriod: TimePeriod): string => {
  const timePeriodSchema = TimePeriodSchema.anyOf.find((t) => t.const === timePeriod)
  isNotUndefined(timePeriodSchema)
  return timePeriodSchema.title
}

export const userActivityChartTimePeriodOptions = () => {
  const userActivityChartTimePeriodValues: TimePeriod[] = ["last_twelve_months", "last_thirty_days"]
  return userActivityChartTimePeriodValues.map((timePeriod) => ({
    value: timePeriod,
    label: displayTimePeriod(timePeriod),
  }))
}

export const spendChartTimePeriodOptions = () => {
  const spendChartTimePeriodValues: TimePeriod[] = ["last_twelve_months", "year_to_date"]
  return spendChartTimePeriodValues.map((timePeriod) => ({
    value: timePeriod,
    label: displayTimePeriod(timePeriod),
  }))
}

export const getChartTimePeriodDateParams = (timePeriod: TimePeriod): ChartTimePeriodDateParams => {
  const currentTimePeriod = getCurrentTimePeriod(timePeriod)

  let interval: string
  switch (timePeriod) {
    case "last_twelve_months":
    case "year_to_date": {
      interval = "P1M"
      break
    }
    case "month_to_date":
    case "last_thirty_days": {
      interval = "P1D"
      break
    }
    default:
      unreachable(timePeriod)
  }

  return {
    startDate: currentTimePeriod.start.toPlainDate().toString(),
    endDate: currentTimePeriod.end.toPlainDate().toString(),
    prevStartDate: currentTimePeriod.prevStart.toPlainDate().toString(),
    prevEndDate: currentTimePeriod.prevEnd.toPlainDate().toString(),
    interval,
  }
}

export const getDefaultSpendChartXAxisProps = (intl: IntlFormatters): AxisProps => {
  return {
    orientation: "bottom",
    // visx automatically converts the values to Date objects if the type is "time"
    // eslint-disable-next-line no-restricted-globals
    tickFormat: (date: Date) => formatYearMonth(intl, legacyDateToPlainDate(date), { year: "numeric", month: "short" }),
    tickStroke: defaultGridStroke,
    tickLabelProps: defaultTickLabelProps,
    stroke: defaultGridStroke,
    strokeWidth: 1,
    left: -1.5,
    numTicks: 5,
  }
}

export const getDefaultSpendChartYAxisProps = (intl: IntlFormatters): AxisProps => {
  return {
    orientation: "left",
    tickFormat: (amount: number) =>
      intl.formatNumber(amount, {
        compactDisplay: "short",
        notation: "compact",
        style: "currency",
        currency: DEFAULT_CURRENCY,
      }),
    stroke: defaultGridStroke,
    tickLabelProps: defaultTickLabelProps,
    tickStroke: defaultGridStroke,
    numTicks: 4,
  }
}
