import { Button, Flex } from "@chakra-ui/react"
import { Group } from "@visx/group"
import Pie from "@visx/shape/lib/shapes/Pie"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "../../../components/Link.js"
import ChartCard from "../../charts/ChartCard.js"
import { PIE_CHART_DEFAULT_MARGIN, pieChartHeight, pieChartWidth } from "../../charts/constants.js"
import type { OptionWithLabel } from "../../charts/types.js"

interface Props {
  width?: number
  height?: number
  usersCount: {
    l90d: number
    l60d: number
    l30d: number
    total: number
  }
  margin?: typeof PIE_CHART_DEFAULT_MARGIN
}

const usageOptions = ["l30d", "l60d", "l90d"] as const
type UsageSelection = (typeof usageOptions)[number]

export default function ToolUsagePie({
  width = pieChartWidth,
  height = pieChartHeight,
  usersCount,
  margin = PIE_CHART_DEFAULT_MARGIN,
}: Props) {
  const intl = useIntl()

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const donutThickness = 25

  const [selection, setSelection] = useState<UsageSelection>("l30d")
  const usageRecord: Record<UsageSelection, number> = {
    l30d: usersCount.l30d,
    l60d: usersCount.l60d,
    l90d: usersCount.l90d,
  }
  if (usersCount.total === 0) {
    return null
  }

  const usagePct = usageRecord[selection] / usersCount.total

  const selectionDisplayName: Record<UsageSelection, string> = {
    l30d: intl.formatMessage({
      id: "tool.context.usage.pie.30d",
      description: "Label for the 30 day usage pie chart selection",
      defaultMessage: "Last 30 days",
    }),
    l60d: intl.formatMessage({
      id: "tool.context.usage.pie.60d",
      description: "Label for the 60 day usage pie chart selection",
      defaultMessage: "Last 60 days",
    }),
    l90d: intl.formatMessage({
      id: "tool.context.usage.pie.90d",
      description: "Label for the 90 day usage pie chart selection",
      defaultMessage: "Last 90 days",
    }),
  }

  return (
    <ChartCard<OptionWithLabel<UsageSelection>>
      title={intl.formatMessage({
        id: "tool.context.usage.activity.title",
        description: "Title for the tool context usage pie chart",
        defaultMessage: "User Activity",
      })}
      selectProps={{
        options: usageOptions.map((option) => ({ value: option, label: selectionDisplayName[option] })),
        value: { value: selection, label: selectionDisplayName[selection] },
        onChange: (newSelection) => {
          if (newSelection) {
            setSelection(newSelection.value)
          }
        },
      }}
      footer={
        <Button as={Link} variant="outline" to="../security" fontSize="sm">
          <FormattedMessage
            id="tool.context.usage.activity.viewAll"
            description="Button to view all activity"
            defaultMessage="View all activity"
          />
        </Button>
      }
    >
      <Flex alignItems="center" justifyContent="center" height="100%">
        <svg width={width} height={height}>
          <rect rx={14} width={width} height={height} fill="transparent" />
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Pie
              data={[
                { usage: usagePct, label: "used" },
                { usage: 1 - usagePct, label: "unused" },
              ]}
              pieValue={(u) => u.usage}
              // Sort the pie elements so that the "used" slice is always starting from the top and expanding clockwise
              pieSort={(a, _) => (a.label === "used" ? -1 : 1)}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={3}
              padAngle={0.005}
              fill={(u) =>
                u.data.label === "used" ? "var(--chakra-colors-brand-500)" : "var(--chakra-colors-gray-100)"
              }
            />
          </Group>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            x="50%"
            y="50%"
            fill="black"
            fontSize={16}
            fontWeight={600}
          >
            <tspan>
              <FormattedMessage
                id="tool.context.usage.pie.percentage"
                description="Tool usage percentage shown in the tool context pie chart"
                defaultMessage="{percentage}%"
                values={{ percentage: (usagePct * 100).toFixed(1) }}
              />
            </tspan>
            <tspan dy="1.4em" x="50%" fontSize={14} fill="var(--chakra-colors-gray-600)">
              <FormattedMessage
                id="tool.context.usage.activity.pie.maus"
                description="Pie chart label for the tool MAU count"
                defaultMessage="{maus} {maus, plural,
                one {MAU}
                other {MAUs}
              }"
                values={{ maus: usageRecord[selection] }}
              />
            </tspan>
          </text>
        </svg>
      </Flex>
    </ChartCard>
  )
}
