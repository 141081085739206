import type { User, WorkflowRun, WorkflowRunUpdatePatch } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import type { UseModalProps } from "@chakra-ui/react"
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePatchWorkflowV1RunsByIdMutation } from "../../../app/services/generated-api.js"
import OrganizationEntityPicker from "../../../components/Form/OrganizationEntityPicker.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"

interface Props extends UseModalProps {
  workflowRun: Pick<WorkflowRun, "id" | "owner" | "target_date" | "display_name">
  updateFields: (keyof WorkflowRunUpdatePatch)[]
}

export function UpdateWorkflowRunModal({ workflowRun, updateFields, ...modalProps }: Props) {
  const intl = useIntl()
  const toast = useToast()
  const updateFieldSet = new Set(updateFields)

  const [updateWorkflowRun, updateWorkflowRunResult] = usePatchWorkflowV1RunsByIdMutation()

  const submitUpdate = async () => {
    try {
      const formValues = form.getValues()
      const updatePayload = {
        ...(updateFieldSet.has("display_name") ? { display_name: formValues.display_name } : {}),
        ...(updateFieldSet.has("owner_id") ? { owner_id: formValues.owner?.id } : {}),
        ...(updateFieldSet.has("target_date") ? { target_date: formValues.target_date } : {}),
      }
      await updateWorkflowRun({
        workflowRunUpdatePatch: updatePayload,
        id: workflowRun.id,
      }).unwrap()

      toast({
        description: intl.formatMessage({
          id: "requests.run.edit.success.toast.title",
          description: "Toast message shown when request is successfully updated",
          defaultMessage: "Request saved!",
        }),
        status: "success",
      })
      modalProps.onClose()
    } catch (err) {
      log.error("Error updating request", err, { workflowRun })
      const title =
        getAPIErrorMessage(err) ||
        intl.formatMessage({
          defaultMessage: "Something went wrong while editing your request",
          description: "The toast title for the error toast when a request could not be updated",
          id: "requests.run.edit.error.toast.title",
        })
      toast({ status: "error", title })
    }
  }

  const form = useForm<Omit<WorkflowRunUpdatePatch, "owner_id"> & { owner?: User }>({
    defaultValues: {
      target_date: workflowRun.target_date,
      owner: workflowRun.owner,
      display_name: workflowRun.display_name,
    },
  })

  return (
    <Modal {...modalProps} size="md">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={form.handleSubmit(submitUpdate)}>
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Edit Request"
            description="The title of the request edit modal"
            id="requests.run.edit.title"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          {updateFieldSet.has("display_name") && (
            <Controller
              name="display_name"
              control={form.control}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid}>
                  <FormLabel htmlFor={field.name}>
                    <FormattedMessage
                      defaultMessage="Request name"
                      description="The label of the request name input in the request edit modal"
                      id="requests.run.edit.name.label"
                    />
                  </FormLabel>
                  <Input {...field} id={field.name} />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          )}
          {updateFieldSet.has("owner_id") && (
            <Controller
              name="owner"
              control={form.control}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid}>
                  <FormLabel htmlFor={field.name}>
                    <FormattedMessage
                      defaultMessage="Champ"
                      description="The label of the request champ input in the request edit modal"
                      id="requests.run.edit.champ.label"
                    />
                  </FormLabel>
                  <OrganizationEntityPicker
                    includedEntities={["user", "person"]}
                    onChangeUser={field.onChange}
                    initialSelected={
                      field.value
                        ? {
                            type: "user",
                            display_name: displayPersonName(field.value, intl),
                            image_asset: field.value.profile_image,
                            ...field.value,
                          }
                        : null
                    }
                    placeholder={intl.formatMessage({
                      id: "requests.edit.modal.champ.placeholder",
                      description: "Placeholder text for request owner aka champ",
                      defaultMessage: "The Champ will own the request process",
                    })}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
                    }}
                    isRequired
                  />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          )}
          {updateFieldSet.has("target_date") && (
            <Controller
              name="target_date"
              control={form.control}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid}>
                  <FormLabel>
                    <FormattedMessage
                      defaultMessage="Complete by"
                      description="The label for the complete by field in the edit request modal form"
                      id="requests.run.edit.complete_by.label"
                    />
                  </FormLabel>
                  <Input type="date" value={field.value || ""} onChange={field.onChange} />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button type="button" onClick={modalProps.onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="The label of the cancel button in the request edit modal"
              id="requests.run.edit.button.cancel"
            />
          </Button>
          <Button type="submit" colorScheme="brand" isLoading={updateWorkflowRunResult.isLoading}>
            <FormattedMessage
              defaultMessage="Save"
              description="The label of the save button in the request edit modal"
              id="requests.run.edit.button.save"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
