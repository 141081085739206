import { hasPermission } from "@brm/schema-helpers/role.js"
import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type {
  FieldMetadataWithSuggestions,
  FormFieldConfig,
  LegalClausesFieldsMetadata,
  ObjectType,
  WorkflowRunFieldGatherer,
  WorkflowRunStep,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import { isEmpty, isObject } from "@brm/util/type-guard.js"
import type { FlexProps, FormLabelProps } from "@chakra-ui/react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { excludeKeys } from "filter-obj"
import { useCallback, useEffect, type FunctionComponent, type ReactNode, type RefObject } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import type { Except, SetRequired } from "type-fest"
import {
  useGetUserV1WhoamiQuery,
  useLazyGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery,
  usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation,
} from "../../app/services/generated-api.js"
import MockedCommentSection from "../../features/workflows/MockedCommentSection.js"
import {
  canApproveWorkflowRunStep,
  type GetLogoForOrganizationProps,
  type GetOrganizationActorProps,
  type WorkflowRunWithExternalFlag,
} from "../../features/workflows/run/utils.js"
import { fieldHasNewOption } from "../../util/form.js"
import { log } from "../../util/logger.js"
import { scrollIntoView } from "../../util/scroll.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { useDocumentVisibility } from "../../util/visibility.js"
import OverflownText from "../OverflownText.js"
import { RenderedMarkdown } from "../RenderedMarkdown.js"
import FieldTimeline from "../Timeline/FieldTimeline.js"
import type { TimelineCommentInputProps } from "../Timeline/TimelineCommentInput.js"
import { TimelineCommentInput } from "../Timeline/TimelineCommentInput.js"
import { ToolLogo } from "../icons/Logo.js"
import { CheckIcon, ChevronUpIcon, CommentIcon, UsersIcon } from "../icons/icons.js"
import LockUnlockIconButton from "../icons/system/LockUnlockIconButton.js"
import FieldApprovalConfirmationModal from "./FieldApprovalConfirmationModal.js"
import FieldGathererSelector from "./FieldGathererSelector.js"
import FieldSourceIcon from "./FieldSourceIcon.js"
import StepApprovalConfirmationModal from "./StepApprovalConfirmationModal.js"
import { useBetsyDealEval } from "./betsy-deal-eval.js"
import type { SchemaFormFieldApproval } from "./types.js"

export interface DynamicFormFieldWrapperProps {
  formField: FormFieldConfig
  label: string
  description?: string
  labelProps?: FormLabelProps
  children: ReactNode

  /** The workflow run, for purposes of fetching the field timeline. */
  workflowRun?: WorkflowRunWithExternalFlag

  /** The ID of the workflow run, for purposes of posting comments and updating the approval status for a step's field. */
  workflowRunStep?: WorkflowRunStep | WorkflowRunStepWithContext

  /** The dot-separated path of the field in the context object. */
  path: (string | number)[]
  /** Data source of the field's current value, used to render an indicator next to the field label */
  fieldMetadata?: FieldMetadataWithSuggestions | LegalClausesFieldsMetadata

  /** The type of the parent object of the field. */
  objectType: ObjectType | undefined

  /** The ID of the parent object of the field. */
  objectId: string | undefined

  /** States if the field is a custom or standard field. */
  isCustomField: boolean

  getValue: (path: string) => unknown

  /** The name of the field. */
  fieldName: string | undefined
  fieldFilledOut: boolean

  /** The current number of comments on the field. */
  commentCount: number | undefined

  fieldGatherer?: WorkflowRunFieldGatherer

  fieldApproval?: SchemaFormFieldApproval

  renderFieldWrapper?: (wrapperProps: Except<DynamicFormFieldWrapperProps, "renderFieldWrapper">) => ReactNode
  renderFieldSource?: () => ReactNode
  /** If this is non empty, the accept button will be rendered next to the field name */
  onVerifyField?: () => void
  verified?: boolean
  isDirty?: boolean

  /** Ref of the field wrapped. */
  fieldRef?: RefObject<HTMLInputElement>
  isActive?: boolean
}

export const DynamicFormFieldWrapper: FunctionComponent<
  DynamicFormFieldWrapperProps & GetLogoForOrganizationProps & GetOrganizationActorProps
> = (props) => {
  const {
    formField,
    label,
    description,
    children,
    labelProps,
    fieldMetadata,
    workflowRun,
    workflowRunStep,
    objectType,
    objectId,
    getValue,
    fieldName,
    fieldFilledOut,
    path,
    commentCount,
    fieldApproval,
    fieldGatherer,
    renderFieldWrapper,
    renderFieldSource,
    getLogoToShowByOrganizationId,
    getOrganizationActorWhenActorMissing,
    onVerifyField,
    isDirty,
    isCustomField,
    fieldRef,
    isActive,
  } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const deleteEventSearchParam = () => {
    setSearchParams(
      (current) => {
        current.delete("event")
        return current
      },
      { replace: true }
    )
  }
  const documentVisibility = useDocumentVisibility()

  const [fetchTimelineEvents, timelineEventsResult] =
    useLazyGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery({
      pollingInterval: documentVisibility === "visible" ? 60_000 : undefined,
      refetchOnFocus: true,
    })

  const defaultTimelineOpen = Boolean(workflowRun && workflowRunStep)
  const timelineDisclosure = useDisclosure({ defaultIsOpen: defaultTimelineOpen })
  const timelineProps = {
    path,
    label,
  } satisfies Partial<TimelineCommentInputProps>

  const hoverActionsVisible = { opacity: 1 }

  const { data: whoami } = useGetUserV1WhoamiQuery()

  useEffect(() => {
    if (
      (workflowRun?.is_external === false || (whoami && workflowRun)) &&
      defaultTimelineOpen &&
      objectId &&
      objectType &&
      fieldName
    ) {
      void fetchTimelineEvents({
        objectId,
        objectType,
        fieldName,
        workflowRunId: workflowRun.id,
      })
    }
  }, [defaultTimelineOpen, fetchTimelineEvents, fieldName, objectType, objectId, workflowRun, whoami])

  const isTarget =
    searchParams.get("type") === objectType && searchParams.get("field") === fieldName && !searchParams.get("event")
  const isEditField = searchParams.get("edit_field") === "true"
  const fieldIsApproved = fieldApproval?.fieldIsApproved
  const fieldIsSharedWithSeller = !formField.is_internal_only
  const workflowHasLink = !!workflowRun && workflowRun.links.length > 0

  const betsyDealEval = useBetsyDealEval(workflowRun, getValue, path)
  const renderedFieldSource = renderFieldSource?.()
  const toolLogo = workflowRun?.new_legal_agreement.tools[0]?.image_asset?.gcs_file_name
  const value = getValue(path.join("."))
  // Some values such as clauses are objects and may have null sub values
  const isValueEmpty = isObject(value) ? isEmpty(excludeKeys(value, (_, v) => isEmpty(v))) : isEmpty(value)

  const workflowRunStepIsApprovedOrCompleted =
    workflowRunStep && (workflowRunStep.status === "completed" || workflowRunStep.status === "approved")
  const newOption = fieldHasNewOption(fieldMetadata, fieldApproval)

  const openField = useCallback(() => {
    fieldRef?.current?.focus()
    fieldRef?.current?.click()
  }, [fieldRef])

  useEffect(() => {
    if (isEditField && isTarget) {
      openField()
      searchParams.delete("edit_field")
      navigate({
        search: searchParams.toString(),
        hash: location.hash,
      })
    }
  }, [fieldRef, isEditField, isTarget, location.hash, navigate, openField, searchParams, setSearchParams])

  return (
    <Card
      borderWidth="thin"
      transform={isActive ? "scale(1.01)" : undefined}
      borderColor="gray.200"
      boxShadow={isActive ? "xl" : "xs"}
      borderRadius="lg"
    >
      <CardBody p={3}>
        <Stack position="relative" flex={1} gap={2} ref={isTarget ? scrollIntoView : undefined}>
          <HStack justifyContent="space-between">
            <Box flexGrow="1" flexShrink={1} minW={0}>
              <HStack justifyContent="space-between" flexShrink={1} minW={0}>
                <HStack gap={2} flexShrink={1} minW={0}>
                  {description ? (
                    <Tooltip label={description} hasArrow maxWidth="400px">
                      <FormLabel
                        mr={0}
                        mb={0}
                        fontSize="md"
                        display="flex"
                        flexShrink={1}
                        minW={0}
                        cursor="help"
                        {...labelProps}
                      >
                        <OverflownText flexShrink={1} minWidth={0}>
                          {label}
                        </OverflownText>
                      </FormLabel>
                    </Tooltip>
                  ) : (
                    <FormLabel mr={0} mb={0} fontSize="md" display="flex" flexShrink={1} minW={0} {...labelProps}>
                      <OverflownText flexShrink={1} minWidth={0}>
                        {label}
                      </OverflownText>
                    </FormLabel>
                  )}
                  {/* Once a field is approved, it will not show new values */}
                  {fieldMetadata &&
                    (newOption && newOption.isNew ? (
                      <Tag
                        gap={1}
                        size="sm"
                        backgroundColor={`${newOption.colorScheme}.50`}
                        color="gray.700"
                        variant="outline"
                        borderColor={`${newOption.colorScheme}.600`}
                        onClick={openField}
                        as={Button}
                        height="fit-content"
                      >
                        <FieldSourceIcon
                          fieldMetadata={newOption.fieldSource ?? fieldMetadata}
                          fieldLabel={label}
                          fieldIcon={toolLogo ? <ToolLogo logo={getPublicImageGcsUrl(toolLogo)} /> : null}
                        />
                        <FormattedMessage
                          id="components.DynamicFormFieldWrapper.newOptionTag"
                          description="New value found tag for a field"
                          defaultMessage="New value"
                        />
                      </Tag>
                    ) : (
                      <FieldSourceIcon
                        fieldMetadata={fieldMetadata}
                        fieldLabel={label}
                        fieldIcon={toolLogo ? <ToolLogo logo={getPublicImageGcsUrl(toolLogo)} /> : null}
                      />
                    ))}
                  {workflowRun &&
                    !workflowRun.is_external &&
                    fieldMetadata?.type !== "link" &&
                    fieldIsSharedWithSeller && (
                      <Box flex={1}>
                        <Tooltip
                          label={
                            <HStack>
                              <Icon as={UsersIcon} color="gray.500" />
                              <Text whiteSpace="nowrap">
                                {workflowHasLink ? (
                                  <FormattedMessage
                                    defaultMessage="Field shared with seller"
                                    description="Tooltip label for fields shared with seller"
                                    id="workflowRun.form.field.sharedWithSeller"
                                  />
                                ) : (
                                  <FormattedMessage
                                    defaultMessage="Field will be shared with seller"
                                    description="Tooltip label for fields that will be shared with the sellers"
                                    id="workflowRun.form.field.willBeSharedWithSellers"
                                  />
                                )}
                              </Text>
                            </HStack>
                          }
                          hasArrow
                          shouldWrapChildren
                        >
                          <Icon as={UsersIcon} color="gray.500" />
                        </Tooltip>
                      </Box>
                    )}
                </HStack>
                {/* onVerifyField today is only set for Agreements */}
                {onVerifyField &&
                  (fieldMetadata?.verified ? (
                    <Icon as={CheckIcon} color="success.500" margin={1} />
                  ) : isValueEmpty && !isDirty ? null : (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={onVerifyField}
                      leftIcon={<Icon as={CheckIcon} color="success.500" />}
                    >
                      <FormattedMessage defaultMessage="Accept" description="Accept field" id="schemaForm.accept" />
                    </Button>
                  ))}
              </HStack>
            </Box>
            {/* This is only for workflows today so it will not collide with the accept button above */}
            {workflowRun &&
              !fieldIsApproved &&
              !workflowRunStepIsApprovedOrCompleted &&
              workflowRunStep &&
              objectId &&
              fieldName &&
              objectType && (
                <FieldGathererSelector
                  canEdit={!!whoami}
                  workflowRunId={workflowRun.id}
                  gatherer={fieldGatherer}
                  objectId={objectId}
                  fieldName={fieldName}
                  objectType={objectType}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  shouldShowSellers={fieldIsSharedWithSeller}
                  isCustomField={isCustomField}
                  workflowRunStepId={workflowRunStep.id}
                />
              )}
          </HStack>
          <Stack flexGrow={1} flexShrink={1} minWidth={0} gap={1}>
            {renderFieldWrapper ? renderFieldWrapper(props) : children}
            {betsyDealEval && (
              <Alert status="info">
                <AlertIcon alignSelf="flex-start" />
                <AlertDescription>
                  <RenderedMarkdown content={betsyDealEval} />
                </AlertDescription>
              </Alert>
            )}
            {/** Hide field source to avoid duplicative information */}
            {renderedFieldSource &&
              (!timelineDisclosure.isOpen || fieldMetadata?.type !== "user" || fieldMetadata.assigned_by_metadata) && (
                <FormHelperText>{renderedFieldSource}</FormHelperText>
              )}
            {workflowRun && whoami && (
              <Flex alignItems="center" flexShrink={0} flexGrow={0} justifyContent="space-between">
                <Flex gap={2}>
                  <Flex
                    opacity={commentCount || timelineDisclosure.isOpen ? 1 : 0}
                    _groupHover={hoverActionsVisible}
                    _groupFocus={hoverActionsVisible}
                    _groupFocusWithin={hoverActionsVisible}
                  >
                    {objectType && objectId && fieldName && (
                      <Button
                        size="sm"
                        variant="subtleOutlined"
                        leftIcon={<Icon as={CommentIcon} />}
                        colorScheme={commentCount ? "brand" : "gray"}
                        iconSpacing={0}
                        gap={2}
                        aria-label={intl.formatMessage(
                          {
                            defaultMessage: "{count} {count, plural, =1 {comment} other {comments}}",
                            description: "Button ARIA label to view comments on a field",
                            id: "workflowRun.form.field.timeline.aria.label",
                          },
                          { count: commentCount ?? 0 }
                        )}
                        {...timelineDisclosure.getButtonProps({
                          onClick: () => {
                            deleteEventSearchParam()
                            if (!timelineDisclosure.isOpen && workflowRun) {
                              void fetchTimelineEvents({
                                objectType,
                                objectId,
                                fieldName,
                                workflowRunId: workflowRun.id,
                              })
                            }
                          },
                        })}
                      >
                        {commentCount && <Text fontSize={14}>{commentCount}</Text>}
                      </Button>
                    )}
                  </Flex>
                  {workflowRunStep && workflowRun && (
                    <FieldApprovalButton
                      formField={formField}
                      label={label}
                      objectId={objectId}
                      workflowRunStep={workflowRunStep}
                      workflowRun={workflowRun}
                      fieldApproval={fieldApproval}
                      fieldFilledOut={fieldFilledOut}
                      // Flex Props
                      opacity={0}
                      _groupHover={hoverActionsVisible}
                      _groupFocus={hoverActionsVisible}
                      _groupFocusWithin={hoverActionsVisible}
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </Stack>
          {/* For now there is only FieldTimeline for workflow schema forms */}
          {workflowRun && whoami && objectType && objectId && fieldName && workflowRunStep && (
            <Stack gap={2} {...timelineDisclosure.getDisclosureProps()}>
              <Box>
                {timelineEventsResult.data && (
                  <FieldTimeline
                    timelineEvents={timelineEventsResult.data}
                    getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
                    getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  />
                )}
                <TimelineCommentInput
                  workflowRunId={workflowRun.id}
                  workflowRunStepId={workflowRunStep.id}
                  showPrivacyControls={fieldIsSharedWithSeller}
                  objectType={objectType}
                  objectId={objectId}
                  fieldName={fieldName}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  {...timelineProps}
                />
              </Box>
            </Stack>
          )}
          {!whoami && workflowRun && workflowRun.is_external && (
            // Logged out sellers see mock comments
            <MockedCommentSection />
          )}
          {timelineDisclosure.isOpen && (
            <Box>
              <Button
                size="sm"
                variant="link"
                colorScheme="brand"
                leftIcon={<Icon as={ChevronUpIcon} />}
                iconSpacing={1}
                {...timelineDisclosure.getButtonProps({
                  onClick: deleteEventSearchParam,
                })}
              >
                <FormattedMessage
                  defaultMessage="Hide activity"
                  description="Button label to hide the activity timeline"
                  id="workflowRun.form.field.timeline.hide"
                />
              </Button>
            </Box>
          )}
        </Stack>
      </CardBody>
    </Card>
  )
}

/**
 * Renders a checkmark that can be clicked to approve a field if viewing user is the approver of this field/step
 * If the field is already approved, renders a button that allows the user to change the approval status and unlock
 * the field. If the user is not the approver, a confirmation modal will be shown when the button is clicked
 */
const FieldApprovalButton = ({
  formField,
  objectId,
  workflowRunStep,
  fieldApproval,
  label,
  fieldFilledOut,
  workflowRun,
  ...flexProps
}: SetRequired<
  Pick<
    DynamicFormFieldWrapperProps,
    "formField" | "objectId" | "workflowRunStep" | "workflowRun" | "fieldApproval" | "label" | "fieldFilledOut"
  >,
  "workflowRunStep" | "workflowRun"
> &
  FlexProps) => {
  const intl = useIntl()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const approvalConfirmationModal = useDisclosure()
  const stepApprovalModal = useDisclosure()

  const [updateFieldApproval, { isLoading: fieldApprovalUpdateLoading }] =
    usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation()

  const fieldValidForApproval: boolean =
    // Only show approval button for submitted workflow run steps
    workflowRunStep.status === "submitted" &&
    // Only show approval button for workflow run steps that have approval steps
    Boolean(workflowRunStep.approval_steps[0]) &&
    // Only show approval button for workflow run steps that are filled out or not required
    (fieldFilledOut || !formField.is_required)

  if (!objectId || !fieldValidForApproval) {
    return null
  }

  const userCanApproveField = Boolean(
    (whoami?.id &&
      getCurrentApprovalStep(workflowRunStep.approval_steps)?.approvers.some((a) => a.user.id === whoami.id)) ||
      hasPermission(whoami?.roles, "workflow:approve:any")
  )
  const fieldIsApproved = fieldApproval?.fieldIsApproved

  return (
    <Flex {...flexProps}>
      {!fieldIsApproved && userCanApproveField ? (
        <IconButton
          size="sm"
          variant="subtleOutlined"
          colorScheme="gray"
          color="brand.500"
          icon={<Icon as={CheckIcon} />}
          aria-label={intl.formatMessage({
            defaultMessage: "Approve field",
            id: "request.field.approve.aria.label",
            description: "Button ARIA label to approve a field",
          })}
          isDisabled={fieldApprovalUpdateLoading}
          onClick={async () => {
            try {
              const stepResponse = await updateFieldApproval({
                id: workflowRunStep.id,
                objectType: formField.object_type,
                objectId,
                fieldName: formField.field_name,
                workflowRunStepFieldApprovalStatus: "approved",
              }).unwrap()
              if (
                stepResponse.field_counts.approved === stepResponse.field_counts.total &&
                canApproveWorkflowRunStep(whoami, workflowRunStep, workflowRun)
              ) {
                stepApprovalModal.onOpen()
              }
            } catch (err) {
              toast({
                description: intl.formatMessage({
                  id: "request.field.approve.error",
                  description: "Toast error message when approver approving a field fails",
                  defaultMessage: "There was an error approving this field",
                }),
                status: "error",
              })
              log.error("Failed to approve a field", err)
            }
          }}
        />
      ) : fieldIsApproved ? (
        <LockUnlockIconButton
          size="sm"
          variant="subtleOutlined"
          colorScheme="gray"
          aria-label={intl.formatMessage({
            defaultMessage: "Reopen field",
            id: "request.field.reopen.aria.label",
            description: "Button ARIA label to reopen a field",
          })}
          isDisabled={fieldApprovalUpdateLoading}
          onClick={async () => {
            if (userCanApproveField) {
              try {
                await updateFieldApproval({
                  id: workflowRunStep.id,
                  objectType: formField.object_type,
                  objectId,
                  fieldName: formField.field_name,
                  workflowRunStepFieldApprovalStatus: "change_requested",
                }).unwrap()
              } catch (err) {
                toast({
                  description: intl.formatMessage({
                    id: "request.field.change_requested.error",
                    description: "Toast error message when approver unapproving a field fails",
                    defaultMessage: "There was an error unapproving this field",
                  }),
                  status: "error",
                })
                log.error("Failed to unapprove a field", err)
              }
            } else {
              approvalConfirmationModal.onOpen()
            }
          }}
        />
      ) : null}
      {approvalConfirmationModal.isOpen && (
        <FieldApprovalConfirmationModal
          workflowRunStepId={workflowRunStep.id}
          objectType={formField.object_type}
          objectId={objectId}
          fieldName={formField.field_name}
          fieldLabel={label}
          {...approvalConfirmationModal}
        />
      )}
      <StepApprovalConfirmationModal workflowRunStep={workflowRunStep} {...stepApprovalModal} />
    </Flex>
  )
}
