import type { DocumentMinimal, FormFieldConfig } from "@brm/schema-types/types.js"
import { chakra, Stack } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { useCallback, useRef, useState } from "react"
import type { DefaultValues, FieldValues, Path, SetValueConfig } from "react-hook-form"
import { useForm } from "react-hook-form"
import type { Except, ReadonlyDeep } from "type-fest"
import { cleanValues } from "../../features/workflows/run/utils.js"
import { initializeReactHookFromState } from "../../util/form.js"
import { log } from "../../util/logger.js"
import { DynamicFormField, type DynamicFormProps } from "./DynamicForm.js"

interface Props<TFieldValues extends FieldValues> {
  initialFormValues: DefaultValues<TFieldValues> | undefined
  rootSchema: ReadonlyDeep<JSONSchema>
  formFields: (FormFieldConfig & Pick<DynamicFormProps, "path">)[]
  onSubmit: (outputs: TFieldValues) => Promise<void>
  /** Set this to undefined if the given schema does not need to render any documents */
  documentDownloadURL: undefined | ((path: (string | number)[], document: DocumentMinimal) => string)
  isEditing: boolean
}

/**
 * Given a root schema and an output schema, SchemaForm page creates a form that submits to the given submit function
 * onBlur whenever there is changes made so that the form can be submitted without the user having to click a button.
 */
export default function SchemaFormPage<TFieldValues extends FieldValues>({
  initialFormValues,
  rootSchema,
  formFields,
  onSubmit,
  documentDownloadURL,
  isEditing,
}: Props<TFieldValues>) {
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const form = useForm<TFieldValues>({ defaultValues: initialFormValues, shouldFocusError: false })
  initializeReactHookFromState(form)

  const formRef = useRef<HTMLFormElement>(null)

  const documentDownloadUrlCallback = useCallback(
    (path: (string | number)[], document: DocumentMinimal): string => {
      if (documentDownloadURL) {
        return documentDownloadURL(path, document)
      }
      log.error("No document download URL found", new Error("Unexpected document found on form"))
      // Returning empty string here will not allow the user to click the document
      return ""
    },
    [documentDownloadURL]
  )
  const onDocumentChange = useCallback(() => {
    formRef.current?.requestSubmit()
  }, [])

  const getValue = useCallback(
    (path: string) => {
      // Need to handle an empty string path as undefined to make sure that react-hook-form returns the root object
      return form.watch((path || undefined) as Path<TFieldValues>)
    },
    [form]
  )

  const formFieldProps: Except<DynamicFormProps, "path" | "formField"> = {
    control: form.control,
    getValue,
    setValue: form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void,
    resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
    rootBaseSchema: rootSchema,
    isReadOnly: !isEditing,
    onDocumentChange,
    uncontrolledComponentResetId,
    incrementUncontrolledComponentResetId,
    getDocumentDownloadUrl: documentDownloadUrlCallback,
  }

  return (
    <chakra.form
      // Submit on blur if changed
      onBlur={(event) => {
        if (isEditing && form.formState.isDirty) {
          event.currentTarget.requestSubmit()
        }
      }}
      onSubmit={form.handleSubmit(async (values) => {
        const { cleanedValues } = cleanValues(values, rootSchema)
        await onSubmit(cleanedValues as TFieldValues)
        form.reset(form.getValues(), { keepValues: true, keepDefaultValues: false, keepErrors: true })
      })}
      noValidate={true} // ensure react-hook-form validation is used
      ref={formRef}
    >
      <Stack>
        {formFields.map((formField) => {
          return (
            <DynamicFormField
              {...formFieldProps}
              key={formField.path.join(".")}
              formField={formField}
              path={formField.path}
            />
          )
        })}
      </Stack>
    </chakra.form>
  )
}
