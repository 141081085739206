import type { As, ButtonProps, ComponentWithAs } from "@chakra-ui/react"
import { Button, Icon, chakra, forwardRef } from "@chakra-ui/react"

interface NavButtonProps extends ButtonProps {
  icon: As
  label: string
}

// see https://chakra-ui.com/community/recipes/as-prop#option-1-using-forwardref-from-chakra-uireact
export const NavButton: ComponentWithAs<"button", NavButtonProps> = forwardRef<NavButtonProps, "button">(
  (props, ref) => {
    const { icon, label, ...buttonProps } = props
    return (
      <Button ref={ref} variant="ghost" justifyContent="start" leftIcon={<Icon as={icon} />} {...buttonProps}>
        <chakra.span>{label}</chakra.span>
      </Button>
    )
  }
)
