import { hasPermission } from "@brm/schema-helpers/role.js"
import {
  type SavedView,
  type WorkflowRunListItem,
  type WorkflowRunMinimal,
  type WorkflowRunTabs,
} from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import {
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { createColumnHelper, type ColumnPinningState } from "@tanstack/react-table"
import { useMemo, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  usePostWorkflowV1RunsByIdCancelMutation,
  usePostWorkflowV1RunsListQuery,
} from "../../../app/services/generated-api.js"
import DataTable from "../../../components/DataTable/DataTable.js"
import FullDataTableHeader from "../../../components/DataTable/FullDataTableHeader.js"
import TablePageHeader from "../../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../../components/DataTable/SchemaTableColumnCustomization.js"
import { ACTIONS_COLUMN_SIZE } from "../../../components/DataTable/const.js"
import { useSchemaColumns } from "../../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../../components/DataTable/use-schema-table-params.js"
import { MoreMenuIcon, PlusIcon } from "../../../components/icons/icons.js"
import Spinner from "../../../components/spinner.js"
import {
  TABLE_INITIAL_PAGE,
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
} from "../../../util/schema-table.js"
import type { FilterTabData } from "../../../util/tabs.js"
import { getTabIndexFromLocation } from "../../../util/tabs.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import { WORKFLOW_RUN_TABLE_DEFAULT_PARAMS, WORKFLOW_RUN_TABLE_ID } from "../constants.js"
import { DeleteWorkflowRunModal } from "./DeleteWorkflowRunModal.js"
import StartWorkflowModal from "./start/StartWorkflowModal.js"

const columnHelper = createColumnHelper<WorkflowRunListItem>()

const defaultColumns = ["tools", "owner", "kind", "progress", "target_date"] satisfies BRMPaths<WorkflowRunListItem>[]

const primaryColumn = "display_name"
const primarySearchColumn: BRMPaths<WorkflowRunListItem> = "display_name"

const columnPinning: ColumnPinningState = {
  left: [primaryColumn],
}

const savedViews: SavedView[] = []

const WorkflowRunList: FunctionComponent = () => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()

  const startWorkflowModal = useDisclosure()

  const workflowRunSchema = useObjectSchema("WorkflowRun")
  const [cancelWorkflowRun] = usePostWorkflowV1RunsByIdCancelMutation()
  const [workflowRunBeingDeleted, setWorkflowRunBeingDeleted] = useState<WorkflowRunMinimal>()
  const deleteModalState = useDisclosure()

  const { data: whoami } = useGetUserV1WhoamiQuery()

  // LOAD FILTER PARAMS AND GET ACTIVE FILTERS
  useLocalStorageTableParamsSync(WORKFLOW_RUN_TABLE_ID)
  const { tableParams, updateTableParams, getUpdatedSerializedParams } = useUrlTableParams<string>({
    defaultParams: WORKFLOW_RUN_TABLE_DEFAULT_PARAMS,
    objectSchema: workflowRunSchema,
    savedViews,
    primarySearchColumn,
  })

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )

  const schemaColumns = useSchemaColumns<WorkflowRunListItem>({
    objectSchema: workflowRunSchema,
    shownColumns,
    primaryColumn,
  })

  const columnsWithAction = useMemo(
    () =>
      schemaColumns && [
        ...schemaColumns,
        columnHelper.display({
          id: "actions-column",
          size: ACTIONS_COLUMN_SIZE,
          meta: {
            textAlign: "center",
          },
          header: intl.formatMessage({
            id: "requests.runs.page.column.actions",
            description: "Invitation table column header for Actions",
            defaultMessage: "Actions",
          }),
          cell: ({ row: { original: workflowRun } }) => (
            <Menu variant="tableCell" isLazy>
              <MenuButton
                as={IconButton}
                variant="ghost"
                icon={<Icon as={MoreMenuIcon} />}
                aria-label={intl.formatMessage({
                  defaultMessage: "Actions",
                  description: "The ARIA label for the actions dropdown button in the requests table",
                  id: "requests.table.action.actions.label",
                })}
              />
              <MenuList>
                <MenuItem
                  isDisabled={!hasPermission(whoami?.roles, "workflow_run:update")}
                  onClick={async () => {
                    try {
                      await cancelWorkflowRun({ id: workflowRun.id }).unwrap()
                      toast({
                        description: intl.formatMessage({
                          id: "cancel-workflow.success",
                          description: "cancel workflow succeeded",
                          defaultMessage: "Workflow cancelled",
                        }),
                        status: "success",
                      })
                    } catch (err) {
                      toast({
                        description: intl.formatMessage({
                          id: "cancel-workflow.failure",
                          description: "cancel workflow failed",
                          defaultMessage: "Failed to cancel Workflow",
                        }),
                        status: "error",
                      })
                    }
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    description="The label for the cancel workflow_run button in the workflow_run table"
                    id="workflow_run.table.action.cancel.label"
                  />
                </MenuItem>
                {/* Only show the delete option to users with the permissions */}
                {hasPermission(whoami?.roles, "workflow_run:delete") && (
                  <MenuItem
                    onClick={() => {
                      setWorkflowRunBeingDeleted(workflowRun)
                      deleteModalState.onOpen()
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Delete"
                      description="The label for the delete request button in the requests table"
                      id="requests.table.action.delete.label"
                    />
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          ),
        }),
      ],
    [cancelWorkflowRun, deleteModalState, intl, schemaColumns, toast, whoami?.roles]
  )

  const apiParams = useMemo(
    () => tableParams && workflowRunSchema && packageSortFilterOptionsForAPI(tableParams, workflowRunSchema, intl),
    [tableParams, workflowRunSchema, intl]
  )

  const myRequestFilters: Filter[][] | undefined = whoami && [
    [
      {
        column: "created_by.id" satisfies BRMPaths<WorkflowRunListItem>,
        fields: { comparator: "eq", value: whoami.id },
      },
      {
        column: "owner.id" satisfies BRMPaths<WorkflowRunListItem>,
        fields: { comparator: "eq", value: whoami.id },
      },
      {
        column: "tools.*.owner.user_id",
        fields: { comparator: "arr_contains", values: [whoami.id], includeNull: false },
      },
    ],
  ]

  const assignedToMeFilters: Filter[][] | undefined = whoami && [
    [
      {
        column: "assigned_to.*.id",
        fields: { comparator: "arr_contains", values: [whoami.id], includeNull: false },
      },
    ],
  ]

  const filterTabConfigByWorkflowRunTab =
    myRequestFilters &&
    assignedToMeFilters &&
    ({
      all_requests: {
        locationHash: "all",
        label: (
          <FormattedMessage
            id="workflow_run.list.page.tab.all"
            description="Tab label to show all requests on the requests list page"
            defaultMessage="All requests"
          />
        ),
        filters: undefined,
      },
      my_requests: {
        locationHash: "my_requests",
        label: (
          <FormattedMessage
            id="workflow_run.list.page.tab.myRequests"
            description="Tab label to show all requests started by the current user"
            defaultMessage="My requests"
          />
        ),
        filters: myRequestFilters,
      },
      assigned_to_me: {
        locationHash: "assigned",
        label: (
          <FormattedMessage
            id="workflow_run.list.page.tab.assigned"
            description="Tab label to show all requests assigned to the current user"
            defaultMessage="Assigned to me"
          />
        ),
        filters: assignedToMeFilters,
      },
    } satisfies Record<WorkflowRunTabs, FilterTabData>)

  const workflowRunTabs =
    filterTabConfigByWorkflowRunTab &&
    (hasPermission(whoami?.roles, "workflow_run:update")
      ? ([
          filterTabConfigByWorkflowRunTab.all_requests,
          filterTabConfigByWorkflowRunTab.my_requests,
          filterTabConfigByWorkflowRunTab.assigned_to_me,
        ] as const)
      : ([
          filterTabConfigByWorkflowRunTab.assigned_to_me,
          filterTabConfigByWorkflowRunTab.my_requests,
          filterTabConfigByWorkflowRunTab.all_requests,
        ] as const))

  const tabIndex = workflowRunTabs && getTabIndexFromLocation(location, workflowRunTabs)

  const workflowRunsTab1 = usePostWorkflowV1RunsListQuery(apiParams ? { listQueryStringParams: apiParams } : skipToken)
  const workflowRunsTab2 = usePostWorkflowV1RunsListQuery(
    apiParams && workflowRunTabs
      ? {
          listQueryStringParams: {
            ...apiParams,
            filter: [...apiParams.filter, ...workflowRunTabs[1].filters],
          },
        }
      : skipToken
  )
  const workflowRunsTab3 = usePostWorkflowV1RunsListQuery(
    apiParams && workflowRunTabs
      ? {
          listQueryStringParams: {
            ...apiParams,
            filter: [...apiParams.filter, ...(workflowRunTabs[2].filters ?? [])],
          },
        }
      : skipToken
  )
  const tabData = [workflowRunsTab1.data, workflowRunsTab2.data, workflowRunsTab3.data]

  if (
    !getUpdatedSerializedParams ||
    !workflowRunSchema ||
    !workflowRunTabs ||
    !columnsWithAction ||
    tabIndex === undefined ||
    !shownColumns
  ) {
    return null
  }

  const isLoading = [workflowRunsTab1.isLoading, workflowRunsTab2.isLoading, workflowRunsTab3.isLoading][tabIndex]

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  const handleChangeTab = (index: number) => {
    const newTab = workflowRunTabs[index]
    isNotUndefined(newTab)
    const hashParams = new URLSearchParams(location.hash.slice(1))
    if (newTab.locationHash) {
      hashParams.set("tab", newTab.locationHash)
    } else {
      hashParams.delete("tab")
    }
    navigate(
      { search: getUpdatedSerializedParams({ page: TABLE_INITIAL_PAGE }).toString(), hash: `#${hashParams}` },
      { replace: true }
    )
  }

  // HANDLE EMPTY TABLE STATE
  const workflowRuns = tabData[tabIndex]
  if (!workflowRuns) {
    return null
  }

  return (
    <>
      <Flex justifyContent="center" flexDir="column" gap={0} flexGrow={1} minHeight={0}>
        <FullDataTableHeader
          displayTitle={intl.formatMessage({
            id: "workflowRunList.title",
            description: "title of the requests list page",
            defaultMessage: "Requests",
          })}
        >
          <TablePageHeader
            tableId={WORKFLOW_RUN_TABLE_ID}
            objectSchema={workflowRunSchema}
            selectedColumns={shownColumns}
            tabProps={{
              data: workflowRunTabs.map((tab, index) => ({
                ...tab,
                total: tabData[index]?.total,
              })),
              handleChangeTab,
              selectedIndex: tabIndex,
            }}
            primarySearch={{
              column: primarySearchColumn,
              currentPrimaryFilter: tableParams.primaryFilter?.fields,
              placeholder: intl.formatMessage({
                id: "workflowRunList.search.name",
                description: "text showing the option to search requests by the name column",
                defaultMessage: "Search requests by name",
              }),
              onPrimaryFilterChange: (fields) =>
                updateTableParams({ primaryFilter: fields && { column: primarySearchColumn, fields } }),
            }}
            filterMap={tableParams.filterMap}
            onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
            afterSavedView={
              <>
                <Button colorScheme="brand" leftIcon={<Icon as={PlusIcon} />} {...startWorkflowModal.getButtonProps()}>
                  <FormattedMessage
                    id="navHeader.menu.add.request.new"
                    description="Nav header menu item to open the new request modal"
                    defaultMessage="New Request"
                  />
                </Button>
                <SchemaTableColumnCustomization
                  activeColumns={shownColumns}
                  primaryColumn={primaryColumn}
                  onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
                  objectSchema={workflowRunSchema}
                />
              </>
            }
          />
        </FullDataTableHeader>
        <DataTable<WorkflowRunListItem>
          data={workflowRuns.list}
          columns={columnsWithAction}
          columnPinning={columnPinning}
          columnOrder={[primaryColumn, ...shownColumns]}
          sorting={tableParams.sorting}
          onSortingChange={(sorting) =>
            sorting instanceof Function
              ? updateTableParams({ sorting: sorting(tableParams.sorting) })
              : updateTableParams({ sorting })
          }
          onRowClick={(row) => navigate(row.id)}
          paginationProps={{
            page: tableParams.page,
            pageSize: tableParams.pageSize,
            onPageChange: (page) => updateTableParams({ page }),
            onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
            totalListElements: workflowRuns.total,
          }}
          isSticky
        />
      </Flex>
      {workflowRunBeingDeleted && (
        <DeleteWorkflowRunModal {...deleteModalState} workflowRun={workflowRunBeingDeleted} />
      )}
      {startWorkflowModal.isOpen && <StartWorkflowModal {...startWorkflowModal} />}
    </>
  )
}
export default WorkflowRunList
