import { createSlice } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/browser"
import { generatedApi } from "../../app/services/generated-api.js"

const userSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(generatedApi.endpoints.getUserV1Whoami.matchFulfilled, (_state, { payload }) => {
      Sentry.setUser({ id: payload.id, email: payload.email })

      pendo.initialize({
        visitor: {
          id: `${import.meta.env.VITE_BRM_ENV === "production" ? "" : `${import.meta.env.VITE_BRM_ENV}-`}${
            payload.email
          }`,
        },
        account: {
          id: `${import.meta.env.VITE_BRM_ENV === "production" ? "" : `${import.meta.env.VITE_BRM_ENV}-`}${
            payload.organization_id
          }`,
        },
      })
    })
  },
})

export const userReducer = userSlice.reducer
