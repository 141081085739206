import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { WORKFLOW_RUN_STEP_FORM_ID } from "../utils.js"

interface WorkflowRunStepAdminApprovalButtonProps {
  isLoading: boolean
  stepDisplayName: string
  approverNames: string[]
}

export default function WorkflowRunStepAdminApprovalButton({
  isLoading,
  stepDisplayName,
  approverNames,
}: WorkflowRunStepAdminApprovalButtonProps) {
  const intl = useIntl()
  const adminApprovalModal = useDisclosure()
  return (
    <>
      <Button onClick={adminApprovalModal.onOpen} colorScheme="brand" isDisabled={isLoading}>
        <FormattedMessage
          defaultMessage="Approve"
          description="Button label to approve the current step"
          id="requests.run.stage.form.approve"
        />
      </Button>
      <Modal {...adminApprovalModal} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              defaultMessage="Approve {stepDisplayName}"
              description="Button label to approve the current step"
              id="requests.run.stage.form.approve"
              values={{ stepDisplayName }}
            />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              defaultMessage="You are not one of the currently assigned approvers. Are you sure you want to approve this step on behalf of {approvers}?"
              description="Confirmation message to approve the current step"
              id="requests.run.stage.form.approve.confirmation"
              values={{
                approvers: <strong>{intl.formatList(approverNames, { type: "conjunction" })}</strong>,
              }}
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button onClick={adminApprovalModal.onClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Button label to cancel the approval modal"
                id="requests.run.stage.form.approve.cancel"
              />
            </Button>
            <Button
              form={WORKFLOW_RUN_STEP_FORM_ID}
              type="submit"
              name="proceed"
              colorScheme="brand"
              isLoading={isLoading}
              onClick={adminApprovalModal.onClose}
            >
              <FormattedMessage
                defaultMessage="Confirm"
                description="Button label to confirm the approval"
                id="requests.run.stage.form.approve.confirm"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
