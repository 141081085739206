import type { DocumentMinimal } from "@brm/schema-types/types.js"
import { Box, Button, Flex, HStack, Icon, Spinner, Text } from "@chakra-ui/react"
import prettyBytes from "pretty-bytes"
import type { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { To } from "react-router-dom"
import { documentStatusText } from "../../features/legal/helpers.js"
import UserWithProviderBadge from "../DataTable/CellRenderer/UserWithProviderBadge.js"
import { RefreshIcon } from "../icons/icons.js"
import { Link, LinkOrSpan } from "../Link.js"
import { FileTypeIcon } from "./FileTypeIcon.js"

export interface DocumentDisplayProps {
  document: DocumentMinimal | File
  isUploading?: boolean
  isError?: boolean
  to?: To
  // Callback when extraction status is failed
  onRetryExtraction?: () => void
}

export const DocumentDisplay: FunctionComponent<DocumentDisplayProps> = ({
  document,
  isUploading,
  isError,
  to,
  onRetryExtraction,
}) => {
  const intl = useIntl()
  const fileName = document instanceof File ? document.name : document.file_name
  const fileSize = document instanceof File ? document.size : document.file_size
  const byteStr = fileSize ? prettyBytes(fileSize, { locale: intl.locale }) : null
  const extractionStatus = "extraction_status" in document ? document.extraction_status : null
  const extractionText = "extraction_status" in document ? documentStatusText(document, intl) : null

  return (
    <HStack minW={0} justify="space-between" width="full">
      <HStack maxW="70%">
        <FileTypeIcon mimeType={"mime_type" in document ? document.mime_type : document.type} boxSize={8} />
        <Flex flexDirection="column" alignItems="start" minW={0}>
          <LinkOrSpan to={to} maxW="full" minW="4ch">
            <Text
              fontWeight="medium"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              textColor={isError ? "error" : "black"}
            >
              {fileName || (
                <FormattedMessage
                  id="file.filename.unknown"
                  description="Unknown filename placeholder"
                  defaultMessage="Unnamed file"
                />
              )}
            </Text>
          </LinkOrSpan>
          {(byteStr || extractionText) && (
            <Text fontWeight="normal" color={isError ? "error.600" : "gray.600"} whiteSpace="nowrap" fontSize="sm">
              {byteStr && extractionText ? `${byteStr} — ${extractionText}` : byteStr || extractionText}
            </Text>
          )}
          {"object_type" in document && extractionStatus === "failed" && onRetryExtraction && (
            <Button
              as={Link}
              variant="link"
              onClick={onRetryExtraction}
              textColor="gray.700"
              fontSize="sm"
              paddingBottom={1}
              gap={2}
            >
              <FormattedMessage
                id="document.retry_extraction"
                defaultMessage="Retry extraction"
                description="Text shown under a document when extraction has failed"
              />
              <Icon as={RefreshIcon} boxSize={4} />
            </Button>
          )}
        </Flex>
      </HStack>
      {isUploading ? (
        <Box>
          <Spinner />
        </Box>
      ) : null}
      {"object_type" in document &&
        document.user_email_integrations?.map((userWithProvider) => (
          <UserWithProviderBadge key={userWithProvider.id} user={userWithProvider} />
        ))}
    </HStack>
  )
}
