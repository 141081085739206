import type { IntlFormatters } from "react-intl"
import type { Descendant } from "slate"
import { isWhitespaceParagraphNode, trimRichTextWhiteSpace } from "../components/RichTextEditor/util/common.js"

/**
 * Validates that a string value is present. Accepts an optional error message which, if supplied, will be
 * returned if the string field fails validation.
 */
export const validateStringRequired = (value: string | null | undefined, errorMessage?: string) => {
  return typeof value === "string" || errorMessage || false
}

export const validateRichTextRequired = (value: Descendant[] | null | undefined, errorMessage?: string) => {
  return (
    (!!value && trimRichTextWhiteSpace(value).some((node) => !isWhitespaceParagraphNode(node))) || errorMessage || false
  )
}

/**
 * Validates that a boolean value is present. Accepts an optional error message which, if supplied, will be
 * returned if the boolean field fails validation.
 */
export const validateBooleanRequired = (value: boolean | null | undefined, errorMessage?: string) => {
  return typeof value === "boolean" || errorMessage || false
}

/**
 * Validates that an array value is present (an empty array is valid). Accepts an optional error message which, if
 * supplied, will be returned if the boolean field fails validation.
 */
export const validateArrayRequired = (value: unknown[] | null | undefined, errorMessage?: string) => {
  return Array.isArray(value) || errorMessage || false
}

export const validateArrayNotEmpty = (value: unknown[] | null | undefined, errorMessage?: string) => {
  return (Array.isArray(value) && value.length > 0) || errorMessage || false
}

/**
 * Validates that a string value is a valid prose string (i.e. contains at least one letter) Accepts an optional
 * error message which, if supplied, will be returned if the string field fails validation.
 */
export const validateProseString = (value: string | null | undefined, errorMessage?: string) => {
  if (!value) {
    return errorMessage || false
  }
  return (value.length > 0 && /\p{Letter}/u.test(value)) || errorMessage || false
}

/**
 * Validates that the input is a valid absolute URL including protocol (e.g. https://example.com).
 */
export const validateURL =
  (intl: IntlFormatters) =>
  (value: string | null | undefined): true | string => {
    if (!value) {
      return true
    }

    return (
      URL.canParse(value) ||
      intl.formatMessage({
        defaultMessage: "Invalid URL",
        description: "Error message displayed when a URL is invalid",
        id: "validation.invalidURL",
      })
    )
  }
