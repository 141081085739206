import { chakra } from "@chakra-ui/system"

const BetsyDisplayName = () => {
  return (
    // eslint-disable-next-line react/jsx-no-literals
    <chakra.span color="blueLight.700">AI</chakra.span>
  )
}

export default BetsyDisplayName
