import type { BetweenFilter, ExistsFilter, GteFilter, LteFilter } from "@brm/schema-types/types.js"
import { maxValueFromFilterField, minValueFromFilterField } from "@brm/type-helpers/filters.js"
import { Flex } from "@chakra-ui/react"
import { useState } from "react"
import { isNotUndefined } from "typed-assert"
import { useDebouncedCallback } from "use-debounce"
import { InputRange } from "../../../Form/InputRange.js"
import { NullCheckbox } from "../../../Form/NullCheckbox.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "../constants.js"
import type { FilterProps } from "./types.js"

/** Filters are always in a menu so components like MenuItems must be used instead of Buttons */
export default function MoneyRangeFilter({
  filter,
  displayPath,
  fieldSchema,
  isNullable,
  onChange,
}: FilterProps<BetweenFilter | GteFilter | LteFilter | ExistsFilter>) {
  const hideCustomInputRange = false // TODO

  const fieldName = displayPath.at(-1)
  isNotUndefined(fieldName, "Display path cannot be empty for filter")

  const [minValue, setMinValue] = useState(minValueFromFilterField(filter))
  const [maxValue, setMaxValue] = useState(maxValueFromFilterField(filter))
  const isNullChecked = filter?.comparator === "exists" ? !filter.value : false

  const reportChange = (minValue?: string, maxValue?: string) => {
    if (minValue && maxValue) {
      onChange({ comparator: "between", minValue, maxValue })
    } else if (minValue) {
      onChange({ comparator: "gte", value: minValue })
    } else if (maxValue) {
      onChange({ comparator: "lte", value: maxValue })
    }
  }

  const debouncedReportChange = useDebouncedCallback(() => {
    reportChange(minValue, maxValue)
  }, 200)

  return (
    <Flex flexDir="column" gap={3} maxH={FILTER_POPOVER_MAX_HEIGHT_CALC} p={3}>
      {isNullable && (
        <NullCheckbox
          isChecked={isNullChecked}
          fieldName={fieldName}
          fieldSchema={fieldSchema}
          onChange={({ target: { checked } }) => {
            onChange({ comparator: "exists", value: !checked })
            setMinValue("")
            setMaxValue("")
          }}
        />
      )}
      {/* TODO have MenuInputRange support CurrencyInput */}
      {!hideCustomInputRange && (
        <InputRange
          leftInputProps={{
            value: minValue,
            autoFocus: true,
            onChange: (e) => {
              setMinValue(e.target.value)
              debouncedReportChange()
            },
          }}
          rightInputProps={{
            value: maxValue,
            onChange: (e) => {
              setMaxValue(e.target.value)
              debouncedReportChange()
            },
          }}
        />
      )}
    </Flex>
  )
}
