import type { SavedView } from "@brm/schema-types/types.js"
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDeleteSavedViewV1ByIdMutation } from "../../app/services/generated-api.js"
import { apiCallToastWrapper } from "../../util/api.js"

export function TableDeleteViewModal({
  viewToDelete,
  onDelete,
  ...modalProps
}: { viewToDelete: SavedView; onDelete: () => void } & UseModalProps) {
  const toast = useToast()
  const intl = useIntl()

  const [deleteView] = useDeleteSavedViewV1ByIdMutation()

  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Confirm Delete View"
            description="Confirm view change modal title"
            id="dataTable.confirmDeleteViewModal.title"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {viewToDelete.is_shared ? (
            <FormattedMessage
              defaultMessage="Are you sure you want to delete the shared view “{viewName}”? It will be deleted for all users in your organization."
              description="Confirm view change modal body"
              id="dataTable.confirmDeleteViewModal.body"
              values={{ viewName: viewToDelete.name }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Are you sure you want to delete your private view “{viewName}”?"
              description="Confirm view change modal body"
              id="dataTable.confirmDeleteViewModal.body"
              values={{ viewName: viewToDelete.name }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <HStack gap={3} width="100%">
            <Button width="100%" variant="outline" onClick={modalProps.onClose}>
              <FormattedMessage
                id="dataTable.selectedViewModal.close"
                defaultMessage="Keep"
                description="Select new view modal close button text"
              />
            </Button>
            <Button
              width="100%"
              colorScheme="error"
              onClick={async () => {
                await apiCallToastWrapper({
                  toast,
                  apiCall: () => deleteView(viewToDelete).unwrap(),
                  onSuccess: () => {
                    onDelete()
                    modalProps.onClose()
                  },
                  successMessage: intl.formatMessage({
                    id: "dataTable.confirmDeleteViewModal.successToast",
                    defaultMessage: "View deleted",
                    description: "Confirm delete view modal success toast",
                  }),
                  fallbackErrorMessage: intl.formatMessage({
                    id: "dataTable.confirmDeleteViewModal.errorToast",
                    defaultMessage: "An error occured deleting the view",
                    description: "Confirm delete view modal error toast",
                  }),
                })
              }}
            >
              <FormattedMessage
                id="dataTable.selectedViewModal.confirm"
                defaultMessage="Delete"
                description="Select new view modal confirm button text"
              />
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
