export const CELL_SIZE_PROPS_MAP = {
  sm: {
    boxSize: 4,
    fontWeight: "normal",
  },
  md: {
    boxSize: 6,
    fontWeight: "medium",
  },
} as const

export type CellSize = keyof typeof CELL_SIZE_PROPS_MAP
