import { Code, Flex, Heading, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { useRouteError } from "react-router-dom"
import { serializeError } from "serialize-error"
import { Link } from "../../components/Link.js"
import { StatusCodes, getAPIErrorStatusCode } from "../../util/error.js"
import { log } from "../../util/logger.js"
import NotFoundErrorView from "./NotFoundErrorView.js"

export default function ErrorBoundary() {
  const error = useRouteError() as { statusText: string; message: string; error: string; data?: { message?: string } }
  log.error("Unexpected error", error)

  let serializedError: string | undefined
  try {
    // in theory this should never crash but serializing an Error in JS is a bit of a minefield
    // and crashing the error page is the last thing we want to do -> be very defensive here
    serializedError = JSON.stringify(serializeError(error), null, 2)
  } catch (err) {
    log.error("Failed to serialize error", err)
  }

  if (getAPIErrorStatusCode(error) === StatusCodes.NOT_FOUND) {
    return <NotFoundErrorView error={error} serializedError={serializedError} />
  }

  // URLSearchParams encodes spaces as "+", which Apple Mail doesn't support, so we have to use encodeURIComponent
  const body = encodeURIComponent(
    `I encountered an error in the BRM app. Please help me fix it.\n\nTechnical Details:\n${serializedError}`
  )

  return (
    <Flex mt={5} flexDirection="column" alignItems="center">
      <Heading as="h1" size="md">
        <FormattedMessage id="error.message.header" description="error page header" defaultMessage="Unexpected Error" />
      </Heading>
      <Text mt={5} textAlign="center">
        <FormattedMessage
          id="error.message.description"
          description="error page text"
          defaultMessage="An unexpected error has occurred. Please contact BRM support if the problem persists and we will get back to you as soon as possible."
        />
      </Text>
      {}
      <Link
        fontSize="xl"
        mt={10}
        variant="highlighted"
        //
        to={`mailto:support@brm.ai?subject=BRM Error&body=${body}`}
        // eslint-disable-next-line react/jsx-no-literals
      >
        support@brm.ai
      </Link>
      <Code whiteSpace="pre-wrap" mt={10} p={3} fontFamily="mono">
        {serializedError || (
          <FormattedMessage
            id="error.message.default"
            description="Default error text if no other text is available"
            defaultMessage="Unknown Error"
          />
        )}
      </Code>
    </Flex>
  )
}
