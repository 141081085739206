import type { ComponentWithAs } from "@chakra-ui/react"
import { Badge, Box, Icon } from "@chakra-ui/react"
import type { ReactNode } from "react"
import { NextIcon } from "../../../components/icons/icons.js"

type NestedBadgeProps = React.ComponentProps<typeof Badge> & {
  leftBadgeContent?: ReactNode
  rightContent: ReactNode
}

export const NestedBadge: ComponentWithAs<"span", NestedBadgeProps> = ({
  colorScheme,
  leftBadgeContent,
  rightContent,
  ...restProps
}) => {
  return (
    <Badge
      size="sm"
      variant="subtleOutlined"
      colorScheme={colorScheme}
      padding={0.5}
      borderRadius="3xl"
      fontWeight="normal"
      display="flex"
      width="max-content"
      alignItems="center"
      flexShrink={1}
      overflow="hidden"
      maxW="100%"
      gap={1}
      {...restProps}
    >
      {leftBadgeContent && (
        <Badge
          size="sm"
          variant="subtleOutlined"
          colorScheme={colorScheme}
          paddingY="2px"
          borderRadius="2xl"
          backgroundColor="white"
        >
          {leftBadgeContent}
        </Badge>
      )}
      {rightContent && (
        <Box ml={1} overflow="hidden" textOverflow="ellipsis">
          {rightContent}
        </Box>
      )}
      <Icon stroke={`${colorScheme}.500`} as={NextIcon} mr={1} />
    </Badge>
  )
}
