import type { LegalAgreementMinimal } from "@brm/schema-types/types.js"
import type { UseModalProps } from "@chakra-ui/react"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDeleteLegalV1AgreementsByIdMutation } from "../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { log } from "../../util/logger.js"

export const DeleteLegalAgreementModal: FunctionComponent<
  UseModalProps & {
    legalAgreementBeingDeleted: Pick<LegalAgreementMinimal, "id">
    deletionMessage?: string
    onCancel?: () => void
    onDelete?: () => void
  }
> = ({ legalAgreementBeingDeleted, deletionMessage, onCancel, onDelete, ...modalProps }) => {
  const intl = useIntl()
  const toast = useToast()
  const [deleteLegalAgreement, deleteLegalAgreementResult] = useDeleteLegalV1AgreementsByIdMutation()

  const onDeleteLegalAgreementClick = async () => {
    try {
      await deleteLegalAgreement({ id: legalAgreementBeingDeleted.id }).unwrap()
      toast({
        status: "success",
        description: intl.formatMessage({
          defaultMessage: "Agreement deleted",
          description: "The toast title for the success toast when a legal agreement was deleted",
          id: "legalAgreement.modal.delete.success.toast.title",
        }),
      })
      modalProps.onClose?.()
    } catch (err) {
      log.error("Error deleting legal agreement", err, { legalAgreementBeingDeleted })
      const title =
        getAPIErrorMessage(err) ||
        intl.formatMessage({
          defaultMessage: "Agreement could not be deleted",
          description: "The toast title for the error toast when a legal agreement could not be deleted",
          id: "legalAgreement.modal.delete.error.toast.title",
        })
      toast({ status: "error", title, duration: null })
    }
    onDelete?.()
  }
  return (
    <Modal {...modalProps} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Delete Agreement"
            description="The header for the delete legal agreement modal"
            id="legalAgreement.modal.delete.header"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {deletionMessage ? (
            deletionMessage
          ) : (
            <FormattedMessage
              defaultMessage="Are you sure you want to delete this agreement? It cannot be restored."
              id="legalAgreement.modal.delete.confirmation"
              description="Confirmation displayed when a user attempts to delete a legal agreement. Displayed on a modal where the user can confirm or cancel the deletion"
            />
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button type="button" onClick={onCancel}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="The label for the cancel button in the delete legal agreement modal"
              id="legalAgreement.modal.delete.cancel"
            />
          </Button>
          <Button
            type="submit"
            colorScheme="error"
            onClick={onDeleteLegalAgreementClick}
            isLoading={deleteLegalAgreementResult.isLoading}
          >
            <FormattedMessage
              defaultMessage="Delete"
              description="The label for the delete button in the delete legal agreement modal"
              id="legalAgreement.modal.delete.delete"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
