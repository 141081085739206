import { buildCreateApi, coreModule, fetchBaseQuery, reactHooksModule } from "@reduxjs/toolkit/query/react"

// Define a service using a base URL and expected endpoints
const createApi = buildCreateApi(coreModule(), reactHooksModule())
const brmApi = createApi({
  reducerPath: "brmApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    credentials: "include",
  }),
  endpoints: () => ({}),
})

export { brmApi }
