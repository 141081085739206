import type { WorkflowRunListItem } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Card, HStack, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "../../../components/Link.js"
import { Timestamp } from "../../../components/Timestamp.js"
import { pathForWorkflowRun } from "../../../util/json-schema.js"
import { AUTO_SELECT_STEP_HASH } from "../constants.js"
import { displayWorkflowStatus } from "../util.js"
import { NestedBadge } from "./NestedBadge.js"
import { workflowRunStatusColors } from "./status-colors.js"

interface Props {
  /** Name of the tool or vendor detail page that the workflow card is in */
  sorDisplayName: string
  workflowRun: WorkflowRunListItem
}

export const WorkflowRunCard = ({ sorDisplayName, workflowRun }: Props) => {
  const intl = useIntl()

  return (
    <Card variant="outline" px={6} py={4}>
      <HStack justifyContent="space-between">
        <Stack>
          <HStack alignItems="center" gap={2}>
            <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" flexShrink={1} minW="5ch" isTruncated>
              {workflowRun.display_name}
            </Text>
            <Text as="span" fontSize="xs" color="gray.600">
              <Timestamp dateTime={workflowRun.created_at} />
            </Text>
          </HStack>
          <Text as="span">
            {workflowRun.kind === "purchase" ? (
              <FormattedMessage
                id="workflowRunCard.purchase.subdetail"
                description="Subdetail for purchase workflow run card"
                defaultMessage="{createdByUser} created a request to purchase {sorDisplayName}"
                values={{
                  createdByUser: displayPersonName(workflowRun.created_by, intl),
                  sorDisplayName,
                }}
              />
            ) : workflowRun.kind === "renewal" ? (
              <FormattedMessage
                id="workflowRunCard.renewal.subdetail"
                description="Subdetail for renewal workflow run card"
                defaultMessage="{createdByUser} created a request to renew {sorDisplayName}"
                values={{
                  createdByUser: displayPersonName(workflowRun.created_by, intl),
                  sorDisplayName,
                }}
              />
            ) : (
              unreachable(workflowRun.kind)
            )}
          </Text>
        </Stack>

        <NestedBadge
          as={Link}
          variant="clickable"
          to={{ pathname: pathForWorkflowRun(workflowRun), hash: AUTO_SELECT_STEP_HASH }}
          colorScheme={workflowRunStatusColors[workflowRun.status]}
          leftBadgeContent={displayWorkflowStatus(workflowRun.status)}
          rightContent={<Text>{workflowRun.display_name}</Text>}
        />
      </HStack>
    </Card>
  )
}
