import { hasPermission } from "@brm/schema-helpers/role.js"
import { Box, Button, FormControl, FormErrorMessage, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { Controller, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1DefinitionsPurchaseQuery,
  useGetWorkflowV1DefinitionsRenewalQuery,
} from "../../../../app/services/generated-api.js"
import { NextIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import type { StartWorkflowFormState } from "./util.js"

export type WorkflowKindSectionFormState = Pick<StartWorkflowFormState, "workflow_kind">

export default function WorkflowKindSection({
  control,
  startPurchase,
  startRenewal,
  closeModal,
}: {
  control: Control<WorkflowKindSectionFormState>
  startPurchase: () => void
  startRenewal: () => void
  closeModal: () => void
}) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { error: renewalError, isLoading: isRenewalLoading } = useGetWorkflowV1DefinitionsRenewalQuery()
  const { error: purchaseError, isLoading: isPurchaseLoading } = useGetWorkflowV1DefinitionsPurchaseQuery()
  const missingPurchaseConfig = purchaseError && "status" in purchaseError && purchaseError.status === 404
  const missingRenewalConfig = renewalError && "status" in renewalError && renewalError.status === 404

  let missing: JSX.Element | null = null
  if (!isPurchaseLoading) {
    if (missingRenewalConfig && missingPurchaseConfig) {
      missing = (
        <FormattedMessage
          id="request.start.config.cta.requests"
          description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
          defaultMessage="requests"
        />
      )
    } else if (missingPurchaseConfig) {
      missing = (
        <FormattedMessage
          id="request.start.config.cta.purchase"
          description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
          defaultMessage="new purchases"
        />
      )
    } else if (missingRenewalConfig) {
      missing = (
        <FormattedMessage
          id="request.start.config.cta.renewal"
          description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
          defaultMessage="renewals"
        />
      )
    }
  }

  return (
    <>
      <Text>
        <FormattedMessage
          id="requests.start.modal.greeting"
          description="Greeting text of the start request modal prompting user for input"
          defaultMessage="Hi {user}, what would you like to request?"
          values={{
            user: whoami?.first_name,
          }}
        />
      </Text>
      <Controller
        name="workflow_kind"
        control={control}
        rules={{
          required: intl.formatMessage({
            id: "requests.start.modal.kind.required",
            defaultMessage: "Select one option to proceed",
            description: "Error message shown when a user tries to continue without selecting a workflow kind",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
            <Stack gap={4}>
              <HStack alignItems="center">
                <Button
                  onClick={() => {
                    field.onChange("purchase")
                    startPurchase()
                  }}
                  variant="hoverOutline"
                  isDisabled={missingPurchaseConfig}
                  width="full"
                  justifyContent="start"
                >
                  <FormattedMessage
                    id="request.start.modal.kind.purchase"
                    defaultMessage="New purchase"
                    description="Request start modal button option to request to purchase a tool"
                  />
                </Button>
              </HStack>
              <HStack alignItems="center">
                <Button
                  onClick={() => {
                    field.onChange("renewal")
                    startRenewal()
                  }}
                  variant="hoverOutline"
                  isDisabled={missingRenewalConfig}
                  width="full"
                  justifyContent="start"
                >
                  <FormattedMessage
                    id="request.start.modal.kind.renewal"
                    defaultMessage="Renew existing tool"
                    description="Request start button option to request to renew a tool"
                  />
                </Button>
              </HStack>
            </Stack>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      {!isRenewalLoading && !isPurchaseLoading && missing ? (
        <Box mt={6} fontSize="sm">
          {hasPermission(whoami?.roles, "workflow_def:create") ? (
            <Link
              to="/settings/requests"
              onClick={closeModal}
              variant="highlighted"
              display="flex"
              gap={0.5}
              alignItems="center"
            >
              <FormattedMessage
                id="request.start.config.cta"
                description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
                defaultMessage="Your organization hasn’t configured {missing} yet. Get started."
                values={{ missing }}
              />
              <Icon as={NextIcon} />
            </Link>
          ) : (
            <Text>
              <FormattedMessage
                id="request.start.config.no_permission"
                description="Message shown to user when no request types have been configured"
                defaultMessage="Your organization hasn’t configured {missing} yet. Please contact an administrator to get started."
                values={{ missing }}
              />
            </Text>
          )}
        </Box>
      ) : null}
    </>
  )
}
