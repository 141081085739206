import type { SigninProviderType } from "@brm/schema-types/types.js"
import { Button, HStack, Stack, Text, type ButtonProps } from "@chakra-ui/react"
import { type FC } from "react"
import { FormattedMessage } from "react-intl"
import { Link, type LinkProps } from "../../components/Link.js"
import { GoogleIcon, OktaIcon } from "../../components/icons/provider-icons.js"

interface OAuthButtonGroupProps {
  isCreateAccountFlow?: boolean
  linkAuthCode?: string
}

const OAuthButtonGroup: FC<OAuthButtonGroupProps> = (props) => {
  return (
    <Stack gap={4}>
      <OAuthButton key="okta" provider="okta" as={Link} to="/login/sso" {...props} />
      <OAuthButton
        key="google"
        provider="google"
        to={`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/signin/google${props.linkAuthCode ? `?linkAuthCode=${props.linkAuthCode}` : ""}`}
        {...props}
      />
    </Stack>
  )
}

export default OAuthButtonGroup

const providerButtonDisplayMap: Record<SigninProviderType, { name: string; icon: JSX.Element }> = {
  google: { name: "Google", icon: <GoogleIcon boxSize={5} /> },
  okta: { name: "Okta", icon: <OktaIcon boxSize={5} /> },
}
interface OAuthButtonProps {
  provider: SigninProviderType
  isCreateAccountFlow?: boolean
}

export const OAuthButton: FC<ButtonProps & OAuthButtonProps & Pick<LinkProps, "to">> = ({
  provider,
  isCreateAccountFlow,
  to,
  ...props
}) => {
  const { name, icon } = providerButtonDisplayMap[provider]

  return (
    <Button id={`${provider}-login`} variant="outline" as={Link} to={to} target="_self" width="full" {...props}>
      <HStack>
        {icon}
        <Text>
          {isCreateAccountFlow ? (
            <FormattedMessage
              id="create_account.oauth.option"
              description="OAuth create account option CTA"
              defaultMessage="Sign up with {providerName}"
              values={{ providerName: name }}
            />
          ) : (
            <FormattedMessage
              id="login.oauth.option"
              description="OAuth sign in option CTA"
              defaultMessage="Sign in with {providerName}"
              values={{ providerName: name }}
            />
          )}
        </Text>
      </HStack>
    </Button>
  )
}
