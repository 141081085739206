import type { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { isApplePlatform } from "../../util/platform.js"

export const ModifierKey: FunctionComponent = () => {
  if (isApplePlatform()) {
    return "⌘"
  }
  return <FormattedMessage defaultMessage="ctrl" description="The ctrl key" id="navHeader.braim.modifierKey" />
}
