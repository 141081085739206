import type { ObjectType, PickableEntity, UserPicker, WorkflowRunFieldGatherer } from "@brm/schema-types/types.js"
import { Icon, Spinner, chakra } from "@chakra-ui/react"
import { useEffect, type FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useLazyPostOrganizationV1PickableEntitiesQuery,
  usePutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererMutation,
} from "../../app/services/generated-api.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { UserPersonEntityCell } from "../EntityPicker.js"
import { AddUserIcon } from "../icons/icons.js"

interface FieldGathererSelectorProps {
  gatherer?: WorkflowRunFieldGatherer
  objectId: string
  objectType: ObjectType
  fieldName: string
  workflowRunId: string
  canEdit: boolean
  shouldShowSellers: boolean
  isCustomField: boolean
  workflowRunStepId: string
}

const FieldGathererSelector: FC<FieldGathererSelectorProps & GetLogoForOrganizationProps> = ({
  gatherer,
  objectId,
  objectType,
  fieldName,
  workflowRunId,
  canEdit,
  shouldShowSellers,
  isCustomField,
  workflowRunStepId,
  getLogoToShowByOrganizationId,
}) => {
  const intl = useIntl()
  const [getPickableUsers, pickableUsersResponse] = useLazyPostOrganizationV1PickableEntitiesQuery()

  useEffect(() => {
    if (canEdit) {
      const entitiesToQuery: Array<"user" | "workflow_seller"> = ["user"]
      if (shouldShowSellers) {
        entitiesToQuery.push("workflow_seller")
      }
      void getPickableUsers({
        body: {
          filter: {
            entities: entitiesToQuery,
            requesting_entity: {
              object_id: workflowRunId,
              object_type: "WorkflowRun",
            },
          },
        },
      })
    }
  }, [canEdit, getPickableUsers, shouldShowSellers, workflowRunId])

  const [updateFieldGatherer, { isLoading: fieldGathererUpdateIsLoading }] =
    usePutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererMutation()
  const pickableUsers = pickableUsersResponse.data as UserPicker[] | undefined

  if (fieldGathererUpdateIsLoading) {
    return <Spinner />
  }

  const pickableUser: UserPicker | null = gatherer?.gatherer
    ? {
        type: "user",
        display_name: gatherer.gatherer.person.display_name,
        image_asset: gatherer.gatherer.profile_image,
        ...gatherer.gatherer,
      }
    : null

  return (
    <chakra.span maxW={200} overflow="hidden" display="flex" flexShrink={0} justifyContent="safe flex-end">
      <UserPersonEntityCell
        getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        noEntitySelectedLabel="No Gatherer"
        entity={pickableUser}
        canEdit={canEdit}
        pickableEntities={pickableUsers || []}
        onSelect={async (entity: PickableEntity | null) => {
          if (entity !== null && entity.type !== "user") {
            throw new Error("entity should always be user or null here")
          }
          await updateFieldGatherer({
            id: workflowRunId,
            objectId,
            objectType,
            fieldName,
            setFieldGatherRequest: {
              gatherer_id: entity?.id || null,
              is_custom: isCustomField,
              workflow_run_step_id: workflowRunStepId,
            },
          })
        }}
        emptyStateIcon={<Icon as={AddUserIcon} />}
        toolTipLabel={
          canEdit ? (
            <FormattedMessage
              defaultMessage="Assign a gatherer to the field"
              description="Tooltip for the assign gatherer input"
              id="workflowRun.form.field.assignGatherer"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Field gatherer"
              description="Tooltip for the field gatherer"
              id="workflowRun.form.field.assignGatherer.noEdit"
            />
          )
        }
        showTooltip={true}
        searchForEntityLabel={intl.formatMessage({
          id: "fieldGatherer.search.placeholder",
          description: "Placeholder text for the search input when changing the field gatherer",
          defaultMessage: "Search for user",
        })}
      />
    </chakra.span>
  )
}

export default FieldGathererSelector
