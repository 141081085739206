import type { ConditionType, WorkflowConditionGroupInput, WorkflowConditionInput } from "@brm/schema-types/types.js"
import { getSchemaAtPath, getTitle, unwrapNullableSchema } from "@brm/util/schema.js"
import { Grid, GridItem, Input, Stack, Text } from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import { Select } from "chakra-react-select"
import { Controller, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { SchemaComparator } from "../../../components/SchemaForm/SchemaComparator.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import { type ConfigureWorkflowStepsFormState } from "./utils.js"

export interface ConditionGroupProps {
  basePath: string
  control: Control<ConfigureWorkflowStepsFormState>
}

export default function ConditionGroup({ basePath, control }: ConditionGroupProps) {
  const intl = useIntl()
  return (
    <Controller
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      name={basePath as any}
      control={control}
      render={({ field }) => {
        const conditionGroup: WorkflowConditionGroupInput | null = field.value

        if (!conditionGroup) {
          return (
            <Text color="gray.600">
              <FormattedMessage
                id="request.config.condition.group.empty"
                defaultMessage="No conditions currently applied. Try prompting to generate!"
                description="Text for empty condition group"
              />
            </Text>
          )
        }

        return (
          <Stack borderWidth={1} borderColor="gray.200" borderRadius="md" p={4}>
            <Select<{ value: ConditionType }>
              variant="unstyled"
              options={[{ value: "and" }, { value: "or" }]}
              getOptionLabel={(option) =>
                option.value === "and"
                  ? intl.formatMessage({
                      id: "request.config.condition.group.and",
                      defaultMessage: "All of the following must be true",
                      description: "Label for AND condition group",
                    })
                  : intl.formatMessage({
                      id: "request.config.condition.group.or",
                      defaultMessage: "At least one of the following must be true",
                      description: "Label for OR condition group",
                    })
              }
              isSearchable={false}
              value={{ value: conditionGroup.group_type }}
              onChange={(value) => {
                if (value) {
                  field.onChange({ ...conditionGroup, group_type: value.value })
                }
              }}
            />
            <Grid templateColumns="repeat(3, 1fr)" rowGap={4} columnGap={2}>
              {conditionGroup.conditions.map((_, idx) => (
                <Controller
                  key={idx}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={`${basePath}.conditions.${idx}` as any}
                  control={control}
                  render={({ field: conditionField }) => (
                    <FieldCondition condition={conditionField.value} onChange={conditionField.onChange} />
                  )}
                />
              ))}
            </Grid>
            <Stack>
              {conditionGroup.children_groups.map((_, idx) => (
                <ConditionGroup key={idx} basePath={`${basePath}.children_groups.${idx}`} control={control} />
              ))}
            </Stack>
          </Stack>
        )
      }}
    />
  )
}

function FieldCondition({
  condition,
  onChange,
}: {
  condition: WorkflowConditionInput
  onChange: (condition: WorkflowConditionInput) => void
}) {
  const objectSchema = useObjectSchema(condition.object_type ?? skipToken)

  const maybeCustomFieldName = condition.is_custom ? ["custom", condition.field_name] : [condition.field_name]
  const fieldSchema = getSchemaAtPath(objectSchema, maybeCustomFieldName)
  if (!fieldSchema) {
    return null
  }
  const fieldName = getTitle(condition.field_name, fieldSchema)

  return (
    <>
      <GridItem colSpan={1}>
        <Input value={fieldName} readOnly />
      </GridItem>
      <SchemaComparator
        referenceSchema={unwrapNullableSchema(fieldSchema)}
        conditionalSchema={condition.condition_schema as ReadonlyDeep<JSONSchemaObject>}
        onChange={(newSchema) => onChange({ ...condition, condition_schema: newSchema })}
      />
    </>
  )
}
