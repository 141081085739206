import { isEmpty } from "@brm/util/type-guard.js"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { type PostBetsyV1AskApiArg } from "../../app/services/generated-api.js"
import { log } from "../../util/logger.js"

/**
 * Reusable function to ask Betsy questions from other components.
 */
export async function askBetsy(
  body: PostBetsyV1AskApiArg["body"],
  onMessage: (text: string) => void,
  signal: AbortSignal
) {
  await fetchEventSource(`${import.meta.env.VITE_API_BASE_URL}/betsy/v1/ask`, {
    method: "POST",
    credentials: "include",
    openWhenHidden: true,
    body: JSON.stringify(body),
    signal,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    onmessage(event) {
      if (event.event === "data") {
        const data = JSON.parse(event.data)
        const text = data?.text
        if (!isEmpty(text)) {
          onMessage(text)
        }
      }
    },
    onerror(e) {
      log.error("braim api failure", e)
    },
  })
}
