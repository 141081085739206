import type { TransactionCategory } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { IntlShape } from "react-intl"

export const displayTransactionCategory = (category: TransactionCategory, intl: IntlShape) => {
  switch (category) {
    case "advertising":
      return intl.formatMessage({
        id: "transaction.category.advertising",
        defaultMessage: "Advertising",
        description: "The display name for the advertising transaction category",
      })
    case "airlines":
      return intl.formatMessage({
        id: "transaction.category.airlines",
        defaultMessage: "Airlines",
        description: "The display name for the airlines transaction category",
      })
    case "alcohol_and_bars":
      return intl.formatMessage({
        id: "transaction.category.alcohol_and_bars",
        defaultMessage: "Alcohol and Bars",
        description: "The display name for the alcohol_and_bars transaction category",
      })
    case "books_and_newspapers":
      return intl.formatMessage({
        id: "transaction.category.books_and_newspapers",
        defaultMessage: "Books and Newspapers",
        description: "The display name for the books_and_newspapers transaction category",
      })
    case "car_rental":
      return intl.formatMessage({
        id: "transaction.category.car_rental",
        defaultMessage: "Car Rental",
        description: "The display name for the car_rental transaction category",
      })
    case "car_services":
      return intl.formatMessage({
        id: "transaction.category.car_services",
        defaultMessage: "Car Services",
        description: "The display name for the car_services transaction category",
      })
    case "charitable_donations":
      return intl.formatMessage({
        id: "transaction.category.charitable_donations",
        defaultMessage: "Charitable Donations",
        description: "The display name for the charitable_donations transaction category",
      })
    case "clothing":
      return intl.formatMessage({
        id: "transaction.category.clothing",
        defaultMessage: "Clothing",
        description: "The display name for the clothing transaction category",
      })
    case "clubs_and_memberships":
      return intl.formatMessage({
        id: "transaction.category.clubs_and_memberships",
        defaultMessage: "Clubs and Memberships",
        description: "The display name for the clubs_and_memberships transaction category",
      })
    case "education":
      return intl.formatMessage({
        id: "transaction.category.education",
        defaultMessage: "Education",
        description: "The display name for the education transaction category",
      })
    case "electronics":
      return intl.formatMessage({
        id: "transaction.category.electronics",
        defaultMessage: "Electronics",
        description: "The display name for the electronics transaction category",
      })
    case "entertainment":
      return intl.formatMessage({
        id: "transaction.category.entertainment",
        defaultMessage: "Entertainment",
        description: "The display name for the entertainment transaction category",
      })
    case "fees_and_financial_institutions":
      return intl.formatMessage({
        id: "transaction.category.fees_and_financial_institutions",
        defaultMessage: "Fees and Financial Institutions",
        description: "The display name for the fees_and_financial_institutions transaction category",
      })
    case "fines":
      return intl.formatMessage({
        id: "transaction.category.fines",
        defaultMessage: "Fines",
        description: "The display name for the fines transaction category",
      })
    case "freight_moving_and_delivery_services":
      return intl.formatMessage({
        id: "transaction.category.freight_moving_and_delivery_services",
        defaultMessage: "Freight, Moving and Delivery Services",
        description: "The display name for the freight_moving_and_delivery_services transaction category",
      })
    case "fuel_and_gas":
      return intl.formatMessage({
        id: "transaction.category.fuel_and_gas",
        defaultMessage: "Fuel and Gas",
        description: "The display name for the fuel_and_gas transaction category",
      })
    case "gambling":
      return intl.formatMessage({
        id: "transaction.category.gambling",
        defaultMessage: "Gambling",
        description: "The display name for the gambling transaction category",
      })
    case "general_merchandise":
      return intl.formatMessage({
        id: "transaction.category.general_merchandise",
        defaultMessage: "General Merchandise",
        description: "The display name for the general_merchandise transaction category",
      })
    case "government_services":
      return intl.formatMessage({
        id: "transaction.category.government_services",
        defaultMessage: "Government Services",
        description: "The display name for the government_services transaction category",
      })
    case "insurance":
      return intl.formatMessage({
        id: "transaction.category.insurance",
        defaultMessage: "Insurance",
        description: "The display name for the insurance transaction category",
      })
    case "internet_and_phone":
      return intl.formatMessage({
        id: "transaction.category.internet_and_phone",
        defaultMessage: "Internet and Phone",
        description: "The display name for the internet_and_phone transaction category",
      })
    case "legal":
      return intl.formatMessage({
        id: "transaction.category.legal",
        defaultMessage: "Legal",
        description: "The display name for the legal transaction category",
      })
    case "lodging":
      return intl.formatMessage({
        id: "transaction.category.lodging",
        defaultMessage: "Lodging",
        description: "The display name for the lodging transaction category",
      })
    case "medical":
      return intl.formatMessage({
        id: "transaction.category.medical",
        defaultMessage: "Medical",
        description: "The display name for the medical transaction category",
      })
    case "office":
      return intl.formatMessage({
        id: "transaction.category.office",
        defaultMessage: "Office",
        description: "The display name for the office transaction category",
      })
    case "office_supplies_and_cleaning":
      return intl.formatMessage({
        id: "transaction.category.office_supplies_and_cleaning",
        defaultMessage: "Office Supplies and Cleaning",
        description: "The display name for the office_supplies_and_cleaning transaction category",
      })
    case "other":
      return intl.formatMessage({
        id: "transaction.category.other",
        defaultMessage: "Other",
        description: "The display name for the other transaction category",
      })
    case "parking":
      return intl.formatMessage({
        id: "transaction.category.parking",
        defaultMessage: "Parking",
        description: "The display name for the parking transaction category",
      })
    case "pet":
      return intl.formatMessage({
        id: "transaction.category.pet",
        defaultMessage: "Pet",
        description: "The display name for the pet transaction category",
      })
    case "political_organizations":
      return intl.formatMessage({
        id: "transaction.category.political_organizations",
        defaultMessage: "Political Organizations",
        description: "The display name for the political_organizations transaction category",
      })
    case "professional_services":
      return intl.formatMessage({
        id: "transaction.category.professional_services",
        defaultMessage: "Professional Services",
        description: "The display name for the professional_services transaction category",
      })
    case "religious_organizations":
      return intl.formatMessage({
        id: "transaction.category.religious_organizations",
        defaultMessage: "Religious Organizations",
        description: "The display name for the religious_organizations transaction category",
      })
    case "restaurants":
      return intl.formatMessage({
        id: "transaction.category.restaurants",
        defaultMessage: "Restaurants",
        description: "The display name for the restaurants transaction category",
      })
    case "saas_software":
      return intl.formatMessage({
        id: "transaction.category.saas_software",
        defaultMessage: "SaaS / Software",
        description: "The display name for the saas_software transaction category",
      })
    case "shipping":
      return intl.formatMessage({
        id: "transaction.category.shipping",
        defaultMessage: "Shipping",
        description: "The display name for the shipping transaction category",
      })
    case "streaming_services":
      return intl.formatMessage({
        id: "transaction.category.streaming_services",
        defaultMessage: "Streaming Services",
        description: "The display name for the streaming_services transaction category",
      })
    case "supermarkets_and_grocery_stores":
      return intl.formatMessage({
        id: "transaction.category.supermarkets_and_grocery_stores",
        defaultMessage: "Supermarkets and Grocery Stores",
        description: "The display name for the supermarkets_and_grocery_stores transaction category",
      })
    case "taxes_and_tax_preparation":
      return intl.formatMessage({
        id: "transaction.category.taxes_and_tax_preparation",
        defaultMessage: "Taxes and Tax Preparation",
        description: "The display name for the taxes_and_tax_preparation transaction category",
      })
    case "taxi_and_rideshare":
      return intl.formatMessage({
        id: "transaction.category.taxi_and_rideshare",
        defaultMessage: "Taxi and Rideshare",
        description: "The display name for the taxi_and_rideshare transaction category",
      })
    case "travel":
      return intl.formatMessage({
        id: "transaction.category.travel",
        defaultMessage: "Travel",
        description: "The display name for the travel transaction category",
      })
    case "utilities":
      return intl.formatMessage({
        id: "transaction.category.utilities",
        defaultMessage: "Utilities",
        description: "The display name for the utilities transaction category",
      })
    default:
      unreachable(category)
  }
}
