import type { ObjectType } from "@brm/schema-types/types.js"
import { mutableClone } from "@brm/util/mutable.js"
import { dereferenceSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken, type SkipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import type { ReadonlyDeep } from "type-fest/source/readonly-deep.js"
import {
  useGetSchemaV1ByObjectTypeInputQuery,
  useGetSchemaV1ByObjectTypePatchQuery,
  useGetSchemaV1ByObjectTypeQuery,
  usePostSchemaV1MapQuery,
  type GetSchemaV1ByObjectTypeInputApiArg,
  type GetSchemaV1ByObjectTypePatchApiArg,
} from "../app/services/generated-api.js"

/**
 * Fetches and dereferences the schema for the given object type.
 *
 * @returns The JSON schema or `undefined` while loading.
 * @throws {Error} If the schema could not be fetched.
 */
export const useObjectSchema = (objectType: ObjectType | SkipToken): ReadonlyDeep<JSONSchemaObject> | undefined => {
  const objectSchemaResult = useGetSchemaV1ByObjectTypeQuery(objectType === skipToken ? skipToken : { objectType })
  const objectSchema = useMemo(() => {
    if (objectSchemaResult.data === undefined) {
      return undefined
    }
    return dereferenceSchema(mutableClone(objectSchemaResult.data) as JSONSchema)
  }, [objectSchemaResult.data])
  if (objectSchema !== undefined && !isObject(objectSchema)) {
    throw Object.assign(new Error("Invalid object schema returned"), { objectSchema })
  }
  return objectSchema
}

/**
 * Fetches and dereferences the schema for multiple object types.
 *
 * @returns A map from the object type to the JSON schema.
 * @throws {Error} If any schema could not be fetched.
 */
export const useObjectSchemasMap = (objectTypes: ObjectType[]) => {
  const objectSchemasResult = usePostSchemaV1MapQuery({ body: { object_types: objectTypes } })

  const objectSchemas = useMemo(() => {
    if (objectSchemasResult.data === undefined) {
      return undefined
    }
    const objectSchemasMap = objectSchemasResult.data as Partial<Record<ObjectType, JSONSchema | undefined>>
    return Object.fromEntries(
      Object.entries(objectSchemasMap).map(([objectType, schema]) => {
        if (schema === undefined || !isObject(schema)) {
          throw Object.assign(new Error("Invalid object schema returned"), { objectType, schema })
        }
        return [objectType, dereferenceSchema(mutableClone(schema) as JSONSchemaObject)]
      })
    )
  }, [objectSchemasResult.data])
  return objectSchemas
}

/**
 * Fetches and dereferences the patch schema for the given object type, which is specifically used for patching workflow draft_state.
 *
 * @returns The JSON schema or `undefined` while loading.
 * @throws {Error} If the schema could not be fetched.
 */
export const useObjectPatchSchema = (
  objectType: GetSchemaV1ByObjectTypePatchApiArg["objectType"] | SkipToken
): ReadonlyDeep<JSONSchemaObject> | undefined => {
  const objectSchemaResult = useGetSchemaV1ByObjectTypePatchQuery(objectType === skipToken ? skipToken : { objectType })
  const objectSchema = useMemo(() => {
    if (objectSchemaResult.data === undefined) {
      return undefined
    }
    return dereferenceSchema(mutableClone(objectSchemaResult.data) as JSONSchemaObject)
  }, [objectSchemaResult.data])
  if (objectSchemaResult.isError && objectType !== skipToken) {
    throw Object.assign(new Error("Error fetching object schema", { cause: objectSchemaResult.error }), {
      objectType,
      schemaKind: "patch",
    })
  }
  if (objectSchema !== undefined && !isObject(objectSchema)) {
    throw Object.assign(new Error("Invalid object patch schema returned"), { objectSchema })
  }
  return objectSchema
}

/**
 * Fetches and dereferences the input schema for the given object type.
 *
 * @returns The JSON schema or `undefined` while loading.
 * @throws {Error} If the schema could not be fetched.
 */
export const useObjectInputSchema = (
  objectType: GetSchemaV1ByObjectTypeInputApiArg["objectType"] | SkipToken
): ReadonlyDeep<JSONSchemaObject> | undefined => {
  const objectSchemaResult = useGetSchemaV1ByObjectTypeInputQuery(objectType === skipToken ? skipToken : { objectType })
  const objectSchema = useMemo(() => {
    if (objectSchemaResult.data === undefined) {
      return undefined
    }
    return dereferenceSchema(mutableClone(objectSchemaResult.data) as JSONSchemaObject)
  }, [objectSchemaResult.data])
  if (objectSchemaResult.isError && objectType !== skipToken) {
    throw Object.assign(new Error("Error fetching object schema", { cause: objectSchemaResult.error }), {
      objectType,
      schemaKind: "input",
    })
  }
  if (objectSchema !== undefined && !isObject(objectSchema)) {
    throw Object.assign(new Error("Invalid object input schema returned"), { objectSchema })
  }
  return objectSchema
}
